import { isEmpty } from 'lodash';
import Papa from 'papaparse';

import { UploadError } from './uploadError';

export type CsvHeaders =
  | 'Id'
  | 'Experiment Name'
  | 'Purpose'
  | 'Bioreg Id'
  | 'Delivered Amount mg'
  | 'Delivered Concentration mg/ml'
  | 'Diluent / Formulation'
  | 'Batch ID'
  | 'A280';

export async function parseCsvFile<T>(file: File, headers: Map<string, string>) {
  const { data, errors } = await new Promise<Papa.ParseResult<T>>((resolve, reject) => {
    Papa.parse<T>(file, {
      complete(results) {
        resolve(results);
      },
      delimiter: ',',
      error(error) {
        reject(error);
      },
      header: true,
      skipEmptyLines: true,
      transformHeader(header: string) {
        return headers.get(header) ?? header;
      },
    });
  });

  if (!isEmpty(errors)) {
    throw papaParseErrorsToUploadError(errors);
  }

  return data;
}

function papaParseErrorsToUploadError(errors: Papa.ParseError[]) {
  const messages = errors.map((error) => {
    return { row: error.row, message: error.message };
  });
  return new UploadError(messages);
}
