import styled from '@emotion/styled';
import { CloseOutlined } from '@mui/icons-material';

import {
  ExperimentState,
  ExperimentSubState,
  UserBasicInfoFragment,
} from '#graphql';
import {
  getUserFullName,
  getUserInitials,
  getExperimentSubState,
} from '#lib/utils';
import { AppColors, ExperimentStatusesType } from '#lib/constants';

const statusColorMap: Record<ExperimentStatusesType, string> = {
  Provision: AppColors.LIME_GREEN,
  Completed: AppColors.PINK,
  Draft: AppColors.DARK_ORANGE,
  Execution: AppColors.LIGHT_BLUE,
  Ready: AppColors.CYAN,
  Review: AppColors.ORANGE,
};

const StatusBubbleStyled = styled.div(
  ({ status }: { status?: ExperimentState }) => ({
    color: 'black',
    backgroundColor: status ? statusColorMap[status] : AppColors.LOW_GREY,
    padding: '10px 12px 10px',
    borderRadius: '25px',
    width: '60px',
    fontSize: '13px',
    fontFamily: 'Roboto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  })
);

const NameBubbleStyled = styled.div({
  height: '38px',
  gap: '0.5em',
  alignItems: 'center',
  background: AppColors.GREY5,
  borderRadius: '37px',
  display: 'flex',
  color: AppColors.DARK_GREY,
  textOverflow: 'ellipsis',
  flexWrap: 'nowrap',
  paddingRight: '1rem',
});

const Title = styled.div({
  width: '38px',
  height: '38px',
  background: AppColors.LIGHT_CYAN,
  color: AppColors.BLACK,
  borderRadius: '37px',
  fontFamily: 'Roboto',
  fontWeight: 'bold',
  fontSize: '14px',
  display: 'flex',
  alignItems: 'center',
  textAlign: 'center',
  justifyContent: 'center',
  letterSpacing: '0.1px',
  marginLeft: '2px',
});

const Body = styled.div({
  display: 'flex',
  alignItems: 'center',
  textAlign: 'center',
  justifyContent: 'center',
  fontSize: '12px',
  flexWrap: 'nowrap',
  color: '#6F6F6F',
  whiteSpace: 'nowrap',
});

const StyledClosedIcon = styled(CloseOutlined)({
  cursor: 'pointer',
});

export const StatusBubble = ({ status }: { status: ExperimentState }) => {
  return (
    <StatusBubbleStyled data-test-id={`status`} status={status}>
      {status}
    </StatusBubbleStyled>
  );
};

export const SubStatusBubble = ({
  status,
}: {
  status?: ExperimentSubState;
}) => {
  return (
    <StatusBubbleStyled data-test-id={`subStatus`}>
      {getExperimentSubState(status)}
    </StatusBubbleStyled>
  );
};

export const NameBubble = ({
  user,
  deleteButton = false,
  onDelete,
}: {
  user: UserBasicInfoFragment;
  deleteButton?: boolean;
  onDelete?: () => void;
}) => (
  <NameBubbleStyled>
    <Title>{getUserInitials(user)}</Title>
    <Body>{getUserFullName(user)}</Body>
    {deleteButton && <StyledClosedIcon onClick={onDelete} />}
  </NameBubbleStyled>
);
