import styled from '@emotion/styled';
import { DateTime } from 'luxon';
import { Divider } from '@mui/material';

import { AppColors } from '#lib/constants';
import { ExperimentDetailsFragment } from '#graphql';

const StyledBody = styled.div(
  ({ size = '14rem', isAbsolute }: { size?: string; isAbsolute: number }) => ({
    background: AppColors.WHITE,
    overflow: 'auto',
    display: 'flex',
    position: isAbsolute ? 'absolute' : 'inherit',
    flexDirection: 'column',
    boxShadow: '-2px 2px 5px rgba(0, 0, 0, 0.2)',
    maxHeight: size,
    padding: '1rem',
    maxWidth: '24.3rem',
    '&::-webkit-scrollbar': {
      width: '0.9px',
    },
  })
);

const StyledCard = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
});

const StyledText = styled.div(
  ({
    weight,
    color,
    center = 0,
  }: {
    weight: string;
    color: string;
    center?: number;
  }) => ({
    fontSize: '10px',
    lineHeight: '12px',
    fontWeight: weight,
    whiteSpace: 'pre-line',
    color: color,
    textAlign: center ? 'center' : 'unset',
  })
);

const StyledDivider = styled(Divider)({
  margin: '10px 0',
  color: AppColors.MERCURY,
});

interface LatestActivityBoxProps {
  size?: string;
  absolute?: boolean;
  activities: ExperimentDetailsFragment['activity'];
}

export const LatestActivityBox = ({
  size,
  activities,
  absolute = false,
}: LatestActivityBoxProps) => {
  return (
    <StyledBody
      data-test-id={`latestActivityList`}
      isAbsolute={+absolute}
      size={size}
    >
      {activities.length ? (
        activities.map((element, index) => (
          <div key={index}>
            <StyledCard data-test-id={`activity${index + 1}`}>
              <StyledText color={AppColors.DARK_GREY} weight="400">
                {element.message}
              </StyledText>
              <StyledText color={AppColors.MID_GREY} weight="400">
                {element.createdBy.email} -{' '}
                {DateTime.fromISO(element.createdAt).toFormat('ff')}
              </StyledText>
            </StyledCard>
            <StyledDivider />
          </div>
        ))
      ) : (
        <StyledText center={+true} color={AppColors.GREY} weight="700">
          No latest activity to show
        </StyledText>
      )}
    </StyledBody>
  );
};
