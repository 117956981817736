import { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import DialogContent from '@mui/material/DialogContent';
import { Divider, Tab, Tabs } from '@mui/material';
import toast from 'react-hot-toast';

import {
  useAddManyTestArticlesToTreatmentMutation,
  useAddTreatmentToExperimentMutation,
  Treatment,
  AddTestArticleInput,
} from '#graphql';
import { SelectionBox, TabPage, ModalTab } from './';
import { useUpdateExperiment } from '#lib/hooks';
import { TREATMENT_MODALS_OPTIONS } from '#lib/constants';
import { ButtonPositiveSolid, ButtonNeutralSolid } from '#components/widgets';
import { AppColors, i18n } from '#lib/constants';
import { SelectedTreatments } from '#lib/types';

const StyledDialogContent = styled(DialogContent)({
  display: 'flex',
  padding: '0',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});

const StyledTabMenu = styled.div({
  display: 'flex',
  justifyContent: 'center',
  marginTop: '10px',
});

const StyledTab = styled(Tab)({
  fontSize: '10px',
  color: `${AppColors.BLACK}`,
  marginLeft: '10px',
  marginRigth: '10px',
  textTransform: 'none',
  fontWeight: '900',
});

const StyledDivider = styled(Divider)({
  marginBottom: '10px',
  width: '100%',
});

const ButtonsContainer = styled.div({
  display: 'flex',
  justifyContent: 'center',
});

const CancelButton = styled(ButtonNeutralSolid)({
  background: AppColors.WHITE,
  border: `2px solid ${AppColors.BLACK}`,
  borderRadius: '28px',
  color: AppColors.BLACK,
  letterSpacing: '1.25px',
  textTransform: 'uppercase',
  fontSize: '10px',
  fontWeight: '500',
  fontStyle: 'normal',
  fontFamily: 'Roboto',
  padding: '10px',
  width: '100px',
  marginRight: '10px',
  '&:hover': {
    background: AppColors.WHITE,
  },
});

const StyledButton = styled(ButtonPositiveSolid)({
  background: AppColors.BRAND_GREEN,
  borderRadius: '28px',
  color: AppColors.WHITE,
  letterSpacing: '1.25px',
  textTransform: 'uppercase',
  fontSize: '10px',
  fontWeight: 'bold',
  fontStyle: 'normal',
  fontFamily: 'Roboto',
  padding: '10px',
  width: '100px',
  marginBottom: '25px',
  marginLeft: '10px',

  '&:hover': {
    background: AppColors.BRAND_GREEN,
  },
});

interface TreatmentModalContentProps {
  handleClose: () => void;
  experimentId: number;
  selectedTreatment: Treatment | undefined;
  treatmentId: number | undefined;
}

export const TreatmentModalContent = ({
  handleClose,
  experimentId,
  selectedTreatment,
  treatmentId,
}: TreatmentModalContentProps) => {
  const { updateExperimentCache } = useUpdateExperiment();
  const [menuTab, setMenuTab] = useState(0);
  const [selectedItems, setSelectedItems] = useState<SelectedTreatments[]>([]);

  useEffect(() => {
    if (selectedTreatment?.testArticles) {
      const selectedTestArticles = selectedTreatment?.testArticles.map(
        (element) => ({
          ...element,
          id: element.moleculeType + '-' + element.moleculeId,
          tab: element.moleculeType,
          testArticleId: element.moleculeId,
        })
      );
      setSelectedItems(selectedTestArticles);
    } else {
      setSelectedItems([]);
    }
  }, [selectedTreatment]);

  const onChangeTab = (_event: React.SyntheticEvent, newMenuOption: number) =>
    setMenuTab(newMenuOption);

  const onChangeSelectedItem = (
    id: string,
    isNew: boolean,
    testArticleId?: number,
    bioregId?: string,
    tab?: string,
    name?: string
  ) => {
    if (isNew && name) {
      setSelectedItems([
        ...selectedItems,
        {
          id,
          tab: tab || '',
          name,
          bioregId,
          testArticleId,
          dosage: 0,
          doseUnit: 'mg/kg',
        },
      ]);
    } else {
      setSelectedItems((items) => items.filter((item) => item.id !== id));
    }
  };

  const getTabsCommonProps = (index: number) => ({
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
    key: `key-${index}`,
  });

  const onChangeCheckbox = (id: string) =>
    selectedItems.find((item) => item.id === id) ? true : false;

  const [addTestArticleToTreatment] = useAddManyTestArticlesToTreatmentMutation(
    {
      onCompleted: (data) => {
        updateExperimentCache({
          treatments: data.setTreatmentsTestArticles,
          id: experimentId,
        });
      },
    }
  );

  const [AddTreatmentToExperiment] = useAddTreatmentToExperimentMutation();

  const handleSave = async () => {
    if (selectedItems.length) {
      const tid = toast.loading(i18n.Common.Saving);
      if (treatmentId) {
        await addTestArticleToTreatment({
          variables: {
            input: getTestArticles(treatmentId),
          },
        });
      } else {
        const response = await AddTreatmentToExperiment({
          variables: {
            input: { experimentId },
          },
        });
        if (response?.data) {
          await addTestArticleToTreatment({
            variables: {
              input: getTestArticles(response.data.addTreatmentToExperiment.id),
            },
          });
        }
      }
      handleClose();
      toast.dismiss(tid);
    }
  };

  const getTestArticles = (newTreatmentId: number): AddTestArticleInput[] =>
    selectedItems.map((item) => {
      const arts = {
        treatmentId: newTreatmentId,
        bioregId: item.bioregId,
        name: item.name as string,
        moleculeType: item.tab,
        moleculeId: item.testArticleId,
        dosage: item.dosage as number,
        doseNumber: item.doseNumber as number,
        doseUnit: item.doseUnit,
        interval: item.interval as number,
        route: item.route,
      };
      return arts;
    });

  const tabMenuComponents = [
    <ModalTab
      onChangeCheckbox={onChangeCheckbox}
      onChangeSelectedItem={onChangeSelectedItem}
      tabName="antibody"
      selectedItemField="name"
      objectPath="antibodies"
    />,
    <ModalTab
      onChangeCheckbox={onChangeCheckbox}
      onChangeSelectedItem={onChangeSelectedItem}
      tabName="conjugate"
      selectedItemField="sgName"
      objectPath="conjugates"
    />,
    <ModalTab
      onChangeCheckbox={onChangeCheckbox}
      onChangeSelectedItem={onChangeSelectedItem}
      tabName="smallMolecule"
      selectedItemField="bioregId"
      objectPath="smallMolecules"
    />,
    <ModalTab
      onChangeCheckbox={onChangeCheckbox}
      onChangeSelectedItem={onChangeSelectedItem}
      tabName="Other Molecules"
      selectedItemField="name"
      objectPath="otherMolecules"
      category="non-SG drug"
    />,
    <ModalTab
      onChangeCheckbox={onChangeCheckbox}
      onChangeSelectedItem={onChangeSelectedItem}
      tabName="Other Molecules"
      selectedItemField="name"
      objectPath="otherMolecules"
      category="immunogen"
    />,
    <ModalTab
      onChangeCheckbox={onChangeCheckbox}
      onChangeSelectedItem={onChangeSelectedItem}
      tabName="Other Molecules"
      selectedItemField="name"
      objectPath="otherMolecules"
      category="control"
    />,
    <ModalTab
      onChangeCheckbox={onChangeCheckbox}
      onChangeSelectedItem={onChangeSelectedItem}
      tabName="Cell Lines"
      selectedItemField="name"
      objectPath="cellLines"
    />,
    <ModalTab
      onChangeCheckbox={onChangeCheckbox}
      onChangeSelectedItem={onChangeSelectedItem}
      tabName="Other Molecules"
      selectedItemField="name"
      objectPath="otherMolecules"
    />,
  ];

  return (
    <>
      <StyledDialogContent data-test-id={`treatmentModal`}>
        <SelectionBox
          onChangeSelectedItem={onChangeSelectedItem}
          selectedItems={selectedItems}
        />
        <StyledTabMenu>
          <Tabs
            value={menuTab}
            onChange={onChangeTab}
            TabIndicatorProps={{ style: { background: AppColors.BRAND_GREEN } }}
          >
            {TREATMENT_MODALS_OPTIONS.map((menuOption, index) => (
              <StyledTab label={menuOption} {...getTabsCommonProps(index)} />
            ))}
          </Tabs>
        </StyledTabMenu>
        <StyledDivider />

        {tabMenuComponents.map((component, index) => (
          <TabPage key={index} value={menuTab} index={index}>
            {component}
          </TabPage>
        ))}
      </StyledDialogContent>
      <StyledDivider />
      <ButtonsContainer>
        <CancelButton data-test-id={`cancelTreatment`} onClick={handleClose}>
          CANCEL
        </CancelButton>
        <StyledButton data-test-id={`submitTreatment`} onClick={handleSave}>
          APPLY
        </StyledButton>
      </ButtonsContainer>
    </>
  );
};
