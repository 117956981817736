import { useState } from 'react';
import styled from '@emotion/styled';
import { IconButton } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CloseIcon from '@mui/icons-material/Close';
import { AppColors, i18n } from '#lib/constants';
import toast from 'react-hot-toast';
import { ConfirmationModal } from '#components/partials';
import { useModal } from '#lib/hooks';

const ListContentContainer = styled.div({
  width: '250px',
  display: 'flex',
  flexDirection: 'column',
  marginRight: '50px',
  marginBottom: '100px',
});

const EditorContainer = styled.div({
  width: '250px',
  height: '350px',
  border: '1px solid #CECECE',
  overflowY: 'scroll',
  overflowX: 'hidden',
});

const StyledItem = styled.div({
  fontSize: '12px',
  color: '#000000',
  background: '#EDEDED',
  padding: '10px',
  margin: '5px',
  borderRadius: '6px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  '&.active': {
    background: AppColors.BRIGHT_GREEN,
  },
});

const StyledTextField = styled.input({
  fontFamily: 'Roboto',
  padding: '3px 5px',
  border: '0',
  fontSize: '15px',
});

const StyledArrowForwardIcon = styled(ArrowForwardIcon)({
  color: '#008844',
  fontSize: '32px',
});

const StyledCloseIcon = styled(CloseIcon)({
  fontSize: '20px',
});

const StyledTitle = styled.h5({
  fontSize: '11px',
  height: '13px',
  textAlign: 'center',
});

const StyledItemContent = styled.span({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  width: '150px',
});

const StyledDescription = styled.span({
  textAlign: 'right',
  fontStyle: 'italic',
  opacity: '0.7',
});

const StyledForm = styled.span({
  display: 'flex',
  flexDirection: 'row',
  border: '1px solid #CECECE',
});

const StyledInputs = styled.span({
  display: 'flex',
  flexDirection: 'column',
  borderRight: '1px solid #CECECE',
  flexGrow: '1',
});

export type TemplateEditorItemType = {
  name: string;
  category?: string;
  id: number;
};

export type CreateTemplateEditorItemType = Omit<
  TemplateEditorItemType,
  'id'
> & { id?: number };

interface ListContentEditorProps {
  items?: TemplateEditorItemType[];
  title: string;
  minItemCount: number;
  addItem: (item: CreateTemplateEditorItemType) => void;
  removeItem: (id: number) => void;
}

export const TemplateEditorItem = ({
  items,
  title,
  minItemCount,
  addItem,
  removeItem,
}: ListContentEditorProps) => {
  const [name, setName] = useState('');
  const [category, setCategory] = useState('');
  const [id, setId] = useState<number>();
  const { Modal, openModal, closeModal } = useModal(
    i18n.Settings.RemoveItemConfirmation,
    'sm'
  );

  const addItemHandler = () => {
    if (!name) {
      toast.error(i18n.Settings.NoName);
      return;
    }
    addItem({ name, category, id });
    resetItem();
  };

  const resetItem = () => {
    setCategory('');
    setName('');
    setId(undefined);
  };

  const confirmRemoveItem = (item: TemplateEditorItemType) => {
    setId(item.id);
    openModal();
  };

  const removeItemHandle = () => {
    id && removeItem(id);
    setId(undefined);
    closeModal();
  };

  const editItem = (item: TemplateEditorItemType) => {
    setName(item.name);
    setCategory(item.category || '');
    setId(item.id);
  };

  return (
    <ListContentContainer data-test-id="templateEditorContainer">
      <StyledTitle>{title}</StyledTitle>
      <EditorContainer data-test-id="templateEditorItems">
        {items?.map((item) => (
          <StyledItem
            key={item.name}
            className={item.name === name ? 'active' : ''}
            data-test-id="templateEditorItem"
            onClick={() => editItem(item)}
          >
            <StyledItemContent
              title={`${item.name}${
                item.category ? ` - ${item.category}` : ''
              }`}
            >
              {item.name}
              {!!item.category && (
                <StyledDescription>&nbsp;- {item.category}</StyledDescription>
              )}
            </StyledItemContent>
            <div>
              {items.length > minItemCount && (
                <IconButton
                  aria-label="delete"
                  name="delete"
                  onClick={() => confirmRemoveItem(item)}
                  sx={{ padding: 0 }}
                >
                  <StyledCloseIcon />
                </IconButton>
              )}
            </div>
          </StyledItem>
        ))}
      </EditorContainer>
      <StyledForm>
        <StyledInputs>
          <StyledTextField
            placeholder="Item Name*"
            value={name}
            onChange={(e) => setName(e.target.value)}
            data-test-id="templateEditorNameInput"
          />
          <StyledTextField
            placeholder="Item Category"
            value={category}
            data-test-id="templateEditorCategoryInput"
            onChange={(e) => setCategory(e.target.value)}
          />
        </StyledInputs>
        <IconButton
          name="add"
          aria-label="add"
          onClick={addItemHandler}
          data-test-id="templateEditorSaveButton"
        >
          <StyledArrowForwardIcon />
        </IconButton>
      </StyledForm>
      <Modal>
        <ConfirmationModal
          closeModal={closeModal}
          confirmationFunction={removeItemHandle}
        />
      </Modal>
    </ListContentContainer>
  );
};
