import React, { type ReactNode } from 'react';
import { Box, Typography } from '@mui/material';
import { map, max } from 'lodash';

export interface DefinitionListProps {
  definitions: Record<string, ReactNode>;
}

export const DefinitionList = ({ definitions }: DefinitionListProps) => {
  return (
    <Box
      component="dl"
      display="grid"
      gridTemplateColumns={`${
        8 * (max(Object.keys(definitions).map((def) => def.length)) ?? 70)
      }px 1fr`}
      rowGap={0.5}
      sx={{ marginBlock: 0 }}
    >
      {map(definitions, (definition, title) => (
        <React.Fragment key={title}>
          <Box component="dt" sx={{ '& >*::after': { content: '": "' } }}>
            <Typography
              variant="body2"
              sx={{
                color: 'rgba(0, 0, 0, 0.6)',
                fontWeight: 'bold',
                fontSize: '.75rem',
              }}
            >
              {title}
            </Typography>
          </Box>
          <Box component="dd" sx={{ marginInlineStart: 0 }}>
            <Typography
              variant="body2"
              sx={{
                color: 'rgba(0, 0, 0, 0.8)',
                fontSize: '.75rem',
              }}
            >
              {definition}
            </Typography>
          </Box>
        </React.Fragment>
      ))}
    </Box>
  );
};
