"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getEntryUpdatesCore = exports.getEntryUpdates = exports.getInitialEntriesForWorksheet = exports.worksheetEntryInitializersByWorksheetType = exports.getInitialEntriesForCellCalculationWorksheets = exports.getInitialEntriesForBioanalysisWorksheet = exports.getInitialEntriesForImagingWorksheet = exports.getInitialEntriesForTreatmentCalcWorksheets = exports.getInitialEntriesForDataCollectionWorksheets = void 0;
const worksheets_core_1 = require("@omnivivo/worksheets-core");
const lodash_1 = require("lodash");
const WorksheetTypes_1 = require("./WorksheetTypes");
const DataCollectionSectionGenerators_1 = require("./DataCollectionSectionGenerators");
const CellCalculationSectionGenerators_1 = require("./CellCalculationSectionGenerators");
// ****************************************************************
//   its-worksheet-definitions
// ****************************************************************
const getInitialEntriesForDataCollectionWorksheets = (treatmentGroupsForWorksheet, modelId) => (0, lodash_1.flatten)(treatmentGroupsForWorksheet
    .filter((tg) => tg.modelId === modelId)
    .map((tg) => (0, lodash_1.flatten)([
    {
        sectionId: (0, DataCollectionSectionGenerators_1.getTgColumnsSectionId)(tg),
        columnId: '001',
    },
    (0, lodash_1.range)(tg.modelCount).map((index) => ({
        sectionId: (0, DataCollectionSectionGenerators_1.getTgSectionId)(tg),
        rowId: `${index + 1}`.padStart(3, '0'),
        columnId: '001',
    })),
])));
exports.getInitialEntriesForDataCollectionWorksheets = getInitialEntriesForDataCollectionWorksheets;
const getInitialEntriesForTreatmentCalcWorksheets = (treatmentGroupsForWorksheet, modelId) => {
    const result = [];
    const testArticlesAdded = {};
    treatmentGroupsForWorksheet
        .filter((tg) => tg.modelId === modelId)
        .forEach(({ treatmentGroupId, treatmentGroupIndex, treatmentGroupName, modelCount: totalAnimals, testArticlesForWorksheet, animalProps, adcOrderItemProps, }) => {
        testArticlesForWorksheet?.forEach((testArticleForWorksheet) => {
            let initialStock;
            if (testArticleForWorksheet.moleculeType === 'conjugate') {
                const adcOrderItemProp = adcOrderItemProps?.find((adcOrderItem) => adcOrderItem.bioregId === testArticleForWorksheet.bioregId);
                if (!(0, lodash_1.isNil)(adcOrderItemProp)) {
                    initialStock = adcOrderItemProp.deliveredConcentration;
                }
            }
            result.push({
                rowId: `testArticle-${testArticleForWorksheet.testArticleId}-treatmentGroup=${treatmentGroupId}`,
                sectionId: 'concentrationWorkspace',
                data: {
                    treatmentGroupId,
                    treatmentGroupIndex,
                    treatmentGroupName,
                    totalAnimals,
                    initialStock,
                    ...testArticleForWorksheet,
                    ...animalProps,
                },
            });
            if (!testArticlesAdded[testArticleForWorksheet.testArticleId]) {
                if (testArticleForWorksheet.moleculeType === 'conjugate') {
                    const adcOrderItemProp = adcOrderItemProps?.find((adcOrderItem) => adcOrderItem.bioregId === testArticleForWorksheet.bioregId);
                    result.push({
                        rowId: `testArticle-${testArticleForWorksheet.testArticleId}`,
                        sectionId: 'adcVerificationWorkspace',
                        data: {
                            treatmentId: testArticleForWorksheet.testArticleName,
                            treatmentGroupIndex,
                            treatmentGroupId,
                            ...adcOrderItemProp,
                        },
                    });
                    testArticlesAdded[testArticleForWorksheet.testArticleId] = true;
                }
            }
            if (testArticleForWorksheet.moleculeType === 'antibody') {
                result.push({
                    rowId: `testArticle-${testArticleForWorksheet.testArticleId}`,
                    sectionId: 'proteinVerificationWorkspace',
                    data: {
                        treatmentId: testArticleForWorksheet.testArticleName,
                        treatmentGroupIndex,
                        treatmentGroupId,
                        bioregId: testArticleForWorksheet.bioregId ?? 'N/A',
                    },
                });
            }
        });
    });
    return result;
};
exports.getInitialEntriesForTreatmentCalcWorksheets = getInitialEntriesForTreatmentCalcWorksheets;
const getInitialEntriesForImagingWorksheet = (treatmentGroupsForWorksheet, modelId) => treatmentGroupsForWorksheet
    .filter((tg) => tg.modelId === modelId)
    .filter((tg) => tg.hasImaging)
    .map(({ treatmentGroupId: treatmentGroup, treatmentGroupIndex }) => ({
    sectionId: 'animalsToInclude',
    rowId: treatmentGroup.toString(),
    data: { treatmentGroup, treatmentGroupIndex },
}));
exports.getInitialEntriesForImagingWorksheet = getInitialEntriesForImagingWorksheet;
const getInitialEntriesForBioanalysisWorksheet = (treatmentGroupsForWorksheet, modelId) => (0, lodash_1.flatten)(treatmentGroupsForWorksheet
    .filter((tg) => tg.modelId === modelId)
    .filter((tg) => tg.hasBioanalysis)
    .map(({ treatmentGroupId: treatmentGroup, treatmentGroupIndex }) => [
    {
        sectionId: 'bloodAnimalsToInclude',
        rowId: treatmentGroup.toString(),
        data: { treatmentGroup, treatmentGroupIndex },
    },
    {
        sectionId: 'tissueAnimalsToInclude',
        rowId: treatmentGroup.toString(),
        data: { treatmentGroup, treatmentGroupIndex },
    },
]));
exports.getInitialEntriesForBioanalysisWorksheet = getInitialEntriesForBioanalysisWorksheet;
const getInitialEntriesForCellCalculationWorksheets = (treatmentGroupsForWorksheet, modelId) => {
    let numberOfGroups = 0;
    let totalAnimalsFromGroups = 0;
    treatmentGroupsForWorksheet
        .filter((tg) => tg.modelId === modelId)
        .forEach(({ modelCount }) => {
        numberOfGroups++;
        totalAnimalsFromGroups += modelCount;
    });
    return [
        {
            sectionId: CellCalculationSectionGenerators_1.SET_AMOUNT_ID,
            columnId: '001',
            rowId: '001',
            data: { numberOfGroups, totalAnimalsFromGroups },
        },
    ];
};
exports.getInitialEntriesForCellCalculationWorksheets = getInitialEntriesForCellCalculationWorksheets;
exports.worksheetEntryInitializersByWorksheetType = {
    bioanalysis: exports.getInitialEntriesForBioanalysisWorksheet,
    cellCalculationAdherent: exports.getInitialEntriesForCellCalculationWorksheets,
    cellCalculationSuspension: exports.getInitialEntriesForCellCalculationWorksheets,
    dataCollectionAnimalWeight: exports.getInitialEntriesForDataCollectionWorksheets,
    dataCollectionTumorSize: exports.getInitialEntriesForDataCollectionWorksheets,
    imaging: exports.getInitialEntriesForImagingWorksheet,
    treatmentCalculation: exports.getInitialEntriesForTreatmentCalcWorksheets,
};
const getInitialEntriesForWorksheet = (type, experimentForWorksheet, modelId) => (0, worksheets_core_1.ensureInitialEmptyEntriesAreIncluded)(WorksheetTypes_1.worksheetsByType[type], exports.worksheetEntryInitializersByWorksheetType[type]?.(experimentForWorksheet.treatmentGroupsForWorksheet, modelId));
exports.getInitialEntriesForWorksheet = getInitialEntriesForWorksheet;
const getEntryUpdates = (type, existingEntries, experimentForWorksheet, modelId) => {
    const treatmentGroupIds = (0, lodash_1.map)(experimentForWorksheet.treatmentGroupsForWorksheet, 'treatmentGroupId');
    const keepEntries = [];
    const removeEntries = [];
    existingEntries.forEach((entry) => {
        const treatmentGroupId = entry.data?.treatmentGroupId ?? entry.data?.treatmentGroup;
        if ((0, lodash_1.isNumber)(treatmentGroupId) && !treatmentGroupIds.includes(treatmentGroupId))
            removeEntries.push(entry);
        else
            keepEntries.push(entry);
    });
    const { create, update, remove } = (0, exports.getEntryUpdatesCore)(keepEntries, (0, exports.getInitialEntriesForWorksheet)(type, experimentForWorksheet, modelId));
    return {
        create,
        remove: removeEntries.length === 0 ? remove : removeEntries.concat(remove),
        update,
    };
};
exports.getEntryUpdates = getEntryUpdates;
const getEntryUpdatesCore = (existingEntries, newEntries) => {
    const { added, changed, deleted } = (0, worksheets_core_1.compareEntries)(existingEntries, newEntries);
    return {
        create: added,
        update: changed,
        remove: deleted,
    };
};
exports.getEntryUpdatesCore = getEntryUpdatesCore;
