import { ChangeEvent } from 'react';
import styled from '@emotion/styled';
import { TextField, IconButton, Typography } from '@mui/material';
import { Search } from '@mui/icons-material';

import { TabButton } from '#components/widgets';
import {
  FULFILLED_ADC_ORDERS_PATH,
  UNFULFILLED_ADC_ORDERS_PATH,
  ADC_ORDERS_PATH,
} from '#lib/constants';
import { useSearchParams } from 'react-router-dom';
import { debounce } from 'lodash';

const StyledContainer = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  width: 'calc(100% - 100px);',
  margin: '20px auto',
});

const StyledSection = styled.div({
  display: 'flex',
  justifyContent: 'flex-start',
  gap: '15px',
  alignItems: 'center',
});

const StyledItemsSection = styled.div({
  display: 'flex',
  marginTop: '6px',
});

const StyledTextField = styled(TextField)({
  div: {
    borderRadius: '4px',
    fontFamily: 'Roboto',
    width: '300px',
    height: '28px',
    padding: '0',
  },
});

type tabOptions = { name: string; path: string };

export const AdcOrderHeader = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const query = searchParams.get('q') ?? '';
  const search = searchParams.toString();

  const tabOptions: tabOptions[] = [
    {
      name: 'Unfulfilled',
      path: `/${ADC_ORDERS_PATH}/${UNFULFILLED_ADC_ORDERS_PATH}?${search}`,
    },
    {
      name: 'Fulfilled',
      path: `/${ADC_ORDERS_PATH}/${FULFILLED_ADC_ORDERS_PATH}?${search}`,
    },
  ];

  const changeHandler = debounce((ev: ChangeEvent<HTMLInputElement>) => {
    searchParams.set('q', ev.target.value);
    setSearchParams(searchParams);
  }, 500);

  return (
    <StyledContainer>
      <StyledSection>
        <Typography variant="h1" sx={{ marginRight: '20px' }}>
          ADC Orders
        </Typography>
        <StyledItemsSection>
          {tabOptions.map(({ name, path }, index) => (
            <TabButton data-test-id={`tab${name}`} key={index} path={path}>
              {name}
            </TabButton>
          ))}
        </StyledItemsSection>
      </StyledSection>

      <StyledSection>
        <StyledTextField
          placeholder="Search"
          defaultValue={query}
          type="search"
          onChange={changeHandler}
          InputProps={{
            endAdornment: (
              <IconButton name="Search" aria-label="Search">
                <Search />
              </IconButton>
            ),
          }}
        />
      </StyledSection>
    </StyledContainer>
  );
};
