import { type ApolloError } from '@apollo/client';
import { isNil } from 'lodash';

import { Loader, MessageBox } from '#components/widgets';
import { i18n } from '#lib/constants';
import React from 'react';

interface HandleNetworkLifecycleProps<T extends object> {
  emptyMessage?: string;
  error?: ApolloError;
  errorMessage?: string;
  loading: boolean;
  data: T | undefined;
  render: ({ data }: { data: T }) => React.ReactNode;
}

export function HandleNetworkLifecycle<T extends object>({
  emptyMessage = i18n.Common.GenericEmpty,
  error,
  errorMessage = i18n.Common.GenericError,
  data,
  loading,
  render,
}: HandleNetworkLifecycleProps<T>) {
  if (loading) {
    return <Loader />;
  }

  if (!isNil(error)) {
    return <MessageBox>{errorMessage}</MessageBox>;
  }

  if (isNil(data)) {
    return <MessageBox>{emptyMessage}</MessageBox>;
  }

  return <>{render({ data })}</>;
}
