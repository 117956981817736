import AddIcon from '@mui/icons-material/Add';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { Box, FormControlLabel, Grid, IconButton, InputLabel, Stack, Typography } from '@mui/material';
import { intersection, isNil, sortBy } from 'lodash';
import { useCallback, useMemo } from 'react';
import { useFieldArray, useWatch } from 'react-hook-form';

import {
  OmniSelectField,
  OmniTextField,
  ReactHookCheckbox,
  type OmniFieldSelectOptionObject,
} from '#components/widgets';
import { type ListContentDetailsFragment } from '#graphql';
import { type TreatmentGroupWithTreatment } from '#lib/types';
import { Button } from '#rds';
import { initialTimepoint, rangeStringToNumberArray, type BioanalysisFormData } from './bioanalysis';

interface CollectionScheduleFormSectionProps {
  bioanalysisTimepoints: ListContentDetailsFragment[];
  collectionScheduleIndex: number;
  doseValues: OmniFieldSelectOptionObject[];
  isDefault: boolean;
  onDelete: () => undefined;
  treatmentGroups: TreatmentGroupWithTreatment[];
}

export function CollectionScheduleFormSection(props: CollectionScheduleFormSectionProps) {
  const { bioanalysisTimepoints, collectionScheduleIndex, doseValues, isDefault, onDelete, treatmentGroups } = props;

  const collectionSchedulePrefix = `collectionSchedules.${collectionScheduleIndex}` as const;

  const timepointsField = `${collectionSchedulePrefix}.timepoints` as const;

  const {
    fields: timepoints,
    append,
    remove,
  } = useFieldArray<BioanalysisFormData, typeof timepointsField>({ name: timepointsField });

  const timepointsValue = useWatch<BioanalysisFormData, typeof timepointsField>({ name: timepointsField });

  const animalsValidator = useCallback(
    (value: string, formValues: BioanalysisFormData) => {
      return validateAnimals(value, formValues, collectionScheduleIndex, treatmentGroups);
    },
    [collectionScheduleIndex, treatmentGroups]
  );

  const groupsValidator = useCallback(
    (value: string, formValues: BioanalysisFormData) => validateGroups(value, formValues, treatmentGroups),
    [treatmentGroups]
  );

  const doseValidator = useCallback(
    (value: string, formValues: BioanalysisFormData) => {
      return validateDose(value, formValues, collectionScheduleIndex, treatmentGroups);
    },
    [collectionScheduleIndex, treatmentGroups]
  );

  const sortedMinutesValues = useMemo(
    () =>
      sortBy(
        bioanalysisTimepoints.map((bioanalysisTimepoint) => ({
          value: bioanalysisTimepoint.name,
          label: bioanalysisTimepoint.description,
        })),
        (minuteValue) => Number(minuteValue.value)
      ),
    [bioanalysisTimepoints]
  );

  const title = isDefault ? 'Default Collection Schedule *' : `Collection Schedule ${collectionScheduleIndex}`;

  return (
    <Stack spacing={2}>
      <Stack direction="row" spacing={1} alignItems="baseline">
        <InputLabel>{title}</InputLabel>
        {isDefault || (
          <Button variant="text" onClick={onDelete}>
            <Typography sx={{ fontSize: '.625rem', textDecoration: 'underline' }}>Delete Set</Typography>
          </Button>
        )}
      </Stack>

      <Box paddingLeft={6}>
        <Stack spacing={3}>
          {isDefault || (
            <Typography
              width={300}
              sx={{
                '.MuiInputBase-root': { fontSize: '.75rem' },
                '.MuiFormLabel-root': { fontSize: '.625rem', paddingBottom: 1 },
              }}
              component="div"
            >
              <OmniTextField
                hideErrorMessage={true}
                label="Apply to Groups"
                name={`${collectionSchedulePrefix}.onlyGroupsString`}
                required
                rules={{
                  required: 'please enter the groups to to which this schedule should be applied',
                  // @ts-expect-error -- cannot find a way around this right now
                  validate: groupsValidator,
                }}
                inputProps={{
                  placeholder: 'Type a subset of groups (e.g. 1-3 or 1,2)',
                }}
              />
            </Typography>
          )}
          <Stack spacing={1}>
            <Grid container item spacing={1}>
              <Grid item xs={2}>
                <Typography fontSize=".625rem" fontWeight="bold">
                  Dose
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography fontSize=".625rem" fontWeight="bold">
                  Timepoint
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography fontSize=".625rem" fontWeight="bold">
                  Animals
                </Typography>
              </Grid>
            </Grid>

            {timepoints.map((timepoint, timepointIndex) => {
              const timepointPrefix = `${collectionSchedulePrefix}.timepoints.${timepointIndex}` as const;

              const includeAllAnimals = timepointsValue[timepointIndex]?.includeAllAnimals === true;

              return (
                <Grid container spacing={2} key={timepoint.id}>
                  <Grid item xs={2}>
                    <Typography sx={{ '.MuiInputBase-root': { fontSize: '.75rem' } }} component="div">
                      <OmniSelectField
                        hideErrorMessage
                        label=""
                        name={`${timepointPrefix}.dose` as const}
                        rules={{
                          required: 'please select the dose',
                          // @ts-expect-error -- cannot find a way around this right now
                          validate: doseValidator,
                        }}
                        selectOptions={doseValues}
                        sort={false}
                      />
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography sx={{ '.MuiInputBase-root': { fontSize: '.75rem' } }} component="div">
                      <OmniSelectField
                        hideErrorMessage
                        label=""
                        name={`${timepointPrefix}.minutes`}
                        rules={{
                          required: 'please select the timepoint',
                        }}
                        selectOptions={sortedMinutesValues}
                        sort={false}
                      />
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Stack direction="row" spacing={2} alignItems="flex-start">
                      <FormControlLabel
                        control={
                          <ReactHookCheckbox
                            controller={{
                              name: `${timepointPrefix}.includeAllAnimals`,
                            }}
                          />
                        }
                        label="Include All"
                        componentsProps={{
                          typography: {
                            fontSize: '.75rem',
                          },
                        }}
                        sx={{
                          paddingTop: '5px',
                          margin: 0,
                          '& .MuiCheckbox-root': {
                            padding: 0,
                            paddingRight: '4px',
                          },
                        }}
                      />
                      <Typography sx={{ '.MuiInputBase-root': { fontSize: '.75rem' } }} width={300} component="div">
                        <OmniTextField
                          hideErrorMessage
                          label=""
                          name={`${timepointPrefix}.onlyAnimalsString`}
                          rules={{
                            required: !includeAllAnimals ? 'please enter the animal group(s)' : undefined,
                            // @ts-expect-error -- cannot find a way around this right now
                            validate: includeAllAnimals ? undefined : animalsValidator,
                          }}
                          inputProps={{
                            placeholder: 'Type a subset of animals (e.g. 1-3 or 1,2)',
                          }}
                          disabled={includeAllAnimals}
                        />
                      </Typography>
                      {timepointIndex !== 0 && (
                        <IconButton
                          aria-label="Delete timepoint"
                          onClick={() => {
                            remove(timepointIndex);
                          }}
                          sx={{ padding: 0 }}
                        >
                          <RemoveCircleOutlineIcon />
                        </IconButton>
                      )}
                    </Stack>
                  </Grid>
                </Grid>
              );
            })}
          </Stack>
          <Stack direction="row" spacing={2}>
            <Button
              variant="outlined"
              size="small"
              onClick={() => {
                append(initialTimepoint);
              }}
              startIcon={<AddIcon />}
            >
              Add Timepoint
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Stack>
  );
}

const rangeRegex = /^\d+(-\d+)?(,\d+(-\d+)?)*$/;

export function validateAnimals(
  value: string,
  formValues: BioanalysisFormData,
  collectionScheduleIndex: number,
  treatmentGroups: TreatmentGroupWithTreatment[]
): string | undefined {
  const { collectionSchedules, treatmentGroups: treatmentGroupsValue } = formValues;

  const collectionScheduleValue = collectionSchedules[collectionScheduleIndex];

  const valueWithoutWhitespace = value.replaceAll(/\s/g, '');

  if (!rangeRegex.test(valueWithoutWhitespace)) {
    return 'please specify the animals as a comma-separated list of integers or integer-ranges (i.e. "1, 3-4, 5")';
  }

  if (
    !collectionScheduleValue.isDefault &&
    !rangeRegex.test(collectionScheduleValue.onlyGroupsString.replaceAll(/\s/g, ''))
  ) {
    return 'please provide a valid value is provided for the groups';
  }

  const animalNumbers = rangeStringToNumberArray(valueWithoutWhitespace);

  const maxAnimalNumber = Math.max(...animalNumbers);

  const onlyGroups = rangeStringToNumberArray(collectionScheduleValue.onlyGroupsString);

  const selectedGroupsAnimals = treatmentGroupsValue
    .filter((treatmentGroupValue) => treatmentGroupValue.checked)
    .map((treatmentGroupValue) => {
      const treatmentGroup = treatmentGroups.find(
        (treatmentGroup) => treatmentGroup.id === treatmentGroupValue.treatmentGroupId
      );

      if (
        isNil(treatmentGroup) ||
        (!collectionScheduleValue.isDefault && !onlyGroups.includes(treatmentGroup.treatmentGroupIndex))
      ) {
        return 0;
      }

      return treatmentGroup.modelCount;
    });

  const maxAnimals = Math.max(0, ...selectedGroupsAnimals);

  if (maxAnimalNumber > maxAnimals) {
    return `you specified an animal number of ${maxAnimalNumber} but the maximum number of animals specified for the selected groups is ${maxAnimals}`;
  }
}

export function validateGroups(
  value: string,
  formValues: BioanalysisFormData,
  treatmentGroups: TreatmentGroupWithTreatment[]
): string | undefined {
  const { treatmentGroups: treatmentGroupsValue } = formValues;

  const valueWithoutWhitespace = value.replaceAll(/\s/g, '');

  if (!rangeRegex.test(valueWithoutWhitespace)) {
    return 'please specify the groups as a comma-separated list of integers or integer-ranges (i.e. "1, 3-4, 5")';
  }

  const groupIndices = rangeStringToNumberArray(valueWithoutWhitespace);

  const selectedTreatmentGroupIndices = treatmentGroupsValue
    .filter((treatmentGroupValue) => treatmentGroupValue.checked)
    .map((treatmentGroupValue) => {
      const treatmentGroup = treatmentGroups.find(
        (treatmentGroup) => treatmentGroup.id === treatmentGroupValue.treatmentGroupId
      );
      return treatmentGroup?.treatmentGroupIndex ?? 0;
    });

  if (intersection(groupIndices, selectedTreatmentGroupIndices).length !== groupIndices.length) {
    return `you specified groups ${groupIndices.toString()} but the selected groups only include ${selectedTreatmentGroupIndices.toString()}`;
  }
}

export function validateDose(
  value: string,
  formValues: BioanalysisFormData,
  collectionScheduleIndex: number,
  treatmentGroups: TreatmentGroupWithTreatment[]
): string | undefined {
  const { collectionSchedules, treatmentGroups: treatmentGroupsValue } = formValues;

  const collectionScheduleValue = collectionSchedules[collectionScheduleIndex];

  if (
    !collectionScheduleValue.isDefault &&
    !rangeRegex.test(collectionScheduleValue.onlyGroupsString.replaceAll(/\s/g, ''))
  ) {
    return 'please provide a valid value is provided for the groups';
  }

  const onlyGroups = rangeStringToNumberArray(collectionScheduleValue.onlyGroupsString);

  const doseNumbers = treatmentGroupsValue
    .filter((treatmentGroupValue) => treatmentGroupValue.checked)
    .map((treatmentGroupValue) => {
      const treatmentGroup = treatmentGroups.find(
        (treatmentGroup) => treatmentGroup.id === treatmentGroupValue.treatmentGroupId
      );

      if (
        isNil(treatmentGroup) ||
        (!collectionScheduleValue.isDefault && !onlyGroups.includes(treatmentGroup.treatmentGroupIndex))
      ) {
        return 0;
      }

      return (
        treatmentGroup.treatment.testArticles.find(
          (testArticle) => testArticle.id.toString() === treatmentGroupValue.baseTestArticleId
        )?.doseNumber ?? 0
      );
    });

  const maxDoses = Math.max(...doseNumbers, 0);

  if (maxDoses === 0) {
    return 'please select at least one treatment group and corresponding basis test article';
  }

  if (Number(value) > maxDoses) {
    return `you specified a dose of ${value} but the maximum number of doses specified for the selected groups is ${maxDoses}`;
  }
}
