import AddIcon from '@mui/icons-material/Add';
import { AppBar, Box, Stack, Toolbar, type SxProps, type Theme } from '@mui/material';
import { isNil } from 'lodash';
import { useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { HandleNetworkLifecycle, OmniForm, OmniIntegerField, OmniSelectField } from '#components/widgets';
import { useListContentsQuery, type BloodBioanalysisFragment } from '#graphql';
import { i18n } from '#lib/constants';
import { type TreatmentGroupWithTreatment } from '#lib/types';
import { Button, LoadingButton } from '#rds';
import { CollectionSchedulesFormSection, TreatmentGroupsFormSection } from '../Bioanalysis';
import {
  BLOOD_METHODS,
  BLOOD_TYPES,
  buildDefaultValues,
  buildTypeA,
  type BloodBioanalysisFormData,
} from './bloodBioanalysis';

const appBarStyles: SxProps<Theme> = {
  backgroundColor: 'rgba(229,243,236, .2)', // Primary P98
  color: (theme) => theme.palette.text.primary,
};

interface BloodBioanalysisFormProps {
  bloodBioanalysis?: BloodBioanalysisFragment;
  handleSubmit?: (data: BloodBioanalysisFormData) => Promise<void> | void;
  onCancel?: () => void;
  onSuccess?: () => void;
  treatmentGroups: TreatmentGroupWithTreatment[];
}

export function BloodBioanalysisForm(props: BloodBioanalysisFormProps) {
  const {
    bloodBioanalysis,
    handleSubmit = () => undefined,
    onCancel = () => undefined,
    onSuccess = () => undefined,
    treatmentGroups,
  } = props;

  const { data, error, loading } = useListContentsQuery({
    variables: {
      category: 'bioanalysisTimepoint',
    },
    fetchPolicy: 'cache-and-network',
  });

  const methods = useForm<BloodBioanalysisFormData>({
    defaultValues: buildDefaultValues(treatmentGroups, bloodBioanalysis),
  });

  const populateWithTypeA = useCallback(() => {
    methods.reset(buildTypeA(treatmentGroups), { keepDefaultValues: true });
  }, [methods.reset]);

  const isNew = isNil(bloodBioanalysis?.id);

  return (
    <HandleNetworkLifecycle
      data={data?.listContents.items}
      loading={loading}
      error={error}
      render={({ data: bioanalysisTimepoints }) => (
        <FormProvider {...methods}>
          <OmniForm
            formText={i18n.BloodBioanalysis.Form}
            handleSubmit={handleSubmit}
            hideActions
            isNew={isNew}
            onCancel={onCancel}
            onSuccess={onSuccess}
          >
            <Stack spacing={4} paddingX={1} paddingY={3}>
              <Stack spacing={3}>
                <Box>
                  <Button variant="contained" onClick={populateWithTypeA} startIcon={<AddIcon />}>
                    Populate with Type A
                  </Button>
                </Box>

                <Box width={250}>
                  <OmniSelectField
                    name="bloodType"
                    hideErrorMessage
                    selectOptions={BLOOD_TYPES}
                    required
                    rules={{
                      required: 'please select the blood type',
                    }}
                  />
                </Box>

                <Box width={450}>
                  <OmniSelectField
                    hideErrorMessage
                    name="methods"
                    label="Methods(s)"
                    selectOptions={BLOOD_METHODS}
                    multiple
                    required
                    rules={{
                      required: 'please select at least one method',
                    }}
                  />
                </Box>

                <Box width={250}>
                  <OmniIntegerField
                    hideErrorMessage
                    label="Final Sample Amount (μl)"
                    name="sampleAmount"
                    required
                    rules={{
                      required: 'please enter the sample amount',
                    }}
                  />
                </Box>
              </Stack>

              <TreatmentGroupsFormSection treatmentGroups={treatmentGroups} />

              <CollectionSchedulesFormSection
                treatmentGroups={treatmentGroups}
                bioanalysisTimepoints={bioanalysisTimepoints}
              />
            </Stack>

            <AppBar position="absolute" sx={{ top: 'auto', bottom: 0, ...appBarStyles }}>
              <Toolbar sx={{ gap: 2 }}>
                <LoadingButton
                  variant="contained"
                  type="submit"
                  disableElevation={true}
                  loading={methods.formState.isSubmitting}
                  disabled={!methods.formState.isDirty}
                >
                  {i18n.BloodBioanalysis.Form.Submit[isNew ? 'Add' : 'Update']}
                </LoadingButton>
                <Button variant="outlined" onClick={onCancel}>
                  Cancel
                </Button>
              </Toolbar>
            </AppBar>
          </OmniForm>
        </FormProvider>
      )}
    />
  );
}
