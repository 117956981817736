import { capitalize, map, sortBy } from 'lodash';
import { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useAllProgramsQuery, useListContentsQuery } from '#graphql';
import { OmniForm, type OmniFormFieldProps } from '#components/widgets';
import { i18n } from '#lib/constants';

export interface ExperimentFormData {
  IACUC: string;
  project: string;
  type: string;
}

interface ExperimentFormProps {
  handleSubmit?: (data: ExperimentFormData) => Promise<void> | void;
}

export function ExperimentForm(props: ExperimentFormProps) {
  const { handleSubmit = () => undefined } = props;

  const typesQueryResult = useListContentsQuery({
    variables: {
      category: 'experimentType',
    },
    fetchPolicy: 'cache-first',
  });

  const protocolsQueryResult = useListContentsQuery({
    variables: {
      category: 'IACUC',
    },
    fetchPolicy: 'cache-first',
  });

  const programsQueryResult = useAllProgramsQuery();

  const programs = useMemo(() => {
    return map(programsQueryResult.data?.programs.items, ({ name }) => ({
      label: name,
      value: name,
    }));
  }, [programsQueryResult]);

  const protocols = useMemo(() => {
    return sortBy(map(protocolsQueryResult.data?.listContents.items, 'name'), (protocol) =>
      Number(protocol.split('-')[0].trim())
    ).map((protocol) => ({
      label: protocol,
      value: protocol,
    }));
  }, [protocolsQueryResult]);

  const types = useMemo(() => {
    return map(typesQueryResult.data?.listContents.items, (type) => ({
      label: capitalize(type.name),
      value: type.name,
    }));
  }, [typesQueryResult]);

  const methods = useForm<ExperimentFormData>({
    defaultValues: {
      IACUC: '',
      project: '',
      type: '',
    },
  });

  const formFields: Array<OmniFormFieldProps<ExperimentFormData>> = [
    {
      colSpan: 2,
      label: 'Program',
      name: 'project',
      rules: { required: 'Program is required' },
      select: true,
      selectOptions: programs,
    },
    {
      colSpan: 2,
      label: 'IACUC protocol',
      name: 'IACUC',
      rules: { required: 'IACUC protocol is required' },
      select: true,
      selectOptions: protocols,
      sort: false,
    },
    {
      colSpan: 2,
      label: 'Experiment type',
      name: 'type',
      rules: { required: 'Experiment type is required' },
      select: true,
      selectOptions: types,
    },
  ];

  return (
    <FormProvider {...methods}>
      <OmniForm fields={formFields} formText={i18n.Experiments.Form} handleSubmit={handleSubmit} isNew={true} />
    </FormProvider>
  );
}
