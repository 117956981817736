"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calcNotEmpty = exports.calcTrue = exports.nanToUndefined = exports.getValidatingArrayFunction = exports.ops = exports.comparable = exports.bothNumbers = exports.filterArrayForNumbers = void 0;
const lodash_1 = require("lodash");
const filterArrayForNumbers = (a) => a.filter((el) => typeof el === 'number');
exports.filterArrayForNumbers = filterArrayForNumbers;
const bothNumbers = (a, b) => (0, lodash_1.isNumber)(a) && (0, lodash_1.isNumber)(b);
exports.bothNumbers = bothNumbers;
const comparable = (a, b) => ((0, lodash_1.isNumber)(a) && (0, lodash_1.isNumber)(b)) || ((0, lodash_1.isString)(a) && (0, lodash_1.isString)(b));
exports.comparable = comparable;
exports.ops = {
    '-': (a, b) => ((0, exports.bothNumbers)(a, b) ? a - b : undefined),
    '+': (a, b) => ((0, exports.bothNumbers)(a, b) ? a + b : undefined),
    '*': (a, b) => ((0, exports.bothNumbers)(a, b) ? a * b : undefined),
    '/': (a, b) => ((0, exports.bothNumbers)(a, b) ? a / b : undefined),
};
const getValidatingArrayFunction = (f) => (a) => Array.isArray(a) ? (0, exports.nanToUndefined)(f((0, exports.filterArrayForNumbers)(a))) : undefined;
exports.getValidatingArrayFunction = getValidatingArrayFunction;
const nanToUndefined = (a) => !(0, lodash_1.isNumber)(a) || isNaN(a) ? undefined : a;
exports.nanToUndefined = nanToUndefined;
// using Ruby-Truth for calc
//  1. false, null and undefined are false
//  2. everything else is true
//  NOTE: 0 and '' are TRUE, unlike normal JS)
//  https://www.ruby-lang.org/en/documentation/ruby-from-other-languages/
const calcTrue = (a) => a !== false && a !== null && a !== undefined;
exports.calcTrue = calcTrue;
function calcNotEmpty(a) {
    return (a !== null &&
        a !== undefined &&
        (typeof a === 'object' ? !(0, lodash_1.isEmpty)(a) : true) &&
        a !== '');
}
exports.calcNotEmpty = calcNotEmpty;
