import styled from '@emotion/styled';
import { DateTime } from 'luxon';

const DateLabelStyled = styled.div({
  color: '#6F6F6F',
  fontSize: '12px',
});

export const DateLabel = ({ updatedAt }: { updatedAt?: string }) => {
  if (!updatedAt) return null;
  const newDate = DateTime.fromISO(updatedAt).toFormat('LL/dd/yyyy');
  return <DateLabelStyled>{newDate}</DateLabelStyled>;
};
