import { ApolloError } from '@apollo/client';
import { Box, Divider, Grid, Paper, Stack, Typography } from '@mui/material';
import { get, isNil } from 'lodash';
import { useFormContext, type FieldValues, type Path, type SubmitHandler } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router';

import { handleApolloFieldErrors } from '#lib/utils';
import { Button, LoadingButton } from '#rds';
import { type ReactNode } from 'react';
import { OmniField, type OmniFieldProps } from './OmniField';

type Columns = 1 | 2 | 3 | 4;

function gridWidth(gridColumns: Columns, colSpan: Columns = 1) {
  if (colSpan >= gridColumns) {
    return 12;
  }

  return Math.round((colSpan / gridColumns) * 12);
}

export type OmniFormFieldProps<TFormData extends FieldValues> = OmniFieldProps<TFormData> & {
  colSpan?: Columns;
};

export interface FormText {
  Description?: string;
  Error: { Add: string; Update: string };
  Submit: { Add: string; Update: string };
  Success: { Add: string; Update: string };
  Title?: { Add: string; Update: string };
}

export interface OmniFormProps<TFormData extends FieldValues> {
  children?: ReactNode;
  fields?: Array<OmniFormFieldProps<TFormData>>;
  formText: FormText;
  gridColumns?: Columns;
  handleSubmit: SubmitHandler<TFormData>;
  hideActions?: boolean;
  isNew?: boolean;
  onCancel?: () => void;
  onSuccess?: () => void;
  successNavigation?: string;
}

export function OmniForm<TFormData extends FieldValues>(props: OmniFormProps<TFormData>) {
  const {
    children,
    fields,
    formText,
    gridColumns = 2,
    handleSubmit,
    hideActions = false,
    isNew = false,
    onCancel = () => {
      navigate(-1);
    },
    onSuccess = () => undefined,
    successNavigation,
  } = props;

  const navigate = useNavigate();
  const methods = useFormContext<TFormData>();

  const addOrUpdate = isNew ? 'Add' : 'Update';

  function setError(path: string, message: string) {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    methods.setError(path as Path<TFormData>, { message });
  }

  function setServerError(message: string) {
    setError('root.serverError', message);
  }

  function clearServerError() {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    methods.clearErrors('root.serverError' as Path<TFormData>);
  }

  async function submitHandler(data: TFormData) {
    try {
      await handleSubmit(data);
      toast.success(formText.Success[addOrUpdate]);
      if (!isNil(successNavigation)) {
        navigate(successNavigation);
      } else {
        onSuccess();
      }
    } catch (error: unknown) {
      toast.error(formText.Error[addOrUpdate]);

      if (error instanceof ApolloError) {
        for (const [field, message] of handleApolloFieldErrors(error)) {
          if (field === 'root') {
            setServerError(message);
          } else {
            setError(field, message);
          }
        }
      } else if (error instanceof Error) {
        setServerError(error.message);
      } else {
        throw error;
      }
    }
  }

  function onSubmit(e: React.BaseSyntheticEvent) {
    clearServerError();
    void methods.handleSubmit(submitHandler)(e);
  }

  const serverError = get(methods.formState.errors, 'root.serverError.message');

  const title = formText.Title?.[addOrUpdate];

  return (
    <Stack spacing={3}>
      {!isNil(title) && <Typography variant="h2">{title}</Typography>}
      <form onSubmit={onSubmit}>
        <Paper elevation={0} variant={!isNil(title) ? 'outlined' : undefined} sx={{ p: !isNil(title) ? 2 : 0 }}>
          <Box padding={2}>
            <Grid container spacing={2}>
              {!isNil(formText.Description ?? serverError) && (
                <Grid item xs={12}>
                  <Typography variant="body1" py={1}>
                    {formText.Description}
                  </Typography>

                  {!isNil(serverError) && (
                    <Typography variant="body1" color="red">
                      {serverError}
                    </Typography>
                  )}
                </Grid>
              )}
              {fields?.map((field) => (
                <Grid item xs={gridWidth(gridColumns, field.colSpan)} key={field.name}>
                  <OmniField {...field} />
                </Grid>
              ))}
            </Grid>
            {children}
          </Box>
        </Paper>

        {!hideActions && (
          <>
            <Divider />

            <Box display="flex" gap={2} paddingTop={3}>
              <LoadingButton
                variant="contained"
                type="submit"
                disableElevation={true}
                loading={methods.formState.isSubmitting}
                disabled={!methods.formState.isDirty}
              >
                {formText.Submit[addOrUpdate]}
              </LoadingButton>
              <Button variant="outlined" onClick={onCancel}>
                Cancel
              </Button>
            </Box>
          </>
        )}
      </form>
    </Stack>
  );
}
