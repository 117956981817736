import { FileValidated } from '@dropzone-ui/react';

import {
  User,
  UserBasicInfoFragment,
  ExperimentBasicInfoFragment,
  ExperimentDetailsFragment,
  ExperimentState,
  Roles,
} from '#graphql';
import { uploadAttachment } from '../api';
import { HTTP } from '#lib/constants';
import { GraphQLError } from 'graphql';

export const getApprovalTextButton = (state: ExperimentState) => {
  switch (state) {
    case 'Review':
      return 'Approve';

    default:
      return 'Submit for approval';
  }
};

export const getApprovalNameDefaultText = (state: ExperimentState) =>
  state === 'Review'
    ? 'Please, assign name before approval'
    : 'Pending for approval...';

export const uploadFiles = async (
  experimentId: number,
  files: FileValidated[],
  category: string
): Promise<{
  success: { id: number; filePath: string }[];
  errors: string[];
}> => {
  let errors: string[] = [];
  const results = await Promise.all(
    files.map(async (file) => {
      const formData = new FormData();
      formData.append('', file.file);
      const response = await uploadAttachment(
        experimentId,
        file.file.name,
        category,
        formData
      );
      if (response.status !== HTTP.CREATED) {
        errors.push(await response.text());
      } else {
        return response.json();
      }
    })
  );
  if (errors.length) {
    errors = errors.map(
      (e, i) => e || `Upload error for: ${files[i].file.name}`
    );
  }
  return { success: results.filter((r) => r), errors };
};

export const getFileExtension = (filename: string) =>
  filename.split('.').pop()?.toUpperCase();

export const getErrorMessage = (error: GraphQLError) => {
  switch (error.extensions.code) {
    case 'FORBIDDEN':
      return "Your don't have access to edit this experiment, contact an administrator for futher information.";

    default:
      return 'Access Denied, contact an administrator for more information';
  }
};
