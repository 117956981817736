import { Navigate, Route, Routes } from 'react-router';
import { NotFound } from '#components/pages';
import { CanRoute } from '#components/widgets';
import { AnimalInventoryPage } from './AnimalInventoryPage';
import { Experiments } from './Experiments';
import {
  PurchaseOrders,
  CreatePurchaseOrder,
  EditPurchaseOrder,
} from './PurchaseOrders';
import {
  Reservations,
  CreateReservation,
  EditReservation,
} from './Reservations';

export function AnimalInventoryRoutes() {
  return (
    <Routes>
      <Route path="/" element={<AnimalInventoryPage />}>
        <Route
          index
          element={<Navigate to="purchase-orders" replace={true} />}
        />

        <Route
          path="purchase-orders"
          element={
            <CanRoute I="list" an="inventory">
              <PurchaseOrders />
            </CanRoute>
          }
        />

        <Route
          path="reservations"
          element={
            <CanRoute I="list" an="inventoryRequest">
              <Reservations />
            </CanRoute>
          }
        />

        <Route
          path="experiments"
          element={
            <CanRoute I="list" an="inventoryRequest">
              <Experiments />
            </CanRoute>
          }
        />

        <Route
          path="purchase-orders/create"
          element={
            <CanRoute I="create" an="inventory">
              <CreatePurchaseOrder />
            </CanRoute>
          }
        />

        <Route
          path="purchase-orders/:id/edit"
          element={
            <CanRoute I="update" an="inventory">
              <EditPurchaseOrder />
            </CanRoute>
          }
        />

        <Route
          path="reservations/create"
          element={
            <CanRoute I="create" an="inventoryRequest">
              <CreateReservation />
            </CanRoute>
          }
        />

        <Route
          path="reservations/:id/edit"
          element={
            <CanRoute I="update" an="inventoryRequest">
              <EditReservation />
            </CanRoute>
          }
        />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}
