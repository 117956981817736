import { Container } from '@mui/material';
import { omit } from 'lodash';
import { useCreateInventoryRequestMutation } from '#graphql';
import { ReservationForm, ReservationFormData } from './ReservationForm';

export function CreateReservation() {
  const [createInventoryRequest] = useCreateInventoryRequestMutation();

  async function handleSubmit(data: ReservationFormData) {
    await createInventoryRequest({
      variables: {
        input: {
          ...omit(data, ['strain']),
          experimentId: parseInt(data.experimentId),
          inventoryId: parseInt(data.inventoryId),
          quantity: parseInt(data.quantity),
          status: data.status!,
        },
      },
    });
  }

  return (
    <Container maxWidth="md">
      <ReservationForm handleSubmit={handleSubmit} />
    </Container>
  );
}
