import MoreTimeIcon from '@mui/icons-material/MoreTime';
import { Button, buttonClasses } from '@mui/material';

const sx = {
  [`&.${buttonClasses.root}`]: {
    backgroundColor: 'white',
    height: '40px',
    minWidth: 'auto',
    padding: '0',
    width: '40px',
  },
};

interface SetCurrentTimeButtonProps {
  disabled?: boolean;
  setCurrentTime: () => void;
}

export function SetCurrentTimeButton(props: SetCurrentTimeButtonProps) {
  const { disabled = false, setCurrentTime } = props;

  return (
    <Button //
      aria-label="set time"
      color="inherit"
      disabled={disabled}
      onClick={setCurrentTime}
      size="small"
      sx={sx}
      variant="outlined"
    >
      <MoreTimeIcon />
    </Button>
  );
}
