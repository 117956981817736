import { useApolloClient, ApolloError, type FetchResult } from '@apollo/client';
import { type GridRowId } from '@mui/x-data-grid-premium';
import { isNil } from 'lodash';
import { set } from 'lodash/fp';
import { type DateTime } from 'luxon';
import { useCallback, useEffect, useState } from 'react';

import {
  UpdatePkTissueWorksheetCollectionActualTimeDocument,
  UpdatePkTissueWorksheetHandlingAndStorageDocument,
  useUpdatePkTissueWorksheetCollectionActualTimesMutation,
} from '#graphql';
import { type DetailRow } from './DetailPanel';

export function useDetailPanel(dataProp: DetailRow[]) {
  const apolloClient = useApolloClient();

  const [updateActualTimes, { error, loading }] = useUpdatePkTissueWorksheetCollectionActualTimesMutation();

  const [data, setData] = useState(dataProp);
  const [updates, setUpdates] = useState<number[]>([]);
  const [mutationStatuses, setMutationStatuses] = useState({});

  async function handleMutation(mutation: Promise<FetchResult>, rowId: GridRowId, key: string) {
    setMutationStatuses((state) => set([rowId, key], { error: undefined, loading: true }, state));

    let error: ApolloError | undefined;

    try {
      const { errors } = await mutation;
      if (!isNil(errors) && errors.length > 0) {
        error = new ApolloError({ graphQLErrors: errors });
      }
    } catch (e: unknown) {
      if (e instanceof ApolloError) {
        error = e;
      } else if (e instanceof Error) {
        error = new ApolloError({ errorMessage: e.message });
      } else {
        error = new ApolloError({ errorMessage: 'Unknown Error' });
      }
    }

    setMutationStatuses((state) => set([rowId, key], { error, loading: false }, state));
  }

  const updateActualTime = useCallback(async (rowId: GridRowId, value: DateTime | null) => {
    const actualTime = isNil(value) ? value : value.toISOTime() ?? '';
    const mutation = apolloClient.mutate({
      mutation: UpdatePkTissueWorksheetCollectionActualTimeDocument,
      variables: {
        input: {
          id: Number(rowId),
          actualTime,
        },
      },
    });
    await handleMutation(mutation, rowId, 'updateActualTimeStatus');
  }, []);

  const updateHandlingAndStorage = useCallback(
    async (rowId: GridRowId, handlingAndStorageId: number, collected: boolean, weight: number | null) => {
      const mutation = apolloClient.mutate({
        mutation: UpdatePkTissueWorksheetHandlingAndStorageDocument,
        variables: {
          input: {
            id: handlingAndStorageId,
            collected,
            weight,
          },
        },
      });
      await handleMutation(mutation, rowId, 'updateHandlingAndStorageStatus');
    },
    []
  );

  async function updateHandlingAndStorageCollected(rowId: GridRowId, handlingAndStorageId: number, collected: boolean) {
    await updateHandlingAndStorage(rowId, handlingAndStorageId, collected, null);
  }

  async function updateHandlingAndStorageWeight(rowId: GridRowId, handlingAndStorageId: number, weight: number | null) {
    const collected = !isNil(weight);
    await updateHandlingAndStorage(rowId, handlingAndStorageId, collected, weight);
  }

  useEffect(() => {
    const updates = data
      .filter((current) => {
        const incoming = dataProp.find((incoming) => incoming.id === current.id);
        return incoming?.expectedTime !== current.expectedTime;
      })
      .map((updated) => updated.id);

    setUpdates(updates);
    setData(dataProp);
  }, [dataProp]);

  return {
    data,
    updateActualTimes,
    context: {
      error,
      loading,
      updates,
      mutationStatuses,
      updateActualTime,
      updateHandlingAndStorageCollected,
      updateHandlingAndStorageWeight,
    },
  };
}
