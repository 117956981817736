import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import bgImage from '#assets/login-bg-image.png';
import { AppColors, FAQ_URL, i18n } from '#lib/constants';
import { Stack } from '@mui/material';

const StyledContainer = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundImage: `url(${bgImage})`,
  height: '100vh',
});

const StyledBox = styled(Stack)({
  width: '500px',
  fontSize: '20px',
  background: AppColors.WHITE,
  borderRadius: '4px',
  boxShadow: '2px 2px 6px rgba(0,0,0,0.2)',
  margin: '100px auto',
  textAlign: 'center',
});

const StyledTitle = styled.div({
  marginBottom: '10px',
  fontWeight: 'bold',
});

const StyledSubTitleText = styled.div({
  fontFamily: 'Roboto',
  fontSize: '14px',
  fontWeight: '400',
  color: AppColors.GREY,
});

export const NoAccessPage = () => {
  return (
    <StyledContainer>
      <StyledBox spacing={3} padding={3}>
        <StyledTitle>You don&apos;t have access to {i18n.AppName}.</StyledTitle>
        <StyledSubTitleText>
          Please see the{' '}
          <a href={FAQ_URL} target="_blank" rel="noopener noreferrer">
            FAQ
          </a>{' '}
          for more information on {i18n.AppName} and how to request access.
        </StyledSubTitleText>
        <StyledSubTitleText>
          Recently granted access? Try <Link to="/login">logging in</Link>.
        </StyledSubTitleText>
      </StyledBox>
    </StyledContainer>
  );
};
