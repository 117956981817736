import CloseIcon from '@mui/icons-material/Close';
import { AppBar, Drawer, IconButton, Toolbar, Typography, type SxProps, type Theme, Box } from '@mui/material';
import { type PropsWithChildren } from 'react';

const appBarStyles: SxProps<Theme> = {
  backgroundColor: 'rgba(229,243,236, .2)', // Primary P98
  color: (theme) => theme.palette.text.primary,
};

interface OmniDrawerProps {
  large?: boolean;
  onClose: () => void;
  open: boolean;
  title: string;
}

export function OmniDrawer(props: PropsWithChildren<OmniDrawerProps>) {
  const { children, large = false, onClose, open, title } = props;

  const drawerStyles: SxProps<Theme> = {
    '& .MuiDrawer-paper': {
      width: large ? '80%' : 'auto',
    },
  };

  return (
    <Drawer anchor="right" open={open} onClose={onClose} sx={drawerStyles}>
      <AppBar position="absolute" sx={appBarStyles}>
        <Toolbar sx={{ justifyContent: 'center' }}>
          <IconButton aria-label="close" onClick={onClose} sx={{ position: 'absolute', left: 24, paddingLeft: 0 }}>
            <CloseIcon />
          </IconButton>
          <Typography fontSize="1.5rem">{title}</Typography>
        </Toolbar>
      </AppBar>
      <Toolbar />
      <Box padding={0} sx={{ overflowY: 'auto' }}>
        {children}
      </Box>
      <Toolbar />
    </Drawer>
  );
}
