import { NameBubble, OmniGrid } from '#components/widgets';
import {
  GridColDef,
  GridRenderCellParams,
  GridRowId,
} from '@mui/x-data-grid-premium';
import { useNavigate } from 'react-router';
import { useAbility } from '@casl/react';
import { UserDetailsFragment, useAllUsersLazyQuery } from '#graphql';
import { AbilityContext } from '#components/contexts';
import { UserColumns } from '#lib/types';

const columns: GridColDef<UserDetailsFragment>[] = [
  {
    disableColumnMenu: true,
    field: 'name',
    filterable: false,
    flex: 2,
    headerName: UserColumns.Name,
    renderCell: (params: GridRenderCellParams<UserDetailsFragment>) => (
      <NameBubble user={params.row} />
    ),
    sortable: false,
  },
  {
    disableColumnMenu: true,
    field: 'role',
    filterable: false,
    flex: 1,
    headerName: UserColumns.Role,
    valueGetter: (params) => params.row.userRole.name,
    sortable: false,
  },
  {
    disableColumnMenu: true,
    field: 'email',
    filterable: false,
    flex: 2,
    headerName: UserColumns.Email,
    sortable: false,
  },
];

export function Users() {
  const navigate = useNavigate();
  const ability = useAbility(AbilityContext);
  const [getUsers, { data, error, loading, previousData }] =
    useAllUsersLazyQuery();

  const currentData = data?.users ?? previousData?.users;

  const rows = currentData?.items ?? [];
  const rowCount = currentData?.totalCount ?? 0;

  const handleEdit = (id: GridRowId) => navigate(`/users/${id}/edit`);

  return (
    <OmniGrid
      columns={columns}
      defaultSort={[{ field: 'email', sort: 'asc' }]}
      error={error}
      loading={loading}
      refetch={getUsers}
      rows={rows}
      rowCount={rowCount}
      showEditButton={true}
      editButtonProps={{ handleEdit }}
      headerProps={{
        title: 'Users',
        showAddButton: ability.can('create', 'user'),
        addButtonProps: {
          label: 'Add User',
          to: '/users/create',
        },
      }}
      showQuickFilter={true}
      dataGridProps={{
        disableColumnFilter: true,
      }}
    />
  );
}
