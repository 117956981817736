import { canDeliverAdcOrderItem, isAdcOrderItemDelivered } from '#lib/adcOrder';
import { isNil } from 'lodash';
import { z } from 'zod';

import { UploadError } from './uploadError';
import { getAdcOrderItemForRow, type AdcOrderItemForUpload } from './common';

const floatSchema = (maxValue: number) =>
  z
    .string()
    .transform((value) => value.replace(',', ''))
    .pipe(z.coerce.number({ invalid_type_error: 'Could not parse number' }).nonnegative().lte(maxValue));

const emptyStringSchema = z
  .string()
  .length(0, { message: 'Could not parse number' })
  .transform(() => null);

const adcOrderItemSchema = z.object({
  id: z
    .string({ required_error: 'Id is required' })
    .pipe(z.coerce.number({ invalid_type_error: 'Could not parse number' }).int().positive()),
  experimentName: z
    .string({ required_error: 'Experiment Name is required' })
    .min(1, { message: 'Experiment Name is required' }),
  purpose: z.string({ required_error: 'Purpose is required' }).min(1, { message: 'Purpose is required' }),
  bioregId: z.string({ required_error: 'Bioreg Id is required' }).min(1, { message: 'Bioreg Id is required' }),
  deliveredAmount: z.union([emptyStringSchema, floatSchema(9999.99)]).optional(),
  deliveredConcentration: z.union([emptyStringSchema, floatSchema(99.99)]).optional(),
  formulation: z.string({ required_error: 'Formulation is required' }).min(1, { message: 'Formulation is required' }),
  batchNumber: z.string({ required_error: 'Batch Number is required' }).min(1, { message: 'Batch Number is required' }),
  a280: z.union([emptyStringSchema, floatSchema(99.99)]).optional(),
});

const adcOrderItemHeaders = adcOrderItemSchema.keyof().Values;
export type AdcOrderItemHeaders = keyof typeof adcOrderItemHeaders;

export type AdcOrderItemDataRaw = z.input<typeof adcOrderItemSchema>;

export async function parseCsvData(
  adcOrderItemCsvData: AdcOrderItemDataRaw[],
  adcOrderItems: AdcOrderItemForUpload[],
  experimentName: string
) {
  const adcOrderItemCsvSchema = refineAdcOrderItemSchema(adcOrderItemSchema, adcOrderItems, experimentName);

  const result = await adcOrderItemCsvSchema.safeParseAsync(adcOrderItemCsvData);

  if (!result.success) {
    throw zodErrorToUploadError(result.error);
  }

  return result.data;
}

function refineAdcOrderItemSchema(
  schema: typeof adcOrderItemSchema,
  adcOrderItems: AdcOrderItemForUpload[],
  experimentName: string
) {
  return z
    .array(
      schema
        .refine(
          (data) => data.experimentName === experimentName,
          (data) => ({
            message: `Expected experiment name "${experimentName}" but was "${data.experimentName}"`,
            path: ['experimentName'],
          })
        )
        .refine(
          ({ bioregId, batchNumber }) => new RegExp(`^${bioregId}-\\d{2,3}$`).test(batchNumber),
          ({ bioregId, batchNumber }) => ({
            message: `Batch number must be formatted "${bioregId}-xdd", but is ${batchNumber}`,
            path: ['batchNumber'],
          })
        )
        .refine(
          (data) => !isNil(getAdcOrderItemForRow(adcOrderItems, data)),
          ({ bioregId, id, purpose }) => ({
            message: `No matching adc order item for Bioreg Id: ${bioregId}, Purpose: ${purpose}, Id: ${id}`,
            path: ['bioregId'],
          })
        )
        .refine(
          (data) => {
            const adcOrderItem = getAdcOrderItemForRow(adcOrderItems, data);

            if (isNil(adcOrderItem)) {
              // already handled above
              return true;
            }

            return isAdcOrderItemDelivered(adcOrderItem) ? canDeliverAdcOrderItem(data) : true;
          },
          {
            message: 'Matching adc order item is delivered but the upload is missing required data',
            path: ['bioregId'],
          }
        )
    )
    .nonempty({ message: 'Uploaded file cannot be empty' });
}

function zodErrorToUploadError(error: z.ZodError) {
  const messages = error.issues.map((issue) => {
    const {
      message,
      path: [rowNumber, column],
    } = issue;

    if (!isNil(rowNumber) && !isNil(column)) {
      return {
        row: Number(rowNumber) + 1,
        column,
        message,
      };
    } else {
      return message;
    }
  });

  return new UploadError(messages, { cause: error });
}
