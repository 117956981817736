import {
  type BloodBioanalysisFragment,
  type CreateBloodBioanalysisInput,
  type UpdateBloodBioanalysisInput,
} from '#graphql';
import { type TreatmentGroupWithTreatment } from '#lib/types';
import {
  formDataToInput as bioanalysisFormDataToInput,
  buildDefaultCollectionSchedulesValue,
  buildDefaultTreatmentGroupsValue,
  type BioanalysisFormData,
} from '../Bioanalysis';

export interface BloodBioanalysisFormData extends BioanalysisFormData {
  methods: string[];
  otherMethods: string;
  sampleAmount: string;
  bloodType: string;
}

export const BLOOD_TYPES = [
  { value: 'wholeBlood', label: 'Whole blood (unprocessed)' },
  { value: 'serum', label: 'Serum' },
  { value: 'plasma', label: 'Plasma' },
];

export const BLOOD_METHODS = [
  {
    value: 'gTAb',
    label: 'gTAb Gyros (min plasma = 15 uL)',
  },
  {
    value: 'acDRUG',
    label: 'acDRUG (min plasma = 30uL)',
  },
  {
    value: 'gADC',
    label: 'gADC Gyros (min Plasma = 15 UL)',
  },
  {
    value: 'lbaCba',
    label: 'Custom LBA/CBA (add pre-dose)',
  },
  {
    value: 'ivigs',
    label: 'Specific (ivigs) Multiplexed Assay (Peptide sTAb + acDrug; min plasma = 30 uL)',
  },
  {
    value: 'sTAb',
    label: 'sTAb LBA',
  },
  {
    value: 'unconjugatedMmae',
    label: 'Unconjugated MMAE (min plasma = 30 uL)',
  },
  {
    value: 'ada',
    label: 'ADA (add pre-dose)',
  },
  {
    value: 'sAntigen',
    label: 'sAntigen/Biomarker (add pre-dose)',
  },
  {
    value: 'generic',
    label: 'Multiplex Assay (peptide-based gTAb/sTAB, acDrug) ',
  },
  {
    value: 'unconjugatedDrug',
    label: 'Unconjugated drug (other payload, min plasma = 60 uL)',
  },
];

export function bloodTypeLabel(bloodTypeValue: string) {
  return BLOOD_TYPES.find((type) => type.value === bloodTypeValue)?.label;
}

export function bloodMethodLabel(bloodMethodValue: string) {
  return BLOOD_METHODS.find((method) => method.value === bloodMethodValue)?.label;
}

export function formDataToCreateInput(
  experimentId: number,
  data: BloodBioanalysisFormData
): CreateBloodBioanalysisInput {
  return {
    ...formDataToInput(data),
    experimentId,
  };
}

export function formDataToUpdateInput(id: number, data: BloodBioanalysisFormData): UpdateBloodBioanalysisInput {
  return {
    ...formDataToInput(data),
    id,
  };
}

export function formDataToInput(data: BloodBioanalysisFormData) {
  return {
    ...data,
    sampleAmount: Number(data.sampleAmount),
    ...bioanalysisFormDataToInput(data),
  };
}

export function buildDefaultValues(
  treatmentGroups: TreatmentGroupWithTreatment[],
  bloodBioanalysis?: BloodBioanalysisFragment
) {
  return {
    methods: bloodBioanalysis?.methods ?? [],
    otherMethods: bloodBioanalysis?.otherMethods ?? '',
    sampleAmount: bloodBioanalysis?.sampleAmount.toString() ?? '',
    bloodType: bloodBioanalysis?.bloodType ?? '',
    treatmentGroups: buildDefaultTreatmentGroupsValue(treatmentGroups, bloodBioanalysis?.treatmentGroups),
    collectionSchedules: buildDefaultCollectionSchedulesValue(bloodBioanalysis?.collectionSchedules),
  };
}

export function buildTypeA(treatmentGroups: TreatmentGroupWithTreatment[]) {
  return {
    methods: [BLOOD_METHODS.find((bm) => bm.value === 'gTAb')?.value ?? ''],
    otherMethods: '',
    sampleAmount: '15',
    bloodType: BLOOD_TYPES.find((bt) => bt.value === 'plasma')?.value ?? '',
    treatmentGroups: treatmentGroups.map((tg) => {
      const checked = !tg.treatment.testArticles.some((testArticle) => testArticle.name.toLowerCase() === 'untreated');
      const baseTestArticleId = checked ? tg.treatment.testArticles[0].id.toString() : '';
      return {
        checked,
        notes: '',
        treatmentGroupId: tg.id,
        baseTestArticleId,
      };
    }),
    collectionSchedules: [
      {
        onlyGroupsString: '',
        isDefault: true,
        timepoints: [
          {
            dose: '1',
            minutes: (3 * 60).toString(),
            onlyAnimalsString: '',
            includeAllAnimals: true,
          },
          {
            dose: '1',
            minutes: (72 * 60).toString(),
            onlyAnimalsString: '',
            includeAllAnimals: true,
          },
          {
            dose: '1',
            minutes: (168 * 60).toString(),
            onlyAnimalsString: '',
            includeAllAnimals: true,
          },
        ],
      },
    ],
  };
}
