import { USER_MANAGEMENT_PATH, TEMPLATE_EDITOR_PATH, TEST_ARTICLES_ROUTE } from '#lib/constants/routes';
import users from '#assets/users.png';
import template from '#assets/template.png';
import cellLine from '#assets/cellLine.png';

export const optionsMenu = [
  {
    id: '1',
    title: 'User management',
    image: users,
    description: 'See all system users, their information and permissions',
    path: USER_MANAGEMENT_PATH,
  },
  {
    id: '2',
    title: 'Template Editor',
    image: template,
    description: 'Add, Delete and Edit Experiment Tags',
    path: TEMPLATE_EDITOR_PATH,
  },
  {
    id: '3',
    title: 'CRO Cell Lines',
    image: cellLine,
    description: 'Add, Delete and Edit CRO Cell Lines',
    path: TEST_ARTICLES_ROUTE,
  },
];
