import { FormHelperText, type TextFieldProps } from '@mui/material';
import { isNil, startCase } from 'lodash';
import { type FieldValues, type UseControllerProps } from 'react-hook-form';

import { ReactHookTextField, type ReactHookTextFieldProps } from '#components/widgets/ReactHookTextField';

export interface OmniTextFieldProps<TFormData extends FieldValues>
  extends Pick<UseControllerProps<TFormData>, 'name' | 'rules'> {
  disabled?: boolean;
  helperText?: string;
  hideErrorMessage?: boolean;
  InputProps?: object;
  label?: string;
  minRows?: number;
  multiline?: boolean;
  required?: boolean;
  type?: TextFieldProps['type'];
  inputProps?: object;
  formatValue?: ReactHookTextFieldProps<TFormData>['formatValue'];
  setValueAs?: ReactHookTextFieldProps<TFormData>['setValueAs'];
}

export function OmniTextField<TFormData extends FieldValues>(props: OmniTextFieldProps<TFormData>) {
  const {
    disabled,
    helperText,
    InputProps,
    hideErrorMessage = false,
    minRows = 3,
    name,
    label,
    multiline = false,
    required = false,
    rules,
    type = 'text',
    inputProps,
    formatValue,
    setValueAs,
  } = props;

  const labelText = `${label ?? startCase(name)} ${required ? '*' : ''}`;

  return (
    <>
      <ReactHookTextField
        collapseHelperText={hideErrorMessage}
        controller={{ name, rules }}
        textField={{
          disabled,
          fullWidth: true,
          id: name,
          InputProps,
          label: labelText,
          multiline,
          minRows,
          type,
          inputProps,
        }}
        formatValue={formatValue}
        setValueAs={setValueAs}
      />
      {!isNil(helperText) && <FormHelperText>{helperText}</FormHelperText>}
    </>
  );
}
