import { isNil } from 'lodash';

import { OmniDrawer } from '#components/widgets/OmniDrawer';
import { type TissueBioanalysisFragment } from '#graphql';
import { type TreatmentGroupWithTreatment } from '#lib/types';
import { CreateTissueBioanalysis } from './CreateTissueBioanalysis';
import { EditTissueBioanalysis } from './EditTissueBioanalysis';

interface TissueBioanalysisModalProps {
  tissueBioanalysis?: TissueBioanalysisFragment;
  onClose: () => void;
  open: boolean;
  experimentId: number;
  treatmentGroups: TreatmentGroupWithTreatment[];
}

export function TissueBioanalysisModal({
  tissueBioanalysis,
  onClose,
  open,
  experimentId,
  treatmentGroups,
}: TissueBioanalysisModalProps) {
  return (
    <OmniDrawer
      large
      open={open}
      onClose={onClose}
      title={`${!isNil(tissueBioanalysis) ? 'Edit' : 'Add'} Tissue Bioanalysis`}
    >
      {tissueBioanalysis != null ? (
        <EditTissueBioanalysis
          tissueBioanalysis={tissueBioanalysis}
          onCancel={onClose}
          onSuccess={onClose}
          treatmentGroups={treatmentGroups}
        />
      ) : (
        <CreateTissueBioanalysis
          experimentId={experimentId}
          onCancel={onClose}
          onSuccess={onClose}
          treatmentGroups={treatmentGroups}
        />
      )}
    </OmniDrawer>
  );
}
