import { type ApolloError } from '@apollo/client';
import { type GridRowId } from '@mui/x-data-grid-premium';
import { type DateTime } from 'luxon';
import { createContext, useContext } from 'react';

interface RowContextValue {
  error: ApolloError | undefined;
  loading: boolean;
  updateActualTime: (rowId: GridRowId, value: DateTime | null) => Promise<void>;
}

const initialValue = {
  error: undefined,
  loading: false,
  updateActualTime: async () => {
    await Promise.resolve();
  },
};

export const RowContext = createContext<RowContextValue>(initialValue);

export const useRowContext = () => useContext(RowContext);
