"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.demoExperimentForWorksheet = void 0;
exports.demoExperimentForWorksheet = {
    treatmentGroupsForWorksheet: [
        {
            treatmentGroupId: 101,
            treatmentGroupName: 'TreatmentGroup 1',
            treatmentGroupIndex: 1,
            treatmentId: 1,
            modelCount: 1,
            animalProps: {},
            hasImaging: false,
            hasBioanalysis: false,
            testArticlesForWorksheet: [],
            modelId: 1,
            adcOrderItemProps: [],
        },
        {
            treatmentGroupId: 102,
            treatmentGroupName: 'TreatmentGroup 2',
            treatmentGroupIndex: 2,
            treatmentId: 2,
            modelCount: 1,
            animalProps: {},
            hasImaging: false,
            hasBioanalysis: false,
            testArticlesForWorksheet: [],
            modelId: 1,
            adcOrderItemProps: [],
        },
    ],
};
