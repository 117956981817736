import styled from '@emotion/styled';
import CheckIcon from '@mui/icons-material/Check';
import DownloadIcon from '@mui/icons-material/Download';
import { AppColors } from '#lib/constants';

export const TestArticleCard = styled.div({
  background: AppColors.WHITE,
  marginBottom: '25px',
  boxShadow: '2px 2px 6px rgba(0, 0, 0, 0.2)',
  border: `2px solid ${AppColors.LIGHT_LIGHT_GREY}`,
  borderRadius: '4px',
});

export const TestArticleCardHeader = styled.div({
  display: 'flex',
  background: '#F1F1F1',
  justifyContent: 'space-between',
});

export const StyledTableLogo = styled.img({
  width: '30px',
  height: '30px',
});

export const HeaderTitle = styled.h4({
  display: 'flex',
  fontSize: '14px',
  alignItems: 'center',
  gap: '2rem',
  marginLeft: '10px',
});

export const TitleContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  marginLeft: '20px',
});

export const IconsContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  fontSize: '11px',
  marginRight: '20px',
});

export const StyledCheckIcon = styled(CheckIcon)({
  cursor: 'pointer',
  fontSize: '22px',
  color: AppColors.BRAND_GREEN,
});

export const StyledDownloadIcon = styled(DownloadIcon)({
  cursor: 'pointer',
  fontSize: '22px',
  color: AppColors.BRAND_GREEN,
});

export const StyledAttachments = styled.div({
  margin: '30px',
  paddingTop: '15px',
  borderTop: `1px solid ${AppColors.GREY3}`,
});
