import { Box, InputAdornment, Paper, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { FormProvider } from 'react-hook-form';
import toast from 'react-hot-toast';
import { compact, partition, sortBy } from 'lodash';

import { type AdcOrderItem, type AdcOrderItemDetailsFragment } from '#graphql';
import { AppColors, i18n } from '#lib/constants';
import { formatBatchNumberSuffixString, formatFloat, ensureBatchNumberSuffix } from '#lib/adcOrder';
import { DefinitionList, FormGrid, type FormGridColDef, ReactHookTextField } from '#components/widgets';

import { useConjugateAdcOrderForm } from './useConjugateAdcOrderForm';
import { FloatField } from './FloatField';
import { DeliverSection } from './DeliverSection';
import { type EditableAdcOrderItemFields } from './AdcOrder';

const columns: Array<FormGridColDef<AdcOrderItem, string | number | undefined | boolean>> = [
  {
    flex: 2,
    field: 'id',
    headerName: i18n.AdcOrderDataHeaders.purpose,
    valueGetter: (params) => params.row.purpose,
  },
  {
    flex: 1,
    field: 'mgPerMl',
    headerName: i18n.AdcOrderDataHeaders.mgPerMl,
    valueGetter: (params) => (params.row.isBioanalysis ? 'N/A' : formatFloat(params.row.mgPerMl)),
  },
  {
    flex: 1,
    field: 'quantity',
    headerName: i18n.AdcOrderDataHeaders.quantity,
    valueGetter: (params) => formatFloat(params.row.quantity),
  },
  {
    flex: 1,
    field: 'wAvgOverage',
    headerName: i18n.AdcOrderDataHeaders.overage,
    valueGetter: (params) => (params.row.wAvgOverage !== 0 ? formatFloat(params.row.wAvgOverage) : 'N/A'),
  },
  {
    flex: 2,
    field: 'deliveredAmount',
    headerName: i18n.AdcOrderFulfillmentHeaders.deliveredAmount,
    cellClassName: 'narrow',
    renderCell: (params) => (
      <FloatField
        {...params}
        extras={{
          canEditField: () => Boolean(params.extras.canEditField?.(params.column.field)) && !params.row.isBioanalysis,
        }}
        maxValue={9999.99}
      />
    ),
  },
  {
    flex: 2,
    field: 'deliveredConcentration',
    headerName: i18n.AdcOrderFulfillmentHeaders.deliveredConcentration,
    cellClassName: 'narrow',
    renderCell: (params) => (!params.row.isBioanalysis ? <FloatField {...params} /> : <></>),
  },
  {
    flex: 2,
    field: 'a280',
    headerName: i18n.AdcOrderFulfillmentHeaders.a280,
    cellClassName: 'narrow',
    renderCell: (params) => (!params.row.isBioanalysis ? <FloatField {...params} /> : <></>),
  },
  {
    flex: 1,
    field: 'removed',
    headerName: '',
    valueGetter: (params) => (params.row.removed ? 'removed' : ''),
  },
];

export const dataTestId = 'conjugateAdcOrder';

export interface ConjugateAdcOrderProps {
  adcOrderId: AdcOrderItem['id'];
  adcOrderItems: AdcOrderItemDetailsFragment[];
  bioregId: AdcOrderItemDetailsFragment['bioregId'];
  conjugate: AdcOrderItemDetailsFragment['conjugate'];
  canEditField?: (field: EditableAdcOrderItemFields) => boolean;
  onConjugateDeliver?: () => void;
}

export const ConjugateAdcOrder = ({
  adcOrderId,
  adcOrderItems,
  bioregId,
  conjugate,
  canEditField = () => false,
  onConjugateDeliver = () => undefined,
}: ConjugateAdcOrderProps) => {
  const [bioAnalysis, [inVivoDose]] = partition(adcOrderItems, 'isBioanalysis');
  const sortedAdcOrderItems = compact([inVivoDose, ...sortBy(bioAnalysis, 'id')]);

  const { methods, onSubmit } = useConjugateAdcOrderForm({
    adcOrderItems: sortedAdcOrderItems,
    bioregId,
  });

  const handleSubmit = methods.handleSubmit(async (data) => await toast.promise(onSubmit(data), i18n.SaveAdcOrderItem));

  return (
    <Paper data-test-id={dataTestId} elevation={1} sx={{ backgroundColor: AppColors.VERY_LIGHT_GREY }}>
      <Box display="flex" flexDirection="column" gap={4}>
        <Box display="flex" justifyContent="space-between" alignItems="start">
          <Box display="flex" flexDirection="column" gap={1}>
            <Typography variant="h3" id={bioregId}>
              {bioregId}
            </Typography>
            <DefinitionList
              definitions={{
                Name: conjugate.sgName,
                Antibody: conjugate.antibodyName,
              }}
            />
          </Box>

          <Box display="flex" flexDirection="column" alignItems="end" gap={1}>
            <DeliverSection
              adcOrderId={adcOrderId}
              adcOrderItems={sortedAdcOrderItems}
              bioregId={bioregId}
              canEditField={canEditField}
              onDeliver={onConjugateDeliver}
            />
          </Box>
        </Box>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit}>
            <Box display="flex" flexDirection="column" gap={1}>
              <Box flex="1" display="flex" gap={2}>
                <ReactHookTextField
                  textField={{
                    disabled: !canEditField('batchNumber'),
                    id: `${bioregId}-batchNumberSuffix`,
                    InputProps: {
                      startAdornment: <InputAdornment position="start">{bioregId}-</InputAdornment>,
                      sx: { width: '12rem' },
                    },
                    inputProps: {
                      maxLength: 3,
                      inputMode: 'numeric',
                      pattern: '\\d*',
                    },
                    label: 'Batch',
                  }}
                  controller={{
                    name: 'batchNumberSuffix',
                    rules: {
                      required: i18n.AdcOrderDataValidations.batchNumberRequired,
                    },
                  }}
                  formatValue={formatBatchNumberSuffixString}
                  setValueAs={ensureBatchNumberSuffix}
                />
                <ReactHookTextField
                  textField={{
                    id: `${bioregId}-formulation`,
                    label: 'Diluent/Formulation',
                    InputProps: {
                      sx: { width: '22rem' },
                    },
                    disabled: !canEditField('formulation'),
                  }}
                  controller={{
                    name: 'formulation',
                    rules: {
                      required: i18n.AdcOrderDataValidations.formulationRequired,
                    },
                  }}
                />
              </Box>

              <FormGrid
                columns={columns}
                extras={{ canEditField }}
                rows={sortedAdcOrderItems}
                sx={{
                  '& .narrow .MuiInputBase-input': {
                    width: '4rem',
                  },
                }}
              />

              {/* using deliveredAmount as a surrogate */}
              {canEditField('deliveredAmount') && (
                <Box display="flex" justifyContent="flex-end">
                  <LoadingButton
                    variant="contained"
                    size="large"
                    type="submit"
                    loading={methods.formState.isSubmitting}
                    disabled={!methods.formState.isDirty}
                  >
                    Save
                  </LoadingButton>
                </Box>
              )}
            </Box>
          </form>
        </FormProvider>
      </Box>
    </Paper>
  );
};
