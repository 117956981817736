import { DateTime } from 'luxon';
import { compact, isNil, map, uniqBy } from 'lodash';
import { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  type Gender,
  type InventoryFragment,
  type InventoryStatus,
  type Species,
  useListContentsQuery,
} from '#graphql';
import { OmniForm, type OmniFormFieldProps } from '#components/widgets';
import { ANIMAL_INVENTORY_ROUTE, i18n } from '#lib/constants';

export interface PurchaseOrderFormData {
  status: InventoryStatus;
  poNumber: string;
  species: Species;
  strain: string;
  gender: Gender;
  dob: string;
  quantity: string;
  notes: string;
}

interface PurchaseOrderFormProps {
  inventory?: InventoryFragment;
  handleSubmit?: (data: PurchaseOrderFormData) => Promise<void> | void;
}

export function PurchaseOrderForm(props: PurchaseOrderFormProps) {
  const { inventory, handleSubmit = () => undefined } = props;

  const listContentsQueryResult = useListContentsQuery({
    variables: {
      category: 'strain',
    },
    fetchPolicy: 'cache-first',
  });

  const [species, strains] = useMemo(() => {
    const strains = listContentsQueryResult.data?.listContents.items ?? [];
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const species = compact(map(uniqBy(strains, 'description'), 'description')) as Species[];
    const strainNames = strains.map((strain) => ({ label: strain.name, value: strain.name }));
    return [species, strainNames];
  }, [listContentsQueryResult]);

  const methods = useForm<PurchaseOrderFormData>({
    defaultValues: {
      status: inventory?.status ?? 'open',
      poNumber: inventory?.poNumber ?? '',
      species: inventory?.species ?? 'mouse',
      strain: inventory?.strain ?? '',
      gender: inventory?.gender ?? 'female',
      dob: (!isNil(inventory) && !isNil(inventory.dob) ? DateTime.fromISO(inventory.dob) : DateTime.now()).toFormat(
        'yyyy-MM-dd'
      ),
      quantity: inventory?.quantity?.toString() ?? '',
      notes: inventory?.notes ?? '',
    },
  });

  const formFields: Array<OmniFormFieldProps<PurchaseOrderFormData>> = [
    {
      name: 'status',
      rules: { required: 'status is required' },
      select: true,
      selectOptions: ['closed', 'open'],
    },
    {
      name: 'poNumber',
      label: 'PO #',
      rules: { required: 'po # is required' },
    },
    {
      name: 'species',
      rules: { required: 'species is required' },
      select: true,
      selectOptions: species,
    },
    {
      name: 'strain',
      rules: { required: 'strain is required' },
      select: true,
      selectOptions: strains,
    },
    {
      name: 'gender',
      rules: { required: 'gender is required' },
      select: true,
      selectOptions: ['female', 'male', 'mix'],
    },
    {
      name: 'dob',
      label: 'Received Date',
      rules: { required: 'received date is required' },
      type: 'date',
    },
    {
      name: 'quantity',
      rules: { required: 'quantity is required' },
    },
    {
      name: 'notes',
      multiline: true,
      colSpan: 2,
    },
  ];

  return (
    <FormProvider {...methods}>
      <OmniForm
        fields={formFields}
        formText={i18n.PurchaseOrders.Form}
        handleSubmit={handleSubmit}
        isNew={isNil(inventory?.id)}
        successNavigation={`/${ANIMAL_INVENTORY_ROUTE}/purchase-orders`}
      />
    </FormProvider>
  );
}
