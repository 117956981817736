import { useState, useEffect } from 'react';
import { type ListContent, useListContentsQuery } from '#graphql';
import { isNil } from 'lodash';

export const useListContent = function () {
  const { data } = useListContentsQuery();
  const [animalList, setAnimalList] = useState<ListContent[]>();
  const [experimentTypeList, setExperimentTypeList] = useState<ListContent[]>();
  const [IACUCList, setIACUCList] = useState<ListContent[]>();
  const [doseUnitList, setDoseUnitList] = useState<ListContent[]>();
  const [routeList, setRouteList] = useState<ListContent[]>();
  const [tissueList, setTissueList] = useState<ListContent[]>();
  const [subcutaneousEndpointList, setSubcutaneousEndpointList] = useState<ListContent[]>();
  const [strainList, setStrainList] = useState<ListContent[]>();
  const [handlingAndStorageList, setHandlingAndStorageList] = useState<ListContent[]>();
  useEffect(() => {
    if (data?.listContents.items.length != null) {
      const items = data?.listContents.items;
      setAnimalList(items.filter((content) => content.category === 'animal'));
      setExperimentTypeList(items.filter((content) => content.category === 'experimentType'));
      setIACUCList(items.filter((content) => content.category === 'IACUC'));
      setSubcutaneousEndpointList(items.filter((content) => content.category === 'subcutaneousEndpoint'));
      setStrainList(items.filter((content) => content.category === 'strain'));
      setDoseUnitList(items.filter((content) => content.category === 'doseUnit'));
      setRouteList(items.filter((content) => content.category === 'route'));
      setTissueList(items.filter((content) => content.category === 'tissue'));
      setHandlingAndStorageList(items.filter((content) => content.category === 'handlingAndStorage'));
    }
  }, [data?.listContents]);

  return {
    loading: isNil(data?.listContents?.items.length),
    animalList,
    experimentTypeList,
    IACUCList,
    doseUnitList,
    routeList,
    subcutaneousEndpointList,
    strainList,
    tissueList,
    handlingAndStorageList,
  };
};
