import styled from '@emotion/styled';
import { groupBy, trim } from 'lodash';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useExperimentTrackerContext } from '#components/contexts';
import { HandleNetworkLifecycle } from '#components/widgets';
import { useExperimentsByStatusQuery } from '#graphql';
import { AppColors } from '#lib/constants';
import { SwimLane } from './SwimLane';

const StyledContainer = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  marginTop: '20px',
});

const StyledColumnsContainer = styled.div({
  display: 'flex',
  width: '97%',
  gap: '1px',
  flexWrap: 'wrap',
});

const swimLanes = [
  ['Review', AppColors.ORANGE],
  ['Provision', AppColors.LIME_GREEN],
  ['Ready', AppColors.CYAN],
  ['Execution', AppColors.LIGHT_BLUE],
];

export const ExperimentsKanbanView = () => {
  const { query, filter, sort, setFilter, setQuery, setSort } = useExperimentTrackerContext();
  const [searchParams] = useSearchParams();

  const { data, error, loading } = useExperimentsByStatusQuery({
    variables: {
      limit: 1000,
      offset: 0,
      query: trim(query),
      filter,
      sort,
      statuses: ['Execution', 'Provision', 'Ready', 'Review'],
    },
  });

  useEffect(() => {
    const searchParam = searchParams.get('q') ?? '';
    const filterParam = searchParams.get('f') ?? '';
    const sortParam = searchParams.get('s') ?? '';
    setQuery(searchParam);
    setFilter(filterParam);
    setSort(sortParam);
  }, []);

  return (
    <HandleNetworkLifecycle
      error={error}
      loading={loading}
      data={data?.experimentsByStatus.items}
      render={({ data: experiments }) => {
        const experimentsByStatus = groupBy(experiments, 'status');
        return (
          <StyledContainer>
            <StyledColumnsContainer>
              {swimLanes.map(([status, color]) => (
                <SwimLane
                  key={status}
                  experiments={experimentsByStatus[status] ?? []}
                  columnColor={color}
                  title={status}
                  loading={loading}
                />
              ))}
            </StyledColumnsContainer>
          </StyledContainer>
        );
      }}
    />
  );
};
