import CloseIcon from '@mui/icons-material/Close';
import { Box, Dialog, Divider, IconButton, Stack, Typography, type Breakpoint } from '@mui/material';
import { isArray, isNil } from 'lodash';
import { useState, type PropsWithChildren } from 'react';

interface ModalProps {
  title?: string;
  content?: string | readonly string[];
}

export const useModal = (legacyTitle: string | null, size: Breakpoint, handleClose?: () => undefined) => {
  const [open, setOpen] = useState<boolean>(false);

  const openModal = () => {
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
  };

  const onClose = handleClose ?? closeModal;

  const Modal = ({ children, title, content }: PropsWithChildren<ModalProps>) => {
    const contentArray = isArray(content) ? content : isNil(content) ? undefined : [content];

    return (
      <Dialog
        open={open}
        data-test-id={'reusableModal'}
        onClose={onClose}
        maxWidth={size}
        fullWidth
        sx={{ minHeight: '500px' }}
      >
        <Stack>
          <Box display="flex" justifyContent="space-between" alignItems="baseline">
            <Typography variant="h2" paddingX={2} paddingTop={1} paddingBottom={2}>
              {legacyTitle ?? title}
            </Typography>
            <IconButton aria-label="close" onClick={closeModal}>
              <CloseIcon />
            </IconButton>
          </Box>
          {!isNil(contentArray) && (
            <Stack padding={2} gap={2}>
              {contentArray.map((text, index) => (
                <Typography key={index} variant="body1">
                  {text}
                </Typography>
              ))}
            </Stack>
          )}

          <Divider />

          {children}
        </Stack>
      </Dialog>
    );
  };

  return { Modal, openModal, closeModal };
};
