import { type FocusEvent } from 'react';
import { Tooltip, FormControl, type SelectChangeEvent } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import toast from 'react-hot-toast';

import { useListContent } from '#lib/hooks';
import { type TestArticle, useUpdateTestArticleMutation } from '#graphql';
import { maybeConvertToScientificNotation } from '#lib/utils';
import { AppColors, i18n } from '#lib/constants';
import {
  StyledTreatmentLine,
  StyledTextField,
  StyledLabel,
  StyledBubble,
  StyledSelect,
  StyledRow,
  StyledMenuItem,
} from './styles';
import { isNil } from 'lodash';
export function requiresFields(testArticle: TestArticle) {
  return !['Cell Lines', 'control'].includes(testArticle.moleculeType ?? '') && testArticle.name !== 'untreated';
}

export const TreatmentCardFields = ({
  testArticle,
  disabled,
  canUpdateRoute,
}: {
  testArticle: TestArticle;
  disabled: boolean;
  canUpdateRoute: boolean;
}) => {
  const methods = useFormContext();
  const { doseUnitList, routeList } = useListContent();

  const [updateTestArticle] = useUpdateTestArticleMutation();

  if (isNil(doseUnitList) || isNil(routeList)) return null;

  const hasRequiredFields = requiresFields(testArticle);

  const updateTestArticleField = async (field: Partial<TestArticle>) => {
    await updateTestArticle({
      variables: {
        input: { id: testArticle.id, name: testArticle.name, ...field },
      },
      optimisticResponse: {
        updateTestArticle: {
          __typename: 'TestArticle',
          ...testArticle,
          ...field,
        },
      },
    });
  };

  const onFloatBlur =
    (testArticle: TestArticle, field: string, isRequired: boolean) => (ev: FocusEvent<HTMLInputElement>) => {
      const value = ev.target.value.trim();

      if (value === '') {
        if (isRequired) {
          toast.error(
            `The ${field} value for test article ${testArticle.moleculeType} - ${testArticle.name} is required`
          );
        } else {
          void updateTestArticleField({ [field]: null });
        }
        return;
      }

      const number = Number.parseFloat(value);

      if (isNaN(number) || number < 0) {
        toast.error(
          `The ${field} value for test article ${testArticle.moleculeType} - ${testArticle.name} must be a number that is >= 0`
        );
      } else {
        void updateTestArticleField({ [field]: number });
      }
    };

  const dosage = maybeConvertToScientificNotation(testArticle.moleculeType, testArticle.dosage) ?? '';

  return (
    <StyledTreatmentLine data-test-id="treatmentCardField">
      <Tooltip title={testArticle.moleculeType ?? ''}>
        <StyledBubble
          data-test-id={`treatmentArticleMoleculeType`}
          customwidth="100px"
          bubblecolor={AppColors.LOW_GREY}
        >
          {testArticle.moleculeType ?? ''}
        </StyledBubble>
      </Tooltip>

      <Tooltip title={testArticle.bioregId ?? ''}>
        <StyledBubble data-test-id={`treatmentArticleBioregId`} customwidth="100px" bubblecolor={AppColors.LOW_GREY}>
          {testArticle.bioregId ?? 'N/A'}
        </StyledBubble>
      </Tooltip>

      <Tooltip title={testArticle.name ?? ''}>
        <StyledBubble
          data-test-id={`treatmentArticleName`}
          customwidth={disabled ? '275px' : '267px'}
          bubblecolor={AppColors.LOW_GREY}
        >
          {testArticle.name ?? ''}
        </StyledBubble>
      </Tooltip>

      <StyledRow>
        <StyledLabel width="45px" id={`doseNumber-${testArticle.id}`}>
          {`# Dose ${hasRequiredFields ? '*' : ''}`}
        </StyledLabel>
        <Controller
          control={methods.control}
          defaultValue={testArticle.doseNumber ?? ''}
          name={`doseNumber-${testArticle.id}`}
          rules={{
            required: hasRequiredFields && `${testArticle.name}: ${i18n.ValidateExperiment.NoDoseNumber}`,
          }}
          render={({ field: { onChange, value } }) => (
            <StyledTextField
              autoComplete="off"
              disabled={disabled}
              inputProps={{
                'aria-labelledby': `doseNumber-${testArticle.id}`,
              }}
              invalid={!isNil(methods.formState.errors?.[`doseNumber-${testArticle.id}`])}
              onBlur={onFloatBlur(testArticle, 'doseNumber', hasRequiredFields)}
              onChange={onChange}
              type="text"
              value={value}
            />
          )}
        />
      </StyledRow>

      <StyledRow>
        <StyledLabel width="43px" id={`dosage-${testArticle.id}`}>
          {`Dose ${hasRequiredFields ? '*' : ''}`}
        </StyledLabel>
        <Controller
          control={methods.control}
          defaultValue={dosage}
          name={`dosage-${testArticle.id}`}
          rules={{
            required: hasRequiredFields && `${testArticle.name}: ${i18n.ValidateExperiment.NoDosage}`,
          }}
          render={({ field: { onChange, value } }) => (
            <StyledTextField
              autoComplete="off"
              disabled={disabled}
              inputProps={{
                'aria-labelledby': `dosage-${testArticle.id}`,
              }}
              invalid={!isNil(methods.formState.errors?.[`dosage-${testArticle.id}`])}
              onBlur={onFloatBlur(testArticle, 'dosage', hasRequiredFields)}
              onChange={onChange}
              type="text"
              value={value}
              width="90px"
            />
          )}
        />
      </StyledRow>

      <FormControl variant="standard">
        <StyledSelect
          disableUnderline
          value={testArticle.doseUnit ?? doseUnitList?.[0].name}
          displayEmpty
          disabled={disabled}
          onChange={(ev: SelectChangeEvent) => {
            void updateTestArticleField({ doseUnit: ev.target.value });
          }}
        >
          <StyledMenuItem disabled value={'1'}>
            --
          </StyledMenuItem>
          {doseUnitList?.map((unit) => (
            <StyledMenuItem key={unit.id} value={unit.name}>
              {unit.name}
            </StyledMenuItem>
          ))}
        </StyledSelect>
      </FormControl>

      <StyledRow>
        <StyledLabel>Interval</StyledLabel>
        <StyledTextField
          autoComplete="off"
          defaultValue={testArticle.interval}
          disabled={disabled}
          onBlur={onFloatBlur(testArticle, 'interval', false)}
          type="text"
        />
      </StyledRow>
      <StyledRow gap="0em">
        <StyledLabel>Route</StyledLabel>

        <FormControl variant="standard">
          <StyledSelect
            disableUnderline
            width="90px"
            value={testArticle.route ?? ''}
            disabled={!canUpdateRoute}
            data-test-id="testArticleRoute"
            displayEmpty
            onChange={(ev: SelectChangeEvent) => {
              void updateTestArticleField({ route: ev.target.value });
            }}
          >
            <StyledMenuItem value="">--</StyledMenuItem>
            {routeList?.map((unit) => (
              <StyledMenuItem key={unit.id} value={unit.name}>
                {unit.name}
              </StyledMenuItem>
            ))}
          </StyledSelect>
        </FormControl>
      </StyledRow>
    </StyledTreatmentLine>
  );
};
