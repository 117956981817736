import {
  getSectionDetailsCSV,
  getCSVSimpleFormat,
  blankLine,
  translateFalseForDisplay,
  getWorksheetSectionListSingleRow,
  getWorksheetSectionListMultipleRows,
} from './exportCore';
import { type WorksheetDefinition } from '@omnivivo/worksheets-core';

export const getBioanalysisWorksheetCsv = (
  definition: WorksheetDefinition,
  data: string[][],
  headers: string[],
  keys: string[]
) => {
  let worksheetCsv = '';

  const bloodRequirements = getWorksheetSectionListSingleRow(definition, 'bloodRequirements', headers, data, keys, [
    'bloodIncluded',
    'amountOfBloodToInclude',
    'timePoints',
  ]);

  const bloodType = getWorksheetSectionListSingleRow(definition, 'bloodRequirements', headers, data, keys, [
    'bloodType',
  ]);

  const bloodAnimalsToInclude = getWorksheetSectionListMultipleRows(
    definition,
    'bloodAnimalsToInclude',
    headers,
    data,
    keys,
    ['treatmentGroupIndex', 'notes']
  );

  const bloodMethodRequirements = getWorksheetSectionListSingleRow(
    definition,
    'bloodMethodRequirements',
    headers,
    data,
    keys,
    [
      'gTAb',
      'acDRUG',
      'gADC',
      'lbaCba',
      'ivigs',
      'sTAb',
      'unconjugatedMmae',
      'ada',
      'sAntigen',
      'generic',
      'unconjugatedDrug',
      'other',
    ]
  );

  const tissueRequirements = getWorksheetSectionListSingleRow(definition, 'tissueRequirements', headers, data, keys, [
    'tissueIncluded',
    'tissuesToInclude',
    'timePoints',
    'handling',
    'storage',
    'strains',
    'destination',
  ]);

  const tissueAnimalsToInclude = getWorksheetSectionListMultipleRows(
    definition,
    'tissueAnimalsToInclude',
    headers,
    data,
    keys,
    ['treatmentGroupIndex', 'notes']
  );

  worksheetCsv = worksheetCsv.concat(
    'Blood Requirements'.concat(
      '\n',
      getCSVSimpleFormat(bloodRequirements.csvHeaders, translateFalseForDisplay(bloodRequirements.csvInfo))
    )
  );

  worksheetCsv = worksheetCsv.concat(
    blankLine,
    'Blood Type'.concat('\n', getCSVSimpleFormat(bloodType.csvHeaders, translateFalseForDisplay(bloodType.csvInfo)))
  );

  worksheetCsv = worksheetCsv.concat(
    blankLine,
    getSectionDetailsCSV(
      'Blood: Animals to Include (uL)',
      bloodAnimalsToInclude.csvHeaders,
      bloodAnimalsToInclude.csvInfo
    )
  );
  worksheetCsv = worksheetCsv.concat(
    blankLine,
    'Blood: Method Requirements'.concat(
      '\n',
      getCSVSimpleFormat(bloodMethodRequirements.csvHeaders, translateFalseForDisplay(bloodMethodRequirements.csvInfo))
    )
  );
  worksheetCsv = worksheetCsv.concat(
    blankLine,
    'Tissue Requirements'.concat(
      '\n',
      getCSVSimpleFormat(tissueRequirements.csvHeaders, translateFalseForDisplay(tissueRequirements.csvInfo))
    )
  );
  worksheetCsv = worksheetCsv.concat(
    blankLine,
    getSectionDetailsCSV(
      'Tissue: Animals to Include',
      tissueAnimalsToInclude.csvHeaders,
      tissueAnimalsToInclude.csvInfo
    )
  );

  return worksheetCsv;
};
