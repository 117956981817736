import { HOME_PATH } from './';
import {
  useAntibodiesByPageQuery,
  useConjugatesByPageQuery,
  useCellLinePageByPageQuery,
  useSmallMoleculesByPageQuery,
  useOtherMoleculesByPageQuery,
} from '#graphql';
import {
  antibodiesTableColumns,
  conjugatesTableColumns,
  cellLineTableColumns,
  smallMoleculesTableColumns,
  otherMoleculesTableColumns,
} from '#lib/utils';
import { TreatmentModalQueries } from '#lib/types';

export const TREATMENT_MODALS_OPTIONS = [
  'Antibodies',
  'Conjugates',
  'Linkers / Small Molecules',
  'Non-SG Drugs',
  'Immunogens',
  'Controls',
  'Cell Lines',
  'Other Molecules ',
];

export const TREATMENT_MODAL_QUERIES: TreatmentModalQueries[] = [
  {
    tabName: 'antibody',
    query: useAntibodiesByPageQuery,
    columns: antibodiesTableColumns,
  },
  {
    tabName: 'conjugate',
    query: useConjugatesByPageQuery,
    columns: conjugatesTableColumns,
  },
  {
    tabName: 'Cell Lines',
    query: useCellLinePageByPageQuery,
    columns: cellLineTableColumns,
  },
  {
    tabName: 'smallMolecule',
    query: useSmallMoleculesByPageQuery,
    columns: smallMoleculesTableColumns,
  },
  {
    tabName: 'Other Molecules',
    query: useOtherMoleculesByPageQuery,
    columns: otherMoleculesTableColumns,
  },
];

export const DOSE_UNIT = ['mg/kg', 'Gy', 'mM', 'uL', 'μg'];

export const DEFAULT_PAGE_SIZE = 20;

export const DEFAULT_EXPERIMENT = {
  IACUC: '',
  type: '',
  project: '',
};

export const DEFAULT_LOCATION_STATE = {
  origin: HOME_PATH,
  isDuplicate: false,
};

const DOTMATICS_HOST =
  process.env.REACT_APP_DOTMATICS_HOST ?? 'seagen-test.dotmatics.net';

export const DOTMATIC_BASE_URL = `https://${DOTMATICS_HOST}/bioregister/complex/show/`;

export const DEFAULT_DEBOUNCE = 600;
export const SLOW_DEBOUNCE = 2000;
const experimentStatusesConst = [
  'Draft',
  'Review',
  'Provision',
  'Ready',
  'Execution',
  'Completed',
] as const;

export let experimentStatuses = [...experimentStatusesConst];
export type ExperimentStatusesType = typeof experimentStatusesConst[number];

export const FAQ_URL =
  'https://seagen.sharepoint.com/:w:/r/sites/ITSSubmit2/_layouts/15/Doc.aspx?sourcedoc=%7B291270E8-1705-451F-9286-5149000A0B8A%7D&file=Omni%20Vivo%20FAQ_Troubleshooting%20Guide.docx&action=default&mobileredirect=true';
