"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calcFunctions = void 0;
const lodash_1 = require("lodash");
const luxon_1 = require("luxon");
const log_1 = require("../log");
const worksheetTools_1 = require("../worksheetTools");
const CalcLib_1 = require("./CalcLib");
const stats = require('stats-lite');
/*
  NOTE: As much as possible, these functions should be inspired by Microsoft Excel functions:
  https://support.microsoft.com/en-us/office/excel-functions-alphabetical-b3944572-255d-4efb-bb96-c6d90033e188
*/
exports.calcFunctions = {
    // ARRAY MATH FUNCTIONS
    sum: (0, CalcLib_1.getValidatingArrayFunction)(stats.sum),
    mean: (0, CalcLib_1.getValidatingArrayFunction)(stats.mean),
    median: (0, CalcLib_1.getValidatingArrayFunction)(stats.median),
    stdev: (0, CalcLib_1.getValidatingArrayFunction)(stats.sampleStdev),
    // STRING FUNCTIONS
    concat: (...args) => (0, lodash_1.flattenDeep)(args).join(''),
    // ARRAY FUNCTIONS
    count: (a) => Array.isArray(a) ? a.length : a != undefined ? 1 : 0,
    first: (a) => (Array.isArray(a) ? a[0] : a),
    last: (a) => (Array.isArray(a) ? a[a.length - 1] : a),
    getAt: (a, index) => (Array.isArray(a) ? a[index] : a),
    push: (a, b) => Array.isArray(a)
        ? [...a, b].filter((item) => (0, CalcLib_1.calcNotEmpty)(item))
        : undefined,
    // SINGLETON MATH FUNCTIONS
    round: (x, significance = 1) => (0, lodash_1.isNumber)(x) && (0, lodash_1.isNumber)(significance)
        ? Math.round(x / significance) * significance
        : undefined,
    ceil: (x, significance = 1) => (0, lodash_1.isNumber)(x) && (0, lodash_1.isNumber)(significance)
        ? Math.ceil(x / significance) * significance
        : undefined,
    floor: (x, significance = 1) => (0, lodash_1.isNumber)(x) && (0, lodash_1.isNumber)(significance)
        ? Math.floor(x / significance) * significance
        : undefined,
    // LOGIC FUNCTIONS
    if: (a, b, c) => ((0, CalcLib_1.calcTrue)(a) ? b : c),
    and: (a, b) => ((0, CalcLib_1.calcTrue)(a) ? b : a),
    or: (a, b) => ((0, CalcLib_1.calcTrue)(a) ? a : b),
    not: (a) => !(0, CalcLib_1.calcTrue)(a),
    // COMPARISON FUNCTIONS
    equal: (a, b) => a === b,
    lt: (a, b) => ((0, CalcLib_1.comparable)(a, b) ? a < b : undefined),
    lte: (a, b) => (0, CalcLib_1.comparable)(a, b) ? a <= b : undefined,
    gt: (a, b) => ((0, CalcLib_1.comparable)(a, b) ? a > b : undefined),
    gte: (a, b) => (0, CalcLib_1.comparable)(a, b) ? a >= b : undefined,
    // DATE FUNCTIONS
    daysDifference: (date1, date2) => {
        if (date1 == null || date2 == null)
            return;
        return (0, CalcLib_1.nanToUndefined)(luxon_1.DateTime.fromISO(date2).diff(luxon_1.DateTime.fromISO(date1), 'days').days);
    },
    today: () => (0, worksheetTools_1.toWorksheetDate)(new Date()),
    toDate: (date) => {
        return (0, worksheetTools_1.toWorksheetDate)(date);
    },
    // WORKSHEET INTROSPECTION
    dataCollectionSectionId: function () {
        return this.section?.dataCollectionSectionId;
    },
    columnId: function () {
        return this.entry?.columnId;
    },
    rowId: function () {
        return this.entry?.rowId;
    },
    valuesFromColumn: function (sectionId, columnId, fieldId) {
        return this.entriesBySectionId[sectionId]
            .filter((entry) => entry.columnId === columnId)
            .map((entry) => this.getResolvedValue(entry, fieldId));
    },
    // return a single field value from the first entry that matches
    lookup: function (sectionId, lookupReturnFieldId, ...filters) {
        const foundEntry = this.entriesBySectionId[sectionId].find((entry) => testEntryForMatch(this, entry, filters));
        if (foundEntry)
            return this.getResolvedValue(foundEntry, lookupReturnFieldId);
    },
    // return all field values from the all entries that match
    select: function (sectionId, lookupReturnFieldId, ...filters) {
        return this.entriesBySectionId[sectionId]
            .filter((entry) => testEntryForMatch(this, entry, filters))
            .map((entry) => this.getResolvedValue(entry, lookupReturnFieldId));
    },
    unique: function (a) {
        return (0, lodash_1.uniq)(Array.isArray(a) ? a : []);
    },
    // return a single value from the 'previous' field as defined by the EquationContext
    valueFromPreviousEntry: function (fieldId) {
        const previousEntry = this.getPreviousEntry();
        return previousEntry && this.getResolvedValue(previousEntry, fieldId);
    },
    // return a single value from the 'first' field as defined by the EquationContext
    valueFromFirstEntry: function (fieldId) {
        const firstEntry = this.getFirstEntry();
        return firstEntry && this.getResolvedValue(firstEntry, fieldId);
    },
    log: log_1.log, // NOTE: log returns the LAST value passed to it
};
const toArray = (a) => (0, lodash_1.flattenDeep)([a]);
const testEntryForMatch = (context, entry, filters) => !filters.find(([lookupMatchFieldId, lookupMatchFieldValue]) => {
    const value = context.getResolvedValue(entry, lookupMatchFieldId);
    return Array.isArray(lookupMatchFieldValue)
        ? !lookupMatchFieldValue.includes(value)
        : value !== lookupMatchFieldValue;
});
