import {
  TimeField as MuiTimeField,
  type TimeFieldProps as MuiTimeFieldProps,
  type TimeValidationError,
} from '@mui/x-date-pickers';
import { isNil, isString } from 'lodash';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';

type TimeFieldProps<TDate> = Omit<MuiTimeFieldProps<TDate>, 'defaultValue' | 'onChange' | 'value'> & {
  error?: boolean;
  onValidChange: (value: DateTime | null) => void;
  value?: string | DateTime | null | undefined;
};

export default function TimeField(props: TimeFieldProps<DateTime>) {
  const { error = false, InputProps: InputPropsProp, onValidChange, value: valueProp, ...rest } = props;

  const [value, setValue] = useState(toDateTimeOrNull(valueProp));
  const [hasError, setHasError] = useState(error);

  useEffect(() => {
    setHasError(false);
    setValue(toDateTimeOrNull(valueProp));
  }, [valueProp]);

  function onChange(value: DateTime | null, { validationError }: { validationError: TimeValidationError }) {
    if (!isNil(validationError)) {
      setHasError(true);
      return;
    }

    setValue(value);
    setHasError(false);
    onValidChange(value);
  }

  const InputProps = {
    ...InputPropsProp,
    error: hasError || error,
  };

  return <MuiTimeField {...rest} clearable={true} InputProps={InputProps} onChange={onChange} value={value} />;
}

function toDateTimeOrNull(value: string | DateTime | null | undefined) {
  return isString(value) ? (DateTime.fromISO(value).isValid ? DateTime.fromISO(value) : null) : value;
}
