import { AddOutlined } from '@mui/icons-material';
import { isNil } from 'lodash';

import { type BloodBioanalysisFragment } from '#graphql';
import { type TreatmentGroupWithTreatment } from '#lib/types';
import { useState } from 'react';
import { StyledButton, StyledButtonContainer } from '../Cards/styles';
import { BloodBioanalysisData } from './BloodBioanalysisData';
import { BloodBioanalysisModal } from './BloodBioanalysisModal';

interface BloodBioanalysisProps {
  bloodBioanalysis?: BloodBioanalysisFragment;
  canEdit: boolean;
  experimentId: number;
  treatmentGroups: TreatmentGroupWithTreatment[];
}

export function BloodBioanalysisSection({
  bloodBioanalysis,
  canEdit,
  experimentId,
  treatmentGroups,
}: BloodBioanalysisProps) {
  const [drawerState, setDrawerState] = useState(false);

  return (
    <div>
      <BloodBioanalysisModal
        bloodBioanalysis={bloodBioanalysis}
        onClose={() => {
          setDrawerState(false);
        }}
        open={drawerState}
        experimentId={experimentId}
        treatmentGroups={treatmentGroups}
      />

      {!isNil(bloodBioanalysis) && (
        <BloodBioanalysisData
          bloodBioanalysis={bloodBioanalysis}
          canEdit={canEdit}
          handleEdit={() => {
            setDrawerState(true);
          }}
          treatmentGroups={treatmentGroups}
        />
      )}

      {canEdit && isNil(bloodBioanalysis) && (
        <StyledButtonContainer gap="5px">
          <StyledButton
            onClick={() => {
              setDrawerState(true);
            }}
            disabled={treatmentGroups.length === 0}
          >
            <AddOutlined /> Add Blood Bioanalysis
          </StyledButton>
        </StyledButtonContainer>
      )}
    </div>
  );
}
