import { Container } from '@mui/material';
import { useCreateUserMutation } from '#graphql';
import { UserFormData, UserForm } from './UserForm';

export function CreateUser() {
  const [createUser] = useCreateUserMutation();

  async function handleSubmit(data: UserFormData) {
    const { username, roleId, ...rest } = data;

    await createUser({
      variables: {
        input: {
          ...rest,
          email: `${data.username}@seagen.com`,
          roleId: parseInt(data.roleId),
        },
      },
    });
  }

  return (
    <Container maxWidth="md">
      <UserForm handleSubmit={handleSubmit} />
    </Container>
  );
}
