import styled from '@emotion/styled';
import { Box } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';

import { ExperimentDetailsFragment } from '#graphql';
import { DocumentationSections } from '#lib/types';
import { AppColors } from '#lib/constants';
import { Can } from '#components/contexts';
import { SyncedExperimentTextArea } from '#components/widgets';

const StyledContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'left',
  width: 'calc(100% - 20px)',
  gap: '1em',
});

const StyledBoxContainer = styled.div({
  display: 'flex',
  flexDirection: 'inherit',
  gap: '0.5rem',
  marginBottom: '2em',
  width: '63rem',
});

const StyledTitle = styled.span({
  fontStyle: 'normal',
  fontWeight: '500',
  fontSize: '14px',
  lineHeight: '24px',
  alignSelf: 'left',
  color: AppColors.BLACK,
  letterSpacing: '0.15px',
});

const sections: DocumentationSections[] = [
  ['Purposes*', 'purposes'],
  ['Design and methods*', 'designMethods'],
  ['Endpoints*', 'endpoints'],
  ['Conclusion', 'conclusion'],
];
interface DocumentationProps {
  experiment: ExperimentDetailsFragment;
}

export const Documentation = ({ experiment }: DocumentationProps) => {
  const methods = useForm({
    defaultValues: {
      purposes: experiment.purposes ?? '',
      designMethods: experiment.designMethods ?? '',
      endpoints: experiment.endpoints ?? '',
      conclusion: experiment.conclusion ?? '',
    },
    mode: 'onBlur',
  });

  return (
    <StyledContainer>
      <StyledBoxContainer>
        <FormProvider {...methods}>
          <Box display="flex" flexDirection="column" gap={3} component="form">
            {sections.map(([label, name]) => (
              <div key={name}>
                <StyledTitle>{label}</StyledTitle>
                <Can
                  I="update"
                  this={experiment as never}
                  passThrough
                  field={name}
                >
                  {(allowed: boolean) => (
                    <SyncedExperimentTextArea
                      experimentId={experiment.id}
                      disabled={!allowed}
                      name={name}
                      required={name !== 'conclusion'}
                    />
                  )}
                </Can>
              </div>
            ))}
          </Box>
        </FormProvider>
      </StyledBoxContainer>
    </StyledContainer>
  );
};
