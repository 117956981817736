import { createTheme } from '@mui/material/styles';
import type {} from '@mui/x-data-grid-premium/themeAugmentation';

import { AppColors } from './constants';

export const seagenTheme = createTheme({
  typography: {
    fontSize: 14,
    body2: {
      fontWeight: 400,
      fontSize: '0.75rem',
      lineHeight: '0.75rem',
      letterSpacing: '0.03125rem',
    },
  },
  palette: {
    primary: {
      main: AppColors.BRAND_GREEN,
      dark: AppColors.DARK_BRAND_GREEN,
      light: AppColors.PRIMARY_LIGHT,
    },
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        h1: {
          fontFamily: 'Roboto',
          lineHeight: '1.8rem',
          fontSize: '1.4rem',
          fontWeight: 'bold',
          letterSpacing: '0.5px',
        },
        h2: {
          fontFamily: 'Roboto',
          lineHeight: '1.2rem',
          fontSize: '1.2rem',
          letterSpacing: '0.5px',
        },
        h3: {
          fontFamily: 'Roboto',
          lineHeight: '1.1rem',
          fontSize: '1.1rem',
          fontWeight: 'bold',
          letterSpacing: '0.5px',
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          '&.Mui-expanded': {
            marginTop: '0',
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          '&.Mui-expanded': {
            minHeight: '48px',
          },
        },
        content: {
          '&.Mui-expanded': {
            margin: `12px 0`,
          },
        },
      },
    },
    MuiInputBase: {
      defaultProps: {
        disableInjectingGlobalStyles: true,
      },
      styleOverrides: {
        root: {
          background: AppColors.WHITE,
          '&.Mui-disabled': {
            background: AppColors.LIGHT_LIGHT_GREY,
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontWeight: 'bold',
        },
      },
    },
    MuiInputLabel: {
      defaultProps: {
        shrink: true,
      },
      styleOverrides: {
        root: {
          fontSize: '.75rem',
        },
        shrink: {
          position: 'relative',
          transform: 'none',
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        size: 'small',
        variant: 'outlined',
      },
    },
    MuiOutlinedInput: {
      defaultProps: {
        notched: false,
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
    },
    MuiStack: {
      defaultProps: {
        useFlexGap: true,
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: 0,
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          marginLeft: '0px',
        },
      },
    },
  },
});
