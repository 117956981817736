import { type WorksheetDefinition } from '@omnivivo/worksheets-core';
import { getSectionDetailsCSV, blankLine, getWorksheetSectionListMultipleRows } from './exportCore';

export const getTreatmentCalculationWorksheetCsv = (
  definition: WorksheetDefinition,
  data: string[][],
  headers: string[],
  keys: string[]
) => {
  let worksheetCsv = '';

  const concentrationWorkspaceList = getWorksheetSectionListMultipleRows(
    definition,
    'concentrationWorkspace',
    headers,
    data,
    keys,
    [
      'treatmentGroupName',
      'testArticleName',
      'totalAnimals',
      'numInjections',
      'animalWeight',
      'injVolumeMls',
      'injVolumeKgs',
      'initialStock',
      'neededDosage',
      'doseUnit',
      'finalConcentration',
      'totalVolume',
      'totalNeeded',
      'amountStock',
      'amountDiluent',
    ],
    'treatmentGroupName'
  );

  const adcWorkspaceList = getWorksheetSectionListMultipleRows(
    definition,
    'adcVerificationWorkspace',
    headers,
    data,
    keys,
    [
      'treatmentId',
      'bioregId',
      'deliveredConcentration',
      'formulation',
      'conjugationAbsorbanceAtA280',
      'inVivoAbsorbanceAtA280',
      'percentageDifferenceForAbsorbance',
    ],
    'treatmentGroupIndex'
  );

  const proteinWorkspaceList = getWorksheetSectionListMultipleRows(
    definition,
    'proteinVerificationWorkspace',
    headers,
    data,
    keys,
    ['treatmentId', 'bioregId', 'absorbanceAtA280', 'extinctionCoefficient', 'mgMl'],
    'treatmentGroupIndex'
  );

  worksheetCsv = worksheetCsv.concat(
    getSectionDetailsCSV(
      'Concentration Workspace',
      concentrationWorkspaceList.csvHeaders,
      concentrationWorkspaceList.csvInfo
    )
  );

  worksheetCsv = worksheetCsv.concat(
    blankLine,
    getSectionDetailsCSV('ADC Verification Workspace', adcWorkspaceList.csvHeaders, adcWorkspaceList.csvInfo)
  );

  worksheetCsv = worksheetCsv.concat(
    blankLine,
    getSectionDetailsCSV(
      'Protein Verification Workspace',
      proteinWorkspaceList.csvHeaders,
      proteinWorkspaceList.csvInfo
    )
  );

  return worksheetCsv;
};
