import { Container, Box } from '@mui/material';
import type {
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
  GridValueFormatterParams,
} from '@mui/x-data-grid-premium';
import { useNavigate } from 'react-router';
import {
  type ExperimentState,
  type UserBasicInfoFragment,
  useAllExperimentsLazyQuery,
} from '#graphql';
import { NameBubble, OmniGrid, StatusBubble } from '#components/widgets';
import { getConfiguratorOverviewPath } from '#lib/constants';
import { ExperimentColumns } from '#lib/types';
import { formatDate } from '#lib/utils';

function dateFormatter(params: GridValueFormatterParams<string>) {
  return params.value ? formatDate(params.value) : '';
}

const columns: GridColDef[] = [
  {
    field: 'approvalName',
    filterable: false,
    flex: 1,
    headerName: ExperimentColumns.ApprovalName,
    disableColumnMenu: true,
    valueFormatter: (params: GridValueFormatterParams<string>) =>
      params.value || 'Pending',
  },
  {
    field: 'name',
    filterable: false,
    flex: 1,
    headerName: ExperimentColumns.Title,
    disableColumnMenu: true,
  },
  {
    field: 'status',
    filterable: false,
    headerName: ExperimentColumns.Phase,
    width: 150,
    disableColumnMenu: true,
    renderCell: (params: GridRenderCellParams<ExperimentState>) => (
      <StatusBubble status={params.value!} />
    ),
  },
  {
    field: 'owner',
    flex: 1,
    filterable: false,
    headerName: ExperimentColumns.Author,
    minWidth: 200,
    disableColumnMenu: true,
    sortable: false,
    renderCell: (params: GridRenderCellParams<UserBasicInfoFragment>) => (
      <NameBubble user={params.value!} />
    ),
  },
  {
    field: 'researcher',
    flex: 1,
    filterable: false,
    headerName: ExperimentColumns.Researcher,
    minWidth: 200,
    disableColumnMenu: true,
    sortable: false,
    renderCell: (params: GridRenderCellParams<UserBasicInfoFragment>) =>
      params.value ? <NameBubble user={params.value} /> : '',
  },
  {
    field: 'submissionDate',
    filterable: false,
    headerName: ExperimentColumns.Submitted,
    valueFormatter: dateFormatter,
    minWidth: 120,
    disableColumnMenu: true,
  },
  {
    field: 'updatedAt',
    filterable: false,
    headerName: ExperimentColumns.Updated,
    valueFormatter: dateFormatter,
    minWidth: 120,
    disableColumnMenu: true,
  },
  {
    field: 'completionDate',
    filterable: false,
    headerName: ExperimentColumns.Completed,
    valueFormatter: dateFormatter,
    minWidth: 120,
    disableColumnMenu: true,
  },
  {
    field: 'project',
    filterable: false,
    flex: 1,
    headerName: ExperimentColumns.Program,
    disableColumnMenu: true,
    sortable: false,
  },
  {
    field: 'IACUC',
    filterable: false,
    flex: 1,
    headerName: ExperimentColumns.IACUC,
    disableColumnMenu: true,
  },
  {
    field: 'CRO',
    filterable: true,
    headerName: ExperimentColumns.CRO,
    renderCell: renderCellBoolean,
    sortable: false,
    type: 'boolean',
    groupable: false,
    pinnable: false,
  },
  {
    field: 'MTA',
    filterable: true,
    headerName: ExperimentColumns.MTA,
    renderCell: renderCellBoolean,
    sortable: false,
    type: 'boolean',
    groupable: false,
    pinnable: false,
  },
];

function renderCellBoolean({ value }: GridRenderCellParams) {
  return <span data-test-id={`booleanCell`}>{value ? 'Yes' : 'No'}</span>;
}

export interface ExperimentsListDataGridViewProps {
  statuses?: ExperimentState[];
}

export const ExperimentsListDataGridView = ({
  statuses = [],
}: ExperimentsListDataGridViewProps) => {
  const navigate = useNavigate();

  const [getExperiments, { data, previousData, loading, error }] =
    useAllExperimentsLazyQuery({
      variables: {
        limit: 0, // required for TS but will be overwritten by the data grid
        statuses,
      },
    });

  const currentData = data?.experiments ?? previousData?.experiments;

  const rows = currentData?.items ?? [];
  const rowCount = currentData?.totalCount ?? 0;

  const onRowClick = (params: GridRowParams) => {
    navigate(getConfiguratorOverviewPath(params.row.id));
  };

  return (
    <Container maxWidth="xl" component="main">
      <Box paddingX={1} paddingY={3}>
        <OmniGrid
          columns={columns}
          defaultSort={[{ field: 'updatedAt', sort: 'desc' }]}
          error={error}
          loading={loading}
          refetch={getExperiments}
          rows={rows}
          rowCount={rowCount}
          showQuickFilter={true}
          dataGridProps={{
            columnVisibilityModel: {
              completionDate: statuses.includes('Completed'),
            },
            onRowClick,
          }}
        />
      </Box>
    </Container>
  );
};
