import { SCIENTIFIC_NOTATION_FORMAT, WORKSHEET_DEFAULT_FORMAT } from '@omnivivo/style';
import {
  inputDateFormat,
  resolveCalc,
  toDateTime,
  toWorksheetDate,
  worksheetDateFormatRegex,
  type WorksheetDefinition,
  type WorksheetEntriesBySectionId,
  type WorksheetFieldDefinition,
  type WorksheetFieldType,
  type WorksheetFieldValue,
} from '@omnivivo/worksheets-core';
import { DOSE_TIME } from '@omnivivo/worksheets/src/DataCollectionSectionGenerators';
import { isBoolean, isDate, isNil, isNumber, isString, sortBy } from 'lodash';
import { DateTime } from 'luxon';
import numeral from 'numeral';

import { type InputElementProps, type State } from './WorksheetComponentInterfaces';

interface IdParts {
  sectionId: string;
  rowNumber: number;
  columnNumber: number;
}

export const encodeSingleFieldId = ({ sectionId, rowNumber, columnNumber }: IdParts) =>
  `${sectionId}|${rowNumber}|${columnNumber}`;

export const decodeSingleFieldId = (id: string) => {
  const [sectionId, rowNumber, columnNumber] = id.split('|');
  return {
    sectionId,
    rowNumber: parseInt(rowNumber),
    columnNumber: parseInt(columnNumber),
  };
};

export type InputValue = string | number | boolean | undefined | null | Date | string[];

function getNumberFormat(value: number, fieldNumberFormat?: string) {
  if (!isNil(fieldNumberFormat) && fieldNumberFormat === 'auto-scientific')
    return value > 10000 ? SCIENTIFIC_NOTATION_FORMAT : WORKSHEET_DEFAULT_FORMAT;

  if (!isNil(fieldNumberFormat)) return fieldNumberFormat;

  return WORKSHEET_DEFAULT_FORMAT;
}

export const worksheetDateToInputValue = (dateThing: Date | string): InputValue => {
  return toDateTime(dateThing)?.toFormat(inputDateFormat) ?? '';
};

export const inputValueToWorksheetNumber = (str: string) => {
  const v = parseFloat(str);
  if (isNaN(v)) return undefined;
  else return v;
};

export const formatNumber = (value: number, field: WorksheetFieldDefinition) =>
  numeral(value).format(getNumberFormat(value, field.numberFormat));

export const worksheetToInputProps = (fieldType: WorksheetFieldType, inputValue: InputValue): InputElementProps =>
  fieldType === 'boolean'
    ? // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      { checked: inputValue as boolean }
    : // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      { value: inputValue as string };

export const worksheetToInputValue = (field: WorksheetFieldDefinition, value: WorksheetFieldValue): InputValue => {
  value = value ?? field.default;

  switch (field.fieldType) {
    case 'boolean':
      return Boolean(value);
    case 'date': {
      if (isString(value) || isDate(value)) {
        return worksheetDateToInputValue(value);
      }
      return '';
    }
    case 'number': {
      if (isNumber(value) || (isString(value) && value !== '' && isFinite(Number(value)))) {
        return formatNumber(Number(value), field);
      }
      return '';
    }
    case 'calc':
    case 'readOnly': {
      if (isNumber(value)) {
        return formatNumber(value, field);
      }
      if (isString(value) && worksheetDateFormatRegex.test(value)) {
        return worksheetDateToInputValue(value)?.toString() ?? '';
      }
      return value?.toString() ?? '';
    }
    default:
      return value?.toString() ?? '';
  }
};

export const inputToWorksheetValue = (field: WorksheetFieldDefinition, inputValue: InputValue): WorksheetFieldValue => {
  switch (field.fieldType) {
    case 'boolean':
      return isBoolean(inputValue) ? inputValue : undefined;
    case 'number':
      return inputValueToWorksheetNumber(inputValue?.toString() ?? '');
    case 'date':
      if (isString(inputValue) || isDate(inputValue)) {
        return toWorksheetDate(inputValue);
      }
      return undefined;
    default:
      return inputValue?.toString();
  }
};

export const getAutocompleteOptions = (
  sectionId: string,
  entryId: number,
  field: WorksheetFieldDefinition,
  worksheetDefinition: WorksheetDefinition,
  entriesBySectionId: WorksheetEntriesBySectionId
) => {
  let { fieldValues, fieldValuesCalc, fieldValueLabelsCalc } = field;
  let fieldLabels: string[];

  const entry = entriesBySectionId[sectionId]?.find((entry) => entry.id === entryId);
  if (isNil(entry)) return [];
  if (!isNil(fieldValuesCalc)) {
    const result = resolveCalc(
      worksheetDefinition,
      entriesBySectionId,
      fieldValuesCalc,
      field.id,
      entry,
      worksheetDefinition.sectionsBySectionId?.[entry.sectionId]
    );
    fieldValues = Array.isArray(result) ? result : fieldValues;
  }
  if (!isNil(fieldValueLabelsCalc)) {
    const result = resolveCalc(
      worksheetDefinition,
      entriesBySectionId,
      fieldValueLabelsCalc,
      field.id,
      entry,
      worksheetDefinition.sectionsBySectionId?.[entry.sectionId]
    );
    if (Array.isArray(result)) fieldLabels = result;
  }
  return [
    ...sortBy(
      (fieldValues ?? []).map((value, i) => ({
        label: fieldLabels?.[i] ?? `${value}`,
        value,
      })),
      'label'
    ),
  ];
};

function isDataCollectionWorksheet(worksheetDefinition: WorksheetDefinition) {
  const { worksheetName } = worksheetDefinition;
  return worksheetName.startsWith('Tumor Size') || worksheetName.startsWith('Animal Weight');
}

export function isDataCollectionDoseTimeField(
  worksheetDefinition: WorksheetDefinition,
  field: WorksheetFieldDefinition
) {
  return isDataCollectionWorksheet(worksheetDefinition) && field.id === DOSE_TIME;
}

export function isDataCollectionDateField(worksheetDefinition: WorksheetDefinition, field: WorksheetFieldDefinition) {
  return isDataCollectionWorksheet(worksheetDefinition) && field.id === 'date';
}

export function isValidDate(date: Date) {
  return date.toTimeString() !== 'Invalid Date';
}

interface SetDateFromSectionParams {
  entryId: number;
  event: Date;
  sectionId: string;
  worksheetDefinition: WorksheetDefinition;
  worksheetState: State;
}

/**
 * This is pretty awkward bc worksheets...
 *
 * 1. Find the section containing the date that corresponds to this data collection section and column
 * 2. Construct a datetime that includes the date from above and the time chosen here
 */
export function setDateFromSection(params: SetDateFromSectionParams) {
  const { entryId, event, sectionId, worksheetDefinition, worksheetState } = params;

  const columnHeaderSection = worksheetDefinition.sections.find(
    (section) => section.dataCollectionSectionId === sectionId
  );
  if (isNil(columnHeaderSection)) {
    throw new Error(
      `Missing column header section for data collection section ${sectionId} on worksheet ${worksheetDefinition.worksheetName}`
    );
  }

  const currentEntry = worksheetState.entriesBySectionId[sectionId].find((entry) => entry.id === entryId);
  const columnHeaderSectionColumnEntry = worksheetState.entriesBySectionId[columnHeaderSection.id].find(
    (entry) => entry.columnId === currentEntry?.columnId
  );
  const columnHeaderSectionColumnEntryDate = columnHeaderSectionColumnEntry?.data.date;

  // If the user hasn't selected the date yet, just use the current date, this will be updated if they change it
  if (isNil(columnHeaderSectionColumnEntryDate)) {
    return event;
  }

  if (!isString(columnHeaderSectionColumnEntryDate)) {
    throw new Error(
      `Expected 'date' to be a string but was ${typeof columnHeaderSectionColumnEntryDate} in section ${
        columnHeaderSection.sectionName
      } on worksheet ${worksheetDefinition.worksheetName}`
    );
  }

  return DateTime.fromISO(columnHeaderSectionColumnEntryDate).set({
    hour: event.getHours(),
    minute: event.getMinutes(),
  });
}

interface DoseTimeUpdateParams {
  entryId: number;
  event: Date;
  sectionId: string;
  worksheetDefinition: WorksheetDefinition;
  worksheetState: State;
}

/**
 * This is pretty awkward bc worksheets...
 *
 * 1. Find the data collection containing the dose times that corresponds to this section and column
 * 2. Construct a date chosen here and all of the times
 * 3. Construct additional updates for the dose times
 */
export function getDoseTimeUpdates(params: DoseTimeUpdateParams) {
  const { entryId, event, sectionId, worksheetDefinition, worksheetState } = params;

  const currentEntry = worksheetState.entriesBySectionId[sectionId].find((entry) => entry.id === entryId);
  const dataCollectionSectionId = worksheetDefinition.sectionsBySectionId?.[sectionId]?.dataCollectionSectionId;

  if (isNil(dataCollectionSectionId)) {
    throw new Error(
      `Expected section ${sectionId} to have a "dataCollectionSectionId" but it was empty on worksheet ${worksheetDefinition.worksheetName}`
    );
  }

  return worksheetState.entriesBySectionId[dataCollectionSectionId]
    .filter((entry) => entry.columnId === currentEntry?.columnId)
    .filter((entry) => !isNil(entry.data.doseTime))
    .map((dataCollectionEntry) => {
      const currentDoseTimeString = dataCollectionEntry.data.doseTime;
      if (!isString(currentDoseTimeString)) {
        throw new Error(
          `Expected '${DOSE_TIME}' to be a string but was ${typeof currentDoseTimeString} in section ${dataCollectionSectionId} on worksheet ${
            worksheetDefinition.worksheetName
          }`
        );
      }
      const currentDoseTime = new Date(currentDoseTimeString);

      const updatedDoseTime = DateTime.fromJSDate(event)
        .set({ hour: currentDoseTime.getHours(), minute: currentDoseTime.getMinutes() })
        .toString();

      return {
        entryId: dataCollectionEntry.id,
        entries: worksheetState.entries,
        fieldId: DOSE_TIME,
        sectionId: dataCollectionSectionId,
        value: updatedDoseTime,
      };
    });
}
