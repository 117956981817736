import { useEffect, useState } from 'react';
import { CloseOutlined, AddOutlined, ContentCopy } from '@mui/icons-material';
import { get, isNil } from 'lodash';
import toast from 'react-hot-toast';

import { ModelModalContent } from '#components/partials/ConfigureOverview/partials';
import { i18n, AppColors } from '#lib/constants';
import {
  type Model,
  useRemoveModelMutation,
  useDuplicateModelToExperimentMutation,
  type ModelFragment,
} from '#graphql';
import { ConfirmationModal } from '#components/partials';
import { useModal, useUpdateExperiment } from '#lib/hooks';

import {
  StyledCard,
  StyledRow,
  StyledBubble,
  StyledNumber,
  StyledEditButton,
  StyledButtonContainer,
  StyledCloseButton,
  StyledButton,
  StyledLabel,
  StyledBubbleContainer,
  StyledLine,
} from './styles';

export const ModelCard = ({
  onModelCountChanged,
  invalid,
  models,
  experimentId,
  disabled,
}: {
  onModelCountChanged: (modelCount: number) => void;
  invalid: boolean;
  models: ModelFragment[];
  experimentId: number;
  disabled: boolean;
}) => {
  const { updateExperimentCache } = useUpdateExperiment();
  const [selectedModel, setSelectedModel] = useState<Model | undefined>();
  const [editModel, setEditMode] = useState<boolean>(false);
  const [currentModelId, setCurrentModelId] = useState<number>();
  useEffect(() => {
    onModelCountChanged(models?.length ?? 0);
  }, [models?.length]);
  const { Modal, openModal, closeModal } = useModal('Select Details For Model', 'md');

  const {
    Modal: DeleteModal,
    openModal: openDeleteModal,
    closeModal: closeDeleteModal,
  } = useModal(i18n.ModelCard.DeleteConfirmation, 'sm');

  const [duplicateModel] = useDuplicateModelToExperimentMutation({
    onCompleted: (data) => {
      updateExperimentCache({
        models: [...models, data.duplicateAnimalToExperiment],
        id: experimentId,
      });
    },
    onError: () => {
      toast.error(i18n.Common.GenericError, {
        id: 'duplicate-modal-toast',
      });
    },
  });

  const [removeModal] = useRemoveModelMutation({
    onCompleted: (data) => {
      updateExperimentCache({
        models: data.removeModel,
        id: experimentId,
      });
    },
    onError: () => {
      toast.error(i18n.ModelCard.CantDelete);
    },
  });

  const handleDelete = async (id?: number) => {
    closeDeleteModal();
    if (!isNil(id)) {
      toast.loading(i18n.Common.Deleting, { id: 'remove-modal-toast' });
      await removeModal({
        variables: {
          id,
        },
      });
      toast.dismiss('remove-modal-toast');
    }
  };

  const handleEdit = (model: Model) => {
    setSelectedModel(model);
    setEditMode(true);
    openModal();
  };

  const handleAddModel = () => {
    setEditMode(false);
    setSelectedModel(undefined);
    openModal();
  };

  const handleDuplicate = async (id: number) => {
    toast.loading(i18n.Common.Duplicating, { id: 'duplicate-modal-toast' });
    await duplicateModel({
      variables: {
        id,
      },
    });
    toast.dismiss('duplicate-modal-toast');
  };
  const modelFields = [
    ['Cell Line', 'cellLine.name', disabled ? '150px' : '220px', `cellLine`],
    ['Location', 'cellLine.location', '150px', `location`],
    ['Strain', 'strain', disabled ? '250px' : '220px', `strain`],
    ['Species', 'species', '60px', `species`],
    ['Gender', 'gender', '60px', `gender`],
    ['Implant type', 'implant', '90px', `implantation`],
    ['Endpoint', 'subcutaneousEndpoint', '70px', 'subcutaneousEndpoint'],
    ['Treatment Start', 'tumorStartingVolume', '100px', 'tumorStartingVolume'],
  ];

  return (
    <>
      <Modal>
        <ModelModalContent
          handleClose={closeModal}
          selectedModel={selectedModel}
          editModel={editModel}
          experimentId={experimentId}
        />
      </Modal>

      <DeleteModal>
        <ConfirmationModal
          closeModal={() => {
            setCurrentModelId(undefined);
            closeDeleteModal();
          }}
          confirmationFunction={async () => {
            await handleDelete(currentModelId);
          }}
        />
      </DeleteModal>

      {models.map((model) => (
        <StyledCard key={model.id} data-test-id={`modelCard`}>
          <StyledRow>
            <StyledNumber cardcolor={AppColors.SPECTRA_BLUE}>{model.name}</StyledNumber>
            <StyledLine>
              {modelFields.map(([title, objectPath, width, testId], index) => (
                <StyledBubbleContainer key={index} data-test-id={testId}>
                  <StyledLabel>{title}</StyledLabel>
                  <StyledBubble customwidth={width} bubblecolor={AppColors.LOW_GREY}>
                    {get(model, objectPath)}
                  </StyledBubble>
                </StyledBubbleContainer>
              ))}
            </StyledLine>
          </StyledRow>
          {!disabled && (
            <StyledRow gap="5px" isGray data-test-id="modelActions">
              <StyledEditButton
                onClick={() => {
                  handleEdit(model);
                }}
              >
                edit
              </StyledEditButton>
              <StyledCloseButton
                data-test-id={`modelDuplicateButton`}
                marginRight="0"
                onClick={async () => {
                  await handleDuplicate(model.id);
                }}
              >
                <ContentCopy fontSize="small" />
              </StyledCloseButton>
              <StyledCloseButton
                background={AppColors.WHITE}
                onClick={() => {
                  setCurrentModelId(model.id);
                  openDeleteModal();
                }}
              >
                <CloseOutlined fontSize="small" />
              </StyledCloseButton>
            </StyledRow>
          )}
        </StyledCard>
      ))}
      <StyledButtonContainer gap="5px">
        <StyledButton invalid={invalid} data-test-id={`addModel`} disabled={disabled} onClick={handleAddModel}>
          <AddOutlined /> Add Model
        </StyledButton>
      </StyledButtonContainer>
    </>
  );
};
