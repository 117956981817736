import { Checkbox, type CheckboxProps, FormControlLabel, type Theme } from '@mui/material';
import { isNil } from 'lodash';
import { useController, type FieldValues, type UseControllerProps } from 'react-hook-form';

export interface ReactHookCheckboxProps<TFormData extends FieldValues> {
  controller: UseControllerProps<TFormData>;
  error?: boolean;
  label?: string;
  size?: CheckboxProps['size'];
  disabled?: boolean;
}

export function ReactHookCheckbox<TFormData extends FieldValues>({
  controller,
  error = false,
  label,
  size,
  disabled = false,
}: ReactHookCheckboxProps<TFormData>) {
  const {
    field: { onChange, onBlur, name, value, ref },
  } = useController(controller);

  const checkboxColor = {
    color: (theme: Theme) => (error ? theme.palette.error.main : theme.palette.grey[500]),
  };

  const fontColor = {
    color: (theme: Theme) => (error ? theme.palette.error.main : theme.palette.text.primary),
  };

  const checkBox = (
    <Checkbox
      color={error ? 'error' : 'primary'}
      name={name}
      checked={value}
      value={value}
      onBlur={onBlur}
      onChange={onChange}
      inputRef={ref}
      size={size}
      sx={checkboxColor}
      disabled={disabled}
    />
  );

  if (isNil(label)) {
    return checkBox;
  }

  return (
    <FormControlLabel
      componentsProps={{
        typography: {
          sx: {
            ...(size === 'small' ? { fontSize: '.75rem', paddingLeft: '1rem' } : {}),
            ...fontColor,
          },
        },
      }}
      control={checkBox}
      label={label}
    />
  );
}
