import styled from '@emotion/styled';
import { isEmpty, isNil } from 'lodash';

import { Loader } from '#components/widgets';
import {
  usePkBloodWorksheetQuery,
  usePkTissueWorksheetQuery,
  useWorksheetsByExperimentQuery,
  type ExperimentDetailsFragment,
} from '#graphql';
import { i18n } from '#lib/constants';
import { PreparationCard } from './partials';
import { type WorksheetThumbnailWorksheet } from './partials/WorksheetThumbnail';

const WorksheetGroupsContainer = styled.div({
  label: 'WorksheetGroupsContainer',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '2em',
  width: '100%',
  margin: '0 auto',
  maxWidth: '1210px',
});

export const Workspace = ({ experiment }: { experiment: ExperimentDetailsFragment }) => {
  const worksheetsQuery = useWorksheetsByExperimentQuery({
    fetchPolicy: 'no-cache',
    variables: { experimentId: experiment.id },
  });

  const pkBloodQuery = usePkBloodWorksheetQuery({ variables: { experimentId: experiment.id } });

  const pkTissueQuery = usePkTissueWorksheetQuery({ variables: { experimentId: experiment.id } });

  const worksheets: WorksheetThumbnailWorksheet[] = [];

  const legacyWorksheets = worksheetsQuery.data?.worksheetsByExperiment.items;
  if (!isNil(legacyWorksheets)) {
    worksheets.push(...legacyWorksheets);
  }

  const pkBloodWorksheet = pkBloodQuery.data?.pkBloodWorksheet;
  if (!isNil(pkBloodWorksheet)) {
    worksheets.push({
      id: pkBloodWorksheet.id,
      experimentId: pkBloodWorksheet.experimentId,
      name: 'PK Blood',
      type: 'pkBlood',
    });
  }

  const pkTissueWorksheet = pkTissueQuery.data?.pkTissueWorksheet;
  if (!isNil(pkTissueWorksheet)) {
    worksheets.push({
      id: pkTissueWorksheet.id,
      experimentId: pkTissueWorksheet.experimentId,
      name: 'PK Tissue',
      type: 'pkTissue',
    });
  }

  if (worksheetsQuery.loading || pkBloodQuery.loading || pkTissueQuery.loading) {
    return <Loader center message="Loading worksheets" />;
  }

  const error = worksheetsQuery.error ?? pkBloodQuery.error ?? pkTissueQuery.error;

  if (!isNil(error)) {
    return <p>{i18n.ExperimentView.Workspace.QueryError}</p>;
  }

  if (isNil(experiment.treatmentGroups) || isEmpty(experiment.treatmentGroups)) {
    return <p>{i18n.ExperimentView.Workspace.NoTreatmentGroups}</p>;
  }

  return (
    <WorksheetGroupsContainer>
      {isEmpty(worksheets) ? (
        <p>{i18n.ExperimentView.Workspace.NoWorksheets}</p>
      ) : (
        <PreparationCard worksheets={worksheets} models={experiment.models} />
      )}
    </WorksheetGroupsContainer>
  );
};
