import styled from '@emotion/styled';
import { ExperimentBasicInfoFragment } from '#graphql';
import { Tooltip } from '@mui/material';
import { DateTime } from 'luxon';

import { getUserInitials, getUserFullName } from '#lib/utils';
import { getConfiguratorOverviewPath } from '#lib/constants';
import { ArrowTableButton } from '#components/widgets';
import { AppColors } from '#lib/constants';

const StyledContainer = styled.div({
  background: AppColors.WHITE,
  boxShadow: '2px 2px 6px rgba(0, 0, 0, 0.2)',
  borderRadius: '4px',
  height: '125px',
  display: 'flex',
  paddingTop: '5px',
});

const StyledPriorityContainer = styled.div(({ color }: { color: string }) => ({
  background: color,
  fontFamily: 'Roboto',
  color: AppColors.GREY,
  width: '25px',
  height: '125px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '10px',
  borderRadius: '3px',
}));

const StyledContentContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  marginLeft: '15px',
  gap: '5px',
  width: '85%',
});

const StyledTitle = styled.h4({
  color: AppColors.BRAND_GREEN,
  fontWeight: '500',
  fontStyle: 'normal',
  marginTop: '8px',
  fontSize: '14px',
  fontFamily: 'Roboto',
  lineHeight: '24px',
  letterSpacing: '0.15px',
  marginBottom: '0px',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
});

const StyledSubText = styled.span({
  fontFamily: 'Roboto',
  fontSize: '10px',
  lineHeight: '14px',
  fontWeight: 'normal',
  fontStyle: 'normal',
  color: AppColors.DARK_GREY,
});

const StyledButtonContainer = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '10px',
  paddingBottom: '5px',
});

const StyledAvatarContainer = styled.div({
  background: AppColors.LIGHT_LIGHT_GREY,
  width: '150px',
  height: '29px',
  borderRadius: '37px',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: '9px',
});

const StyledInitialsText = styled.div({
  width: '29px',
  height: '29px',
  fontSize: '12px',
  background: AppColors.LIGHT_TURQUOISE,
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontWeight: 'bold',
  lineHeight: '24px',
  letterSpacing: '0.1px',
});

const StyledText = styled.span({
  color: AppColors.DARK_GREY,
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontSize: '10px',
  lineHeight: '20px',
  letterSpacing: '0.25px',
  paddingLeft: '3%',
  width: '70%',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
});

const ArrowTableButtonContainer = styled.div({
  padding: '2px 8px',
  background: '#F7F7F7',
  borderRadius: '37px',
});

const ExperimentCard = ({
  experiment,
  color,
}: {
  experiment: ExperimentBasicInfoFragment;
  color: string;
}) => {
  return (
    <StyledContainer data-test-id={`experimentCard`}>
      <StyledPriorityContainer color={color}></StyledPriorityContainer>

      <StyledContentContainer data-test-id={experiment.name}>
        <StyledTitle>{experiment.approvalName || 'Pending'}</StyledTitle>
        <StyledSubText>{`Experiment Title: ${experiment.name}`}</StyledSubText>
        <StyledSubText>
          {DateTime.fromISO(experiment.submissionDate || '').toFormat('DDD')}
        </StyledSubText>

        <StyledButtonContainer>
          <Tooltip title={getUserFullName(experiment.owner) || ''}>
            <StyledAvatarContainer>
              <StyledInitialsText>
                {getUserInitials(experiment.owner)}
              </StyledInitialsText>
              <StyledText>{getUserFullName(experiment.owner!)}</StyledText>
            </StyledAvatarContainer>
          </Tooltip>
          <ArrowTableButtonContainer>
            <ArrowTableButton
              path={getConfiguratorOverviewPath(experiment.id)}
            />
          </ArrowTableButtonContainer>
        </StyledButtonContainer>
      </StyledContentContainer>
    </StyledContainer>
  );
};

export default ExperimentCard;
