import { useState } from 'react';
import styled from '@emotion/styled';
import { Dropzone, FileItem, FileValidated } from '@dropzone-ui/react';
import toast from 'react-hot-toast';

import { AppColors, i18n } from '#lib/constants';
import { uploadFiles } from '#lib/utils';
import { ButtonNeutralSolid, ButtonPositiveSolid } from '#components/widgets';
import { Attachment } from '#graphql';

const StyledContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',

  '.dropzone-ui': {
    maxWidth: '54em',
    marginBottom: '1em',
  },
});

const ButtonsContainer = styled.div({
  display: 'flex',
  justifyContent: 'center',
  '.Mui-disabled': {
    background: 'none !important',
  },
});

const CancelButton = styled(ButtonNeutralSolid)({
  background: AppColors.WHITE,
  border: `2px solid ${AppColors.BLACK}`,
  borderRadius: '28px',
  color: AppColors.BLACK,
  letterSpacing: '1.25px',
  textTransform: 'uppercase',
  fontSize: '10px',
  fontWeight: '500',
  fontStyle: 'normal',
  fontFamily: 'Roboto',
  padding: '10px',
  width: '100px',
  marginRight: '10px',
  '&:hover': {
    background: AppColors.WHITE,
  },
});

const StyledButton = styled(ButtonPositiveSolid)({
  background: AppColors.BRAND_GREEN,
  borderRadius: '28px',
  color: AppColors.WHITE,
  letterSpacing: '1.25px',
  textTransform: 'uppercase',
  fontSize: '10px',
  fontWeight: 'bold',
  fontStyle: 'normal',
  fontFamily: 'Roboto',
  padding: '10px',
  width: '100px',
  marginBottom: '25px',
  marginLeft: '10px',

  '&:hover': {
    background: AppColors.BRAND_GREEN,
  },
});

interface AddAttachmentsModalProps {
  closeModal: () => void;
  experimentId: number;
  category: string;
  addFiles: (files: Attachment[]) => void;
}

export const AddAttachmentsModal = ({
  closeModal,
  experimentId,
  category,
  addFiles,
}: AddAttachmentsModalProps) => {
  const [files, setFiles] = useState<FileValidated[]>([]);
  const onDeleteFile = (id: string | number | undefined) =>
    setFiles(files.filter((file) => file.id !== id));

  const onHandleFiles = (incomingFiles: FileValidated[]) => {
    setFiles(incomingFiles);
  };

  const onSave = async () => {
    closeModal();
    const toastId = toast.loading(i18n.ExperimentView.UploadingFiles);
    const result = await uploadFiles(experimentId, files, category);
    toast.remove(toastId);
    if (result.errors.length) {
      for (const error of result.errors) {
        toast.error(error);
      }
    }
    if (result.success.length) {
      addFiles(
        result.success.map((nf, i) => ({
          id: nf.id,
          category,
          filename: files[i].file.name,
          contentType: '',
          createdAt: new Date().toISOString(),
        }))
      );
    }
  };

  return (
    <StyledContainer>
      <Dropzone
        onChange={onHandleFiles}
        value={files}
        onClean={() => setFiles([])}
        maxFileSize={104857600}
        maxHeight="10em"
        label="Drop Files here or click to browse"
        behaviour="add"
        color={AppColors.BRAND_GREEN}
      >
        {files.map((file) => (
          <FileItem
            {...file}
            key={file.id}
            onDelete={onDeleteFile}
            alwaysActive
            preview
            info
            elevation={4}
            resultOnTooltip
          />
        ))}
      </Dropzone>
      <ButtonsContainer>
        <CancelButton onClick={closeModal}>Cancel</CancelButton>
        <StyledButton disabled={files.length === 0} onClick={onSave}>
          Save Files
        </StyledButton>
      </ButtonsContainer>
    </StyledContainer>
  );
};
