import { Grid } from '@mui/material';
import { isNil } from 'lodash';
import { FormProvider, useForm } from 'react-hook-form';

import { OmniForm, OmniSelectField, OmniTextField } from '#components/widgets';
import { type Imaging } from '#graphql';
import { i18n } from '#lib/constants';
import { type TreatmentGroupWithTreatment } from '#lib/types';
import { TreatmentGroupsFormSection } from './TreatmentGroupsFormSection';

export interface ImagingFormData {
  instructions: string;
  timePoints: string;
  treatmentGroups: Array<{
    checked: boolean;
    id: number | undefined;
    notes: string;
    treatmentGroupId: number;
  }>;
  /*
    These need to be present in the FormData type so we can "register" the fields to keep
    track of validity for multiple inputs
  */
  treatmentGroupsValidity?: never;
  numViews: string;
}

interface ImagingFormProps {
  imaging?: Imaging;
  handleSubmit?: (data: ImagingFormData) => Promise<void> | void;
  onCancel?: () => void;
  onSuccess?: () => void;
  treatmentGroups: TreatmentGroupWithTreatment[];
}

export function ImagingForm(props: ImagingFormProps) {
  const {
    imaging,
    handleSubmit = () => undefined,
    onCancel = () => undefined,
    onSuccess = () => undefined,
    treatmentGroups,
  } = props;

  const methods = useForm<ImagingFormData>({
    defaultValues: {
      instructions: imaging?.instructions ?? '',
      timePoints: imaging?.timePoints ?? '',
      numViews: imaging?.numViews.toString() ?? '1',
      treatmentGroups: treatmentGroups.map((tg) => {
        const imagingTreatmentGroup = (imaging?.treatmentGroups ?? []).find((bbtg) => bbtg.treatmentGroupId === tg.id);
        return {
          checked: !isNil(imagingTreatmentGroup),
          id: imagingTreatmentGroup?.id ?? undefined,
          notes: imagingTreatmentGroup?.notes ?? '',
          treatmentGroupId: tg.id,
        };
      }),
    },
  });

  return (
    <FormProvider {...methods}>
      <OmniForm
        formText={i18n.Imaging.Form}
        handleSubmit={handleSubmit}
        isNew={isNil(imaging?.id)}
        onCancel={onCancel}
        onSuccess={onSuccess}
      >
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <OmniTextField name="timePoints" required rules={{ required: 'please enter the timepoints' }} />
          </Grid>
          <Grid item xs={6}>
            <OmniTextField multiline name="instructions" />
          </Grid>
          <Grid item xs={6}>
            <OmniSelectField
              name="numViews"
              required
              rules={{ required: 'please select the number of views' }}
              selectOptions={['1', '2']}
              label="Number of Views"
            />
          </Grid>
          <TreatmentGroupsFormSection treatmentGroups={treatmentGroups} />
        </Grid>
      </OmniForm>
    </FormProvider>
  );
}
