import styled from '@emotion/styled';

import MouseIcon from '#assets/icon_mouse.svg';
import RatIcon from '#assets/icon_rat.svg';
import MonkeyIcon from '#assets/icon_monkey.svg';
import { Species } from '#graphql';

const StyledIcon = styled.img({
  width: '15px',
  height: '15px',
});

export const AnimalIcon = ({ specie }: { specie: Species }) => {
  switch (specie) {
    case 'mouse':
      return <StyledIcon src={MouseIcon} />;

    case 'monkey':
      return <StyledIcon src={MonkeyIcon} />;

    case 'rat':
      return <StyledIcon src={RatIcon} />;
  }
};
