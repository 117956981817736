"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toWorksheetDate = exports.isWorksheetDateFormat = exports.toDateTime = exports.isoDateTimeFormatRegex = exports.worksheetDateFormatRegex = exports.inputDateFormatRegex = exports.worksheetDateFormat = exports.inputDateFormat = exports.getSectionById = exports.getNormalizeWorksheetDefinition = exports.getSectionsBySectionId = exports.getDenormalizedWorksheetDefinition = exports.getDenormalizedWorksheetSectionDefinition = exports.getNormalizedSectionDefinition = exports.getNextId = exports.WORKSHEET_ENTRY_STARTING_ID = exports.getColumnIdsBySectionId = exports.getRowIdsBySectionId = exports.getEditableFieldCount = exports.isFieldTypeEditable = exports.isFieldTypeReadOnly = void 0;
const lodash_1 = require("lodash");
const luxon_1 = require("luxon");
const InternalTools_1 = require("./InternalTools");
const isFieldTypeReadOnly = (fieldType) => fieldType === 'readOnly' || fieldType === 'spacer' || fieldType === 'calc';
exports.isFieldTypeReadOnly = isFieldTypeReadOnly;
const isFieldTypeEditable = (fieldType) => !(0, exports.isFieldTypeReadOnly)(fieldType);
exports.isFieldTypeEditable = isFieldTypeEditable;
const getEditableFieldCount = (section) => section.fields?.reduce((count, { fieldType }) => count + ((0, exports.isFieldTypeEditable)(fieldType) ? 1 : 0), 0);
exports.getEditableFieldCount = getEditableFieldCount;
const getRowIdsBySectionId = (entriesBySectionId) => (0, lodash_1.mapValues)(entriesBySectionId, (entries) => [
    ...new Set(entries.filter(({ rowId }) => !!rowId).map(({ rowId }) => rowId)),
].sort());
exports.getRowIdsBySectionId = getRowIdsBySectionId;
const getColumnIdsBySectionId = (entriesBySectionId) => (0, lodash_1.mapValues)(entriesBySectionId, (entries) => [
    ...new Set(entries
        .filter(({ columnId }) => !!columnId)
        .map(({ columnId }) => columnId)),
].sort());
exports.getColumnIdsBySectionId = getColumnIdsBySectionId;
const getLargestId = (ids) => {
    let largestId = '';
    ids.forEach((id) => {
        if (id != null && id > largestId)
            largestId = id;
    });
    return largestId;
};
exports.WORKSHEET_ENTRY_STARTING_ID = '001';
const getNextId = (ids) => {
    if (ids.length === 0)
        return exports.WORKSHEET_ENTRY_STARTING_ID;
    let largestId = getLargestId(ids);
    if (largestId.length == 0)
        return exports.WORKSHEET_ENTRY_STARTING_ID;
    const [digits] = largestId.match(/\d+$/) || [];
    if (!digits)
        return largestId + exports.WORKSHEET_ENTRY_STARTING_ID;
    if (/^9+$/.test(digits))
        return largestId + exports.WORKSHEET_ENTRY_STARTING_ID;
    const lastNum = parseInt(digits);
    return (largestId.slice(0, largestId.length - digits.length) +
        (0, lodash_1.padStart)(`${lastNum + 1}`, digits.length, '0'));
};
exports.getNextId = getNextId;
const getNormalizedSectionDefinition = (section) => ({
    ...section,
    normalized: true,
    fieldsByFieldId: (0, InternalTools_1.getRecordsById)(section.fields),
});
exports.getNormalizedSectionDefinition = getNormalizedSectionDefinition;
const getDenormalizedWorksheetSectionDefinition = (section) => {
    const { normalized, fieldsByFieldId, ...rest } = section;
    return rest;
};
exports.getDenormalizedWorksheetSectionDefinition = getDenormalizedWorksheetSectionDefinition;
const getDenormalizedWorksheetDefinition = (worksheet) => {
    const { sectionsBySectionId, normalized, sections, ...rest } = worksheet;
    return {
        ...rest,
        sections: sections.map(exports.getDenormalizedWorksheetSectionDefinition),
    };
};
exports.getDenormalizedWorksheetDefinition = getDenormalizedWorksheetDefinition;
const getSectionsBySectionId = (sections) => (0, InternalTools_1.getRecordsById)(sections);
exports.getSectionsBySectionId = getSectionsBySectionId;
const getNormalizeWorksheetDefinition = (worksheetDefinition) => {
    if (worksheetDefinition.normalized)
        return worksheetDefinition;
    const normalizedSections = worksheetDefinition.sections.map(exports.getNormalizedSectionDefinition);
    const normalizeWorksheetDefinition = {
        ...worksheetDefinition,
        sections: normalizedSections,
        sectionsBySectionId: (0, exports.getSectionsBySectionId)(normalizedSections),
        normalized: true,
    };
    return normalizeWorksheetDefinition;
};
exports.getNormalizeWorksheetDefinition = getNormalizeWorksheetDefinition;
const getSectionById = (sections, sectionId) => sections.find((s) => s.id === sectionId);
exports.getSectionById = getSectionById;
/*****************************************************
 * DATES
 *****************************************************/
exports.inputDateFormat = 'LL/dd/yyyy';
exports.worksheetDateFormat = 'yyyy-LL-dd';
exports.inputDateFormatRegex = /^\d\d\/\d\d\/\d\d\d\d$/;
exports.worksheetDateFormatRegex = /^\d\d\d\d-\d\d-\d\d$/;
exports.isoDateTimeFormatRegex = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/;
const toDateTime = (input) => {
    if ((0, lodash_1.isDate)(input))
        return luxon_1.DateTime.fromJSDate(input);
    if (exports.worksheetDateFormatRegex.test(input)) {
        return luxon_1.DateTime.fromFormat(input, exports.worksheetDateFormat);
    }
    else if (exports.inputDateFormatRegex.test(input)) {
        return luxon_1.DateTime.fromFormat(input, exports.inputDateFormat);
    }
    else if (exports.isoDateTimeFormatRegex.test(input)) {
        return luxon_1.DateTime.fromFormat(input.slice(0, 10), exports.worksheetDateFormat);
    }
    else
        return undefined;
};
exports.toDateTime = toDateTime;
const isWorksheetDateFormat = (value) => (0, lodash_1.isString)(value) && exports.worksheetDateFormatRegex.test(value);
exports.isWorksheetDateFormat = isWorksheetDateFormat;
const toWorksheetDate = (dateThing) => (0, exports.toDateTime)(dateThing)?.toFormat(exports.worksheetDateFormat);
exports.toWorksheetDate = toWorksheetDate;
