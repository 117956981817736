import { chain, every, groupBy, isNil, map, sortBy } from 'lodash';
import { DateTime } from 'luxon';

import {
  type AdcOrderDetailsFragment,
  type AdcOrderItemDetailsFragment,
  type AdcOrderWithExperimentFragment,
  type ExperimentDetailsFragment,
} from '#graphql';

export const floatFormat = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
});

export function getAdcOrderWithExperiment(
  adcOrder: AdcOrderDetailsFragment,
  experiment: ExperimentDetailsFragment
): AdcOrderWithExperimentFragment {
  return {
    ...adcOrder,
    experiment: {
      ...experiment,
    },
  };
}

function isPresent(value: string | number | null | undefined) {
  return !isNil(value);
}

export function canDeliverAdcOrderItem(adcOrderItem: {
  batchNumber?: AdcOrderItemDetailsFragment['batchNumber'] | null;
  deliveredAmount?: AdcOrderItemDetailsFragment['deliveredAmount'] | null;
  deliveredAt?: AdcOrderItemDetailsFragment['deliveredAt'] | null;
  deliveredConcentration?: AdcOrderItemDetailsFragment['deliveredConcentration'] | null;
  formulation?: AdcOrderItemDetailsFragment['formulation'] | null;
  purpose: AdcOrderItemDetailsFragment['purpose'] | null;
  a280?: AdcOrderItemDetailsFragment['a280'] | null;
}) {
  return (
    !isPresent(adcOrderItem.deliveredAt) &&
    isPresent(adcOrderItem.batchNumber) &&
    isPresent(adcOrderItem.formulation) &&
    isPresent(adcOrderItem.deliveredAmount) &&
    (adcOrderItem.purpose === 'In Vivo Dose' ? isPresent(adcOrderItem.deliveredConcentration) : true) &&
    (adcOrderItem.purpose === 'In Vivo Dose' ? isPresent(adcOrderItem.a280) : true)
  );
}

export function canReturnAdcOrderItem(adcOrderItem: AdcOrderItemDetailsFragment) {
  return isPresent(adcOrderItem.deliveredAt) && !isPresent(adcOrderItem.returnedAt);
}

export function isAdcOrderItemDelivered(adcOrderItem: {
  deliveredAt?: AdcOrderItemDetailsFragment['deliveredAt'] | null;
}) {
  return isPresent(adcOrderItem.deliveredAt);
}

export function canDeliverEveryAdcOrderItem(adcOrderItems: AdcOrderItemDetailsFragment[]) {
  return every(adcOrderItems, canDeliverAdcOrderItem);
}

export function canReturnEveryAdcOrderItem(adcOrderItems: AdcOrderItemDetailsFragment[]) {
  return every(adcOrderItems, canReturnAdcOrderItem);
}

export function canDeliverAllAdcOrderItemGroups(groupedAdcOrderItems: AdcOrderItemsConjugateMap) {
  const allNonDeliveredAdcOrderItems = map(Array.from(groupedAdcOrderItems.values()), 'nonDelivered').flat();

  return allNonDeliveredAdcOrderItems.length > 0 && every(allNonDeliveredAdcOrderItems, canDeliverAdcOrderItem);
}

export function allDeliverableAdcOrderItemIds(groupedAdcOrderItems: AdcOrderItemsConjugateMap) {
  return map(Array.from(groupedAdcOrderItems.values()), 'nonDelivered')
    .filter(canDeliverEveryAdcOrderItem)
    .flat()
    .map((adcOrderItem) => adcOrderItem.id);
}

type BioregId = string;
export interface AdcOrderItemGroup {
  delivered: AdcOrderItemDetailsFragment[];
  nonDelivered: AdcOrderItemDetailsFragment[];
}

class ConjugateMap extends Map<BioregId, AdcOrderItemDetailsFragment['conjugate']> {}

export class AdcOrderItemsConjugateMap extends Map<BioregId, AdcOrderItemGroup> {}

export function groupConjugatesAndAdcOrderItemsByBioregId(
  adcOrderItems: AdcOrderItemDetailsFragment[]
): [ConjugateMap, AdcOrderItemsConjugateMap] {
  const conjugatesByBioregId = new ConjugateMap();
  const adcOrderItemGroupsByBioregId = new AdcOrderItemsConjugateMap();

  for (const adcOrderItem of adcOrderItems) {
    const { bioregId, deliveredAt } = adcOrderItem;

    if (isNil(bioregId)) {
      continue;
    }

    if (!conjugatesByBioregId.has(bioregId)) {
      conjugatesByBioregId.set(bioregId, adcOrderItem.conjugate);
    }

    if (!adcOrderItemGroupsByBioregId.has(bioregId)) {
      adcOrderItemGroupsByBioregId.set(bioregId, {
        delivered: [],
        nonDelivered: [],
      });
    }

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- we set this above
    const groupedAdcOrderItems = adcOrderItemGroupsByBioregId.get(bioregId)!;

    if (!isNil(deliveredAt)) {
      groupedAdcOrderItems.delivered.push(adcOrderItem);
    } else {
      groupedAdcOrderItems.nonDelivered.push(adcOrderItem);
    }
  }

  return [conjugatesByBioregId, adcOrderItemGroupsByBioregId];
}

const toDateTime = (dateStr: string) => DateTime.fromISO(dateStr);
const maxDateTime = (dateTimes: DateTime[]) => DateTime.max(...dateTimes);

export function lastCreatedAt(adcOrderItems: AdcOrderItemDetailsFragment[]): DateTime {
  return chain(adcOrderItems).map('createdAt').compact().map(toDateTime).thru(maxDateTime).value();
}

export function lastDeliveredAt(adcOrderItems: AdcOrderItemDetailsFragment[]): DateTime {
  return chain(adcOrderItems).map('deliveredAt').compact().map(toDateTime).thru(maxDateTime).value();
}

export function lastReturnedAt(adcOrderItems: AdcOrderItemDetailsFragment[]): DateTime {
  return chain(adcOrderItems).map('returnedAt').compact().map(toDateTime).thru(maxDateTime).value();
}

export function formatFloat(value: number | undefined) {
  return !isNil(value) ? floatFormat.format(value) : '';
}

export function formatFloatString(value: string) {
  const numberOrNaN = parseFloat(value.replace(',', ''));
  if (isNaN(numberOrNaN)) {
    return '';
  }
  return floatFormat.format(numberOrNaN);
}

export function ensureBatchNumberSuffix(value: string) {
  return value.replace(/\D/, '').slice(0, 3);
}

export function formatBatchNumberSuffixString(value: string) {
  const replaced = ensureBatchNumberSuffix(value);
  if (replaced.length === 1) {
    return `0${replaced}`;
  }
  return replaced;
}

interface EnsureFloatOpts {
  maxIntegerDigits?: number;
  maxFractionDigits?: number;
}

// For now, restricts num decimal places and integers to 2 digits
// Regex was getting awkward with the digit limits
export function ensureFloat(value: string, opts?: EnsureFloatOpts) {
  const { maxIntegerDigits = 2, maxFractionDigits = 2 } = opts ?? {};

  return value
    .split('.')
    .slice(0, 2)
    .map((str, index) => str.replace(/\D/, '').slice(...(index === 0 ? [0, maxIntegerDigits] : [0, maxFractionDigits])))
    .join('.');
}

export function hasDeliveredItems(adcOrderItems: AdcOrderItemDetailsFragment[]) {
  return adcOrderItems.some((adcOrderItem) => isPresent(adcOrderItem.deliveredAt));
}

export function getConjugateAdcOrderItemsForDisplay(
  bioregId: string,
  adcOrderItemGroup: AdcOrderItemGroup,
  hideDelivered: boolean
) {
  const results = [];

  const { delivered, nonDelivered } = adcOrderItemGroup;

  if (nonDelivered.length > 0) {
    results.push({
      key: `${bioregId}-nonDelivered`,
      adcOrderItems: nonDelivered,
    });
  }

  if (!hideDelivered && delivered.length > 0) {
    const groupedDeliveredAdcOrderItems = groupBy(delivered, 'deliveredAt');

    sortBy(Object.keys(groupedDeliveredAdcOrderItems), (deliveredAt) => {
      const adcOrderItems = groupedDeliveredAdcOrderItems[deliveredAt];
      return lastCreatedAt(adcOrderItems);
    })
      .reverse()
      .forEach((deliveredAt) => {
        const deliveredAdcOrderItems = groupedDeliveredAdcOrderItems[deliveredAt];
        results.push({
          key: `${bioregId}-${deliveredAt}`,
          adcOrderItems: deliveredAdcOrderItems,
        });
      });
  }

  return results;
}
