import styled from '@emotion/styled';
import { TextField } from '@mui/material';

import { AppButton, ButtonNeutral } from '#components/widgets';
import { AppColors } from '#lib/constants';
import { Link } from 'react-router-dom';

export const HeaderWrapper = styled.div({
  label: 'HeaderWrapper',
  height: '101px',
  display: 'flex',
  flexDirection: 'column',
  background: AppColors.LIGHT_LIGHT_GREY,
  boxSizing: 'border-box',
  borderBottom: `1px solid ${AppColors.MERCURY}`,
  padding: '5px 30px 0px 30px',
});

export const TopHalfWrapper = styled.div({
  flex: '1 0 50%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

export const ItemWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
});

export const StyledButton = styled(AppButton)({
  minWidth: '48px',
  textTransform: 'none',
  fontSize: '12px',
  padding: '3px',
  '&:hover': {
    background: AppColors.BRAND_GREEN,
  },
});

export const StyledCircleButton = styled(ButtonNeutral)({
  minWidth: '28px !important',
  padding: 0,
  borderRadius: '50%',
});

export const StyledCircleLink = styled(Link)({
  minWidth: '28px !important',
  padding: '4px',
  borderRadius: '50%',
  height: '28px',
  background: 'white',
  boxSizing: 'border-box',
  border: '2px solid',
  borderColor: AppColors.BLACK,
  color: AppColors.BLACK,
});

export const BottomHalfWrapper = styled.div({
  flex: '1 0 50%',
  display: 'flex',
  justifyContent: 'space-between',
});

export const TabButtonsWrapper = styled.div({
  display: 'flex',
  alignSelf: 'flex-end',
});

export const StyledTextField = styled(TextField)({
  div: {
    fontSize: '18px',
    fontWeight: 'bold',
    lineHeight: '24px',
    width: '30em',
  },
  fieldset: {
    border: 'transparent',
  },
  input: {
    padding: 0,
  },
});
