import { Box, InputLabel, Stack, Typography } from '@mui/material';
import { compact, difference, isEmpty } from 'lodash';

import {
  type ExperimentDetailsFragment,
  type ListContentDetailsFragment,
  type TissueBioanalysisFragment,
} from '#graphql';
import { DenseTable, Panel } from '#rds';

interface TissueBioanalysisDataProps {
  bioanalysisTimepoints: ListContentDetailsFragment[];
  tissueBioanalysis: TissueBioanalysisFragment;
  experiment: ExperimentDetailsFragment;
}

export function TissueBioanalysisData(props: TissueBioanalysisDataProps) {
  const { bioanalysisTimepoints, experiment, tissueBioanalysis } = props;

  const nonDefaultCollectionScheduleGroups =
    tissueBioanalysis.collectionSchedules?.flatMap((collectionSchedule) => collectionSchedule.onlyGroups) ?? [];

  const treatmentGroupIndices = compact(
    tissueBioanalysis.treatmentGroups.map(
      (bioanalysisTreatmentGroup) =>
        experiment.treatmentGroups.find((tg) => tg.id === bioanalysisTreatmentGroup.treatmentGroupId)
          ?.treatmentGroupIndex
    )
  );
  const defaultCollectionScheduleGroups = difference(treatmentGroupIndices, nonDefaultCollectionScheduleGroups);

  const tissuesColumns = [
    { field: 'tissue', headerName: 'Tissue' },
    { field: 'method', headerName: 'Method' },
    {
      field: 'includeWeight',
      headerName: 'Include Weight',
      renderCell(row: (typeof tissuesRows)[number]) {
        return row.includeWeight ? 'Y' : '';
      },
    },
    { field: 'notes', headerName: 'Notes' },
  ];

  const tissuesRows = tissueBioanalysis.tissues.flatMap((tissue) =>
    tissue.handlingAndStorage.map((handlingAndStorage) => ({
      tissue: tissue.tissue,
      method: handlingAndStorage.method,
      includeWeight: handlingAndStorage.includeWeight,
      notes: tissue.notes,
    }))
  );

  const groupsColumns = compact([
    { field: 'treatmentGroupIndex', headerName: 'Group' },
    { field: 'treatmentNumber', headerName: 'Treatment' },
    { field: 'testArticle', headerName: 'Test Article' },
    { field: 'dose', headerName: 'Dose' },
    { field: 'schedule', headerName: 'Schedule' },
    tissueBioanalysis.collectionCriteria === 'dose'
      ? {
          field: 'isBasis',
          headerName: 'Is Basis',
          renderCell(row: (typeof groupsRows)[number]) {
            return row.isBasis ? 'Y' : '';
          },
        }
      : null,
    { field: 'notes', headerName: 'Notes' },
  ]);

  const groupsRows = tissueBioanalysis.treatmentGroups.flatMap((tissueBioanalysisTreatmentGroup) => {
    const { treatmentGroupId, baseTestArticleId: _baseTestArticleId } = tissueBioanalysisTreatmentGroup;
    const treatmentGroup = experiment.treatmentGroups.find((tg) => tg.id === treatmentGroupId);
    const treatment = experiment.treatments.find((treatment) => treatment.id === treatmentGroup?.treatmentId);
    const testArticles = treatment?.testArticles;
    return (testArticles ?? []).map((testArticle) => ({
      treatmentGroupIndex: treatmentGroup?.treatmentGroupIndex.toString(),
      treatmentNumber: treatment?.number.toString(),
      testArticle: testArticle.name,
      dose: `${testArticle.dosage} ${testArticle.doseUnit}`,
      schedule: `q${testArticle.interval}dx${testArticle.doseNumber}`,
      isBasis: false,
      notes: treatmentGroup?.notes,
    }));
  });

  const collectionSchedulesColumns = compact([
    { field: 'index', headerName: 'Index' },
    { field: 'groups', headerName: 'Groups' },
    tissueBioanalysis.collectionCriteria === 'dose' ? { field: 'dose', headerName: 'Dose' } : null,
    { field: 'timepoint', headerName: 'Collection Point' },
    { field: 'animals', headerName: 'Animals' },
  ]);

  const collectionSchedulesRows = (tissueBioanalysis.collectionSchedules ?? []).flatMap((schedule, scheduleIndex) => {
    return schedule.timepoints.map((timepoint) => ({
      index: schedule.isDefault ? 'Default' : (scheduleIndex + 1).toString(),
      groups: schedule.isDefault
        ? isEmpty(defaultCollectionScheduleGroups)
          ? 'none'
          : defaultCollectionScheduleGroups.join(', ')
        : schedule.onlyGroups.join(', '),
      dose: timepoint.dose,
      timepoint:
        bioanalysisTimepoints.find((bioanalysisTimepoint) => bioanalysisTimepoint.name === timepoint.minutes.toString())
          ?.description ?? '',
      animals: isEmpty(timepoint.onlyAnimals) ? 'all' : timepoint.onlyAnimals.join(', '),
    }));
  });

  return (
    <Box paddingX={4} paddingY={2}>
      <Panel title="Tissue Bioanalysis" defaultExpanded>
        <Stack spacing={3}>
          <Stack spacing={2} direction="row">
            <Stack spacing={1.5}>
              <InputLabel>Stains</InputLabel>
              <Typography noWrap variant="body2" title={tissueBioanalysis.stains ?? ''}>
                {tissueBioanalysis.stains ?? ''}
              </Typography>
            </Stack>
            <Stack spacing={1.5}>
              <InputLabel>Destination</InputLabel>
              <Typography noWrap variant="body2" title={tissueBioanalysis.destination ?? ''}>
                {tissueBioanalysis.destination ?? ''}
              </Typography>
            </Stack>
          </Stack>

          <Stack spacing={2}>
            <InputLabel>Tissue(s), Handling and Storage</InputLabel>
            <DenseTable
              columns={tissuesColumns}
              label="tissues"
              rows={tissuesRows}
              rowGrouping={['tissue', 'notes']}
              striped
            />
          </Stack>

          <Stack spacing={2}>
            <InputLabel>Groups</InputLabel>
            <DenseTable
              columns={groupsColumns}
              label="groups"
              rows={groupsRows}
              rowGrouping={['treatmentGroupIndex', 'treatmentNumber', 'notes']}
              striped
            />
          </Stack>

          <Stack spacing={2}>
            <InputLabel>Collection Schedules</InputLabel>
            <DenseTable
              columns={collectionSchedulesColumns}
              label="collectionSchedules"
              rows={collectionSchedulesRows}
              rowGrouping={['index', 'groups']}
              striped
            />
          </Stack>
        </Stack>
      </Panel>
    </Box>
  );
}
