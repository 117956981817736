import styled from '@emotion/styled';
import { isNil } from 'lodash';
import React from 'react';

import { RoundedButtonWithAddIcon } from '#components/widgets';
import { formatId } from '#lib/utils';
import { ChartSection } from './ChartSection';
import { DataCollectionSection } from './DataCollectionSection';
import { FormSection } from './FormSection';
import { SectionHeader } from './SectionHeader';
import { gridSize } from './SectionStyles';
import { TableSection } from './TableSection';
import { type SectionRenderProps } from './WorksheetComponentInterfaces';

const SectionRenderers = {
  form: FormSection,
  table: TableSection,
  dataCollection: DataCollectionSection,
  chart: ChartSection,
  hidden: ChartSection, // this will never get used, but Typescript whines
  attachments: ChartSection, // this will never get used, but Typescript whines
};

const SectionContainer = styled.div({
  label: 'SectionContainer',
  padding: '15px 0',
});

const ButtonSection = styled.div({
  label: 'ButtonSection',
  display: 'flex',
  gap: gridSize,
});

const AddRowButton = (props: SectionRenderProps) => (
  <RoundedButtonWithAddIcon
    onClick={() => {
      void props.worksheetMutations.addRow({ sectionId: props.section.id });
    }}
    data-test-id={`add${props.section.rowName}Button`}
    text={`add ${props.section.rowName ?? 'row'}`}
  />
);

const AddColumnButton = (props: SectionRenderProps) => (
  <RoundedButtonWithAddIcon
    onClick={() => {
      void props.worksheetMutations.addColumn({ sectionId: props.section.id });
    }}
    text={`add ${props.section.columnName ?? 'column'}`}
  />
);

export const SectionViewCore = (props: SectionRenderProps) => {
  const { section } = props;
  const SectionRenderer = SectionRenderers[section.sectionType];
  if (isNil(SectionRenderer)) {
    console.error(`No section renderer for sectionType: ${section.sectionType}`);
    return <></>;
  }
  return (
    <SectionContainer data-test-id={formatId(section.sectionName)}>
      {section.sectionName ?? <SectionHeader section={section} />}
      <SectionRenderer key={section.id} {...props} />
      <ButtonSection>
        {section.allowAddRows === true ? <AddRowButton {...props} /> : undefined}
        {section.allowAddColumns === true ? <AddColumnButton {...props} /> : undefined}
      </ButtonSection>
    </SectionContainer>
  );
};

export const SectionView = React.memo(SectionViewCore);
