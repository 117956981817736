import { useAbility } from '@casl/react';
import { Stack } from '@mui/material';
import { useParams } from 'react-router-dom';
import { z } from 'zod';

import { AbilityContext } from '#components/contexts';
import { HandleNetworkLifecycle } from '#components/widgets';
import { i18n } from '#lib/constants';
import { startCase } from 'lodash';
import { Collections } from '../Collections';
import { Configuration } from '../Configuration';
import { Header } from '../Header';
import { TissueBioanalysisData } from '../TissueBioanalysisData';
import { Title } from '../Title';
import { usePKTissueWorksheetPage } from './usePKTissueWorksheetPage';

const Params = z.object({
  experimentId: z.coerce.number(),
});

export function PKTissueWorksheetPage() {
  const { experimentId } = Params.parse(useParams());

  const { data, error, loading } = usePKTissueWorksheetPage(experimentId);

  const ability = useAbility(AbilityContext);

  return (
    <HandleNetworkLifecycle
      errorMessage={i18n.PKTissueWorksheet.emptyMessage}
      error={error}
      loading={loading}
      data={data}
      render={({ data: [experiment, worksheet, tissueBioanalysis, bioanalysisTimepoints] }) => {
        const timepoints = tissueBioanalysis.collectionSchedules?.flatMap((schedule) => schedule.timepoints) ?? [];

        const abilitySubject = { ...worksheet, experiment };
        const canUpdateNotes = ability.can('update', abilitySubject, 'notes');
        const canUpdateBasisDate = ability.can('update', abilitySubject, 'basisDate');
        const canClearActualTimes = ability.can('clearActualTimes', abilitySubject);
        const canUpdateActualTime = ability.can('updateActualTimes', abilitySubject, 'actualTime');

        return (
          <Stack>
            <Header
              bioanalysisTimepoints={bioanalysisTimepoints}
              canClearActualTimes={canClearActualTimes}
              worksheet={worksheet}
            />
            <Title
              subtitle={`Collection Criteria: ${startCase(worksheet.collectionCriteria)}`}
              title={experiment.approvalName ?? experiment.name}
            />
            <Configuration
              canUpdateBasisDate={canUpdateBasisDate}
              canUpdateNotes={canUpdateNotes}
              worksheet={worksheet}
            />
            <TissueBioanalysisData
              bioanalysisTimepoints={bioanalysisTimepoints}
              tissueBioanalysis={tissueBioanalysis}
              experiment={experiment}
            />
            <Collections
              bioanalysisTimepoints={bioanalysisTimepoints}
              canUpdateActualTime={canUpdateActualTime}
              collections={worksheet.pkTissueWorksheetCollections}
              collectionCriteria={tissueBioanalysis.collectionCriteria}
              experiment={experiment}
              timepoints={timepoints}
              worksheetId={worksheet.id}
              tissues={tissueBioanalysis.tissues}
            />
          </Stack>
        );
      }}
    />
  );
}
