import { Button } from '#rds';
import {
  InputLabel,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  tableCellClasses,
  type TableCellProps,
  type TableRowProps,
} from '@mui/material';
import { capitalize, isNil, isUndefined } from 'lodash';
import { type PropsWithChildren, type ReactElement, type ReactNode } from 'react';

export interface OmniTableColDef<TableRowModel> {
  field: string;
  headerName?: string;
  renderCell?: (row: TableRowModel) => React.ReactNode;
  renderHeader?: (column: OmniTableColDef<TableRowModel>) => React.ReactNode;
  tableCellProps?: (row: TableRowModel) => TableCellProps;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- required to allow the definition of arbitrary row types
export type OmniTableRowModel = Record<string, any>;

export type OmniTableRowDef<TableRowModel> = TableRowModel & {
  tableRowProps?: TableRowProps;
};

interface HeaderAction {
  label: string;
  onClick: TableActionProps['onClick'];
}

interface GroupedRows<TableRowModel> {
  grouped: true;
  rows: Record<string, Array<OmniTableRowDef<TableRowModel>>>;
}

interface Rows<TableRowModel> {
  grouped?: false | undefined;
  rows: Array<OmniTableRowDef<TableRowModel>>;
}

type OmniTableProps<TableRowModel> = {
  columns: Array<OmniTableColDef<TableRowModel>>;
  headerActions?: HeaderAction[];
  headerLabel?: string;
  headerFields?: ReactElement;
  size?: 'small' | 'medium';
  name?: string;
} & (Rows<TableRowModel> | GroupedRows<TableRowModel>);

const headerId = 'omniTableHeader';

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- required to allow the definition of arbitrary row types
export function OmniTable<TableRowModel extends Record<string, any>>(props: OmniTableProps<TableRowModel>) {
  const { columns, grouped, headerActions = [], headerFields, headerLabel, rows, size = 'small' } = props;

  const groups = grouped === true ? Object.keys(rows) : ([''] as const);

  return (
    <TableContainer>
      {!isNil(headerLabel) && (
        <>
          <TableHeader id={headerId} label={headerLabel}>
            <TableActions>
              {headerActions.map(({ label, onClick }) => (
                <TableAction key={label} onClick={onClick}>
                  {label}
                </TableAction>
              ))}
            </TableActions>
          </TableHeader>
          <TableHead>{headerFields}</TableHead>
        </>
      )}
      <Table
        size={size}
        aria-labelledby={headerId}
        sx={{
          [`& .${tableCellClasses.head}`]: {
            fontWeight: 600,
          },
        }}
      >
        <TableHead>
          <TableRow>
            {columns.map((column, columnIndex) => {
              const { field, headerName, renderHeader } = column;
              const value = !isNil(renderHeader) ? renderHeader(column) : headerName ?? capitalize(field);
              return (
                <TableCell key={columnIndex}>
                  <Typography fontSize=".625rem" fontWeight="bold">
                    {value}
                  </Typography>
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        {groups.map((group, groupIndex) => {
          const groupRows = grouped === true ? rows[group] : rows;
          return (
            <TableBody
              key={groupIndex}
              className={grouped === true ? 'grouped' : ''}
              sx={{
                '.MuiTableCell-root .MuiRadio-root, .MuiTableCell-root .MuiCheckbox-root, ': {
                  padding: '0px',
                },
                '& .MuiTableCell-root.notFirstRowOfGroup': {
                  paddingTop: '0px',
                },
              }}
            >
              {groupRows.map((row, rowIndex) => {
                const tableRowProps = row.tableRowProps ?? {};
                return (
                  <TableRow key={rowIndex} {...tableRowProps}>
                    {columns.map((column, columnIndex) => {
                      const {
                        //
                        renderCell,
                        tableCellProps = () => ({}),
                      } = column;

                      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- for now required to allow the definition of arbitrary row types
                      const renderValue = isUndefined(renderCell) ? row[column.field] ?? '' : renderCell(row);

                      return (
                        <TableCell key={`${rowIndex}-${columnIndex}`} {...tableCellProps(row)}>
                          {renderValue}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}

              {groups.length > 1 && groupIndex !== groups.length - 1 && (
                <TableRow className="group-row">
                  <TableCell colSpan={columns.length} height="24px"></TableCell>
                </TableRow>
              )}
            </TableBody>
          );
        })}
      </Table>
    </TableContainer>
  );
}

type TableHeaderProps = PropsWithChildren<{
  id: string;
  label: string;
}>;

function TableHeader({ children, id, label }: TableHeaderProps) {
  return (
    <Stack direction="row" spacing={2} alignItems="baseline">
      <InputLabel id={id}>{label}</InputLabel>
      {children}
    </Stack>
  );
}

type TableActionProps = PropsWithChildren<{
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}>;

function TableAction({ children, onClick }: TableActionProps) {
  return (
    <Button variant="text" onClick={onClick} sx={{ minWidth: '0px' }}>
      <Typography sx={{ fontSize: '.625rem', textDecoration: 'underline' }}>{children}</Typography>
    </Button>
  );
}

function TableActions({ children }: { children: ReactNode }) {
  return <Stack direction="row">{children}</Stack>;
}
