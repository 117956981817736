import { Container } from '@mui/material';
import { useParams } from 'react-router';
import { useCroCellLineQuery, useUpdateCroCellLineMutation } from '#graphql';
import { Loader } from '#components/widgets';
import { CroCellLineForm, type CroCellLineFormData } from './CroCellLineForm';
import { isNil } from 'lodash';

export function EditCroCellLine() {
  const [updateCroCellLine] = useUpdateCroCellLineMutation();
  const { id } = useParams();

  const { data, error, loading } = useCroCellLineQuery({
    variables: {
      id: Number(id),
    },
  });

  if (loading) {
    return <Loader />;
  }

  if (!isNil(error)) {
    console.error({ error });
    return null;
  }

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-non-null-asserted-optional-chain
  const cellLine = data?.croCellLine!;

  async function handleSubmit(data: CroCellLineFormData) {
    await updateCroCellLine({
      variables: {
        input: {
          id: cellLine.id,
          ...data,
        },
      },
    });
  }

  return (
    <Container maxWidth="sm">
      <CroCellLineForm cellLine={cellLine} handleSubmit={handleSubmit} />
    </Container>
  );
}
