import { useCallback } from 'react';

import { ExperimentByIdDocument, useCreateTissueBioanalysisMutation } from '#graphql';
import { type TreatmentGroupWithTreatment } from '#lib/types';
import { TissueBioanalysisForm } from './TissueBioanalysisForm';
import { formDataToCreateInput, type TissueBioanalysisFormData } from './tissueBioanalysis';

interface CreateTissueBioanalysisProps {
  experimentId: number;
  onCancel?: () => void;
  onSuccess?: () => void;
  treatmentGroups: TreatmentGroupWithTreatment[];
}

export function CreateTissueBioanalysis(props: CreateTissueBioanalysisProps) {
  const { experimentId, onCancel = () => undefined, onSuccess = () => undefined, treatmentGroups } = props;

  const [createTissueBioanalysis] = useCreateTissueBioanalysisMutation({
    awaitRefetchQueries: true,
    refetchQueries: [ExperimentByIdDocument, 'experimentById'],
  });

  const handleSubmit = useCallback(
    async function handleSubmit(data: TissueBioanalysisFormData) {
      await createTissueBioanalysis({
        variables: {
          input: formDataToCreateInput(experimentId, data, treatmentGroups),
        },
      });
    },
    [createTissueBioanalysis, treatmentGroups]
  );

  return (
    <TissueBioanalysisForm
      handleSubmit={handleSubmit}
      onCancel={onCancel}
      onSuccess={onSuccess}
      treatmentGroups={treatmentGroups}
    />
  );
}
