import { Button as MuiButton, type ButtonProps } from '@mui/material';

const styleOverrides = {
  borderRadius: '1.25rem',
  minWidth: '100px',
  textTransform: 'none',
} as const;

export default function Button(props: ButtonProps) {
  return <MuiButton {...props} sx={{ ...styleOverrides, ...props.sx }} />;
}
