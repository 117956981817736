import { Radio, type Theme } from '@mui/material';
import { useController, type UseControllerProps } from 'react-hook-form';

export interface ReactHookRadioButtonProps<TValue> {
  controller: UseControllerProps;
  disabled?: boolean;
  error?: boolean;
  value: TValue;
}

export default function ReactHookRadioButton<TValue>({
  controller,
  disabled,
  error = false,
  value,
}: ReactHookRadioButtonProps<TValue>) {
  const {
    field: { onChange, onBlur, name, value: fieldValue, ref },
  } = useController(controller);

  const color = {
    color: (theme: Theme) => (error ? theme.palette.error.main : theme.palette.grey[500]),
  };

  return (
    <Radio
      color={error ? 'error' : 'primary'}
      name={name}
      checked={value === fieldValue}
      value={value}
      onBlur={onBlur}
      onChange={onChange}
      inputRef={ref}
      disabled={disabled}
      sx={color}
    />
  );
}
