import { HandleNetworkLifecycle } from '#components/widgets';
import {
  ExperimentByIdDocument,
  useDeleteTissueBioanalysisMutation,
  useListContentsQuery,
  type TissueBioanalysisFragment,
} from '#graphql';
import { i18n } from '#lib/constants';
import { type TreatmentGroupWithTreatment } from '#lib/types';
import { startCase } from 'lodash';
import { TissueBioanalysisDataView } from './TissueBioanalysisDataView';

interface TissueBioanalysisDataProps {
  tissueBioanalysis: TissueBioanalysisFragment;
  canEdit: boolean;
  handleEdit: () => void;
  treatmentGroups: TreatmentGroupWithTreatment[];
}
export function TissueBioanalysisData(props: TissueBioanalysisDataProps) {
  const { tissueBioanalysis, canEdit, handleEdit, treatmentGroups } = props;

  const { data, error, loading } = useListContentsQuery({
    fetchPolicy: 'cache-and-network',
  });

  const [deleteTissueBioanalysis] = useDeleteTissueBioanalysisMutation({
    awaitRefetchQueries: true,
    refetchQueries: [ExperimentByIdDocument, 'experimentById'],
  });

  async function onDelete() {
    await deleteTissueBioanalysis({
      variables: {
        input: {
          id: tissueBioanalysis.id,
        },
      },
    });
  }

  const definitions = {
    'Collection Criteria': startCase(tissueBioanalysis.collectionCriteria),
    Stains: tissueBioanalysis.stains ?? '-',
    Destination: tissueBioanalysis.destination,
  };

  return (
    <HandleNetworkLifecycle
      data={data?.listContents.items}
      error={error}
      loading={loading}
      render={({ data: listContent }) => (
        <TissueBioanalysisDataView
          collectionCriteria={tissueBioanalysis.collectionCriteria}
          bioanalysisTreatmentGroups={tissueBioanalysis.treatmentGroups}
          canEdit={canEdit}
          definitions={definitions}
          deleteTexts={i18n.TissueBioanalysis}
          handleEdit={handleEdit}
          onDelete={onDelete}
          treatmentGroups={treatmentGroups}
          collectionSchedules={tissueBioanalysis.collectionSchedules}
          bioanalysisTimepoints={listContent.filter((lc) =>
            ['bioanalysisTimepoint', 'bioanalysisTumorVolumeTimepoint'].includes(lc.category)
          )}
          tissues={tissueBioanalysis.tissues}
        />
      )}
    />
  );
}
