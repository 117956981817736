import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import bgImage from '#assets/login-bg-image.png';
import { AppColors } from '#lib/constants';

const StyledContainer = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundImage: `url(${bgImage})`,
  height: '100vh',
});

const StyledBox = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  background: AppColors.WHITE,
  borderRadius: '4px',
  boxShadow: '2px 2px 6px rgba(0,0,0,0.2)',
  padding: '30px',
});

const StyledTitleText = styled.h3(
  ({ size, align }: { size: string; align?: boolean }) => ({
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: size,
    alignSelf: align ? 'flex-start' : '',
    lineHeight: '24px',
    color: AppColors.BLACK,
  })
);

const StyledSubTitleText = styled.span({
  fontFamily: 'Roboto',
  fontSize: '14px',
  fontWeight: '400',
  color: AppColors.GREY,
});

const StyledLink = styled(Link)({
  marginTop: '20px',
  display: 'block',
  color: '#444499',
});

export function NotFound() {
  return (
    <StyledContainer>
      <StyledBox>
        <StyledTitleText size="18px">404</StyledTitleText>
        <StyledSubTitleText>
          Sorry, the page you requested does not exist.
        </StyledSubTitleText>
        <StyledLink to="/">Back to safety</StyledLink>
      </StyledBox>
    </StyledContainer>
  );
}
