import { useState } from 'react';
import styled from '@emotion/styled';
import { useApolloClient } from '@apollo/client';

import {
  useExperimentOrdersQuery,
  OrdersDetailsFragment,
  ExperimentOrdersDocument,
  ExperimentDetailsFragment,
} from '#graphql';
import { Loader, MessageBox } from '#components/widgets';
import { Refresh, AdcOrder } from '#components/partials/TestArticles/partials';
import { i18n } from '#lib/constants';
import { isApproved } from '#lib/utils';
import { Can } from '#components/contexts';

const Orders = styled.div({
  display: 'flex',
  flexDirection: 'column',
  paddingBottom: '50px',
  margin: '0 20px',
  width: '1200px',
  overflowX: 'auto',
});

const Header = styled.header({ display: 'flex', alignItems: 'center' });

const MainTitle = styled.h4({
  fontSize: '18px',
  marginLeft: '10px',
});

const PreviewBanner = styled.div({
  position: 'fixed',
  left: '50%',
  fontSize: '300px',
  transform: 'rotate(-45deg)',
  opacity: 0.05,
  width: '1050px',
  margin: '250px 0 0 -525px',
  pointerEvents: 'none',
});

export const TestArticles = ({
  experiment,
}: {
  experiment: ExperimentDetailsFragment;
}) => {
  const client = useApolloClient();
  const [orders, setOrders] = useState<OrdersDetailsFragment>({});
  const { loading } = useExperimentOrdersQuery({
    variables: { experimentId: experiment.id },
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      setOrders(data.experimentOrders);
    },
  });

  if (loading || !orders) {
    return <Loader message="Loading Orders..." />;
  }

  const hasOrders = () => {
    return !!(orders?.adcOrder || orders?.animalOrder || orders?.nonAdcOrder);
  };

  const isPreview = () => !isApproved(experiment) && hasOrders();

  return (
    <Orders>
      {isPreview() && <PreviewBanner>Preview</PreviewBanner>}
      <Header>
        <MainTitle>Orders</MainTitle>
        <Can I="update" this={experiment as never} field="*">
          <Refresh
            experimentId={experiment.id}
            onUpdate={(newOrders: OrdersDetailsFragment) => {
              setOrders(newOrders);
              client.writeQuery({
                query: ExperimentOrdersDocument,
                data: { experimentOrders: newOrders },
                variables: { experimentId: experiment.id },
              });
            }}
          />
        </Can>
      </Header>

      {orders.adcOrder ? (
        <AdcOrder adcOrder={orders.adcOrder} experiment={experiment} />
      ) : (
        <MessageBox>{i18n.Orders.EmptyOrders}</MessageBox>
      )}
    </Orders>
  );
};
