import { Box, Paper, Stack, Typography } from '@mui/material';

import { type PkTissueWorksheetFragment } from '#graphql';
import { BasisDate } from './BasisDate';
import { Notes } from './Notes';

interface ConfigurationProps {
  canUpdateBasisDate: boolean;
  canUpdateNotes: boolean;
  worksheet: Pick<PkTissueWorksheetFragment, 'id' | 'collectionCriteria' | 'notes' | 'status'>;
}

export function Configuration(props: ConfigurationProps) {
  const { canUpdateBasisDate, canUpdateNotes, worksheet } = props;
  return (
    <Box paddingX={4} paddingY={2}>
      <Paper>
        <Box padding={2}>
          <Stack spacing={3}>
            <Typography variant="h3">Configuration</Typography>
            <Stack spacing={2} direction="row">
              {worksheet.collectionCriteria === 'dose' ? (
                <BasisDate canUpdateBasisDate={canUpdateBasisDate} worksheet={worksheet} />
              ) : null}
              <Notes worksheet={worksheet} canUpdateNotes={canUpdateNotes} />
            </Stack>
          </Stack>
        </Box>
      </Paper>
    </Box>
  );
}
