import { AddOutlined } from '@mui/icons-material';
import { isNil } from 'lodash';

import { type Imaging } from '#graphql';
import { useModal } from '#lib/hooks';
import { type TreatmentGroupWithTreatment } from '#lib/types';
import { StyledButton, StyledButtonContainer } from '../Cards/styles';
import { ImagingData } from './ImagingData';
import { ImagingModal } from './ImagingModal';

interface ImagingProps {
  canEdit: boolean;
  experimentId: number;
  imaging?: Imaging;
  treatmentGroups: TreatmentGroupWithTreatment[];
}

export function ImagingSection({
  canEdit,
  experimentId,
  imaging,
  treatmentGroups,
}: ImagingProps) {
  const { Modal, openModal, closeModal } = useModal(
    `${!isNil(imaging) ? 'Edit' : 'Add'} Imaging`,
    'lg'
  );

  return (
    <div>
      <Modal>
        <ImagingModal
          closeModal={closeModal}
          experimentId={experimentId}
          imaging={imaging}
          treatmentGroups={treatmentGroups}
        />
      </Modal>

      {!isNil(imaging) && (
        <ImagingData
          canEdit={canEdit}
          handleEdit={openModal}
          imaging={imaging}
          treatmentGroups={treatmentGroups}
        />
      )}

      {canEdit && isNil(imaging) && (
        <StyledButtonContainer gap="5px">
          <StyledButton onClick={openModal} disabled={treatmentGroups.length === 0}>
            <AddOutlined /> Add Imaging
          </StyledButton>
        </StyledButtonContainer>
      )}
    </div>
  );
}
