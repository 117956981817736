import { InputAdornment, TextField } from '@mui/material';
import { type GridRowId } from '@mui/x-data-grid-premium';
import { debounce, isNil } from 'lodash';
import { useCallback, useEffect, useState, type ChangeEvent } from 'react';

import { useRowContext } from './rowContext';

const maxDigits = 4;

const regex = new RegExp(`^\\d{0,${maxDigits}}$`);

interface DetailPanelRowIntegerFieldProps {
  disabled?: boolean;
  field: string;
  handlingAndStorageId: number;
  rowId: GridRowId;
  value: number | undefined;
}

function formatValue(value: number | undefined) {
  return isNil(value) ? '' : value.toString();
}

function parseValue(value: string | null) {
  return isNil(value) || value === '' ? null : parseInt(value);
}

export function DetailPanelRowIntegerField(props: DetailPanelRowIntegerFieldProps) {
  const { disabled = false, field, handlingAndStorageId, rowId, value: defaultValue } = props;

  const { updateHandlingAndStorageWeight } = useRowContext();
  const [value, setValue] = useState(formatValue(defaultValue));
  const [dirty, setDirty] = useState(false);

  function onChange(event: ChangeEvent<HTMLInputElement>) {
    setDirty(true);
    if (regex.test(event.target.value)) {
      setValue(event.target.value);
    }
  }

  const update = useCallback(
    debounce((value: number | null) => {
      void updateHandlingAndStorageWeight(rowId, handlingAndStorageId, value);
    }, 300),
    [handlingAndStorageId]
  );

  useEffect(() => {
    if (!dirty) {
      return;
    }

    update(parseValue(value));
  }, [value]);

  useEffect(() => {
    return () => {
      update.flush();
    };
  }, [update]);

  return (
    <TextField
      name={field}
      value={value}
      disabled={disabled}
      inputProps={{
        inputMode: 'numeric',
        sx: {
          textAlign: 'right',
        },
      }}
      InputProps={{
        endAdornment: <InputAdornment position="end">mg</InputAdornment>,
      }}
      onChange={onChange}
    />
  );
}
