import styled from '@emotion/styled';
import { ModeEdit } from '@mui/icons-material';
import { find, isNil } from 'lodash';
import { Link } from 'react-router-dom';

import CalculatorIcon from '#assets/calculator.svg';
import DocumentIcon from '#assets/document.svg';
import { type ExperimentDetailsFragment } from '#graphql';
import { AppColors } from '#lib/constants';
import { getWorksheetPath } from '#lib/constants/routes';

const StyledThumbnail = styled(Link)({
  position: 'relative',
  width: '130px',
  textDecoration: 'none',
});

const StyledBox = styled.div(({ color, content }: { color?: string; content: string }) => ({
  background: `linear-gradient(180deg, ${AppColors.GREY6} 0%, ${AppColors.MERCURY} 100%);`,
  width: '130px',
  height: '70px',
  display: 'flex',
  gap: '0.7em',
  flexDirection: 'column',
  borderRadius: '4px',
  position: 'relative',
  '&:before': {
    content: `"${content ?? ''}"`,
    position: 'absolute',
    top: '0',
    left: '0',
    bottom: '0',
    width: '20px',
    backgroundColor: color ?? '#aaa',
    borderRadius: '4px 0 0 4px',
    boxSizing: 'border-box',
    padding: '29px 0 0 7px',
    fontSize: '10px',
    fontWeight: 'bold',
    color: '#747474',
  },
}));

const StyledIconWrapper = styled.div({
  opacity: '0.4',
  width: '100%',
  height: '100%',
  '&:hover': {
    opacity: '1',
  },
});

const StyledIcon = styled.img({
  width: '20px',
  height: '20px',
  alignSelf: 'center',
  marginRight: '5px',
  paddingLeft: '55px',
  paddingTop: '25px',
});

const StyledEditIcon = styled(ModeEdit)({
  position: 'relative',
  alignSelf: 'flex-end',
  bottom: '7px',
  right: '4px',
  fontSize: '15px',
  color: AppColors.GREY,
});

const StyledText = styled.span({
  color: AppColors.BLACK,
  fontWeight: '700',
  fontSize: '10px',
  lineHeight: '12px',
  fontStyle: 'normal',
  maxWidth: '8em',
});

export interface WorksheetThumbnailWorksheet {
  animalId?: number;
  definition?: string;
  experimentId?: number;
  id: number;
  name?: string;
  type?: string;
}

interface WorksheetThumbnailProps {
  worksheet: WorksheetThumbnailWorksheet;
  models: ExperimentDetailsFragment['models'];
}

export const WorksheetThumbnail = ({ worksheet, models }: WorksheetThumbnailProps) => {
  let color = AppColors.DARK_GREY;

  switch (worksheet.type) {
    case 'pkBlood':
      color = AppColors.DARK_RED;
      break;
    case 'pkTissue':
      color = AppColors.DARK_TURQUOISE;
      break;
    default:
      if (!isNil(worksheet.definition)) {
        try {
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- JSON.parse requires this
          const definition = JSON.parse(worksheet.definition) as { color: string };
          color = definition.color;
        } catch (error: unknown) {}
      }
      break;
  }

  let content = '';
  if (!isNil(worksheet.animalId)) {
    const model = find(models, { id: worksheet.animalId });
    if (!isNil(model)) {
      content = model.name;
    }
  }

  const type = worksheet.name ?? worksheet.type ?? 'no type';
  const icon = (worksheet.type ?? '').includes('Cal c') ? CalculatorIcon : DocumentIcon;

  return (
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- the experimentId will always be present
    <StyledThumbnail to={getWorksheetPath(worksheet.experimentId!, worksheet.id, worksheet.type)}>
      <StyledBox data-test-id={`${worksheet.name} Thumbnail`} color={color} content={content}>
        <StyledIconWrapper>
          <StyledIcon src={icon} />
        </StyledIconWrapper>
        <StyledEditIcon />
      </StyledBox>
      <StyledText>{type}</StyledText>
    </StyledThumbnail>
  );
};
