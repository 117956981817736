import { LoadingButton } from '@mui/lab';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material';
import { GridRowId } from '@mui/x-data-grid-premium';
import { useState } from 'react';
import toast from 'react-hot-toast';

export interface OmniGridConfirmModalText {
  Title: string;
  Content: string;
  Error: string;
  Success: string;
}

export interface UseOmniGridConfirmModalParams {
  modalText?: OmniGridConfirmModalText;
  onConfirm?: (id: GridRowId) => Promise<void> | void;
}

export function useOmniGridConfirmModal(
  params?: UseOmniGridConfirmModalParams
) {
  const [open, setOpen] = useState<boolean>(false);
  const [gridRowId, setGridRowId] = useState<GridRowId>();

  return {
    ...params,
    closeModal: () => {
      setOpen(false);
      setGridRowId(undefined);
    },
    gridRowId,
    open,
    openModal: (id: GridRowId) => {
      setGridRowId(id);
      setOpen(true);
    },
  };
}

export interface OmniGridConfirmModalProps
  extends UseOmniGridConfirmModalParams {
  closeModal: VoidFunction;
  gridRowId?: GridRowId;
  open: boolean;
  openModal: (id: GridRowId) => void;
}

export function OmniGridConfirmModal(props: OmniGridConfirmModalProps) {
  const {
    closeModal,
    gridRowId,
    modalText,
    onConfirm = () => {},
    open,
  } = props;

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>();

  const handleConfirm = async () => {
    setError(undefined);
    setLoading(true);
    try {
      gridRowId && (await onConfirm(gridRowId));
      closeModal();
      toast.success(modalText?.Success ?? '');
    } catch (error: any) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={closeModal}>
      <DialogTitle>{modalText?.Title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{modalText?.Content}</DialogContentText>
        {error && (
          <DialogContentText color="red">
            {modalText?.Error}
            {error.message}
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        <Button disableElevation onClick={closeModal} variant="outlined">
          No
        </Button>
        <LoadingButton
          disableElevation
          loading={loading}
          onClick={handleConfirm}
          variant="contained"
        >
          Yes
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
