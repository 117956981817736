import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import { type PropsWithChildren } from 'react';

type PanelProps = PropsWithChildren<{
  defaultExpanded?: boolean;
  title: string;
}>;

export default function Panel(props: PanelProps) {
  const { children, defaultExpanded = false, title } = props;

  const panelHeaderId = `panel-header-${title}`;
  const panelContentId = `panel-content-${title}`;

  return (
    <Accordion defaultExpanded={defaultExpanded}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={panelContentId} id={panelHeaderId}>
        <Typography variant="h3">{title}</Typography>
      </AccordionSummary>
      <AccordionDetails id={panelContentId}>{children}</AccordionDetails>
    </Accordion>
  );
}
