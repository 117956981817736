import { ChangeEvent, useState, useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';
import debounce from 'lodash/debounce';

import { TextField, Autocomplete, CircularProgress } from '@mui/material';
import { UserBasicInfoFragment, useAllUsersQuery } from '#graphql';
import { useUpdateExperiment } from '#lib/hooks';
import { getUserFullName, getUserInitials } from '#lib/utils';
import { StyledContainer, StyledInitials } from './styles';

interface UserDropdownProps {
  selectedUser: UserBasicInfoFragment;
  disabled: boolean;
  role: string;
  fieldName: string;
  experimentId: number;
}

export const UserDropdown = ({
  selectedUser,
  role,
  experimentId,
  fieldName,
  disabled,
}: UserDropdownProps) => {
  const [query, setQuery] = useState('');
  const { updateExperiment } = useUpdateExperiment();
  const { data: userData, loading } = useAllUsersQuery({
    variables: {
      limit: 10,
      offset: 0,
      query: query,
      role,
    },
  });
  const userInitials = !isEmpty(selectedUser) && getUserInitials(selectedUser);
  const [newSelectedUser, setNewSelectedUser] = useState(selectedUser);

  const handleChange = useMemo(
    () =>
      debounce((value: UserBasicInfoFragment) => {
        setNewSelectedUser(value);
        updateExperiment({ [fieldName]: value || null, id: experimentId });
      }, 1500),
    []
  );

  return (
    <StyledContainer data-test-id={`userDropdown`}>
      <StyledInitials>{userInitials}</StyledInitials>
      <Autocomplete
        disabled={disabled}
        isOptionEqualToValue={(option, value) =>
          option.email === value.email || value.email === selectedUser.email
        }
        value={newSelectedUser}
        onChange={(_event, value) =>
          handleChange(value as UserBasicInfoFragment)
        }
        getOptionLabel={(option) => {
          if (!isEmpty(option))
            return getUserFullName(option as UserBasicInfoFragment);
          return 'Select User';
        }}
        options={userData?.users ? userData.users.items : []}
        loading={loading}
        renderInput={(params) => (
          <TextField
            {...params}
            onChange={(ev: ChangeEvent<HTMLInputElement>) =>
              setQuery(ev.target.value)
            }
            InputProps={{
              ...params.InputProps,
              sx: {
                background: 'none',
              },
              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress color="primary" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </StyledContainer>
  );
};
