import { FormGroup, FormHelperText, InputLabel } from '@mui/material';
import { isNil, startCase } from 'lodash';
import { useEffect, type ReactNode } from 'react';
import { useFormContext, type FieldValues } from 'react-hook-form';

import { ReactHookCheckbox } from '../ReactHookCheckbox';

interface BaseProps {
  fieldNames: string[];
  hideErrorMessage?: boolean;
  label?: string;
  render?: (args: { error: boolean; errorMessage: string | undefined }) => ReactNode;
  required?: boolean;
}

interface Validation {
  fieldName: string;
  validator: (value: any, values: FieldValues) => true | string;
}

type WithoutValidation = BaseProps & {
  validation: never;
};

type WithValidation = BaseProps & {
  validation: Validation;
};

export type OmniCheckboxGroupProps = WithValidation | WithoutValidation;

export function OmniCheckboxGroup(props: OmniCheckboxGroupProps) {
  const { fieldNames, hideErrorMessage = false, label, render, required, validation } = props;

  const methods = useFormContext();

  const withValidation = !isNil(validation);

  if (withValidation) {
    methods.register(validation.fieldName, { validate: { validator: validation.validator } });
  }

  useEffect(() => {
    if (withValidation) {
      const subscription = methods.watch((_value, { name: fieldName }) => {
        if (isNil(fieldName)) {
          return;
        }

        if (fieldNames.includes(fieldName)) {
          methods.clearErrors(validation.fieldName);
        }
      });
      return () => {
        subscription.unsubscribe();
      };
    }
  }, [methods.watch]);

  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const errorMessage = methods.formState.errors[validation.fieldName]?.message as string | undefined;
  const error = !isNil(errorMessage);

  return (
    <>
      {!isNil(label) && (
        <InputLabel error={!isNil(errorMessage)} required={required} shrink={true}>
          {label}
        </InputLabel>
      )}

      {!isNil(render) ? (
        render({ error, errorMessage })
      ) : (
        <FormGroup>
          {fieldNames.map((fieldName) => (
            <ReactHookCheckbox
              key={fieldName}
              controller={{ name: fieldName }}
              error={error}
              label={label ?? startCase(fieldName)}
            />
          ))}
        </FormGroup>
      )}
      {!hideErrorMessage && <FormHelperText error>{errorMessage ?? ' '}</FormHelperText>}
    </>
  );
}
