import { styled } from '@mui/material';
import { Link } from 'react-router-dom';

import { AppColors } from '#lib/constants';

const styleOverrides = {
  minWidth: '28px !important',
  padding: '4px',
  borderRadius: '50%',
  height: '28px',
  background: 'white',
  boxSizing: 'border-box',
  border: '2px solid',
  borderColor: AppColors.BLACK,
  color: AppColors.BLACK,
} as const;

export default styled(Link)({ styleOverrides });
