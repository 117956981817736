import styled from '@emotion/styled';
import { NavLink } from 'react-router-dom';
import { getAppColors } from '#lib/utils';

interface TabButtonProps extends React.HTMLAttributes<HTMLAnchorElement> {
  path: string;
}

export const TabButton = ({ path, ...props }: TabButtonProps) => {
  const TabButtonStyled = styled(NavLink)({
    fontFamily: 'Roboto',
    marginLeft: '3px',
    marginRight: '15px',
    fontSize: '13px',
    fontWeight: '500',
    display: 'flex',
    gap: '7px',
    textDecoration: 'none',
    '&.active': {
      borderBottom: '3px solid',
      borderColor: getAppColors('--color-brand-green'),
      color: getAppColors('--color-brand-green'),
      fontWeight: '600',
      display: 'flex',
    },
  });

  return <TabButtonStyled {...props} to={path} />;
};
