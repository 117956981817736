import { ApolloError } from '@apollo/client';
import { type FileValidated } from '@dropzone-ui/react';
import { isNil } from 'lodash';
import { useState } from 'react';

import { useUpdateAdcOrderItemMutation, type AdcOrderItemInput } from '#graphql';
import { type AdcOrderItemForUpload, hasAdcOrderItemDiff, isInVivoDose, toAdcOrderItemInputs } from './common';
import { parseCsvData, type AdcOrderItemDataRaw, type AdcOrderItemHeaders } from './parseCsvData';
import { type CsvHeaders, parseCsvFile } from './parseCsvFile';
import { UploadError } from './uploadError';

const csvHeaders = new Map<CsvHeaders, AdcOrderItemHeaders>([
  ['Id', 'id'],
  ['Experiment Name', 'experimentName'],
  ['Purpose', 'purpose'],
  ['Bioreg Id', 'bioregId'],
  ['Delivered Amount mg', 'deliveredAmount'],
  ['Delivered Concentration mg/ml', 'deliveredConcentration'],
  ['Diluent / Formulation', 'formulation'],
  ['Batch ID', 'batchNumber'],
  ['A280', 'a280'],
]);

export async function loadAdcOrderItemUpdates(
  file: File,
  adcOrderItems: AdcOrderItemForUpload[],
  experimentName: string
) {
  const adcOrderItemCsvData = await parseCsvFile<AdcOrderItemDataRaw>(file, csvHeaders);

  // filter here so we only parse the ones we care about
  const inVivoDoseAdcOrderItemCsvData = adcOrderItemCsvData.filter(isInVivoDose);

  const adcOrderItemsData = await parseCsvData(inVivoDoseAdcOrderItemCsvData, adcOrderItems, experimentName);

  return adcOrderItemsData.filter(hasAdcOrderItemDiff(adcOrderItems)).flatMap(toAdcOrderItemInputs(adcOrderItems));
}

export default function useUploadAdcOrder(adcOrderItems: AdcOrderItemForUpload[], experimentName: string) {
  const [updateAdcOrderItem] = useUpdateAdcOrderItemMutation();
  const [file, setFile] = useState<FileValidated>();
  const [error, setError] = useState<UploadError>();

  async function updateAdcOrderItems(adcOrderItemUpdates: AdcOrderItemInput[]) {
    try {
      await Promise.all(
        adcOrderItemUpdates.map(
          async (adcOrderItemUpdate) =>
            await updateAdcOrderItem({
              variables: {
                input: adcOrderItemUpdate,
              },
            })
        )
      );
    } catch (error: unknown) {
      if (error instanceof ApolloError) {
        throw apolloErrorToUploadError(error);
      }

      throw error;
    }
  }

  async function handleFile() {
    if (isNil(file)) {
      return;
    }

    try {
      const adcOrderItemUpdates = await loadAdcOrderItemUpdates(file.file, adcOrderItems, experimentName);

      await updateAdcOrderItems(adcOrderItemUpdates);
    } catch (error: unknown) {
      if (error instanceof UploadError) {
        setError(error);
      } else {
        setError(new UploadError(['Unknown error'], { cause: error }));
      }

      throw error;
    }
  }

  function onChange(incomingFiles: FileValidated[]) {
    setError(undefined);
    setFile(incomingFiles[0] ?? undefined);
  }

  function onClean() {
    setFile(undefined);
    setError(undefined);
  }

  function onDelete() {
    setFile(undefined);
  }

  const value = isNil(file) ? [] : [file];

  return {
    error,
    handleFile,
    onChange,
    onClean,
    onDelete,
    value,
  };
}

function apolloErrorToUploadError(error: ApolloError) {
  const messages = error.graphQLErrors.map((graphQLError) => graphQLError.message);
  return new UploadError(messages, { cause: error });
}
