import { AppBar, Box, Stack, Toolbar, type SxProps, type Theme } from '@mui/material';
import { capitalize, groupBy, isNil, map, sortBy, xor } from 'lodash';
import { FormProvider, useForm } from 'react-hook-form';

import { HandleNetworkLifecycle, OmniForm, OmniSelectField, OmniTextField } from '#components/widgets';
import { useListContentsQuery, type ListContentDetailsFragment, type TissueBioanalysisFragment } from '#graphql';
import { i18n } from '#lib/constants';
import { type TreatmentGroupWithTreatment } from '#lib/types';
import { Button, LoadingButton } from '#rds';
import { useMemo } from 'react';
import { HandlingAndStorageFormSection } from './HandlingAndStorageFormSection';
import { DESTINATION_OPTIONS, buildDefaultValues, type TissueBioanalysisFormData } from './tissueBioanalysis';
import { TreatmentGroupsFormSection } from './TreatmentGroupsFormSection';
import { CollectionSchedulesFormSection } from './CollectionSchedulesFormSection';

const appBarStyles: SxProps<Theme> = {
  backgroundColor: 'rgba(229,243,236, .2)', // Primary P98
  color: (theme) => theme.palette.text.primary,
};

interface TissueBioanalysisFormProps {
  tissueBioanalysis?: TissueBioanalysisFragment;
  handleSubmit?: (data: TissueBioanalysisFormData) => Promise<void> | void;
  onCancel?: () => void;
  onSuccess?: () => void;
  treatmentGroups: TreatmentGroupWithTreatment[];
}

const collectionCriteria = [
  { value: 'dose', label: 'Dose' },
  { value: 'tumorVolume', label: 'Tumor Volume' },
];

export function TissueBioanalysisForm(props: TissueBioanalysisFormProps) {
  const {
    tissueBioanalysis,
    handleSubmit = () => undefined,
    onCancel = () => undefined,
    onSuccess = () => undefined,
    treatmentGroups,
  } = props;

  const { data, error, loading } = useListContentsQuery({
    fetchPolicy: 'cache-and-network',
  });

  const formData = useMemo(() => {
    const listContents = data?.listContents?.items;

    if (isNil(listContents)) {
      return undefined;
    }

    const { bioanalysisTimepoint, bioanalysisTumorVolumeTimepoint, tissue, handlingAndStorage } = groupBy(
      listContents,
      'category'
    );

    const priorityTissueTypes = ['Tumor', 'Spleen', 'Tumor draining lymph node'];
    const tissueTypes = sortBy(
      tissue.map(({ name }) => ({
        label: capitalize(name),
        value: capitalize(name),
      })),
      [({ label }) => (priorityTissueTypes.includes(label) ? priorityTissueTypes.indexOf(label) : 999999), 'label']
    );

    const handlingAndStorageMethods = handlingAndStorage.map(({ name }) => name);

    return {
      bioanalysisTimepoints: bioanalysisTimepoint,
      bioanalysisTumorVolumeTimepoints: bioanalysisTumorVolumeTimepoint,
      handlingAndStorageMethods,
      tissueTypes,
    };
  }, [data]);

  return (
    <HandleNetworkLifecycle
      data={formData}
      loading={loading}
      error={error}
      render={({ data }) => {
        return (
          <Form
            {...data}
            tissueBioanalysis={tissueBioanalysis}
            treatmentGroups={treatmentGroups}
            handleSubmit={handleSubmit}
            onCancel={onCancel}
            onSuccess={onSuccess}
          />
        );
      }}
    />
  );
}

interface FormProps {
  bioanalysisTimepoints: ListContentDetailsFragment[];
  bioanalysisTumorVolumeTimepoints: ListContentDetailsFragment[];
  handlingAndStorageMethods: string[];
  tissueBioanalysis?: TissueBioanalysisFragment;
  tissueTypes: Array<{ value: string; label: string }>;
  treatmentGroups: TreatmentGroupWithTreatment[];
  handleSubmit: (data: TissueBioanalysisFormData) => Promise<void> | void;
  onCancel: () => void;
  onSuccess: () => void;
}

function Form(props: FormProps) {
  const {
    bioanalysisTimepoints,
    bioanalysisTumorVolumeTimepoints,
    handlingAndStorageMethods,
    tissueBioanalysis,
    tissueTypes,
    treatmentGroups,
    handleSubmit,
    onCancel,
    onSuccess,
  } = props;

  const methods = useForm<TissueBioanalysisFormData>({
    defaultValues: buildDefaultValues(treatmentGroups, handlingAndStorageMethods, tissueBioanalysis),
  });

  const destinationValue = methods.watch('destination');

  const isNew = isNil(tissueBioanalysis?.id);

  function tissueNamesOnClose() {
    const [tissues, tissueNames] = methods.getValues(['tissues', 'tissueNames']);
    const tissueNamesInTissues = map(tissues, 'tissue');

    if (xor(tissueNames, tissueNamesInTissues).length > 0) {
      const updated = [
        ...tissues.filter((tissue) => tissueNames.includes(tissue.tissue)),
        ...tissueNames
          .filter((tissueNameValue) => !tissueNamesInTissues.includes(tissueNameValue))
          .map((tissueName) => ({
            tissue: tissueName,
            notes: '',
            handlingAndStorage: handlingAndStorageMethods.map((handlingAndStorageMethod) => {
              return {
                checked: false,
                method: handlingAndStorageMethod,
                includeWeight: false,
              };
            }),
          })),
      ];

      methods.setValue('tissues', updated);
    }
  }

  return (
    <FormProvider {...methods}>
      <OmniForm
        formText={i18n.TissueBioanalysis.Form}
        handleSubmit={handleSubmit}
        hideActions
        isNew={isNew}
        onCancel={onCancel}
        onSuccess={onSuccess}
      >
        <Stack spacing={4} paddingX={1} paddingY={3}>
          <Box width={450}>
            <OmniSelectField
              hideErrorMessage
              name="collectionCriteria"
              label="Collection Criteria"
              selectOptions={collectionCriteria}
              required
              rules={{
                required: 'please select the collection criteria',
              }}
            />
          </Box>

          <Box width={450}>
            <OmniSelectField
              hideErrorMessage
              name="tissueNames"
              label="Tissue(s)"
              selectOptions={tissueTypes}
              multiple
              required
              rules={{
                required: 'please select at least one tissue',
              }}
              onClose={tissueNamesOnClose}
              sort={false}
            />
          </Box>

          <HandlingAndStorageFormSection />

          <Stack spacing={3}>
            <Box width={450}>
              <OmniTextField
                hideErrorMessage
                label="Stain(s)"
                name="stains"
                required
                rules={{
                  required: 'please enter at least one stain',
                }}
                inputProps={{
                  placeholder: 'stains separated by commas',
                }}
              />
            </Box>

            <Stack direction="row" gap={2}>
              <Box width={250}>
                <OmniSelectField
                  name="destination"
                  label="Destination"
                  hideErrorMessage
                  selectOptions={DESTINATION_OPTIONS}
                  required
                  rules={{
                    required: 'please select the destination',
                  }}
                />
              </Box>
              <Box width={450}>
                <OmniTextField
                  hideErrorMessage
                  label=""
                  name="otherDestination"
                  inputProps={{
                    placeholder: 'Other',
                  }}
                  InputProps={{
                    sx: { marginTop: 'calc(1rem + 1px)' },
                  }}
                  disabled={destinationValue !== 'Other'}
                  rules={{
                    // @ts-expect-error -- unable to fix
                    validate(value: string, formValues: TissueBioanalysisFormData) {
                      return formValues.destination === 'Other' && (isNil(value) || value === '')
                        ? 'please enter the other destination'
                        : undefined;
                    },
                  }}
                />
              </Box>
            </Stack>
          </Stack>

          <TreatmentGroupsFormSection treatmentGroups={treatmentGroups} />

          <CollectionSchedulesFormSection
            treatmentGroups={treatmentGroups}
            bioanalysisTimepoints={bioanalysisTimepoints}
            bioanalysisTumorVolumeTimepoints={bioanalysisTumorVolumeTimepoints}
          />
        </Stack>

        <AppBar position="absolute" sx={{ top: 'auto', bottom: 0, ...appBarStyles }}>
          <Toolbar sx={{ gap: 2 }}>
            <LoadingButton
              variant="contained"
              type="submit"
              disableElevation={true}
              loading={methods.formState.isSubmitting}
              disabled={!methods.formState.isDirty}
            >
              {i18n.TissueBioanalysis.Form.Submit[isNew ? 'Add' : 'Update']}
            </LoadingButton>
            <Button variant="outlined" onClick={onCancel}>
              Cancel
            </Button>
          </Toolbar>
        </AppBar>
      </OmniForm>
    </FormProvider>
  );
}
