import styled from '@emotion/styled';
import { largeFontSize } from '#lib/constants/typography';
import {
  cellStyle,
  gridSize,
  colMajorBorderStyle,
  stickyStyles,
} from './SectionStyles';

export const TableWrapper = styled.div(
  ({ noScroll }: { noScroll?: boolean }) => ({
    width: '100%',
    margin: '20px 0 0 0',
    ...(noScroll ? {} : { overflowX: 'auto' }),
  })
);

export const Table = styled.table({
  borderSpacing: 0,
  paddingBottom: '20px',
  tableLayout: 'fixed',
});

export const Row = styled.tr(({ hidden }: { hidden?: boolean }) => ({
  ':nth-of-type(2n+1)': {
    backgroundColor: '#fafafa',
  },
  display: !!hidden ? 'none' : 'table-row',
}));

export const StyledDeleteRowIcon = styled.img({
  width: '23px',
  height: '23px',
  cursor: 'pointer',
  paddingTop: '2px',
});

export const HeaderRow = styled.tr({});

export const DataCollectionColumnLabel = styled.div({
  display: 'flex',
  flexDirection: 'column',
  paddingBottom: `${gridSize}px`,
  paddingTop: `${gridSize}px`,
  flexGrow: 0,
  flexShrink: 0,
});

export const Cell = styled.td({
  ...cellStyle,
  padding: `${gridSize}px`,
});

export const DataCell = styled.td({
  ...cellStyle,
  padding: `${gridSize}px`,
  marginTop: 'auto',
  marginBottom: 'auto',
});

export const ColHeaderCell = styled.td({
  ...cellStyle,
  padding: `${gridSize}px ${gridSize * 2}px`,
  marginTop: 'auto',
  marginBottom: 'auto',
  borderLeft: colMajorBorderStyle,
});

export const LabelCell = styled.div({ ...cellStyle, fontWeight: 700 });
export const ColumnLabelCell = styled.td({
  ...cellStyle,
  padding: `${gridSize}px`,
  verticalAlign: 'bottom',
});

export const LayoutCell = styled.td(({ sticky }: { sticky?: boolean }) => ({
  ...cellStyle,
  marginTop: 0,
  marginBottom: 0,
  fontSize: 0,
  paddingTop: 0,
  paddingBottom: 0,
  paddingLeft: `${gridSize}px`,
  paddingRight: `${gridSize}px`,
  verticalAlign: 'bottom',
  ...(sticky ? stickyStyles : {}),
}));

export const RowLabelCell = styled.td(({ sticky }: { sticky?: boolean }) => ({
  ...cellStyle,
  padding: `${gridSize}px`,
  color: 'black',
  textAlign: 'left',
  fontWeight: 500,
  backgroundColor: 'white',
  ...(sticky ? stickyStyles : {}),
}));

export const ColLabel = styled.div({
  ...cellStyle,
  fontSize: largeFontSize,
  fontWeight: 'bold',
});

export const RowLabel = styled.div({
  ...cellStyle,
  fontWeight: 'bold',
  flexBasis: '50px',
  flexGrow: 0,
  flexShrink: 0,
  marginTop: 'auto',
  marginBottom: 'auto',
});

export const StyledFieldName = styled.div`
  //styleName: Small text bold;
  font-family: Roboto;
  font-size: 10px;
  font-weight: 700;
  line-height: 12px;
  letter-spacing: 0;
  text-align: left;

  color: #747474;
  padding-bottom: 7px;
`;
