import { DialogContent } from '@mui/material';
import { isNil } from 'lodash';

import { type Imaging } from '#graphql';
import { type TreatmentGroupWithTreatment } from '#lib/types';
import { CreateImaging } from './CreateImaging';
import { EditImaging } from './EditImaging';

interface ImagingModalProps {
  imaging?: Imaging;
  closeModal?: () => void;
  experimentId: number;
  treatmentGroups: TreatmentGroupWithTreatment[];
}

export function ImagingModal({
  imaging,
  closeModal = () => undefined,
  experimentId,
  treatmentGroups,
}: ImagingModalProps) {
  return (
    <DialogContent>
      {!isNil(imaging) ? (
        <EditImaging
          imaging={imaging}
          onCancel={closeModal}
          onSuccess={closeModal}
          treatmentGroups={treatmentGroups}
        />
      ) : (
        <CreateImaging
          experimentId={experimentId}
          onCancel={closeModal}
          onSuccess={closeModal}
          treatmentGroups={treatmentGroups}
        />
      )}
    </DialogContent>
  );
}
