import { TextField, type TextFieldProps } from '@mui/material';
import { identity, isNil, set } from 'lodash';
import { type ChangeEvent, type ReactNode } from 'react';
import {
  useController,
  useFormContext,
  type FieldValues,
  type PathValue,
  type UseControllerProps,
} from 'react-hook-form';

// Omitting certain fields since they are managed by the React Hook Form Controller
type RestrictedTextFieldProps = Omit<
  TextFieldProps,
  'defaultValue' | 'error' | 'helperText' | 'inputRef' | 'name' | 'onBlur' | 'onChange' | 'value'
>;

export interface ReactHookTextFieldProps<TFormData extends FieldValues> {
  children?: ReactNode;
  collapseHelperText?: boolean;
  controller: UseControllerProps<TFormData>;
  textField: RestrictedTextFieldProps;
  formatValue?: (value: string) => PathValue<TFormData, UseControllerProps<TFormData>['name']>;
  setValueAs?: (value: string) => any;
}

export function ReactHookTextField<TFormData extends FieldValues>(props: ReactHookTextFieldProps<TFormData>) {
  const {
    children,
    collapseHelperText = false,
    controller,
    textField,
    formatValue = identity<PathValue<TFormData, UseControllerProps<TFormData>['name']>>,
    setValueAs = identity<string>,
  } = props;

  const {
    field: { onChange, onBlur, name, value, ref },
    fieldState: { isDirty, error },
  } = useController(controller);

  const { setValue } = useFormContext<TFormData>();

  const handleBlur = () => {
    if (isDirty) {
      setValue(name, formatValue(value));
    }
    onBlur();
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(set(e, 'target.value', setValueAs(e.target.value)));
  };

  // Only format value on initial render
  const fieldValue = value === '' || isDirty ? value : formatValue(value);

  // The ' ' ensures that space is always reserved for the helper text field
  const helperText = error?.message ?? (collapseHelperText ? undefined : ' ');

  return (
    <TextField
      {...textField}
      name={name}
      value={fieldValue}
      onBlur={handleBlur}
      onChange={handleChange}
      inputRef={ref}
      error={!isNil(error)}
      helperText={helperText}
    >
      {children}
    </TextField>
  );
}
