import { Navigate, Route, Routes } from 'react-router';
import {
  ExperimentsKanbanView,
  ExperimentsListDataGridView,
  ExperimentTrackerHeader,
  CreateExperiment,
} from '#components/partials';
import { ExperimentTrackerProvider } from '#components/contexts';
import {
  CARDS_PATH,
  LIST_PATH,
  ARCHIVE_PATH,
  DRAFTS_PATH,
  CREATE_EXPERIMENT_PATH,
} from '#lib/constants';
import { NotFound } from '#components/pages';
import { StyledSeparator } from '#components/styles';

export const ExperimentsPage = () => {
  return (
    <ExperimentTrackerProvider>
      <ExperimentTrackerHeader />
      <StyledSeparator />
      <Routes>
        <Route path={CREATE_EXPERIMENT_PATH} element={<CreateExperiment />} />
        <Route path={CARDS_PATH} element={<ExperimentsKanbanView />} />
        <Route
          path={LIST_PATH}
          element={
            <ExperimentsListDataGridView
              statuses={['Execution', 'Provision', 'Ready', 'Review']}
            />
          }
        />
        <Route
          path={ARCHIVE_PATH}
          element={<ExperimentsListDataGridView statuses={['Completed']} />}
        />
        <Route
          path={DRAFTS_PATH}
          element={<ExperimentsListDataGridView statuses={['Draft']} />}
        />
        <Route index element={<Navigate to={CARDS_PATH} replace={true} />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </ExperimentTrackerProvider>
  );
};
