import { Box, Chip } from '@mui/material';
import { compact } from 'lodash';

import { HandleNetworkLifecycle } from '#components/widgets';
import {
  ExperimentByIdDocument,
  useDeleteBloodBioanalysisMutation,
  useListContentsQuery,
  type BloodBioanalysisFragment,
} from '#graphql';
import { i18n } from '#lib/constants';
import { type TreatmentGroupWithTreatment } from '#lib/types';
import { BLOOD_METHODS, BLOOD_TYPES } from './bloodBioanalysis';
import { BioanalysisDataView } from '../Bioanalysis';

interface BloodBioanalysisDataProps {
  bloodBioanalysis: BloodBioanalysisFragment;
  canEdit: boolean;
  handleEdit: () => void;
  treatmentGroups: TreatmentGroupWithTreatment[];
}
export function BloodBioanalysisData(props: BloodBioanalysisDataProps) {
  const { bloodBioanalysis, canEdit, handleEdit, treatmentGroups } = props;

  const { data, error, loading } = useListContentsQuery({
    variables: {
      category: 'bioanalysisTimepoint',
    },
    fetchPolicy: 'cache-and-network',
  });

  const [deleteBloodBioanalysis] = useDeleteBloodBioanalysisMutation({
    awaitRefetchQueries: true,
    refetchQueries: [ExperimentByIdDocument, 'experimentById'],
  });

  async function onDelete() {
    await deleteBloodBioanalysis({
      variables: {
        input: {
          id: bloodBioanalysis.id,
        },
      },
    });
  }

  const selectedMethods = compact(bloodBioanalysis.methods).map(
    (method) => BLOOD_METHODS.find((bm) => bm.value === method)?.label
  );
  const selectedBloodType = BLOOD_TYPES.find((bt) => bt.value === bloodBioanalysis.bloodType)?.label;

  const definitions = {
    'Blood Type': selectedBloodType,
    'Final Sample Value (μl)': bloodBioanalysis.sampleAmount,
    Methods: (
      <Box display="flex" flexWrap="wrap" gap={0.5}>
        {selectedMethods.map((method) => (
          <Chip key={method} label={method} />
        ))}
      </Box>
    ),
    'Other methods': bloodBioanalysis.otherMethods ?? '',
  };

  return (
    <HandleNetworkLifecycle
      data={data?.listContents.items}
      error={error}
      loading={loading}
      render={({ data: bioanalysisTimepoints }) => (
        <BioanalysisDataView
          bioanalysisTreatmentGroups={bloodBioanalysis.treatmentGroups}
          canEdit={canEdit}
          definitions={definitions}
          deleteTexts={i18n.BloodBioanalysis}
          handleEdit={handleEdit}
          onDelete={onDelete}
          treatmentGroups={treatmentGroups}
          collectionSchedules={bloodBioanalysis.collectionSchedules}
          bioanalysisTimepoints={bioanalysisTimepoints}
        />
      )}
    />
  );
}
