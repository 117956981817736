import { Box, InputLabel } from '@mui/material';
import { type GridColDef, type GridRowsProp, type GridValidRowModel } from '@mui/x-data-grid-premium';
import { isNil, isString } from 'lodash';

interface Extras {
  canEditField?: (field: any) => boolean;
}
export interface FormGridValueGetterParams<R extends GridValidRowModel> {
  row: R;
}

export interface RenderCellParams<R extends GridValidRowModel> {
  column: FormGridColDef<R>;
  extras: Extras;
  row: R;
  rowIndex: number;
}

export type FormGridColDef<R extends GridValidRowModel = any, V = any, F = V> = Pick<
  GridColDef<R, V, F>,
  'align' | 'cellClassName' | 'flex' | 'field' | 'headerAlign' | 'headerName'
> & {
  // Only applies when renderCell is not specified
  valueGetter?: (params: FormGridValueGetterParams<R>) => V;
  renderCell?: (params: RenderCellParams<R>) => JSX.Element;
};

export interface FormGridProps {
  columns: FormGridColDef[];
  extras?: Extras;
  rows: GridRowsProp;
  sx?: object;
}

export const FormGrid = ({ columns, extras = {}, rows, sx = {} }: FormGridProps) => (
  <Box
    display="grid"
    gridTemplateColumns={columns.map((column) => `${column.flex}fr`).join(' ')}
    gap={1}
    alignItems="flex-start"
    role="grid"
    aria-colcount={columns.length}
    aria-rowcount={rows.length}
    sx={sx}
  >
    {/* header row */}
    {columns.map((column) => (
      <InputLabel
        id={column.field}
        key={column.field}
        role="columnheader"
        sx={{
          textWrap: 'wrap',
          textAlign: column.headerAlign ?? 'left',
        }}
      >
        {column.headerName}
      </InputLabel>
    ))}

    {/* data cells */}
    {rows.flatMap((row, rowIndex) =>
      columns.map((column, colIndex) => {
        const textAlign = column.align ?? 'left';
        const alignItems = textAlign === 'right' ? 'flex-end' : textAlign === 'center' ? 'center' : 'flex-start';

        const key = `${row.id}-${column.field}`;

        let content;
        if (!isNil(column.renderCell)) {
          content = column.renderCell({ column, extras, row, rowIndex });
        } else {
          const value = column.valueGetter?.({ row }) ?? row[column.field];
          content = <Box paddingY="8.5px">{value}</Box>;
        }

        const className = isString(column.cellClassName) ? column.cellClassName : undefined;

        return (
          // to be fully a11y compliant cells should be contained by a `row` but that doesn't play well with `display="grid"`
          <Box
            className={className}
            textAlign={textAlign}
            key={key}
            role="gridcell"
            aria-colindex={colIndex + 1}
            aria-rowindex={rowIndex + 1}
            data-field={column.field}
            data-colindex={colIndex}
            data-rowid={row.id}
            data-rowindex={rowIndex}
            sx={{
              '& > *': { alignItems },
              '& input,select,textarea': { textAlign },
            }}
          >
            {content}
          </Box>
        );
      })
    )}
  </Box>
);
