"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.cellCalculationAdherent = exports.cellCalculationSuspension = exports.numberSections = exports.implantCellsSection = exports.harvestCellsSectionAdherent = exports.harvestCellsSectionSuspension = exports.cultureCellSectionAdherent = exports.cultureCellSectionSuspension = exports.thawCellsSection = exports.setAmountSection = exports.headingSection = exports.TOTAL_VOLUME_FOR_TOTAL_CULTURE_MEDIA_USED_ID = exports.TOTAL_VOLUME_ID = exports.TOTAL_MEDIA_VOLUME_ID = exports.THAW_CELLS_ID = exports.SET_AMOUNT_ID = exports.IMPLANT_CELLS_ID = exports.HARVEST_CELLS_ID = exports.EXPERIMENT_ID = exports.CULTURE_CELLS_INDEX = exports.CULTURE_CELLS_ID = void 0;
const style_1 = require("@omnivivo/style");
const worksheets_core_1 = require("@omnivivo/worksheets-core");
const ExperimentInjectedSections_1 = require("./ExperimentInjectedSections");
exports.CULTURE_CELLS_ID = 'cultureCells';
exports.CULTURE_CELLS_INDEX = 4;
exports.EXPERIMENT_ID = 'experiment';
exports.HARVEST_CELLS_ID = 'harvestCells';
exports.IMPLANT_CELLS_ID = 'implantCells'; // this ID cannot change - the back-end detects this for updates
exports.SET_AMOUNT_ID = 'setAmount';
exports.THAW_CELLS_ID = 'thawCells'; // this ID cannot change - the back-end detects this for updates
exports.TOTAL_MEDIA_VOLUME_ID = 'totalMediaVolume';
exports.TOTAL_VOLUME_ID = 'totalVolume';
exports.TOTAL_VOLUME_FOR_TOTAL_CULTURE_MEDIA_USED_ID = 'totalVolumeForTotalCultureMediaUsed';
exports.headingSection = {
    id: 'heading',
    sectionType: 'form',
    fields: [
        {
            id: 'instructions',
            fieldType: 'readOnly',
            fieldName: 'Instructions',
            gridWidth: 6,
            default: 'In this worksheet, cancer cells are prepared and then implanted in the animals. The steps of the worksheet are sequential and are numbered in the order they should be completed: 1 set amount, 2 calculate cell concentration, 3 thaw cells, 4 culture cells, 5 harvest cells, 6 implant cells.',
        },
        {
            id: 'notes',
            fieldType: 'longText',
            fieldName: 'notes',
            gridWidth: 6,
        },
    ],
};
exports.setAmountSection = {
    id: exports.SET_AMOUNT_ID,
    sectionType: 'form',
    sectionName: 'Set amount',
    fields: [
        {
            id: 'numberOfGroups',
            fieldName: 'Number of groups',
            fieldType: 'readOnly',
            gridWidth: 3,
            default: 2,
        },
        {
            id: 'totalAnimalsFromGroups',
            fieldName: 'Total animals from groups',
            fieldType: 'readOnly',
            gridWidth: 3,
            default: 20,
        },
        {
            id: 'animalFactor',
            fieldName: 'Animal factor',
            fieldType: 'number',
            gridWidth: 3,
            default: 1.34,
        },
        {
            id: 'totalAnimals',
            fieldName: 'Total animals',
            fieldType: 'calc',
            // Sum of all animals in groups plus animals to add (if any).
            calc: `round(totalAnimalsFromGroups * animalFactor)`,
            gridWidth: 3,
            numberFormat: '0',
        },
        {
            id: 'cellsPerAnimal',
            fieldName: 'Cells per animal',
            fieldType: 'number',
            numberFormat: '0.00e+0',
            gridWidth: 3,
        },
        {
            id: 'implantVolume',
            fieldName: 'Implant volume (mL)',
            fieldType: 'number',
            gridWidth: 3,
        },
    ],
};
exports.thawCellsSection = {
    id: exports.THAW_CELLS_ID,
    sectionType: 'form',
    sectionName: 'Thaw cells',
    fields: [
        { id: 'date', fieldName: 'Date', fieldType: 'date', gridWidth: 3 },
        { id: 'spacer1', fieldType: 'spacer', gridWidth: 8 },
        {
            id: 'cellLineLotNumber',
            fieldName: 'Cell line lot number',
            fieldType: 'string',
        },
        {
            id: 'cellCultureMedia',
            fieldName: 'Cell culture media',
            fieldType: 'string',
        },
        {
            id: 'numberOfVials',
            fieldName: 'Number of vials',
            fieldType: 'number',
        },
        {
            id: 'centrifugeSpeed',
            fieldName: 'Centrifuge speed (rpm)',
            fieldType: 'number',
        },
        {
            id: 'centrifugeTime',
            fieldName: 'Centrifuge time (min)',
            fieldType: 'number',
        },
        { id: 'flaskSize', fieldName: 'Flask size', fieldType: 'string' },
        {
            id: exports.TOTAL_MEDIA_VOLUME_ID,
            fieldName: 'Media per flask (mL)',
            fieldType: 'number',
        },
        {
            id: 'numberOfFlasks',
            fieldName: 'Number of flasks',
            fieldType: 'number',
        },
        {
            id: 'notes',
            fieldName: 'Notes',
            fieldType: 'longText',
            gridWidth: 8,
        },
    ],
};
exports.cultureCellSectionSuspension = {
    id: exports.CULTURE_CELLS_ID,
    sectionType: 'table',
    sectionName: 'Culture cells (Suspension)',
    allowAddRows: true,
    fields: [
        { id: 'date', fieldName: 'Date', fieldType: 'date', gridWidth: 1.5 },
        {
            id: 'day',
            fieldName: 'Day',
            fieldType: 'calc',
            calc: `daysDifference(thawCells.date, date)`,
            gridWidth: 0.5,
        },
        {
            id: 'viCellCountStart',
            fieldName: 'Vi-cell count start (viable cells/mL)',
            fieldType: 'number',
            gridWidth: 1,
            numberFormat: '0.00e+0',
        },
        {
            id: 'cellCheck',
            fieldName: 'Cell check / viability %',
            fieldType: 'number',
            gridWidth: 1,
            maxNumber: 100,
        },
        {
            id: 'initialTotalVolume',
            fieldName: 'Initial total volume (mL)',
            fieldType: 'number',
            gridWidth: 1,
        },
        {
            id: 'initialTotalNumViableCells',
            fieldName: 'Initial total # of viable cells',
            fieldType: 'calc',
            calc: 'viCellCountStart * initialTotalVolume',
            gridWidth: 1,
            numberFormat: '0.00e+0',
        },
        {
            id: 'finalCellConcentration',
            fieldName: 'Final cell concentration (cells/mL)',
            fieldType: 'number',
            gridWidth: 1,
            numberFormat: '0.00e+0',
        },
        {
            id: 'finalTotalVolumePerFlask',
            fieldName: 'Final total volume per flask (mL)',
            fieldType: 'number',
            gridWidth: 1,
        },
        {
            id: exports.TOTAL_VOLUME_FOR_TOTAL_CULTURE_MEDIA_USED_ID,
            fieldName: 'Volume per flask (mL)',
            fieldType: 'calc',
            hidden: true,
            calc: 'finalTotalVolumePerFlask',
        },
        {
            id: 'flaskSize',
            fieldName: 'Flask size',
            fieldType: 'string',
            gridWidth: 1,
        },
        {
            id: 'numberOfFlasks',
            fieldName: 'Number of flasks',
            fieldType: 'number',
            gridWidth: 1,
        },
        {
            id: 'notes',
            fieldName: 'Notes',
            fieldType: 'longText',
            gridWidth: 8,
        },
    ],
};
exports.cultureCellSectionAdherent = {
    id: exports.CULTURE_CELLS_ID,
    sectionType: 'table',
    sectionName: `Culture cells (Adherent)`,
    allowAddRows: true,
    fields: [
        { id: 'date', fieldName: 'Date', fieldType: 'date', gridWidth: 1.5 },
        {
            id: 'day',
            fieldName: 'Day',
            fieldType: 'calc',
            calc: `daysDifference(thawCells.date, date)`,
            gridWidth: 0.5,
        },
        {
            id: 'confluency',
            fieldName: 'Confluency (%)',
            fieldType: 'number',
            gridWidth: 1,
            maxNumber: 100,
        },
        {
            id: 'trypsinConcentration',
            fieldName: 'Trypsin concentration %',
            fieldType: 'number',
            gridWidth: 1,
            maxNumber: 100,
        },
        {
            id: 'trypsin',
            fieldName: 'Trypsin (mL)',
            fieldType: 'number',
            gridWidth: 1,
        },
        {
            id: 'PBS',
            fieldName: 'PBS (mL)',
            fieldType: 'number',
            gridWidth: 1,
        },
        {
            id: 'viableCells',
            fieldName: 'Viable (cells/mL)',
            fieldType: 'number',
            gridWidth: 1,
            numberFormat: '0.00e+0',
        },
        {
            id: 'suspensionVolume',
            fieldName: 'Total media volume (mL)',
            fieldType: 'number',
            gridWidth: 1,
        },
        {
            id: 'totalCells',
            fieldName: 'Total cells',
            fieldType: 'calc',
            calc: 'viableCells * suspensionVolume',
            gridWidth: 1,
            numberFormat: '0.00e+0',
        },
        {
            id: exports.TOTAL_VOLUME_FOR_TOTAL_CULTURE_MEDIA_USED_ID,
            fieldName: 'Total media volume (mL)',
            fieldType: 'calc',
            hidden: true,
            calc: 'totalCells',
            gridWidth: 1,
        },
        {
            id: 'cellCheck',
            fieldName: 'Cell check / viability %',
            fieldType: 'number',
            gridWidth: 1,
            maxNumber: 100,
        },
        {
            id: 'ratioSplit',
            fieldName: 'Ratio of split',
            fieldType: 'string',
            gridWidth: 1,
        },
        {
            id: 'totalFreshMediaVolume',
            fieldName: 'Total fresh media volume (mL)',
            fieldType: 'number',
            gridWidth: 1,
        },
        {
            id: 'flaskSize',
            fieldName: 'Flask size',
            fieldType: 'string',
            gridWidth: 1,
        },
        {
            id: 'numberOfFlasks',
            fieldName: 'Number of flasks',
            fieldType: 'number',
            gridWidth: 1,
        },
        {
            id: 'notes',
            fieldName: 'Notes',
            fieldType: 'longText',
            gridWidth: 8,
        },
    ],
};
const dateFields = [
    {
        id: 'date',
        fieldName: 'Date',
        fieldType: 'date',
        gridWidth: 3,
    },
    {
        id: 'day',
        fieldName: 'Day',
        fieldType: 'calc',
        calc: `daysDifference(thawCells.date, date)`,
        gridWidth: 3,
    },
    { id: 'dateSpacer', fieldType: 'spacer', gridWidth: 6 },
];
exports.harvestCellsSectionSuspension = {
    id: exports.HARVEST_CELLS_ID,
    sectionType: 'form',
    sectionName: 'Harvest cells (suspension)',
    fields: [
        ...dateFields,
        {
            id: 'cellVolume',
            fieldName: 'Final Total media volume (mL)',
            fieldType: 'number',
        },
        {
            id: 'viCellCount',
            fieldName: 'Viable (cells/mL)',
            fieldType: 'number',
            numberFormat: '0.00e+0',
        },
        {
            id: 'totalCells',
            fieldName: 'Total cells',
            fieldType: 'calc',
            calc: 'cellVolume * viCellCount',
            numberFormat: '0.00e+0',
        },
        {
            id: 'volumeForTransfer',
            fieldName: 'Volume for transfer (mL)',
            fieldType: 'calc',
            calc: `${exports.IMPLANT_CELLS_ID}.totalNumCellsNeeded / viCellCount`,
        },
        {
            id: 'centrifugeSpeed',
            fieldName: 'Centrifuge speed (rpm)',
            fieldType: 'number',
        },
        {
            id: 'centrifugeLength',
            fieldName: 'Centrifuge length (mins)',
            fieldType: 'number',
        },
        {
            id: 'cellViabilityPercentageAtImplant',
            fieldName: 'Cell viability % at the time of implant',
            fieldType: 'number',
            maxNumber: 100,
        },
        {
            id: 'totalCultureMediaUsed',
            fieldName: 'Total culture media used (mL)',
            fieldType: 'calc',
            calc: `or(thawCells.${exports.TOTAL_MEDIA_VOLUME_ID}, 0) + sum(${exports.CULTURE_CELLS_ID}.${exports.TOTAL_VOLUME_FOR_TOTAL_CULTURE_MEDIA_USED_ID})`,
        },
        {
            id: 'notes',
            fieldName: 'Notes',
            fieldType: 'longText',
            gridWidth: 8,
        },
    ],
};
exports.harvestCellsSectionAdherent = {
    id: exports.HARVEST_CELLS_ID,
    sectionType: 'form',
    sectionName: 'Harvest cells (adherent)',
    fields: [
        ...dateFields,
        {
            id: 'confluencyPercentage',
            fieldName: 'Confluency %',
            fieldType: 'number',
            maxNumber: 100,
        },
        {
            id: 'totalNumberOfFlasks',
            fieldName: 'Total number of flasks (adherent)',
            fieldType: 'number',
        },
        { id: 'flaskSize', fieldName: 'Flask size', fieldType: 'string' },
        {
            id: 'trypsin',
            fieldName: 'Trypsin (mL)',
            fieldType: 'number',
        },
        {
            id: 'trypsinConcentration',
            fieldName: 'Trypsin concentration %',
            fieldType: 'number',
            maxNumber: 100,
        },
        {
            id: 'centrifugeSpeed',
            fieldName: 'Centrifuge speed (rpm)',
            fieldType: 'number',
        },
        {
            id: 'centrifugeLength',
            fieldName: 'Centrifuge length (mins)',
            fieldType: 'number',
        },
        {
            id: 'viCellCount',
            fieldName: 'Viable (cells/mL)',
            fieldType: 'number',
            numberFormat: '0.00e+0',
        },
        {
            id: 'cellVolume',
            fieldName: 'Final Total media volume (mL)',
            fieldType: 'number',
        },
        {
            id: 'totalCells',
            fieldName: 'Total cells',
            fieldType: 'calc',
            calc: 'cellVolume * viCellCount',
            numberFormat: '0.00e+0',
        },
        {
            id: 'volumeForTransfer',
            fieldName: 'Volume for transfer (mL)',
            fieldType: 'calc',
            calc: `${exports.IMPLANT_CELLS_ID}.totalNumCellsNeeded / viCellCount`,
        },
        {
            id: 'cellViabilityPercentageAtImplant',
            fieldName: 'Cell viability % at the time of implant',
            fieldType: 'number',
            maxNumber: 100,
        },
        {
            id: 'averageCellsPerConfluentFlasks',
            fieldName: 'Average cell # / confluent flasks',
            fieldType: 'calc',
            numberFormat: '0.00e+0',
            calc: 'totalCells / totalNumberOfFlasks',
        },
        { id: 'preNotesDivide', fieldType: 'spacer', gridWidth: 8 },
        {
            id: 'notes',
            fieldName: 'Notes',
            fieldType: 'longText',
            gridWidth: 8,
        },
    ],
};
exports.implantCellsSection = {
    id: exports.IMPLANT_CELLS_ID,
    sectionType: 'form',
    sectionName: 'Implant requirements',
    fields: [
        {
            id: 'date',
            fieldName: 'Date',
            fieldType: 'calc',
            calc: 'harvestCells.date',
            gridWidth: 3,
        },
        {
            id: 'day',
            fieldName: 'Day',
            fieldType: 'calc',
            calc: `daysDifference(thawCells.date, date)`,
            gridWidth: 3,
        },
        {
            id: 'cellConcentrationForImplant',
            fieldName: 'Cell concentration for implant (cell/mL)',
            fieldType: 'calc',
            calc: `setAmount.cellsPerAnimal / setAmount.implantVolume`,
            numberFormat: '0.00e+0',
        },
        {
            id: 'totalVolumeNeeded',
            fieldName: 'Total volume implant media needed (mL)',
            fieldType: 'calc',
            calc: `setAmount.totalAnimals * setAmount.implantVolume * overageFactor`,
        },
        {
            id: 'totalNumCellsNeeded',
            fieldName: 'Total # cells needed',
            fieldType: 'calc',
            calc: 'cellConcentrationForImplant * totalVolumeNeeded',
            numberFormat: '0.00e+0',
        },
        {
            id: 'overageFactor',
            fieldName: 'Overage factor',
            fieldType: 'number',
            default: 1.3,
        },
        {
            id: 'cellImplantMedia',
            fieldName: 'Cell implant media',
            fieldType: 'string',
        },
        {
            id: 'implantVolumePresentation',
            fieldName: 'Implant volume (mL)',
            fieldType: 'calc',
            calc: 'setAmount.implantVolume',
        },
        {
            id: 'notes',
            fieldName: 'Notes',
            fieldType: 'longText',
            gridWidth: 8,
        },
    ],
};
const numberSections = (sections) => sections.map((section, index) => ({
    ...section,
    sectionName: `${index + 1}. ${section.sectionName}`,
}));
exports.numberSections = numberSections;
exports.cellCalculationSuspension = (0, worksheets_core_1.getNormalizeWorksheetDefinition)({
    worksheetName: 'Cell Calculation Suspension',
    color: style_1.AppColors.SPECTRA_BLUE,
    sections: [
        ...ExperimentInjectedSections_1.experimentInjectedSectionDefinitions,
        exports.headingSection,
        ...(0, exports.numberSections)([
            exports.setAmountSection,
            exports.thawCellsSection,
            exports.cultureCellSectionSuspension,
            exports.harvestCellsSectionSuspension,
            exports.implantCellsSection,
        ]),
    ],
});
exports.cellCalculationAdherent = (0, worksheets_core_1.getNormalizeWorksheetDefinition)({
    worksheetName: 'Cell Calculation Adherent',
    color: style_1.AppColors.SPECTRA_BLUE,
    sections: [
        ...ExperimentInjectedSections_1.experimentInjectedSectionDefinitions,
        exports.headingSection,
        ...(0, exports.numberSections)([
            exports.setAmountSection,
            exports.thawCellsSection,
            exports.cultureCellSectionAdherent,
            exports.harvestCellsSectionAdherent,
            exports.implantCellsSection,
        ]),
    ],
});
