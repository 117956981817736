import { useMemo } from 'react';
import { ListContent, ListContentCategory, ListContentInput } from '#graphql';
import {
  CreateTemplateEditorItemType,
  TemplateEditorItem,
} from '#components/partials/TemplateEditorItem';
import { categoryTitleMap } from '#lib/constants';

interface ListContentEditorProps {
  listContents: ListContent[];
  category: ListContentCategory;
  save: (items: ListContentInput[], category: string) => Promise<void>;
  loading: boolean;
}

export const ListContentEditor = ({
  listContents,
  category,
  save,
  loading,
}: ListContentEditorProps) => {
  const items = useMemo(
    () =>
      listContents.map((lc) => ({
        name: lc.name,
        category: lc.description,
        id: lc.id,
      })),
    [listContents]
  );
  const addItem = (item: CreateTemplateEditorItemType) => {
    const listContentsToSave: {
      name: string;
      description?: string;
      id?: number;
    }[] = items.map((it) => {
      if (item.id === it.id) {
        return {
          name: item.name,
          description: item.category,
        };
      }
      return {
        name: it.name,
        description: it.category,
      };
    });
    if (!item.id) {
      listContentsToSave.push({
        name: item.name,
        description: item.category,
      });
    }
    save(listContentsToSave, category);
  };

  const removeItem = (id: number) => {
    save(
      listContents
        .filter((i) => i.id !== id)
        .map((i) => ({ name: i.name, description: i.description })),
      category
    );
  };

  return (
    <TemplateEditorItem
      items={items}
      title={categoryTitleMap[category]}
      addItem={addItem}
      removeItem={removeItem}
      minItemCount={1}
    />
  );
};
