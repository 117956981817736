import { Box, Container } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { Header } from '#components/widgets';
import { ANIMAL_INVENTORY_ROUTE } from '#lib/constants';

const navLinks = [
  {
    name: 'Purchase Orders',
    path: `/${ANIMAL_INVENTORY_ROUTE}/purchase-orders`,
  },
  {
    name: 'Reservations',
    path: `/${ANIMAL_INVENTORY_ROUTE}/reservations`,
  },
  {
    name: 'Experiments',
    path: `/${ANIMAL_INVENTORY_ROUTE}/experiments`,
  },
];

export function AnimalInventoryPage() {
  return (
    <>
      <Header title="Animal Inventory" navLinks={navLinks} />
      <Container maxWidth="xl" component="main">
        <Box paddingX={1} paddingY={3}>
          <Outlet />
        </Box>
      </Container>
    </>
  );
}
