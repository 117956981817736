import { isNil } from 'lodash';
import { type FieldValues, FormProvider, useForm } from 'react-hook-form';

import { OmniForm, type OmniFormFieldProps } from '#components/widgets';
import { CRO_CELL_LINE_PATH, TEST_ARTICLES_ROUTE, i18n } from '#lib/constants';
import { type CellLineFragment } from '#graphql';
import { CroSpecies, CultureProperties, ModelType } from '#lib/croCellLine';

export interface CroCellLineFormData extends FieldValues {
  name: string;
  modelType: string;
  disease: string;
  recommendedGrowthMedia: string;
  cultureProperties: string;
  source: string;
  location: string;
  species: string;
}

interface CroCellLineFormProps {
  cellLine?: CellLineFragment;
  handleSubmit?: (data: CroCellLineFormData) => Promise<void> | void;
}

export function CroCellLineForm(props: CroCellLineFormProps) {
  const { cellLine, handleSubmit = () => undefined } = props;

  const methods = useForm<CroCellLineFormData>({
    defaultValues: {
      name: cellLine?.name ?? '',
      modelType: cellLine?.modelType ?? '',
      disease: cellLine?.disease ?? '',
      recommendedGrowthMedia: cellLine?.recommendedGrowthMedia ?? '',
      cultureProperties: cellLine?.cultureProperties ?? '',
      source: cellLine?.source ?? '',
      location: cellLine?.location ?? '',
      species: cellLine?.species ?? '',
    },
  });
  const [modelType] = methods.watch(['modelType']);

  const validateOnModelTypeChange = (field: string) => {
    if (modelType !== ModelType.pdx) {
      return field === 'recommendedGrowthMedia'
        ? 'recommended growth media is required'
        : 'cultural properties is required';
    }
  };

  const formFields: Array<OmniFormFieldProps<CroCellLineFormData>> = [
    {
      name: 'name',
      rules: { required: 'name is required' },
    },
    {
      name: 'modelType',
      label: i18n.CroCellLines.CroCellLineHeaders.modelType,
      rules: {
        required: 'model type is required',
      },
      select: true,
      selectOptions: Object.values(ModelType),
    },
    {
      name: 'disease',
      rules: { required: 'disease is required' },
    },
    {
      name: 'recommendedGrowthMedia',
      rules: { required: validateOnModelTypeChange('recommendedGrowthMedia') },
      label: i18n.CroCellLines.CroCellLineHeaders.recommendedGrowthMedia,
    },
    {
      name: 'cultureProperties',
      label: i18n.CroCellLines.CroCellLineHeaders.cultureProperties,
      rules: { required: validateOnModelTypeChange('cultureProperties') },
      select: true,
      selectOptions: Object.values(CultureProperties),
    },
    {
      name: 'source',
    },
    {
      name: 'location',
      rules: { required: 'location is required' },
    },
    {
      name: 'species',
      rules: { required: 'species is required' },
      select: true,
      selectOptions: Object.values(CroSpecies),
    },
  ];

  return (
    <FormProvider {...methods}>
      <OmniForm
        fields={formFields}
        formText={i18n.CroCellLines.Form}
        handleSubmit={handleSubmit}
        isNew={isNil(cellLine?.id)}
        successNavigation={`${TEST_ARTICLES_ROUTE}${CRO_CELL_LINE_PATH}`}
      />
    </FormProvider>
  );
}
