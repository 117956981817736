import { isNil } from 'lodash';

import { OmniDrawer } from '#components/widgets/OmniDrawer';
import { type BloodBioanalysisFragment } from '#graphql';
import { type TreatmentGroupWithTreatment } from '#lib/types';
import { CreateBloodBioanalysis } from './CreateBloodBioanalysis';
import { EditBloodBioanalysis } from './EditBloodBioanalysis';

interface BloodBioanalysisModalProps {
  bloodBioanalysis?: BloodBioanalysisFragment;
  onClose: () => void;
  open: boolean;
  experimentId: number;
  treatmentGroups: TreatmentGroupWithTreatment[];
}

export function BloodBioanalysisModal({
  bloodBioanalysis,
  onClose,
  open,
  experimentId,
  treatmentGroups,
}: BloodBioanalysisModalProps) {
  return (
    <OmniDrawer
      large
      open={open}
      onClose={onClose}
      title={`${!isNil(bloodBioanalysis) ? 'Edit' : 'Add'} Blood Bioanalysis`}
    >
      {bloodBioanalysis != null ? (
        <EditBloodBioanalysis
          bloodBioanalysis={bloodBioanalysis}
          onCancel={onClose}
          onSuccess={onClose}
          treatmentGroups={treatmentGroups}
        />
      ) : (
        <CreateBloodBioanalysis
          experimentId={experimentId}
          onCancel={onClose}
          onSuccess={onClose}
          treatmentGroups={treatmentGroups}
        />
      )}
    </OmniDrawer>
  );
}
