import styled from '@emotion/styled';
import { AppColors } from '#lib/constants';

const StyledBox = styled.div(
  ({
    marginTop = '100px',
    width = '500px',
  }: {
    marginTop?: string;
    width?: string;
  }) => ({
    width,
    fontSize: '20px',
    background: AppColors.WHITE,
    borderRadius: '4px',
    boxShadow: '2px 2px 6px rgba(0,0,0,0.2)',
    padding: '30px',
    margin: `${marginTop} auto 0`,
    textAlign: 'center',
  })
);

const StyledTitle = styled.div({
  marginBottom: '10px',
  fontWeight: 'bold',
});

export const MessageBox = ({
  title,
  children,
  ...props
}: {
  title?: string;
  children: React.ReactNode;
  marginTop?: string;
  width?: string;
}) => {
  return (
    <StyledBox {...props}>
      {title && <StyledTitle>{title}</StyledTitle>}
      {children}
    </StyledBox>
  );
};
