import { Ability, AbilityBuilder, detectSubjectType } from '@casl/ability';
import { type BoundCanProps, createContextualCan } from '@casl/react';
import { isNil } from 'lodash';
import React from 'react';

import { type User } from '#graphql';
import { type AppAbility } from '#lib/types';
import { unCapitalize } from '#lib/utils';
import { useAuthState } from './Auth';

export type CanProps = BoundCanProps<AppAbility>;

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- required for now
export const AbilityContext = React.createContext<AppAbility>(null as unknown as AppAbility);

export const Can = createContextualCan(AbilityContext.Consumer);

export const AbilityProvider = ({ children }: { children: React.ReactNode }) => {
  const user = useAuthState();
  const ability = getAbility(user);
  return <AbilityContext.Provider value={ability}>{children}</AbilityContext.Provider>;
};

function getAbility(user?: User | null) {
  // eslint-disable-next-line @typescript-eslint/unbound-method -- following documentation
  const { can, build } = new AbilityBuilder<AppAbility>(Ability);
  const permissions = user?.permissions ?? [];
  for (const p of permissions) {
    can(
      p.action,
      p.subject,
      p.fields,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- JSON/parse
      !isNil(p.conditions) && p.conditions !== '' ? JSON.parse(p.conditions) : undefined
    );
  }
  // @ts-expect-error -- this works right now, not sure how to fix this
  return build({ detectSubjectType: detectOmnivivoSubjectType });
}

function detectOmnivivoSubjectType(subject: { __typename?: string }): string {
  if (!isNil(subject) && typeof subject === 'object' && !isNil(subject.__typename)) {
    if (subject.__typename.startsWith('PK')) {
      return subject.__typename.replace('PK', 'pk');
    }
    // Converting the first character to lower case to Match with Permissions Resource
    return unCapitalize(subject.__typename);
  }

  return detectSubjectType(subject);
}
