import { useEffect, useMemo, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { debounce } from 'lodash';
import { TextField } from '@mui/material';

import {
  ExperimentDetailsFragment,
  useUpdateExperimentMutation,
} from '#graphql';
import { AppColors, DEFAULT_DEBOUNCE } from '#lib/constants';
import { getCharacterCount } from '#lib/utils';
import { SyncIndicator } from '#components/widgets';
interface SyncedExperimentTextAreaProps {
  experimentId: number;
  name: keyof ExperimentDetailsFragment;
  disabled: boolean;
  required: boolean | string;
}

export const SyncedExperimentTextArea = ({
  experimentId,
  name,
  disabled = false,
  required = false,
}: SyncedExperimentTextAreaProps) => {
  const { resetField } = useFormContext();
  const { field, fieldState } = useController({ name, rules: { required } });

  const [updateExperimentMutation, { loading, error }] =
    useUpdateExperimentMutation();

  const updateExperiment = useMemo(() => {
    return debounce((value: string) => {
      updateExperimentMutation({
        variables: {
          input: {
            id: experimentId,
            [name]: value,
          },
        },
        onCompleted({ updateExperiment }) {
          resetField(name, { defaultValue: updateExperiment[name] });
        },
      });
    }, DEFAULT_DEBOUNCE);
  }, []);

  useEffect(() => {
    return () => {
      updateExperiment.flush();
    };
  }, []);

  const [characterCount, setCharacterCount] = useState<number>(
    field.value.length
  );

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCharacterCount(event.target.value.length);
    updateExperiment(event.target.value);
    field.onChange(event);
  };

  return (
    <TextField
      inputProps={{ maxLength: 2000 }}
      disabled={disabled}
      multiline
      rows={10}
      data-test-id={name}
      helperText={getCharacterCount(2000, characterCount)}
      InputProps={{
        sx: {
          alignItems: 'start',
        },
        endAdornment: (
          <SyncIndicator {...{ loading, error, dirty: fieldState.isDirty }} />
        ),
      }}
      fullWidth
      error={!!fieldState.error}
      defaultValue={field.value}
      name={field.name}
      inputRef={field.ref}
      onBlur={field.onBlur}
      onChange={onChange}
      sx={{
        borderRadius: '4px',

        '.MuiOutlinedInput-root': {
          height: 'inherit',
          fontStyle: 'normal',
          fontSize: '18px',
          lineHeight: '1.2',
          color: AppColors.BLACK,
        },
      }}
    />
  );
};
