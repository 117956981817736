import { Box, Grid, InputLabel, Paper, Stack, TextField, Typography } from '@mui/material';
import { compact, debounce, difference, isEmpty, isNil } from 'lodash';
import { useCallback, type ChangeEvent } from 'react';
import toast from 'react-hot-toast';

import {
  bloodMethodLabel,
  bloodTypeLabel,
} from '#components/partials/ConfigureOverview/partials/BloodBioanalysis/bloodBioanalysis';
import { SyncIndicator } from '#components/widgets';
import {
  type ListContentDetailsFragment,
  useUpdatePkBloodWorksheetNotesMutation,
  type BloodBioanalysisFragment,
  type BloodBioanalysisTimepointFragment,
  type BloodBioanalysisTreatmentGroupFragment,
  type ExperimentDetailsFragment,
  type PkBloodWorksheetFragment,
} from '#graphql';
import { DEFAULT_DEBOUNCE, i18n } from '#lib/constants';

interface InformationProps {
  bioanalysisTimepoints: ListContentDetailsFragment[];
  bloodBioanalysis: BloodBioanalysisFragment;
  canUpdateNotes: boolean;
  experiment: ExperimentDetailsFragment;
  worksheet: Pick<PkBloodWorksheetFragment, 'id' | 'notes' | 'status'>;
}

export function Information(props: InformationProps) {
  const { bioanalysisTimepoints, experiment, bloodBioanalysis, canUpdateNotes, worksheet } = props;

  const [updateNotes, { loading, error }] = useUpdatePkBloodWorksheetNotesMutation();

  const onChangeNotes = useCallback(
    debounce((event: ChangeEvent<HTMLInputElement>) => {
      void toast.promise(
        updateNotes({
          variables: {
            input: {
              id: worksheet.id,
              notes: event.target.value,
            },
          },
        }),
        i18n.PKBloodWorksheet.UpdateNotes
      );
    }, DEFAULT_DEBOUNCE),
    [updateNotes]
  );

  const nonDefaultCollectionScheduleGroups =
    bloodBioanalysis.collectionSchedules?.flatMap((collectionSchedule) => collectionSchedule.onlyGroups) ?? [];

  const treatmentGroupIndices = compact(
    bloodBioanalysis.treatmentGroups.map(
      (bioanalysisTreatmentGroup) =>
        experiment.treatmentGroups.find((tg) => tg.id === bioanalysisTreatmentGroup.treatmentGroupId)
          ?.treatmentGroupIndex
    )
  );
  const defaultCollectionScheduleGroups = difference(treatmentGroupIndices, nonDefaultCollectionScheduleGroups);

  return (
    <Box paddingX={4} paddingY={2}>
      <Paper elevation={1}>
        <Box padding={4}>
          <Stack spacing={2}>
            <Typography variant="h3">{experiment.approvalName ?? experiment.name}</Typography>
            <Grid container spacing={1}>
              <Grid item xs={2}>
                <Stack spacing={2}>
                  <Stack spacing={1.5}>
                    <InputLabel>Blood Type</InputLabel>
                    <Typography noWrap variant="body2" title={bloodTypeLabel(bloodBioanalysis.bloodType) ?? ''}>
                      {bloodTypeLabel(bloodBioanalysis.bloodType) ?? ''}
                    </Typography>
                  </Stack>
                  <Stack spacing={1.5}>
                    <InputLabel>Method(s)</InputLabel>
                    <Stack spacing={1}>
                      {bloodBioanalysis.methods.map((method) => (
                        <Typography noWrap key="method" variant="body2" title={bloodMethodLabel(method) ?? ''}>
                          {bloodMethodLabel(method) ?? ''}
                        </Typography>
                      ))}

                      {!isNil(bloodBioanalysis.otherMethods) && bloodBioanalysis.otherMethods !== '' && (
                        <Typography noWrap key="method" variant="body2" title={bloodBioanalysis.otherMethods}>
                          {bloodBioanalysis.otherMethods}
                        </Typography>
                      )}
                    </Stack>
                  </Stack>
                  <Stack spacing={1.5}>
                    <InputLabel>Final Sample Amount (ul)</InputLabel>
                    <Typography variant="body2">{bloodBioanalysis.sampleAmount}</Typography>
                  </Stack>
                </Stack>
              </Grid>
              <Grid item xs={3}>
                <Stack spacing={2}>
                  {bloodBioanalysis.collectionSchedules?.map((schedule, index) => {
                    const groups = schedule.isDefault
                      ? isEmpty(defaultCollectionScheduleGroups)
                        ? 'none'
                        : defaultCollectionScheduleGroups.toString()
                      : schedule.onlyGroups.toString();

                    return (
                      <Stack spacing={1.5} key={schedule.id}>
                        <div>
                          <InputLabel title={`${formatCollectionScheduleTitle(schedule, index)} (Group(s): ${groups})`}>
                            {formatCollectionScheduleTitle(schedule, index)}
                            {'  '}
                            <Typography variant="body2" component="span">
                              (Group(s): {groups})
                            </Typography>
                          </InputLabel>
                        </div>
                        <Stack spacing={1}>
                          {schedule.timepoints.map((timepoint) => (
                            <Typography
                              key={timepoint.id}
                              variant="body2"
                              title={formatTimepoint(timepoint, bioanalysisTimepoints)}
                            >
                              {formatTimepoint(timepoint, bioanalysisTimepoints)}
                            </Typography>
                          ))}
                        </Stack>
                      </Stack>
                    );
                  })}
                </Stack>
              </Grid>
              <Grid item xs={4}>
                <Stack spacing={1.5}>
                  <InputLabel>Groups</InputLabel>
                  <Stack spacing={1}>
                    {bloodBioanalysis.treatmentGroups.map((tg) => {
                      return (
                        <Typography noWrap key={tg.id} variant="body2" title={formatGroup(tg, experiment)}>
                          {formatGroup(tg, experiment)}
                        </Typography>
                      );
                    })}
                  </Stack>
                </Stack>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  name="notes"
                  inputProps={{ maxLength: 1000 }}
                  disabled={!canUpdateNotes}
                  multiline
                  InputProps={{
                    sx: {
                      alignItems: 'start',
                    },
                    endAdornment: <SyncIndicator dirty={false} error={error} loading={loading} />,
                  }}
                  label="Notes"
                  fullWidth
                  minRows={4}
                  onChange={onChangeNotes}
                  defaultValue={worksheet.notes}
                />
              </Grid>
            </Grid>
          </Stack>
        </Box>
      </Paper>
    </Box>
  );
}

function formatCollectionScheduleTitle({ isDefault }: { isDefault: boolean }, index: number) {
  return isDefault ? 'Default Collection Schedule' : `Collection Schedule ${index + 1}`;
}

type TimepointArgs = Pick<BloodBioanalysisTimepointFragment, 'dose' | 'minutes' | 'onlyAnimals'>;
function formatTimepoint(args: TimepointArgs, bioanalysisTimepoints: Array<{ name: string; description?: string }>) {
  const { dose, minutes, onlyAnimals } = args;
  const timepointLabel =
    bioanalysisTimepoints.find((bioanalysisTimepoint) => bioanalysisTimepoint.name === minutes.toString())
      ?.description ?? '';
  return `Dose ${dose}: ${timepointLabel} (${
    isEmpty(onlyAnimals) ? 'all animals' : `animals ${onlyAnimals.toString()}`
  })`;
}

function formatGroup(
  bloodBioanalysisTreatmentGroup: BloodBioanalysisTreatmentGroupFragment,
  experiment: ExperimentDetailsFragment
) {
  const { treatmentGroupId, baseTestArticleId: _baseTestArticleId } = bloodBioanalysisTreatmentGroup;
  const treatmentGroup = experiment.treatmentGroups.find((tg) => tg.id === treatmentGroupId);
  const testArticles = experiment.treatments.find((treatment) => treatment.id === treatmentGroup?.treatmentId)
    ?.testArticles;
  return `${treatmentGroup?.treatmentGroupIndex}: ${testArticles
    ?.map((testArticle) => `${testArticle.name}, ${testArticle.dosage}${testArticle.doseUnit}`)
    .join('; ')}`;
}
