import { pick } from 'lodash';
import { Container } from '@mui/material';
import { useUpdateUserMutation } from '#graphql';
import { CanRoute } from '#components/widgets';
import { UserFormData, UserForm } from './UserForm';
import { useAuthState } from '#components/contexts';

export function EditProfile() {
  const [updateUser] = useUpdateUserMutation();
  const user = useAuthState()!;

  async function handleSubmit(data: UserFormData) {
    await updateUser({
      variables: {
        input: {
          ...pick(data, ['firstName', 'lastName']),
          id: user.id,
        },
      },
      awaitRefetchQueries: true,
      refetchQueries: ['Me'],
    });
  }

  return (
    <Container maxWidth="md">
      <CanRoute I="update" this={user as never}>
        <UserForm user={user} handleSubmit={handleSubmit} />
      </CanRoute>
    </Container>
  );
}
