import { Box, Button, InputLabel, TextField } from '@mui/material';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import ScienceIcon from '@mui/icons-material/Science';
import { LoadingButton } from '@mui/lab';
import toast from 'react-hot-toast';
import { map } from 'lodash';
import { useForm } from 'react-hook-form';
import { DateTime } from 'luxon';

import {
  AdcOrderItem,
  AdcOrderItemDetailsFragment,
  useDeliverAdcOrderItemsMutation,
  useReturnAdcOrderItemsMutation,
} from '#graphql';
import { i18n } from '#lib/constants';
import {
  canDeliverEveryAdcOrderItem,
  canReturnEveryAdcOrderItem,
  lastDeliveredAt,
  lastReturnedAt,
} from '#lib/adcOrder';
import { EditableAdcOrderItemFields } from './AdcOrder';

export interface DeliverSectionProps {
  adcOrderId: AdcOrderItem['id'];
  adcOrderItems: AdcOrderItemDetailsFragment[];
  canEditField: (field: EditableAdcOrderItemFields) => boolean;
  bioregId: AdcOrderItemDetailsFragment['bioregId'];
  onDeliver: () => void;
}

type DeliverFormInputs = {
  deliveredAt: string;
};

// yikes, we are getting just a date, not a datetime from the date field
// so we have to explicitly convert to UTC before saving, otherwise the
// time will be set to 00:00:00 UTC, resulting in this displaying the
// incorrect date in locale time.
export function convertDate(value: string) {
  return DateTime.fromISO(value).toUTC().toISO();
}

export const DeliverSection = ({
  adcOrderId,
  adcOrderItems,
  bioregId,
  canEditField,
  onDeliver,
}: DeliverSectionProps) => {
  const deliveredAt = lastDeliveredAt(adcOrderItems);
  const returnedAt = lastReturnedAt(adcOrderItems);
  const canDeliver = canDeliverEveryAdcOrderItem(adcOrderItems);
  const canReturn = canReturnEveryAdcOrderItem(adcOrderItems);

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isSubmitting, isValid },
  } = useForm<DeliverFormInputs>({
    defaultValues: {
      deliveredAt: deliveredAt?.toFormat('yyyy-MM-dd') ?? '',
    },
    mode: 'all',
  });

  const [deliverAdcOrderItems] = useDeliverAdcOrderItemsMutation();
  const [returnAdcOrderItems] = useReturnAdcOrderItemsMutation();

  const deliver = async (deliveredAt?: string) => {
    await deliverAdcOrderItems({
      variables: {
        input: {
          adcOrderId,
          adcOrderItemIds: map(adcOrderItems, 'id'),
          deliveredAt: deliveredAt ? convertDate(deliveredAt) : undefined,
        },
      },
    });
    onDeliver();
  };

  const handleDeliverClick = () => {
    return toast.promise(deliver(), i18n.DeliverAdcOrderItem);
  };

  const handleDeliveredAtSubmit = ({ deliveredAt }: DeliverFormInputs) => {
    if (!deliveredAt) {
      return;
    }
    return toast.promise(deliver(deliveredAt), i18n.UpdateAdcOrderItemDelivery);
  };

  const onReturn = () =>
    returnAdcOrderItems({
      variables: {
        input: {
          adcOrderId,
          adcOrderItemIds: map(adcOrderItems, 'id'),
        },
      },
    });

  const handleReturnClick = () => {
    return toast.promise(onReturn(), i18n.ReturnAdcOrderItem);
  };

  if (!deliveredAt) {
    if (canEditField('deliveredAt')) {
      return (
        <Button
          startIcon={<ScienceIcon />}
          variant="contained"
          disableElevation
          disabled={!canDeliver}
          onClick={handleDeliverClick}
        >
          Deliver
        </Button>
      );
    } else {
      return null;
    }
  }

  const canEditReturn = canEditField('returnedAt');
  const canEditDeliver = canEditField('deliveredAt') && !returnedAt;

  return (
    <Box display="flex" gap={1} flexDirection="column" alignItems="end">
      <form onSubmit={handleSubmit(handleDeliveredAtSubmit)}>
        <Box display="flex" gap={1} alignItems="center">
          <InputLabel htmlFor={`${bioregId}-deliveredAt`}>
            Delivered:
          </InputLabel>
          <TextField
            {...register('deliveredAt', {
              required: i18n.AdcOrderDataValidations.deliveredAtRequired,
            })}
            id={`${bioregId}-deliveredAt`}
            disabled={!canEditDeliver}
            error={!!errors.deliveredAt}
            type="date"
          />
          {canEditDeliver && (
            <LoadingButton
              variant="contained"
              type="submit"
              loading={isSubmitting}
              disabled={!isDirty || !isValid}
            >
              Update
            </LoadingButton>
          )}
          {canEditReturn && canReturn && (
            <Button
              startIcon={<KeyboardReturnIcon />}
              variant="contained"
              disableElevation
              color="error"
              onClick={handleReturnClick}
            >
              Return
            </Button>
          )}
        </Box>
      </form>
      {returnedAt && (
        <Box display="flex" gap={1} alignItems="center">
          <InputLabel htmlFor={`${bioregId}-returnedAt`}>Returned:</InputLabel>
          <TextField
            defaultValue={returnedAt?.toFormat('yyyy-MM-dd') ?? ''}
            name="returnedAt"
            id={`${bioregId}-returnedAt`}
            disabled={true}
            type="date"
          />
        </Box>
      )}
    </Box>
  );
};
