import { LicenseInfo } from '@mui/x-license-pro';
import { Helmet } from 'react-helmet';
import { Toaster } from 'react-hot-toast';
import { Router } from './Router';
import { i18n } from '#lib/constants';

LicenseInfo.setLicenseKey(`${process.env.DATAGRID_KEY}`);

function App() {
  return (
    <>
      <Helmet>
        <title>{i18n.AppName}</title>
      </Helmet>
      <Router />
      <Toaster />
    </>
  );
}

export default App;
