import { GridColDef, GridRowId } from '@mui/x-data-grid-premium';
import { useNavigate } from 'react-router-dom';
import { compact, map, uniqBy } from 'lodash';
import { useCallback, useMemo } from 'react';
import { useAbility } from '@casl/react';
import {
  InventorySummaryFragment,
  Species,
  useDeleteInventoryMutation,
  useListContentsQuery,
  useListInventoryLazyQuery,
} from '#graphql';
import { AbilityContext } from '#components/contexts';
import { OmniGrid } from '#components/widgets';
import { ANIMAL_INVENTORY_ROUTE, i18n } from '#lib/constants';
import { formatDate } from '#lib/utils';

export function PurchaseOrders() {
  const navigate = useNavigate();
  const ability = useAbility(AbilityContext);

  const [deleteInventory] = useDeleteInventoryMutation();

  const listContentsQueryResult = useListContentsQuery({
    variables: {
      category: 'strain',
    },
    fetchPolicy: 'cache-first',
  });

  const [getInventory, { loading, error, data, previousData }] =
    useListInventoryLazyQuery();

  const [species, strains] = useMemo(() => {
    const strains = listContentsQueryResult.data?.listContents.items ?? [];
    const species = compact(
      map(uniqBy(strains, 'description'), 'description')
    ).sort() as Species[];
    const strainNames = map(strains, 'name').sort();
    return [species, strainNames];
  }, [listContentsQueryResult]);

  const currentData = data?.listInventory ?? previousData?.listInventory;

  const rows = currentData?.items ?? [];
  const rowCount = currentData?.totalCount ?? 0;

  const handleEdit = useCallback(
    (id: GridRowId) =>
      navigate(`/${ANIMAL_INVENTORY_ROUTE}/purchase-orders/${id}/edit`),
    [navigate]
  );

  const onDelete = useCallback(
    async (id: GridRowId) => {
      await deleteInventory({
        variables: {
          id: Number(id),
        },
        awaitRefetchQueries: true,
        refetchQueries: ['ListInventory'],
      });
    },
    [deleteInventory]
  );

  const columns: GridColDef<InventorySummaryFragment>[] = useMemo(() => {
    return [
      {
        field: 'status',
        flex: 1,
        headerName: 'Status',
        type: 'singleSelect',
        valueOptions: () => ['closed', 'open'],
      },
      {
        field: 'poNumber',
        flex: 1,
        headerName: 'PO #',
        disableColumnMenu: true,
        sortable: false,
        filterable: false,
      },
      {
        field: 'species',
        flex: 1,
        headerName: 'Species',
        type: 'singleSelect',
        valueOptions: () => species,
      },
      {
        field: 'strain',
        flex: 1,
        headerName: 'Strain',
        type: 'singleSelect',
        valueOptions: () => strains,
        disableColumnMenu: true,
        sortable: false,
        filterable: false,
      },
      {
        field: 'gender',
        flex: 1,
        headerName: 'Gender',
        type: 'singleSelect',
        valueOptions: () => ['female', 'male', 'mixed'],
      },
      {
        field: 'dob',
        flex: 1,
        headerName: 'Receipt Date',
        valueFormatter: (params) =>
          params.value ? formatDate(params.value) : '',
        minWidth: 120,
        disableColumnMenu: true,
        sortable: false,
        filterable: false,
      },
      {
        field: 'quantity',
        flex: 1,
        headerName: 'Ordered',
        disableColumnMenu: true,
        sortable: false,
        filterable: false,
      },
      {
        field: 'used',
        flex: 1,
        headerName: 'Used',
        disableColumnMenu: true,
        sortable: false,
        filterable: false,
      },
      {
        field: 'reserved',
        flex: 1,
        headerName: 'Reserved',
        disableColumnMenu: true,
        sortable: false,
        filterable: false,
      },
      {
        field: 'available',
        flex: 1,
        headerName: 'Available',
        valueGetter: (params) =>
          params.row.quantity -
          (params.row.used ?? 0) -
          (params.row.reserved ?? 0),
        disableColumnMenu: true,
        sortable: false,
        filterable: false,
      },
      {
        field: 'notes',
        flex: 1,
        headerName: 'Notes',
        disableColumnMenu: true,
        sortable: false,
        filterable: false,
      },
    ];
  }, [species, strains]);

  return (
    <OmniGrid
      columns={columns}
      defaultSort={[{ field: 'createdAt', sort: 'asc' }]}
      error={error}
      loading={loading}
      refetch={getInventory}
      rows={rows}
      rowCount={rowCount}
      showDeleteButton={ability.can('delete', 'inventory')}
      deleteConfirmProps={{
        modalText: i18n.PurchaseOrders.DeleteModal,
        onConfirm: onDelete,
      }}
      showEditButton={ability.can('update', 'inventory')}
      editButtonProps={{ handleEdit }}
      headerProps={{
        title: 'Purchase Orders',
        showAddButton: ability.can('create', 'inventory'),
        addButtonProps: {
          label: 'Add Purchase Order',
          to: `/${ANIMAL_INVENTORY_ROUTE}/purchase-orders/create`,
        },
      }}
      showQuickFilter={true}
    />
  );
}
