"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BrandGradient = exports.ChartColors = exports.AppColors = void 0;
exports.AppColors = {
    WHITE: '#FFFFFF',
    VERY_LIGHT_GREY: '#FAFAFA',
    VERY_LIGHT_GREEN: '#E5F3EC',
    LIGHT_LIGHT_GREY: '#F7F7F7',
    LIGHT_GREY: '#EAEAEA',
    LIGHT_MID_GREY: '#CECECE',
    MID_GREY: '#AEAEAE',
    LOW_GREY: '#EFEFEF',
    GREY: '#747474',
    GREY1: '#CFCFCF',
    GREY2: '#E6E6E6',
    GREY3: '#E2E2E2',
    GREY4: '#F4F4F4',
    GREY5: '#F1F1F1',
    GREY6: '#F8F8F8',
    GREY7: '#E8E8E8',
    DARK_GREY: '#5F5F5F',
    DARK_DARK_GREY: '#2C2C2C',
    DARK_SHADE_RGBA: 'rgba(0,0,0,0.2)',
    BLACK: '#000000',
    BRAND_GREEN: '#008844',
    DARK_BRAND_GREEN: '#006231',
    LIGHT_BRAND_GREEN: '#8DCD45',
    GREEN1: '#1B9C6B',
    GREEN2: '#69BB99',
    GREEN3: '#B3DBC7',
    GREEN4: '#CCE7DA',
    GREEN5: '#E6F3EC',
    GREEN6: '#007C4C',
    DARK_CYAN: '#13BCFF',
    CYAN: '#5FECFD',
    SHADE_BLUE: '#CFD9FF',
    SPECTRA_BLUE: '#6DD3FF',
    SPECTRA_LIGHT_BLUE: '#95DFFF',
    SPECTRA_YELLOW: '#FAF129',
    SPECTRA_GREEN: '#A1ED25',
    SPECTRA_RED: '#FA511B',
    LIGHT_CYAN: '#B7F7FF',
    DARK_BLUE: '#4917B2',
    BLUE: '#829BFF',
    LIGHT_BLUE: '#B4C3FF',
    PURPLE: '#A105C0',
    DARK_PURPLE: '#6b0380',
    VIOLET: '#E100BA',
    PINK: '#FF95ED',
    DARK_RED: '#C90000',
    RED: '#FF0000',
    LIGHT_RED: '#FFCACA',
    DARK_ORANGE: '#FF9632',
    ORANGE: '#FDC24B',
    MERCURY: '#E1E1E1',
    LIGHT_ORANGE: '#FFE4AC',
    DARK_YELLOW: '#DDC700',
    YELLOW: '#FFE600',
    LIGHT_YELLOW: '#FFF7AB',
    LIME_GREEN: '#8DD615',
    BRIGHT_GREEN: '#D9FE0E',
    LIGHT_BRIGHT_GREEN: '#D9FE0E',
    DARK_TURQUOISE: '#2D938F',
    TURQUOISE: '#43D9A3',
    LIGHT_TURQUOISE: '#A7FFE0',
};
exports.ChartColors = [
    exports.AppColors.BRAND_GREEN,
    exports.AppColors.BLUE,
    exports.AppColors.RED,
    exports.AppColors.CYAN,
    exports.AppColors.PURPLE,
    exports.AppColors.ORANGE,
    exports.AppColors.DARK_BRAND_GREEN,
    exports.AppColors.DARK_BLUE,
    exports.AppColors.DARK_RED,
    exports.AppColors.DARK_CYAN,
    exports.AppColors.DARK_PURPLE,
    exports.AppColors.DARK_ORANGE,
];
exports.BrandGradient = `linear-gradient(${exports.AppColors.BRAND_GREEN}, ${exports.AppColors.LIGHT_BRAND_GREEN})`;
