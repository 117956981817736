import {
  OtherMolecule,
  OtherMoleculesByPageDocument,
  OtherMoleculesByPageQuery,
  useCreateOtherMoleculeMutation,
  useDeleteOtherMoleculeMutation,
  useOtherMoleculesByPageQuery,
  useUpdateOtherMoleculeMutation,
} from '#graphql';
import { i18n } from '#lib/constants';
import toast from 'react-hot-toast';
import {
  CreateTemplateEditorItemType,
  TemplateEditorItem,
} from '#components/partials/TemplateEditorItem';
import { useApolloClient } from '@apollo/client';

const ALLOWED_CATEGORIES = ['control', 'other', 'immunogen', 'non-SG drug'];

function isOtherMolecule(item: Record<string, any>): item is OtherMolecule {
  return (
    !!(item as OtherMolecule).id &&
    !!(item as OtherMolecule).name &&
    !!(item as OtherMolecule).category
  );
}

function isCreateOtherMoleculeInput(
  item: Record<string, any>
): item is Omit<OtherMolecule, 'id'> {
  return !!(item as OtherMolecule).name && !!(item as OtherMolecule).category;
}

export const OtherMoleculesEditor = () => {
  const client = useApolloClient();
  const [createOtherMolecule, { loading: createLoading }] =
    useCreateOtherMoleculeMutation();
  const [updateOtherMolecule, { loading: updateLoading }] =
    useUpdateOtherMoleculeMutation();
  const [deleteOtherMolecule, { loading: deleteLoading }] =
    useDeleteOtherMoleculeMutation();
  const loading = createLoading || updateLoading || deleteLoading;
  const { data } = useOtherMoleculesByPageQuery({
    variables: {
      offset: 0,
      limit: 10000,
      isEditable: true,
      category: 'all',
    },
  });
  const otherMolecules = data?.otherMolecules?.items || [];

  const addItem = (item: CreateTemplateEditorItemType) => {
    item.category = getCategoryLabel(item.category!);
    if (!item.category) {
      toast.error(
        i18n.Settings.InvalidCategory + ALLOWED_CATEGORIES.join(', ')
      );
      return;
    }
    if (isOtherMolecule(item)) {
      updateOtherMolecule({
        variables: { input: item },
        onError: (error) => {
          toast.error(error.message);
        },
        onCompleted: ({ updateOtherMolecule }) => {
          updateQuery(
            otherMolecules.map((item) =>
              item.id === updateOtherMolecule.id ? updateOtherMolecule : item
            )
          );
        },
      });
      return;
    } else if (
      data?.otherMolecules?.items?.some(
        (m) => m.name === item.name && m.category === item.category
      )
    ) {
      toast.error(i18n.Settings.IsDuplicate);
      return;
    } else if (isCreateOtherMoleculeInput(item)) {
      createOtherMolecule({
        variables: { input: item },
        onCompleted: ({ createOtherMolecule }) => {
          updateQuery([...otherMolecules, createOtherMolecule]);
        },
        onError: (error) => {
          toast.error(error.message);
        },
      });
    }
  };

  const removeItem = (id: number) => {
    if (id) {
      deleteOtherMolecule({
        variables: { id },
        onCompleted: () => {
          const normalizedId = client.cache.identify({
            id,
            __typename: 'OtherMolecule',
          });
          client.cache.evict({ id: normalizedId });
          client.cache.gc();
        },
        onError: (error) => {
          toast.error(error.message);
        },
      });
    }
  };

  const updateQuery = (
    items: OtherMoleculesByPageQuery['otherMolecules']['items']
  ) => {
    client.writeQuery({
      query: OtherMoleculesByPageDocument,
      variables: {
        offset: 0,
        limit: 10000,
        isEditable: true,
        category: 'all',
      },
      data: {
        otherMolecules: {
          items: items.map((item) => ({
            ...item,
            bioregId: item.bioregId ?? null,
            otherNames: item.otherNames ?? null,
            commonName: item.commonName ?? null,
            __typename: 'OtherMolecule',
          })),
          totalCount: items.length,
        },
      },
    });
  };

  return (
    <TemplateEditorItem
      items={otherMolecules}
      title="Test Articles"
      addItem={addItem}
      removeItem={removeItem}
      minItemCount={0}
    />
  );
};

export function getCategoryLabel(category: string): string | undefined {
  const index = ALLOWED_CATEGORIES.map((c) =>
    c.toLowerCase().replace(/\W/g, '')
  ).indexOf(category.toLowerCase().replace(/\W/g, '').replace(/s$/, ''));
  if (index !== -1) return ALLOWED_CATEGORIES[index];
}
