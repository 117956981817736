import { type ApolloError } from '@apollo/client';
import { type GridRowId } from '@mui/x-data-grid-premium';
import { type DateTime } from 'luxon';
import { createContext, useContext } from 'react';

interface MutationStatus {
  error: ApolloError | undefined;
  loading: boolean;
}

type MutationStatuses = Record<
  GridRowId,
  {
    updateActualTimeStatus: MutationStatus;
    updateHandlingAndStorageStatus: MutationStatus;
  }
>;

export interface DetailPanelContextValue {
  error: ApolloError | undefined;
  loading: boolean;
  updates: number[];
  mutationStatuses: MutationStatuses;
  updateActualTime: (rowId: GridRowId, value: DateTime | null) => Promise<void>;
  updateHandlingAndStorageCollected: (rowId: GridRowId, id: number, collected: boolean) => Promise<void>;
  updateHandlingAndStorageWeight: (rowId: GridRowId, id: number, weight: number | null) => Promise<void>;
}

const initialValue = {
  error: undefined,
  loading: false,
  updates: [],
  mutationStatuses: {},
  updateActualTime: async () => {
    await Promise.resolve();
  },
  updateHandlingAndStorageCollected: async () => {
    await Promise.resolve();
  },
  updateHandlingAndStorageWeight: async () => {
    await Promise.resolve();
  },
};

export const DetailPanelContext = createContext<DetailPanelContextValue>(initialValue);

export const useDetailPanelContext = () => useContext(DetailPanelContext);
