import { AddOutlined } from '@mui/icons-material';
import toast from 'react-hot-toast';

import {
  useDeleteUserFromExperimentMutation,
  DeleteUserFromExperimentInput,
  ExperimentDetailsFragment,
} from '#graphql';
import { i18n } from '#lib/constants';
import { NameBubble } from '#components/widgets';
import { useModal, useUpdateExperiment } from '#lib/hooks';
import { handleApolloError } from '#lib/utils';
import {
  StyledButton,
  StyledButtonContainer,
  StyledContainer,
  StyledItem,
  StyledTeamContainer,
} from './styles';
import { AddUsersModal } from './';

export const AssociatedUsers = ({
  experimentId,
  team,
  editable,
}: {
  experimentId: number;
  team: ExperimentDetailsFragment['team'];
  editable: boolean;
}) => {
  const { updateExperimentCache } = useUpdateExperiment();
  const { Modal, openModal, closeModal } = useModal(
    'Add Users to Experiment',
    'xs'
  );

  const [deleteUser] = useDeleteUserFromExperimentMutation();

  const onDeleteUser = async (userId: number) => {
    const deleteInput: DeleteUserFromExperimentInput = {
      experimentId,
      userId,
    };
    const tid = toast.loading(i18n.ExperimentView.DeletingAssociatedUser);
    const response = await deleteUser({
      variables: { input: deleteInput },
      onError(error) {
        toast.dismiss(tid);
        toast.error(handleApolloError(error));
      },
    });
    if (response?.data) {
      updateExperimentCache({
        team: response.data.deleteUserFromExperiment.team,
        id: experimentId,
      });
    }
    toast.dismiss(tid);
  };

  const excludedUsers = team?.map((member) => member!.id) || [];

  return (
    <StyledContainer>
      <Modal>
        <AddUsersModal
          onClose={closeModal}
          experimentId={experimentId}
          excludedUsers={excludedUsers}
        />
      </Modal>
      <StyledTeamContainer>
        {team?.map((member, index) => (
          <StyledItem key={index}>
            <NameBubble
              user={member!}
              onDelete={() => onDeleteUser(member?.id!)}
              deleteButton={editable}
            />
          </StyledItem>
        ))}
      </StyledTeamContainer>

      <StyledButtonContainer>
        <StyledButton disabled={!editable} onClick={openModal}>
          <AddOutlined /> Add User
        </StyledButton>
      </StyledButtonContainer>
    </StyledContainer>
  );
};
