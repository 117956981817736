import { Select, type SelectProps, selectClasses } from '@mui/material';

const styleOverrides = {
  borderRadius: '1.5rem',
  [`& .${selectClasses.select}`]: {
    px: 2,
    py: 1,
  },
} as const;

export default function RoundSelect<Value>(props: SelectProps<Value>) {
  return <Select {...props} sx={styleOverrides} />;
}
