import { useEffect, useState } from 'react';
import { CloseOutlined, ContentCopy, AddOutlined } from '@mui/icons-material';
import { isNil } from 'lodash';
import toast from 'react-hot-toast';
import { Controller, useFormContext } from 'react-hook-form';

import { AppColors, i18n } from '#lib/constants';
import {
  type Treatment,
  useDuplicateTreatmentToExperimentMutation,
  useDeleteTreatmentFromExperimentMutation,
  type TestArticle,
  type ExperimentDetailsFragment,
} from '#graphql';
import { Can } from '#components/contexts';
import { ConfirmationModal } from '#components/partials';
import { useUpdateExperiment, useModal } from '#lib/hooks';

import { TreatmentModalContent } from '#components/partials/ConfigureOverview/partials';
import { TreatmentCardFields } from './TreatmentCardFields';
import {
  StyledCard,
  StyledRow,
  StyledColumn,
  StyledNumber,
  StyledGrayBackground,
  StyledEditButton,
  StyledCloseButton,
  StyledButtonContainer,
  StyledButton,
} from './styles';

const AddTreatmentButton = ({
  onChange,
  onClick,
  treatments,
  disabled,
}: {
  onChange: (treatmentCount: number) => void;
  onClick: () => void;
  treatments: Treatment[];
  disabled: boolean;
}) => {
  const methods = useFormContext();
  useEffect(() => {
    onChange(treatments.length);
  }, [treatments]);
  return (
    <StyledButton
      invalid={!isNil(methods.formState.errors?.treatmentCount)}
      data-test-id={`addTreatment`}
      disabled={disabled}
      onClick={onClick}
    >
      <AddOutlined /> Add Treatment
    </StyledButton>
  );
};

export const TreatmentCard = ({
  treatments,
  experiment,
  disabled,
}: {
  treatments: Treatment[];
  experiment: ExperimentDetailsFragment;
  disabled: boolean;
}) => {
  const methods = useFormContext();
  const { updateExperimentCache } = useUpdateExperiment();
  const [selectedTreatment, setSelectedTreatment] = useState<Treatment | undefined>();
  const [treatmentId, setTreatmentId] = useState<number | undefined>();

  const {
    Modal: DeleteTreatment,
    openModal: openDeleteTreatment,
    closeModal: closeDeleteTreatment,
  } = useModal(i18n.TreatmentCard.DeleteConfirmation, 'sm');

  const [currentTreatmentId, setCurrentTreatmentId] = useState<number>();

  const { Modal, openModal, closeModal } = useModal('Select Test Articles For Treatment', 'md');

  const [duplicateTreatment] = useDuplicateTreatmentToExperimentMutation({
    onCompleted: (data) => {
      updateExperimentCache({
        treatments: data.duplicateTreatmentToExperiment,
        id: experiment.id,
      });
    },
  });

  const [deleteTreatment] = useDeleteTreatmentFromExperimentMutation({
    onCompleted: (data) => {
      updateExperimentCache({
        treatments: data.removeTreatment?.treatments,
        id: experiment.id,
      });
    },
    onError: () => {
      toast.error(i18n.TreatmentCard.CantDelete);
    },
  });

  const handleDelete = async (id?: number) => {
    closeDeleteTreatment();
    if (!isNil(id)) {
      const tid = toast.loading(i18n.Common.Deleting);
      await deleteTreatment({
        variables: {
          id,
        },
      });
      toast.dismiss(tid);
    }
    setCurrentTreatmentId(undefined);
  };

  const handleDuplicate = async (id: number) => {
    const tid = toast.loading(i18n.Common.Duplicating);
    await duplicateTreatment({
      variables: {
        id,
      },
    });
    toast.dismiss(tid);
  };

  const handleEdit = (treatment: Treatment) => {
    setTreatmentId(treatment.id);
    setSelectedTreatment(treatment);
    openModal();
  };

  const handleAddTreatment = () => {
    setTreatmentId(undefined);
    setSelectedTreatment(undefined);
    openModal();
  };

  return (
    <>
      <Modal>
        <TreatmentModalContent
          handleClose={closeModal}
          experimentId={experiment.id}
          selectedTreatment={selectedTreatment}
          treatmentId={treatmentId}
        />
      </Modal>

      <DeleteTreatment>
        <ConfirmationModal
          closeModal={() => {
            setCurrentTreatmentId(undefined);
            closeDeleteTreatment();
          }}
          confirmationFunction={async () => {
            await handleDelete(currentTreatmentId);
          }}
        />
      </DeleteTreatment>
      {treatments.map((treatment) => (
        <StyledCard data-test-id={`treatmentCard`} key={treatment.id}>
          <StyledRow>
            <StyledNumber cardcolor={AppColors.SPECTRA_YELLOW}>{treatment.number}</StyledNumber>
            <StyledColumn>
              <Can I="update" this={experiment} field="treatments.testArticles.route" passThrough>
                {(allowed) =>
                  treatment.testArticles.map((testArticle: TestArticle) => (
                    <TreatmentCardFields
                      key={`field-${testArticle.id}`}
                      testArticle={testArticle}
                      disabled={disabled}
                      canUpdateRoute={allowed}
                    />
                  ))
                }
              </Can>
            </StyledColumn>
            {!disabled && (
              <StyledGrayBackground data-test-id="treatmentActions">
                <StyledRow isGray gap="5px">
                  <StyledEditButton
                    onClick={() => {
                      handleEdit(treatment);
                    }}
                  >
                    edit
                  </StyledEditButton>
                  <StyledCloseButton
                    marginRight="0"
                    onClick={async () => {
                      await handleDuplicate(treatment.id);
                    }}
                  >
                    <ContentCopy fontSize="small" />
                  </StyledCloseButton>
                  <StyledCloseButton
                    data-test-id={'deleteTreatmentButton'}
                    background={AppColors.LIGHT_GREY}
                    onClick={() => {
                      setCurrentTreatmentId(treatment.id);
                      openDeleteTreatment();
                    }}
                  >
                    <CloseOutlined fontSize="small" />
                  </StyledCloseButton>
                </StyledRow>
              </StyledGrayBackground>
            )}
          </StyledRow>
        </StyledCard>
      ))}
      <StyledButtonContainer gap="5px">
        <Controller
          control={methods.control}
          name="treatmentCount"
          rules={{
            min: { value: 1, message: i18n.ValidateExperiment.NoTreatments },
          }}
          render={({ field: { onChange } }) => (
            <AddTreatmentButton
              onChange={onChange}
              disabled={disabled}
              onClick={() => {
                handleAddTreatment();
              }}
              treatments={treatments}
            />
          )}
        />
      </StyledButtonContainer>
    </>
  );
};
