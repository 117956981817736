import { Container, Box } from '@mui/material';
import { Header } from '#components/widgets';
import { Outlet } from 'react-router';

export const UsersPage = () => {
  return (
    <>
      <Header title="User Management" />
      <Container maxWidth="xl" component="main">
        <Box paddingX={1} paddingY={3}>
          <Outlet />
        </Box>
      </Container>
    </>
  );
};
