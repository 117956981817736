import { type GridColDef } from '@mui/x-data-grid-premium';
import { groupBy, isNil, sumBy, uniq } from 'lodash';
import { useMemo } from 'react';
import { useInventoryRequirementsLazyQuery } from '#graphql';
import { OmniGrid, DEFAULT_PAGE_SIZE } from '#components/widgets';
import { formatDate, getUserFullName } from '#lib/utils';

export interface ExperimentsRowModel {
  groupedApprovalName?: string;
  id: number;
  approvalName: string;
  researcher: string;
  strain: string;
  required: number;
  reserved: number;
  fulfilled: number;
  poReceivedDates: string;
}

export function Experiments() {
  const [getExperiments, { loading, error, data, previousData }] = useInventoryRequirementsLazyQuery({
    variables: { statuses: ['Provision', 'Ready', 'Execution'], limit: DEFAULT_PAGE_SIZE },
  });

  const currentData = data?.experiments ?? previousData?.experiments;

  const rows = useMemo(() => {
    const rows: ExperimentsRowModel[] = [];

    for (const experiment of currentData?.items ?? []) {
      const { id, approvalName, orders, researcher, inventoryRequests = [] } = experiment;

      const researcherFullName = !isNil(researcher) ? getUserFullName(researcher) : 'N/A';
      if (isNil(orders?.animalOrder?.animalOrderItems)) {
        rows.push({
          id,
          approvalName: approvalName!,
          researcher: researcherFullName,
          strain: 'N/A',
          required: 0,
          reserved: 0,
          fulfilled: 0,
          poReceivedDates: 'N/A',
        });
      } else {
        const animalOrderItemsByStrain = groupBy(orders?.animalOrder?.animalOrderItems, 'animal.strain');

        const strains = Object.keys(animalOrderItemsByStrain);

        const groupedApprovalName = strains.length > 1 ? approvalName : undefined;

        for (const strain of strains) {
          const animalOrderItems = animalOrderItemsByStrain[strain];
          const quantity = sumBy(animalOrderItems, 'quantity');

          const inventoryRequestsForStrain = inventoryRequests.filter((ir) => ir.inventory?.strain === strain);

          const reserved = sumBy(
            inventoryRequestsForStrain.filter((ir) => ir.status === 'reserved'),
            'quantity'
          );

          const fulfilled = sumBy(
            inventoryRequestsForStrain.filter((ir) => ir.status === 'used'),
            'quantity'
          );

          const poReceivedDates =
            uniq(
              inventoryRequestsForStrain.map((inventoryRequest) => formatDate(String(inventoryRequest?.inventory?.dob)))
            ).join(', ') ?? 'N/A';

          rows.push({
            groupedApprovalName,
            id,
            approvalName: approvalName!,
            researcher: researcherFullName,
            strain,
            required: Math.ceil(quantity * 1.34),
            reserved,
            fulfilled,
            poReceivedDates,
          });
        }
      }
    }
    return rows;
  }, [currentData]);

  const rowCount = currentData?.totalCount ?? 0;

  const columns: Array<GridColDef<ExperimentsRowModel>> = useMemo(() => {
    return [
      {
        field: 'groupedApprovalName',
        flex: 2,
        headerName: '',
        sortable: false,
        filterable: false,
      },
      {
        field: 'approvalName',
        flex: 3,
        headerName: 'Experiment',
        sortable: false,
        filterable: false,
      },
      {
        field: 'researcher',
        flex: 2,
        headerName: 'Researcher',
        sortable: false,
        filterable: false,
      },
      {
        field: 'strain',
        flex: 3,
        headerName: 'Strain',
        sortable: false,
        filterable: false,
      },
      {
        field: 'required',
        flex: 1,
        headerName: 'Required',
        type: 'number',
        sortable: false,
        filterable: false,
      },
      {
        field: 'reserved',
        flex: 1,
        headerName: 'Reserved',
        type: 'number',
        sortable: false,
        filterable: false,
      },
      {
        field: 'fulfilled',
        flex: 1,
        headerName: 'Fulfilled',
        type: 'number',
        sortable: false,
        filterable: false,
      },
      {
        field: 'poReceivedDates',
        flex: 2,
        headerName: 'PO Received Dates',
        sortable: false,
        filterable: false,
      },
    ];
  }, []);

  return (
    <OmniGrid
      columns={columns}
      defaultSort={[{ field: 'approvalName', sort: 'asc' }]}
      error={error}
      loading={loading}
      refetch={getExperiments}
      rows={rows}
      rowCount={rowCount}
      showQuickFilter={true}
      dataGridProps={{
        disableColumnFilter: true,
        disableColumnMenu: true,
        disableRowGrouping: false,
        groupingColDef: {
          flex: 2,
          hideDescendantCount: true,
          leafField: 'approvalName',
        },
        getRowId: (row) => `${row.id}-${row.strain}`,
        initialState: {
          columns: {
            columnVisibilityModel: {
              approvalName: false,
            },
          },
          rowGrouping: { model: ['groupedApprovalName'] },
        },
        sortingMode: 'client',
      }}
      headerProps={{
        title: 'Experiments',
      }}
    />
  );
}
