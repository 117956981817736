import { isUndefined } from 'lodash';

import {
  useTissueBioanalysisQuery,
  useExperimentByIdQuery,
  useListContentsQuery,
  usePkTissueWorksheetWithCollectionsQuery,
} from '#graphql';

export function usePKTissueWorksheetPage(experimentId: number) {
  const experimentQuery = useExperimentByIdQuery({ variables: { id: experimentId }, fetchPolicy: 'cache-and-network' });
  const pkTissueWorksheetQuery = usePkTissueWorksheetWithCollectionsQuery({
    variables: { experimentId },
    fetchPolicy: 'cache-and-network',
    pollInterval: 5000,
  });
  const tissueBioanalysisQuery = useTissueBioanalysisQuery({
    variables: { experimentId },
    fetchPolicy: 'cache-and-network',
  });

  const bioanalysisTimepointsQuery = useListContentsQuery({
    fetchPolicy: 'cache-and-network',
  });

  const error =
    experimentQuery.error ??
    pkTissueWorksheetQuery.error ??
    tissueBioanalysisQuery.error ??
    bioanalysisTimepointsQuery.error;

  const loading =
    experimentQuery.loading ||
    pkTissueWorksheetQuery.loading ||
    tissueBioanalysisQuery.loading ||
    bioanalysisTimepointsQuery.loading;

  // TODO - clean this up
  const data =
    isUndefined(experimentQuery.data) ||
    isUndefined(experimentQuery.data.experiment) ||
    isUndefined(pkTissueWorksheetQuery.data) ||
    isUndefined(pkTissueWorksheetQuery.data.pkTissueWorksheet) ||
    isUndefined(tissueBioanalysisQuery.data) ||
    isUndefined(tissueBioanalysisQuery.data.tissueBioanalysis) ||
    isUndefined(bioanalysisTimepointsQuery.data) ||
    isUndefined(bioanalysisTimepointsQuery.data.listContents.items)
      ? undefined
      : ([
          experimentQuery.data.experiment,
          pkTissueWorksheetQuery.data.pkTissueWorksheet,
          tissueBioanalysisQuery.data.tissueBioanalysis,
          bioanalysisTimepointsQuery.data.listContents.items.filter((lc) =>
            ['bioanalysisTimepoint', 'bioanalysisTumorVolumeTimepoint'].includes(lc.category)
          ),
        ] as const);

  return {
    data,
    error,
    loading,
  };
}
