import styled from '@emotion/styled';
import {
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
} from '@mui/material';
import { AddOutlined } from '@mui/icons-material';
import { saveAs } from 'file-saver';

import { AppColors, i18n } from '#lib/constants';
import { useModal } from '#lib/hooks';
import { AddAttachmentsModal } from './';
import { Attachment, useExperimentAttachmentsLazyQuery } from '#graphql';
import { getFileExtension } from '#lib/utils';
import { ButtonPositive } from '#components/widgets';
import toast from 'react-hot-toast';
import { getAttachment, deleteAttachment } from '#lib/api';
import { useEffect, useState } from 'react';

const StyledContainer = styled.div({
  overflow: 'auto',
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  maxHeight: '20em',
  gap: '1rem',

  '&::-webkit-scrollbar': {
    width: '0.9px',
    borderRadius: '8px',
  },
});

const StyledTableCell = styled(TableCell)({
  fontSize: '10px',
  fontWeight: '400',
  padding: '1em',
  color: AppColors.DARK_GREY,
  borderStyle: 'hidden',
});

const StyledTableRow = styled(TableRow)({
  '&:nth-of-type(odd)': {
    backgroundColor: AppColors.VERY_LIGHT_GREY,
  },
});

const StyledLink = styled.a({
  fontSize: '10px',
  color: AppColors.DARK_GREY,
  fontWeight: '500',
  lineHeight: '12px',
  textDecorationLine: 'underline',
  cursor: 'pointer',
});

const StyledButtonContainer = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
});

const StyledButton = styled(ButtonPositive)({
  height: '29px',
  borderRadius: '38px',
  boxSizing: 'border-box',
  color: AppColors.BRAND_GREEN,
  textTransform: 'uppercase',
  fontSize: '10px',
  lineHeight: '16px',
  fontWeight: 'bold',
  display: 'flex',
  justifyContent: 'space-between',
});
interface AttachmentsProps {
  experimentId: number;
  category: string;
  canEdit: boolean;
  attachments?: Attachment[];
  render?: (openModal: () => void) => JSX.Element;
}

type Actions = [
  actionName: string,
  action: (filename: string, category: string, contentType: string) => void
];

export const Attachments = ({
  attachments,
  experimentId,
  category,
  canEdit,
  render,
}: AttachmentsProps) => {
  const {
    openModal,
    Modal: AttachmentsModal,
    closeModal,
  } = useModal('Add Attachments', 'md');
  const [files, setFiles] = useState<Attachment[]>();
  useEffect(() => {
    if (attachments) {
      setFiles(attachments);
    } else {
      getAttachments();
    }
  }, [attachments]);
  const [getAttachments, { loading }] = useExperimentAttachmentsLazyQuery({
    variables: { experimentId, category },
    onCompleted: (data) => {
      setFiles(data.experimentAttachments);
    },
  });
  const onDownload = async (filename: string, category: string) => {
    const file = await toast.promise(
      getAttachment(experimentId, filename, category),
      {
        loading: i18n.ExperimentView.DownloadingFileMessage,
        success: i18n.ExperimentView.DownloadedFileMessage,
        error: i18n.Common.GenericError,
      }
    );
    if (file) {
      saveAs(file, filename);
    }
  };

  const onDelete = async (filename: string, category: string) => {
    const toastId = toast.loading(i18n.ExperimentView.DeletingFileMessage);
    try {
      const response = await deleteAttachment(experimentId, filename, category);
      toast.success(i18n.ExperimentView.DeletedFileMessage, { id: toastId });
    } catch (err) {
      toast.error(i18n.Common.GenericError, { id: toastId });
    }
    setFiles(files?.filter((f) => f.filename !== filename));
  };

  const columns = ['Title', 'File Type', 'Actions'];
  const actions: Actions[] = [['Download', onDownload]];

  if (canEdit) actions.push(['Delete', onDelete]);
  if (loading) {
    return <StyledContainer>...</StyledContainer>;
  }

  return (
    <>
      <StyledContainer>
        <AttachmentsModal>
          <AddAttachmentsModal
            closeModal={closeModal}
            experimentId={experimentId}
            addFiles={(newFiles: Attachment[]) => {
              setFiles([...(files || []), ...newFiles]);
            }}
            category={category}
          />
        </AttachmentsModal>
        {!!files?.length && (
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((name, index) => (
                  <StyledTableCell key={index}>{name}</StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {files.map(({ filename, category, contentType }, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell>{filename}</StyledTableCell>
                  <StyledTableCell>
                    {getFileExtension(filename)}
                  </StyledTableCell>
                  {actions.map(([actionName, action], i) => (
                    <StyledTableCell
                      key={`action-${i}`}
                      onClick={() => action(filename, category, contentType)}
                      style={{ width: '2em' }}
                    >
                      <StyledLink>{actionName}</StyledLink>
                    </StyledTableCell>
                  ))}
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        )}
        <StyledButtonContainer>
          {render?.(openModal) ?? (
            <StyledButton disabled={!canEdit} onClick={openModal}>
              <AddOutlined />
              {i18n.Common.addAttachments}
            </StyledButton>
          )}
        </StyledButtonContainer>
      </StyledContainer>
    </>
  );
};
