import { Navigate, Route, Routes, useParams } from 'react-router';
import styled from '@emotion/styled';

import {
  ExperimentConfiguratorHeader,
  ConfigureOverview,
  Documentation,
  Workspace,
  TestArticles,
} from '#components/partials';
import {
  OVERVIEW_PATH,
  DOCUMENTATION_PATH,
  WORKSPACE_PATH,
  TEST_ARTICLES_PATH,
  i18n,
} from '#lib/constants';
import { useExperimentByIdQuery } from '#graphql';
import { NotFound } from '#components/pages';
import { Loader } from '#components/widgets';

const StyledContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: '1em',
});

const StyledMessage = styled.div({
  fontSize: '25px',
  marginTop: '80px',
  textAlign: 'center',
  fontStyle: 'italic',
  color: '#999',
});

export const ExperimentConfiguratorPage = () => {
  const { experimentId } = useParams();
  const { data, error } = useExperimentByIdQuery({
    variables: { id: +experimentId! },
  });
  const experiment = data?.experiment;

  if (error?.message) {
    const message = error.message as keyof typeof i18n.ExperimentView;
    return (
      <StyledMessage data-test-id="experimentLoadingError">
        {i18n.ExperimentView[message] || message}
      </StyledMessage>
    );
  }
  if (!experiment) return <Loader />;
  return (
    <>
      <ExperimentConfiguratorHeader experiment={experiment} />
      <StyledContainer>
        <Routes>
          <Route
            path={OVERVIEW_PATH}
            element={<ConfigureOverview experiment={experiment} />}
          />
          <Route
            path={TEST_ARTICLES_PATH}
            element={<TestArticles experiment={experiment} />}
          />
          <Route
            path={DOCUMENTATION_PATH}
            element={<Documentation experiment={experiment} />}
          />
          <Route
            path={WORKSPACE_PATH}
            element={<Workspace experiment={experiment} />}
          />
          <Route
            index
            element={<Navigate to={OVERVIEW_PATH} replace={true} />}
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </StyledContainer>
    </>
  );
};
