import { Navigate, Route, Routes } from 'react-router';
import { NotFound } from '#components/pages';
import { CanRoute } from '#components/widgets';
import { TestArticlesPage } from './TestArticlesPage';
import { CroCellLines, CreateCroCellLine, EditCroCellLine } from './CroCellLines';

export function TestArticlesRoutes() {
  return (
    <Routes>
      <Route path="/" element={<TestArticlesPage />}>
        <Route index element={<Navigate to="cro-cell-lines" replace={true} />} />

        <Route
          path="cro-cell-lines"
          element={
            <CanRoute I="list" an="cellLine">
              <CroCellLines />
            </CanRoute>
          }
        />
        <Route
          path="cro-cell-lines/create"
          element={
            <CanRoute I="create" an="cellLine">
              <CreateCroCellLine />
            </CanRoute>
          }
        />
        <Route
          path="cro-cell-lines/:id/edit"
          element={
            <CanRoute I="update" an="cellLine">
              <EditCroCellLine />
            </CanRoute>
          }
        />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}
