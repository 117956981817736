import { Box } from '@mui/material';
import { Outlet } from 'react-router';
import { AbilityProvider, AuthProvider } from '#components/contexts';
import { Navbar } from '#components/partials';

export function Layout() {
  return (
    <AuthProvider>
      <AbilityProvider>
        <Box minWidth="1024px">
          <Navbar />
          <Outlet />
        </Box>
      </AbilityProvider>
    </AuthProvider>
  );
}
