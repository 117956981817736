import { isNil, isNumber } from 'lodash';

import { type AdcOrderItemDetailsFragment, type AdcOrderItemInput } from '#graphql';

export interface AdcOrderItemForUpload {
  a280?: AdcOrderItemDetailsFragment['a280'] | null;
  batchNumber?: AdcOrderItemDetailsFragment['batchNumber'] | null;
  bioregId?: AdcOrderItemDetailsFragment['batchNumber'] | null;
  deliveredAmount?: AdcOrderItemDetailsFragment['deliveredAmount'] | null;
  deliveredAt?: AdcOrderItemDetailsFragment['deliveredAt'] | null;
  deliveredConcentration?: AdcOrderItemDetailsFragment['deliveredConcentration'] | null;
  formulation?: AdcOrderItemDetailsFragment['formulation'] | null;
  id: AdcOrderItemDetailsFragment['id'];
  purpose: AdcOrderItemDetailsFragment['purpose'];
  quantity?: AdcOrderItemDetailsFragment['quantity'];
}

function isFloatDiff(f1: number | null | undefined, f2: number | null | undefined) {
  if (isNumber(f1) && isNumber(f2)) {
    return Math.sign(Math.round(f1 * 100) - Math.round(f2 * 100)) !== 0;
  }

  return f1 !== f2;
}

function isStringDiff(s1: string | null | undefined, s2: string | null | undefined) {
  if (isNil(s1) && isNil(s2)) {
    return false;
  }
  return s1 !== s2;
}

type AdcOrderItemForGet = Pick<AdcOrderItemForUpload, 'bioregId' | 'id' | 'purpose'>;

export function getAdcOrderItemForRow<T extends AdcOrderItemForGet>(
  adcOrderItems: T[],
  adcOrderItemData: AdcOrderItemForGet
) {
  return adcOrderItems.find((adcOrderItem) => {
    return (
      adcOrderItem.bioregId === adcOrderItemData.bioregId &&
      adcOrderItem.purpose === adcOrderItemData.purpose &&
      adcOrderItem.id === adcOrderItemData.id
    );
  });
}

type AdcOrderItemForDiff = Pick<
  AdcOrderItemForUpload,
  'a280' | 'batchNumber' | 'deliveredAmount' | 'deliveredConcentration' | 'formulation'
>;

export function isAdcOrderItemDiff(adcOrderItem: AdcOrderItemForDiff, adcOrderItemData: AdcOrderItemForDiff) {
  return (
    isFloatDiff(adcOrderItem.deliveredAmount, adcOrderItemData.deliveredAmount) ||
    isFloatDiff(adcOrderItem.deliveredConcentration, adcOrderItemData.deliveredConcentration) ||
    isFloatDiff(adcOrderItem.a280, adcOrderItemData.a280) ||
    isStringDiff(adcOrderItem.formulation, adcOrderItemData.formulation) ||
    isStringDiff(adcOrderItem.batchNumber, adcOrderItemData.batchNumber)
  );
}

export function hasAdcOrderItemDiff<T extends AdcOrderItemForDiff & AdcOrderItemForGet>(adcOrderItems: T[]) {
  return function (adcOrderItemData: AdcOrderItemForGet & AdcOrderItemForDiff) {
    const adcOrderItem = getAdcOrderItemForRow(adcOrderItems, adcOrderItemData);
    if (isNil(adcOrderItem)) {
      return false;
    }

    return isAdcOrderItemDiff(adcOrderItem, adcOrderItemData);
  };
}

export function toAdcOrderItemInputs<T extends AdcOrderItemForUpload>(adcOrderItems: T[]) {
  return function (adcOrderItemData: AdcOrderItemForGet & AdcOrderItemForDiff): AdcOrderItemInput[] {
    const inVivoAdcOrderItem = getAdcOrderItemForRow(adcOrderItems, adcOrderItemData);
    if (isNil(inVivoAdcOrderItem)) {
      return [];
    }

    const adcOrderItemsForConjugate = adcOrderItems.filter((adcOrderItem) => {
      return (
        adcOrderItem.bioregId === inVivoAdcOrderItem.bioregId &&
        adcOrderItem.deliveredAt === inVivoAdcOrderItem.deliveredAt
      );
    });

    return adcOrderItemsForConjugate.map((adcOrderItem) => {
      if (isInVivoDose(adcOrderItem)) {
        return {
          id: adcOrderItem.id,
          a280: adcOrderItemData.a280,
          batchNumber: adcOrderItemData.batchNumber,
          deliveredAmount: adcOrderItemData.deliveredAmount,
          deliveredConcentration: adcOrderItemData.deliveredConcentration,
          formulation: adcOrderItemData.formulation,
        };
      } else {
        // This is necessary because the deliver logic expects the batch number and formulation to be present
        // on all adc order items, not just the in vivo dose
        return {
          id: adcOrderItem.id,
          batchNumber: adcOrderItemData.batchNumber,
          formulation: adcOrderItemData.formulation,
          deliveredAmount: adcOrderItem.quantity,
        };
      }
    });
  };
}

export function isInVivoDose({ purpose }: { purpose: string }) {
  return purpose === 'In Vivo Dose';
}
