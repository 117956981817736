import { ApolloError } from '@apollo/client';
import { GraphQLError } from 'graphql';
import { i18n } from '#lib/constants';

type ErrorTuple = [field: string, message: string];

function errorToTuple(graphQLError: GraphQLError): ErrorTuple {
  if (graphQLError.extensions.field) {
    const field = (graphQLError.extensions.field as string).replace(
      /^input\./,
      ''
    );
    const message = graphQLError.message.split('.').pop();
    if (message) {
      return [field, message];
    }
  }

  const code = graphQLError.extensions.code as keyof typeof i18n.Errors;
  const rootMessage = i18n.Errors[code] ?? i18n.Common.GenericError;
  return ['root', rootMessage];
}

export function handleGraphQLError(graphQLError: GraphQLError) {
  if (graphQLError.extensions.code === 'VALIDATION_ERRORS') {
    return graphQLError.extensions.errors as ErrorTuple[];
  }

  return [errorToTuple(graphQLError)];
}

export function handleApolloError(error: ApolloError) {
  const message = error.graphQLErrors
    .map((graphQLError) => {
      const code = graphQLError.extensions.code as keyof typeof i18n.Errors;
      return i18n.Errors[code];
    })
    .join('\n');

  return message ?? i18n.Common.GenericError;
}

export function handleApolloFieldErrors(error: ApolloError) {
  return error.graphQLErrors.map(handleGraphQLError).flat(1);
}
