import { Navigate, Route, Routes } from 'react-router';

import {
  AdcOrdersPage,
  AnimalInventoryRoutes,
  ExperimentConfiguratorPage,
  ExperimentsPage,
  Layout,
  LoginPage,
  NoAccessPage,
  NotFound,
  PKBloodWorksheetPage,
  ProfileRoutes,
  SettingsPage,
  TemplateEditorPage,
  TestArticlesRoutes,
  Theme,
  UsersRoutes,
  WorksheetPage,
} from '#components/pages';
import { PKTissueWorksheetPage } from '#components/pages/PKTissueWorksheet';
import { CanRoute } from '#components/widgets';
import {
  ADC_ORDERS_ROUTE,
  ANIMAL_INVENTORY_ROUTE,
  CONFIGURE_ROUTES,
  EXPERIMENTS_ROUTES,
  HOME_PATH,
  PK_BLOOD_WORKSHEET_ROUTE,
  PK_TISSUE_WORKSHEET_ROUTE,
  PROFILE_ROUTE,
  SETTINGS_ROUTE,
  TEMPLATE_EDITOR_PATH,
  TEST_ARTICLES_ROUTE,
  USER_MANAGEMENT_PATH,
  WORKSHEET_ROUTE,
} from '#lib/constants';

export function Router() {
  return (
    <Routes>
      {/* Authenticated Routes */}
      <Route path="/" element={<Layout />}>
        <Route index element={<Navigate to={HOME_PATH} replace={true} />} />

        <Route path={EXPERIMENTS_ROUTES} element={<ExperimentsPage />} />

        <Route path={CONFIGURE_ROUTES} element={<ExperimentConfiguratorPage />} />

        <Route path={SETTINGS_ROUTE} element={<SettingsPage />} />

        <Route path={ADC_ORDERS_ROUTE} element={<AdcOrdersPage />} />

        <Route path={TEMPLATE_EDITOR_PATH} element={<TemplateEditorPage />} />

        <Route path={PROFILE_ROUTE} element={<ProfileRoutes />} />

        <Route path={`${USER_MANAGEMENT_PATH}/*`} element={<UsersRoutes />} />

        <Route path={WORKSHEET_ROUTE} element={<WorksheetPage />} />

        <Route path={PK_BLOOD_WORKSHEET_ROUTE} element={<PKBloodWorksheetPage />} />

        <Route path={PK_TISSUE_WORKSHEET_ROUTE} element={<PKTissueWorksheetPage />} />

        <Route
          path={`${ANIMAL_INVENTORY_ROUTE}/*`}
          element={
            <CanRoute I="list" an="inventory">
              <AnimalInventoryRoutes />
            </CanRoute>
          }
        />
        <Route path={`${TEST_ARTICLES_ROUTE}/*`} element={<TestArticlesRoutes />} />
        <Route path="*" element={<NotFound />} />
      </Route>

      {/* Unauthenticated Routes */}

      {process.env.NODE_ENV === 'development' && <Route path="/theme" element={<Theme />} />}

      <Route path="/login" element={<LoginPage />} />
      <Route path="/no-access" element={<NoAccessPage />} />
      <Route path="*" element={<Navigate to="/login" replace={true} />} />
    </Routes>
  );
}
