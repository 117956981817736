import styled from '@emotion/styled';
import { AppColors } from '#lib/constants';

export const StyledContainer = styled.div({
  background: AppColors.LIGHT_LIGHT_GREY,
  borderRadius: '37px',
  display: 'flex',
  height: '38px',
  '.MuiAutocomplete-root': {
    width: '100%',
  },
  '.MuiAutocomplete-root .MuiOutlinedInput-root': {
    padding: '0 !important',
  },
  '.MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
});

export const StyledInitials = styled.div({
  background: AppColors.LIGHT_RED,
  minWidth: '38px',
  height: '38px',
  borderRadius: '37px',
  fontSize: '12px',
  fontWeight: 'bold',
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  lineHeight: '24px',
  letterSpacing: '0.1px',
  color: AppColors.BLACK,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});
