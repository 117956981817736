import styled from '@emotion/styled';
import { Can } from '#components/contexts';
import { SectionView } from './SectionView';
import { SectionHeader } from './SectionHeader';
import { Attachments } from '#components/partials';
import { useWorksheetState } from './WorksheetBodyState';
import { WorksheetBodyProps } from './WorksheetComponentInterfaces';

const AttachmentsContainer = styled.div({
  label: 'AttachmentsContainer',
  padding: '15px 0',
  maxWidth: '1000px',
});

const WorksheetBodyContainer2 = styled.div({
  label: 'WorksheetBodyContainer2',
  marginLeft: '20px',
  marginRight: '20px',
});

const WorksheetBodyContainer = styled.div`
  label: WorksheetBodyContainer2;
  width: 100%;
  @media (min-width: 1040px) {
    width: 100%;
  }
  padding-bottom: 20px;
`;

export const WorksheetBody = (props: WorksheetBodyProps) => {
  const { experiment } = props;
  const {
    worksheetMutations,
    entriesBySectionId,
    rowIdsBySectionId,
    columnIdsBySectionId,
    worksheet,
    getWorksheetState,
  } = useWorksheetState(props);

  return (
    <WorksheetBodyContainer>
      <WorksheetBodyContainer2>
        {worksheet.definition.sections
          .filter((section) => section.sectionType !== 'hidden')
          .map((section) => (
            <SectionView
              key={section.id}
              {...{
                section,
                worksheetMutations,
              }}
              worksheetDefinition={worksheet.definition}
              entriesBySectionId={entriesBySectionId}
              entries={entriesBySectionId[section.id] || []}
              rowIds={rowIdsBySectionId[section.id] || []}
              columnIds={columnIdsBySectionId[section.id] || []}
              getWorksheetState={getWorksheetState}
            />
          ))}
        {worksheet.experimentId && (
          <Can
            I={'update'}
            this={experiment as never}
            field={'attachments'}
            passThrough
          >
            {(canEditAttachment) => (
              <AttachmentsContainer>
                <SectionHeader sectionName="Attachments" />
                <Attachments
                  {...{
                    experimentId: worksheet.experimentId!,
                    canEdit: canEditAttachment,
                    category: `worksheetId-${worksheet.id}`,
                  }}
                />
              </AttachmentsContainer>
            )}
          </Can>
        )}
      </WorksheetBodyContainer2>
    </WorksheetBodyContainer>
  );
};
