import { Container } from '@mui/material';
import { DateTime } from 'luxon';
import { useParams } from 'react-router';
import { useInventoryQuery, useUpdateInventoryMutation } from '#graphql';
import { Loader } from '#components/widgets';
import { PurchaseOrderForm, PurchaseOrderFormData } from './PurchaseOrderForm';

export function EditPurchaseOrder() {
  const [updateInventory] = useUpdateInventoryMutation();
  const { id } = useParams();

  const { data, error, loading } = useInventoryQuery({
    variables: {
      id: Number(id),
    },
  });

  if (loading) {
    return <Loader />;
  }

  if (error) {
    console.error({ error });
    return null;
  }

  const inventory = data?.inventory!;

  async function handleSubmit(data: PurchaseOrderFormData) {
    await updateInventory({
      variables: {
        input: {
          id: inventory.id,
          ...data,
          quantity: parseInt(data.quantity),
          dob: DateTime.fromISO(data.dob).toUTC().toISO(),
        },
      },
    });
  }

  return (
    <Container maxWidth="sm">
      <PurchaseOrderForm inventory={inventory} handleSubmit={handleSubmit} />
    </Container>
  );
}
