import { type PropsWithChildren } from 'react';

import { DetailPanelContext, type DetailPanelContextValue } from './detailPanelContext';

type DetailPanelProviderProps = PropsWithChildren<{
  context: DetailPanelContextValue;
}>;

export function DetailPanelProvider({ children, context }: DetailPanelProviderProps) {
  return <DetailPanelContext.Provider value={context}>{children}</DetailPanelContext.Provider>;
}
