import {
  getNormalizeWorksheetDefinition,
  worksheetDataForExport,
  type WorksheetFieldDefinition,
  type WorksheetDefinition,
} from '@omnivivo/worksheets-core';
import { isNil, isString, sortBy } from 'lodash';
import { DateTime } from 'luxon';

import { type ListContent, type ExperimentDetailsFragment, type Worksheet, type WorksheetEntry } from '#graphql';
import { getExperimentWorksheetEntries } from '#lib/ExperimentWorksheetsEntryInjectionLib';
import { csvEncodeLine } from '../utils/common';

export const blankLine = '\n\n';

export const translateBooleanToText = (value?: boolean) => (value === true ? 'Yes' : 'No');

export const formatDateTime = (dateTime?: string) =>
  !isNil(dateTime) ? DateTime.fromISO(dateTime).toFormat('DDD') : 'Pending';

export function formatTime(dateTime?: string) {
  return isNil(dateTime) || dateTime === '' ? '-' : DateTime.fromISO(dateTime).toLocaleString(DateTime.TIME_24_SIMPLE);
}

export function formatDateTimeWith12hrs(dateTime?: string) {
  return isNil(dateTime) || dateTime === '' ? '-' : DateTime.fromISO(dateTime).toLocaleString(DateTime.DATETIME_SHORT);
}

export const formatBoolean = (value?: string) => (!isNil(value) && value === 'true' ? 'Yes' : 'No');

export function getWorksheetHeadersAndColumns(sectionName: string, definition: WorksheetDefinition) {
  const section = definition.sections.find((section) => section.id === sectionName);
  const fields: WorksheetFieldDefinition[] = (section?.fields ?? []).filter((field) => field.fieldType !== 'spacer');

  return {
    headers: fields.map((field) => field.fieldName ?? ''),
    columns: fields.map((field) => field.id),
  };
}

export const getWorksheetSectionListSingleRow = (
  definition: WorksheetDefinition,
  sectionName: string,
  headers: string[],
  data: string[][],
  keys: string[],
  selectedColumns: string[]
) => {
  const csvHeaders = new Array<string>();
  const csvInfo = new Array<string>();
  const sectionData = getSections(sectionName, keys, data)[0];

  selectedColumns.forEach((column) => {
    const index = keys.indexOf(column);
    // if we cannot find the column in the hydrated data, grab the fieldName from the definition as the header
    if (index === -1) {
      const def = definition.sections.find((section) => section.id === sectionName);
      csvHeaders.push(def?.fields?.find((field) => field.id === column)?.fieldName ?? '');
    } else {
      csvHeaders.push(headers[index]);
    }
    csvInfo.push(sectionData[index] ?? '-');
  });

  return {
    csvHeaders,
    csvInfo,
  };
};

export const getWorksheetSectionListMultipleRows = (
  definition: WorksheetDefinition,
  sectionName: string,
  headers: string[],
  data: string[][],
  keys: string[],
  selectedColumns: Array<string | [column: string, formatter: (value: string) => string]>,
  sortKey?: string
) => {
  const csvHeaders = new Array<string>();
  let sectionsData = getSections(sectionName, keys, data);
  const csvInfo = new Array<string[]>();

  selectedColumns.forEach((selectedColumn) => {
    const column = isString(selectedColumn) ? selectedColumn : selectedColumn[0];
    const index = keys.indexOf(column);
    // if we cannot find the column in the hydrated data, grab the fieldName from the definition as the header
    if (index === -1) {
      const def = definition.sections.find((section) => section.id === sectionName);
      csvHeaders.push(def?.fields?.find((field) => field.id === column)?.fieldName ?? '');
    } else {
      csvHeaders.push(headers[index]?.toString() ?? '');
    }
  });

  if (!isNil(sortKey)) {
    const keyIndex = keys.indexOf(sortKey);
    if (keyIndex !== -1) {
      sectionsData = sortBy(sectionsData, (row) => row[keyIndex]);
    }
  }

  sectionsData.forEach((sectionData) => {
    const row = selectedColumns.map((selectedColumn) => {
      const column = isString(selectedColumn) ? selectedColumn : selectedColumn[0];

      // pad ratios with a preceding space so Excel doesn't think they are a time value
      const defaultFormatter = (value: string) => (/^\d+(\.\d+)?:\d+(\.\d+)?$/.test(value) ? ' ' + value : value);

      const formatter = isString(selectedColumn) ? defaultFormatter : selectedColumn[1];

      const index = keys.indexOf(column);

      const value = sectionData[index]?.toString().trim() ?? '';

      return formatter(value);
    });
    csvInfo.push(row);
  });

  return {
    csvHeaders,
    csvInfo,
  };
};

export const getWorksheetCSV = (
  experiment: ExperimentDetailsFragment,
  worksheet: Worksheet,
  worksheetEntries: WorksheetEntry[],
  listContent: ListContent[]
) => {
  const hydratedWorksheet = {
    ...worksheet,
    definition: getNormalizeWorksheetDefinition(
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      JSON.parse(worksheet.definition)
    ),
  };
  const hydratedWorksheetEntries = worksheetEntries.map((entry) => ({
    ...entry,
    id: entry.id ?? 0,
    worksheetId: entry.worksheetId ?? 0,
    sectionId: entry.sectionId ?? '',
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    data: !isNil(entry.data) ? JSON.parse(entry.data) : {},
  }));

  const entries = [
    ...hydratedWorksheetEntries,
    ...getExperimentWorksheetEntries(experiment, hydratedWorksheet, hydratedWorksheetEntries, listContent),
  ];
  return {
    name: worksheet.name ?? '',
    data: worksheetDataForExport({
      worksheet: hydratedWorksheet,
      entries,
    }),
  };
};

export const getSections = (sectionName: string, entryKeys: string[], data: string[][]) => {
  const foundItemIndex = entryKeys.findIndex((key) => key === 'sectionId');
  return data.filter((section: string[]) => section[foundItemIndex] === sectionName);
};

export const parseArrayToCSV = (headers: string[], values: string[][]) =>
  csvEncodeLine(headers) + '\n' + values.map(csvEncodeLine).join('\n');

export const getCSVSimpleFormat = (headers: string[], values: string[]) =>
  csvEncodeLine(headers) + '\n' + csvEncodeLine(values);

export const getCSVVerticalFormat = (headers: string[], values: string[]) => {
  let result = '';
  for (let i = 0; i < headers.length; i++) {
    result += headers[i].concat('\n', values[i] + blankLine);
  }
  return result;
};

export const translateFalseForDisplay = (values: string[]) => {
  return values.map((value) => (value === 'false' ? '-' : value));
};

export const getSectionDetailsCSV = (sectionHeader: string, headers: string[], data: string[][]) => {
  return sectionHeader.concat('\n', parseArrayToCSV(headers, data));
};

export function groupIdForSection(section: string) {
  return section.split('-')[1];
}
