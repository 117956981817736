"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createEntriesBySectionsId = exports.createEntries = exports.compareEntries = exports.getEntriesByNextEntryId = exports.sortEntries = exports.ensureInitialEmptyEntriesAreIncluded = exports.getInitialEmptyEntriesToAdd = exports.getEntriesByRowIdColumnId = exports.getWorksheetEntriesBySectionId = exports.getEntriesToCreateToAddColumn = exports.getEntriesToDeleteToDeleteColumn = exports.getEntriesToDeleteToDeleteRow = exports.getEntriesToCreateToAddRow = exports.getEntriesByUniqueKeys = exports.getUniqueEntryKey = void 0;
const lodash_1 = require("lodash");
const worksheetInternalTools_1 = require("./worksheetInternalTools");
const worksheetTools_1 = require("./worksheetTools");
const getUniqueEntryKey = (entry //
) => `${entry.sectionId || ''},${entry.rowId || ''},${entry.columnId || ''}`;
exports.getUniqueEntryKey = getUniqueEntryKey;
const getEntriesByUniqueKeys = (entries) => {
    const result = {};
    entries.forEach((entry) => (result[(0, exports.getUniqueEntryKey)(entry)] = entry));
    return result;
};
exports.getEntriesByUniqueKeys = getEntriesByUniqueKeys;
const getEntriesToCreateToAddRow = (worksheetId, columnIds, entries, newEntry) => {
    const rowId = (0, worksheetTools_1.getNextId)(entries.map(({ rowId }) => rowId));
    const newEntries = (columnIds.length > 0 ? columnIds : [worksheetTools_1.WORKSHEET_ENTRY_STARTING_ID]).map((columnId) => ({
        ...newEntry,
        worksheetId,
        rowId,
        columnId,
    }));
    return newEntries;
};
exports.getEntriesToCreateToAddRow = getEntriesToCreateToAddRow;
const getEntriesToDeleteToDeleteRow = (sectionId, rowId, entries) => entries.filter(({ sectionId: s, rowId: r }) => sectionId === s && rowId === r);
exports.getEntriesToDeleteToDeleteRow = getEntriesToDeleteToDeleteRow;
const getEntriesToDeleteToDeleteColumn = (sectionId, columnId, entries) => entries.filter(({ sectionId: s, columnId: c }) => sectionId === s && columnId === c);
exports.getEntriesToDeleteToDeleteColumn = getEntriesToDeleteToDeleteColumn;
const getEntriesToCreateToAddColumn = (worksheetId, definition, rowIds, entries, newEntry, sectionId) => {
    sectionId = sectionId || newEntry.sectionId;
    const section = definition.sectionsBySectionId[sectionId];
    if (!newEntry.sectionId || !section)
        throw new Error('valid sectionId required');
    const columnId = (0, worksheetTools_1.getNextId)(entries.map(({ columnId }) => columnId));
    const newEntries = (rowIds.length > 0 ? rowIds : [worksheetTools_1.WORKSHEET_ENTRY_STARTING_ID]).map((rowId) => ({
        ...newEntry,
        worksheetId,
        columnId,
        sectionId,
        rowId,
    }));
    if (section.columnSectionId)
        newEntries.push({
            worksheetId,
            sectionId: section.columnSectionId,
            columnId,
        });
    return newEntries;
};
exports.getEntriesToCreateToAddColumn = getEntriesToCreateToAddColumn;
const getWorksheetEntriesBySectionId = (definition, entries) => {
    const worksheetEntriesBySectionId = {};
    definition.sections.forEach((section) => (worksheetEntriesBySectionId[section.id] = []));
    entries.forEach((entry) => worksheetEntriesBySectionId[entry.sectionId]?.push(entry));
    return worksheetEntriesBySectionId;
};
exports.getWorksheetEntriesBySectionId = getWorksheetEntriesBySectionId;
const getEntriesByRowIdColumnId = (entries) => {
    const entriesByRowIdColumnId = {};
    entries.forEach((entry) => {
        if (entry.rowId && entry.columnId) {
            (entriesByRowIdColumnId[entry.rowId] ||= {})[entry.columnId] = entry;
        }
    });
    return entriesByRowIdColumnId;
};
exports.getEntriesByRowIdColumnId = getEntriesByRowIdColumnId;
const getInitialEmptyEntriesToAdd = (definition, existingEntries = []) => {
    const rowId = (0, worksheetTools_1.getNextId)([]), columnId = (0, worksheetTools_1.getNextId)([]), sectionsWithEntries = {};
    existingEntries.forEach(({ sectionId }) => sectionId != null && (sectionsWithEntries[sectionId] = true));
    return definition.sections
        .filter((section) => section.sectionType !== 'hidden' && section.sectionType !== 'chart' && !sectionsWithEntries[section.id])
        .map((section) => ({
        sectionId: section.id,
        rowId,
        columnId,
    }));
};
exports.getInitialEmptyEntriesToAdd = getInitialEmptyEntriesToAdd;
// Every non-hidden, non-chart section should have at least one empty entry
const ensureInitialEmptyEntriesAreIncluded = (definition, existingEntries = []) => (0, exports.getInitialEmptyEntriesToAdd)(definition, existingEntries).concat(existingEntries);
exports.ensureInitialEmptyEntriesAreIncluded = ensureInitialEmptyEntriesAreIncluded;
const compareTwoEntries = (sortField, entry1, entry2) => (0, worksheetInternalTools_1.compare)(sortField ? entry1.data[sortField] : 0, sortField ? entry2.data[sortField] : 0) || // FIRST sort by the sortField
    (0, worksheetInternalTools_1.compare)(entry1.rowId, entry2.rowId) || // THEN sort on rowId
    (0, worksheetInternalTools_1.compare)(entry1.columnId, entry2.columnId) || // THEN sort on columnId
    (0, worksheetInternalTools_1.compare)(entry1.id, entry2.id); // FINALLY sort on entry.id to ensure deterministic sorts
const sortEntries = (section, entries) => entries.sort((a, b) => compareTwoEntries(section.sortBy, a, b));
exports.sortEntries = sortEntries;
const getEntriesByNextEntryId = (entries, section) => {
    let lastEntry;
    const result = {};
    (0, exports.sortEntries)(section, [...entries]).forEach((entry) => {
        if (lastEntry) {
            if (section.sectionType !== 'dataCollection' || entry.rowId === lastEntry.rowId)
                result[entry.id] = lastEntry;
        }
        lastEntry = entry;
    });
    return result;
};
exports.getEntriesByNextEntryId = getEntriesByNextEntryId;
const compareEntries = (entriesBefore, entriesAfter) => {
    const added = [];
    const changed = [];
    const deleted = [];
    const entriesBeforeByUniqueKey = (0, exports.getEntriesByUniqueKeys)(entriesBefore);
    const entriesAfterByUniqueKey = (0, exports.getEntriesByUniqueKeys)(entriesAfter);
    for (const key in entriesBeforeByUniqueKey) {
        const entryBefore = entriesBeforeByUniqueKey[key];
        const entryAfter = entriesAfterByUniqueKey[key];
        if (entryAfter) {
            if (!(0, lodash_1.isEqual)(entryBefore.data ?? {}, entryAfter.data ?? {})) {
                changed.push({
                    ...entryBefore,
                    data: {
                        ...entryBefore.data,
                        ...entryAfter.data,
                    },
                });
            }
        }
        else if ((0, lodash_1.isEmpty)(entryBefore.data)) {
            deleted.push({
                ...entryBefore,
            });
        }
    }
    for (const key in entriesAfterByUniqueKey) {
        if (!entriesBeforeByUniqueKey[key]) {
            added.push(entriesAfterByUniqueKey[key]);
        }
    }
    return { added, changed, deleted };
};
exports.compareEntries = compareEntries;
const createEntries = (entries) => entries.map((entry, id) => ({
    ...entry,
    id,
    worksheetId: 1,
    sectionId: entry.sectionId ?? 'unknown',
    data: entry.data || {},
}));
exports.createEntries = createEntries;
const createEntriesBySectionsId = (newEntriesBySectionId) => (0, exports.createEntries)((0, lodash_1.flatten)(Object.keys(newEntriesBySectionId).map((sectionId) => newEntriesBySectionId[sectionId].map((entry) => ({
    ...entry,
    sectionId,
})))));
exports.createEntriesBySectionsId = createEntriesBySectionsId;
