import React from 'react';
import styled from '@emotion/styled';
import { AddOutlined } from '@mui/icons-material';
import { AppColors } from '#lib/constants';
import { ButtonPositive } from '#components/widgets/Buttons';

export const RoundedButton = styled(ButtonPositive)({
  height: '29px',
  borderRadius: '38px',
  boxSizing: 'border-box',
  color: AppColors.BRAND_GREEN,
  textTransform: 'uppercase',
  fontSize: '10px',
  lineHeight: '16px',
  fontWeight: 'bold',
  display: 'flex',
  justifyContent: 'space-between',
});

const ButtonTextDiv = styled.div({
  paddingTop: '1px',
});

export const RoundedButtonWithAddIcon = ({
  onClick,
  disabled,
  text,
}: {
  onClick: () => any;
  disabled?: boolean;
  text: string;
}) => (
  <RoundedButton {...{ disabled, onClick }}>
    <AddOutlined />
    <ButtonTextDiv>{`${text}`}</ButtonTextDiv>
  </RoundedButton>
);
