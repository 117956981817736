import Adc from '#assets/adc-no-bg.svg';
import Mouse from '#assets/icon_mouse.svg';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';

import SeagenLogo from '#assets/seagen-logo.svg';
import HomeIcon from '#assets/home-icon.svg';
import SettingsIcon from '#assets/settings-icon.svg';
import {
  SETTINGS_PATH,
  HOME_PATH,
  ADC_ORDERS_PATH,
  LOGOUT_PATH,
  UNFULFILLED_ADC_ORDERS_PATH,
  ANIMAL_INVENTORY_ROUTE,
} from '#lib/constants';
import { getUserInitials } from '#lib/utils';
import { AppColors, i18n } from '#lib/constants';
import { Can, useAuthState } from '#components/contexts';

const StyledContainer = styled.div({
  display: 'flex',
  height: '47px',
  justifyContent: 'space-between',
  padding: '0 20px',
  boxShadow: '0px 5px 11px 0px rgba(0,0,0,0.1)',
  backgroundColor: AppColors.WHITE,
  alignItems: 'center',

  position: 'relative',
  zIndex: 1,
});

const StyledSection = styled.div(
  ({
    customGap,
    customJustify,
  }: {
    customGap: string;
    customJustify?: string;
  }) => ({
    display: 'flex',
    gap: customGap,
    alignItems: 'center',
    justifyContent: customJustify || '',
  })
);

const StyledLogo = styled.img({
  width: '77px',
  height: '19px',
});

const StyledText = styled.span(
  ({
    customSize,
    customColor,
    isTitleText,
  }: {
    customSize?: string;
    isTitleText?: boolean;
    customColor?: string;
  }) => ({
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: isTitleText ? 'bold' : 'normal',
    fontSize: customSize || '14px',
    lineHeight: '20px',
    marginTop: isTitleText ? '' : '3px',
    color: customColor || AppColors.DARK_GREY,
    textTransform: isTitleText ? 'uppercase' : 'none',
    cursor: isTitleText ? 'pointer' : 'none',
    ...(isTitleText && {
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      padding: '2px',
    }),
  })
);

const StyledIcon = styled.img({
  width: '15px',
  height: '15px',
  cursor: 'pointer',
});

const StyledAvatarSection = styled.span({
  background: AppColors.LIGHT_TURQUOISE,
  borderRadius: '30px',
  width: '28.62px',
  height: '28.62px',
  textAlign: 'center',
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  position: 'relative',
  '&:hover [data-id="avatar-section"]': {
    display: 'flex',
  },
});

const StyledAvatarContent = styled.div({
  display: 'none',
  backgroundColor: AppColors.WHITE,
  position: 'absolute',
  minWidth: '50px',
  flexDirection: 'column',
  right: '-2px',
  boxShadow: '0px 8px 16px 0px rgba(0, 0, 0, 0.3)',
  borderRadius: '4px',
  zIndex: '99',
  top: 'calc(100% - 3px)',
  padding: '10px 10px',
});

const StyledMenuLabel = styled.label({
  color: 'black',
  padding: '12px 3px',
  textDecoration: 'none',
  display: 'block',
  fontSize: '12px',
  textAlign: 'center',
  borderBottom: `0.2px solid ${AppColors.GREY1}`,
  cursor: 'pointer',
});

const LogoutLink = styled.a({
  textDecoration: 'none',
  color: AppColors.BRAND_GREEN,
});

export const Navbar = () => {
  const navigate = useNavigate();
  const user = useAuthState();

  return (
    <StyledContainer data-test-id={`mainNavbar`}>
      <StyledSection customGap="15px">
        <StyledLogo src={SeagenLogo} />
        <StyledText>|</StyledText>
        <StyledText>{i18n.AppName}</StyledText>
      </StyledSection>

      {user && (
        <StyledSection customGap="22px" data-test-id={`mainNavbarMenu`}>
          <Can I="list" an="inventory">
            <StyledIcon
              role={`link`}
              src={Mouse}
              title={`Animal Inventory`}
              onClick={() => navigate(`/${ANIMAL_INVENTORY_ROUTE}`)}
            />
          </Can>
          <Can I="update" an="adcOrder">
            <StyledIcon
              role={`link`}
              src={Adc}
              title={`ADC Orders`}
              onClick={() =>
                navigate(`${ADC_ORDERS_PATH}/${UNFULFILLED_ADC_ORDERS_PATH}`)
              }
            />
          </Can>
          <StyledIcon
            role={`link`}
            src={HomeIcon}
            title={`Home`}
            onClick={() => navigate(HOME_PATH)}
          />
          <Can I="update" a="listContent">
            <StyledIcon
              role={`link`}
              src={SettingsIcon}
              title={`Settings`}
              data-test-id={`navbarSettingsMenu`}
              onClick={() => navigate(SETTINGS_PATH)}
            />
          </Can>
          <StyledAvatarSection>
            <StyledText
              customColor={AppColors.BLACK}
              customSize="12px"
              isTitleText
            >
              {getUserInitials(user)}
            </StyledText>
            <StyledAvatarContent data-id="avatar-section">
              <StyledMenuLabel onClick={() => navigate('/profile')}>
                {user.email}
              </StyledMenuLabel>
              <StyledMenuLabel>
                <LogoutLink title={`Log out`} href={LOGOUT_PATH}>
                  Log out
                </LogoutLink>
              </StyledMenuLabel>
            </StyledAvatarContent>
          </StyledAvatarSection>
        </StyledSection>
      )}
    </StyledContainer>
  );
};
