import styled from '@emotion/styled';
import CloseIcon from '@mui/icons-material/Close';
import { ChangeEvent } from 'react';
import { Tooltip, TextField } from '@mui/material';
import { AppColors } from '#lib/constants';
import { SelectedTreatments } from '#lib/types';

const StyledContainer = styled.div({
  width: 'calc(100% - 15px)',
  marginTop: '1em',
});

const StyledBox = styled.div({
  display: 'flex',
  height: '70px',
  border: `1px solid ${AppColors.LIGHT_MID_GREY}`,
  flexWrap: 'wrap',
  padding: '10px',
  overflow: 'auto',
  gap: '1em',
});

const StyledItem = styled.div({
  flex: '0 0 14%',

  height: '28px',
  background: AppColors.LIGHT_LIGHT_GREY,
  padding: '0px 5px',
  borderRadius: '4px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',

  '&::-webkit-scrollbar': {
    width: '0.9px',
    borderRadius: '8px',
  },

  '&::-webkit-scrollbar-track': {
    boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)',
  },

  '&::-webkit-scrollbar-thumb': {
    backgroundColor: AppColors.GREY2,
    outline: '1px solid slategrey',
  },
});

const StyledText = styled.span({
  color: AppColors.DARK_GREY,
  fontSize: '10px',
  textOverflow: 'hidden',
  height: '28px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  borderRight: '1px solid #CDCDCD',
  paddingRight: '10px',
  display: 'flex',
  alignItems: 'center',
});

const StyledCloseIcon = styled(CloseIcon)({
  fontSize: '10px',
  marginLeft: '7px',
  cursor: 'pointer',
});

const StyledTextField = styled(TextField)({
  div: {
    borderRadius: '4px',
    width: '65px',
    height: '18px',
    fontSize: '10px',
    marginLeft: '10px',
  },
});

interface SelectionBoxProps {
  selectedItems: SelectedTreatments[];
  onChangeSelectedItem: (
    id: string,
    isNew: boolean,
    testArticleId?: number,
    tab?: string,
    name?: string
  ) => void;
}

export const SelectionBox = ({
  selectedItems,
  onChangeSelectedItem,
}: SelectionBoxProps) => {
  return (
    <StyledContainer>
      {selectedItems?.map((item, i) => (
        <Tooltip title={item.name || ''} key={i}>
          <StyledItem>
            <StyledText>{`${item.tab}: ${item.name}`}</StyledText>
            <StyledCloseIcon
              onClick={() => onChangeSelectedItem(item.id, false)}
            />
          </StyledItem>
        </Tooltip>
      ))}
    </StyledContainer>
  );
};
