import { WorksheetSectionDefinition, WorksheetFieldDefinition } from '@omnivivo/worksheets-core';
import { ChartColors, SCIENTIFIC_NOTATION_FORMAT, WORKSHEET_DEFAULT_FORMAT } from '@omnivivo/style';
import { EXPERIMENT_MODELS_ID, experimentInjectedSectionDefinitions } from './ExperimentInjectedSections';
import { TestArticleForWorksheet, TreatmentGroupForWorksheet } from './WorksheetInitializationTypes';
import { compact } from 'lodash';

const numeral = require('numeral');

const TREATMENT_GROUP_SECTION_TYPE = 'treatmentGroup';
const TREATMENT_GROUP_COLUMNS_SECTION_TYPE = 'treatmentGroupColumns';
export const DAY_ID = 'day';
const IMPLANTATION_DATE_ID = 'implantationDate';
const INPUTS_ID = 'inputs';
export const AVERAGE_WEIGHT_CHANGE_PERCENT_ID = 'averageWeightChangePercentage';
export const MEAN_ID = 'mean';
export const MEDIAN_ID = 'median';
export const WEIGHT_CHANGE_PERCENT_ID = 'weightChangePercentage';
export const DOSE_AMOUNT = 'doseAmount';
export const DOSE_TIME = 'doseTime';
export const DOSE_REQUIRED = 'doseRequired';

const getSectionId = (sectionType: string, treatmentGroupForWorksheet: TreatmentGroupForWorksheet) =>
  `${sectionType}-${treatmentGroupForWorksheet.treatmentGroupId}`;

export const getTgSectionId = (tg: TreatmentGroupForWorksheet) => getSectionId(TREATMENT_GROUP_SECTION_TYPE, tg);

export const getTgColumnsSectionId = (tg: TreatmentGroupForWorksheet) =>
  getSectionId(TREATMENT_GROUP_COLUMNS_SECTION_TYPE, tg);

export const dataCollectionCommonSections = (tg?: TreatmentGroupForWorksheet): WorksheetSectionDefinition[] => [
  {
    id: 'heading',
    sectionType: 'form',
    fields: [
      {
        id: 'instructions',
        fieldType: 'readOnly',
        fieldName: 'Instructions',
        gridWidth: 6,
        default:
          "In this worksheet, data can be collected for all animals in all groups. The groups are vertically stacked by number in their own section of the worksheet. Within each group's section the data is separated by day, with each day being represented by a large column unit. The dates for each day are fully editable and there is no limit to the number of days that can be recorded. ",
      },
      {
        id: 'notes',
        fieldType: 'longText',
        fieldName: 'notes',
        gridWidth: 6,
      },
    ],
  },
  {
    id: INPUTS_ID,
    sectionType: 'form',
    fields: [
      {
        id: IMPLANTATION_DATE_ID,
        fieldType: 'calc',
        fieldName: 'Implantation date',
        calc: `lookup('${EXPERIMENT_MODELS_ID}', 'implantationDate', ['id', ${tg?.modelId}])`,
        gridWidth: 2,
      },
    ],
  },
];

export const createDataCollectionColumnsSection = (
  tg: TreatmentGroupForWorksheet,
  dataSourceFieldName: string,
  numberFormat: string | undefined
): WorksheetSectionDefinition => ({
  id: getTgColumnsSectionId(tg),
  dataCollectionSectionId: getTgSectionId(tg),
  sectionType: 'hidden',
  fields: [
    {
      id: 'date',
      fieldType: 'date',
      gridWidth: 4,
      fieldName: 'Date',
    },
    {
      id: DAY_ID,
      fieldType: 'calc',
      gridWidth: 2,
      calc: `daysDifference(${INPUTS_ID}.${IMPLANTATION_DATE_ID}, date)`,
      fieldName: 'Day',
    },
    {
      id: 'notes',
      fieldType: 'longText',
      gridWidth: 6,
      fieldName: 'notes',
    },
    {
      id: 'testArticles',
      fieldType: 'readOnly',
      gridWidth: 12,
      default: getTestArticleName(tg.testArticlesForWorksheet),
      fieldName: 'Test Articles',
    },
    {
      id: MEAN_ID,
      fieldName: 'Mean',
      fieldType: 'calc',
      numberFormat,
      gridWidth: 2,
      calc: `mean(valuesFromColumn(dataCollectionSectionId(), columnId(), "${dataSourceFieldName}"))`,
    },
    {
      id: 'stdDev',
      fieldName: 'Standard deviation',
      fieldType: 'calc',
      numberFormat,
      gridWidth: 3,
      calc: `stdev(valuesFromColumn(dataCollectionSectionId(), columnId(), "${dataSourceFieldName}"))`,
    },
    {
      id: MEDIAN_ID,
      fieldName: 'Median',
      fieldType: 'calc',
      numberFormat,
      gridWidth: 2,
      calc: `median(valuesFromColumn(dataCollectionSectionId(), columnId(), "${dataSourceFieldName}"))`,
    },
    {
      id: AVERAGE_WEIGHT_CHANGE_PERCENT_ID,
      fieldType: 'calc',
      numberFormat: '0.00',
      unit: '%',
      hidden: true,
      // The original Excel sheets used the `AVERAGE` function for this which calculates the `arithmetic mean`
      calc: `mean(valuesFromColumn(dataCollectionSectionId(), columnId(), "${WEIGHT_CHANGE_PERCENT_ID}"))`,
    },
  ],
});

export const createDataCollectionMainSection = (
  treatmentGroupForWorksheet: TreatmentGroupForWorksheet,
  fields: WorksheetFieldDefinition[],
  treatmentGroupsForWorksheet: TreatmentGroupForWorksheet[]
): WorksheetSectionDefinition => ({
  id: getTgSectionId(treatmentGroupForWorksheet),
  columnSectionId: getTgColumnsSectionId(treatmentGroupForWorksheet),
  sectionName: treatmentGroupForWorksheet.treatmentGroupName,
  sectionType: 'dataCollection',
  allowAddColumns: true,
  allowAddRows: true,
  allowRemoveRows: false,
  rowName: 'animal',
  columnName: 'day',
  addRowToSectionIds: treatmentGroupsForWorksheet.map((tg) => getTgSectionId(tg)), // TODO - this is currently handled in the VIEW; it shouldn't be; how can we do this better?
  fields: fields,
});

export const createDataCollectionCharts = (
  treatmentGroupsForWorksheet: TreatmentGroupForWorksheet[],
  chartLabel: string,
  yAxisLabel: string
): WorksheetSectionDefinition[] => [
  {
    id: 'meanGraph',
    sectionName: `Mean ${chartLabel}`,
    sectionType: 'chart',
    chartXLabel: 'days since implantation',
    chartYLabel: `mean ${yAxisLabel}`,
    chartData: treatmentGroupsForWorksheet.map((tg, i) => ({
      sourceSectionId: getTgColumnsSectionId(tg),
      label: `${getTestArticleName(tg.testArticlesForWorksheet)} | ${tg.treatmentGroupName}`,
      xFieldId: DAY_ID,
      yFieldId: MEAN_ID,
      color: ChartColors[i % ChartColors.length],
    })),
  },
  {
    id: 'medianGraph',
    sectionName: `Median ${chartLabel}`,
    sectionType: 'chart',
    chartXLabel: 'days since implantation',
    chartYLabel: `median ${yAxisLabel}`,
    chartData: treatmentGroupsForWorksheet.map((tg, i) => ({
      sourceSectionId: getTgColumnsSectionId(tg),
      label: `${getTestArticleName(tg.testArticlesForWorksheet)} | ${tg.treatmentGroupName}`,
      xFieldId: DAY_ID,
      yFieldId: MEDIAN_ID,
      color: ChartColors[i % ChartColors.length],
    })),
  },
  {
    id: 'bodyWeightGraph',
    sectionName: `% Body Weight Change`,
    sectionType: 'chart',
    chartXLabel: 'days',
    chartYLabel: `% weight change`,
    chartData: treatmentGroupsForWorksheet.map((tg, i) => ({
      sourceSectionId: getTgColumnsSectionId(tg),
      label: `${getTestArticleName(tg.testArticlesForWorksheet)} | ${tg.treatmentGroupName}`,
      xFieldId: DAY_ID,
      yFieldId: AVERAGE_WEIGHT_CHANGE_PERCENT_ID,
      color: ChartColors[i % ChartColors.length],
    })),
  },
];

export const createAllDataCollectionSections = (
  treatmentGroupsForWorksheet: TreatmentGroupForWorksheet[],
  dataSourceFieldName: string,
  chartLabel: string,
  yAxisLabel: string,
  numberFormat: string | undefined,
  fields: WorksheetFieldDefinition[]
): WorksheetSectionDefinition[] => [
  ...experimentInjectedSectionDefinitions,
  ...dataCollectionCommonSections(treatmentGroupsForWorksheet[0]),
  ...treatmentGroupsForWorksheet.map((num) =>
    createDataCollectionColumnsSection(num, dataSourceFieldName, numberFormat)
  ),
  ...treatmentGroupsForWorksheet.map((treatmentGroupForWorksheet) =>
    createDataCollectionMainSection(treatmentGroupForWorksheet, fields, treatmentGroupsForWorksheet)
  ),
  ...createDataCollectionCharts(treatmentGroupsForWorksheet, chartLabel, yAxisLabel),
];

export function formatNumber(value?: number) {
  if (value) {
    const format = value > 10000 ? SCIENTIFIC_NOTATION_FORMAT : WORKSHEET_DEFAULT_FORMAT;

    return numeral(value).format(format);
  }
  return '';
}

export function getTestArticleName(testArticles: TestArticleForWorksheet[]): string {
  return testArticles
    .map(({ doseNumber, doseUnit, interval, neededDosage, testArticleName }) => {
      return compact([
        testArticleName,
        formatNumber(neededDosage),
        testArticleName === 'Untreated' ? '' : doseUnit,
        compact([
          interval && `q${numeral(interval).format(WORKSHEET_DEFAULT_FORMAT)}`,
          doseNumber && `dx${doseNumber}`,
        ]).join(''),
      ]).join(' ');
    })
    .join(', ');
}
