import styled from '@emotion/styled';
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import { toWorksheetDate, type WorksheetFieldValue } from '@omnivivo/worksheets-core';
import { isDate, isEqual, isNil, isNull, isNumber } from 'lodash';
import { DateTime } from 'luxon';
import { memo, useEffect, useState } from 'react';

import { mediumTextStyle } from '#lib/constants/typography';
import { Chip } from '@mui/material';
import { DateField } from './DateField';
import { TimeField } from './TimeField';
import { formGrey, seagenGreen } from './SectionStyles';
import {
  getAutocompleteOptions,
  getDoseTimeUpdates,
  inputToWorksheetValue,
  isDataCollectionDateField,
  isDataCollectionDoseTimeField,
  isValidDate,
  setDateFromSection,
  worksheetToInputProps,
  worksheetToInputValue,
  type InputValue,
} from './SingleFieldLib';
import { type CommonProps, type DropdownValue, type SingleFieldProps } from './WorksheetComponentInterfaces';

const FieldContainer = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  minHeight: '28px',
  overflow: 'hidden',
});

const AutocompleteTextField = styled(TextField)({
  ...mediumTextStyle,
});

const basicFieldStyle = {
  width: '100%',
  ...mediumTextStyle,
};

const inputBorderStyle = '1px solid #CECECE';

const basicInputStyle = {
  ...basicFieldStyle,
  paddingLeft: '5px',
  paddingRight: '5px',
  paddingTop: '3px',
  paddingBottom: '3px',
  borderRadius: '4px',
  border: inputBorderStyle,
};

const readOnlyStyles = {
  ...basicFieldStyle,
  display: 'flex',
  alignItems: 'center',
  verticalAlign: 'middle',
};

const ReadOnlyTextContents = styled.span(readOnlyStyles);

const autoCompleteStyles = {
  width: '100%',
  '& .MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
    paddingTop: '3px',
    paddingBottom: '3px',
    height: 'auto',
  },
  [`& .${autocompleteClasses.inputRoot}`]: {
    height: '24px',
    paddingTop: 0,
    backgroundColor: 'white',
  },

  [`& .${autocompleteClasses.input}`]: {
    ...basicFieldStyle,
    padding: '0 !important',
    margin: 0,
    height: '24px',
  },
};
const autoCompleteChipOverflowStyles = {
  height: 'max-content',
  '& .MuiChip-label': {
    whiteSpace: 'wrap',
    wordBreak: 'break-all',
  },
};

const LongTextInput = styled.textarea({ ...basicInputStyle, height: '50px', lineHeight: 1.25 });
const Input = styled.input(basicInputStyle);

const NumberInput = styled.input({
  ...basicInputStyle,
});

export function getKeyboardNavigableProps<T extends HTMLTextAreaElement & HTMLInputElement>(props: CommonProps) {
  return {
    id: props.id,
    onFocus: (e: React.FocusEvent<T>) => {
      try {
        // This was failing locally for me, not sure why - Dave
        e.currentTarget.select();
      } catch (error: unknown) {
        try {
          e.target.select();
        } catch (_error) {
          // do nothing
        }
      }
    },
    onKeyDown: (e: React.KeyboardEvent<T>) => {
      const { id, selectionEnd } = e.currentTarget;

      switch (e.key) {
        case 'ArrowDown':
          if (!isNil(props.onGoDownInput)) {
            props.onGoDownInput(id);
            e.preventDefault();
          }
          break;
        case 'ArrowUp':
          if (!isNil(props.onGoUpInput)) {
            props.onGoUpInput(id);
            e.preventDefault();
          }
          break;
        case 'ArrowRight':
        case 'Enter':
          if (!isNil(props.onGoRightInput)) {
            props.onGoRightInput(id);
            e.preventDefault();
          }
          break;
        case 'ArrowLeft':
          if (!isNil(props.onGoLeftInput) && selectionEnd === 0) {
            props.onGoLeftInput(id);
            e.preventDefault();
          }
          break;
      }
    },
  };
}

const CheckboxWrapper = styled.div({
  display: 'flex',
  height: '28px',
  width: '28px',
  justifyContent: 'center',
  alignItems: 'center',
  marginLeft: '-5px',
});

const ReadOnlyField = ({ value, field }: CommonProps) => {
  value = value ?? field.default;
  return (
    <ReadOnlyTextContents>
      {value == null || (isNumber(value) && isNaN(value)) ? '-' : worksheetToInputValue(field, value)}
    </ReadOnlyTextContents>
  );
};

const FieldRenderers = {
  readOnly: ReadOnlyField,
  calc: ReadOnlyField,
  number: (props: CommonProps) => (
    <NumberInput
      placeholder="-"
      {...props.inputElementProps}
      {...getKeyboardNavigableProps(props)}
      onDragStart={(event) => {
        event.preventDefault();
      }}
    />
  ),
  string: (props: CommonProps) => (
    <Input placeholder="-" {...props.inputElementProps} {...getKeyboardNavigableProps(props)} />
  ),
  longText: (props: CommonProps) => (
    <LongTextInput {...props.inputElementProps} {...getKeyboardNavigableProps(props)} />
  ),
  date: DateField,
  time: TimeField,
  spacer: (props: CommonProps) => <div {...props.inputElementProps} />,
  boolean: (props: CommonProps) => (
    <CheckboxWrapper>
      {/* @ts-expect-error -- it's impossible to satisfy the prop types */}
      <Checkbox
        {...props.inputElementProps}
        sx={{
          color: formGrey,
          '&.Mui-checked': { color: seagenGreen },
        }}
        {...getKeyboardNavigableProps(props)}
      />
    </CheckboxWrapper>
  ),
  singleSelect: (props: CommonProps) => {
    const { value } = props;
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState<DropdownValue[]>([]);
    const currentOption = options.find(({ value: v }) => v === value) ?? options[0];

    // The `getWorksheetState` function is async. useState does not accept an async function for default.
    // To get the initial value, useEffect with trigger the async function and set the options when the
    // Promise resolves.
    useEffect(() => {
      void props.getWorksheetState().then((state) => {
        const newOptions: DropdownValue[] = getAutocompleteOptions(
          props.sectionId,
          props.entryId,
          props.field,
          props.worksheetDefinition,
          state.entriesBySectionId
        );
        // Only set the options if they are different.
        if (!isEqual(options, newOptions)) {
          setOptions(newOptions);
        }
      });
    }, []);

    return (
      <>
        {!isNil(currentOption) && (
          <Autocomplete
            data-test-id={`dropdown`}
            disablePortal
            disableClearable
            value={currentOption}
            onOpen={async () => {
              setOpen(true);
              setOptions(
                getAutocompleteOptions(
                  props.sectionId,
                  props.entryId,
                  props.field,
                  props.worksheetDefinition,
                  (await props.getWorksheetState()).entriesBySectionId
                )
              );
            }}
            onClose={() => {
              setOpen(false);
            }}
            open={open}
            options={options}
            onChange={(event, selectedOption) => props.inputElementProps.onChange(event, selectedOption?.value)}
            renderInput={(params) => <AutocompleteTextField {...params} {...getKeyboardNavigableProps(props)} />}
            renderOption={(props, option) => (
              <Box component="li" sx={{ ...mediumTextStyle }} {...props}>
                {option.label}
              </Box>
            )}
            sx={autoCompleteStyles}
          />
        )}
      </>
    );
  },
  multiSelect: (props: CommonProps) => {
    const { value } = props;
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState<DropdownValue[]>([]);
    let currentOption: string[] = [];
    if (!isNil(value)) {
      currentOption = value.toString().split(',');
    } else {
      currentOption = [];
    }

    useEffect(() => {
      void props.getWorksheetState().then((state) => {
        const newOptions: DropdownValue[] = getAutocompleteOptions(
          props.sectionId,
          props.entryId,
          props.field,
          props.worksheetDefinition,
          state.entriesBySectionId
        );

        if (!isEqual(options, newOptions)) {
          setOptions(newOptions);
        }
      });
    }, []);

    return (
      <>
        {!isNil(currentOption) && (
          <Autocomplete
            data-test-id={`dropdown`}
            disablePortal
            disableClearable
            multiple
            autoSelect
            freeSolo
            value={currentOption}
            onOpen={async () => {
              setOpen(true);
              setOptions(
                getAutocompleteOptions(
                  props.sectionId,
                  props.entryId,
                  props.field,
                  props.worksheetDefinition,
                  (await props.getWorksheetState()).entriesBySectionId
                )
              );
            }}
            onClose={() => {
              setOpen(false);
            }}
            open={open}
            options={options.map((option) => option.label)}
            onChange={(event, selectedOption) => props.inputElementProps.onChange(event, selectedOption)}
            renderInput={(params) => <AutocompleteTextField {...params} />}
            renderTags={(value: string[], getTagProps) =>
              value.map(
                (option: string, index: number) =>
                  option.length > 0 && (
                    <Chip
                      sx={{ ...mediumTextStyle, ...autoCompleteChipOverflowStyles }}
                      variant="outlined"
                      label={option}
                      {...getTagProps({ index })}
                    />
                  )
              )
            }
            sx={autoCompleteStyles}
            {...getKeyboardNavigableProps(props)}
          />
        )}
      </>
    );
  },
};

const SingleFieldBase = ({
  id,
  entryId,
  field,
  sectionId,
  updateValue,
  value: originalValue,
  worksheetDefinition,
  onGoUpInput,
  onGoDownInput,
  onGoLeftInput,
  onGoRightInput,
  getWorksheetState,
}: SingleFieldProps) => {
  const { id: fieldId, fieldType } = field;

  const [inputValue, setInputValue] = useState(worksheetToInputValue(field, originalValue));
  const [unsavedValue, setUnsavedValue] = useState(originalValue);
  const [lastSavedValue, setLastSavedValue] = useState(originalValue);

  useEffect(() => {
    setInputValue(worksheetToInputValue(field, originalValue));
    setUnsavedValue(originalValue);
    setLastSavedValue(originalValue);
  }, [originalValue]);

  const saveWorksheetValue = async (value: WorksheetFieldValue) => {
    if (value !== lastSavedValue) {
      if (isNumber(value) && !isNil(field.maxNumber) && value > field.maxNumber) {
        const inputValue: InputValue = isNumber(lastSavedValue) ? worksheetToInputValue(field, lastSavedValue) : '';
        setInputValue(inputValue);
        return;
      }
      setUnsavedValue(value);
      await updateValue({
        entryId,
        entries: (await getWorksheetState()).entries,
        fieldId,
        sectionId,
        value,
      });
      setLastSavedValue(value);
    }
  };

  const saveUnsavedWorksheetValue = async () => {
    await saveWorksheetValue(unsavedValue);
  };

  async function handleTimeChange(event: unknown) {
    if (!isNull(event) && !isDate(event)) {
      return;
    }

    setInputValue(event);

    if (isNull(event)) {
      await saveWorksheetValue(null);
      return;
    }

    if (!isValidDate(event)) {
      return;
    }

    const worksheetDateValue = isDataCollectionDoseTimeField(worksheetDefinition, field)
      ? setDateFromSection({
          event,
          worksheetDefinition,
          worksheetState: await getWorksheetState(),
          entryId,
          sectionId,
        })
      : event;

    await saveWorksheetValue(worksheetDateValue.toString());
  }

  async function handleDateChange(event: unknown) {
    if (!isNull(event) && !isDate(event)) {
      return;
    }

    setInputValue(event);

    if (isNull(event)) {
      await saveWorksheetValue(null);
      return;
    }

    if (!isValidDate(event)) {
      return;
    }

    const dateTime = DateTime.fromJSDate(event);

    // a bit of a hack to prevent extra saving and a formatting error that happens with the year doesn't have 4 digits
    if (dateTime.year < 2023) {
      return;
    }

    const doseTimeUpdates = isDataCollectionDateField(worksheetDefinition, field)
      ? getDoseTimeUpdates({
          entryId,
          event,
          sectionId,
          worksheetDefinition,
          worksheetState: await getWorksheetState(),
        })
      : [];

    await Promise.all([saveWorksheetValue(toWorksheetDate(event)), ...doseTimeUpdates.map(updateValue)]);
  }

  const commonProps = {
    id,
    field,
    entryId,
    sectionId,
    worksheetDefinition,
    value: originalValue,
    saveWorksheetValue,
    getWorksheetState,
    inputElementProps: {
      ...worksheetToInputProps(fieldType, inputValue),
      onChange: async (event: any, secondArgument?: InputValue) => {
        if (fieldType === 'time') {
          await handleTimeChange(event);
          return;
        }

        if (fieldType === 'date') {
          await handleDateChange(event);
          return;
        }

        let inputValue: InputValue;

        if (fieldType === 'boolean') inputValue = event.target.checked;
        else if (fieldType === 'singleSelect') inputValue = secondArgument;
        else if (fieldType === 'multiSelect') inputValue = secondArgument;
        else inputValue = event.target.value;
        const worksheetValueToSave = inputToWorksheetValue(field, inputValue);

        setInputValue(inputValue);

        if (['boolean', 'singleSelect', 'multiSelect'].includes(fieldType)) {
          await saveWorksheetValue(worksheetValueToSave);
        } else {
          setUnsavedValue(worksheetValueToSave);
        }
      },
      onBlur: async () => {
        await saveUnsavedWorksheetValue();
      },
    },
    handleTimeChange: (date: Date) => {
      void handleTimeChange(date);
    },
    onGoUpInput,
    onGoDownInput,
    onGoLeftInput,
    onGoRightInput,
  };

  const Renderer = FieldRenderers[fieldType];
  return (
    <FieldContainer data-test-id={id}>
      <Renderer {...commonProps} />
    </FieldContainer>
  );
};

export const SingleField = memo(SingleFieldBase);
