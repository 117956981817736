"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.experimentInjectedSectionDefinitions = exports.UNTREATED_CONTROL_ID = exports.MODEL_COUNT_ID = exports.PRE_CLINICAL_OBSERVATION_LIST = exports.EXPERIMENT_TREATMENT_GROUPS_ID = exports.EXPERIMENT_MODELS_ID = void 0;
exports.EXPERIMENT_MODELS_ID = 'experimentModels';
exports.EXPERIMENT_TREATMENT_GROUPS_ID = 'experimentTreatmentGroups';
exports.PRE_CLINICAL_OBSERVATION_LIST = 'preclinicalObservationList';
exports.MODEL_COUNT_ID = 'modelCount';
exports.UNTREATED_CONTROL_ID = 'untreatedControl';
exports.experimentInjectedSectionDefinitions = [
    {
        sectionType: 'hidden',
        disableEntryVivification: true,
        id: exports.EXPERIMENT_TREATMENT_GROUPS_ID,
        fields: [
            { id: 'id', fieldType: 'readOnly' },
            { id: 'name', fieldType: 'readOnly' },
            { id: 'treatmentGroupIndex', fieldType: 'readOnly' },
            { id: 'treatmentId', fieldType: 'readOnly' },
            { id: 'modelId', fieldType: 'readOnly' },
            { id: exports.MODEL_COUNT_ID, fieldType: 'readOnly' },
            { id: 'overage', fieldType: 'readOnly' },
            { id: 'notes', fieldType: 'readOnly' },
            { id: 'hasBioanalysis', fieldType: 'readOnly' },
            { id: 'hasImaging', fieldType: 'readOnly' },
            { id: 'testArticles', fieldType: 'readOnly' },
            { id: exports.UNTREATED_CONTROL_ID, fieldType: 'readOnly' },
        ],
    },
    {
        sectionType: 'hidden',
        id: exports.PRE_CLINICAL_OBSERVATION_LIST,
        disableEntryVivification: true,
        fields: [{ id: 'name', fieldType: 'readOnly' }],
    },
    {
        sectionType: 'hidden',
        disableEntryVivification: true,
        id: exports.EXPERIMENT_MODELS_ID,
        fields: [
            { id: 'id', fieldType: 'readOnly' },
            { id: 'cellLineName', fieldType: 'readOnly' },
            { id: 'implantationDate', fieldType: 'date' },
        ],
    },
];
