import { useCallback } from 'react';

import { ExperimentByIdDocument, useCreateBloodBioanalysisMutation } from '#graphql';
import { type TreatmentGroupWithTreatment } from '#lib/types';
import { BloodBioanalysisForm } from './BloodBioanalysisForm';
import { type BloodBioanalysisFormData, formDataToCreateInput } from './bloodBioanalysis';

interface CreateBloodBioanalysisProps {
  experimentId: number;
  onCancel?: () => void;
  onSuccess?: () => void;
  treatmentGroups: TreatmentGroupWithTreatment[];
}

export function CreateBloodBioanalysis(props: CreateBloodBioanalysisProps) {
  const { experimentId, onCancel = () => undefined, onSuccess = () => undefined, treatmentGroups } = props;

  const [createBloodBioanalysis] = useCreateBloodBioanalysisMutation({
    awaitRefetchQueries: true,
    refetchQueries: [ExperimentByIdDocument, 'experimentById'],
  });

  const handleSubmit = useCallback(
    async function handleSubmit(data: BloodBioanalysisFormData) {
      await createBloodBioanalysis({
        variables: {
          input: formDataToCreateInput(experimentId, data),
        },
      });
    },
    [createBloodBioanalysis]
  );

  return (
    <BloodBioanalysisForm
      handleSubmit={handleSubmit}
      onCancel={onCancel}
      onSuccess={onSuccess}
      treatmentGroups={treatmentGroups}
    />
  );
}
