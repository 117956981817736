import { useApolloClient, type ApolloError } from '@apollo/client';
import { debounce, isNil, omit } from 'lodash';
import { useMemo } from 'react';
import toast from 'react-hot-toast';

import {
  ExperimentByIdDocument,
  useUpdateExperimentMutation,
  type ExperimentByIdQuery,
  type ExperimentDetailsFragment,
  type UpdateExperimentInput,
} from '#graphql';
import { DEFAULT_DEBOUNCE, i18n } from '#lib/constants';
import { handleApolloError } from '#lib/utils';

type ExperimentData = ExperimentDetailsFragment;

export function useUpdateExperiment() {
  const client = useApolloClient();
  const [updateExperimentMutation, { loading }] = useUpdateExperimentMutation();

  const getCachedExperiment = (id: number): ExperimentDetailsFragment => {
    const cachedQuery = client.readQuery<ExperimentByIdQuery>({
      query: ExperimentByIdDocument,
      variables: { id },
    });
    if (isNil(cachedQuery) || isNil(cachedQuery.experiment)) {
      throw new Error('Cannot update an experiment that has not been initialized');
    }
    return cachedQuery.experiment;
  };

  const getMergedExperiment = (updateData: Partial<ExperimentData> & { id: number }): ExperimentDetailsFragment => {
    // Mimicking Prisma's behavior of considering null as a value and undefined as not setting a value
    // https://www.prisma.io/docs/concepts/components/prisma-client/null-and-undefined
    return {
      ...getCachedExperiment(updateData.id),
      ...removeUndefinedValues(updateData),
    };
  };

  const updateExperiment = (updateData: Partial<ExperimentData> & { id: number }) => {
    updateExperimentCache(updateData);
    const filteredUpdateData = filterFields(updateData);
    const mergedExperiment = getMergedExperiment(filteredUpdateData);
    const updateExperimentInput = getUpdateExperimentDataInput(mergedExperiment);
    return debounceExperiment(updateExperimentInput);
  };

  const updateExperimentCache = (updateData: Partial<ExperimentData> & { id: number }) => {
    const mergedExperiment = getMergedExperiment(updateData);
    client.writeQuery({
      query: ExperimentByIdDocument,
      variables: { id: updateData.id },
      data: { experiment: mergedExperiment },
    });
  };

  const saveExperiment = (updateExperimentInput: UpdateExperimentInput) => {
    void toast.promise(
      updateExperimentMutation({
        variables: {
          input: updateExperimentInput,
        },
      }),
      {
        loading: i18n.ExperimentView.ExperimentUpdatingExperiment,
        success: i18n.ExperimentView.ExperimentUpdatedMessage,
        error(err: ApolloError) {
          const duplicateApprovalNameError = err.graphQLErrors.find((graphQLError) => {
            return (
              graphQLError.extensions.code === 'UNIQUE_CONSTRAINT' &&
              graphQLError.extensions.field === 'Experiment_approvalname_key'
            );
          });
          const invalidPKBloodCollectionDeletionError = err.graphQLErrors.find((graphQLError) => {
            return graphQLError.extensions.code === 'INVALID_PKBLOOD_COLLECTION_DELETION';
          });
          const invalidPKTissueCollectionDeletionError = err.graphQLErrors.find((graphQLError) => {
            return graphQLError.extensions.code === 'INVALID_PKTISSUE_COLLECTION_DELETION';
          });
          const invalidTreatmentGroupError = err.graphQLErrors.find((graphQLError) => {
            return graphQLError.extensions.code === 'INVALID_TREATMENT_GROUP';
          });

          if (!isNil(duplicateApprovalNameError)) {
            return i18n.ExperimentView.ExperimentDuplicateName;
          } else if (!isNil(invalidPKBloodCollectionDeletionError)) {
            return i18n.PKBloodWorksheet.InvalidPKBloodCollectionDeletionError;
          } else if (!isNil(invalidPKTissueCollectionDeletionError)) {
            return i18n.PKTissueWorksheet.InvalidPKTissueCollectionDeletionError;
          } else if (!isNil(invalidTreatmentGroupError)) {
            return i18n.ExperimentView.InvalidTreatmentGroupError;
          } else {
            return handleApolloError(err);
          }
        },
      }
    );
  };

  const debounceExperiment = useMemo(() => debounce(saveExperiment, DEFAULT_DEBOUNCE), []);

  return {
    loading,
    saveExperiment: debounceExperiment,
    updateExperiment,
    updateExperimentCache,
  };
}

function filterFields(
  experimentData: Partial<ExperimentData> & { id: number }
): Partial<ExperimentData> & { id: number } {
  const { id, ...data } = omit(experimentData, [
    'updatedAt',
    'bloodBioanalysis',
    'tissueBioanalysis',
    'imaging',
    '__typename',
    'approvalCount',
    'team',
    'models',
    'attachments',
    'treatmentGroups',
    'worksheets',
    'orders',
    'treatments',
    'activity',
  ]);

  return Object.keys(data).length > 0 ? { ...data, id } : { id };
}

function getUpdateExperimentDataInput(experimentData: Partial<ExperimentData> & { id: number }): UpdateExperimentInput {
  const { coordinator, researcher, owner, ...data } = filterFields(experimentData);

  return {
    ...data,
    coordinatorId: coordinator?.id,
    researcherId: researcher?.id ?? null,
    ownerId: owner?.id,
  };
}

function removeUndefinedValues<T>(obj: Partial<T>): Partial<T> {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (value !== undefined) return { ...acc, [key]: value };
    return acc;
  }, {});
}
