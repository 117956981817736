import CheckIcon from '@mui/icons-material/Check';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import { Box, IconButton, Stack } from '@mui/material';
import {
  DataGridPremium,
  gridClasses,
  useGridApiRef,
  type GridColumns,
  type GridRowClassNameParams,
  type GridValueFormatterParams,
} from '@mui/x-data-grid-premium';
import { chain, isNil, sum, sumBy } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { ConfirmationModal } from '#components/partials';
import { useUpdatePkBloodWorksheetCollectionActualTimesMutation } from '#graphql';
import { AppColors, i18n } from '#lib/constants';
import { useModal } from '#lib/hooks';
import { formatDate, formatTime } from '#lib/utils';
import { Button } from '#rds';
import { DetailPanelRow } from './DetailPanelRow';
import { DetailPanelRowSetCurrentTimeButton } from './DetailPanelRowSetCurrentTimeButton';
import { DetailPanelRowSyncIndicator } from './DetailPanelRowSyncIndicator';
import { DetailPanelRowTimeField } from './DetailPanelRowTimeField';
import { DetailPanelContext } from './detailPanelContext';

export interface DetailRow {
  id: number;
  treatment: string;
  treatmentGroupIndex: string;
  animalNumber: number;
  expectedTime: string | undefined;
  actualTime: string | undefined;
}

interface GridDetailRow extends DetailRow {
  lastOfGroup?: boolean;
}

interface DetailPanelProps {
  canUpdateActualTime: boolean;
  closePanel?: () => void;
  data: DetailRow[];
  worksheetId: number;
}

const sx = {
  // borders
  [`&.${gridClasses.root}`]: {
    border: 'none',
  },
  [`.${gridClasses.columnHeaders}`]: {
    borderBottom: 'none',
  },
  [`.${gridClasses.row}:first-of-type .${gridClasses.cell}:not(.sync):not(:last-of-type)`]: {
    borderTop: '1px solid lightgrey',
  },
  [`.${gridClasses.row} .${gridClasses.cell}:first-of-type`]: {
    borderLeft: '1px solid lightgrey',
  },
  [`.${gridClasses.row} .${gridClasses.cell}:last-of-type`]: {
    borderBottom: 'none',
  },
  [`.${gridClasses.row} .${gridClasses.cell}.sync`]: {
    borderBottom: 'none',
    borderLeft: '1px solid lightgrey',
    borderRight: 'none',
    borderTop: 'none',
  },
  [`.${gridClasses.columnHeader}.${gridClasses.withBorder}`]: {
    borderRight: 'none',
  },

  // column header separator
  [`.${gridClasses.columnSeparator}`]: {
    display: 'none',
  },

  // striped
  [`.${gridClasses.row}:nth-of-type(odd) .${gridClasses.cell}:not(.sync):not(:last-of-type)`]: {
    background: AppColors.LIGHT_LIGHT_GREY,
  },
  [`.${gridClasses.row}:hover .${gridClasses.cell}.sync, .${gridClasses.row}:hover .${gridClasses.cell}:last-of-type`]:
    {
      background: 'white',
    },

  // grouped
  [`.${gridClasses.row}.last-of-group`]: {
    marginBottom: 4,
  },
  [`.${gridClasses.row}.last-of-group + .${gridClasses.row} .${gridClasses.cell}:not(.sync):not(:last-of-type)`]: {
    borderTop: '1px solid lightgrey',
  },
  [`.${gridClasses.row}.first-of-group .${gridClasses.cell}:not(.sync)`]: {
    borderTop: '1px solid lightgrey',
  },
  [`.${gridClasses['row--lastVisible']} .${gridClasses.cell}:not(.sync):not(:last-of-type)`]: {
    borderBottom: '1px solid lightgrey',
  },

  // fade on data update
  [`.${gridClasses.row}.updated-enter .${gridClasses.cell}:not(.sync):not(:last-of-type)`]: {
    borderColor: '#DA7929',
    backgroundColor: '#F4CFA6',
  },
  [`.${gridClasses.row}.updated-enter-active .${gridClasses.cell}:not(.sync):not(:last-of-type)`]: {
    borderColor: '#DA7929',
    backgroundColor: '#F4CFA6',
    transition: `all 200ms ease-in-out`,
  },
  [`.${gridClasses.row}.updated-enter-done .${gridClasses.cell}:not(.sync):not(:last-of-type)`]: {
    transition: `all 2000ms ease-in-out`,
  },
};

const experimentFeatures = {
  newEditingApi: true,
};

export function DetailPanel(props: DetailPanelProps) {
  const { canUpdateActualTime, closePanel = () => undefined, data: dataProp, worksheetId } = props;
  const apiRef = useGridApiRef();

  const [updateActualTimes, { error, loading }] = useUpdatePkBloodWorksheetCollectionActualTimesMutation();

  const [data, setData] = useState(dataProp);
  const [updates, setUpdates] = useState<number[]>([]);

  const {
    Modal: PopulateActualTimesWithExpectedTimesModal,
    openModal: openPopulateActualTimesWithExpectedTimesModal,
    closeModal: closePopulateActualTimesWithExpectedTimesModal,
  } = useModal(null, 'sm');

  useEffect(() => {
    const updates = data
      .filter((current) => {
        const incoming = dataProp.find((incoming) => incoming.id === current.id);
        return incoming?.expectedTime !== current.expectedTime;
      })
      .map((updated) => updated.id);

    setUpdates(updates);
    setData(dataProp);
  }, [dataProp]);

  const detailPanelContext = useMemo(() => {
    return {
      error,
      loading,
      updates,
    };
  }, [error, loading, updates]);

  const [rows, height] = useMemo(() => {
    const rows = buildRows(data);
    const height = 56 + sum(rows.map((row) => (row.lastOfGroup === true ? 84 : 52)));
    return [rows, height];
  }, [data]);

  const populateActualTimesWithExpectedTimes = useCallback(() => {
    const updatedRows = rows.map((row) => ({ ...row, actualTime: row.expectedTime }));

    apiRef.current.updateRows(updatedRows);
    void updateActualTimes({
      variables: {
        input: {
          pkBloodWorksheetId: worksheetId,
          actualTimes: updatedRows.map(({ id, actualTime }) => ({ id, actualTime: actualTime ?? null })),
        },
      },
    });
  }, [apiRef, rows, updateActualTimes, worksheetId]);

  const maybePopulateActualTimesWithExpectedTimes = useCallback(() => {
    const hasData = data.some((collection) => !isNil(collection.actualTime) && collection.actualTime !== '');
    if (hasData) {
      openPopulateActualTimesWithExpectedTimesModal();
    } else {
      populateActualTimesWithExpectedTimes();
    }
  }, [data, openPopulateActualTimesWithExpectedTimesModal, populateActualTimesWithExpectedTimes]);

  const [columns, gridWidth] = useMemo(() => {
    const columns: GridColumns<GridDetailRow> = [
      {
        field: 'treatment',
        headerName: 'Treatment',
        sortable: false,
        width: 300,
      },
      {
        field: 'treatmentGroupIndex',
        headerName: 'Group',
        sortable: false,
        width: 100,
      },
      {
        field: 'animalNumber',
        headerName: 'Animal',
        sortable: false,
        width: 100,
      },
      {
        field: 'expectedTime',
        headerName: 'Exp. Time',
        sortable: false,
        valueFormatter(params: GridValueFormatterParams<GridDetailRow['expectedTime']>) {
          return formatTime(params.value) ?? '-';
        },
        width: 100,
      },
      {
        field: 'populateButton',
        renderHeader() {
          return (
            <IconButton size="small" onClick={maybePopulateActualTimesWithExpectedTimes}>
              <DoubleArrowIcon color="primary" titleAccess="populate actual times with expected times" />
            </IconButton>
          );
        },
        sortable: false,
        width: 50,
      },
      {
        field: 'actualDate',
        headerName: 'Collection Date',
        sortable: false,
        valueGetter(params) {
          return isNil(params.row.actualTime) ? '-' : formatDate(params.row.actualTime);
        },
        width: 150,
      },
      {
        field: 'actualTime',
        headerName: 'Collection Time',
        renderCell(params) {
          return (
            <DetailPanelRowTimeField
              id={params.id}
              actualTime={params.row.actualTime}
              disabled={!canUpdateActualTime}
            />
          );
        },
        sortable: false,
        width: 200,
      },
      {
        field: 'actions',
        getActions(params) {
          return [<DetailPanelRowSetCurrentTimeButton id={params.id} disabled={!canUpdateActualTime} />];
        },
        type: 'actions',
        width: 80,
      },
      {
        field: 'sync',
        headerName: '',
        cellClassName: 'sync',
        renderCell() {
          return <DetailPanelRowSyncIndicator />;
        },
        sortable: false,
        width: 50,
      },
    ];

    const gridWidth = sumBy(columns, 'width') + 22;

    return [columns, gridWidth];
  }, [maybePopulateActualTimesWithExpectedTimes]);

  const canClosePanel = !rows.some((row) => isNil(row.actualTime));

  return (
    <>
      <PopulateActualTimesWithExpectedTimesModal
        title="Populate actual times with expected times"
        content={i18n.PKBloodWorksheet.PopulateActualTimesWithExpectedTimes.Confirmation}
      >
        <ConfirmationModal
          closeModal={closePopulateActualTimesWithExpectedTimesModal}
          confirmationFunction={() => {
            closePopulateActualTimesWithExpectedTimesModal();
            populateActualTimesWithExpectedTimes();
          }}
        />
      </PopulateActualTimesWithExpectedTimesModal>
      <Box padding={4} borderBottom="1px solid #E0E0E0">
        <Stack spacing={4} alignItems="flex-start">
          <Box height={height} maxHeight={700} width={gridWidth}>
            <DetailPanelContext.Provider value={detailPanelContext}>
              <DataGridPremium
                apiRef={apiRef}
                rows={rows}
                columns={columns}
                hideFooter
                disableColumnMenu
                disableColumnReorder
                disableExtendRowFullWidth
                experimentalFeatures={experimentFeatures}
                // Will be used in PK Tissue, needs formatting updates
                // initialState={{
                //   pinnedColumns: { left: ['treatment', 'treatmentGroupIndex', 'animalNumber'], right: ['syncIndicator'] },
                // }}
                getRowClassName={getRowClassName}
                components={{
                  Row: DetailPanelRow,
                }}
                sx={sx}
              />
            </DetailPanelContext.Provider>
          </Box>
          <Button variant="contained" startIcon={<CheckIcon />} disabled={!canClosePanel} onClick={closePanel}>
            Confirm Final Collection Times
          </Button>
        </Stack>
      </Box>
    </>
  );
}

function buildRows(rows: GridDetailRow[]) {
  return chain(rows)
    .sortBy([(row) => Number(row.treatmentGroupIndex), 'animalNumber'])
    .groupBy('treatmentGroupIndex')
    .flatMap((rowGroup) => {
      const last = rowGroup.pop();
      return isNil(last) //
        ? rowGroup
        : rowGroup.concat([{ ...last, lastOfGroup: true }]);
    })
    .value();
}

function getRowClassName(params: GridRowClassNameParams<GridDetailRow>) {
  return params.row.lastOfGroup === true ? 'last-of-group' : '';
}
