import styled from '@emotion/styled';
import { PropagateLoader } from 'react-spinners';
import { AppColors } from '#lib/constants';

const StyledContainer = styled.div(
  ({ center = false }: { center?: boolean }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: '20px',
    marginBottom: '20px',
    height: center ? '500px' : '100px',
  })
);

const StyledMessage = styled.div({
  marginTop: '20px',
  fontSize: '20px',
  color: '#999',
});

export const dataTestId = 'loader';

export const Loader = ({
  message,
  center = false,
}: {
  message?: string;
  center?: boolean;
}) => (
  <StyledContainer center={center} data-test-id={dataTestId}>
    <PropagateLoader color={AppColors.BRAND_GREEN} loading={true} size={10} />
    {message && <StyledMessage>{message}</StyledMessage>}
  </StyledContainer>
);
