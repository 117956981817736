import { SectionRenderProps } from "./WorksheetComponentInterfaces";
import {
  TableEntry,
  TableColumnLabels,
  getEntryWidth,
  DeleteEntryButton,
  getTableNavigationByIdsHandlers,
} from "./SectionLib";
import {
  Cell,
  Table,
  HeaderRow,
  Row,
  TableWrapper,
} from "./SectionStyledComponents";
import { sortEntries } from "@omnivivo/worksheets-core";

export const TableSection = ({
  worksheetDefinition,
  section,
  entries,
  worksheetMutations,
  getWorksheetState,
}: SectionRenderProps) => {
  return (
    <TableWrapper>
      <Table
        data-test-id={`${section.id}Table`}
        style={{ width: `${getEntryWidth(section.fields)}px` }}
      >
        <thead>
          <HeaderRow key="colLabels">
            <TableColumnLabels section={section} />
          </HeaderRow>
        </thead>
        <tbody>
          {sortEntries(section, entries).map((entry, rowNumber) => {
            const rowId = `${entry.id}${rowNumber}`;
            return (
              <Row
                data-test-id={`${section.id}-${rowNumber}`}
                hidden={entry.data.hidden as boolean}
                key={rowId}
              >
                <TableEntry
                  {...{
                    rowNumber,
                    worksheetDefinition,
                    section,
                    entry,
                    worksheetMutations,
                    getWorksheetState,
                    rowId: entry.rowId,
                    ...getTableNavigationByIdsHandlers(section.fields),
                  }}
                />
                {((section.allowRemoveRows ?? section.allowAddRows) && (
                  <Cell width={"30px"} data-test-id={`table-delete-row`}>
                    <DeleteEntryButton
                      deleteAction={() =>
                        worksheetMutations.deleteEntry({
                          entryId: entry.id,
                          sectionId: entry.sectionId,
                        })
                      }
                    />
                  </Cell>
                )) ||
                  undefined}
              </Row>
            );
          })}
        </tbody>
      </Table>
    </TableWrapper>
  );
};
