import { getRequest, postRequest, deleteRequest } from './rest';
import {
  API_GET_ATTACHMENT,
  API_ADD_ATTACHMENT,
  API_DELETE_ATTACHMENT,
  HTTP,
  i18n,
} from '../constants';

export const getAttachment = async (
  experimentId: number,
  filename: string,
  category: string
): Promise<Blob> => {
  const data = await getRequest({
    endpoint: `${API_GET_ATTACHMENT}?experimentId=${experimentId}&filename=${filename}&category=${category}`,
  });
  return data.blob();
};

export const uploadAttachment = (
  experimentId: number,
  filename: string,
  category: string,
  formData: FormData
) =>
  postRequest({
    endpoint: `${API_ADD_ATTACHMENT}?experimentId=${experimentId}&filename=${filename}&category=${category}`,
    data: formData,
  });

export const deleteAttachment = async (
  experimentId: number,
  filename: string,
  category: string
): Promise<string> => {
  const response = await deleteRequest({
    endpoint: `${API_DELETE_ATTACHMENT}?experimentId=${experimentId}&filename=${filename}&category=${category}`,
  });
  return response.text();
};
