import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box } from '@mui/material';
import {
  DataGridPremium,
  gridClasses,
  useGridApiRef,
  type GridColDef,
  type GridRowParams,
  type GridValidRowModel,
} from '@mui/x-data-grid-premium';
import { compact, isNil, isNull, isUndefined, max, min } from 'lodash';
import { useCallback, useMemo } from 'react';

import {
  useListContentsQuery,
  type ExperimentDetailsFragment,
  type ListContentDetailsFragment,
  type PkTissueWorksheetCollectionFragment,
  type TissueBioanalysisTimepointFragment,
  type TissueBioanalysisTissueFragment,
} from '#graphql';
import { formatDate, formatTime } from '#lib/utils';
import { DetailPanel, type DetailRow } from './DetailPanel';
import { type HandlingAndStorageKey } from './DetailPanel/DetailPanel';

interface GridRow extends GridValidRowModel {
  id: number;
  date: string;
  dose: number;
  timepoint: string;
  expectedCollectionTime: string | null;
  completedTime: string | null;
}

interface CollectionsProps {
  bioanalysisTimepoints: ListContentDetailsFragment[];
  canUpdateActualTime: boolean;
  collectionCriteria: string;
  collections: PkTissueWorksheetCollectionFragment[] | undefined;
  experiment: ExperimentDetailsFragment;
  timepoints: TissueBioanalysisTimepointFragment[];
  worksheetId: number;
  tissues: TissueBioanalysisTissueFragment[];
}

export function Collections(props: CollectionsProps) {
  const {
    bioanalysisTimepoints,
    canUpdateActualTime,
    collectionCriteria,
    collections,
    experiment,
    timepoints,
    worksheetId,
    tissues,
  } = props;

  const apiRef = useGridApiRef();

  const { data } = useListContentsQuery({
    variables: {
      category: 'handlingAndStorage',
    },
  });

  const { rows, detailRows } = useMemo(() => {
    const rows: GridRow[] = [];
    const detailRows = new Map<number, DetailRow[]>();
    const index = new Map<string, number>();

    for (const collection of collections ?? []) {
      const timepoint = timepoints.find((t) => t.id === collection.tissueBioanalysisTimepointId);
      if (isUndefined(timepoint)) {
        // This should not be possible
        continue;
      }

      const key = `${collection.expectedDate}-${timepoint.dose}-${timepoint.minutes}`;

      const detailRow = buildDetailRow(collection, experiment);

      let rowId = index.get(key);
      if (isUndefined(rowId)) {
        rowId = rows.length + 1;
        index.set(key, rowId);
        rows.push({
          id: rowId,
          date: !isNil(collection.expectedDate) ? formatDate(collection.expectedDate) : 'TBD',
          dose: timepoint.dose,
          timepoint:
            bioanalysisTimepoints.find(
              (bioanalysisTimepoint) => bioanalysisTimepoint.name === timepoint.minutes.toString()
            )?.description ?? '',
          // assigned below
          expectedCollectionTime: null,
          completedTime: null,
        });
        detailRows.set(rowId, [detailRow]);
      } else {
        detailRows.set(rowId, [...(detailRows.get(rowId) ?? []), detailRow]);
      }
    }

    const updatedRows = rows.map((row) => {
      const detailRowGroup = detailRows.get(row.id);

      const completedTime = (detailRowGroup ?? []).some((detailRow) => isNil(detailRow.actualTime))
        ? null
        : max(detailRowGroup?.map((detailRow) => detailRow.actualTime)) ?? null;

      const expectedCollectionTime =
        isNull(completedTime) && (detailRowGroup ?? []).some((detailRow) => !isNil(detailRow.expectedTime))
          ? min(compact(detailRowGroup?.map((detailRow) => detailRow.expectedTime))) ?? null
          : null;

      return {
        ...row,
        expectedCollectionTime,
        completedTime,
      };
    });
    return { rows: updatedRows, detailRows };
  }, [collections, timepoints, experiment]);

  const getDetailPanelContent = useCallback(
    (rowParams: GridRowParams<GridRow>) => {
      const rows = detailRows.get(rowParams.row.id) ?? [];

      return (
        <DetailPanel
          canUpdateActualTime={canUpdateActualTime}
          closePanel={() => {
            apiRef.current.toggleDetailPanel(rowParams.row.id);
          }}
          collectionCriteria={collectionCriteria}
          data={rows}
          handlingAndStorage={data?.listContents.items}
          tissues={tissues}
          worksheetId={worksheetId}
        />
      );
    },
    [detailRows, canUpdateActualTime, data]
  );

  const columns = useMemo(() => buildColumns(collectionCriteria), [collectionCriteria]);

  if (isUndefined(collections)) {
    return null;
  }

  return (
    <Box paddingY={2}>
      <DataGridPremium
        apiRef={apiRef}
        columns={columns}
        rows={rows}
        autoHeight
        density="compact"
        disableColumnMenu
        disableColumnReorder
        disableColumnResize
        hideFooter
        rowThreshold={0}
        getDetailPanelHeight={() => 'auto'}
        getDetailPanelContent={getDetailPanelContent}
        getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
        components={{
          DetailPanelExpandIcon: ExpandMoreIcon,
          DetailPanelCollapseIcon: ExpandLessIcon,
        }}
        sx={(theme) => ({
          '&.MuiDataGrid-root': {
            borderLeft: '0',
            borderRight: '0',
            borderTop: '0',
          },
          [`& .${gridClasses.columnSeparator}`]: {
            display: 'none',
          },
          [`& .${gridClasses.columnHeaderTitle}`]: {
            fontWeight: 600,
          },
          [`& .${gridClasses.row}.odd`]: {
            backgroundColor: '#ECECEC',
          },
          [`& .${gridClasses['row--detailPanelExpanded']}`]: {
            backgroundColor: theme.palette.primary.light,
          },
        })}
      />
    </Box>
  );
}

function buildDetailRow(
  collection: PkTissueWorksheetCollectionFragment,
  experiment: ExperimentDetailsFragment
): DetailRow {
  const treatmentGroup = experiment.treatmentGroups.find((tg) => tg.id === collection.treatmentGroupId);
  const treatment = experiment.treatments
    .find((treatment) => treatment.id === treatmentGroup?.treatmentId)
    ?.testArticles.map((testArticle) => testArticle.name)
    .join(', ');

  const handlingAndStorage = collection.pkTissueWorksheetHandlingAndStorage.reduce((acc, handlingAndStorage) => {
    const key: HandlingAndStorageKey = `handlingAndStorage|${handlingAndStorage.tissueBioanalysisTissueId}|${handlingAndStorage.method}`;

    return {
      ...acc,
      [key]: {
        id: handlingAndStorage.id,
        weight: handlingAndStorage.weight,
        collected: handlingAndStorage.collected,
      },
    };
  }, {});

  return {
    id: collection.id,
    treatment: treatment ?? '',
    treatmentGroupIndex: treatmentGroup?.treatmentGroupIndex.toString() ?? '',
    animalNumber: collection.animalNumber,
    expectedTime: collection.expectedTime,
    actualTime: collection.actualTime,
    ...handlingAndStorage,
  };
}

function buildColumns(collectionCriteria: string): Array<GridColDef<GridRow>> {
  return compact([
    collectionCriteria === 'dose' ? { field: 'date', headerName: 'Date', width: 150, sortable: false } : null,
    collectionCriteria === 'dose' ? { field: 'dose', headerName: 'Dose', sortable: false } : null,
    { field: 'timepoint', headerName: 'Collection Point', width: 150, sortable: false },
    collectionCriteria === 'dose'
      ? {
          field: 'expectedCollectionTime',
          headerName: 'Exp. Collection Time',
          width: 150,
          sortable: false,
          valueGetter(params) {
            return formatTime(params.row.expectedCollectionTime) ?? '-';
          },
        }
      : null,
    {
      field: 'completedTime',
      headerName: 'Completed Time',
      width: 150,
      sortable: false,
      valueGetter(params) {
        return formatTime(params.row.completedTime) ?? '-';
      },
    },
  ]);
}
