import { type WorksheetDefinition } from '@omnivivo/worksheets-core';
import { isEmpty, isNil, keyBy } from 'lodash';

import { type TreatmentGroup } from '#graphql';
import {
  blankLine,
  formatBoolean,
  formatTime,
  getCSVSimpleFormat,
  getWorksheetSectionListMultipleRows,
  getWorksheetSectionListSingleRow,
  groupIdForSection,
  parseArrayToCSV,
} from './exportCore';

export const getTumorSizeWorksheetCsv = (
  definition: WorksheetDefinition,
  data: string[][],
  headers: string[],
  keys: string[],
  experimentTreatmentGroups: TreatmentGroup[]
) => {
  let worksheetCsv = '';

  const experimentTreatmentGroupsById = keyBy(experimentTreatmentGroups, 'id');

  const implantation = getWorksheetSectionListSingleRow(definition, 'inputs', headers, data, keys, [
    'implantationDate',
  ]);

  worksheetCsv = worksheetCsv.concat(getCSVSimpleFormat(implantation.csvHeaders, implantation.csvInfo), blankLine);

  // Get treatment groups and treatment group columns
  const sectionIndex = keys.findIndex((key) => key === 'sectionId');
  const treatmentGroups = data.filter((entry) => entry[sectionIndex].startsWith('treatmentGroup-'));
  const treatmentGroupColumns = data
    .filter(
      (entry) =>
        entry[sectionIndex].startsWith('treatmentGroupColumns-') &&
        !isNil(experimentTreatmentGroupsById[Number(groupIdForSection(entry[sectionIndex]))])
    )
    .sort((a, b) => {
      return (
        experimentTreatmentGroupsById[Number(groupIdForSection(a[sectionIndex]))]?.treatmentGroupIndex -
        experimentTreatmentGroupsById[Number(groupIdForSection(b[sectionIndex]))]?.treatmentGroupIndex
      );
    });

  // Reduce treatment groups to only those with data
  const treatmentGroupsWithData = treatmentGroups.filter((group) => {
    const groupNumber = groupIdForSection(group[sectionIndex]);
    const groupColumns = treatmentGroupColumns.filter(
      (column) => column[sectionIndex] === `treatmentGroupColumns-${groupNumber}`
    );
    return !isEmpty(groupColumns);
  });

  const columnIdIndex = keys.findIndex((key) => key === 'columnId');
  const sectionIdIndex = keys.findIndex((key) => key === 'sectionId');
  let previousGroupName = '';
  treatmentGroupColumns.forEach((group) => {
    const days = treatmentGroupColumns.filter((entry) => entry[columnIdIndex] === group[columnIdIndex]);
    const groupIndex =
      experimentTreatmentGroupsById[Number(groupIdForSection(group[sectionIndex]))]?.treatmentGroupIndex;

    const groupOutputRows = getWorksheetSectionListSingleRow(
      definition,
      `${group[sectionIndex]}`,
      headers,
      days,
      keys,
      ['date', 'day', 'notes', 'Test Articles', 'mean', 'stdDev', 'median']
    );
    const rows = treatmentGroupsWithData.filter((entry) => entry[columnIdIndex] === group[columnIdIndex]);
    const animalOutputRows = getWorksheetSectionListMultipleRows(
      definition,
      `treatmentGroup-${groupIdForSection(group[sectionIndex])}`,
      headers,
      rows,
      keys,
      [
        '',
        'rowId',
        'length',
        'width',
        'mm3',
        'mass',
        'weightChangePercentage',
        'observations',
        ['doseRequired', formatBoolean],
        'doseAmount',
        ['doseTime', formatTime],
      ]
    );

    if (group[sectionIdIndex] !== previousGroupName) {
      worksheetCsv = worksheetCsv.concat('\n', `Group ${groupIndex}`, '\n');
      previousGroupName = group[sectionIdIndex];
    }

    worksheetCsv = worksheetCsv.concat(getCSVSimpleFormat(groupOutputRows.csvHeaders, groupOutputRows.csvInfo), '\n');

    animalOutputRows.csvHeaders[animalOutputRows.csvHeaders.indexOf('Row ID')] = 'Animals';
    worksheetCsv = worksheetCsv.concat(parseArrayToCSV(animalOutputRows.csvHeaders, animalOutputRows.csvInfo), '\n');
  });

  return worksheetCsv;
};
