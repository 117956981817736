import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton, Stack } from '@mui/material';
import {
  DataGridPremium,
  type DataGridPremiumProps,
  type GridColDef,
  type GridFilterModel,
  type GridFilterOperator,
  type GridRowId,
  type GridRowModel,
  type GridSortModel,
  GridToolbar,
  getGridDateOperators,
  getGridDefaultColumnTypes,
  getGridSingleSelectOperators,
  getGridStringOperators,
  useGridApiRef,
  useKeepGroupedColumnsHidden,
} from '@mui/x-data-grid-premium';
import { isNil, trim } from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { type PaginationSort } from '#graphql';
import { AppColors } from '#lib/constants';
import {
  OmniGridConfirmModal,
  type UseOmniGridConfirmModalParams,
  useOmniGridConfirmModal,
} from './OmniGridConfirmModal';
import { OmniGridHeader, type OmniGridHeaderProps } from './OmniGridHeader';

enum SearchParams {
  Page = 'p',
  PageSize = 'ps',
  Query = 'qm',
  FilterModel = 'fm',
  SortModel = 'sm',
}

const DEFAULT_PAGE = 0;
export const DEFAULT_PAGE_SIZE = 10;
const DEFAULT_GRID_SORT_MODEL: GridSortModel = [{ field: 'createdAt', sort: 'desc' }];

const DEFAULT_PAGE_SIZES = [DEFAULT_PAGE_SIZE, DEFAULT_PAGE_SIZE * 2, DEFAULT_PAGE_SIZE * 4];

const dateOperators = getGridDateOperators().filter((operator) => operator.value === 'is');

const singleSelectOperators = getGridSingleSelectOperators().filter((operator) => operator.value === 'is');

const stringOperators = getGridStringOperators().filter((operator) => operator.value === 'equals');

const defaultColumnTypes = getGridDefaultColumnTypes();

const filterOperators: Record<string, GridFilterOperator[]> = {
  boolean: defaultColumnTypes.boolean.filterOperators!,
  date: dateOperators,
  singleSelect: singleSelectOperators,
};

function getFilterOperator(columnType: string) {
  return filterOperators[columnType] ?? stringOperators;
}

function parse<T>(model: string | null): T | undefined {
  if (model === null) {
    return undefined;
  }

  try {
    return JSON.parse(model);
  } catch (error) {
    return undefined;
  }
}

function stringify(model: object) {
  return JSON.stringify(model);
}

export interface EditButtonProps {
  handleEdit: (id: GridRowId) => void;
}

export interface OmniGridProps {
  loading?: boolean;
  error?: any;
  rows?: readonly GridRowModel[];
  rowCount?: number;
  refetch: any;
  columns: GridColDef[];
  defaultPage?: number;
  defaultPageSize?: number;
  defaultSort?: GridSortModel;
  dataGridProps?: Partial<DataGridPremiumProps>;
  showQuickFilter?: boolean;
  showEditButton?: boolean;
  editButtonProps?: EditButtonProps;
  showDeleteButton?: boolean;
  deleteConfirmProps?: UseOmniGridConfirmModalParams;
  headerProps?: OmniGridHeaderProps;
}

export function OmniGrid(props: OmniGridProps) {
  const {
    loading,
    error,
    rows = [],
    rowCount = 0,
    refetch,
    columns,
    defaultPage = DEFAULT_PAGE,
    defaultPageSize = DEFAULT_PAGE_SIZE,
    defaultSort = DEFAULT_GRID_SORT_MODEL,
    dataGridProps = {},
    showQuickFilter,
    showEditButton,
    editButtonProps,
    showDeleteButton,
    deleteConfirmProps,
    headerProps,
  } = props;

  const [searchParams, setSearchParams] = useSearchParams();
  const apiRef = useGridApiRef();
  const modalControl = useOmniGridConfirmModal(deleteConfirmProps);

  const updateSearchParams = useCallback(
    (params: Array<{ key: SearchParams; value: string }>) => {
      const urlSearchParams = new URLSearchParams(searchParams);
      params.forEach(({ key, value }) => {
        urlSearchParams.set(key, value);
      });
      setSearchParams(urlSearchParams);
    },
    [searchParams]
  );

  const updateSearchParam = (key: SearchParams, value: string) => {
    updateSearchParams([{ key, value }]);
  };

  const onPageChange = useCallback(
    (newPage: number) => {
      updateSearchParam(SearchParams.Page, `${newPage}`);
    },
    [updateSearchParam]
  );

  const onPageSizeChange = useCallback(
    (newPageSize: number) => {
      updateSearchParam(SearchParams.PageSize, `${newPageSize}`);
    },
    [updateSearchParam]
  );

  const onSortModelChange = useCallback(
    (newSortModel: GridSortModel) => {
      updateSearchParam(SearchParams.SortModel, stringify(newSortModel));
    },
    [updateSearchParam]
  );

  const onFilterModelChange = useCallback(
    (newFilterModel: GridFilterModel) => {
      updateSearchParam(SearchParams.FilterModel, stringify(newFilterModel));
    },
    [updateSearchParams]
  );

  const { filterModel, page, pageSize, sortModel } = useMemo(() => {
    return {
      page: parseInt(searchParams.get(SearchParams.Page) ?? `${defaultPage}`),
      pageSize: parseInt(searchParams.get(SearchParams.PageSize) ?? `${defaultPageSize}`),
      sortModel: parse<GridSortModel>(searchParams.get(SearchParams.SortModel)) ?? undefined,
      filterModel: parse<GridFilterModel>(searchParams.get(SearchParams.FilterModel)) ?? undefined,
    };
  }, [searchParams]);

  useEffect(() => {
    const { quickFilterValues, items, linkOperator } = filterModel ?? {};

    refetch({
      variables: {
        limit: pageSize,
        offset: page * pageSize,
        query: !isNil(quickFilterValues) ? trim(quickFilterValues.join(' ')) : undefined,
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        sort: sortModel ?? (defaultSort as PaginationSort[]),
        filter: { items, linkOperator },
      },
    });
  }, [filterModel, page, pageSize, sortModel]);

  // TODO - replace with custom ColDef
  const modifiedColumns = useMemo(() => {
    const cols: GridColDef[] = columns.map((column) => ({
      ...column,
      filterOperators: getFilterOperator(column.type ?? 'string'),
    }));

    if (!isNil(showEditButton) || !isNil(showDeleteButton)) {
      cols.push({
        field: 'actions',
        type: 'actions',
        headerName: 'Actions',
        width: 100,
        cellClassName: 'actions',
        renderCell: ({ id }) => {
          return (
            <>
              {!isNil(showEditButton) && (
                <IconButton
                  aria-label="Edit"
                  onClick={() => editButtonProps?.handleEdit(id)}
                  className="textPrimary"
                  color="inherit"
                >
                  <EditIcon />
                </IconButton>
              )}
              {!isNil(showDeleteButton) && (
                <IconButton
                  aria-label="Delete"
                  onClick={() => {
                    modalControl.openModal(id);
                  }}
                  className="textPrimary"
                  color="inherit"
                >
                  <DeleteIcon />
                </IconButton>
              )}
            </>
          );
        },
      });
    }

    return cols;
  }, [showEditButton, showDeleteButton, editButtonProps, columns]);

  const { initialState: dataGridPropsInitialState, sx, ...restDataGridProps } = dataGridProps;

  const initialState = !isNil(dataGridPropsInitialState)
    ? useKeepGroupedColumnsHidden({
        apiRef,
        initialState: dataGridPropsInitialState,
      })
    : {};

  const dataGridStyles = {
    ...(isNil(dataGridProps?.getRowHeight)
      ? {
          '& .MuiDataGrid-main': {
            minHeight: `calc(56px + ${defaultPageSize} * 52px)`,
          },
        }
      : {}),
    '& .MuiDataGrid-row:nth-of-type(even)': {
      backgroundColor: AppColors.GREY6,
    },
    '& .MuiDataGrid-row:hover': {
      backgroundColor: AppColors.GREEN5,
    },
    '& .MuiDataGrid-cell': {
      color: AppColors.DARK_GREY,
      fontSize: '12px',
    },
    '& .MuiDataGrid-row': {
      cursor: !isNil(dataGridProps.onRowClick) ? 'pointer' : 'auto',
    },
    ...sx,
  };

  return (
    <Stack spacing={3}>
      <OmniGridConfirmModal {...modalControl} />
      {!isNil(headerProps?.title) && headerProps?.title !== undefined ? <OmniGridHeader {...headerProps} /> : null}
      <DataGridPremium
        /*
         * Overridable props and defaults
         */
        apiRef={apiRef}
        disableColumnPinning
        disableColumnSelector
        disableDensitySelector
        disableRowGrouping
        filterMode="server"
        pagination
        paginationMode="server"
        rowGroupingColumnMode="single"
        sortingMode="server"
        /*
         * Overrides and additional props
         */
        {...restDataGridProps}
        /*
         * Non-Overridable props
         */
        experimentalFeatures={{ newEditingApi: true }}
        columns={modifiedColumns}
        error={error}
        initialState={initialState}
        loading={loading}
        onFilterModelChange={onFilterModelChange}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        onSortModelChange={onSortModelChange}
        page={page}
        pageSize={pageSize}
        rowCount={rowCount}
        rows={rows}
        rowsPerPageOptions={DEFAULT_PAGE_SIZES}
        filterModel={filterModel}
        sortModel={sortModel}
        sx={dataGridStyles}
        components={{ Toolbar: GridToolbar }}
        componentsProps={{
          toolbar: {
            csvOptions: { disableToolbarButton: true },
            excelOptions: { disableToolbarButton: true },
            printOptions: { disableToolbarButton: true },
            quickFilterProps: { debounceMs: 500 },
            showQuickFilter,
          },
        }}
      />
    </Stack>
  );
}
