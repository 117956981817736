import { type ApolloError } from '@apollo/client';
import { createContext, useContext } from 'react';

interface DetailPanelContextValue {
  error: ApolloError | undefined;
  loading: boolean;
  updates: number[];
}

const initialValue = {
  error: undefined,
  loading: false,
  updates: [],
};

export const DetailPanelContext = createContext<DetailPanelContextValue>(initialValue);

export const useDetailPanelContext = () => useContext(DetailPanelContext);
