import { ChangeEvent } from 'react';
import {
  MenuItem,
  IconButton,
  SelectChangeEvent,
  Typography,
  Box,
} from '@mui/material';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import {
  ArrowUpward,
  ArrowDownward,
  Search as MuiSearch,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import replace from 'lodash/replace';

import { TabButton } from '#components/widgets';
import { Can, useExperimentTrackerContext } from '#components/contexts';
import {
  HOME_PATH,
  CARDS_LIST_PATH,
  CARDS_ARCHIVE_PATH,
  CARDS_DRAFTS_PATH,
  CREATE_EXPERIMENT_PATH,
  CREATE_EXPERIMENT_ROUTE,
} from '#lib/constants';
import {
  StyledContainer,
  StyledSection,
  StyledItemsSection,
  StyledAgendaIcon,
  StyledMenuIcon,
  StyledFolderIcon,
  StyledCreateIcon,
  StyledTextField,
  StyledSelect,
  StyledButton,
} from './styles';
import { debounce } from 'lodash';

type tabOptions = [name: string, path: string, icon: EmotionJSX.Element];

export const ExperimentTrackerHeader = () => {
  const { query, setQuery, filter, setFilter, sort, setSort } =
    useExperimentTrackerContext();
  const navigate = useNavigate();

  const isKanBan = location.pathname === HOME_PATH;

  // If the filterOptions on the list data grids should also have ADC and Bioanalysis filters, work will need to be done in resolver to handle those filters.
  let filterOptions: Record<string, string>[] = [
    {
      name: 'All Experiments',
      value: 'allExperiments',
    },
    {
      name: 'CRO Only',
      value: 'croOnly',
    },
    {
      name: 'MTA Only',
      value: 'mtaOnly',
    },
  ];

  if (isKanBan) {
    filterOptions = [
      ...filterOptions,
      ...[
        {
          name: 'ADC Fulfilled Orders',
          value: 'adcFulfilled',
        },
        {
          name: 'ADC Unfulfilled Orders',
          value: 'adcUnfulfilled',
        },
        {
          name: 'Bioanalysis included',
          value: 'hasBioanalysis',
        },
        {
          name: 'Bioanalysis not included',
          value: 'hasNotBioanalysis',
        },
      ],
    ];
  }

  const sortOptions: Record<string, string | EmotionJSX.Element>[] = [
    ['Submitted Date', 'submissionDate'],
    ['Updated Date', 'updatedDate'],
    ['Experiment Name', 'approvalName'],
    ['Experiment Title', 'name'],
    ['IACUC', 'IACUC'],
  ].flatMap(([name, value]) => [
    {
      name,
      value: `${value}Asc`,
      icon: <ArrowUpward fontSize="small" />,
    },
    {
      name,
      value: `${value}Desc`,
      icon: <ArrowDownward fontSize="small" />,
    },
  ]);

  const changeHandler = debounce((ev: ChangeEvent<HTMLInputElement>) => {
    setQuery(ev.target.value);
  }, 500);

  const changeFilterOption = (ev: SelectChangeEvent<unknown>) => {
    setFilter(ev.target.value as string);
  };

  const changeSortOption = (ev: SelectChangeEvent<unknown>) => {
    setSort(ev.target.value as string);
  };

  const onCreateNewExperiment = () =>
    navigate(CREATE_EXPERIMENT_PATH, {
      state: { origin: location.pathname },
    });

  const tabOptions: tabOptions[] = [
    [
      'Board',
      `${HOME_PATH}?q=${query}&f=${filter}&s=${sort}`,
      <StyledAgendaIcon />,
    ],
    ['List', CARDS_LIST_PATH, <StyledMenuIcon />],
    ['Archive', CARDS_ARCHIVE_PATH, <StyledFolderIcon />],
    ['Drafts', CARDS_DRAFTS_PATH, <StyledCreateIcon />],
  ];

  return (
    <StyledContainer>
      <Box
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
        gap={2}
        height="60px"
      >
        <Typography variant="h1">Experiment tracker</Typography>
        <StyledItemsSection>
          {tabOptions.map(([name, path, icon], index) => (
            <TabButton
              data-test-id={`tab${replace(name, ' ', '')}`}
              key={index}
              path={path}
            >
              {icon}
              {name}
            </TabButton>
          ))}
        </StyledItemsSection>
      </Box>

      <StyledSection customGap="5px">
        {location.pathname !== CREATE_EXPERIMENT_ROUTE && (
          <>
            <Search
              defaultValue={query}
              display={isKanBan}
              onChange={changeHandler}
            />
            <Filter
              display={isKanBan}
              filterOptions={filterOptions}
              isplaceholder={filter}
              onChange={changeFilterOption}
              value={filter}
            />
            <Sort
              display={isKanBan}
              isplaceholder={sort}
              onChange={changeSortOption}
              sortOptions={sortOptions}
              value={sort}
            />
            <Can I="create" an="experiment">
              <StyledButton
                data-test-id={`createExperiment`}
                onClick={onCreateNewExperiment}
              >
                <AddOutlinedIcon /> Create Experiment
              </StyledButton>
            </Can>
          </>
        )}
      </StyledSection>
    </StyledContainer>
  );
};

function Filter({
  display,
  filterOptions,
  isplaceholder,
  onChange,
  value,
}: {
  display: boolean;
  filterOptions: Record<string, string>[];
  isplaceholder: string;
  onChange?: (
    event: SelectChangeEvent<unknown>,
    child: React.ReactNode
  ) => void;
  value: string;
}) {
  if (!display) return <></>;
  return (
    <StyledSelect
      value={value}
      onChange={onChange}
      displayEmpty
      autoWidth={false}
      isplaceholder={isplaceholder}
      data-test-id={`trackerFilters`}
    >
      <MenuItem value={`1`} disabled>
        Filter
      </MenuItem>
      {filterOptions.map((opt, index) => (
        <MenuItem key={index} value={opt.value}>
          {opt.name}
        </MenuItem>
      ))}
    </StyledSelect>
  );
}

function Search({
  defaultValue,
  display,
  onChange,
}: {
  defaultValue: string;
  display?: boolean;
  onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
}) {
  if (!display) return <></>;
  return (
    <StyledTextField
      placeholder={`Search`}
      type={`search`}
      defaultValue={defaultValue}
      onChange={onChange}
      InputProps={{
        endAdornment: (
          <IconButton name={`Search`} aria-label={`Search`}>
            <MuiSearch />
          </IconButton>
        ),
      }}
    />
  );
}

function Sort({
  display,
  isplaceholder,
  onChange,
  sortOptions,
  value,
}: {
  display: boolean;
  isplaceholder: string;
  onChange?: (
    event: SelectChangeEvent<unknown>,
    child: React.ReactNode
  ) => void;
  sortOptions: Record<string, string | EmotionJSX.Element>[];
  value: string;
}) {
  if (!display) return <></>;
  return (
    <StyledSelect
      value={value}
      width={`200px`}
      onChange={onChange}
      displayEmpty
      autoWidth={false}
      isplaceholder={isplaceholder}
    >
      <MenuItem value={`1`} disabled>
        Sort
      </MenuItem>
      {sortOptions.map(({ value, name, icon }, index) => (
        <MenuItem key={index} value={value as string}>
          {name as string} {icon}
        </MenuItem>
      ))}
    </StyledSelect>
  );
}
