import styled from '@emotion/styled';

import { type ExperimentDetailsFragment } from '#graphql';
import { AppColors } from '#lib/constants';
import { WorksheetThumbnail, type WorksheetThumbnailWorksheet } from './WorksheetThumbnail';

const WorksheetsColContainer = styled.div({
  label: 'WorksheetsColContainer',
  display: 'flex',
  flexDirection: 'column',
  background: AppColors.WHITE,
  padding: '1em',
  borderRadius: '4px',
  gap: '1em',
});

const WorksheetsListContainer = styled.div({
  label: 'WorksheetsListContainer',
  display: 'flex',
  flexWrap: 'wrap',
  gap: '1em',
  minHeight: '90px',
});

interface WorksheetsProps {
  worksheets: WorksheetThumbnailWorksheet[];
  models: ExperimentDetailsFragment['models'];
}

// TODO: Update worksheets type when data is in database.
export const Worksheets = ({ worksheets, models }: WorksheetsProps) => {
  return (
    <WorksheetsColContainer>
      <WorksheetsListContainer>
        {worksheets.map((worksheet, index) => (
          <WorksheetThumbnail key={index} worksheet={worksheet} models={models} />
        ))}
      </WorksheetsListContainer>
    </WorksheetsColContainer>
  );
};
