import { Box, Typography } from '@mui/material';
import { AppColors } from '@omnivivo/style';
import { TabButton } from '#components/widgets';

interface NavLinks {
  name: string;
  path: string;
}
interface HeaderProps {
  actions?: React.ReactChild;
  navLinks?: NavLinks[];
  title: string;
}

export function Header({ actions, navLinks = [], title }: HeaderProps) {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      paddingY={2}
      paddingX={4}
      borderBottom={`1px solid ${AppColors.MERCURY}`}
      component="header"
    >
      <Box display="flex" gap={2} alignItems="center">
        <Typography variant="h1" sx={{ marginRight: '20px' }}>
          {title}
        </Typography>
        <Box display="flex">
          {navLinks.map(({ name, path }, index) => (
            <TabButton data-test-id={`tab${name}`} key={index} path={path}>
              {name}
            </TabButton>
          ))}
        </Box>
      </Box>
      <Box>{actions}</Box>
    </Box>
  );
}
