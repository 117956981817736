import { isUndefined } from 'lodash';

import {
  useBloodBioanalysisQuery,
  useExperimentByIdQuery,
  useListContentsQuery,
  usePkBloodWorksheetWithCollectionsQuery,
} from '#graphql';

export function usePKBloodWorksheetPage(experimentId: number) {
  const experimentQuery = useExperimentByIdQuery({ variables: { id: experimentId }, fetchPolicy: 'cache-and-network' });
  const pkBloodWorksheetQuery = usePkBloodWorksheetWithCollectionsQuery({
    variables: { experimentId },
    fetchPolicy: 'cache-and-network',
    pollInterval: 5000,
  });
  const bloodBioanalysisQuery = useBloodBioanalysisQuery({
    variables: { experimentId },
    fetchPolicy: 'cache-and-network',
  });

  const bioanalysisTimepointsQuery = useListContentsQuery({
    variables: {
      category: 'bioanalysisTimepoint',
    },
    fetchPolicy: 'cache-and-network',
  });

  const error =
    experimentQuery.error ??
    pkBloodWorksheetQuery.error ??
    bloodBioanalysisQuery.error ??
    bioanalysisTimepointsQuery.error;

  const loading =
    experimentQuery.loading ||
    pkBloodWorksheetQuery.loading ||
    bloodBioanalysisQuery.loading ||
    bioanalysisTimepointsQuery.loading;

  // TODO - clean this up
  const data =
    isUndefined(experimentQuery.data) ||
    isUndefined(experimentQuery.data.experiment) ||
    isUndefined(pkBloodWorksheetQuery.data) ||
    isUndefined(pkBloodWorksheetQuery.data.pkBloodWorksheet) ||
    isUndefined(bloodBioanalysisQuery.data) ||
    isUndefined(bloodBioanalysisQuery.data.bloodBioanalysis) ||
    isUndefined(bioanalysisTimepointsQuery.data) ||
    isUndefined(bioanalysisTimepointsQuery.data.listContents.items)
      ? undefined
      : ([
          experimentQuery.data.experiment,
          pkBloodWorksheetQuery.data.pkBloodWorksheet,
          bloodBioanalysisQuery.data.bloodBioanalysis,
          bioanalysisTimepointsQuery.data.listContents.items,
        ] as const);

  return {
    data,
    error,
    loading,
  };
}
