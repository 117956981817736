import React, { useState } from 'react';
import styled from '@emotion/styled';
import { TextField, Divider } from '@mui/material';

import { AppColors } from '#lib/constants';
import { ButtonNeutralSolid, ButtonPositive } from '#components/widgets';

const StyledContainer = styled.div({
  padding: '1em',
});

const StyledTextField = styled(TextField)({
  width: '54rem',
  div: {
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: '14px',
    letterSpacing: '0.5px',
    color: 'rgba(0, 0, 0, 0.6)',
  },
});

const StyledDivider = styled(Divider)({
  margin: '1em 0',
});

const ButtonsContainer = styled.div({
  display: 'flex',
  justifyContent: 'center',
});

const CancelButton = styled(ButtonNeutralSolid)({
  background: AppColors.WHITE,
  border: `2px solid ${AppColors.BLACK}`,
  borderRadius: '28px',
  color: AppColors.BLACK,
  letterSpacing: '1.25px',
  textTransform: 'uppercase',
  fontSize: '10px',
  fontWeight: '500',
  fontStyle: 'normal',
  fontFamily: 'Roboto',
  padding: '10px',
  width: '100px',
  marginRight: '10px',
  '&:hover': {
    background: AppColors.WHITE,
  },
});

const StyledButton = styled(ButtonPositive)({
  borderRadius: '28px',
  color: AppColors.BRAND_GREEN,
  letterSpacing: '1.25px',
  textTransform: 'uppercase',
  fontSize: '10px',
  fontWeight: 'bold',
  fontStyle: 'normal',
  fontFamily: 'Roboto',
  padding: '10px',
  width: '100px',
  marginBottom: '25px',
  marginLeft: '10px',
});

export const NotesModalContent = ({
  onClose,
  onSave,
  value = '',
  keyName,
  disabled,
}: {
  onClose: () => void;
  onSave: ({}, closeModal: () => void) => void;
  value?: string;
  keyName: string;
  disabled: boolean;
}) => {
  const [note, setNote] = useState({ [keyName]: value });
  return (
    <StyledContainer>
      <StyledTextField
        defaultValue={value}
        inputProps={{ maxLength: 1000 }}
        disabled={disabled}
        onChange={(ev: React.ChangeEvent<HTMLInputElement>) =>
          setNote({ [keyName]: ev.target.value })
        }
        rows={20}
        multiline
      />
      <StyledDivider />
      <ButtonsContainer>
        <CancelButton onClick={onClose}>Close</CancelButton>
        <StyledButton disabled={disabled} onClick={() => onSave(note, onClose)}>
          Save
        </StyledButton>
      </ButtonsContainer>
    </StyledContainer>
  );
};
