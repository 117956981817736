import styled from '@emotion/styled';
import { isNil, map, mapValues } from 'lodash';
import { useEffect } from 'react';
import { Controller, FormProvider, useController, useForm } from 'react-hook-form';

import { Can } from '#components/contexts';
import { Attachments } from '#components/partials';
import { type ExperimentState, type ExperimentDetailsFragment } from '#graphql';
import { AppColors, ATTACHMENT_CATEGORY_OVERVIEW, i18n } from '#lib/constants';
import { useUpdateExperiment } from '#lib/hooks';
import {
  AssociatedUsers,
  ExperimentDetails,
  GeneralDetails,
  GroupCard,
  ModelCard,
  TreatmentCard,
} from './partials';
import { BloodBioanalysisSection } from './partials/BloodBioanalysis';
import { ImagingSection } from './partials/Imaging';
import { TissueBioanalysisSection } from './partials/TissueBioanalysis';

const StyledContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  width: '100%',
  margin: '0 auto',
  maxWidth: '1339px',
  gap: '10px',
  marginBottom: '50px',
});

const StyledTitle = styled.h4({
  fontSize: '14px',
  lineHeight: '24px',
  letterSpacing: '0.15px',
  fontWeight: 'bold',
  fontFamily: 'Roboto',
  margin: '10px 0',
});

const StyledSeparator = styled.div({
  background: AppColors.LIGHT_GREY,
  height: '1px',
  marginTop: '18px',
  marginBottom: '2px',
});

const StyledError = styled.div({
  color: AppColors.RED,
});

export const ConfigureOverview = ({ experiment }: { experiment: ExperimentDetailsFragment }) => {
  const methods = useForm({
    defaultValues: {
      ...mapValues(experiment, (value) => value ?? ''),
      status: experiment.status,
      modelCount: experiment?.models?.length ?? 0,
      treatmentCount: experiment?.treatments?.length ?? 0,
      treatmentGroupCount: experiment?.treatmentGroups?.length ?? 0,
    },
  });
  const { saveExperiment } = useUpdateExperiment();
  async function onSubmit<T extends { status: ExperimentState }>(data: T) {
    await saveExperiment({
      status: data.status,
      approvalName: experiment.approvalName,
      id: experiment.id,
    });
  };
  useController({
    name: 'purposes',
    rules: { required: i18n.ValidateExperiment.NoPurposes },
    control: methods.control,
  });
  useController({
    name: 'designMethods',
    rules: { required: i18n.ValidateExperiment.NoDesignMethods },
    control: methods.control,
  });
  useController({
    name: 'endpoints',
    rules: { required: i18n.ValidateExperiment.NoEndpoints },
    control: methods.control,
  });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const hasErrors = !(Object.keys(methods.formState.errors).length === 0);

  const treatmentGroupsWithTreatments = experiment.treatmentGroups.map((tg) => ({
    ...tg,
    /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion --
     * Treatment groups must have treatments from the same experiment
    **/
    treatment: experiment.treatments.find((t) => t.id === tg.treatmentId)!,
  }));

  const displayNewBioanalysis =
    process.env.REACT_APP_FEATURE_BIOANALYSIS === 'true' &&
    isNil(experiment.worksheets?.find((worksheet) => worksheet.type === 'bioanalysis'));

  const displayNewImaging =
    process.env.REACT_APP_FEATURE_BIOANALYSIS === 'true' &&
    isNil(experiment.worksheets?.find((worksheet) => worksheet.type === 'imaging'));

  return (
    <StyledContainer>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          {hasErrors && (
            <StyledError>
              <h2>{i18n.ValidateExperiment.Main}</h2>
              {map(methods.formState.errors, (value, field) => (
                <div key={field} data-test-id="experiment-validation-error">
                  {value?.message}
                </div>
              ))}
            </StyledError>
          )}
          <GeneralDetails experiment={experiment} />
          <StyledSeparator />

          <ExperimentDetails experiment={experiment} />
          <StyledSeparator />

          <StyledTitle>Define Models*</StyledTitle>
          <Controller
            control={methods.control}
            name="modelCount"
            rules={{
              min: { value: 1, message: i18n.ValidateExperiment.NoModels },
            }}
            render={({ field: { onChange } }) => (
              <Can I="update" this={experiment} field="models" passThrough>
                {(allowed) => (
                  <ModelCard
                    experimentId={experiment.id}
                    models={experiment.models}
                    onModelCountChanged={onChange}
                    invalid={!isNil(methods.formState.errors?.modelCount)}
                    disabled={!allowed}
                  />
                )}
              </Can>
            )}
          />

          <StyledSeparator />

          <StyledTitle>Define Treatments*</StyledTitle>
          <Can I="update" this={experiment} field="treatments" passThrough>
            {(allowed) => (
              <TreatmentCard
                treatments={experiment.treatments}
                experiment={experiment}
                disabled={!allowed}
              />
            )}
          </Can>
          <StyledSeparator />

          <StyledTitle>Configure Groups*</StyledTitle>
          <Controller
            control={methods.control}
            name="treatmentGroupCount"
            rules={{
              min: {
                value: 1,
                message: i18n.ValidateExperiment.NoTreatmentGroups,
              },
            }}
            render={({ field: { onChange } }) => (
              <Can I="update" this={experiment} field="treatmentGroups" passThrough>
                {(allowed) => (
                  <GroupCard
                    experiment={experiment}
                    onTreatmentGroupCountChanged={onChange}
                    invalid={!isNil(methods.formState.errors?.treatmentGroupCount)}
                    disabled={!allowed}
                  />
                )}
              </Can>
            )}
          />
        </form>
      </FormProvider>
      <StyledSeparator />

      {displayNewBioanalysis && (
        <>
          <StyledTitle>Blood Bioanalysis</StyledTitle>
          <Can I="update" this={experiment} field="bloodBioanalysis" passThrough>
            {(canEditBloodBioanalysis) => (
              <BloodBioanalysisSection
                bloodBioanalysis={experiment.bloodBioanalysis}
                canEdit={canEditBloodBioanalysis}
                experimentId={experiment.id}
                treatmentGroups={treatmentGroupsWithTreatments}
              />
            )}
          </Can>

          <StyledSeparator />

          <StyledTitle>Tissue Bioanalysis</StyledTitle>
          <Can I="update" this={experiment} field="tissueBioanalysis" passThrough>
            {(canEditTissueBioanalysis) => (
              <TissueBioanalysisSection
                tissueBioanalysis={experiment.tissueBioanalysis}
                canEdit={canEditTissueBioanalysis}
                experimentId={experiment.id}
                treatmentGroups={treatmentGroupsWithTreatments}
              />
            )}
          </Can>

          <StyledSeparator />
        </>
      )}

      {displayNewImaging && (
        <>
          <StyledTitle>Imaging</StyledTitle>
          <Can I="update" this={experiment} field="imaging" passThrough>
            {(canEditImaging) => (
              <ImagingSection
                imaging={experiment.imaging}
                canEdit={canEditImaging}
                experimentId={experiment.id}
                treatmentGroups={treatmentGroupsWithTreatments}
              />
            )}
          </Can>

          <StyledSeparator />
        </>
      )}

      <StyledTitle>Attachments</StyledTitle>
      <Can I="update" this={experiment} field="attachments" passThrough>
        {(canEditAttachment) => (
          <Attachments
            experimentId={experiment.id}
            canEdit={canEditAttachment}
            attachments={experiment.attachments ?? []}
            category={ATTACHMENT_CATEGORY_OVERVIEW}
          />
        )}
      </Can>
      {experiment.MTA && (
        <Can I="update" this={experiment} field="team" passThrough>
          {(allowed) => (
            <>
              <StyledSeparator />
              <StyledTitle>Associated Users</StyledTitle>
              <AssociatedUsers
                experimentId={experiment.id}
                team={experiment.team}
                editable={allowed}
              />
            </>
          )}
        </Can>
      )}
    </StyledContainer>
  );
};
