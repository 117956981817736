import {
  addEntryLocal,
  bulkAddEntriesLocal,
  deleteEntryLocal,
  getColumnIdsBySectionId,
  getEntriesToCreateToAddColumn,
  getEntriesToCreateToAddRow,
  getEntriesToDeleteToDeleteColumn,
  getEntriesToDeleteToDeleteRow,
  getRowIdsBySectionId,
  getSectionById,
  getWorksheetEntriesBySectionId,
  updateAllCalcs,
  updateValueLocal,
  type NewWorksheetEntry,
  type WorksheetDefinition,
  type WorksheetEntriesBySectionId,
  type WorksheetEntry,
  type WorksheetEntryDeleterProps,
  type WorksheetFieldUpdateProps,
} from '@omnivivo/worksheets-core';
import { isNil } from 'lodash';
import isEqual from 'lodash/isEqual';
import { useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';

import { i18n } from '#lib/constants';
import { type State, type WorksheetBodyProps } from './WorksheetComponentInterfaces';

export interface WorksheetState {
  entriesBySectionId: WorksheetEntriesBySectionId;
}

const calcState = (definition: WorksheetDefinition, rawEntries: WorksheetEntry[]): State => {
  const entries = updateAllCalcs(definition, rawEntries);
  const entriesBySectionId = getWorksheetEntriesBySectionId(definition, entries);
  const rowIdsBySectionId = getRowIdsBySectionId(entriesBySectionId);
  const columnIdsBySectionId = getColumnIdsBySectionId(entriesBySectionId);
  return {
    rawEntries,
    entries,
    entriesBySectionId,
    rowIdsBySectionId,
    columnIdsBySectionId,
  };
};

interface GetMutationsProps extends WorksheetBodyProps {
  setState: (updateStateFunction: (state: State) => State) => any;
}

const getMutations = ({
  setState,
  worksheet,
  updateValue: apiUpdateValue,
  deleteEntry: apiDeleteEntry,
  addEntry: apiAddEntry,
  bulkAddEntries: apiBulkAddEntries,
  bulkDeleteEntries: apiBulkDeleteEntries,
}: GetMutationsProps) =>
  // We need useMemo so we only create these functions once; subsequent
  // renders will use exactly the same functions. All this is needed so nested components'
  // React.memo() equality tests don't get triggered because of these functions.
  useMemo(() => {
    //* *********************************************************
    // HELPERS
    //* *********************************************************
    const setEntriesAsync = async (
      asyncStateUpdater: (state: State) => Promise<WorksheetEntry[]>
    ): Promise<WorksheetEntry[]> => {
      return await new Promise((resolve) => {
        setState((state) => {
          setTimeout(() => {
            // guarantee the inner setState gets called after the outer setState is finished
            void asyncStateUpdater(state).then((entries) => {
              setState(() => calcState(worksheet.definition, entries));
              resolve(entries);
            });
          }, 0);
          return state;
        });
      });
    };

    const handleErrors = async (
      writePromise: Promise<any>,
      restoreStateOnFailureAsyncStateUpdater?: (state: State) => WorksheetEntry[],
      returnFailedPromise = true
    ) => {
      return await writePromise.catch((error) => {
        toast.error(`${i18n.Worksheets.WorksheetEntrySaveFailed}: ${error.message}`);
        console.error({
          WorksheetBodyState: `worksheet entry mutation API request error: ${error.message}`,
          error,
        });
        if (!isNil(restoreStateOnFailureAsyncStateUpdater)) {
          void setEntriesAsync(async (state) => await Promise.resolve(restoreStateOnFailureAsyncStateUpdater(state)));
        }
        if (returnFailedPromise) throw error;
      });
    };

    const bulkCreate = async (currentEntries: WorksheetEntry[], entriesToAdd: NewWorksheetEntry[]) => {
      const createdEntries: WorksheetEntry[] = await handleErrors(apiBulkAddEntries(entriesToAdd));
      return bulkAddEntriesLocal(currentEntries, createdEntries);
    };

    const bulkDelete = async (currentEntries: WorksheetEntry[], entriesToDelete: WorksheetEntry[]) => {
      const entriesToDeletedMap: Record<string, boolean> = {};
      entriesToDelete.forEach(({ id, sectionId }) => (entriesToDeletedMap[`${id}-${sectionId}`] = true));
      await handleErrors(
        apiBulkDeleteEntries(entriesToDelete.map(({ id }) => id)),
        (state) => [
          ...state.entries.filter(({ id, sectionId }) => !entriesToDeletedMap[`${id}-${sectionId}`]),
          ...entriesToDelete,
        ],
        false
      );
      return currentEntries.filter(({ id, sectionId }) => !entriesToDeletedMap[`${id}-${sectionId}`]);
    };

    //* *********************************************************
    // SINGLETON CREATE-UPDATE-DELETE
    //* *********************************************************
    const addEntry = async (newEntry: NewWorksheetEntry) =>
      await setEntriesAsync(async ({ entries }) =>
        addEntryLocal(entries, await handleErrors(apiAddEntry(newEntry)))
      ).then(([newEntry]) => newEntry);

    // The below exceptions are necessary as the UI encounters issues when the recommended changes are made
    // dcorwin 12-19-2023
    // eslint-disable-next-line @typescript-eslint/promise-function-async
    const updateValue = (updateProps: WorksheetFieldUpdateProps) => {
      // eslint-disable-next-line @typescript-eslint/require-await
      return setEntriesAsync(async ({ entries }) => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        handleErrors(
          apiUpdateValue(updateProps),
          (state) => {
            const previousEntry = entries.find(
              ({ id, sectionId }) => id === updateProps.entryId && sectionId === updateProps.sectionId
            );

            return updateValueLocal(state.entries, {
              ...updateProps,
              value: previousEntry?.data[updateProps.fieldId],
            });
          },
          false
        );
        return updateValueLocal(entries, updateProps);
      });
    };

    const deleteEntry = async (deleteProps: WorksheetEntryDeleterProps) =>
      await setEntriesAsync(async ({ entries }) => {
        const oldEntry = entries.find(
          ({ id, sectionId }) => id === deleteProps.entryId && sectionId === deleteProps.sectionId
        );
        if (!isNil(oldEntry))
          await handleErrors(
            apiDeleteEntry(deleteProps),
            (state) => [
              ...state.entries.filter((entry) => entry.id !== oldEntry.id || entry.sectionId !== deleteProps.sectionId),
              oldEntry,
            ],
            false
          );

        return deleteEntryLocal(entries, deleteProps);
      });

    //* *********************************************************
    // ROW/COLUMN CREATE-UPDATE-DELETE
    //* *********************************************************

    const addColumn = async (newEntry: NewWorksheetEntry) =>
      await setEntriesAsync(async ({ entries, rowIdsBySectionId, entriesBySectionId }) => {
        const sectionId = newEntry.sectionId;
        if (isNil(sectionId)) {
          return [];
        }

        const sectionIds = getSectionById(worksheet.definition.sections, sectionId)?.addRowToSectionIds ?? [sectionId];

        let entriesToAdd: NewWorksheetEntry[] = [];
        for (const id of sectionIds) {
          entriesToAdd = entriesToAdd.concat(
            getEntriesToCreateToAddColumn(
              worksheet.id,
              worksheet.definition,
              rowIdsBySectionId[id],
              entriesBySectionId[id],
              newEntry,
              id
            )
          );
        }
        return await bulkCreate(entries, entriesToAdd);
      });

    const addRow = async (newEntry: NewWorksheetEntry) =>
      await setEntriesAsync(async ({ entries, columnIdsBySectionId, entriesBySectionId }) => {
        const sectionId = newEntry.sectionId;

        if (isNil(sectionId)) {
          return [];
        }

        if (entriesBySectionId[sectionId].length === 0) return await addColumn(newEntry);

        return await bulkCreate(
          entries,
          getEntriesToCreateToAddRow(
            worksheet.id,
            columnIdsBySectionId[sectionId],
            entriesBySectionId[sectionId],
            newEntry
          )
        );
      });

    const deleteRow = async (sectionId: string, rowId: string) =>
      await setEntriesAsync(
        async ({ entries }) => await bulkDelete(entries, getEntriesToDeleteToDeleteRow(sectionId, rowId, entries))
      );

    const deleteColumn = async (columnSectionId: string, sectionId: string, columnId: string) =>
      await setEntriesAsync(
        async ({ entries }) =>
          await bulkDelete(entries, [
            ...getEntriesToDeleteToDeleteColumn(sectionId, columnId, entries),
            ...getEntriesToDeleteToDeleteColumn(columnSectionId, columnId, entries),
          ])
      );

    //* *********************************************************
    // RETURN ALL MUTATION-FUNCTIONS
    //* *********************************************************
    return {
      addColumn,
      addRow,
      addEntry,
      updateValue,
      deleteEntry,
      deleteRow,
      deleteColumn,
    };
  }, []);

export const useWorksheetState = (props: WorksheetBodyProps) => {
  const { entries: propsEntries } = props;

  const worksheet = useMemo(() => props.worksheet, []);

  const calcStateFromPropsEntries = () => calcState(worksheet.definition, propsEntries);

  const [state, setState] = useState(calcStateFromPropsEntries);

  // update state if new state comes in as props
  const [initialEntries, setInitialEntries] = useState(propsEntries);
  useEffect(() => {
    if (!isEqual(propsEntries, initialEntries)) {
      setState(calcStateFromPropsEntries);
      setInitialEntries(propsEntries);
    }
  }, [propsEntries]);

  const getState: () => Promise<State> = useMemo(() => {
    return async () => {
      return await new Promise((resolve) => {
        setState((currentState) => {
          resolve(currentState);
          return currentState;
        });
      });
    };
  }, []);

  return {
    worksheet,
    ...state,
    worksheetMutations: getMutations({ setState, ...props }),
    getWorksheetState: getState,
  };
};
