import styled from '@emotion/styled';
import { Button, ButtonProps } from '@mui/material';
import { AppColors } from '#lib/constants';
import { useLocation, useNavigate } from 'react-router';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

type ButtonPolarity = 'positive' | 'negative' | 'neutral';
type ButtonSize = 'tiny' | 'small' | 'medium' | 'big' | 'huge';

type AppButtonProps = {
  solid?: string;
  polarity?: ButtonPolarity;
  customsize?: ButtonSize;
} & ButtonProps;

const ButtonStyled = styled(Button)(
  ({
    solid,
    polarity = 'neutral',
    customsize = 'medium',
    disabled = false,
  }: AppButtonProps) => {
    const baseColor = polarityColorMap[polarity];
    let background;
    let borderColor;
    let color;

    if (solid === 'true') {
      background = baseColor;
      borderColor = 'initial';
      color = AppColors.WHITE;
    } else {
      background = AppColors.WHITE;
      borderColor = baseColor;
      color = baseColor;
    }
    return {
      boxSizing: 'border-box',
      height: sizeHeightMap[customsize],
      borderRadius: sizeBorderRadiusMap[customsize],
      fontSize: sizeFontSizeMap[customsize],
      background,
      borderColor,
      color,
      filter: disabled ? 'brightness(0.5)' : 'none',
      padding: '1%',
    };
  }
);

const ButtonStyledInner = styled.div({
  display: 'flex',
  gap: '4px',
  boxSizing: 'border-box',
  height: '100%',
  padding: '4px',
  fontSize: '14px',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
});

const ButtonPositiveStyled = styled(Button)(({ disabled }: ButtonProps) => ({
  height: '28px',
  background: 'white',
  boxSizing: 'border-box',
  fontWeight: '600',
  fontSize: '12px',
  letterSpacing: '1.25px',
  border: '2px solid',
  borderColor: disabled ? AppColors.GREEN3 : AppColors.BRAND_GREEN,
  borderRadius: '28px',
  color: disabled ? AppColors.GREEN3 : AppColors.BRAND_GREEN,
}));

const ButtonPositiveTableStyled = styled(Button)(
  ({ disabled }: ButtonProps) => ({
    height: '28px',
    background: 'white',
    boxSizing: 'border-box',
    fontWeight: '600',
    fontSize: '12px',
    letterSpacing: '1.25px',
    border: '2px solid',
    borderColor: disabled ? AppColors.GREEN3 : AppColors.BRAND_GREEN,
    borderRadius: '28px',
    color: disabled ? AppColors.GREEN3 : AppColors.BRAND_GREEN,
  })
);

const ButtonPositiveSolidStyled = styled(Button)(
  ({ disabled }: ButtonProps) => ({
    height: '28px',
    background: disabled ? AppColors.GREEN3 : AppColors.BRAND_GREEN,
    borderRadius: '28px',
    color: 'white',
  })
);

const ButtonNeutralStyled = styled(Button)(({ disabled }: ButtonProps) => ({
  height: '28px',
  background: 'white',
  boxSizing: 'border-box',
  border: '2px solid',
  borderRadius: '28px',
  borderColor: disabled ? AppColors.LIGHT_GREY : AppColors.BLACK,
  color: disabled ? AppColors.LIGHT_GREY : AppColors.BLACK,
}));

const ButtonNeutralSolidStyled = styled(Button)(
  ({ disabled }: ButtonProps) => ({
    height: '28px',
    background: disabled ? AppColors.LIGHT_GREY : AppColors.BLACK,
    borderRadius: '28px',
    color: AppColors.WHITE,
  })
);

const ButtonTinySolidPositiveStyled = styled(Button)({
  height: '18px',
  background: AppColors.BRAND_GREEN,
  borderRadius: '4px',
  color: AppColors.WHITE,
});

const ButtonSmallSolidPositiveStyled = styled(Button)({
  height: '24px',
  borderRadius: '4px',
  background: AppColors.BRAND_GREEN,
  color: AppColors.WHITE,
});

const ButtonSmallSolidNegativeStyled = styled(Button)({
  height: '24px',
  borderRadius: '4px',
  background: AppColors.DARK_RED,
  colors: AppColors.WHITE,
});

const NavButtonStyled = styled.div(
  ({ path, currentPath, tab = false }: NavButtonProps) => {
    const selectionProps =
      currentPath === path
        ? {
            background: AppColors.GREEN2,
            color: AppColors.WHITE,
            display: 'flex',
          }
        : {
            color: AppColors.GREY,
          };
    return {
      cursor: 'pointer',
      minWidth: '110px',
      minHeight: '33px',
      background: AppColors.LIGHT_GREY,
      borderRadius: '10px 10px 0 0',
      fontStyle: 'normal',
      fontFamily: 'Roboto',
      marginLeft: '3px',
      marginRight: '15px',
      fontSize: '14px',
      fontWeight: '500',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      ...selectionProps,
    };
  }
);

const polarityColorMap = {
  positive: AppColors.BRAND_GREEN,
  negative: AppColors.DARK_RED,
  neutral: AppColors.LIGHT_GREY,
};

const sizeHeightMap = {
  tiny: '18px',
  small: '24px',
  medium: '28px',
  big: '32px',
  huge: '40px',
};

const sizeBorderRadiusMap = {
  tiny: '4px',
  small: '4px',
  medium: '28px',
  big: '32px',
  huge: '40px',
};

const sizeFontSizeMap = {
  tiny: '10px',
  small: '10px',
  medium: '12px',
  big: '12px',
  huge: '16px',
};

const ButtonArrowTableStyled = styled(ArrowForwardIosIcon)({
  height: '28px',
  fontWeight: '900',
  color: '#6F6F6F',
  fontSize: '18px',
  cursor: 'pointer',
});

export const AppButton = (props: AppButtonProps) => {
  return (
    <ButtonStyled {...props}>
      <ButtonStyledInner>{props.children}</ButtonStyledInner>
    </ButtonStyled>
  );
};

export const ButtonPositive = (props: ButtonProps) => (
  <ButtonPositiveStyled {...props} />
);

interface ButtonPositiveTableProps extends ButtonProps {
  path: string;
}

export const ButtonPositiveTable = (props: ButtonPositiveTableProps) => {
  const navigate = useNavigate();
  return (
    <ButtonPositiveTableStyled
      {...props}
      onClick={() => navigate(props.path)}
    />
  );
};

interface ArrowTableButtonProps {
  path: string;
}

export const ArrowTableButton = (props: ArrowTableButtonProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <ButtonArrowTableStyled
      {...props}
      onClick={() =>
        navigate(props.path, { state: { origin: location.pathname } })
      }
    />
  );
};

export const ButtonPositiveSolid = (props: ButtonProps) => (
  <ButtonPositiveSolidStyled {...props} />
);

export const ButtonNeutral = (props: ButtonProps) => (
  <ButtonNeutralStyled {...props} />
);

export const ButtonNeutralSolid = (props: ButtonProps) => (
  <ButtonNeutralSolidStyled {...props} />
);

export const ButtonTinySolidPositive = (props: ButtonProps) => (
  <ButtonTinySolidPositiveStyled {...props} />
);

export const ButtonSmallSolidPositive = (props: ButtonProps) => (
  <ButtonSmallSolidPositiveStyled {...props} />
);

export const ButtonSmallSolidNegative = (props: ButtonProps) => (
  <ButtonSmallSolidNegativeStyled {...props} />
);

interface NavButtonProps extends React.HTMLAttributes<HTMLDivElement> {
  currentPath?: string;
  path: string;
  tab?: boolean;
}

export const NavButton = (props: NavButtonProps) => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <NavButtonStyled
      {...props}
      currentPath={location.pathname}
      onClick={() =>
        navigate(props.path, {
          state: { origin: props.currentPath, isDuplicate: false },
        })
      }
    />
  );
};

export const ButtonGroup = () => {};
