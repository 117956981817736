import { Container } from '@mui/material';
import { useParams } from 'react-router';
import { omit } from 'lodash';
import {
  useInventoryRequestQuery,
  useUpdateInventoryRequestMutation,
} from '#graphql';
import { Loader } from '#components/widgets';
import { ReservationForm, ReservationFormData } from './ReservationForm';

export function EditReservation() {
  const [updateInventoryRequest] = useUpdateInventoryRequestMutation();
  const { id } = useParams();

  const { data, error, loading } = useInventoryRequestQuery({
    variables: {
      id: Number(id),
    },
  });

  if (loading) {
    return <Loader />;
  }

  if (error) {
    console.error({ error });
    return null;
  }

  const inventoryRequest = data?.inventoryRequest!;

  async function handleSubmit(data: ReservationFormData) {
    await updateInventoryRequest({
      variables: {
        input: {
          ...omit(data, ['strain']),
          experimentId: parseInt(data.experimentId),
          inventoryId: parseInt(data.inventoryId),
          quantity: parseInt(data.quantity),
          id: inventoryRequest.id,
        },
      },
      awaitRefetchQueries: true,
      refetchQueries: ['InventoryRequirements'],
    });
  }

  return (
    <Container maxWidth="md">
      <ReservationForm
        inventoryRequest={inventoryRequest}
        handleSubmit={handleSubmit}
      />
    </Container>
  );
}
