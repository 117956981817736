import { Box, FormControl, InputLabel, Typography, debounce } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';

import { isNil } from 'lodash';
import { useCallback } from 'react';
import toast from 'react-hot-toast';

import { useUpdatePkBloodWorksheetBasisDateMutation, type PkBloodWorksheet } from '#graphql';
import { DEFAULT_DEBOUNCE } from '#lib/constants';
import { i18n } from '#lib/constants/i18n';
import { formatDate } from '#lib/utils';
import { DateTime } from 'luxon';

const MIN_DATE = DateTime.fromISO('2023-06-01');

interface TitleProps {
  canUpdateBasisDate: boolean;
  worksheet: {
    id: number;
    basisDate?: PkBloodWorksheet['basisDate'];
  };
}

/**
 *
 * TODO
 * - add validation to basis date field, do not allow date to be removed
 * - above may require using react-hook-form
 */
export function Title(props: TitleProps) {
  const { canUpdateBasisDate, worksheet } = props;

  const [updateBasisDate] = useUpdatePkBloodWorksheetBasisDateMutation();

  const onChangeBasisDate = useCallback(
    debounce((value: DateTime | null) => {
      void toast.promise(
        updateBasisDate({
          variables: {
            input: {
              id: worksheet.id,
              basisDate: isNil(value) ? null : value.toISODate() ?? '',
            },
          },
        }),
        i18n.PKBloodWorksheet.UpdateBasisDate
      );
    }, DEFAULT_DEBOUNCE),
    [updateBasisDate]
  );

  return (
    <Box display="flex" justifyContent="space-between" paddingX={4} paddingY={2}>
      <Typography variant="h1" display="flex" alignItems="center" paddingTop={2}>
        <span>PK Blood Worksheet</span>
      </Typography>
      {canUpdateBasisDate ? (
        <DatePicker
          label="Basis Date"
          defaultValue={DateTime.fromISO(worksheet.basisDate ?? '')}
          onChange={onChangeBasisDate}
          minDate={MIN_DATE}
          slotProps={{
            field: { clearable: true },
          }}
        />
      ) : (
        // width and padding to match text input
        <FormControl sx={{ width: '246px' }}>
          <InputLabel>Basis Date</InputLabel>
          <Box paddingY="10.5px" paddingX="16px">
            {isNil(worksheet.basisDate) ? '-' : formatDate(worksheet.basisDate)}
          </Box>
        </FormControl>
      )}
    </Box>
  );
}
