import styled from '@emotion/styled';
import { Launch, ArrowBack } from '@mui/icons-material';
import { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import { Select, MenuItem } from '@mui/material';

import {
  Worksheet,
  defaultStatus,
  WorksheetStatus,
  WorksheetEntry,
} from '@omnivivo/worksheets-core';
import { mediumTextStyle } from '#lib/constants/typography';
import { AppColors } from '#lib/constants';

import {
  ExperimentByIdDocument,
  PermissionAction,
  useUpdateWorksheetStatusMutation,
  AllExperimentsDocument,
  ExperimentDetailsFragment,
} from '#graphql';

import {
  ItemWrapper,
  TopHalfWrapper,
  StyledButton,
  StyledCircleLink,
} from '#components/partials/ExperimentConfigurationHeader/styles';

import { tightBoxShadow } from './SectionStyles';
import { downloadAsCsv } from './WorksheetBodyLib';

export const HeaderWrapper = styled.div({
  label: 'HeaderWrapper',
  height: '59px',
  display: 'flex',
  flexDirection: 'column',
  background: AppColors.LIGHT_LIGHT_GREY,
  boxSizing: 'border-box',
  borderBottom: `1px solid ${AppColors.MERCURY}`,
  padding: '0 30px',
  width: '100%',
  boxShadow: tightBoxShadow,
});

const worksheetHumanStatus = {
  notStarted: 'Not started',
  started: 'Started',
  complete: 'Complete',
};

const worksheetStatusList: WorksheetStatus[] = [
  'notStarted',
  'started',
  'complete',
];

const getWorksheetHumanName = (type: WorksheetStatus): string =>
  worksheetHumanStatus[type];

type ButtonActions = [
  name: string,
  icon: EmotionJSX.Element,
  action: () => void,
  permission: PermissionAction,
  isExport?: boolean
];

export const StyledTitle = styled.div({
  fontSize: '18px',
  fontWeight: 'bold',
  lineHeight: '24px',
  width: '30em',
  paddingTop: '3px',
});

const StyledSelect = styled(Select)({
  height: '28px',
  borderRadius: '100px',
  backgroundColor: 'white',
  border: '0px solid white',
  fontSize: '12px',
});

const StyledMenuItem = styled(MenuItem)({
  ...mediumTextStyle,
  fontSize: '12px',
});

export const WorksheetHeader = ({
  worksheet,
  entries,
  experiment,
}: {
  worksheet: Worksheet;
  entries: WorksheetEntry[];
  experiment?: ExperimentDetailsFragment;
}) => {
  const [updateWorksheetStatus] = useUpdateWorksheetStatusMutation({
    refetchQueries: [
      {
        query: ExperimentByIdDocument,
        variables: {
          id: worksheet.experimentId!,
        },
      },
      {
        query: AllExperimentsDocument,
        variables: {
          limit: 10,
          statuses: ['Draft'],
        },
      },
    ],
  });

  const actionButtons: ButtonActions[] = [
    [
      'Export',
      <Launch fontSize="inherit" />,
      () => {
        if (!experiment) {
          return;
        }
        downloadAsCsv({ worksheet, experiment, entries });
      },
      'read',
      true,
    ],
  ];

  return (
    <HeaderWrapper>
      <TopHalfWrapper>
        <ItemWrapper>
          <StyledCircleLink to={`/configure/${experiment?.id}/workspace`}>
            <ArrowBack fontSize="inherit" />
          </StyledCircleLink>
          <StyledTitle
            data-test-experiment-name={experiment?.name}
            data-test-id="experimentDisplayName"
          >
            {`${experiment?.approvalName ?? 'Pending'} ${
              worksheet.name ?? worksheet.definition.worksheetName
            }`}
          </StyledTitle>
        </ItemWrapper>
        <ItemWrapper>
          <StyledSelect
            sx={{ border: '4px solid blue' }}
            value={worksheet.status || defaultStatus}
            data-test-id={`worksheetStatus`}
            onChange={(event) =>
              updateWorksheetStatus({
                variables: {
                  input: {
                    id: worksheet!.id,
                    status: event.target.value as string,
                  },
                },
              })
            }
          >
            {worksheetStatusList.map((status) => (
              <StyledMenuItem key={`${status}`} value={status}>
                {getWorksheetHumanName(status)}
              </StyledMenuItem>
            ))}
          </StyledSelect>
          {actionButtons.map(
            ([name, icon, action, permission, isExport = false]) => (
              <StyledButton
                key={name}
                solid="true"
                customsize="tiny"
                polarity="positive"
                onClick={action}
              >
                {icon} {name}
              </StyledButton>
            )
          )}
        </ItemWrapper>
      </TopHalfWrapper>
    </HeaderWrapper>
  );
};
