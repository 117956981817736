import { ReactNode } from 'react';
export interface FilterBy<D> {
  label: string;
  fn: (element: D) => boolean;
}

export interface SortBy {
  column: string;
  descending: boolean;
}

export interface ListManipulation<D> {
  filterBy: FilterBy<D>;
  sortBy: SortBy;
}

export interface ListManipulationEvent<D> {
  filterByRequest?: FilterBy<D>;
  sortByRequest?: SortBy;
}

export interface ColumnConfig<D> {
  column: string;
  renderFn: (element: D) => ReactNode;
  sortFn?: (a: D, b: D) => 1 | 0 | -1;
}

export const ExperimentColumns = {
  Title: 'Title',
  ApprovalName: 'Experiment Name',
  Phase: 'Phase',
  Priority: 'Priority',
  Author: 'Author',
  Researcher: 'Researcher',
  Updated: 'Updated',
  Submitted: 'Submitted',
  Program: 'Program',
  IACUC: 'IACUC',
  CRO: 'CRO',
  MTA: 'MTA',
  Open: '',
  Completed: 'Completed',
} as const;
export type ExperimentColumns =
  typeof ExperimentColumns[keyof typeof ExperimentColumns];

export const UserColumns = {
  Name: 'Name',
  Email: 'Email',
  Role: 'Role',
  PhoneNumber1: 'Work Phone Number',
  PhoneNumber2: 'Personal Phone Number',
  Open: '',
} as const;
export type UserColumns = typeof UserColumns[keyof typeof UserColumns];
