import styled from '@emotion/styled';
import { AppColors } from '#lib/constants';
import { TemplateEditorHeader, TemplateEditor } from '#components/partials';

const StyledSeparator = styled.div({
  border: `1px solid ${AppColors.MERCURY}`,
});

export const TemplateEditorPage = () => {
  return (
    <>
      <TemplateEditorHeader />
      <StyledSeparator />
      <TemplateEditor />
    </>
  );
};
