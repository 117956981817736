import { useState } from 'react';
import styled from '@emotion/styled';
import { KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material';

import { AppColors } from '#lib/constants';
import { useClickOutside } from '#lib/hooks';
import { LatestActivityBox } from '#components/partials';
import { Activity, ExperimentDetailsFragment } from '#graphql';

const StyledContainer = styled.div({
  display: 'flex',
  flexDirection: 'column-reverse',
});

const StyledSection = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  gap: '0.2rem',
});

const StyledBubble = styled.div({
  background: AppColors.MID_GREY,
  borderRadius: '50%',
  padding: '0.1rem',
  color: AppColors.WHITE,
  fontWeight: '700',
  fontSize: '10px',
  lineHeight: '12px',
  width: '18px',
  height: '18px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const StyledTitle = styled.div({
  fontWeight: '500',
  fontSize: '14px',
  lineHeight: '24px',
  letterSpacing: '0.5px',
  color: AppColors.GREY,
});

const StyledIcons = styled.div({
  color: AppColors.GREY,
  cursor: 'pointer',
});

const StyledList = styled.div(({ visible = false }: { visible?: boolean }) => ({
  position: 'absolute',
  top: '147px',
  zIndex: '2',
  right: '4px',
  width: '18.13rem',
  display: visible ? 'visible' : 'none',
  boxShadow: '-2px 2px 5px rgba(0, 0, 0, 0.2)',
  transition: 'opacity 1s ease-in',
}));

const ArrowComponent = ({ isOpen }: { isOpen: boolean }) =>
  isOpen ? (
    <StyledIcons>
      <KeyboardArrowUp />
    </StyledIcons>
  ) : (
    <StyledIcons>
      <KeyboardArrowDown />
    </StyledIcons>
  );

export const LatestActivityList = ({
  activities,
}: {
  activities: ExperimentDetailsFragment['activity'];
}) => {
  let domNode = useClickOutside(() => {
    setActivityListState(false);
  });
  const [activityListState, setActivityListState] = useState(false);

  return (
    <StyledContainer>
      <StyledSection>
        <StyledBubble>{activities.length || '-'}</StyledBubble>
        <StyledTitle>Latest Activity</StyledTitle>
        <StyledIcons
          data-test-id={`latestActivityButton`}
          onClick={() => setActivityListState(!activityListState)}
        >
          <ArrowComponent isOpen={activityListState} />
        </StyledIcons>
      </StyledSection>
      <StyledList ref={domNode} visible={activityListState}>
        <LatestActivityBox activities={activities} absolute size="25rem" />
      </StyledList>
    </StyledContainer>
  );
};
