"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CalcParser = void 0;
const CalcLib_1 = require("./CalcLib");
const { Parser } = require('caffeine-eight');
class CalcParser extends Parser {
}
exports.CalcParser = CalcParser;
function resolveInfixBinaryOpsSequence(values, operands) {
    let valueStackSize = 1;
    let operandStackSize = 0;
    operands.forEach((operand, readIndex) => {
        if (operand === '-' || operand === '+') {
            operands[operandStackSize++] = operands[readIndex];
            values[valueStackSize++] = values[readIndex + 1];
        }
        else {
            values[valueStackSize - 1] = CalcLib_1.ops[operands[readIndex]](values[valueStackSize - 1], values[readIndex + 1]);
        }
    });
    let result = values[0];
    for (let i = 0; i < operandStackSize; i++) {
        result = CalcLib_1.ops[operands[i]](result, values[i + 1]);
    }
    return result;
}
CalcParser.rule({
    root: [
        'expression',
        {
            evaluate(context) {
                return this.expression.evaluate(context);
            },
        },
    ],
    expression: [
        ['binaryOp', 'nonBinOpExpr'],
        {
            evaluate(context) {
                return this.matches[0].evaluate(context);
            },
        },
    ],
    binaryOp: [
        'nonBinOpExpr binOpExtension+',
        {
            evaluate(context) {
                return resolveInfixBinaryOpsSequence([this.nonBinOpExpr]
                    .concat(this.binOpExtensions.map(({ nonBinOpExpr }) => nonBinOpExpr))
                    .map((node) => node.evaluate(context)), this.binOpExtensions.map(({ op }) => op.text));
            },
        },
    ],
    binOpExtension: '_? op:/[-+*\\/]/ _? nonBinOpExpr',
    nonBinOpExpr: [
        ['parenthetical', 'function', 'boolConst', 'literal', 'variable', 'array'],
        {
            evaluate(context) {
                return this.matches[0].evaluate(context);
            },
        },
    ],
    boolConst: [
        /true|false/,
        {
            evaluate(_context) {
                return this.text === 'true';
            },
        },
    ],
    array: [
        "'[' _? arguments? _? ']'",
        {
            evaluate(context) {
                return this.arguments
                    ? this.arguments
                        .getArguments()
                        .map((match) => match.evaluate(context))
                    : [];
            },
        },
    ],
    function: [
        "identifier '(' _? arguments? _? ')'",
        {
            evaluateArguments(context) {
                return this.arguments
                    ? this.arguments
                        .getArguments()
                        .map((match) => match.evaluate(context))
                    : [];
            },
            evaluate(context) {
                const f = context.resolveFunction(this.identifier.text);
                if (!f)
                    throw new Error(`Could not resolve function named: '${this.identifier.text}'`);
                return f.call(context.evalContext || {}, ...this.evaluateArguments(context));
            },
        },
    ],
    arguments: [
        'expression argumentExtension*',
        {
            getArguments() {
                return this.matches.filter((match) => match.evaluate);
            },
        },
    ],
    argumentExtension: [
        "_? ',' _? expression",
        {
            evaluate(context) {
                return this.expression.evaluate(context);
            },
        },
    ],
    parenthetical: [
        "'(' _? expression _? ')'",
        {
            evaluate(context) {
                return this.expression.evaluate(context);
            },
        },
    ],
    propAccessorExtension: "'.' identifier",
    variable: [
        'identifier propAccessorExtension*',
        {
            evaluate(context) {
                return context.resolveValue(this.text);
            },
        },
    ],
    identifier: /(?!\d)((?!\s)[$\w\u007f-\uffff])+/,
    literal: [
        /-?(?=[1-9]|0(?!\d))\d+(\.\d+)?([eE][+-]?\d+)?/,
        /"([^"\\]*|\\["\\\/bfnrt]|\\u[0-9a-f]{4})*"/,
        /'([^'\\]*|\\['\\\/bfnrt]|\\u[0-9a-f]{4})*'/,
        {
            evaluate(_context) {
                // eval is safe here because the parser guarantees this.text only contains a number or string
                return eval(this.text); // eslint-disable-line
            },
        },
    ],
    _: /\s+/,
});
