import { Box, Container } from '@mui/material';
import {
  ExperimentForm,
  type ExperimentFormData,
} from '#components/partials/CreateExperiment';
import { useCreateExperimentMutation } from '#graphql';
import { useNavigate } from 'react-router';

export function CreateExperiment() {
  const [createExperiment] = useCreateExperimentMutation();
  const navigate = useNavigate();

  async function handleSubmit(data: ExperimentFormData) {
    const createExperimentResult = await createExperiment({
      variables: {
        input: {
          ...data,
        },
      },
    });

    if (createExperimentResult.errors) {
      throw createExperimentResult.errors;
    }

    navigate(
      `/configure/${createExperimentResult.data?.createExperiment.id}/overview`
    );
  }

  return (
    <Box paddingX={1} paddingY={3}>
      <Container maxWidth="sm">
        <ExperimentForm handleSubmit={handleSubmit} />
      </Container>
    </Box>
  );
}
