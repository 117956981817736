import { CSSObject } from '@emotion/styled';
import { WorksheetFieldDefinition } from '@omnivivo/worksheets-core';
import { AppColors } from '#lib/constants';
import { mediumTextStyle } from '#lib/constants/typography';

export const seagenGreen = AppColors.BRAND_GREEN;
export const formGrey = AppColors.DARK_GREY;
export const lightGrey = AppColors.GREY2;

export const boxShadow = '0px 7px 11px 3px rgba(0,0,0,0.1)';
export const tightBoxShadow = '-2px 2px 5px 0px #00000033';

export const gridSize = 10;

export const cellStyle = {
  margin: `${gridSize}px`,
  marginTop: 0,
  marginBottom: 0,
  ...mediumTextStyle,
};

export const colMajorBorderStyle = '1px solid #eaeaea';

export const firstDataCollectionCellStyle = {
  paddingLeft: `${2 * gridSize}px`,
  borderLeft: colMajorBorderStyle,
};

export const lastDataCollectionCellStyle = {
  paddingRight: `${2 * gridSize}px`,
};

export const getCellPositionStyle = (
  fields: WorksheetFieldDefinition[] = [],
  index: number,
  forDataCollection?: boolean
) =>
  forDataCollection
    ? index === 0
      ? firstDataCollectionCellStyle
      : index === fields.length - 1
      ? lastDataCollectionCellStyle
      : undefined
    : undefined;

export const stickyStyles: CSSObject = {
  position: 'sticky',
  left: 0,
  maxWidth: 300,
  zIndex: 2,
};
