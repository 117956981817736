import { AddOutlined } from '@mui/icons-material';
import { isNil } from 'lodash';

import { type TissueBioanalysisFragment } from '#graphql';
import { type TreatmentGroupWithTreatment } from '#lib/types';
import { useState } from 'react';
import { StyledButton, StyledButtonContainer } from '../Cards/styles';
import { TissueBioanalysisData } from './TissueBioanalysisData';
import { TissueBioanalysisModal } from './TissueBioanalysisModal';

interface TissueBioanalysisProps {
  tissueBioanalysis?: TissueBioanalysisFragment;
  canEdit: boolean;
  experimentId: number;
  treatmentGroups: TreatmentGroupWithTreatment[];
}

export function TissueBioanalysisSection({
  tissueBioanalysis,
  canEdit,
  experimentId,
  treatmentGroups,
}: TissueBioanalysisProps) {
  const [drawerState, setDrawerState] = useState(false);

  return (
    <div>
      <TissueBioanalysisModal
        tissueBioanalysis={tissueBioanalysis}
        onClose={() => {
          setDrawerState(false);
        }}
        open={drawerState}
        experimentId={experimentId}
        treatmentGroups={treatmentGroups}
      />

      {!isNil(tissueBioanalysis) && (
        <TissueBioanalysisData
          tissueBioanalysis={tissueBioanalysis}
          canEdit={canEdit}
          handleEdit={() => {
            setDrawerState(true);
          }}
          treatmentGroups={treatmentGroups}
        />
      )}

      {canEdit && isNil(tissueBioanalysis) && (
        <StyledButtonContainer gap="5px">
          <StyledButton
            onClick={() => {
              setDrawerState(true);
            }}
            disabled={treatmentGroups.length === 0}
          >
            <AddOutlined /> Add Tissue Bioanalysis
          </StyledButton>
        </StyledButtonContainer>
      )}
    </div>
  );
}
