import {
  type BioanalysisCollectionScheduleFragment,
  type BioanalysisTreatmentGroupFragment,
  type CreateTissueBioanalysisInput,
  type TissueBioanalysisFragment,
  type UpdateTissueBioanalysisInput,
} from '#graphql';
import { type TreatmentGroupWithTreatment } from '#lib/types';
import { isEmpty, isNil, omit } from 'lodash';

import {
  defaultCollectionSchedule,
  numberArrayToRangeString,
  rangeStringToNumberArray,
  type BioanalysisFormData,
} from '../Bioanalysis';

const OTHER_DESTINATION = 'Other';
const DEFAULT_DESTINATIONS = ['AP Lab', 'Histology Core'];

export const DESTINATION_OPTIONS = DEFAULT_DESTINATIONS.concat([OTHER_DESTINATION]);

export interface TissueBioanalysisFormData extends BioanalysisFormData {
  collectionCriteria: string;
  destination: string;
  otherDestination?: string;
  stains: string;
  tissueNames: string[];
  tissues: Array<{
    id?: number;
    tissue: string;
    handlingAndStorage: Array<{
      checked: boolean;
      method: string;
      includeWeight: boolean;
    }>;
    notes: string;
  }>;
  /*
    This needs to be present in the FormData type so we can "register" the fields to keep
    track of validity for multiple inputs
  */
  handlingAndStorageValidity?: never;
}

export function formDataToCreateInput(
  experimentId: number,
  data: TissueBioanalysisFormData,
  treatmentGroups: TreatmentGroupWithTreatment[]
): CreateTissueBioanalysisInput {
  return {
    ...formDataToInput(data, treatmentGroups),
    experimentId,
  };
}

export function formDataToUpdateInput(
  id: number,
  data: TissueBioanalysisFormData,
  treatmentGroups: TreatmentGroupWithTreatment[]
): UpdateTissueBioanalysisInput {
  return {
    ...formDataToInput(data, treatmentGroups),
    id,
  };
}

export function formDataToInput(data: TissueBioanalysisFormData, treatmentGroups: TreatmentGroupWithTreatment[]) {
  const { collectionCriteria, destination, otherDestination, tissues, tissueNames: _tissueNames, ...rest } = data;
  return {
    ...rest,
    collectionCriteria,
    destination: DEFAULT_DESTINATIONS.includes(destination) ? destination : otherDestination ?? '',
    tissues: tissues.map((tissue) => ({
      id: tissue.id,
      tissue: tissue.tissue,
      notes: tissue.notes,
      handlingAndStorage: tissue.handlingAndStorage
        .filter((handlingAndStorage) => handlingAndStorage.checked)
        .map((handlingAndStorage) => omit(handlingAndStorage, 'checked')),
    })),
    treatmentGroups: data.treatmentGroups
      .filter((tg) => tg.checked)
      .map((tg) => {
        const { checked: _checked, baseTestArticleId, ...rest } = tg;

        return {
          ...rest,
          baseTestArticleId:
            collectionCriteria === 'dose'
              ? Number(baseTestArticleId)
              : treatmentGroups.find((treatmentGroup) => treatmentGroup.id === tg.treatmentGroupId)?.treatment
                  .testArticles[0].id ?? 0,
        };
      }),
    collectionSchedules: data.collectionSchedules.map((collectionSchedule) => {
      const { onlyGroupsString, timepoints, ...rest } = collectionSchedule;
      return {
        ...rest,
        onlyGroups: collectionSchedule.isDefault ? [] : rangeStringToNumberArray(onlyGroupsString),
        timepoints: timepoints.map((timepoint) => {
          const { includeAllAnimals, dose, minutes, onlyAnimalsString, ...rest } = timepoint;
          return {
            ...rest,
            dose: collectionCriteria === 'dose' ? Number(dose) : 1,
            minutes: Number(minutes),
            onlyAnimals: includeAllAnimals === true ? [] : rangeStringToNumberArray(onlyAnimalsString),
          };
        }),
      };
    }),
  };
}

export function buildDefaultValues(
  treatmentGroups: TreatmentGroupWithTreatment[],
  handlingAndStorageMethods: string[],
  tissueBioanalysis?: TissueBioanalysisFragment
) {
  const destination = tissueBioanalysis?.destination ?? '';

  const tissues =
    tissueBioanalysis?.tissues.map((tissue) => ({
      id: tissue.id,
      tissue: tissue.tissue,
      handlingAndStorage: handlingAndStorageMethods.map((handlingAndStorageMethod) => {
        const handlingAndStorageValue = tissue.handlingAndStorage.find(
          (handlingAndStorage) => handlingAndStorage.method === handlingAndStorageMethod
        );
        return {
          checked: !isNil(handlingAndStorageValue),
          method: handlingAndStorageMethod,
          includeWeight: handlingAndStorageValue?.includeWeight ?? false,
        };
      }),
      notes: tissue.notes,
    })) ?? [];

  const collectionCriteria = tissueBioanalysis?.collectionCriteria ?? '';

  return {
    stains: tissueBioanalysis?.stains ?? '',
    collectionCriteria,
    destination: destination === '' || DEFAULT_DESTINATIONS.includes(destination) ? destination : OTHER_DESTINATION,
    otherDestination: !DEFAULT_DESTINATIONS.includes(destination) ? destination : '',
    treatmentGroups: buildDefaultTreatmentGroupsValue(
      collectionCriteria,
      treatmentGroups,
      tissueBioanalysis?.treatmentGroups
    ),
    collectionSchedules: buildDefaultCollectionSchedulesValue(
      collectionCriteria,
      tissueBioanalysis?.collectionSchedules
    ),
    tissueNames: tissueBioanalysis?.tissues.map((tissue) => tissue.tissue) ?? [],
    tissues,
  };
}

export function buildDefaultCollectionSchedulesValue(
  collectionCriteria: string,
  collectionSchedules?: BioanalysisCollectionScheduleFragment[]
) {
  if (isNil(collectionSchedules) || isEmpty(collectionSchedules)) {
    return [defaultCollectionSchedule];
  }

  return collectionSchedules.map((collectionSchedule) => {
    return {
      id: collectionSchedule.id,
      isDefault: collectionSchedule.isDefault,
      onlyGroupsString: numberArrayToRangeString(collectionSchedule.onlyGroups),
      timepoints: collectionSchedule.timepoints.map((timepoint) => ({
        id: timepoint.id,
        dose: collectionCriteria === 'tumorVolume' ? '' : timepoint.dose.toString(),
        minutes: timepoint.minutes.toString(),
        onlyAnimalsString: numberArrayToRangeString(timepoint.onlyAnimals),
        includeAllAnimals: isEmpty(timepoint.onlyAnimals),
      })),
    };
  });
}

export function buildDefaultTreatmentGroupsValue(
  collectionCriteria: string,
  treatmentGroups: TreatmentGroupWithTreatment[],
  bioanalysisTreatmentGroups?: BioanalysisTreatmentGroupFragment[]
) {
  return treatmentGroups.map((tg) => {
    const tissueBioanalysisTreatmentGroup = (bioanalysisTreatmentGroups ?? []).find(
      (btg) => btg.treatmentGroupId === tg.id
    );
    return {
      checked: !isNil(tissueBioanalysisTreatmentGroup),
      id: tissueBioanalysisTreatmentGroup?.id ?? undefined,
      notes: tissueBioanalysisTreatmentGroup?.notes ?? '',
      treatmentGroupId: tg.id,
      baseTestArticleId:
        collectionCriteria === 'tumorVolume'
          ? tg.treatment.testArticles[0].id.toString() ?? ''
          : tissueBioanalysisTreatmentGroup?.baseTestArticleId.toString() ?? '',
    };
  });
}
