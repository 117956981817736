import { Can, CanProps } from '#components/contexts';
import { NotFound } from '#components/pages';

export type CanRouteProps = CanProps;

export function CanRoute(props: CanRouteProps) {
  const { children, passThrough, ...canProps } = props;

  return (
    <Can {...canProps} passThrough>
      {(allowed) => (allowed ? children : <NotFound />)}
    </Can>
  );
}
