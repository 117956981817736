import { useState, useEffect, ChangeEvent } from 'react';
import toast from 'react-hot-toast';
import { Grid, SelectChangeEvent } from '@mui/material';
import { Search } from '#components/widgets';
import { Gender, i18n, Implant } from '#lib/constants';
import {
  useCellLinePageByPageQuery,
  useAddModelToExperimentMutation,
  useUpdateModelMutation,
  Model,
  Species,
} from '#graphql';
import { useUpdateExperiment, useListContent } from '#lib/hooks';
import { TableView } from '../TreatmentModal/TableView';
import { cellLineTableColumns } from '#lib/utils';
import {
  SelectContainer,
  StyledButton,
  StyledDialogContent,
  StyledDivider,
  StyledFormHelperText,
  StyledLabel,
  StyledMenuItem,
  StyledSelect,
  StyledTextField,
  StyledTableView,
  ModelOptionsContainer,
  CancelButton,
  ButtonsContainer,
  TableTitle,
} from './styles';

interface ModelModalContentProps {
  handleClose: () => void;
  selectedModel?: Model;
  editModel: boolean;
  experimentId: number;
}

export const ModelModalContent = ({
  handleClose,
  selectedModel = {
    gender: 'female',
    implant: 'subcutaneous',
    species: '' as Species,
    strain: '',
    subcutaneousEndpoint: '',
    id: null!,
    name: 'A',
    tumorStartingVolume: '',
  },
  editModel,
  experimentId,
}: ModelModalContentProps) => {
  const { updateExperimentCache } = useUpdateExperiment();
  const { subcutaneousEndpointList, strainList } = useListContent();
  const [selectedItem, setSelectedItem] = useState<number | null>();
  const [model, setModel] = useState(selectedModel);
  const [showValidation, setShowValidation] = useState(false);
  const [disabledEndpoint, setDisabledEndpoint] = useState(false);
  const [query, setQuery] = useState('');

  useEffect(() => {
    if (editModel) {
      if (selectedModel?.cellLine) {
        setSelectedItem(selectedModel.cellLine.id);
      }
      if (model.implant === 'subcutaneous') {
        setDisabledEndpoint(false);
      }
    }
  }, [editModel]);

  const [addModelToExperiment] = useAddModelToExperimentMutation();

  const [updateModel] = useUpdateModelMutation();

  const { data, fetchMore, networkStatus, loading } =
    useCellLinePageByPageQuery({
      variables: {
        limit: 50,
        offset: 0,
        query,
      },
      notifyOnNetworkStatusChange: true,
    });

  const cellLines = data?.cellLines;

  if (!strainList) return null;

  const sortStrainList = strainList.sort((a, b) =>
    a.name.localeCompare(b.name)
  );

  const onHandleChange = (id: number | null) => {
    setSelectedItem(id);
  };

  const loadCellLine = () =>
    fetchMore({
      variables: {
        limit: 50,
        offset: cellLines?.items.length,
      },
    });

  const onChangeValue = (value: string, propertyName: string) => {
    let updatedData = { [propertyName]: value };
    if (propertyName === 'strain') {
      const species = strainList.find((strain) => strain.name === value)
        ?.description!;
      updatedData = { ...updatedData, species: species };
    }
    if (updatedData.implant === 'subcutaneous') {
      setDisabledEndpoint(false);
    }
    if (updatedData.implant && updatedData.implant !== 'subcutaneous') {
      setDisabledEndpoint(true);
      updatedData = { ...updatedData, subcutaneousEndpoint: '' };
    }

    setModel({
      ...model,
      ...updatedData,
    });
  };

  const isModelValid = () => {
    return (
      model &&
      model.strain &&
      model.implant &&
      (model.implant !== 'subcutaneous' || model.subcutaneousEndpoint)
    );
  };

  const handleSave = async () => {
    setShowValidation(true);
    if (isModelValid()) {
      const tid = toast.loading(i18n.Common.Saving);
      let models;
      if (editModel) {
        const editModelFields = {
          id: model.id,
          species: model.species,
          strain: model.strain,
          gender: model.gender,
          implant: model.implant,
          cellLineId: selectedItem,
          subcutaneousEndpoint: model.subcutaneousEndpoint,
          tumorStartingVolume: model.tumorStartingVolume,
        };
        const response = await updateModel({
          variables: {
            input: editModelFields,
          },
        });
        models = response?.data?.updateModel;
      } else {
        const modelFields = {
          experimentId: experimentId,
          cellLineId: selectedItem,
          species: model.species,
          strain: model.strain,
          gender: model.gender,
          implant: model.implant,
          subcutaneousEndpoint: model.subcutaneousEndpoint,
          tumorStartingVolume: model.tumorStartingVolume,
        };
        const response = await addModelToExperiment({
          variables: {
            input: modelFields,
          },
        });
        models = response?.data?.addModelToExperiment;
      }
      if (models) {
        updateExperimentCache({
          models,
          id: experimentId,
        });
        handleClose();
        toast.dismiss(tid);
      } else {
        toast.error(i18n.Common.GenericError, { id: tid });
      }
    }
  };

  const onChangeQuery = (searchText: string) => {
    setQuery(searchText);
  };

  return (
    <>
      <StyledDialogContent data-test-id={`modelModal`}>
        <ModelOptionsContainer>
          <SelectContainer>
            <StyledLabel>Species</StyledLabel>
            <StyledLabel>{model?.species || 'Select a strain'}</StyledLabel>
          </SelectContainer>
          <SelectContainer>
            <StyledLabel htmlFor="strain">Strain</StyledLabel>
            <StyledSelect
              data-test-id={`selectStrain`}
              value={model?.strain}
              displayEmpty
              onChange={(ev: SelectChangeEvent<unknown>) =>
                onChangeValue(ev.target.value as string, 'strain')
              }
              id="strain"
            >
              <StyledMenuItem value="">Select Strain</StyledMenuItem>
              {sortStrainList.map((strain, i) => (
                <StyledMenuItem key={i} value={strain.name}>
                  {strain.name}
                </StyledMenuItem>
              ))}
            </StyledSelect>
            {!model?.strain && showValidation && (
              <StyledFormHelperText>Select a Strain</StyledFormHelperText>
            )}
          </SelectContainer>
          <SelectContainer>
            <StyledLabel htmlFor="gender">Gender</StyledLabel>
            <StyledSelect
              value={model?.gender}
              displayEmpty
              onChange={(ev: SelectChangeEvent<unknown>) =>
                onChangeValue(ev.target.value as string, 'gender')
              }
              id="gender"
            >
              {Gender.map((gender, i) => (
                <StyledMenuItem key={i} value={gender}>
                  {gender}
                </StyledMenuItem>
              ))}
            </StyledSelect>
            {!model?.gender && showValidation && (
              <StyledFormHelperText>Select a Gender</StyledFormHelperText>
            )}
          </SelectContainer>
          <SelectContainer>
            <StyledLabel htmlFor="implant">Implant Type</StyledLabel>
            <StyledSelect
              value={model?.implant}
              data-test-id={`selectImplant`}
              displayEmpty
              onChange={(ev: SelectChangeEvent<unknown>) =>
                onChangeValue(ev.target.value as string, 'implant')
              }
              id="implant"
            >
              {Implant.map((implant, i) => (
                <StyledMenuItem
                  key={i}
                  value={implant}
                  data-test-id={`${implant}Option`}
                >
                  {implant}
                </StyledMenuItem>
              ))}
            </StyledSelect>
            {!model?.implant && showValidation && (
              <StyledFormHelperText>
                Select an implant type
              </StyledFormHelperText>
            )}
          </SelectContainer>
          <SelectContainer>
            <StyledLabel htmlFor="subcutaneousEndpoint">End Point</StyledLabel>
            <StyledSelect
              value={model?.subcutaneousEndpoint ?? ''}
              displayEmpty
              disabled={disabledEndpoint}
              onChange={(ev: SelectChangeEvent<unknown>) =>
                onChangeValue(ev.target.value as string, 'subcutaneousEndpoint')
              }
              id="subcutaneousEndpoint"
            >
              <StyledMenuItem value="">Select End Point</StyledMenuItem>
              {subcutaneousEndpointList?.map((endpoint, i) => (
                <StyledMenuItem key={i} value={endpoint.name}>
                  {endpoint.name}
                </StyledMenuItem>
              ))}
            </StyledSelect>
            {!model?.subcutaneousEndpoint &&
              model?.implant === 'subcutaneous' &&
              showValidation && (
                <StyledFormHelperText>Select an End Point</StyledFormHelperText>
              )}
          </SelectContainer>
          <SelectContainer>
            <StyledLabel htmlFor="startingTumorVolume">
              {'Treatment Start ((mm^3 (sc) or day (IV))'}
            </StyledLabel>
            <StyledTextField
              value={model?.tumorStartingVolume}
              onChange={(ev: ChangeEvent<HTMLInputElement>) =>
                onChangeValue(ev.target.value as string, 'tumorStartingVolume')
              }
              id="startingTumorVolume"
            />
          </SelectContainer>
        </ModelOptionsContainer>
      </StyledDialogContent>
      <StyledDivider />
      <Grid container>
        <Grid item xs={3}>
          <TableTitle>Select cell line</TableTitle>
        </Grid>
        <Grid item xs={6}>
          <Grid container justifyContent="center">
            <Grid item>
              <Search onChange={onChangeQuery} query={query} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={3}></Grid>
      </Grid>
      <StyledTableView data-test-id="cellLineTable">
        <TableView
          tabName="CellLine"
          tableData={cellLines?.items}
          dataCount={cellLines?.totalCount}
          loadItems={loadCellLine}
          loading={loading}
          networkStatus={networkStatus}
          columns={cellLineTableColumns}
          selectedItemField="id"
          isRadio={true}
          includeNone={!query || 'none'.includes(query.toLowerCase())}
          onChangeRadio={onHandleChange}
          radioValue={selectedItem ? selectedItem.toString() : ''}
          radioName="cellLines"
        />
      </StyledTableView>
      <ButtonsContainer>
        <CancelButton data-test-id={`cancelModel`} onClick={handleClose}>
          CANCEL
        </CancelButton>
        <StyledButton data-test-id={`submitModel`} onClick={handleSave}>
          APPLY
        </StyledButton>
      </ButtonsContainer>
    </>
  );
};
