import { useAbility } from '@casl/react';
import { InputAdornment } from '@mui/material';
import { isNil } from 'lodash';
import { useEffect, useMemo } from 'react';
import { FormProvider, useForm, type FieldValues, type SubmitHandler } from 'react-hook-form';

import { AbilityContext } from '#components/contexts';
import { OmniForm, type OmniFormFieldProps } from '#components/widgets';
import { useAllRolesQuery, type UserDetailsFragment } from '#graphql';
import { i18n } from '#lib/constants';

function getDefaultValues(user?: UserDetailsFragment) {
  return {
    firstName: user?.firstName ?? '',
    lastName: user?.lastName ?? '',
    username: user?.email.replace('@seagen.com', '') ?? '',
    roleId: user?.roleId.toString() ?? '',
  };
}
export interface UserFormData extends FieldValues {
  firstName: string;
  lastName: string;
  username: string;
  roleId: string;
}

export interface UserFormProps {
  user?: UserDetailsFragment;
  handleSubmit: SubmitHandler<UserFormData>;
}

export function UserForm(props: UserFormProps) {
  const { user, handleSubmit = () => undefined } = props;

  const isNew = isNil(user);

  const ability = useAbility(AbilityContext);

  const skipRolesQuery = isNew ? !ability.can('create', 'user') : !ability.can('update', user, 'roleId');

  const canEditEmail = isNew ? ability.can('create', 'user') : ability.can('update', user, 'email');

  const rolesQueryResult = useAllRolesQuery({
    skip: skipRolesQuery,
  });

  const roleOptions = useMemo(() => {
    const roles = skipRolesQuery && !isNew ? [user.userRole] : rolesQueryResult.data?.roles.items ?? [];

    return roles.map((role) => ({
      label: role.name,
      value: role.id.toString(),
    }));
  }, [skipRolesQuery, rolesQueryResult]);

  const methods = useForm<UserFormData>({
    criteriaMode: 'all',
    defaultValues: getDefaultValues(user),
    mode: 'onBlur',
  });

  const { formState, reset } = methods;

  // TODO - generalize this in OmniForm somehow?
  useEffect(() => {
    if (formState.isSubmitSuccessful) {
      reset(getDefaultValues(user));
    }
  }, [formState, user]);

  const formFields: Array<OmniFormFieldProps<UserFormData>> = useMemo(
    () => [
      {
        name: 'firstName',
        label: 'First Name',
        rules: {
          required: 'please enter a valid first name',
        },
        colSpan: 1,
      },
      {
        name: 'lastName',
        label: 'Last Name',
        rules: {
          required: 'please enter a valid last name',
        },
        colSpan: 1,
      },
      {
        name: 'username',
        label: 'Seagen Email',
        rules: {
          required: 'please enter a valid Seagen username',
        },
        colSpan: 1,
        disabled: !canEditEmail,
        InputProps: {
          endAdornment: <InputAdornment position="end">@seagen.com</InputAdornment>,
        },
      },
      {
        name: 'roleId',
        label: 'Role',
        rules: {
          required: 'please select a valid role',
        },
        select: true,
        selectOptions: roleOptions,
        colSpan: 1,
        disabled: skipRolesQuery,
      },
    ],
    [ability, isNew, roleOptions]
  );

  return (
    <FormProvider {...methods}>
      <OmniForm<UserFormData>
        fields={formFields}
        formText={i18n.Users.Form}
        handleSubmit={handleSubmit}
        isNew={isNew}
        successNavigation={isNew ? '/users' : ''}
      />
    </FormProvider>
  );
}
