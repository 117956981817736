// eslint-disable-next-line n/no-extraneous-import
import isPropValid from '@emotion/is-prop-valid';
import styled from '@emotion/styled';
import {
  InputLabel,
  TextField,
  Select,
  MenuItem,
  FormControlLabel,
} from '@mui/material';
import { ArrowForwardIos } from '@mui/icons-material';

import { ButtonPositive } from '#components/widgets';
import { AppColors } from '#lib/constants';

export const StyledGroupCard = styled.div({
  display: 'flex',
  background: AppColors.WHITE,
  flexDirection: 'column',
  boxShadow: ' 2px 2px 6px rgba(0, 0, 0, 0.2)',
  borderRadius: '4px',
});

export const StyledHeader = styled.div({
  display: 'flex',
  background: AppColors.GREY7,
  gap: '15px',
  padding: '10px',
  justifyContent: 'space-between',
  minWidth: '1150px',
  alignItems: 'center',
});

export const StyledCircle = styled.div({
  background: AppColors.SPECTRA_GREEN,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '18px',
  lineHeight: '24px',
  fontWeight: 900,
  fontStyle: 'normal',
  width: '30px',
  height: '30px',
  borderRadius: '50%',
});

export const StyledRoundedBox = styled.div({
  borderRadius: '6px',
  gap: '8px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  paddingRight: '2px',
});

export const StyledAnimalBox = styled.div({
  background: AppColors.SPECTRA_BLUE,
  paddingLeft: '5px',
  width: '170px',
  height: '30px',
  display: 'flex',
  gap: '5px',
  borderRadius: '6px',
  justifyContent: 'center',
  alignItems: 'center',
});

export const StyledNumberField = styled(TextField)({
  input: {
    width: '35px',
    padding: '2.5px',
    textAlign: 'center',
    height: '22px',
    borderRadius: '6px',
    color: AppColors.DARK_GREY,
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: '0.15px',
    background: AppColors.WHITE,
    '&[type=number]': {
      MozAppearance: 'textfield',
    },
    '&::-webkit-outer-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
    '&::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
  },
});

export const StyledGroupSelect = styled(Select)({
  border: 'none',
  background: 'none',
  color: AppColors.BLACK,
  fontStyle: 'normal',
  fontSize: '12px',
  fontWeight: 400,
  fieldset: {
    border: 'none',
  },
});

export const StyledTreatmentBox = styled.div({
  width: '130px',
  height: '30px',
  backgroundColor: AppColors.SPECTRA_YELLOW,
  borderRadius: '6px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const StyledWorksheetTypeBox = styled.div({
  background: AppColors.LIGHT_LIGHT_GREY,
  opacity: '0.65',
  borderRadius: '6px',
  paddingLeft: '9px',
  display: 'flex',
  height: '30px',
  gap: '2px',
  input: {
    border: '10px solid red',
  },
});

export const StyledLabelCheckbox = styled(FormControlLabel)({
  display: 'flex',
  flexDirection: 'row-reverse',
  color: AppColors.DARK_GREY,
  margin: 0,
  paddingY: '2px',
  span: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '10px',
    lineHeight: '12px',
    '&.Mui-checked': {
      color: AppColors.SPECTRA_RED,
    },
    '&.MuiTypography-root': {
      color: 'black',
    },
  },
});

export const StyledNumberTextField = styled(TextField)({
  input: {
    width: '61px',
    padding: '0',
    textAlign: 'center',
    height: '22px',
    borderRadius: '6px',
    color: AppColors.DARK_GREY,
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: '0.15px',
    background: AppColors.WHITE,
    '&[type=number]': {
      MozAppearance: 'textfield',
    },
    '&::-webkit-outer-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
    '&::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
  },
});

export const StyledCard = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  minHeight: '54px',
  background: AppColors.LIGHT_LIGHT_GREY,
  borderRadius: '4px',
  boxShadow: `2px 2px 6px ${AppColors.DARK_SHADE_RGBA}`,
  margin: '5px 0',
});

export const StyledRow = styled.div(
  ({ gap = '12px', isGray = false }: { gap?: string; isGray?: boolean }) => ({
    display: 'flex',
    gap,
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'center',
    background: isGray ? AppColors.GREY7 : '',
    padding: isGray ? '0.5em' : '',
  })
);

export const StyledLine = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '.5em',
  maxWidth: '70em',
});

export const StyledNumber = styled.div(
  ({ cardcolor }: { cardcolor: string }) => ({
    borderRadius: '3px',
    width: '1.25rem',
    background: cardcolor,
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    minWidth: '10px',
    padding: '0 5px',
    alignItems: 'center',
    fontSize: '10px',
    color: AppColors.GREY,
    textTransform: 'uppercase',
  })
);

export const StyledButtonContainer = styled.div(
  ({ gap }: { gap?: string }) => ({
    marginTop: '10px',
    display: 'flex',
    justifyContent: 'flex-end',
    gap,
  })
);

export const StyledButton = styled(ButtonPositive, {
  shouldForwardProp: (prop) =>
    prop === 'inputProps' || (isPropValid(prop) && prop !== 'invalid'),
})(({ invalid }: { invalid?: boolean }) => ({
  height: '29px',
  borderRadius: '38px',
  boxSizing: 'border-box',
  color: (invalid === true) ? AppColors.RED : AppColors.BRAND_GREEN,
  textTransform: 'uppercase',
  fontSize: '10px',
  lineHeight: '16px',
  fontWeight: 'bold',
  display: 'flex',
  borderColor: (invalid === true) ? AppColors.RED : AppColors.BRAND_GREEN,
  background: (invalid === true) ? AppColors.LIGHT_RED : AppColors.VERY_LIGHT_GREEN,
  justifyContent: 'space-between',
}));

export const StyledPopulatedButton = styled(ButtonPositive)({
  height: '29px',
  borderRadius: '38px',
  boxSizing: 'border-box',
  color: AppColors.BRAND_GREEN,
  textTransform: 'uppercase',
  fontSize: '10px',
  lineHeight: '16px',
  fontWeight: 'bold',
  display: 'flex',
  background: AppColors.VERY_LIGHT_GREEN,
  justifyContent: 'flex-start',
  minWidth: '181px',
  gap: '35px',
});

export const StyledGroupIconContainer = styled.div({
  width: '50px',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const StyledGroupIcon = styled(ArrowForwardIos)({
  alignSelf: 'center',
  marginRight: '0.5em',
  cursor: 'pointer',
  color: AppColors.GREY,
});

export const StyledGroupText = styled.div({
  fontSize: '14px',
  fontWeight: 'bold',
  lineHeight: '24px',
  letterSpacing: '0.15px',
});

export const StyledGroupSubText = styled.div({
  fontSize: '12px',
  fontWeight: '400',
  lineHeight: '14px',
  letterSpacing: '0.5px',
  color: AppColors.DARK_DARK_GREY,
});

export const StyledBubble = styled.div(
  ({
    customwidth = '250px',
    bubblecolor,
  }: {
    customwidth?: string;
    bubblecolor?: string;
  }) => ({
    background: bubblecolor ?? AppColors.LOW_GREY,
    width: customwidth,
    height: '23px',
    fontSize: '12px',
    padding: '5px',
    lineHeight: '16px',
    color: AppColors.BLACK,
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    wordBreak: 'break-all',
  })
);

export const StyledBubbleContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '3px',
});

export const StyledGroupBubble = styled.div(
  ({ bubblecolor }: { bubblecolor: string }) => ({
    background: bubblecolor,
    minWidth: '75px',
    maxWidth: '200px',
    height: '23px',
    fontSize: '10px',
    lineHeight: '16px',
    color: AppColors.DARK_GREY,
    borderRadius: '25px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  })
);

export const StyledTextField = styled(TextField, {
  shouldForwardProp: (prop) =>
    prop === 'inputProps' || (isPropValid(prop) && prop !== 'invalid'),
})(({ invalid, width }: { invalid?: boolean; width?: string }) => ({
  div: {
    width: width ?? '60px',
    height: '22px',
    background: (invalid === true) ? AppColors.LIGHT_RED : AppColors.WHITE,
    fontSize: '12px',
    color: AppColors.BLACK,
    fontWeight: '400',
    lineHeight: '14px',
  },
  input: {
    '&[type=number]': {
      MozAppearance: 'textfield',
    },
    '&::-webkit-outer-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
    '&::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
  },
}));

export const StyledEditButton = styled(ButtonPositive)({
  height: '29px',
  borderRadius: '38px',
  boxSizing: 'border-box',
  color: AppColors.BRAND_GREEN,
  background: AppColors.VERY_LIGHT_GREEN,
  border: `2px solid ${AppColors.BRAND_GREEN}`,
  textTransform: 'uppercase',
  fontSize: '10px',
  lineHeight: '16px',
  fontWeight: 'bold',
  display: 'flex',
  justifyContent: 'center',
});

export const StyledCloseButton = styled.div(
  ({
    background,
    marginRight = '15px',
  }: {
    background?: string;
    marginRight?: string;
  }) => ({
    width: '23px',
    height: '23px',
    background,
    borderRadius: '50%',
    justifyContent: 'center',
    marginRight,
    alignItems: 'center',
    alignSelf: 'center',
    display: 'flex',
    cursor: 'pointer',
    svg: {
      width: '15px',
    },
  })
);

export const StyledLabel = styled(InputLabel)(
  ({ width }: { width?: string }) => ({
    width: width ?? 'auto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '10px',
    lineHeight: '12px',
    color: AppColors.DARK_GREY,
    wordBreak: 'break-all',
  })
);

export const StyledTreatmentLine = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: '0.5em',
  maxWidth: '75em',
  minWidth: '53em',
  padding: '0.2em',
});

export const StyledColumn = styled.div({
  display: 'flex',
  flexDirection: 'column',
  width:'71em'
});

export const StyledTreatmentBubbleContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '3px',
  marginTop: '1em',
  marginBottom: '1em',
});

export const StyledSelect = styled(Select<string>)(
  ({ width = '60px' }: { width?: string }) => ({
    borderRadius: '4px',
    background: AppColors.LOW_GREY,
    color: AppColors.DARK_GREY,
    borderBottom: 0,
    width,
    textAlign: 'end',
    height: '23px',
    marginLeft: '10px',
    fontSize: '10px',
  })
);

export const StyledMenuItem = styled(MenuItem)({
  fontSize: '10px',
  color: AppColors.BLACK,
});

export const StyledGrayBackground = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: AppColors.GREY7,
  height: '100%',
});
