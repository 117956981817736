"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.dataCollectionTumorSize = exports.createTumorSizeDataCollectionWorksheetDefinition = void 0;
const style_1 = require("@omnivivo/style");
const worksheets_core_1 = require("@omnivivo/worksheets-core");
const DataCollectionSectionGenerators_1 = require("./DataCollectionSectionGenerators");
const WorksheetInitializationTypes_1 = require("./WorksheetInitializationTypes");
const createTumorSizeDataCollectionWorksheetDefinition = (treatmentGroupsForWorksheet) => (0, worksheets_core_1.getNormalizeWorksheetDefinition)({
    worksheetName: 'Tumor Size Worksheet',
    color: style_1.AppColors.SPECTRA_GREEN,
    sections: (0, DataCollectionSectionGenerators_1.createAllDataCollectionSections)(treatmentGroupsForWorksheet, 'mm3', 'Tumor Volume', 'mm3', '0.00', [
        {
            id: 'length',
            fieldName: 'Length (mm)',
            fieldType: 'number',
            gridWidth: 0.75,
        },
        {
            id: 'width',
            fieldName: 'Width (mm)',
            fieldType: 'number',
            gridWidth: 0.75,
        },
        {
            id: 'mm3',
            fieldName: 'mm3',
            fieldType: 'calc',
            gridWidth: 0.5,
            numberFormat: '0',
            calc: 'if(lt(length, width), width * length * length / 2, width * width * length / 2)',
        },
        {
            id: 'mass',
            fieldName: 'Mass (g)',
            fieldType: 'number',
            gridWidth: 0.75,
        },
        {
            id: DataCollectionSectionGenerators_1.WEIGHT_CHANGE_PERCENT_ID,
            fieldName: 'Weight change %',
            fieldType: 'calc',
            numberFormat: '0.00',
            unit: '%',
            calc: 'or(100 * mass / valueFromFirstEntry("mass") - 100, 0)',
            gridWidth: 0.67,
        },
        {
            id: 'observations',
            fieldName: 'Preclinical Observations',
            fieldType: 'multiSelect',
            gridWidth: 3,
            fieldValuesCalc: 'select("preclinicalObservationList", "name")',
            fieldValueLabelsCalc: 'select("preclinicalObservationList", "name")',
        },
        {
            id: DataCollectionSectionGenerators_1.DOSE_REQUIRED,
            fieldName: 'Dose Required',
            fieldType: 'boolean',
            gridWidth: 0.5,
        },
        {
            id: DataCollectionSectionGenerators_1.DOSE_AMOUNT,
            fieldName: 'Dose Amount (μL)',
            fieldType: 'calc',
            numberFormat: '0.00',
            unit: 'ml',
            calc: 'if(equal(doseRequired, true), round(10 * mass, 10), "-")',
            gridWidth: 0.5,
        },
        {
            id: DataCollectionSectionGenerators_1.DOSE_TIME,
            fieldName: 'Dose Time',
            fieldType: 'time',
            gridWidth: 1.75,
        },
    ]),
});
exports.createTumorSizeDataCollectionWorksheetDefinition = createTumorSizeDataCollectionWorksheetDefinition;
// This is only used for Demo worksheets; maybe it belongs elsewhere.
exports.dataCollectionTumorSize = (0, exports.createTumorSizeDataCollectionWorksheetDefinition)(WorksheetInitializationTypes_1.demoExperimentForWorksheet.treatmentGroupsForWorksheet);
