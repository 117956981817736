import { useContext, createContext, ReactNode, useState } from 'react';

interface ExperimentTrackerFilterContext {
  setQuery: (value: string) => void;
  query: string;
  filter: string;
  setFilter: (value: string) => void;
  sort: string;
  setSort: (value: string) => void;
}

export const ExperimentTrackerContext =
  createContext<ExperimentTrackerFilterContext>(
    {} as ExperimentTrackerFilterContext
  );

export function useExperimentTrackerContext() {
  return useContext(ExperimentTrackerContext);
}

export function ExperimentTrackerProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [query, setQuery] = useState('');
  const [filter, setFilter] = useState('');
  const [sort, setSort] = useState('');

  const contextValue = {
    setQuery,
    query,
    filter,
    setFilter,
    sort,
    setSort,
  };

  return (
    <ExperimentTrackerContext.Provider value={contextValue}>
      {children}
    </ExperimentTrackerContext.Provider>
  );
}
