"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateAllCalcs = exports.resolveCalc = exports.evalEquation = exports.resetCalcEvalCount = exports.getCalcEvalCount = exports.setLogCalcErrors = void 0;
const worksheetEntryTools_1 = require("../worksheetEntryTools");
const CalcFunctions_1 = require("./CalcFunctions");
const CalcCache_1 = require("./CalcCache");
const CalcParser_1 = require("./CalcParser");
const lodash_1 = require("lodash");
const parseCache = {};
let logCalcErrors = true;
const setLogCalcErrors = (toLog) => (logCalcErrors = toLog);
exports.setLogCalcErrors = setLogCalcErrors;
let calcEvalCount = 0;
const getCalcEvalCount = () => calcEvalCount;
exports.getCalcEvalCount = getCalcEvalCount;
const resetCalcEvalCount = () => {
    calcEvalCount = 0;
};
exports.resetCalcEvalCount = resetCalcEvalCount;
const evalEquation = (calc, options = {}) => {
    calcEvalCount++;
    // @ts-ignore - CalcParser does not support TypeScript
    return (parseCache[calc] ??= new CalcParser_1.CalcParser().parse(calc)).evaluate(options);
};
exports.evalEquation = evalEquation;
const resolveFunction = (name) => CalcFunctions_1.calcFunctions[name];
const getCurrentEntryFieldValue = (entry, fieldId, _default) => {
    return entry.data[fieldId] != null ? entry.data[fieldId] : _default;
};
const getEntryFieldValue = (definition, entriesBySectionId, sectionId, fieldId) => {
    const sectionEntries = entriesBySectionId[sectionId] || [];
    const section = definition.sectionsBySectionId[sectionId];
    if (!section?.fieldsByFieldId)
        return undefined;
    const _default = section.fieldsByFieldId[fieldId].default;
    if (section.sectionType === 'form') {
        const [entry0] = sectionEntries;
        if (entry0)
            return getCurrentEntryFieldValue(entry0, fieldId, _default);
    }
    else {
        return sectionEntries.map((entry) => getCurrentEntryFieldValue(entry, fieldId, _default));
    }
};
const getResolvedValue = (entry, fieldId, definition, calcField) => {
    if (!entry)
        return undefined;
    const { sectionId, data } = entry;
    let sourceSectionDef, sourceFieldDef;
    if ((sourceSectionDef = definition.sectionsBySectionId[sectionId])) {
        if ((sourceFieldDef = sourceSectionDef.fieldsByFieldId[fieldId])) {
            if (calcField && sourceFieldDef.fieldType === 'calc') {
                return calcField(sourceSectionDef, sourceFieldDef, entry);
            }
            else
                return data[fieldId];
        }
    }
};
const resolveCalcIdentifier = (definition, entriesBySectionId, identifier, entry, section, calcField) => {
    let sectionId = section?.id, fieldId = '', tooManyAccessors;
    let sourceSectionDef, sourceFieldDef;
    let isEntrySelfReference = false;
    if (/\./.test(identifier))
        [sectionId, fieldId, tooManyAccessors] = identifier.split('.');
    else {
        isEntrySelfReference = true;
        fieldId = identifier;
    }
    if (tooManyAccessors)
        return undefined;
    if (sectionId &&
        (sourceSectionDef = definition.sectionsBySectionId[sectionId])) {
        if ((sourceFieldDef = sourceSectionDef.fieldsByFieldId[fieldId])) {
            if (entry && calcField && sourceFieldDef.fieldType === 'calc') {
                if (isEntrySelfReference || sourceSectionDef.sectionType === 'form') {
                    return calcField(sourceSectionDef, sourceFieldDef, isEntrySelfReference ? entry : entriesBySectionId[sectionId][0]);
                }
                else {
                    return (entriesBySectionId[sourceSectionDef.id] || []).map((sourceEntry) => {
                        return calcField(sourceSectionDef, sourceFieldDef, sourceEntry);
                    });
                }
            }
            else {
                return isEntrySelfReference
                    ? entry &&
                        getCurrentEntryFieldValue(entry, fieldId, sourceFieldDef.default)
                    : getEntryFieldValue(definition, entriesBySectionId, sectionId, fieldId);
            }
        }
    }
};
const resolveCalc = (definition, entriesBySectionId, calc, fieldId, entry, section, calcField, getEntriesByNextEntryId) => {
    if (!definition?.normalized)
        throw new Error('definition: WorksheetDefinition must be normalized');
    let result;
    const resolveValue = (identifier) => resolveCalcIdentifier(definition, entriesBySectionId, identifier, entry, section, calcField);
    try {
        result = (0, exports.evalEquation)(calc, {
            evalContext: {
                getResolvedValue: (entry, fieldId) => getResolvedValue(entry, fieldId, definition, calcField),
                getPreviousEntry: () => section &&
                    entry &&
                    getEntriesByNextEntryId &&
                    getEntriesByNextEntryId(section?.id)[entry?.id],
                getFirstEntry: () => {
                    if (section && entry && getEntriesByNextEntryId) {
                        const entriesByNextEntryId = getEntriesByNextEntryId(section?.id);
                        if (!entriesByNextEntryId[entry.id])
                            return;
                        let returnEntry = entry;
                        while (entriesByNextEntryId[returnEntry.id]) {
                            returnEntry = entriesByNextEntryId[returnEntry.id];
                        }
                        return returnEntry;
                    }
                },
                worksheetDefinition: definition,
                entriesBySectionId,
                section,
                entry,
                fieldId,
            },
            resolveValue,
            resolveFunction,
        });
    }
    catch (error) {
        result = 'ERROR';
        logCalcErrors &&
            console.error(`Error in calculation. section: ${section?.id}, field: ${fieldId}, calc: '${calc}'`);
        logCalcErrors && console.error(error);
    }
    return result;
};
exports.resolveCalc = resolveCalc;
const updateAllCalcs = (definition, entries) => {
    if (!definition.normalized)
        throw new Error('WorksheetDefinition must be normalized');
    const { sectionsBySectionId } = definition;
    const resultCache = (0, CalcCache_1.createResultCache)();
    const entriesBySectionId = (0, worksheetEntryTools_1.getWorksheetEntriesBySectionId)(definition, entries);
    const entriesByNextEntryIdBySectionId = {};
    const cachedGetEntriesByNextEntryId = (sectionId) => entriesByNextEntryIdBySectionId[sectionId] ||
        (entriesByNextEntryIdBySectionId[sectionId] = (0, worksheetEntryTools_1.getEntriesByNextEntryId)(
        // TODO: if this section uses sortBy and that is a calc field, we need to resolve all those fields first
        entriesBySectionId[sectionId], sectionsBySectionId[sectionId]));
    const calcField = (section, fieldDef, entry) => {
        if (!entry)
            return undefined;
        if ((0, CalcCache_1.isValueCached)(resultCache, section.id, entry.id, fieldDef.id))
            return (0, CalcCache_1.getCachedValue)(resultCache, section.id, entry.id, fieldDef.id);
        // prevent recursion
        (0, CalcCache_1.setCachedValue)(resultCache, section.id, entry.id, fieldDef.id, undefined);
        return (0, CalcCache_1.setCachedValue)(resultCache, section.id, entry.id, fieldDef.id, (0, exports.resolveCalc)(definition, entriesBySectionId, fieldDef.calc, fieldDef.id, entry, section, calcField, cachedGetEntriesByNextEntryId));
    };
    // find all fields with calculations and perform their calculations
    definition.sections.forEach((section) => {
        const sectionEntries = entriesBySectionId[section.id] || [];
        (section.fields || []).forEach((fieldDef) => {
            if (fieldDef.fieldType === 'calc') {
                sectionEntries.forEach((entry) => {
                    calcField(section, fieldDef, entry);
                });
            }
        });
    });
    // merge the current data for all entries with the updated, calculated values in resultsCache
    let foundChange = false;
    const newEntries = entries.map((entry) => {
        const newEntry = resultCache[entry.sectionId]?.[entry.id];
        if (newEntry) {
            const dataWithNulls = {
                ...entry.data,
                ...(0, CalcCache_1.cachedCalcValuesToWorksheetFieldValues)(resultCache[entry.sectionId][entry.id]),
            };
            const data = {};
            for (const k in dataWithNulls) {
                const v = dataWithNulls[k];
                if (v != null)
                    data[k] = v;
            }
            if (!(0, lodash_1.isEqual)(data, entry.data)) {
                foundChange = true;
                return { ...entry, data };
            }
        }
        return entry;
    });
    return foundChange ? newEntries : entries;
};
exports.updateAllCalcs = updateAllCalcs;
