import { capitalize, isNil } from 'lodash';
import { DateTime } from 'luxon';

import { type User, type UserBasicInfoFragment } from '#graphql';

const csvEncodeField = (field: string) => {
  field = field
    .replaceAll('"', '""')
    .replaceAll(String.fromCharCode(181), 'u') // µ
    .replaceAll(String.fromCharCode(956), 'u'); // μ

  field = /[,]/.test(field) ? `"${field}"` : field;
  return field;
};

export function formatId(id: string | number | null | undefined) {
  if (!isNil(id) && id !== '' && id !== 0) {
    const wordList = id.toString().split(' ');

    return wordList.length === 1
      ? wordList[0]
      : wordList
          .map((word, index) => {
            const trimmed = word.trim();
            return !isNil(trimmed) ? (index < 1 ? trimmed.toLowerCase() : capitalize(trimmed)) : '';
          })
          .filter((value) => !isNil(value))
          .join('');
  }
  return undefined;
}

export function getRandomInt(max = 1000): number {
  return Math.floor(Math.random() * max);
}

export const getUserFullName = (user: User | UserBasicInfoFragment) =>
  !isNil(user.firstName) && !isNil(user.lastName)
    ? `${user.firstName} ${user.lastName}`
    : user.firstName ?? user.lastName ?? user.email;

export const getUserInitials = (user: User | UserBasicInfoFragment | null) => {
  if (isNil(user)) return '';
  let initials = `${user.email[0]}${user.email[1]}`;
  if (!isNil(user.firstName) && !isNil(user.lastName)) {
    initials = `${user.firstName[0]}${user.lastName[0]}`;
  } else if (!isNil(user.lastName)) {
    initials = `${user.lastName[0]}${user.lastName[1]}`;
  } else if (!isNil(user.firstName)) {
    initials = `${user.firstName[0]}${user.firstName[1]}`;
  }
  return initials.toUpperCase();
};

export const getCharacterCount = (limit: number, currentLength: number) => `${currentLength ?? 0}/${limit}`;

export const csvEncodeLine = (fields: string[]) => fields.map(csvEncodeField).join(',');

export function unCapitalize(string: string) {
  return string.charAt(0).toLowerCase() + string.slice(1);
}

export function formatDate(isoString: string) {
  return DateTime.fromISO(isoString).toFormat('LL/dd/yyyy');
}

export function formatTime(timeString: string | null | undefined) {
  return isNil(timeString) ? timeString : DateTime.fromISO(timeString).toLocaleString(DateTime.TIME_SIMPLE);
}
