import { Box, FormHelperText, type TableRowProps, type Theme } from '@mui/material';
import { isEmpty, isNil } from 'lodash';
import { useEffect } from 'react';
import { useFormContext, useWatch, type UseFormGetValues, type UseFormSetValue } from 'react-hook-form';

import { OmniTextField, ReactHookCheckbox } from '#components/widgets';
import { OmniTable, type OmniTableColDef } from '#components/widgets/OmniTable';
import ReactHookRadioButton from '#components/widgets/ReactHookRadioButton';
import { type TreatmentGroupWithTreatment } from '#lib/types';
import { type TreatmentGroupsFormData } from './bioanalysis';

interface TreatmentGroupsFormSectionProps {
  treatmentGroups: TreatmentGroupWithTreatment[];
}

interface TableRowModel {
  error: boolean;
  numRowsInGroup: number;
  checked: boolean;
  fieldName: (name: string) => string;
  isFirstRowOfGroup: boolean;
  treatmentGroupIndex: string;
  treatmentNumber: string;
  testArticleName: string;
  testArticleId: string;
  doseNumber: string;
  doseAmount: string;
  interval: string;
  tableRowProps: TableRowProps;
}

export function TreatmentGroupsFormSection({ treatmentGroups }: TreatmentGroupsFormSectionProps) {
  const { formState, getValues, register, setValue, trigger } = useFormContext<TreatmentGroupsFormData>();
  const { submitCount } = formState;

  const treatmentGroupsValue = useWatch<TreatmentGroupsFormData, 'treatmentGroups'>({ name: 'treatmentGroups' });

  register('treatmentGroupsValidity', {
    validate(_value, values: TreatmentGroupsFormData) {
      const checkedGroups = values.treatmentGroups.filter((tg) => tg.checked);

      if (isEmpty(checkedGroups) || checkedGroups.some((tg) => tg.baseTestArticleId === '')) {
        return 'please select at least one group and a corresponding basis test article';
      }
    },
  });

  useEffect(() => {
    if (submitCount > 0) {
      void trigger('treatmentGroupsValidity');
    }
  }, [treatmentGroupsValue, submitCount]);

  const groupsErrorMessage = formState.errors.treatmentGroupsValidity?.message;
  const hasError = !isNil(groupsErrorMessage) && formState.isDirty;

  const rows = getRows(treatmentGroups, treatmentGroupsValue, hasError);

  const columns = getColumns();

  const headerActions = getHeaderActions(getValues, setValue);

  const groupsError = hasError ? <FormHelperText error>{groupsErrorMessage}</FormHelperText> : undefined;

  return (
    <>
      <OmniTable
        name="treatmentGroups"
        columns={columns}
        headerActions={headerActions}
        headerFields={groupsError}
        headerLabel="Groups to Include *"
        rows={rows}
        size="small"
      />
    </>
  );
}

function getRows(
  treatmentGroups: TreatmentGroupWithTreatment[],
  treatmentGroupsValue: TreatmentGroupsFormData['treatmentGroups'],
  hasError: boolean
): TableRowModel[] {
  return treatmentGroupsValue.flatMap((treatmentGroupValue, rowIndex) => {
    const treatmentGroup = treatmentGroups.find((tg) => tg.id === treatmentGroupValue.treatmentGroupId);

    // Should never happen
    if (isNil(treatmentGroup)) {
      throw new Error();
    }

    const numRowsInGroup = treatmentGroup.treatment.testArticles.length;

    const backgroundColor = {
      '&.MuiTableRow-root': (theme: Theme) => ({
        backgroundColor: rowIndex % 2 === 1 ? theme.palette.action.hover : 'inherit',
      }),
    };

    const [firstTestArticle, ...remainingTestArticles] = treatmentGroup.treatment.testArticles;

    const common = {
      error: hasError && (!treatmentGroupValue.checked || treatmentGroupValue.baseTestArticleId === ''),
      numRowsInGroup,
      checked: treatmentGroupValue.checked,
      fieldName: (name: string) => `treatmentGroups.${rowIndex}.${name}`,
    };

    return [
      {
        ...common,
        isFirstRowOfGroup: true,
        treatmentGroupIndex: treatmentGroup.treatmentGroupIndex.toString(),
        treatmentNumber: treatmentGroup.treatment.number.toString(),
        testArticleName: firstTestArticle.name,
        testArticleId: firstTestArticle.id.toString(),
        doseNumber: firstTestArticle.doseNumber?.toString() ?? '',
        doseAmount: `${firstTestArticle.dosage} ${firstTestArticle.doseUnit}`,
        interval: firstTestArticle.interval?.toString() ?? '',
        tableRowProps: {
          sx: {
            ...(numRowsInGroup > 1 ? { 'td:not(:last-child), th': { borderBottom: 0 } } : {}),
            ...backgroundColor,
          },
        },
      },
      ...remainingTestArticles.map((testArticle, testArticleIndex) => ({
        ...common,
        isFirstRowOfGroup: false,
        treatmentGroupIndex: '',
        treatmentNumber: '',
        testArticleName: testArticle.name,
        testArticleId: testArticle.id.toString(),
        doseNumber: testArticle.doseNumber?.toString() ?? '',
        doseAmount: `${testArticle.dosage} ${testArticle.doseUnit}`,
        interval: testArticle.interval?.toString() ?? '',
        tableRowProps: {
          sx: {
            ...(testArticleIndex < numRowsInGroup - 2 ? { 'td:not(:last-child), th': { borderBottom: 0 } } : {}),
            ...backgroundColor,
          },
        },
      })),
    ];
  });
}

function getColumns(): Array<OmniTableColDef<TableRowModel>> {
  return [
    {
      field: 'groupSelected',
      headerName: '',
      renderCell: (row) => {
        return row.isFirstRowOfGroup ? (
          <ReactHookCheckbox
            controller={{
              name: row.fieldName('checked'),
            }}
            error={row.error && !row.checked}
          />
        ) : (
          ''
        );
      },
    },
    {
      field: 'treatmentGroupIndex',
      headerName: 'Group',
    },
    {
      field: 'treatmentNumber',
      headerName: 'Treatment',
    },
    {
      field: 'isBasis',
      headerName: 'Basis',
      renderCell: (row) => {
        return (
          <ReactHookRadioButton
            controller={{
              name: row.fieldName('baseTestArticleId'),
            }}
            disabled={!row.checked}
            value={row.testArticleId}
            error={row.error}
          />
        );
      },
      tableCellProps: (row) => ({
        ...(row.isFirstRowOfGroup ? {} : { className: 'notFirstRowOfGroup' }),
      }),
    },
    {
      field: 'testArticleName',
      headerName: 'Test Article(s)',
      renderCell: (row) => (
        <Box
          title={row.testArticleName}
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {row.testArticleName}
        </Box>
      ),
      tableCellProps: (row) => ({
        ...(row.isFirstRowOfGroup ? {} : { className: 'notFirstRowOfGroup' }),
        sx: {
          maxWidth: '150px',
        },
      }),
    },
    {
      field: 'doseNumber',
      headerName: '# Dose',
      tableCellProps: (row) => ({
        ...(row.isFirstRowOfGroup ? {} : { className: 'notFirstRowOfGroup' }),
      }),
    },
    {
      field: 'doseAmount',
      headerName: 'Dose Amount',
      renderCell: (row) => (
        <Box
          title={row.doseAmount}
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {row.doseAmount}
        </Box>
      ),
      tableCellProps: (row) => ({
        ...(row.isFirstRowOfGroup ? {} : { className: 'notFirstRowOfGroup' }),
        sx: {
          maxWidth: '120px',
        },
      }),
    },
    {
      field: 'interval',
      headerName: 'Interval',
      tableCellProps: (row) => ({
        ...(row.isFirstRowOfGroup ? {} : { className: 'notFirstRowOfGroup' }),
      }),
    },
    {
      field: 'notes',
      tableCellProps: (row) => ({
        ...(row.isFirstRowOfGroup ? { rowSpan: row.numRowsInGroup } : { sx: { padding: '0px' } }),
      }),
      renderCell: (row) => {
        if (row.isFirstRowOfGroup) {
          return (
            <OmniTextField
              disabled={!row.checked}
              hideErrorMessage
              label=""
              minRows={row.numRowsInGroup}
              multiline
              name={row.fieldName('notes')}
              InputProps={{
                sx: {
                  fontSize: '.75rem',
                  paddingX: 1,
                  paddingY: 0.5,
                  minWidth: 200,
                  height: 27 * row.numRowsInGroup,
                  lineHeight: '24px',
                },
              }}
              inputProps={{
                height: '100%',
              }}
            />
          );
        }
        return '';
      },
    },
  ];
}

function getHeaderActions(
  getValues: UseFormGetValues<TreatmentGroupsFormData>,
  setValue: UseFormSetValue<TreatmentGroupsFormData>
) {
  function updateAll(value: boolean) {
    getValues('treatmentGroups').forEach((_treatmentGroupValue, rowIndex) => {
      const field = `treatmentGroups.${rowIndex}.checked` as const;
      setValue(field, value, { shouldDirty: true, shouldValidate: true, shouldTouch: true });
    });
  }

  return [
    {
      label: 'Select All',
      onClick() {
        updateAll(true);
      },
    },
    {
      label: 'Deselect All',
      onClick() {
        updateAll(false);
      },
    },
  ];
}
