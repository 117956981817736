import { Dropzone, FileItem } from '@dropzone-ui/react';
import { Button, Stack, Typography } from '@mui/material';
import { isEmpty, isNil } from 'lodash';
import toast from 'react-hot-toast';

import { type AdcOrderItemDetailsFragment } from '#graphql';
import { AppColors, i18n } from '#lib/constants';
import { useUploadAdcOrder } from './useUploadAdcOrder';

interface UploadAdcOrdersProps {
  adcOrderItems: AdcOrderItemDetailsFragment[];
  experimentName: string;
  onUpload: () => void;
}

export const UploadAdcOrders = ({ adcOrderItems, experimentName, onUpload }: UploadAdcOrdersProps) => {
  const { error, onChange, onClean, onDelete, handleFile, value } = useUploadAdcOrder(adcOrderItems, experimentName);

  async function uploadFile() {
    try {
      await toast.promise(handleFile(), i18n.UploadAdcOrderItemCsv);
      onUpload();
    } catch (_error: unknown) {
      // do nothing, errors are already handled
    }
  }

  return (
    <Stack gap={2} padding={2}>
      <Dropzone
        onChange={onChange}
        value={value}
        onClean={onClean}
        maxFileSize={104857600}
        maxHeight="10em"
        label="Drop file here or click to browse"
        behaviour="add"
        color={AppColors.BRAND_GREEN}
        accept=".csv"
        maxFiles={1}
      >
        {value.map((file) => (
          <FileItem
            {...file}
            key={file.id}
            onDelete={onDelete}
            alwaysActive
            preview
            info
            elevation={4}
            resultOnTooltip
          />
        ))}
      </Dropzone>
      <Stack direction="row" justifyContent="center" gap={2}>
        <Button disabled={isEmpty(value)} disableElevation onClick={uploadFile} variant="contained">
          Upload
        </Button>
        <Button disableElevation onClick={onUpload} variant="outlined">
          Cancel
        </Button>
      </Stack>
      {!isNil(error) && (
        <Stack gap={1} width="100%">
          <Typography fontWeight="bold" color={(theme) => theme.palette.error.main}>
            Errors
          </Typography>
          <Stack gap={0.5} width="100%" paddingLeft={1}>
            {error.formattedMessages().map((message, index) => (
              <Typography key={index} color={(theme) => theme.palette.error.main}>
                - {message}
              </Typography>
            ))}
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};
