import { Container } from '@mui/material';
import { DateTime } from 'luxon';
import { useCreateInventoryMutation } from '#graphql';
import { PurchaseOrderForm, PurchaseOrderFormData } from './PurchaseOrderForm';

export function CreatePurchaseOrder() {
  const [createInventory] = useCreateInventoryMutation();

  async function handleSubmit(data: PurchaseOrderFormData) {
    await createInventory({
      variables: {
        input: {
          ...data,
          quantity: parseInt(data.quantity),
          dob: DateTime.fromISO(data.dob).toUTC().toISO()!,
        },
      },
    });
  }

  return (
    <Container maxWidth="sm">
      <PurchaseOrderForm handleSubmit={handleSubmit} />
    </Container>
  );
}
