import { ArrowBack } from '@mui/icons-material';
import DownloadIcon from '@mui/icons-material/Download';
import { Box, MenuItem, Typography, type SelectChangeEvent } from '@mui/material';
import { type WorksheetStatus } from '@omnivivo/worksheets-core';
import fileDownload from 'js-file-download';
import { isNil, startCase } from 'lodash';
import { useCallback } from 'react';
import toast from 'react-hot-toast';

import { ConfirmationModal } from '#components/partials';
import {
  useClearPkTissueWorksheetCollectionsActualTimeMutation,
  useExperimentByIdLazyQuery,
  useUpdatePkTissueWorksheetStatusMutation,
  type ListContentDetailsFragment,
  type PkTissueWorksheetCollectionFragment,
  type PkTissueWorksheetFragment,
} from '#graphql';
import { i18n } from '#lib/constants/i18n';
import { getExperimentPKTissue } from '#lib/export';
import { useModal } from '#lib/hooks';
import { Button, CircleLink, RoundSelect } from '#rds';

// should live elsewhere
const worksheetStatusList: WorksheetStatus[] = ['notStarted', 'started', 'complete'];

interface HeaderProps {
  bioanalysisTimepoints: ListContentDetailsFragment[];
  canClearActualTimes?: boolean;
  worksheet: PkTissueWorksheetFragment & {
    pkTissueWorksheetCollections?: PkTissueWorksheetCollectionFragment[];
  };
}

/**
 * TODO
 * - resolve console error by updating select to controlled component
 * - use common worksheet status
 */
export function Header(props: HeaderProps) {
  const { bioanalysisTimepoints, canClearActualTimes = false, worksheet } = props;

  const { experimentId, status, id } = worksheet;

  const [updateStatus] = useUpdatePkTissueWorksheetStatusMutation();
  const [clearActualTimes] = useClearPkTissueWorksheetCollectionsActualTimeMutation();
  const [getExperiment] = useExperimentByIdLazyQuery({
    variables: {
      id: experimentId,
    },
  });

  const onSelectStatus = useCallback(
    (event: SelectChangeEvent<string>) => {
      void toast.promise(
        updateStatus({
          variables: {
            input: {
              id,
              status: event.target.value,
            },
          },
        }),
        i18n.PKTissueWorksheet.UpdateStatus
      );
    },
    [updateStatus]
  );

  const onDownload = useCallback(async () => {
    const fileName = 'PK_Tissue_Worksheet.csv';
    try {
      const experiment = (await getExperiment()).data?.experiment;
      // won't happen
      if (isNil(experiment)) {
        throw new Error('Could not find experiment');
      }
      const content = getExperimentPKTissue(experiment, bioanalysisTimepoints, worksheet);
      fileDownload(content, fileName);
    } catch (error: unknown) {
      console.error(error);
      toast.error(i18n.Errors.INTERNAL_SERVER_ERROR);
    }
  }, [worksheet]);

  async function onDelete() {
    await clearActualTimes({
      variables: {
        input: {
          id,
        },
      },
    });
  }

  const { Modal: DeleteModal, openModal: openDeleteModal, closeModal: closeDeleteModal } = useModal(null, 'sm');

  async function handleDelete() {
    await toast.promise(onDelete(), i18n.PKTissueWorksheet.DeletePKTissueCollection);
    closeDeleteModal();
  }

  return (
    <Box display="flex" justifyContent="space-between" paddingX={2} paddingY={1} bgcolor="primary.light">
      <DeleteModal title="Clear Actual Times" content={i18n.PKTissueWorksheet.DeletePKTissueCollection.Confirmation}>
        <ConfirmationModal
          closeModal={closeDeleteModal}
          confirmationFunction={() => {
            void handleDelete();
          }}
        />
      </DeleteModal>
      <Box display="flex" gap={2} alignItems="center">
        <CircleLink to={`/configure/${experimentId}/workspace`}>
          <ArrowBack fontSize="inherit" />
        </CircleLink>
        <Typography variant="h3">Data Collection - PK Tissue Worksheet</Typography>
      </Box>
      <Box display="flex" justifyContent="flexEnd" alignItems="center" gap={2}>
        {canClearActualTimes && (
          <Button color="error" variant="contained" onClick={openDeleteModal}>
            Clear Collection Data
          </Button>
        )}
        <RoundSelect defaultValue={status} onChange={onSelectStatus}>
          {worksheetStatusList.map((status) => (
            <MenuItem key={status} value={status}>
              {startCase(status)}
            </MenuItem>
          ))}
        </RoundSelect>
        <Button variant="contained" startIcon={<DownloadIcon />} onClick={onDownload}>
          Download
        </Button>
      </Box>
    </Box>
  );
}
