import { useMemo } from 'react';
import { InputLabel, TextField } from '@mui/material';
import { debounce } from 'lodash';
import toast from 'react-hot-toast';

import { AdcOrderDetailsFragment, useUpdateAdcOrderMutation } from '#graphql';
import { DEFAULT_DEBOUNCE } from '#lib/constants';
import { i18n } from '#lib/constants';

interface AdcNotesProps {
  adcOrder: AdcOrderDetailsFragment;
  disabled?: boolean;
  hiddenLabel?: boolean;
}

export const AdcNotes = ({
  adcOrder,
  disabled,
  hiddenLabel,
}: AdcNotesProps) => {
  const [updateAdcOrder] = useUpdateAdcOrderMutation();

  const onNotesChange = useMemo(
    () =>
      debounce(
        (notes) =>
          toast.promise(
            updateAdcOrder({
              variables: {
                input: {
                  id: adcOrder.id,
                  notes,
                },
              },
            }),
            i18n.SaveAdcOrderNotes
          ),
        DEFAULT_DEBOUNCE
      ),
    []
  );

  return (
    <div>
      {!hiddenLabel && (
        <InputLabel htmlFor={`${adcOrder.id}-notes`}>Notes</InputLabel>
      )}
      <TextField
        disabled={disabled}
        defaultValue={adcOrder.notes}
        multiline
        fullWidth
        name="notes"
        minRows={2}
        id={`${adcOrder.id}-notes`}
        onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
          onNotesChange(ev.target.value);
        }}
      />
    </div>
  );
};
