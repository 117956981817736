import { TimeField as MuiTimeField } from '@mui/x-date-pickers';
import { AppColors } from '@omnivivo/style';
import { isNil } from 'lodash';
import { DateTime } from 'luxon';

import { mediumTextStyle } from '#lib/constants/typography';
import { Button, InputAdornment } from '@mui/material';
import { type CommonProps } from './WorksheetComponentInterfaces';

const currentTimeButtonStyles = {
  borderRadius: '38px',
  color: AppColors.BRAND_GREEN,
  fontSize: '10px',
  fontWeight: 'bold',
  padding: '0px',
  minWidth: '52px',
};

export function TimeField(props: CommonProps) {
  const {
    inputElementProps: { onChange, value },
  } = props;

  const dateValue = isNil(value) || value === '' ? undefined : DateTime.fromJSDate(new Date(value));

  return (
    <MuiTimeField
      format="hh:mm a"
      label=""
      onChange={(value, context) => {
        if (!isNil(context.validationError)) {
          return;
        }
        const valueStr = DateTime.isDateTime(value) ? value.toJSDate() : value;
        onChange?.(valueStr);
      }}
      value={dateValue}
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          if (event.shiftKey) {
            if (!isNil(props.onGoUpInput)) {
              props.onGoUpInput(event.currentTarget.id);
              event.preventDefault();
            }
          } else {
            if (!isNil(props.onGoDownInput)) {
              props.onGoDownInput(event.currentTarget.id);
              event.preventDefault();
            }
          }
        }
      }}
      slotProps={{
        textField: {
          id: props.id,
          size: 'small',
          InputProps: {
            sx: {
              ...mediumTextStyle,
              '.MuiOutlinedInput-input': {
                paddingRight: '5px',
                paddingTop: '3px',
                paddingLeft: '5px',
                paddingBottom: '3px',
              },
            },
            endAdornment: (
              <InputAdornment position="end">
                <Button
                  sx={currentTimeButtonStyles}
                  variant="outlined"
                  onClick={() => props.handleTimeChange?.(new Date())}
                >
                  Now
                </Button>
              </InputAdornment>
            ),
          },
          sx: {
            '.MuiOutlinedInput-root': {
              height: '100%',
              paddingRight: '5px',
            },
          },
        },
      }}
    />
  );
}
