import styled from '@emotion/styled';
import { AddOutlined, CloseOutlined } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { type GridColumns, type GridRenderCellParams } from '@mui/x-data-grid-premium';
import { AppColors } from '@omnivivo/style';
import { compact, difference, isEmpty, isNil } from 'lodash';
import toast from 'react-hot-toast';

import { ConfirmationModal } from '#components/partials/ConfirmationModal';
import { DefinitionList, OmniGrid, type DefinitionListProps } from '#components/widgets';
import {
  type BioanalysisCollectionScheduleFragment,
  type BioanalysisTreatmentGroupFragment,
  type ListContentDetailsFragment,
  type TestArticle,
} from '#graphql';
import { useModal } from '#lib/hooks';
import { type TreatmentGroupWithTreatment } from '#lib/types';
import { StyledButton, StyledButtonContainer, StyledCloseButton, StyledGroupCard } from '../Cards/styles';

interface DeleteTexts {
  Delete: {
    Confirmation: string;
    loading: string;
    success: string;
    error: string;
  };
}

interface TreatmentGroupRow {
  id: number;
  group: number;
  treatment: number;
  testArticles: TestArticle[];
  baseTestArticleId: number;
  notes: string | undefined;
}

interface BioanalysisDataProps {
  bioanalysisTreatmentGroups: BioanalysisTreatmentGroupFragment[];
  canEdit: boolean;
  definitions: DefinitionListProps['definitions'];
  deleteTexts: DeleteTexts;
  handleEdit: () => void;
  onDelete: () => Promise<void>;
  treatmentGroups: TreatmentGroupWithTreatment[];
  collectionSchedules?: BioanalysisCollectionScheduleFragment[];
  bioanalysisTimepoints: ListContentDetailsFragment[];
}

export const StyledHeader = styled.div({
  display: 'flex',
  background: AppColors.LIGHT_LIGHT_GREY,
  padding: '10px',
  minWidth: '1150px',
  flexDirection: 'column',
});

const StyledContainer = styled.div({
  padding: '10px',
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  overflowX: 'auto',
});

const treatmentGroupColumns: GridColumns<TreatmentGroupRow> = [
  {
    field: 'group',
    flex: 1,
    headerName: 'Group',
    sortable: false,
  },
  {
    field: 'treatment',
    flex: 1,
    headerName: 'Treatment',
    sortable: false,
  },
  {
    field: 'testArticles',
    flex: 2,
    headerName: 'Test Articles (*Basis Test Article)',
    sortable: false,
    renderCell(params: GridRenderCellParams<TreatmentGroupRow['testArticles'], TreatmentGroupRow>) {
      return (
        <Typography component="ul" paddingLeft={2}>
          {params.value?.map((testArticle) => (
            <li key={testArticle.id}>
              {testArticleSummary(testArticle, params.row.baseTestArticleId === testArticle.id)}
            </li>
          ))}
        </Typography>
      );
    },
  },
  {
    field: 'notes',
    flex: 2,
    headerName: 'Notes',
    sortable: false,
  },
];

const collectionScheduleColumns = [
  {
    field: 'dose',
    flex: 1,
    headerName: 'Dose',
    sortable: false,
  },
  {
    field: 'minutes',
    flex: 1,
    headerName: 'Timepoint',
    sortable: false,
  },
  {
    field: 'onlyAnimals',
    flex: 2,
    headerName: 'Animals',
    sortable: false,
  },
];

function testArticleSummary(testArticle: TestArticle, baseTestArticle: boolean) {
  const testArticleName = `${testArticle.name}: ${testArticle.dosage} ${testArticle.doseUnit} q${testArticle.interval}dx${testArticle.doseNumber}`;
  return (
    <Typography variant="body2" sx={{ fontWeight: baseTestArticle ? 'bold' : 'normal' }}>
      {testArticleName}
    </Typography>
  );
}

export function BioanalysisDataView(props: BioanalysisDataProps) {
  const {
    bioanalysisTreatmentGroups,
    canEdit,
    definitions,
    deleteTexts,
    handleEdit,
    onDelete,
    treatmentGroups,
    collectionSchedules,
    bioanalysisTimepoints,
  } = props;

  const {
    Modal: DeleteModal,
    openModal: openDeleteModal,
    closeModal: closeDeleteModal,
  } = useModal(deleteTexts.Delete.Confirmation, 'sm');

  async function handleDelete() {
    await toast.promise(onDelete(), deleteTexts.Delete);
    closeDeleteModal();
  }

  const treatmentGroupRows: TreatmentGroupRow[] = compact(
    treatmentGroups.map((tg) => {
      const btg = bioanalysisTreatmentGroups.find((btg) => btg.treatmentGroupId === tg.id);

      if (isNil(btg)) {
        return undefined;
      }

      return {
        id: btg.id,
        group: tg.treatmentGroupIndex,
        treatment: tg.treatment.number,
        testArticles: tg.treatment.testArticles,
        baseTestArticleId: btg.baseTestArticleId,
        notes: btg.notes,
      };
    })
  );

  const nonDefaultCollectionScheduleGroups =
    collectionSchedules?.flatMap((collectionSchedule) => collectionSchedule.onlyGroups) ?? [];
  const treatmentGroupIndices = compact(
    bioanalysisTreatmentGroups.map(
      (bioanalysisTreatmentGroup) =>
        treatmentGroups.find((tg) => tg.id === bioanalysisTreatmentGroup.treatmentGroupId)?.treatmentGroupIndex
    )
  );
  const defaultCollectionScheduleGroups = difference(treatmentGroupIndices, nonDefaultCollectionScheduleGroups);

  return (
    <>
      <DeleteModal>
        <ConfirmationModal
          closeModal={closeDeleteModal}
          confirmationFunction={() => {
            void handleDelete();
          }}
        />
      </DeleteModal>

      <StyledGroupCard>
        <Box display="flex" justifyContent="space-between">
          <Box padding={2} sx={{ background: AppColors.LIGHT_LIGHT_GREY, flex: 1 }}>
            <DefinitionList definitions={definitions} />
          </Box>
          {canEdit && (
            <Box display="flex" alignItems="center" paddingLeft={2} sx={{ background: AppColors.LIGHT_GREY }}>
              <StyledButtonContainer gap="5px">
                <StyledButton onClick={handleEdit}>
                  <AddOutlined /> Edit
                </StyledButton>
                <StyledCloseButton background={AppColors.WHITE} onClick={openDeleteModal}>
                  <CloseOutlined fontSize="small" />
                </StyledCloseButton>
              </StyledButtonContainer>
            </Box>
          )}
        </Box>
        <StyledContainer>
          <Typography fontSize=".75rem" fontWeight="bold">
            Groups
          </Typography>
          <OmniGrid
            columns={treatmentGroupColumns}
            dataGridProps={{
              autoHeight: true,
              disableColumnFilter: true,
              disableColumnMenu: true,
              disableSelectionOnClick: true,
              getRowHeight: () => 'auto',
              hideFooter: true,
              sx: {
                '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
                  py: (theme) => theme.spacing(1),
                  alignItems: 'flex-start',
                },
              },
            }}
            defaultPageSize={treatmentGroupRows.length}
            refetch={() => undefined}
            rows={treatmentGroupRows}
            rowCount={treatmentGroupRows.length}
            showQuickFilter={false}
          />
        </StyledContainer>
        <StyledContainer>
          {isEmpty(collectionSchedules) ? (
            ''
          ) : (
            <Typography fontSize=".75rem" fontWeight="bold">
              Collection Schedules
            </Typography>
          )}
        </StyledContainer>
        {collectionSchedules?.map((collectionSchedule, collectionScheduleIndex) => {
          const rowItems = compact(
            collectionSchedule.timepoints.map((timePointsItem) => {
              const animals = isEmpty(timePointsItem.onlyAnimals) ? 'all' : timePointsItem.onlyAnimals.join(',');
              const timepoint = bioanalysisTimepoints.find(
                (bioanalysisTimepoint) => bioanalysisTimepoint.name === timePointsItem?.minutes.toString()
              )?.description;

              return {
                id: timePointsItem.id,
                dose: timePointsItem.dose,
                minutes: timepoint,
                onlyAnimals: animals,
              };
            })
          );
          return (
            <StyledContainer key={collectionSchedule.id}>
              {collectionSchedule.isDefault ? (
                <>
                  <Typography fontSize=".75rem">Default Collection Schedule</Typography>
                  <Typography fontSize=".75rem">{`Applies to group(s): ${
                    isEmpty(defaultCollectionScheduleGroups) ? 'none' : defaultCollectionScheduleGroups.toString()
                  }`}</Typography>
                </>
              ) : (
                <>
                  <Typography fontSize=".75rem">{`Collection Schedule ${collectionScheduleIndex + 1}`}</Typography>
                  <Typography fontSize=".75rem">{`Applies to group(s): ${collectionSchedule.onlyGroups.toString()}`}</Typography>
                </>
              )}
              <OmniGrid
                columns={collectionScheduleColumns}
                dataGridProps={{
                  autoHeight: true,
                  disableColumnFilter: true,
                  disableColumnMenu: true,
                  disableSelectionOnClick: true,
                  getRowHeight: () => 'auto',
                  hideFooter: true,
                  sx: {
                    '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
                      py: (theme) => theme.spacing(1),
                    },
                  },
                }}
                defaultPageSize={rowItems.length}
                refetch={() => undefined}
                rows={rowItems}
                rowCount={rowItems.length}
                showQuickFilter={false}
              />
            </StyledContainer>
          );
        })}
      </StyledGroupCard>
    </>
  );
}
