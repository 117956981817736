import { Route, Routes } from 'react-router';
import { NotFound } from '#components/pages';
import { CanRoute } from '#components/widgets';
import { CreateUser } from './CreateUser';
import { EditUser } from './EditUser';
import { Users } from './Users';
import { UsersPage } from './UsersPage';

export function UsersRoutes() {
  return (
    <Routes>
      <Route path="/" element={<UsersPage />}>
        <Route
          index
          element={
            <CanRoute I="list" a="user">
              <Users />
            </CanRoute>
          }
        />

        <Route
          path="create"
          element={
            <CanRoute I="create" a="user">
              <CreateUser />
            </CanRoute>
          }
        />

        <Route path=":id/edit" element={<EditUser />} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}
