import { type FieldValues } from 'react-hook-form';

import { OmniTextField, type OmniTextFieldProps } from './OmniTextField';

interface OmniIntegerFieldProps<TFormData extends FieldValues> extends OmniTextFieldProps<TFormData> {}

export function OmniIntegerField<TFormData extends FieldValues>(props: OmniIntegerFieldProps<TFormData>) {
  // @ts-expect-error -- not sure how to address right now
  return <OmniTextField {...props} formatValue={formatValue} setValueAs={setValueAs} />;
}

function formatValue(value: string) {
  const output = parseInt(value, 10);
  return isNaN(output) ? '' : output.toString();
}

function setValueAs(value: string) {
  const output = parseInt(value, 10);
  return isNaN(output) ? '' : output;
}
