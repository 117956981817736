import { useRef, useEffect } from 'react';

export const useClickOutside = (action: () => void) => {
  // Setting type as "Any" for referencing any type of object of the DOM.
  let domNode = useRef<any>();
  useEffect(() => {
    let eventListener = (event: MouseEvent) => {
      if (!domNode.current.contains(event.target)) action();
    };

    document.addEventListener('mousedown', eventListener);

    return () => {
      document.removeEventListener('mousedown', eventListener);
    };
  });

  return domNode;
};
