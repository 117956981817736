import { Route, Routes } from 'react-router';
import { NotFound } from '#components/pages';
import { CanRoute } from '#components/widgets';
import { EditProfile } from './EditProfile';
import { ProfilePage } from './ProfilePage';

export function ProfileRoutes() {
  return (
    <Routes>
      <Route path="/" element={<ProfilePage />}>
        <Route
          index
          element={
            <CanRoute I="update" a="user">
              <EditProfile />
            </CanRoute>
          }
        />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}
