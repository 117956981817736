export enum CroSpecies {
  mouse = 'mouse',
  human = 'human',
}

export enum CultureProperties {
  adherent = 'adherent',
  suspension = 'suspension',
}
export enum ModelType {
  cdx = 'cell-line-derived-xenograft',
  pdx = 'patient-derived-xenograft',
  syngeneic = 'syngeneic',
}
