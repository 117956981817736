import { Box, FormHelperText, type TableRowProps, type Theme } from '@mui/material';
import { isNil } from 'lodash';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { OmniTextField, ReactHookCheckbox } from '#components/widgets';
import { OmniTable, type OmniTableColDef } from '#components/widgets/OmniTable';
import { useEffect } from 'react';
import { type TissueBioanalysisFormData } from './tissueBioanalysis';

interface TableRowModel {
  error: boolean;
  numRowsInGroup: number;
  checked: boolean;
  fieldName: (name: string) => string;
  notesFieldName: string;
  isFirstRowOfGroup: boolean;
  tissue: string;
  method: string;
  includeWeight: boolean;
  notes: string;
  tableRowProps: TableRowProps;
}

export function HandlingAndStorageFormSection() {
  const { formState, register, trigger } = useFormContext<TissueBioanalysisFormData>();
  const { fields: tissuesValue } = useFieldArray<TissueBioanalysisFormData, 'tissues'>({
    name: 'tissues',
  });

  const { submitCount } = formState;

  register('handlingAndStorageValidity', {
    validate(_value, values: TissueBioanalysisFormData) {
      const missingHandlingAndStorage = values.tissues.some(
        (tissue) => !tissue.handlingAndStorage.some((handlingAndStorage) => handlingAndStorage.checked)
      );

      if (missingHandlingAndStorage) {
        return 'please select at least one handling and storage method for each tissue';
      }
    },
  });

  useEffect(() => {
    if (submitCount > 0) {
      void trigger('handlingAndStorageValidity');
    }
  }, [tissuesValue, submitCount]);

  const handlingAndStorageErrorMessage = formState.errors.handlingAndStorageValidity?.message;
  const hasError = !isNil(handlingAndStorageErrorMessage) && formState.isDirty;

  const handlingAndStorageError = hasError ? (
    <FormHelperText error>{handlingAndStorageErrorMessage}</FormHelperText>
  ) : undefined;

  const columns = getColumns();

  const rows = getRows(tissuesValue, hasError);

  return (
    <>
      <OmniTable
        name="tissues"
        columns={columns}
        headerLabel="Handling and Storage Method(s) *"
        headerFields={handlingAndStorageError}
        rows={rows}
        size="small"
      />
    </>
  );
}

function getRows(tissuesValue: TissueBioanalysisFormData['tissues'], hasError: boolean): TableRowModel[] {
  return tissuesValue.flatMap((tissueValue, rowIndex) => {
    const backgroundColor = {
      '&.MuiTableRow-root': (theme: Theme) => ({
        backgroundColor: rowIndex % 2 === 1 ? theme.palette.action.hover : 'inherit',
      }),
    };

    const numRowsInGroup = tissueValue.handlingAndStorage.length;

    const missingHandlingAndStorage = !tissueValue.handlingAndStorage.some(
      (handlingAndStorage) => handlingAndStorage.checked
    );

    return tissueValue.handlingAndStorage.map((handlingAndStorage, groupIndex) => {
      const fieldName = (name: string) => `tissues.${rowIndex}.handlingAndStorage.${groupIndex}.${name}`;
      const notesFieldName = `tissues.${rowIndex}.notes`;

      const isFirstRowOfGroup = groupIndex === 0;

      const error = hasError && missingHandlingAndStorage;
      return {
        ...handlingAndStorage,
        error,
        numRowsInGroup,
        fieldName,
        notesFieldName,
        isFirstRowOfGroup,
        tissue: tissueValue.tissue,
        notes: tissueValue.notes ?? '',
        tableRowProps: {
          sx: {
            ...(isFirstRowOfGroup
              ? numRowsInGroup > 1
                ? { 'td:not(:last-child), th': { borderBottom: 0 } }
                : {}
              : groupIndex < numRowsInGroup - 1
              ? { 'td:not(:last-child), th': { borderBottom: 0 } }
              : {}),
            ...backgroundColor,
          },
        },
      };
    });
  });
}

function getColumns(): Array<OmniTableColDef<TableRowModel>> {
  return [
    {
      field: 'tissue',
      headerName: 'Tissue',
      renderCell(row) {
        return row.isFirstRowOfGroup ? row.tissue : '';
      },
    },
    {
      field: 'method',
      headerName: 'Handling and Storage Method',
      renderCell(row) {
        return (
          <ReactHookCheckbox
            controller={{
              name: row.fieldName('checked'),
            }}
            error={row.error && !row.checked}
            label={row.method}
            size="small"
          />
        );
      },
      tableCellProps: (row) => ({
        ...(row.isFirstRowOfGroup ? {} : { className: 'notFirstRowOfGroup' }),
      }),
    },
    {
      field: 'includeWeight',
      headerName: 'Include Weight',
      renderCell(row) {
        return (
          <Box
            paddingLeft={3}
            sx={{
              '.MuiFormControlLabel-label': {
                fontSize: '.75rem',
              },
            }}
          >
            <ReactHookCheckbox
              controller={{
                name: row.fieldName('includeWeight'),
              }}
              disabled={!row.checked}
              error={row.error && !row.checked}
              size="small"
            />
          </Box>
        );
      },
      tableCellProps: (row) => ({
        ...(row.isFirstRowOfGroup ? {} : { className: 'notFirstRowOfGroup' }),
      }),
    },
    {
      field: 'notes',
      tableCellProps: (row) => ({
        ...(row.isFirstRowOfGroup ? { rowSpan: row.numRowsInGroup } : { sx: { padding: '0px' } }),
      }),
      renderCell: (row) => {
        if (row.isFirstRowOfGroup) {
          return (
            <OmniTextField
              hideErrorMessage
              label=""
              minRows={row.numRowsInGroup}
              multiline
              name={row.notesFieldName}
              InputProps={{
                sx: {
                  fontSize: '.75rem',
                  paddingX: 1,
                  paddingY: 0.5,
                  minWidth: 200,
                  height: 27 * row.numRowsInGroup,
                  lineHeight: '24px',
                },
              }}
              inputProps={{
                height: '100%',
              }}
            />
          );
        }
        return '';
      },
    },
  ];
}
