import styled from '@emotion/styled';
import bgImage from '#assets/login-bg-image.png';
import { AppColors, LOGIN_PATH, i18n } from '#lib/constants';
import { ButtonPositiveSolid } from '#components/widgets';

const StyledContainer = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundImage: `url(${bgImage})`,
  height: '100vh',
});

const StyledBox = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  background: AppColors.WHITE,
  borderRadius: '4px',
  boxShadow: '2px 2px 6px rgba(0,0,0,0.2)',
  padding: '30px',
});

const StyledTitleText = styled.h3(
  ({ size, align }: { size: string; align?: boolean }) => ({
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: size,
    alignSelf: align ? 'flex-start' : '',
    lineHeight: '24px',
    color: AppColors.BLACK,
  })
);

const StyledSubTitleText = styled.span({
  fontFamily: 'Roboto',
  fontSize: '12px',
  fontWeight: '400',
  lineHeight: '14px',
  letterSpacing: '0.5px',
  color: AppColors.GREY,
});

const StyledSeparator = styled.div({
  border: `1px solid ${AppColors.LIGHT_GREY}`,
  width: '15vw',
  margin: '20px 0',
});

const StyledButton = styled(ButtonPositiveSolid)({
  background: AppColors.BRAND_GREEN,
  borderRadius: '28px',
  color: AppColors.WHITE,
  letterSpacing: '1.25px',
  textTransform: 'uppercase',
  fontSize: '10px',
  fontWeight: 'bold',
  fontStyle: 'normal',
  fontFamily: 'Roboto',
  padding: '10px',
  width: '241px',
  marginBottom: '25px',

  '&:hover': {
    background: AppColors.BRAND_GREEN,
  },

  a: {
    width: 'inherit',
  },
});

export const LoginPage = () => {
  return (
    <StyledContainer>
      <StyledBox>
        <StyledTitleText size="18px">Welcome to {i18n.AppName}</StyledTitleText>
        <StyledSubTitleText>Seagen's experiment tracker</StyledSubTitleText>
        <StyledSeparator />
        <StyledButton data-test-id={`button-login-ad`} href={LOGIN_PATH}>
          Login With Active Directory
        </StyledButton>
      </StyledBox>
    </StyledContainer>
  );
};
