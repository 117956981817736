import { useDeleteImagingMutation, type Imaging, ExperimentByIdDocument } from '#graphql';
import { i18n } from '#lib/constants';
import { type TreatmentGroupWithTreatment } from '#lib/types';
import { BioanalysisData } from './BioanalysisData';

interface ImagingDataProps {
  canEdit: boolean;
  handleEdit: () => void;
  imaging: Imaging;
  treatmentGroups: TreatmentGroupWithTreatment[];
}

export function ImagingData(props: ImagingDataProps) {
  const { canEdit, handleEdit, imaging, treatmentGroups } = props;

  const [deleteImaging] = useDeleteImagingMutation({
    awaitRefetchQueries: true,
    refetchQueries: [ExperimentByIdDocument, 'experimentById'],
  });

  async function onDelete() {
    await deleteImaging({
      variables: {
        input: {
          id: imaging.id,
        },
      },
    });
  }

  const definitions = {
    Timepoints: imaging.timePoints,
    Instructions: imaging.instructions ?? '',
    'Number of Views': imaging.numViews,
  };

  return (
    <BioanalysisData
      bioanalysisTreatmentGroups={imaging.treatmentGroups}
      canEdit={canEdit}
      definitions={definitions}
      deleteTexts={i18n.Imaging}
      handleEdit={handleEdit}
      onDelete={onDelete}
      treatmentGroups={treatmentGroups}
    />
  );
}
