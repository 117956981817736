import { type GridRowId } from '@mui/x-data-grid-premium';
import { DateTime } from 'luxon';

import { SetCurrentTimeButton } from '../SetCurrentTimeButton';
import { useRowContext } from './rowContext';

interface DetailPanelRowSetCurrentTimeButtonProps {
  id: GridRowId;
  disabled?: boolean;
}

export function DetailPanelRowSetCurrentTimeButton(props: DetailPanelRowSetCurrentTimeButtonProps) {
  const { id, disabled = false } = props;

  const { updateActualTime } = useRowContext();

  function setCurrentTime() {
    void updateActualTime(id, DateTime.now());
  }

  return <SetCurrentTimeButton disabled={disabled} setCurrentTime={setCurrentTime} />;
}
