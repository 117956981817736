"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.cachedCalcValuesToWorksheetFieldValues = exports.setCachedValue = exports.getCachedValue = exports.isValueCached = exports.createResultCache = void 0;
const calcResultToWorksheetFieldValue = (a) => (a === null ? undefined : a);
const createResultCache = () => ({});
exports.createResultCache = createResultCache;
const isValueCached = (resultCache, sectionId, entryId, fieldId) => {
    const sectionResultCache = resultCache[sectionId];
    const entryResultCache = sectionResultCache && sectionResultCache[entryId];
    return undefined !== (entryResultCache && entryResultCache[fieldId]);
};
exports.isValueCached = isValueCached;
const getCachedValue = (resultCache, sectionId, entryId, fieldId, _default) => {
    const sectionResultCache = resultCache[sectionId];
    const entryResultCache = sectionResultCache && sectionResultCache[entryId];
    const cachedResult = entryResultCache && entryResultCache[fieldId];
    return cachedResult === undefined
        ? _default
        : calcResultToWorksheetFieldValue(cachedResult);
};
exports.getCachedValue = getCachedValue;
const setCachedValue = (resultCache, sectionId, entryId, fieldId, value) => {
    const sectionResultCache = (resultCache[sectionId] ??= {});
    const entryResultCache = (sectionResultCache[entryId] ??= {});
    entryResultCache[fieldId] = value === undefined ? null : value;
    return value;
};
exports.setCachedValue = setCachedValue;
const cachedCalcValuesToWorksheetFieldValues = (entryResultCache) => {
    const result = {};
    for (const k in entryResultCache) {
        const v = entryResultCache[k];
        result[k] = v === null ? undefined : Array.isArray(v) ? v[0] : v;
    }
    return result;
};
exports.cachedCalcValuesToWorksheetFieldValues = cachedCalcValuesToWorksheetFieldValues;
