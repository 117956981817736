import Cookies from 'js-cookie';

import { JWT_NAME } from '../constants';

const token = Cookies.get(JWT_NAME);

const API_URL = process.env.REACT_APP_API_URI;
const authHeader = { 'x-ms-client-principal': token || '' };

export const postRequest = ({
  endpoint,
  data = {},
}: {
  endpoint: string;
  data: any;
}) =>
  fetch(`${API_URL}/${endpoint}`, {
    credentials: 'same-origin',
    method: 'POST',
    body: data,
    headers: {
      ...authHeader,
    },
  });

export const getRequest = ({
  endpoint,
  responseType = 'blob',
}: {
  endpoint: string;
  responseType?: string;
}) =>
  fetch(`${API_URL}/${endpoint}`, {
    credentials: 'same-origin',
    method: 'GET',
    headers: {
      ...authHeader,
      'Content-Type': responseType,
    },
  });

export const deleteRequest = ({
  endpoint,
}: {
  endpoint: string;
}): Promise<Response> =>
  fetch(`${API_URL}/${endpoint}`, {
    credentials: 'same-origin',
    method: 'DELETE',
    headers: {
      ...authHeader,
    },
  });
