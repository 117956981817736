import { AddOutlined, Cached, CloseOutlined, ContentCopy } from '@mui/icons-material';
import { Box, Checkbox, Chip, MenuItem, Stack, type SelectChangeEvent } from '@mui/material';
import { find, isEmpty, isNil, last, map, sortBy } from 'lodash';
import { useEffect, useState, type ChangeEvent } from 'react';
import toast from 'react-hot-toast';

import { Can } from '#components/contexts';
import { AnimalIcon } from '#components/widgets';
import {
  ExperimentByIdDocument,
  useAddTreatmentGroupMutation,
  useDeleteTreatmentGroupMutation,
  useDuplicateTreatmentGroupMutation,
  usePopulateTreatmentGroupsMutation,
  useUpdateTreatmentGroupMutation,
  type ExperimentDetailsFragment,
  type TreatmentGroup,
} from '#graphql';
import { AppColors, i18n } from '#lib/constants';
import { useListContent, useModal, useUpdateExperiment } from '#lib/hooks';
import { getDefaultGroupValues } from '#lib/utils';

import { ConfirmationModal, TreatmentGroupCardContent } from '#components/partials';
import {
  StyledAnimalBox,
  StyledBubble,
  StyledButton,
  StyledButtonContainer,
  StyledCircle,
  StyledCloseButton,
  StyledGroupCard,
  StyledGroupSelect,
  StyledHeader,
  StyledLabel,
  StyledLabelCheckbox,
  StyledNumberField,
  StyledNumberTextField,
  StyledPopulatedButton,
  StyledRoundedBox,
  StyledRow,
  StyledTreatmentBox,
  StyledWorksheetTypeBox,
} from './styles';

interface GroupCardProps {
  onTreatmentGroupCountChanged: (treatmentGroupCount: number) => void;
  invalid: boolean;
  disabled: boolean;
  experiment: ExperimentDetailsFragment;
}

export const GroupCard = ({ onTreatmentGroupCountChanged, invalid, experiment, disabled }: GroupCardProps) => {
  const { updateExperimentCache } = useUpdateExperiment();
  const { animalList } = useListContent();

  const [disablePopulateButton, setDisablePopulateButton] = useState<boolean>(false);

  useEffect(() => {
    onTreatmentGroupCountChanged(experiment.treatmentGroups.length);
  }, [experiment.treatmentGroups]);

  const {
    Modal: DeleteTreatmentGroup,
    openModal: openDeleteTreatmentGroup,
    closeModal: closeDeleteTreatmentGroup,
  } = useModal(i18n.TreatmentGroupCard.DeleteConfirmation, 'sm');

  const [currentGroupId, setCurrentGroupId] = useState<number>();

  const [duplicateGroup] = useDuplicateTreatmentGroupMutation();

  const [deleteGroup] = useDeleteTreatmentGroupMutation({
    awaitRefetchQueries: true,
    refetchQueries: [ExperimentByIdDocument, 'experimentById'],
  });

  const [updateTreatmentGroup, { loading: submitting }] = useUpdateTreatmentGroupMutation({
    awaitRefetchQueries: true,
    refetchQueries: [ExperimentByIdDocument, 'experimentById'],
  });

  const [addTreatmentGroup] = useAddTreatmentGroupMutation();

  const [populateTreatmentGroups] = usePopulateTreatmentGroupsMutation({
    variables: {
      experimentId: experiment.id,
    },
  });

  const save = async (updatedField: Partial<TreatmentGroup>, treatmentGroup: TreatmentGroup) => {
    await toast.promise(
      updateTreatmentGroup({
        variables: {
          input: {
            id: treatmentGroup.id,
            ...updatedField,
          },
        },
      }),
      i18n.TreatmentGroupCard.UpdateTreatmentGroup
    );
  };

  const handleDuplicateGroup = async (id: number) => {
    const tid = toast(i18n.Common.Duplicating);
    const response = await duplicateGroup({ variables: { id } });
    updateExperimentCache({
      id: experiment.id,
      treatmentGroups: response.data?.duplicateTreatmentGroup,
    });
    toast.dismiss(tid);
  };

  const handleDeleteGroup = async (id?: number) => {
    closeDeleteTreatmentGroup();
    if (!isNil(id)) {
      updateExperimentCache({
        treatmentGroups: experiment.treatmentGroups.filter((tg) => tg.id !== id),
        id: experiment.id,
      });
      await toast.promise(deleteGroup({ variables: { id } }), i18n.TreatmentGroupCard.DeleteTreatmentGroup);
    }
  };

  const handleAddGroup = async () => {
    if (isNil(experiment.models) || isEmpty(experiment.models)) {
      toast.error(i18n.ExperimentView.NoModelsDefined);
      return;
    }
    if (isNil(experiment.treatments) || isEmpty(experiment.treatments)) {
      toast.error(i18n.ExperimentView.NoTreatmentsDefined);
      return;
    }

    const overageValue = experiment.CRO ? 'overageCRO' : 'overage';

    // Either the first model without a treatment group, sorted by name, or the last one
    const sortedModels = sortBy(experiment.models, 'name');
    const tgModelIds = map(experiment.treatmentGroups, 'modelId');

    /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion --
     *  We would have already returned above if the there are no models
     **/
    const model = sortedModels.find((model) => !tgModelIds.includes(model.id)) ?? last(sortedModels)!;

    // Either the first treatment without a treatment group, sorted by number, or the last one
    const sortedTreatments = sortBy(experiment.treatments, 'number');
    const tgTreatmentIds = map(experiment.treatmentGroups, 'treatmentId');

    /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion --
     *  We would have already returned above if the there are no treatments
     **/
    const treatment =
      sortedTreatments.find((treatment) => !tgTreatmentIds.includes(treatment.id)) ?? last(sortedTreatments)!;

    const input = {
      experimentId: experiment.id,
      treatmentId: treatment.id,
      treatmentGroupIndex: isEmpty(experiment.treatmentGroups)
        ? 1
        : Math.max(...experiment.treatmentGroups.map((tg) => tg.treatmentGroupIndex)) + 1,
      modelCount: 0,
      modelId: model.id,
      overage: getDefaultGroupValues(animalList ?? [], overageValue),
    };
    const tid = toast.loading(i18n.Common.Saving);
    const response = await addTreatmentGroup({
      variables: {
        input,
      },
    });
    if (!isNil(response?.data)) {
      updateExperimentCache({
        id: experiment.id,
        treatmentGroups: response.data.addTreatmentGroupToExperiment,
      });
    }
    toast.dismiss(tid);
  };

  const handlePopulateTreatmentGroups = async () => {
    setDisablePopulateButton(true);
    const tid = toast.loading(i18n.ExperimentView.PopulateTreatmentGroups);
    const response = await populateTreatmentGroups();
    if (!isNil(response?.data)) {
      updateExperimentCache({
        id: experiment.id,
        treatmentGroups: response.data?.populateTreatmentGroups,
      });
    }
    toast.dismiss(tid);
    setDisablePopulateButton(false);
  };

  const displayNewBioanalysis =
    process.env.REACT_APP_FEATURE_BIOANALYSIS === 'true' &&
    isNil(experiment.worksheets?.find((worksheet) => worksheet.type === 'bioanalysis'));

  const displayNewImaging =
    process.env.REACT_APP_FEATURE_BIOANALYSIS === 'true' &&
    isNil(experiment.worksheets?.find((worksheet) => worksheet.type === 'imaging'));

  return (
    <>
      <DeleteTreatmentGroup>
        <ConfirmationModal
          closeModal={() => {
            setCurrentGroupId(undefined);
            closeDeleteTreatmentGroup();
          }}
          confirmationFunction={async () => {
            await handleDeleteGroup(currentGroupId);
          }}
        />
      </DeleteTreatmentGroup>
      {experiment.treatmentGroups.map((group) => (
        <StyledGroupCard key={group.treatmentGroupIndex} data-test-id="treatmentGroupCard">
          <StyledHeader>
            <Box display="flex" alignItems="center" gap={2}>
              <StyledCircle>{group.treatmentGroupIndex}</StyledCircle>

              <StyledRoundedBox>
                <StyledAnimalBox>
                  <AnimalIcon specie={find(experiment.models, { id: group.modelId })?.species ?? 'mouse'} />
                  <StyledNumberField
                    defaultValue={group.modelCount ?? ''}
                    type="number"
                    disabled={disabled || submitting}
                    onBlur={async (ev: React.FocusEvent<HTMLInputElement>) => {
                      await save({ modelCount: Number(ev.target.value) }, group);
                    }}
                  />
                  {!(experiment.models.length === 0) && (
                    <StyledGroupSelect
                      displayEmpty
                      defaultValue={group.modelId}
                      disabled={disabled || submitting}
                      sx={{
                        '&.Mui-disabled': {
                          background: 'none',
                        },
                      }}
                      onChange={async (ev: SelectChangeEvent<unknown>) => {
                        await save({ modelId: Number(ev.target.value) }, group);
                      }}
                    >
                      {experiment.models.map((model) => (
                        <MenuItem key={model.id} value={model.id}>
                          {`Model ${model.name}`}
                        </MenuItem>
                      ))}
                    </StyledGroupSelect>
                  )}
                </StyledAnimalBox>

                <StyledBubble customwidth="390px">
                  {find(experiment.models, { id: group.modelId })?.cellLine?.name ?? ''}
                </StyledBubble>
              </StyledRoundedBox>

              <StyledTreatmentBox>
                {!(experiment.treatments.length === 0) && (
                  <StyledGroupSelect
                    displayEmpty
                    defaultValue={group.treatmentId}
                    disabled={disabled || submitting}
                    onChange={async (ev: SelectChangeEvent<unknown>) => {
                      await save({ treatmentId: Number(ev.target.value) }, group);
                    }}
                    sx={{
                      '&.Mui-disabled': {
                        background: 'none',
                      },
                    }}
                  >
                    {experiment.treatments.map((treatment) => (
                      <MenuItem key={treatment.id} value={treatment.id}>
                        {`Treatment ${treatment.number}`}
                      </MenuItem>
                    ))}
                  </StyledGroupSelect>
                )}
              </StyledTreatmentBox>

              <StyledLabel>Overage</StyledLabel>
              <StyledNumberTextField
                defaultValue={group.overage}
                type="text"
                disabled={disabled || submitting}
                onBlur={async (ev: React.FocusEvent<HTMLInputElement>) => {
                  const overage = +ev.target.value;
                  if (isNaN(overage)) {
                    toast.error('Invalid number');
                  } else {
                    await save({ overage }, group);
                  }
                }}
              />

              {(displayNewBioanalysis || displayNewImaging) && (
                <Stack direction="row" spacing={1}>
                  {group.hasBioanalysis === true && <Chip size="small" label="Bioanalysis" color="primary" />}
                  {group.hasImaging === true && <Chip size="small" label="Imaging" color="secondary" />}
                </Stack>
              )}

              {!(displayNewBioanalysis && displayNewImaging) && (
                <StyledWorksheetTypeBox>
                  {!displayNewBioanalysis && (
                    <StyledLabelCheckbox
                      control={
                        <Checkbox
                          disabled={disabled || submitting}
                          defaultChecked={group.hasBioanalysis}
                          onChange={async (ev: ChangeEvent<HTMLInputElement>) => {
                            await save({ hasBioanalysis: ev.target.checked }, group);
                          }}
                        />
                      }
                      label="BioAnalysis"
                    />
                  )}
                  {!displayNewImaging && (
                    <StyledLabelCheckbox
                      control={
                        <Can I="update" this={experiment} field="treatmentGroups.hasImaging" passThrough>
                          {(allowed) => (
                            <Checkbox
                              disabled={!allowed || submitting}
                              data-test-id="treatmentGroupHasImaging"
                              defaultChecked={group.hasImaging}
                              onChange={async (ev: ChangeEvent<HTMLInputElement>) => {
                                await save({ hasImaging: ev.target.checked }, group);
                              }}
                            />
                          )}
                        </Can>
                      }
                      label="Imaging"
                    />
                  )}
                </StyledWorksheetTypeBox>
              )}
            </Box>

            <Box display="flex" justifyContent="flex-end">
              {!disabled && (
                <StyledRow data-test-id="treatmentGroupActions">
                  <StyledCloseButton
                    marginRight="0"
                    onClick={async () => {
                      await handleDuplicateGroup(group.id);
                    }}
                  >
                    <ContentCopy fontSize="small" />
                  </StyledCloseButton>
                  <StyledCloseButton
                    background={AppColors.WHITE}
                    data-test-id="deleteTreatmentGroup"
                    onClick={() => {
                      setCurrentGroupId(group.id);
                      openDeleteTreatmentGroup();
                    }}
                  >
                    <CloseOutlined fontSize="small" />
                  </StyledCloseButton>
                </StyledRow>
              )}
            </Box>
          </StyledHeader>

          <TreatmentGroupCardContent treatmentGroup={group} experiment={experiment} />
        </StyledGroupCard>
      ))}
      <StyledButtonContainer gap="5px">
        <StyledPopulatedButton
          disabled={disabled || disablePopulateButton || experiment.treatmentGroups.length > 0}
          onClick={async () => {
            await handlePopulateTreatmentGroups();
          }}
          data-test-id={`populateTG`}
        >
          <Cached /> Populate
        </StyledPopulatedButton>
        <StyledButton disabled={disabled} onClick={handleAddGroup} invalid={invalid} data-test-id="addTreatmentGroup">
          <AddOutlined /> Add Group
        </StyledButton>
      </StyledButtonContainer>
    </>
  );
};
