import { isNil, omit } from 'lodash';
import { type FieldValues } from 'react-hook-form';

import { OmniSelectField, type OmniSelectFieldProps } from './OmniSelectField';
import { OmniTextField, type OmniTextFieldProps } from './OmniTextField';

export type OmniFieldProps<TFormData extends FieldValues> = { select?: boolean } & (
  | OmniTextFieldProps<TFormData>
  | OmniSelectFieldProps<TFormData>
);

export function OmniField<TFormData extends FieldValues>(props: OmniFieldProps<TFormData>) {
  return !isNil(props.select) && 'selectOptions' in props ? (
    <OmniSelectField {...omit(props, 'select')} />
  ) : (
    <OmniTextField {...props} />
  );
}
