"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.imaging = void 0;
const style_1 = require("@omnivivo/style");
const worksheets_core_1 = require("@omnivivo/worksheets-core");
const ExperimentInjectedSections_1 = require("./ExperimentInjectedSections");
exports.imaging = (0, worksheets_core_1.getNormalizeWorksheetDefinition)({
    worksheetName: 'Imaging',
    color: style_1.AppColors.RED,
    sections: [
        ...ExperimentInjectedSections_1.experimentInjectedSectionDefinitions,
        {
            id: 'requirements',
            sectionType: 'form',
            sectionName: 'Requirements',
            fields: [
                {
                    id: 'timePoints',
                    fieldName: 'Time points',
                    fieldType: 'string',
                    gridWidth: 6,
                },
                {
                    id: 'instructions',
                    fieldName: 'Instructions',
                    fieldType: 'longText',
                    gridWidth: 12,
                },
            ],
        },
        {
            id: 'animalsToInclude',
            sectionType: 'table',
            sectionName: 'Animals to include',
            allowAddRows: true,
            fields: [
                {
                    id: 'treatmentGroup',
                    fieldName: 'Group',
                    fieldType: 'singleSelect',
                    fieldValuesCalc: 'select("experimentTreatmentGroups", "id", ["hasImaging", true])',
                    fieldValueLabelsCalc: 'select("experimentTreatmentGroups", "name", ["hasImaging", true])',
                    gridWidth: 3,
                },
                {
                    id: 'treatmentGroupIndex',
                    fieldName: 'Group Index',
                    fieldType: 'readOnly',
                    hidden: true,
                },
                {
                    id: 'notes',
                    fieldName: 'Notes',
                    fieldType: 'string',
                    gridWidth: 6,
                },
            ],
        },
    ],
});
