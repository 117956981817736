import { type ApolloError } from '@apollo/client';
import PublishedWithChangesIcon from '@mui/icons-material/Check';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import SyncProblemIcon from '@mui/icons-material/SyncProblem';
import { CircularProgress, Tooltip } from '@mui/material';
import { isNil } from 'lodash';

type Size = 'small' | 'medium';

const sizes = new Map<Size, number>([
  ['small', 14],
  ['medium', 26],
]);

export interface SyncIndicatorProps {
  dirty: boolean;
  error?: ApolloError;
  loading: boolean;
  size?: Size;
}

export const SyncIndicator = ({ dirty, error, loading, size = 'medium' }: SyncIndicatorProps) => {
  if (loading) {
    return <CircularProgress size={sizes.get(size)} color="warning" />;
  }

  if (!isNil(error)) {
    return (
      <Tooltip title={error.message}>
        <SyncProblemIcon color="error" />
      </Tooltip>
    );
  }

  if (dirty) {
    return <MoreHorizIcon color="info" />;
  }

  return <PublishedWithChangesIcon color="success" />;
};
