import fileDownload from 'js-file-download';
import { DateTime } from 'luxon';
import { get, sortBy, isNull, isNil } from 'lodash';

import { type AdcOrderWithExperimentFragment } from '#graphql';
import { i18n } from '#lib/constants';

const ADC_REQUESTED_FORMAT_DATE = 'MM/dd/yyyy';

export function getFormattedDate(date?: string) {
  return !isNil(date) ? DateTime.fromISO(date).toFormat(ADC_REQUESTED_FORMAT_DATE) : '';
}

export function generateCSVInfo(adcOrder: AdcOrderWithExperimentFragment, experimentName?: string) {
  const headers = [
    ...Object.values(i18n.AdcOrderDataHeadersWithoutOverage),
    ...Object.values(i18n.AdcOrderFulfillmentHeaders),
  ].join(',');
  const fields = [
    ...Object.keys(i18n.AdcOrderDataHeadersWithoutOverage),
    ...Object.keys(i18n.AdcOrderFulfillmentHeaders),
  ];

  const values = sortBy(adcOrder.adcOrderItems, [
    'isBioanalysis',
    'bioregId',
    'purpose',
    'bioregId', // Sorting a second time for In Vivo Dose.
  ])
    .map((aoi) => ({
      ...aoi,
      approvalCount: get(adcOrder, 'experiment.approvalCount'),
      lastUpdated: `"${getFormattedDate(get(adcOrder, 'experiment.updatedAt'))}"`,
      name: `"${aoi.conjugate.sgName}"`,
      dotmaticId: `"${aoi.conjugate.dotmaticId}"`,
      antibodyName: `"${aoi.conjugate.antibodyName}"`,
      experimentName,
      quantity: Number(aoi.quantity ?? 0).toFixed(2),
      deliveredAmount: Number(aoi.deliveredAmount ?? aoi.quantity ?? 0).toFixed(2),
      purpose: `"${aoi.purpose}"`,
      mgPerMl: !aoi.isBioanalysis || aoi.purpose === 'In Vivo Dose' ? `"${Number(aoi.mgPerMl).toFixed(2)}"` : 'N/A',
      formulation: `"${aoi.formulation ?? ''}"`,
      a280: Number(aoi.a280 ?? 0).toFixed(2),
      deliveredAt: !isNull(aoi.deliveredAt) ? `"${getFormattedDate(aoi.deliveredAt)}"` : '',
    }))
    .map((aoiJson: Record<string, any>) => fields.map((field) => aoiJson[field]).join(','))
    .join('\n');

  return {
    csvContent: `${headers}\n${values}`,
    fileName: `order-${adcOrder.id}${!isNull(experimentName) ? `-${experimentName}` : ''}.csv`,
  };
}

export function download(adcOrder: AdcOrderWithExperimentFragment, experimentName?: string) {
  const csvInfo = generateCSVInfo(adcOrder, experimentName);

  fileDownload(csvInfo.csvContent, csvInfo.fileName);
}
