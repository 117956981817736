import styled from '@emotion/styled';
import { ButtonPositive } from '#components/widgets';
import MenuPlaceholder from '#assets/placeholder-settings-menu.svg';
import { useNavigate } from 'react-router';

const StyledContainer = styled.div({
  flex: '0 0 290px',
  background: '#FFFFFF',
  height: '247px',
  boxShadow: '2px 2px 6px rgba(0, 0, 0, 0.2)',
  borderRadius: '4px',
});

const StyledImageWrapper = styled.div({
  display: 'flex',
  justifyContent: 'center',
});

const StyledImg = styled.img({
  maxWidth: '300px',
  maxHeight: '126px',
});

const StyledTextDescription = styled.div({
  fontFamily: 'Roboto',
  fontWeight: 'normal',
  fontStyle: 'normal',
  fontSize: '12px',
  lineHeight: '14px',
  letterSpacing: '0.5px',
  color: '#00000099',
  padding: '0 20px 10px 20px',
  display: 'flex',
  marginTop: '20px',
});

const StyledButtonWrapper = styled.div({
  display: 'flex',
  justifyContent: 'space-around',
});

const StyledButton = styled(ButtonPositive)({
  border: 'none',
  fontSize: '10px',
  marginTop: '14px',
});

const StyledTitleText = styled.h4({
  color: '#000000de',
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 'bold',
  fontSize: '14px',
  letterSpacing: '0.15px',
});

type SettingsMenuCardProps = {
  title: string;
  image: string;
  description: string;
  path: string;
};

const SettingsMenuCard = ({
  title,
  image,
  description,
  path,
}: SettingsMenuCardProps) => {
  const navigate = useNavigate();

  return (
    <StyledContainer data-test-id={`settingsMenuCard`}>
      <StyledButtonWrapper data-test-id={`settings${title}Card`}>
        <StyledTitleText>{title}</StyledTitleText>
        <StyledButton
          data-test-id={`settingsCardButton`}
          onClick={() => navigate(path)}
        >
          Open
        </StyledButton>
      </StyledButtonWrapper>
      <StyledImageWrapper>
        <StyledImg src={image || MenuPlaceholder} />
      </StyledImageWrapper>
      <StyledTextDescription>{description}</StyledTextDescription>
    </StyledContainer>
  );
};

export default SettingsMenuCard;
