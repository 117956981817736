import { useCallback } from 'react';

import { ExperimentByIdDocument, useUpdateTissueBioanalysisMutation, type TissueBioanalysisFragment } from '#graphql';
import { type TreatmentGroupWithTreatment } from '#lib/types';
import { TissueBioanalysisForm } from './TissueBioanalysisForm';
import { formDataToUpdateInput, type TissueBioanalysisFormData } from './tissueBioanalysis';

interface EditTissueBioanalysisProps {
  tissueBioanalysis: TissueBioanalysisFragment;
  onCancel?: () => void;
  onSuccess?: () => void;
  treatmentGroups: TreatmentGroupWithTreatment[];
}

export function EditTissueBioanalysis(props: EditTissueBioanalysisProps) {
  const { tissueBioanalysis, onCancel = () => undefined, onSuccess = () => undefined, treatmentGroups } = props;

  const [updateTissueBioanalysis] = useUpdateTissueBioanalysisMutation({
    awaitRefetchQueries: true,
    refetchQueries: [ExperimentByIdDocument, 'experimentById'],
  });

  const handleSubmit = useCallback(
    async function handleSubmit(data: TissueBioanalysisFormData) {
      await updateTissueBioanalysis({
        variables: {
          input: formDataToUpdateInput(tissueBioanalysis.id, data, treatmentGroups),
        },
      });
    },
    [updateTissueBioanalysis, treatmentGroups]
  );

  return (
    <TissueBioanalysisForm
      tissueBioanalysis={tissueBioanalysis}
      handleSubmit={handleSubmit}
      onCancel={onCancel}
      onSuccess={onSuccess}
      treatmentGroups={treatmentGroups}
    />
  );
}
