import {
  getEditableFieldCount,
  getEntriesByRowIdColumnId,
  type WorksheetEntriesByRowIdColumnId,
  type WorksheetEntry,
  type WorksheetSectionDefinition,
} from '@omnivivo/worksheets-core';
import { isNil } from 'lodash';
import React, { useState } from 'react';
import toast from 'react-hot-toast';

import { ConfirmationModal } from '#components/partials';
import { i18n } from '#lib/constants';
import { useModal } from '#lib/hooks';
import { FormSection } from './FormSection';
import {
  DeleteEntryButton,
  TableColumnLabels,
  TableEntry,
  TableFieldLayoutRow,
  getEntryWidth,
  getTableNavigationByIdsHandlers,
} from './SectionLib';
import {
  Cell,
  ColHeaderCell,
  HeaderRow,
  LayoutCell,
  Row,
  RowLabelCell,
  Table,
  TableWrapper,
} from './SectionStyledComponents';
import { type SectionRenderProps } from './WorksheetComponentInterfaces';

interface SectionColumnId {
  columnSectionId: string;
  sectionId: string;
  columnId: string;
}

const rowLabelWidth = 30;
const deleteButtonWidth = 30;
const rowLabelWidthWithPadding = rowLabelWidth + 20 + deleteButtonWidth + 20;

const DataCollectionRows = ({
  worksheetDefinition,
  rowIds,
  columnIds,
  section,
  worksheetMutations,
  entries,
  getWorksheetState,
}: SectionRenderProps) => {
  const editableColumnCount = getEditableFieldCount(section);

  const entriesByRowIdColumnId: WorksheetEntriesByRowIdColumnId = getEntriesByRowIdColumnId(entries);

  return (
    <>
      {rowIds.map((rowId, rowNumber) => (
        <Row key={`row:${rowId}`}>
          <RowLabelCell sticky>{rowId}</RowLabelCell>
          {columnIds.map((columnId, columnNumber) => {
            const entry = entriesByRowIdColumnId[rowId]?.[columnId];
            return (
              !isNil(entry) && (
                <TableEntry
                  key={`table-entry ${entry.id}`}
                  {...{
                    editableColumnCount,
                    rowNumber,
                    columnNumber,
                    worksheetDefinition,
                    section,
                    entry,
                    worksheetMutations,
                    getWorksheetState,
                    forDataCollection: true,
                    rowId,
                    columnId,
                    ...getTableNavigationByIdsHandlers(section.fields),
                  }}
                />
              )
            );
          })}
          {Boolean(section.allowRemoveRows ?? section.allowAddRows) && (
            <Cell width={`${deleteButtonWidth}px`} data-test-id={`data-collection-delete-row`}>
              <DeleteEntryButton
                deleteAction={() => {
                  worksheetMutations.deleteRow(section.id, rowId);
                }}
              />
            </Cell>
          )}
        </Row>
      ))}
    </>
  );
};

interface DataCollectionSectionHeaderProps extends SectionRenderProps {
  columnSection: WorksheetSectionDefinition;
  colSpan: number;
  deleteColumnAction: (sectionColumnId: SectionColumnId) => void;
}

const ColumnSectionRow = (props: DataCollectionSectionHeaderProps) => {
  const { section, columnSection, columnIds, deleteColumnAction, entriesBySectionId, colSpan } = props;

  const columnEntriesByColumnId: Record<string, WorksheetEntry> = {};
  const columnEntries: WorksheetEntry[] = entriesBySectionId[columnSection.id] ?? [];
  columnEntries.forEach((columnEntry) => {
    columnEntriesByColumnId[columnEntry.columnId ?? ''] = columnEntry;
  });

  return (
    <HeaderRow key="columnSection">
      <RowLabelCell sticky width={`${rowLabelWidth}px`}>
        &nbsp;
      </RowLabelCell>
      {columnIds.map((columnId) => {
        const entry = columnEntriesByColumnId[columnId];
        return (
          <ColHeaderCell data-test-id="treatmentGroupDayHeader" key={`headerCol:${columnId}`} colSpan={colSpan}>
            {Boolean(section.allowRemoveColumns ?? section.allowAddColumns) && (
              <div style={{ textAlign: 'right' }} data-test-id="data-collection-delete-column">
                <DeleteEntryButton
                  deleteAction={() => {
                    deleteColumnAction({ columnSectionId: columnSection.id, sectionId: section.id, columnId });
                  }}
                />
              </div>
            )}
            {
              <FormSection
                {...{
                  ...props,
                  section: columnSection,
                  entries: [entry],
                  columnId,
                }}
              />
            }
          </ColHeaderCell>
        );
      })}
      <Cell width={`${deleteButtonWidth}px`}>&nbsp;</Cell>
    </HeaderRow>
  );
};

const ColumnLabelsRow = (props: SectionRenderProps) => {
  const { section, columnIds } = props;

  return (
    <HeaderRow key="colLabels2">
      <RowLabelCell sticky style={{ width: `${rowLabelWidth}px` }}>
        &nbsp;
      </RowLabelCell>
      {columnIds.map((columnId) => (
        <TableColumnLabels key={columnId} section={section} forDataCollection />
      ))}
      <Cell>&nbsp;</Cell>
    </HeaderRow>
  );
};

const ColumnLayoutRow = (props: SectionRenderProps) => {
  const { section, columnIds } = props;

  return (
    <HeaderRow key="colLabels0">
      <LayoutCell sticky style={{ width: `${rowLabelWidth}px` }}>
        &nbsp;
      </LayoutCell>
      {columnIds.map((columnId) => (
        <TableFieldLayoutRow key={columnId} section={section} forDataCollection />
      ))}
      <LayoutCell>&nbsp;</LayoutCell>
    </HeaderRow>
  );
};

export const DataCollectionSectionBase = (props: SectionRenderProps) => {
  const {
    section: { fields = [], columnSectionId },
    columnIds,
    worksheetMutations: { deleteColumn },
    worksheetDefinition: { sections },
  } = props;

  const [currentSectionColumnId, setCurrentSectionColumnId] = useState<SectionColumnId>();

  const columnSection = !isNil(columnSectionId) && sections.find(({ id }) => id === columnSectionId);

  const {
    Modal: DeleteModal,
    openModal: openDeleteModal,
    closeModal: closeDeleteModal,
  } = useModal(i18n.Worksheet.DeleteColumnConfirmation, 'md');

  const handleColumnDelete = ({ columnSectionId, sectionId, columnId }: SectionColumnId) => {
    closeDeleteModal();
    toast(i18n.Common.Deleting);
    deleteColumn(columnSectionId, sectionId, columnId);
    setCurrentSectionColumnId(undefined);
  };

  function deleteColumnAction(sectionColumnId: SectionColumnId) {
    setCurrentSectionColumnId(sectionColumnId);
    openDeleteModal();
  }

  return (
    <>
      <DeleteModal>
        <ConfirmationModal
          closeModal={() => {
            setCurrentSectionColumnId(undefined);
            closeDeleteModal();
          }}
          confirmationFunction={() => {
            if (!isNil(currentSectionColumnId)) {
              handleColumnDelete(currentSectionColumnId);
            } else {
              toast(i18n.Common.GenericError);
            }
          }}
        />
      </DeleteModal>
      <TableWrapper noScroll>
        <Table
          style={{
            width: rowLabelWidthWithPadding + getEntryWidth(fields) * columnIds.length,
          }}
        >
          <thead>
            <ColumnLayoutRow {...props} />
            {!isNil(columnSection) && columnSection !== false && (
              <ColumnSectionRow
                {...{
                  ...props,
                  colSpan: fields.length,
                  columnSection,
                  deleteColumnAction,
                }}
              />
            )}
            <ColumnLabelsRow {...props} />
          </thead>
          <tbody>
            <DataCollectionRows {...props} />
          </tbody>
        </Table>
      </TableWrapper>
    </>
  );
};

export const DataCollectionSection = React.memo(DataCollectionSectionBase);
