import { Checkbox, Radio, TextField, type TableRowProps } from '@mui/material';
import { omit } from 'lodash';

import { OmniTable, type OmniTableColDef } from '#components/widgets/OmniTable';

type TableRowModel = (typeof flatRows)[number];

// This is necessary because the only way to get the text area to stretch the entire row span
// when the table cell has `display: table-cell` is to set an explicit height.
// This height is specific to the table with size = "small" we can work on improving this.
const ROW_PADDING_Y = 6;
const ROW_CONTENT_HEIGHT = 33;

const columns: Array<OmniTableColDef<TableRowModel>> = [
  {
    field: 'groupSelected',
    headerName: '',
    renderCell: (row) => {
      if (row.isFirstRowOfGroup) {
        return (
          <Checkbox
            color="primary"
            defaultChecked={row.groupSelected}
            size="small"
            inputProps={{ 'aria-labelledby': `treatment-group-${row.treatmentGroupId}` }}
          />
        );
      }
      return '';
    },
  },
  {
    field: 'treatmentGroupIndex',
    headerName: 'Group',
    renderCell: (row) => (row.isFirstRowOfGroup ? row.treatmentGroupIndex : ''),
  },
  {
    field: 'treatmentNumber',
    headerName: 'Treatment',
    renderCell: (row) => (row.isFirstRowOfGroup ? row.treatmentNumber : ''),
  },
  {
    field: 'isBasis',
    headerName: 'Basis',
    renderCell: (row) => {
      return (
        <Radio
          defaultChecked={row.isBasis}
          value={row.testArticleId}
          name={`isbasis-treatment-group-${row.treatmentGroupId}`}
          size="small"
          inputProps={{ 'aria-labelledby': `test-article-${row.name}` }}
        />
      );
    },
  },
  {
    field: 'name',
    headerName: 'Test Article(s)',
    tableCellProps: (row) => ({
      id: `test-article-${row.name}`,
      sx: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        textWrap: 'nowrap',
        maxWidth: '40px',
      },
      title: row.name,
    }),
  },
  { field: 'doseAmount', headerName: 'Dose Amount' },
  {
    field: 'notes',
    tableCellProps: (row) => ({
      ...(row.isFirstRowOfGroup ? { rowSpan: row.numRowsInGroup } : { sx: { padding: '0px' } }),
    }),
    renderCell: (row) => {
      if (row.isFirstRowOfGroup) {
        return (
          <TextField
            fullWidth
            id={`notes-group-${row.treatmentGroupId}`}
            multiline
            rows={row.numRowsInGroup}
            defaultValue={row.notes}
            sx={{
              '.MuiInputBase-inputMultiline': {
                height: `${row.numRowsInGroup * ROW_CONTENT_HEIGHT - ROW_PADDING_Y * 2}px`,
              },
            }}
          />
        );
      }
      return '';
    },
  },
];

const rows = [
  {
    id: 1,
    groupSelected: true,
    treatmentGroupIndex: 1,
    treatmentNumber: 1,
    testArticles: [
      { id: 1, doseAmount: '10mg', name: 'SGD-001002 hksjdh fkjh kjh kjh kjhs dfjh', isBasis: false },
      { id: 2, doseAmount: '15mg', name: 'SGD-001003', isBasis: false },
      { id: 3, doseAmount: '13mg', name: 'SGD-001004', isBasis: false },
    ],
    notes: '',
  },
  {
    id: 2,
    groupSelected: false,
    treatmentGroupIndex: 2,
    treatmentNumber: 1,
    testArticles: [
      { id: 4, doseAmount: '15mg', name: 'SGD-001006', isBasis: false },
      { id: 5, doseAmount: '10mg', name: 'SGD-001007', isBasis: false },
    ],
    notes: '',
  },
  {
    id: 3,
    groupSelected: true,
    treatmentGroupIndex: 3,
    treatmentNumber: 1,
    testArticles: [{ id: 6, doseAmount: '10mg', name: 'SGD-001002', isBasis: false }],
    notes: '',
  },
];

const headerActions = [
  { label: 'Select All', onClick: () => undefined },
  { label: 'Deselect All', onClick: () => undefined },
];

const flatRows = rows.flatMap((row, rowIndex) =>
  row.testArticles.map((testArticle, testArticleIndex) => {
    const tableRowProps: TableRowProps = {
      sx: {
        ...(testArticleIndex < row.testArticles.length - 1 ? { 'td, th': { borderBottom: 0 } } : {}),
        ...(testArticleIndex === 0 && row.testArticles.length > 1
          ? {
              'td:not(:last-child), th:not(:last-child)': { borderBottom: 0 },
            }
          : {}),
        ...(rowIndex % 2 === 1
          ? {
              '&.MuiTableRow-root': (theme) => ({
                backgroundColor: theme.palette.action.hover,
              }),
            }
          : {}),
      },
    };
    return {
      ...omit(row, ['id', 'testArticles']),
      ...omit(testArticle, 'id'),
      isFirstRowOfGroup: testArticleIndex === 0,
      treatmentGroupId: row.id,
      testArticleId: testArticle.id,
      numRowsInGroup: row.testArticles.length,
      tableRowProps,
    };
  })
);

export function GroupsTableExample() {
  return (
    <OmniTable
      columns={columns}
      headerActions={headerActions}
      headerLabel="Groups to Include*"
      rows={flatRows}
      size="small"
    />
  );
}
