import { type WorksheetDefinition } from '@omnivivo/worksheets-core';
import JSZip from 'jszip';
import { compact, difference, isEmpty, isNil, startCase } from 'lodash';
import { DateTime } from 'luxon';

import {
  BLOOD_METHODS,
  BLOOD_TYPES,
} from '#components/partials/ConfigureOverview/partials/BloodBioanalysis/bloodBioanalysis';
import type {
  ActivityDetailsFragment,
  BloodBioanalysisCollectionScheduleFragment,
  BloodBioanalysisFragment,
  ExperimentDetailsFragment,
  InventoryRequestFragment,
  ListContent,
  ListContentDetailsFragment,
  Model,
  OrdersDetailsFragment,
  PkBloodWorksheetCollectionFragment,
  PkBloodWorksheetFragment,
  PkTissueWorksheetCollectionFragment,
  PkTissueWorksheetFragment,
  TestArticle,
  TissueBioanalysisCollectionScheduleFragment,
  TissueBioanalysisFragment,
  Treatment,
  TreatmentGroup,
  WorksheetEntry,
} from '#graphql';
import { getAdcOrderWithExperiment } from '#lib/adcOrder';
import { generateCSVInfo } from '#lib/adcOrderDownload';
import {
  blankLine,
  formatDateTime,
  formatDateTimeWith12hrs,
  getCSVSimpleFormat,
  getCSVVerticalFormat,
  getSectionDetailsCSV,
  getSections,
  getWorksheetCSV,
  parseArrayToCSV,
  translateBooleanToText,
} from '#lib/export/exportCore';
import { csvEncodeLine } from '#lib/utils';
import { getAttachment } from '../api';
import { getAnimalWeightWorksheetCsv } from './animalWeightWorksheetExport';
import { getBioanalysisWorksheetCsv } from './bioanalysisWorksheetExport';
import { getCellCalculationWorksheetCsv } from './cellCalcWorksheetExport';
import { getImagingWorksheetCsv } from './imagingWorksheetExport';
import { getTreatmentCalculationWorksheetCsv } from './treatmentCalcWorksheetExport';
import { getTumorSizeWorksheetCsv } from './tumorSizeWorksheetExport';

type PKBloodWorksheetWithCollections = PkBloodWorksheetFragment & {
  pkBloodWorksheetCollections?: PkBloodWorksheetCollectionFragment[];
};

type PKTissueWorksheetWithCollections = PkTissueWorksheetFragment & {
  pkTissueWorksheetCollections?: PkTissueWorksheetCollectionFragment[];
};

function trueOrHyphen(bool: boolean) {
  return bool ? 'True' : '-';
}

const getExperimentHeaderCSV = (experiment: ExperimentDetailsFragment) => {
  const experimentHeaderInfo = {
    'Experiment Name': experiment.approvalName ?? experiment.name,
    'Experiment ID': String(experiment.id),
  };

  return getCSVSimpleFormat(Object.keys(experimentHeaderInfo), Object.values(experimentHeaderInfo));
};

const getExperimentStatusCSV = (experiment: ExperimentDetailsFragment) => {
  const experimentStatusInfo = {
    Phase: experiment.status,
    'Sub Phase': experiment.subStatus ?? '',
    'Meeting Date': !isNil(experiment.meetingDate)
      ? DateTime.fromISO(experiment.meetingDate, {
          zone: 'utc',
        }).toFormat('MM/dd/yyyy')
      : '',
  };

  return 'Status'.concat(
    '\n',
    getCSVSimpleFormat(Object.keys(experimentStatusInfo), Object.values(experimentStatusInfo))
  );
};

const getExperimentHypothesisCSV = (experiment: ExperimentDetailsFragment) => {
  const experimentHypothesisInfo = {
    'Hypothesis & Summary': experiment.hypothesis ?? '',
  };

  return getCSVSimpleFormat(Object.keys(experimentHypothesisInfo), Object.values(experimentHypothesisInfo));
};

const getExperimentDetailsCSV = (experiment: ExperimentDetailsFragment) => {
  const experimentGeneralInfo = {
    Author: `${experiment.owner.firstName} ${experiment.owner.lastName} - ${experiment.owner.email}`,
    'Research Associate': `${experiment.researcher?.firstName} ${experiment.researcher?.lastName} - ${experiment.researcher?.email}`,
    'Experiment Type': experiment.type,
    Program: experiment.project,
    'IACUC Protocol': experiment.IACUC,
    CRO: experiment.CRO ? 'Yes' : 'No',
    MTA: experiment.MTA ? 'Yes' : 'No',
  };
  return 'Experiment Details'.concat(
    '\n',
    getCSVSimpleFormat(Object.keys(experimentGeneralInfo), Object.values(experimentGeneralInfo))
  );
};

const getADCDetailsCSV = (data: string) => {
  return 'ADC'.concat('\n', data);
};

const getExperimentFooterCSV = (experiment: ExperimentDetailsFragment) => {
  const experimentFooterInfo = {
    Purposes: experiment.purposes ?? '',
    'Design and Methods': experiment.designMethods ?? '',
    Endpoints: experiment.endpoints ?? '',
    Conclusion: experiment.conclusion ?? '',
  };
  return getCSVVerticalFormat(Object.keys(experimentFooterInfo), Object.values(experimentFooterInfo));
};

const getTreatmentsInfoList = (treatments: Treatment[]) =>
  treatments.map((treatment) => ({
    headers: [
      'Treatment Number',
      'Molecule Type',
      'BioReg ID',
      'Name',
      'Dose Number',
      'Dosage',
      'Dose Unit',
      'Interval',
      'Route',
    ],
    csvInfo: treatment.testArticles.map((article) => [
      treatment.number.toString(),
      article.moleculeType ?? 'N/A',
      article.bioregId ?? 'N/A',
      article.name,
      String(article.doseNumber ?? 'N/A'),
      String(article.dosage ?? 'N/A'),
      String(article.doseUnit ?? 'N/A'),
      String(article.interval ?? 'N/A'),
      article.route ?? 'N/A',
    ]),
  }));

const getModelsInfoList = (models: Model[]) =>
  models.map((model) => {
    const headers = [
      'Cell Line',
      'Location',
      'Disease',
      'Strain',
      'Species',
      'Gender',
      'Implant Type',
      'Endpoint',
      'Treatment Start',
      'Cell Line - Growth',
      'Model Name',
    ];

    const csvInfo: string[] = [
      model.cellLine?.name ?? 'None',
      model.cellLine?.location ?? 'None',
      model.cellLine?.disease ?? '',
      model.strain ?? '',
      model.species ?? '',
      model.gender ?? '',
      model.implant ?? '',
      model.subcutaneousEndpoint ?? '',
      model.tumorStartingVolume ?? '',
      model.cellLine?.cultureProperties ?? '',
      model.name ?? '',
    ];

    return {
      headers,
      csvInfo,
    };
  });

const getGroupInfoList = (experiment: ExperimentDetailsFragment) =>
  experiment.treatmentGroups.map((group) => {
    const model = experiment.models.find((model) => model.id === group.modelId);
    const treatment = experiment.treatments.find((treatment) => treatment.id === group.treatmentId);

    if (isNil(model) || isNil(treatment)) {
      return { headers: [], csvInfo: [] };
    }

    return {
      headers: [
        'Group',
        'Animal Count',
        'Model Name',
        'Cell Line',
        'Treatment Number',
        'Is Bio Analysis included?',
        'Is Imaging included?',
        'Overage',
      ],
      csvInfo: [
        group.treatmentGroupIndex.toString(),
        String(group.modelCount ?? ''),
        model.name ?? '',
        model.cellLine?.name ?? '',
        `${treatment.number}`,
        translateBooleanToText(group.hasBioanalysis),
        translateBooleanToText(group.hasImaging),
        String(group.overage ?? ''),
      ],
    };
  });

const getAnimalsList = (inventoryRequests: InventoryRequestFragment[]) => ({
  headers: ['Status', 'Species', 'Strain', 'Gender', 'Quantity', 'PO #', 'Notes', 'Delivered'],
  csvInfo: inventoryRequests.map((inventoryRequest) => [
    inventoryRequest.status,
    inventoryRequest.inventory?.species ?? '',
    inventoryRequest.inventory?.strain ?? '',
    inventoryRequest.inventory?.gender ?? '',
    String(inventoryRequest.quantity),
    inventoryRequest.inventory?.poNumber ?? '',
    inventoryRequest.notes ?? '',
    formatDateTime(inventoryRequest.inventory?.dob),
  ]),
});

const getOrdersListNonADC = (orders: OrdersDetailsFragment) =>
  Object.keys(orders ?? [])
    .filter((keyName) => keyName !== '__typename' && keyName !== 'adcOrder' && keyName !== 'animalOrder')
    .map(() => {
      return {
        headers: ['Name', 'Category', 'BioReg ID', 'Requested mg'],
        csvInfo: orders.nonAdcOrder?.nonAdcOrderItems.map((item) => [
          item.name,
          item.category,
          item.bioregId ?? '',
          item.category === 'cell lines' ? '' : String(item.quantity),
        ]),
      };
    });

const getOrderListADC = (experiment: ExperimentDetailsFragment) => {
  if (isNil(experiment.orders) || isNil(experiment.orders.adcOrder)) {
    return '';
  }

  const adcOrderWithExperiment = getAdcOrderWithExperiment(experiment.orders.adcOrder, experiment);

  const adcOrderItemsCSV = generateCSVInfo(adcOrderWithExperiment, experiment.approvalName).csvContent;

  return ['Notes', adcOrderWithExperiment.notes ?? '', '\n' + adcOrderItemsCSV].join('\n');
};

const getWorksheetHeaderCSV = (
  experiment: ExperimentDetailsFragment,
  worksheetName: string,
  worksheetStatus: string
) => {
  const worksheetHeaderInfo = {
    Name: (experiment.approvalName ?? experiment.name).concat(' - ', worksheetName),
    Status: worksheetStatus,
  };

  return getCSVSimpleFormat(Object.keys(worksheetHeaderInfo), Object.values(worksheetHeaderInfo));
};

const getWorksheetInstructionsCSV = (data: string[][], keys: string[]) => {
  const sectionData = getSections('heading', keys, data)[0];

  if (!isNil(sectionData)) {
    const indexInstructions = keys.findIndex((key) => key === 'Instructions');
    const indexNotes = keys.findIndex((key) => key === 'notes');

    const worksheetInstructionsInfo = {
      Instructions: sectionData[indexInstructions]?.toString().replaceAll('“', '"').replaceAll('”', '"') ?? '',
      Notes: sectionData[indexNotes]?.toString() ?? '',
    };

    return getCSVSimpleFormat(Object.keys(worksheetInstructionsInfo), Object.values(worksheetInstructionsInfo));
  }

  return sectionData;
};

const getActivityCSV = (activity: ActivityDetailsFragment[]) => ({
  name: 'Activity.csv',
  headers: ['Created By', 'Created At', 'Message'],
  csvInfo: activity.map((log) => [
    `${log.createdBy.firstName} ${log.createdBy.lastName}`,
    formatDateTime(log.createdAt),
    log.message,
  ]),
});

interface WorksheetData {
  name: string;
  data: {
    headers: string[];
    entryKeys: string[];
    data: string[][];
  };
}

export const getWorksheetPageCSV = (
  definition: WorksheetDefinition,
  experiment: ExperimentDetailsFragment,
  worksheet: { status?: string; type?: string },
  worksheetData: WorksheetData
) => {
  const name = worksheetData.name.replace('|', '---'); // Zip does not like `|` in filename

  const worksheetHeaderCSV = getWorksheetHeaderCSV(experiment, name, worksheet.status ?? 'not started');

  const worksheetInstructionsCSV =
    getWorksheetInstructionsCSV(worksheetData.data.data, worksheetData.data.entryKeys) ?? '';

  let worksheetCsv = '';
  switch (worksheet.type) {
    case 'bioanalysis':
      worksheetCsv = getBioanalysisWorksheetCsv(
        definition,
        worksheetData.data.data,
        worksheetData.data.headers,
        worksheetData.data.entryKeys
      );
      break;
    case 'cellCalculationAdherent':
      worksheetCsv = getCellCalculationWorksheetCsv(
        definition,
        'adherent',
        worksheetData.data.data,
        worksheetData.data.headers,
        worksheetData.data.entryKeys
      );
      break;
    case 'cellCalculationSuspension':
      worksheetCsv = getCellCalculationWorksheetCsv(
        definition,
        'suspension',
        worksheetData.data.data,
        worksheetData.data.headers,
        worksheetData.data.entryKeys
      );
      break;
    case 'dataCollectionAnimalWeight':
      worksheetCsv = getAnimalWeightWorksheetCsv(
        definition,
        worksheetData.data.data,
        worksheetData.data.headers,
        worksheetData.data.entryKeys,
        experiment.treatmentGroups
      );
      break;
    case 'dataCollectionTumorSize':
      worksheetCsv = getTumorSizeWorksheetCsv(
        definition,
        worksheetData.data.data,
        worksheetData.data.headers,
        worksheetData.data.entryKeys,
        experiment.treatmentGroups
      );
      break;
    case 'imaging':
      worksheetCsv = getImagingWorksheetCsv(
        definition,
        worksheetData.data.data,
        worksheetData.data.headers,
        worksheetData.data.entryKeys
      );
      break;
    case 'treatmentCalculation':
      worksheetCsv = getTreatmentCalculationWorksheetCsv(
        definition,
        worksheetData.data.data,
        worksheetData.data.headers,
        worksheetData.data.entryKeys
      );
      break;
  }

  return [name, compact([worksheetHeaderCSV, worksheetInstructionsCSV, worksheetCsv]).join(blankLine)];
};

function useNewBioanalysisExport(experiment: ExperimentDetailsFragment) {
  return (
    process.env.REACT_APP_FEATURE_BIOANALYSIS === 'true' &&
    (!isNil(experiment.bloodBioanalysis) || !isNil(experiment.tissueBioanalysis))
  );
}

function useNewImagingExport(experiment: ExperimentDetailsFragment) {
  return process.env.REACT_APP_FEATURE_BIOANALYSIS === 'true' && !isNil(experiment.imaging);
}

function testArticleSummary(testArticle: TestArticle) {
  return `${testArticle.name} @ ${testArticle.dosage} ${testArticle.doseUnit}`;
}

function getCollectionSchedules(
  collectionSchedules: Array<BloodBioanalysisCollectionScheduleFragment | TissueBioanalysisCollectionScheduleFragment>,
  bioanalysisTreatmentGroups: Array<{ treatmentGroupId: number }>,
  treatmentGroups: TreatmentGroup[],
  bioanalysisTimepoints: ListContentDetailsFragment[],
  isDose = true
) {
  if (collectionSchedules.length === 0) {
    return '';
  }

  let collectionsInfo = 'Collection Schedules';

  const defaultCollectionScheduleGroups = difference(
    treatmentGroups
      .filter(
        (treatmentGroup) =>
          !isNil(
            bioanalysisTreatmentGroups.find(
              (bloodBioanalysisTreatmentGroup) => bloodBioanalysisTreatmentGroup.treatmentGroupId === treatmentGroup.id
            )
          )
      )
      .map((treatmentGroup) => treatmentGroup.treatmentGroupIndex),
    collectionSchedules
      .filter((collectionSchedule) => !collectionSchedule.isDefault)
      .flatMap((collectionSchedule) => collectionSchedule.onlyGroups)
  );

  const defaultCollectionScheduleGroupsValue =
    defaultCollectionScheduleGroups.length === bioanalysisTreatmentGroups.length
      ? 'All'
      : defaultCollectionScheduleGroups.toString();

  collectionSchedules.forEach((collectionSchedule, index) => {
    const timepointInfo = [
      [
        collectionSchedule.isDefault
          ? ['Default Collection Schedule']
          : ['Collection Schedule ' + (index + 1).toString()],
        ['Groups'],
        [
          collectionSchedule.isDefault
            ? defaultCollectionScheduleGroupsValue
            : collectionSchedule.onlyGroups.toString(),
        ],
        compact([isDose ? 'Dose' : null, 'Collection Point', 'Animals']),
        ...collectionSchedule.timepoints.map((timepoint) =>
          compact([
            isDose ? timepoint.dose.toString() : null,
            bioanalysisTimepoints.find(
              (bioanalysisTimepoint) => bioanalysisTimepoint.name === timepoint?.minutes.toString()
            )?.description ?? '-',
            isEmpty(timepoint.onlyAnimals) ? 'All' : timepoint.onlyAnimals.toString(),
          ])
        ),
      ],
    ]
      .map((section) => section.map(csvEncodeLine).join('\n'))
      .join(blankLine);

    collectionsInfo = [collectionsInfo, timepointInfo].join(blankLine);
  });
  return collectionsInfo;
}

function getExperimentBloodBioanalysis(
  bloodBioanalysis: BloodBioanalysisFragment,
  treatmentGroups: TreatmentGroup[],
  treatments: Treatment[],
  bioanalysisTimepoints: ListContentDetailsFragment[]
) {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-non-null-asserted-optional-chain
  const selectedBloodType = BLOOD_TYPES.find((bt) => bt.value === bloodBioanalysis?.bloodType)?.label!;

  const bloodBioAnalysisCsv = [
    [
      ['Blood Bioanalysis'],
      ['Blood Bioanalysis Requirements'],
      ['Sample Amount (ul)', 'Blood Type'],
      [bloodBioanalysis.sampleAmount.toString(), selectedBloodType],
    ],
    [
      ['Blood Bioanalysis Methods'],
      [...BLOOD_METHODS.map((bm) => bm.label), 'Other'],
      [
        ...BLOOD_METHODS.map((bm) => bloodBioanalysis.methods.includes(bm.value)).map(trueOrHyphen),
        bloodBioanalysis.otherMethods ?? '-',
      ],
    ],
    [
      ['Blood Bioanalysis Animals'],
      ['Group Index', 'Treatment', 'Test Articles', 'Basis TestArticle', 'Notes'],
      ...bloodBioanalysis.treatmentGroups.map((bbtg) => [
        treatmentGroups.find((tg) => tg.id === bbtg.treatmentGroupId)?.treatmentGroupIndex?.toString() ?? '-',
        treatments
          ?.find((ts) => ts.id === treatmentGroups.find((tgs) => tgs.id === bbtg.treatmentGroupId)?.treatmentId)
          ?.number.toString() ?? '-',
        treatments
          ?.find((ts) => ts.id === treatmentGroups.find((tgs) => tgs.id === bbtg.treatmentGroupId)?.treatmentId)
          ?.testArticles?.map((ta) => testArticleSummary(ta))
          .toString() ?? '-',
        getBaseTestArticle(
          treatments
            ?.find((ts) => ts.id === treatmentGroups.find((tgs) => tgs.id === bbtg.treatmentGroupId)?.treatmentId)
            ?.testArticles?.find((ta) => ta.id === bbtg.baseTestArticleId)
        ),
        bbtg.notes ?? '-',
      ]),
    ],
  ]
    .map((section) => section.map(csvEncodeLine).join('\n'))
    .join(blankLine);

  const collectionScheduleCsv = getCollectionSchedules(
    bloodBioanalysis.collectionSchedules ?? [],
    bloodBioanalysis.treatmentGroups,
    treatmentGroups,
    bioanalysisTimepoints
  );
  if (collectionScheduleCsv !== '') {
    return [bloodBioAnalysisCsv, collectionScheduleCsv].join(blankLine);
  }
  return bloodBioAnalysisCsv;
}

function getExperimentTissueBioanalysis(
  tissueBioanalysis: TissueBioanalysisFragment,
  treatmentGroups: TreatmentGroup[],
  treatments: Treatment[],
  bioanalysisTimepoints: ListContentDetailsFragment[]
) {
  const isDose = tissueBioanalysis.collectionCriteria === 'dose';

  const tissueBioAnalysisCsv = [
    [
      ['Tissue Bioanalysis'],
      ['Tissue Bioanalysis Requirements'],
      ['Collection Criteria', 'Destination', 'Stains'],
      [startCase(tissueBioanalysis.collectionCriteria), tissueBioanalysis.destination, tissueBioanalysis.stains ?? '-'],
    ],
    [
      ['Tissue Bioanalysis Animals'],
      compact(['Group Index', 'Treatment', 'Test Articles', isDose ? 'Basis TestArticle' : null, 'Notes']),
      ...tissueBioanalysis.treatmentGroups.map((bbtg) =>
        compact([
          treatmentGroups.find((tg) => tg.id === bbtg.treatmentGroupId)?.treatmentGroupIndex?.toString() ?? '-',
          treatments
            ?.find((ts) => ts.id === treatmentGroups.find((tgs) => tgs.id === bbtg.treatmentGroupId)?.treatmentId)
            ?.number.toString() ?? '-',
          treatments
            ?.find((ts) => ts.id === treatmentGroups.find((tgs) => tgs.id === bbtg.treatmentGroupId)?.treatmentId)
            ?.testArticles?.map((ta) => testArticleSummary(ta))
            .toString() ?? '-',
          isDose
            ? getBaseTestArticle(
                treatments
                  ?.find((ts) => ts.id === treatmentGroups.find((tgs) => tgs.id === bbtg.treatmentGroupId)?.treatmentId)
                  ?.testArticles?.find((ta) => ta.id === bbtg.baseTestArticleId)
              )
            : null,
          bbtg.notes ?? '-',
        ])
      ),
    ],
    [
      ['Tissue Bioanalysis Tissues'],
      ['Tissue', 'Handling and Storage', 'Notes'],
      ...tissueBioanalysis.tissues.map((tissue) => [
        tissue.tissue,
        tissue.handlingAndStorage.map((hs) => `${hs.method}; weight: ${hs.includeWeight.toString()}`).join(', '),
        tissue.notes ?? '-',
      ]),
    ],
  ]
    .map((section) => section.map(csvEncodeLine).join('\n'))
    .join(blankLine);

  const collectionScheduleCsv = getCollectionSchedules(
    tissueBioanalysis.collectionSchedules ?? [],
    tissueBioanalysis.treatmentGroups,
    treatmentGroups,
    bioanalysisTimepoints,
    isDose
  );
  if (collectionScheduleCsv !== '') {
    return [tissueBioAnalysisCsv, collectionScheduleCsv].join(blankLine);
  }
  return tissueBioAnalysisCsv;
}

function getBaseTestArticle(testArticle: TestArticle | undefined) {
  if (testArticle !== undefined) {
    return testArticleSummary(testArticle);
  }
  return '-';
}

function getExperimentBioanalysis(
  experiment: ExperimentDetailsFragment,
  bioanalysisTimepoints: ListContentDetailsFragment[]
) {
  const { bloodBioanalysis, tissueBioanalysis, treatmentGroups, treatments } = experiment;
  return compact([
    !isNil(bloodBioanalysis) &&
      getExperimentBloodBioanalysis(bloodBioanalysis, treatmentGroups, treatments, bioanalysisTimepoints),
    !isNil(tissueBioanalysis) &&
      getExperimentTissueBioanalysis(tissueBioanalysis, treatmentGroups, treatments, bioanalysisTimepoints),
  ]).join(blankLine);
}

function getExperimentImaging(experiment: ExperimentDetailsFragment) {
  const { imaging, treatmentGroups, treatments } = experiment;
  return compact([
    !isNil(imaging) &&
      [
        [
          ['Imaging'],
          ['Imaging Requirements'],
          ['Time points', 'Instructions', 'Number of Views'],
          [imaging.timePoints, imaging.instructions ?? '', imaging.numViews.toString()],
        ],
        [
          ['Imaging Animals'],
          ['Group Index', 'Test Articles', 'Notes'],
          ...imaging.treatmentGroups.map((itg) => [
            treatmentGroups.find((tg) => tg.id === itg.treatmentGroupId)?.treatmentGroupIndex?.toString() ?? '-',
            treatments
              ?.find((ts) => ts.id === treatmentGroups.find((tgs) => tgs.id === itg.treatmentGroupId)?.treatmentId)
              ?.testArticles?.map((ta) => testArticleSummary(ta))
              .toString() ?? '-',
            itg.notes ?? '-',
          ]),
        ],
      ]
        .map((section) => section.map(csvEncodeLine).join('\n'))
        .join(blankLine),
  ]).join(blankLine);
}

export function getExperimentPKBlood(
  experiment: ExperimentDetailsFragment,
  bioanalysisTimepoints: ListContentDetailsFragment[],
  pkBloodWorksheet?: PKBloodWorksheetWithCollections
) {
  const { bloodBioanalysis, treatmentGroups, treatments } = experiment;

  if (!isNil(pkBloodWorksheet) && !isNil(bloodBioanalysis)) {
    return getExperimentPKBloodWorksheet(
      pkBloodWorksheet,
      bloodBioanalysis,
      treatmentGroups,
      treatments,
      bioanalysisTimepoints
    );
  }
  return '';
}

function getExperimentPKBloodWorksheet(
  pkBloodWorksheet: PKBloodWorksheetWithCollections,
  bloodBioAnalysis: BloodBioanalysisFragment,
  treatmentGroups: TreatmentGroup[],
  treatments: Treatment[],
  bioanalysisTimepoints: ListContentDetailsFragment[]
) {
  const pkBloodWorksheetCSV = [
    ['PK Blood Worksheet'],
    ['Basis Date', 'Status', 'Notes'],
    [formatDateTime(pkBloodWorksheet.basisDate) ?? '-', pkBloodWorksheet.status, pkBloodWorksheet.notes ?? '-'],
  ]
    .map(csvEncodeLine)
    .join('\n');
  const pkBloodWorksheetCollectionsCsv = getPkBloodWorksheetCollections(
    pkBloodWorksheet.pkBloodWorksheetCollections ?? [],
    bloodBioAnalysis,
    treatmentGroups ?? [],
    treatments,
    bioanalysisTimepoints
  );
  return [pkBloodWorksheetCSV, pkBloodWorksheetCollectionsCsv].join(blankLine);
}

function getPkBloodWorksheetCollections(
  worksheetCollections: PkBloodWorksheetCollectionFragment[],
  bloodBioAnalysis: BloodBioanalysisFragment,
  treatmentGroups: TreatmentGroup[],
  treatments: Treatment[],
  bioanalysisTimepoints: ListContentDetailsFragment[]
) {
  if (worksheetCollections.length === 0) {
    return '';
  }

  const collectionHeaders = [
    'Date',
    'Dose',
    'TimePoint',
    'Treatment',
    'Group',
    'Animal',
    'Expected DateTime',
    'Collection DateTime',
  ];
  let collectionsInfo = ['PK Blood Worksheet Collections', csvEncodeLine(collectionHeaders)].join('\n');
  const bloodBioAnalysisTimepoints = bloodBioAnalysis?.collectionSchedules?.flatMap(
    (collectionSchedule) => collectionSchedule?.timepoints
  );

  worksheetCollections.forEach((collection) => {
    const timePoint = bloodBioAnalysisTimepoints?.find((time) => time.id === collection.bloodBioanalysisTimepointId);
    const worksheetCollectionsInfo = [
      [
        formatDateTime(collection.expectedDate) ?? '-',
        timePoint?.dose?.toString() ?? '-',
        bioanalysisTimepoints.find(
          (bioanalysisTimepoint) => bioanalysisTimepoint.name === timePoint?.minutes.toString()
        )?.description ?? '-',
        treatments
          ?.find(
            (treatment) =>
              treatment.id ===
              treatmentGroups.find((treatmentGroup) => treatmentGroup.id === collection.treatmentGroupId)?.treatmentId
          )
          ?.testArticles?.map((testArticle) => testArticleSummary(testArticle))
          .toString() ?? '-',
        treatmentGroups
          .find((treatmentGroup) => treatmentGroup.id === collection.treatmentGroupId)
          ?.treatmentGroupIndex?.toString() ?? '-',

        collection.animalNumber.toString(),
        formatDateTimeWith12hrs(collection.expectedTime) ?? '-',
        formatDateTimeWith12hrs(collection.actualTime) ?? '-',
      ],
    ]
      .map(csvEncodeLine)
      .join('\n');

    collectionsInfo = [collectionsInfo, worksheetCollectionsInfo].join('\n');
  });

  return collectionsInfo;
}

export function getExperimentPKTissue(
  experiment: ExperimentDetailsFragment,
  bioanalysisTimepoints: ListContentDetailsFragment[],
  pkTissueWorksheet?: PKTissueWorksheetWithCollections
) {
  const { tissueBioanalysis, treatmentGroups, treatments } = experiment;

  if (!isNil(pkTissueWorksheet) && !isNil(tissueBioanalysis)) {
    return getExperimentPKTissueWorksheet(
      pkTissueWorksheet,
      tissueBioanalysis,
      treatmentGroups,
      treatments,
      bioanalysisTimepoints
    );
  }
  return '';
}

function getExperimentPKTissueWorksheet(
  pkTissueWorksheet: PKTissueWorksheetWithCollections,
  tissueBioAnalysis: TissueBioanalysisFragment,
  treatmentGroups: TreatmentGroup[],
  treatments: Treatment[],
  bioanalysisTimepoints: ListContentDetailsFragment[]
) {
  const isDose = tissueBioAnalysis.collectionCriteria === 'dose';

  const pkTissueWorksheetCSV = [
    ['PK Tissue Worksheet'],
    compact(['Collection Criteria', isDose ? 'Basis Date' : null, 'Status', 'Notes']),
    compact([
      startCase(tissueBioAnalysis.collectionCriteria),
      isDose ? formatDateTime(pkTissueWorksheet.basisDate) ?? '-' : null,
      pkTissueWorksheet.status,
      pkTissueWorksheet.notes ?? '-',
    ]),
  ]
    .map(csvEncodeLine)
    .join('\n');
  const pkTissueWorksheetCollectionsCsv = getPkTissueWorksheetCollections(
    pkTissueWorksheet.pkTissueWorksheetCollections ?? [],
    tissueBioAnalysis,
    treatmentGroups ?? [],
    treatments,
    bioanalysisTimepoints
  );
  return [pkTissueWorksheetCSV, pkTissueWorksheetCollectionsCsv].join(blankLine);
}

function getPkTissueWorksheetCollections(
  worksheetCollections: PkTissueWorksheetCollectionFragment[],
  tissueBioAnalysis: TissueBioanalysisFragment,
  treatmentGroups: TreatmentGroup[],
  treatments: Treatment[],
  bioanalysisTimepoints: ListContentDetailsFragment[]
) {
  const isDose = tissueBioAnalysis.collectionCriteria === 'dose';

  if (worksheetCollections.length === 0) {
    return '';
  }

  const collectionHeaders = compact([
    isDose ? 'Date' : null,
    isDose ? 'Dose' : null,
    'Collection Point',
    'Treatment',
    'Group',
    'Animal',
    isDose ? 'Expected DateTime' : null,
    'Collection DateTime',
    ...tissueBioAnalysis.tissues.flatMap((tissue) =>
      tissue.handlingAndStorage.map((handlingAndStorage) => `${tissue.tissue} | ${handlingAndStorage.method}`)
    ),
  ]);

  let collectionsInfo = ['PK Tissue Worksheet Collections', csvEncodeLine(collectionHeaders)].join('\n');
  const tissueBioAnalysisTimepoints = tissueBioAnalysis?.collectionSchedules?.flatMap(
    (collectionSchedule) => collectionSchedule?.timepoints
  );

  worksheetCollections.forEach((collection) => {
    const timePoint = tissueBioAnalysisTimepoints?.find((time) => time.id === collection.tissueBioanalysisTimepointId);
    const worksheetCollectionsInfo = [
      compact([
        isDose ? formatDateTime(collection.expectedDate) ?? '-' : null,
        isDose ? timePoint?.dose?.toString() ?? '-' : null,
        bioanalysisTimepoints.find(
          (bioanalysisTimepoint) => bioanalysisTimepoint.name === timePoint?.minutes.toString()
        )?.description ?? '-',
        treatments
          ?.find(
            (treatment) =>
              treatment.id ===
              treatmentGroups.find((treatmentGroup) => treatmentGroup.id === collection.treatmentGroupId)?.treatmentId
          )
          ?.testArticles?.map((testArticle) => testArticleSummary(testArticle))
          .toString() ?? '-',
        treatmentGroups
          .find((treatmentGroup) => treatmentGroup.id === collection.treatmentGroupId)
          ?.treatmentGroupIndex?.toString() ?? '-',

        collection.animalNumber.toString(),
        isDose ? formatDateTimeWith12hrs(collection.expectedTime) ?? '-' : null,
        formatDateTimeWith12hrs(collection.actualTime) ?? '-',
        ...tissueBioAnalysis.tissues.flatMap((tissue) =>
          tissue.handlingAndStorage.map((handlingAndStorage) => {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const collectionHandlingAndStorage = collection.pkTissueWorksheetHandlingAndStorage.find(
              (worksheetHandlingAndStorage) =>
                worksheetHandlingAndStorage.tissueBioanalysisTissueId === tissue.id &&
                worksheetHandlingAndStorage.method === handlingAndStorage.method
            )!;
            const { collected, weight } = collectionHandlingAndStorage;
            if (!isNil(weight)) {
              return `${weight}mg`;
            }
            return collected ? 'Collected' : '-';
          })
        ),
      ]),
    ]
      .map(csvEncodeLine)
      .join('\n');

    collectionsInfo = [collectionsInfo, worksheetCollectionsInfo].join('\n');
  });

  return collectionsInfo;
}

export const getExperimentZipFile = async (
  experiment: ExperimentDetailsFragment,
  getWorksheetEntriesForWorksheet: (worksheetId: number) => Promise<WorksheetEntry[]>,
  inventoryRequests: InventoryRequestFragment[],
  listContent: ListContent[],
  pkBloodWorksheet?: PKBloodWorksheetWithCollections,
  pkTissueWorksheet?: PKTissueWorksheetWithCollections
) => {
  const preclinicalObservations = listContent.filter((lc) => lc.category === 'preclinicalObservation');
  const bioanalysisTimepoints = listContent.filter((lc) =>
    ['bioanalysisTimepoint', 'bioanalysisTumorVolumeTimepoint'].includes(lc.category)
  );

  const modelList = getModelsInfoList(experiment.models);
  const models = new Array<string[]>();
  modelList.forEach((model) => models.push(model.csvInfo));

  const treatmentList = getTreatmentsInfoList(experiment.treatments);
  const treatments = new Array<string[]>();
  treatmentList.forEach((treatment) => {
    treatment.csvInfo.forEach((csv) => treatments.push(csv));
  });

  const treatmentGroupList = getGroupInfoList(experiment);
  const groups = new Array<string[]>();
  treatmentGroupList.forEach((group) => groups.push(group.csvInfo));

  const zip = new JSZip();
  zip.file(
    'ExperimentDetails.csv',
    compact([
      getExperimentHeaderCSV(experiment),
      getExperimentStatusCSV(experiment),
      getExperimentHypothesisCSV(experiment),
      getExperimentDetailsCSV(experiment),
      getSectionDetailsCSV('Models', modelList[0].headers, models),
      getSectionDetailsCSV('Treatments', treatmentList[0].headers, treatments),
      getSectionDetailsCSV('Groups', treatmentGroupList[0].headers, groups),
      useNewBioanalysisExport(experiment) && getExperimentBioanalysis(experiment, bioanalysisTimepoints),
      useNewImagingExport(experiment) && getExperimentImaging(experiment),
      getExperimentPKBlood(experiment, bioanalysisTimepoints, pkBloodWorksheet),
      getExperimentPKTissue(experiment, bioanalysisTimepoints, pkTissueWorksheet),
      getExperimentFooterCSV(experiment),
    ]).join(blankLine)
  );

  for (const attachment of experiment.attachments ?? []) {
    const file = await getAttachment(experiment.id, attachment.filename, attachment.category);
    zip.folder('Attachments')?.file(attachment.filename, file, { base64: true });
  }

  const ordersList = getOrdersListNonADC(experiment.orders ?? {});
  const orders = new Array<string[]>();
  ordersList.forEach((order) => order.csvInfo?.forEach((csv) => orders.push(csv)));

  const animalsList = getAnimalsList(inventoryRequests);
  const animals = animalsList.csvInfo;

  const adcOrders = getOrderListADC(experiment);

  zip.file(
    'Orders.csv',
    [
      getADCDetailsCSV(adcOrders),
      getSectionDetailsCSV('Non-ADC', ordersList[0].headers, orders),
      getSectionDetailsCSV('Animals', animalsList.headers, animals),
    ].join(blankLine)
  );

  for (const worksheet of experiment.worksheets ?? []) {
    if (worksheet.type === 'bioanalysis' && useNewBioanalysisExport(experiment)) {
      continue;
    }

    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const definition = JSON.parse(worksheet.definition) as WorksheetDefinition;
    const worksheetEntries = await getWorksheetEntriesForWorksheet(worksheet.id);

    const worksheetData = getWorksheetCSV(experiment, worksheet, worksheetEntries, preclinicalObservations);

    const [name, worksheetCSV] = getWorksheetPageCSV(definition, experiment, worksheet, worksheetData);

    zip.file(`${name}.csv`, worksheetCSV);
  }

  const activityCSV = getActivityCSV(experiment.activity);
  zip.file(activityCSV.name, parseArrayToCSV(activityCSV.headers, activityCSV.csvInfo));

  return await zip.generateAsync({ type: 'blob' });
};
