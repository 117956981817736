"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.treatmentGroupWorksheetsByType = exports.globalWorksheetsByType = exports.worksheetsByType = void 0;
const BioanalysisWorksheet_1 = require("./BioanalysisWorksheet");
const CellCalculationSectionGenerators_1 = require("./CellCalculationSectionGenerators");
const DataCollectionAnimalWeightWorksheet_1 = require("./DataCollectionAnimalWeightWorksheet");
const DataCollectionTumorSizeWorksheet_1 = require("./DataCollectionTumorSizeWorksheet");
const ImagingWorksheet_1 = require("./ImagingWorksheet");
const TreatmentCalculationWorksheet_1 = require("./TreatmentCalculationWorksheet");
exports.worksheetsByType = {
    bioanalysis: BioanalysisWorksheet_1.bioanalysis,
    cellCalculationAdherent: CellCalculationSectionGenerators_1.cellCalculationAdherent,
    cellCalculationSuspension: CellCalculationSectionGenerators_1.cellCalculationSuspension,
    dataCollectionAnimalWeight: DataCollectionAnimalWeightWorksheet_1.dataCollectionAnimalWeight,
    dataCollectionTumorSize: DataCollectionTumorSizeWorksheet_1.dataCollectionTumorSize,
    imaging: ImagingWorksheet_1.imaging,
    treatmentCalculation: TreatmentCalculationWorksheet_1.treatmentCalculation,
};
exports.globalWorksheetsByType = {
    cellCalculationAdherent: CellCalculationSectionGenerators_1.cellCalculationAdherent,
    cellCalculationSuspension: CellCalculationSectionGenerators_1.cellCalculationSuspension,
    imaging: ImagingWorksheet_1.imaging,
};
exports.treatmentGroupWorksheetsByType = {
    dataCollectionAnimalWeight: DataCollectionAnimalWeightWorksheet_1.dataCollectionAnimalWeight,
    dataCollectionTumorSize: DataCollectionTumorSizeWorksheet_1.dataCollectionTumorSize,
};
