import { type BioanalysisCollectionScheduleFragment, type BioanalysisTreatmentGroupFragment } from '#graphql';
import { type TreatmentGroupWithTreatment } from '#lib/types';
import { isEmpty, isNil, isUndefined, range, uniq } from 'lodash';

export interface TreatmentGroupsFormData {
  treatmentGroups: Array<{
    checked: boolean;
    id: number | undefined;
    notes: string;
    treatmentGroupId: number;
    baseTestArticleId: string;
  }>;
  /*
    This needs to be present in the FormData type so we can "register" the fields to keep
    track of validity for multiple inputs
  */
  treatmentGroupsValidity?: never;
}

export interface CollectionSchedulesFormData {
  collectionSchedules: Array<{
    id?: number;
    isDefault: boolean;
    onlyGroupsString: string;
    timepoints: Array<{
      id?: number;
      onlyAnimalsString: string;
      dose: string;
      minutes: string;
      includeAllAnimals?: boolean;
    }>;
  }>;
}

export interface BioanalysisFormData extends CollectionSchedulesFormData, TreatmentGroupsFormData {}

export const initialTimepoint = {
  dose: '',
  minutes: '',
  onlyAnimalsString: '',
  includeAllAnimals: true,
};

export const initialCollectionSchedule = {
  onlyGroupsString: '',
  isDefault: false,
  timepoints: [initialTimepoint],
};

export const defaultCollectionSchedule = {
  onlyGroupsString: '',
  isDefault: true,
  timepoints: [
    {
      dose: '',
      minutes: '',
      onlyAnimalsString: '',
      includeAllAnimals: true,
    },
  ],
};

type Range = [number, number];

function byNumber(a: number, b: number) {
  return a - b;
}

/**
 * This assumes the range string is properly formatted
 */
export function rangeStringToNumberArray(rangeString: string): number[] {
  return uniq(
    rangeString
      .replaceAll(/\s/g, '')
      .split(',')
      .flatMap((integerOrRange) => {
        if (!integerOrRange.includes('-')) {
          return Number(integerOrRange);
        }

        const [start, end] = integerOrRange //
          .split('-')
          .map(Number)
          .sort(byNumber);

        return range(start, end + 1);
      })
      .sort(byNumber)
  );
}

export function numberArrayToRangeString(numbers: number[]): string {
  const initialValue: Range[] = [];

  return uniq(numbers)
    .sort(byNumber)
    .reduce((acc, number) => {
      const [lastPair] = acc.slice(-1);

      if (isUndefined(lastPair)) {
        acc.push([number, number]);
      } else {
        const [start, end] = lastPair;

        if (end === number - 1) {
          acc.splice(-1, 1, [start, number]);
        } else {
          acc.push([number, number]);
        }
      }
      return acc;
    }, initialValue)
    .map(([start, end]) => (start === end ? `${start}` : `${start}-${end}`))
    .join(', ');
}

export function formDataToInput(data: BioanalysisFormData) {
  return {
    treatmentGroups: data.treatmentGroups
      .filter((tg) => tg.checked)
      .map((tg) => {
        const { checked: _checked, baseTestArticleId, ...rest } = tg;
        return {
          ...rest,
          baseTestArticleId: Number(baseTestArticleId),
        };
      }),
    collectionSchedules: data.collectionSchedules.map((collectionSchedule) => {
      const { onlyGroupsString, timepoints, ...rest } = collectionSchedule;
      return {
        ...rest,
        onlyGroups: collectionSchedule.isDefault ? [] : rangeStringToNumberArray(onlyGroupsString),
        timepoints: timepoints.map((timepoint) => {
          const { includeAllAnimals, dose, minutes, onlyAnimalsString, ...rest } = timepoint;
          return {
            ...rest,
            dose: Number(dose),
            minutes: Number(minutes),
            onlyAnimals: includeAllAnimals === true ? [] : rangeStringToNumberArray(onlyAnimalsString),
          };
        }),
      };
    }),
  };
}

export function buildDefaultCollectionSchedulesValue(collectionSchedules?: BioanalysisCollectionScheduleFragment[]) {
  if (isNil(collectionSchedules) || isEmpty(collectionSchedules)) {
    return [defaultCollectionSchedule];
  }

  return collectionSchedules.map((collectionSchedule) => {
    return {
      id: collectionSchedule.id,
      isDefault: collectionSchedule.isDefault,
      onlyGroupsString: numberArrayToRangeString(collectionSchedule.onlyGroups),
      timepoints: collectionSchedule.timepoints.map((timepoint) => ({
        id: timepoint.id,
        dose: timepoint.dose.toString(),
        minutes: timepoint.minutes.toString(),
        onlyAnimalsString: numberArrayToRangeString(timepoint.onlyAnimals),
        includeAllAnimals: isEmpty(timepoint.onlyAnimals),
      })),
    };
  });
}

export function buildDefaultTreatmentGroupsValue(
  treatmentGroups: TreatmentGroupWithTreatment[],
  bioanalysisTreatmentGroups?: BioanalysisTreatmentGroupFragment[]
) {
  return treatmentGroups.map((tg) => {
    const tissueBioanalysisTreatmentGroup = (bioanalysisTreatmentGroups ?? []).find(
      (btg) => btg.treatmentGroupId === tg.id
    );
    return {
      checked: !isNil(tissueBioanalysisTreatmentGroup),
      id: tissueBioanalysisTreatmentGroup?.id ?? undefined,
      notes: tissueBioanalysisTreatmentGroup?.notes ?? '',
      treatmentGroupId: tg.id,
      baseTestArticleId: tissueBioanalysisTreatmentGroup?.baseTestArticleId.toString() ?? '',
    };
  });
}
