import { omit } from 'lodash';
import { Container } from '@mui/material';
import { useParams } from 'react-router';
import { useUpdateUserMutation, useUserQuery } from '#graphql';
import { CanRoute, Loader } from '#components/widgets';
import { UserFormData, UserForm } from './UserForm';

export function EditUser() {
  const [updateUser] = useUpdateUserMutation();
  const { id } = useParams();

  const { data, error, loading } = useUserQuery({
    variables: {
      id: Number(id),
    },
  });

  if (loading) {
    return <Loader />;
  }

  if (error) {
    console.error({ error });
    return null;
  }

  const user = data?.user!;

  async function handleSubmit(data: UserFormData) {
    await updateUser({
      variables: {
        input: {
          ...omit(data, ['username']),
          email: `${data.username}@seagen.com`,
          roleId: parseInt(data.roleId),
          id: user.id,
        },
      },
    });
  }

  return (
    <Container maxWidth="md">
      <CanRoute I="update" this={user as never}>
        <UserForm user={user} handleSubmit={handleSubmit} />
      </CanRoute>
    </Container>
  );
}
