import styled from '@emotion/styled';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { ButtonNeutral, ButtonPositive } from '#components/widgets/Buttons';
import { useNavigate } from 'react-router-dom';
import { MenuItem, TextField, IconButton, Select } from '@mui/material';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { ButtonPositiveSolid } from '#components/widgets';
import { UserDetailsFragment } from '#graphql';

const StyledItemContainer = styled.div({
  display: 'flex',
  gap: '5px',
  alignItems: 'center',
});

const StyledSection = styled.div({
  display: 'flex',
  gap: '20px',
});

const StyledRightSection = styled.div({
  display: 'flex',
  gap: '20px',
  marginTop: '15px',
});

const StyledText = styled.h3({
  fontFamily: 'Roboto',
  fontWeight: 'bold',
  fontSize: '18px',
  lineHeight: '24px',
  color: '#000000',
});

const StyledButton = styled(ButtonPositiveSolid)({
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '10px',
  lineHeight: '16px',
  letterSpacing: '1.25px',
  textTransform: 'uppercase',
  width: '50px',
  height: '28px',
  borderRadius: '28px',
  justifyContent: 'space-around',
  '&:hover': {
    background: '#008844',
  },
});

const StyledHeaderContainer = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '20px 20px 0 20px',
});

const StyledCircleButton = styled(ButtonNeutral)({
  minWidth: '28px !important',
  padding: 0,
  borderRadius: '50%',
});

const StyledMediumArrowIcon = styled(ArrowBackIcon)({
  fontSize: 'medium',
  margin: '0 5px 0 5px',
});

const StyledSmallArrowIcon = styled(ArrowBackIcon)({
  fontSize: 'small',
  margin: '0 5px 0 0',
});

const StyledTextField = styled(TextField)({
  div: {
    borderRadius: '4px',
    fontFamily: 'Roboto',
    width: '175px',
    height: '28px',
    padding: '0',
  },
});
export const TemplateEditorHeader = () => {
  const navigate = useNavigate();

  return (
    <StyledHeaderContainer>
      <StyledSection>
        <StyledItemContainer>
          <StyledCircleButton onClick={() => navigate(-1)}>
            <StyledMediumArrowIcon />
          </StyledCircleButton>
        </StyledItemContainer>
        <StyledItemContainer>
          <StyledText>Content Editor</StyledText>
        </StyledItemContainer>
      </StyledSection>
      <StyledRightSection>
        <StyledButton onClick={() => navigate(-1)}>
          <StyledSmallArrowIcon /> EXIT
        </StyledButton>
      </StyledRightSection>
    </StyledHeaderContainer>
  );
};
