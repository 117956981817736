import AddIcon from '@mui/icons-material/Add';
import { Box, Typography, Button } from '@mui/material';
import { isNil } from 'lodash';
import { Link } from 'react-router-dom';

export interface OmniGridHeaderProps {
  addButtonProps?: {
    to: string;
    label: string;
  };
  showAddButton?: boolean;
  title: string;
}

export function OmniGridHeader(props: OmniGridHeaderProps) {
  const { addButtonProps, showAddButton, title } = props;

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      minHeight="2rem"
    >
      <Typography variant="h2">{title}</Typography>
      {!isNil(showAddButton) && !isNil(addButtonProps) && (
        <Button
          component={Link}
          disableElevation
          size="small"
          startIcon={<AddIcon />}
          to={addButtonProps.to}
          variant="contained"
        >
          {addButtonProps.label}
        </Button>
      )}
    </Box>
  );
}
