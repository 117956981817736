import styled from '@emotion/styled';
import CreateOutlineIcon from '@mui/icons-material/CreateOutlined';
import FolderOutlineIcon from '@mui/icons-material/FolderOutlined';
import GridViewOutlineIcon from '@mui/icons-material/GridViewOutlined';
import MenuOutlineIcon from '@mui/icons-material/MenuOutlined';
import ViewAgendaOutlineIcon from '@mui/icons-material/ViewAgendaOutlined';
import { TextField, Select } from '@mui/material';

import { AppColors } from '#lib/constants';
import { ButtonPositiveSolid } from '#components/widgets';

export const StyledContainer = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  paddingTop: '5px',
  margin: '0 10px',
  gap: '30px',
});

export const StyledSection = styled.div(
  ({ customGap }: { customGap: string }) => ({
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: customGap,
  })
);

export const StyledItemsSection = styled.div({
  display: 'flex',
  marginTop: '6px',
});

export const StyledAgendaIcon = styled(ViewAgendaOutlineIcon)({
  fontSize: 'medium',
});

export const StyledMenuIcon = styled(MenuOutlineIcon)({
  fontSize: 'medium',
});

export const StyledGridViewIcon = styled(GridViewOutlineIcon)({
  fontSize: 'medium',
});

export const StyledFolderIcon = styled(FolderOutlineIcon)({
  fontSize: 'medium',
});

export const StyledCreateIcon = styled(CreateOutlineIcon)({
  fontSize: 'medium',
});

export const StyledTextField = styled(TextField)({
  div: {
    borderRadius: '4px',
    fontFamily: 'Roboto',
    width: '175px',
    height: '28px',
    padding: '0',
  },
});

export const StyledButton = styled(ButtonPositiveSolid)({
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '10px',
  lineHeight: '16px',
  letterSpacing: '1.25px',
  textTransform: 'uppercase',
  width: '175px',
  height: '28px',
  borderRadius: '28px',
  justifyContent: 'space-around',
  '&:hover': {
    background: AppColors.BRAND_GREEN,
  },
});

// https://github.com/mui/material-ui/issues/11069 - Select doesn't support a placeholder native
export const StyledSelect = styled(Select)(
  ({
    isplaceholder,
    width = '175px',
  }: {
    isplaceholder: string;
    width?: string;
  }) => ({
    borderRadius: '4px',
    fontFamily: 'Roboto',
    width: width,
    height: '28px',
    color: isplaceholder === '1' ? '#D0D0D0' : '',
  })
);
