import type { ListContentCategory } from '#graphql';

export const categoryTitleMap: Record<ListContentCategory, string> = {
  animal: 'Animal',
  doseUnit: 'Dose Unit',
  experimentType: 'Experiment Type',
  IACUC: 'IACUC',
  notificationEmailBioanalysisForTissueGroupTeam: 'Notification Email: Bioanalysis For Tissue Group Team',
  notificationEmailBloodGroupTeam: 'Notification Email: Blood Group Team',
  notificationEmailConjugationGroupTeam: 'Notification Email: Conjugation Group Team',
  notificationEmailGroupAdmin: 'Notification Email: Group Admin',
  notificationEmailProteinScienceGroupTeam: 'Notification Email: Protein Science Group Team',
  notificationEmailToxicologyGroupTeam: 'Notification Email: Toxicology Group Team',
  notificationEmailCROGroupTeam: 'Notification Email: CRO Group Team',
  route: 'Route',
  strain: 'Strain',
  subcutaneousEndpoint: 'Subcutaneous Endpoint',
  excludeConjugate: 'Exclusion List: Conjugates',
  preclinicalObservation: 'Preclinical Observation',
  tissue: 'Tissue',
  handlingAndStorage: 'Handling & Storage',
  bioanalysisTimepoint: 'Bioanalysis Dose Collection Point',
  bioanalysisTumorVolumeTimepoint: 'Bioanalysis Tumor Volume Collection Point',
};
