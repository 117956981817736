import { type GridRowId, type GridColDef } from '@mui/x-data-grid-premium';
import { useAbility } from '@casl/react';
import { useNavigate } from 'react-router-dom';
import { useCroCellLinesLazyQuery, type CellLineFragment, useDeleteCroCellLineMutation } from '#graphql';
import { AbilityContext } from '#components/contexts';
import { OmniGrid } from '#components/widgets';
import { CRO_CELL_LINE_PATH, TEST_ARTICLES_ROUTE, i18n } from '#lib/constants';
import { useCallback } from 'react';
import { startCase } from 'lodash';
import { CroSpecies, CultureProperties, ModelType } from '#lib/croCellLine';

export function CroCellLines() {
  const navigate = useNavigate();
  const ability = useAbility(AbilityContext);
  const [deleteCroCellLine] = useDeleteCroCellLineMutation();

  const [getCellLine, { loading, error, data, previousData }] = useCroCellLinesLazyQuery();
  const currentData = data?.croCellLines ?? previousData?.croCellLines;
  const rows = currentData?.items ?? [];
  const rowCount = currentData?.totalCount ?? 0;
  const handleEdit = useCallback(
    (id: GridRowId) => {
      navigate(`${TEST_ARTICLES_ROUTE}${CRO_CELL_LINE_PATH}/${id}/edit`);
    },
    [navigate]
  );

  const onDelete = useCallback(
    async (id: GridRowId) => {
      await deleteCroCellLine({
        variables: {
          id: Number(id),
        },
        awaitRefetchQueries: true,
        refetchQueries: ['croCellLines'],
      });
    },
    [deleteCroCellLine]
  );

  const columns: Array<GridColDef<CellLineFragment>> = [
    {
      field: 'name',
      flex: 1,
      headerName: i18n.CroCellLines.CroCellLineHeaders.name,
      sortable: true,
      filterable: false,
    },
    {
      field: 'modelType',
      flex: 1,
      headerName: i18n.CroCellLines.CroCellLineHeaders.modelType,
      type: 'singleSelect',
      sortable: true,
      filterable: true,
      valueFormatter: (params) => startCase(params.value),
      valueOptions: () => Object.values(ModelType),
    },
    {
      field: 'disease',
      flex: 1,
      headerName: i18n.CroCellLines.CroCellLineHeaders.disease,
      sortable: true,
      filterable: true,
    },
    {
      field: 'recommendedGrowthMedia',
      flex: 1,
      headerName: i18n.CroCellLines.CroCellLineHeaders.recommendedGrowthMedia,
      sortable: true,
      filterable: true,
    },
    {
      field: 'cultureProperties',
      flex: 1,
      headerName: i18n.CroCellLines.CroCellLineHeaders.cultureProperties,
      type: 'singleSelect',
      valueFormatter: (params) => startCase(params.value),
      valueOptions: () => Object.values(CultureProperties),
      sortable: true,
      filterable: true,
    },
    {
      field: 'source',
      flex: 1,
      headerName: i18n.CroCellLines.CroCellLineHeaders.source,
      sortable: true,
      filterable: true,
    },
    {
      field: 'location',
      flex: 1,
      headerName: i18n.CroCellLines.CroCellLineHeaders.location,
      sortable: true,
      filterable: true,
    },
    {
      field: 'species',
      flex: 1,
      headerName: i18n.CroCellLines.CroCellLineHeaders.species,
      type: 'singleSelect',
      sortable: true,
      filterable: true,
      valueFormatter: (params) => startCase(params.value),
      valueOptions: () => Object.values(CroSpecies),
    },
  ];

  return (
    <OmniGrid
      columns={columns}
      defaultSort={[{ field: 'name', sort: 'asc' }]}
      error={error}
      loading={loading}
      refetch={getCellLine}
      rows={rows}
      rowCount={rowCount}
      showDeleteButton={ability.can('delete', 'cellLine')}
      deleteConfirmProps={{
        modalText: i18n.CroCellLines.DeleteModal,
        onConfirm: onDelete,
      }}
      showEditButton={ability.can('update', 'cellLine')}
      editButtonProps={{ handleEdit }}
      headerProps={{
        title: 'CRO Cell Lines',
        showAddButton: ability.can('create', 'cellLine'),
        addButtonProps: {
          label: 'Add CRO Cell Line',
          to: `${TEST_ARTICLES_ROUTE}${CRO_CELL_LINE_PATH}/create`,
        },
      }}
      showQuickFilter={true}
    />
  );
}
