import { isNil } from 'lodash';

const CONFIGURE_PATH = '/configure';

// EXPORTS

export const TEST_ARTICLES_PATH = 'test-articles';

export const ARCHIVE_PATH = 'archive';

export const CARDS_PATH = 'cards';

export const ADC_ORDERS_PATH = 'adcOrders';

export const CARDS_LIST_PATH = '/experiments/list';

export const CARDS_ARCHIVE_PATH = '/experiments/archive';

export const CREATE_EXPERIMENT_ROUTE = '/experiments/create';

export const CREATE_EXPERIMENT_PATH = `create`;

export const CARDS_DRAFTS_PATH = '/experiments/drafts';

export const CONFIGURE_ROUTES = 'configure/:experimentId/*';

export const DRAFTS_PATH = 'drafts';

export const DOCUMENTATION_PATH = 'documentation';

export const EXPERIMENTS_ROUTES = 'experiments/*';

export const ANIMAL_INVENTORY_ROUTE = 'animal-inventory';

export const WORKSHEET_ROUTE = `experiments/:experimentId/worksheets/:worksheetId`;

export const PK_BLOOD_WORKSHEET_ROUTE = 'experiments/:experimentId/pk-blood-worksheet';

export const PK_TISSUE_WORKSHEET_ROUTE = 'experiments/:experimentId/pk-tissue-worksheet';

export const HOME_PATH = '/experiments/cards';

export const ADC_ORDERS_ROUTE = 'adcOrders/*';

export const UNFULFILLED_ADC_ORDERS_PATH = 'unfulfilled';

export const FULFILLED_ADC_ORDERS_PATH = 'fulfilled';

export const LIST_PATH = 'list';

export const LOGIN_AUTH_ROUTE = '/.auth/me';

export const LOGIN_PATH = '/.auth/login/aad?post_login_redirect_uri=/experiments/cards';

export const LOGOUT_PATH = '/.auth/logout?post_logout_redirect_uri=/';

export const OVERVIEW_PATH = 'overview';

export const PROFILE_ROUTE = '/profile';

export const SETTINGS_PATH = '/settings';

export const SETTINGS_ROUTE = 'settings';

export const TEMPLATE_EDITOR_PATH = '/template-editor';

export const USER_MANAGEMENT_PATH = '/users';

export const NO_ACCESS_PATH = '/no-access';

export const WORKSPACE_PATH = 'workspace';

export const TEST_ARTICLES_ROUTE = '/test-articles';

export const CRO_CELL_LINE_PATH = '/cro-cell-lines';

export const getConfiguratorOverviewPath = (experimentId?: number) =>
  !isNil(experimentId) ? `${CONFIGURE_PATH}/${experimentId}/${OVERVIEW_PATH}` : `${CONFIGURE_PATH}/0/${OVERVIEW_PATH}`;

export const getConfiguratorWorkspacePath = (experimentId?: number) =>
  !isNil(experimentId) ? `${CONFIGURE_PATH}/${experimentId}/${WORKSPACE_PATH}` : '';

export const getConfiguratorTestArticlesPath = (experimentId?: number) =>
  !isNil(experimentId) ? `${CONFIGURE_PATH}/${experimentId}/${TEST_ARTICLES_PATH}` : '';

export const getConfiguratorDocumentationPath = (experimentId?: number) =>
  !isNil(experimentId) ? `${CONFIGURE_PATH}/${experimentId}/${DOCUMENTATION_PATH}` : '';

export const getWorksheetPath = (experimentId: number, worksheetId: number, type?: string) => {
  if (type === 'pkBlood') {
    return `/experiments/${experimentId}/pk-blood-worksheet`;
  }
  if (type === 'pkTissue') {
    return `/experiments/${experimentId}/pk-tissue-worksheet`;
  }
  return `/experiments/${experimentId}/worksheets/${worksheetId}`;
};
