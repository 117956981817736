import { isEqual, isNil } from 'lodash';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { useUpdateAdcOrderItemMutation, type AdcOrderItemDetailsFragment } from '#graphql';

interface ConjugateAdcOrderFormFormValues {
  batchNumberSuffix: string;
  formulation: string;
  adcOrderItems: Array<{
    id: string;
    deliveredAmount: string;
    deliveredConcentration: string;
    a280: string;
  }>;
}

function buildDefaultValues(adcOrderItems: AdcOrderItemDetailsFragment[]): ConjugateAdcOrderFormFormValues {
  const firstAdcOrderItem = adcOrderItems[0];

  // TODO - refactor to use a generic "deep stringify" (types are failing me)
  return {
    batchNumberSuffix: firstAdcOrderItem.batchNumber?.split('-')[2] ?? '',
    formulation: firstAdcOrderItem.formulation ?? '',
    adcOrderItems: adcOrderItems.map((adcOrderItem) => ({
      id: adcOrderItem.id.toString(),
      deliveredConcentration: adcOrderItem.deliveredConcentration?.toString() ?? '',
      deliveredAmount: (adcOrderItem.deliveredAmount ?? adcOrderItem.quantity)?.toString() ?? '',
      a280: adcOrderItem.a280?.toString() ?? '',
    })),
  };
}

function toFloat(value: string) {
  return !isNil(value) ? parseFloat(value.replace(',', '')) : null;
}

interface UseConjugateAdcOrderFormProps {
  adcOrderItems: AdcOrderItemDetailsFragment[];
  bioregId: AdcOrderItemDetailsFragment['bioregId'];
}

export function useConjugateAdcOrderForm({ adcOrderItems, bioregId }: UseConjugateAdcOrderFormProps) {
  const defaultValues = buildDefaultValues(adcOrderItems);

  const methods = useForm<ConjugateAdcOrderFormFormValues>({ defaultValues });

  useEffect(() => {
    if (methods.formState.isSubmitSuccessful || !isEqual(defaultValues, methods.formState.defaultValues)) {
      methods.reset(defaultValues);
    }
  }, [methods.formState, methods.reset, defaultValues]);

  const [updateAdcOrderItem] = useUpdateAdcOrderItemMutation();

  const onSubmit = async (data: ConjugateAdcOrderFormFormValues) => {
    return await Promise.all(
      data.adcOrderItems.map(
        async (adcOrderItem) =>
          await updateAdcOrderItem({
            variables: {
              input: {
                id: parseInt(adcOrderItem.id),
                deliveredConcentration: toFloat(adcOrderItem.deliveredConcentration),
                deliveredAmount: toFloat(adcOrderItem.deliveredAmount),
                batchNumber: `${bioregId}-${data.batchNumberSuffix}`,
                formulation: data.formulation,
                a280: toFloat(adcOrderItem.a280),
              },
            },
          })
      )
    );
  };

  return {
    methods,
    onSubmit,
  };
}
