"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.presentIsOne = exports.compare = void 0;
const lodash_1 = require("lodash");
const compare = (a, b) => a === b
    ? 0
    : (0, lodash_1.isNumber)(a) && (0, lodash_1.isNumber)(b)
        ? a - b
        : (0, lodash_1.isString)(a) && (0, lodash_1.isString)(b)
            ? a.localeCompare(b)
            : (0, lodash_1.isBoolean)(a) && (0, lodash_1.isBoolean)(b)
                ? a
                    ? -1 // true first
                    : 1
                : (0, exports.presentIsOne)(a) - (0, exports.presentIsOne)(b);
exports.compare = compare;
const presentIsOne = (a) => (a == null ? 1 : 0);
exports.presentIsOne = presentIsOne;
