import { Tooltip } from '@mui/material';
import get from 'lodash/get';

import {
  ExperimentDetailsFragment,
  TestArticle,
  TreatmentGroup,
} from '#graphql';
import { AppColors } from '#lib/constants';
import {
  StyledContainer,
  StyledCard,
  StyledRow,
  StyledIndex,
  StyledLine,
  StyledBubbleContainer,
  StyledColumn,
  StyledBubble,
  StyledTreatmentLine,
  StyledLabel,
} from './styles';
import { maybeConvertToScientificNotation } from '#lib/utils';
import { find } from 'lodash';

type TreatmentField = {
  fieldName: string;
  width: string;
  testId: string;
  label?: string;
  format?: (ta: TestArticle, value: string) => string;
};
export const TreatmentGroupCardContent = ({
  treatmentGroup,
  includeModel = false,
  experiment,
}: {
  treatmentGroup: TreatmentGroup;
  includeModel?: boolean;
  experiment: ExperimentDetailsFragment;
}) => {
  const formatDosage = (testArticle: TestArticle, _value: string) =>
    maybeConvertToScientificNotation(
      testArticle.moleculeType,
      testArticle.dosage
    );
  const modelFields = [
    ['cellLine.name', '250px', `cellLine`],
    ['strain', '250px', `strain`],
    ['species', '90px', `species`],
    ['gender', '60px', `gender`],
    ['implant', '100px', `implantation`],
    ['subcutaneousEndpoint', '100px', 'subcutaneousEndpoint'],
  ];

  const treatmentFields: TreatmentField[] = [
    { fieldName: 'moleculeType', width: '100px', testId: `moleculeType` },
    {
      fieldName: 'bioregId',
      width: '100px',
      testId: `bioregId`,
      format: (_ta: TestArticle, value: string) => value ?? 'N/A',
    },
    { fieldName: 'name', width: '410px', testId: `name` },
    {
      fieldName: 'doseNumber',
      width: '41px',
      testId: `doseNumber`,
      label: '# Dose',
    },
    {
      fieldName: 'dosage',
      width: '42px',
      testId: `dosage`,
      label: 'Dose',
      format: formatDosage,
    },
    { fieldName: 'doseUnit', width: '60px', testId: `doseUnit` },
    {
      fieldName: 'interval',
      width: '42px',
      testId: 'interval',
      label: 'Interval',
    },
    { fieldName: 'route', width: '60px', testId: 'route', label: 'Route' },
  ];

  const model = find(experiment.models, { id: treatmentGroup.modelId });
  const treatment = find(experiment.treatments, {
    id: treatmentGroup.treatmentId,
  });
  return (
    <StyledContainer>
      {includeModel && (
        <StyledCard>
          <StyledRow>
            <StyledIndex cardcolor={AppColors.SPECTRA_BLUE}>
              {model?.name}
            </StyledIndex>
            <StyledLine>
              {modelFields.map(([objectPath, width, testId], index) => (
                <StyledBubbleContainer key={index} data-test-id={testId}>
                  <Tooltip title={get(model, objectPath) || ''}>
                    <StyledBubble
                      customwidth={width}
                      bubblecolor={AppColors.LOW_GREY}
                    >
                      {get(model, objectPath)}
                    </StyledBubble>
                  </Tooltip>
                </StyledBubbleContainer>
              ))}
            </StyledLine>
          </StyledRow>
        </StyledCard>
      )}
      {!!treatment && (
        <StyledCard>
          <StyledRow>
            <StyledIndex
              cardcolor={AppColors.SPECTRA_YELLOW}
              data-test-id="treatmentNumber"
            >
              {treatment.number}
            </StyledIndex>
            <StyledColumn>
              {treatment.testArticles?.map((testArticle: TestArticle) => (
                <StyledTreatmentLine key={testArticle.id}>
                  {treatmentFields.map(
                    ({
                      fieldName,
                      width,
                      testId,
                      label,
                      format = (_t: TestArticle, v: string) => v,
                    }) => (
                      <StyledBubbleContainer
                        key={fieldName}
                        data-test-id={testId}
                      >
                        {label && <StyledLabel>{label}</StyledLabel>}
                        <Tooltip title={get(testArticle, fieldName) || ''}>
                          <StyledBubble
                            customwidth={width}
                            bubblecolor={AppColors.LOW_GREY}
                          >
                            {format(testArticle, get(testArticle, fieldName))}
                          </StyledBubble>
                        </Tooltip>
                      </StyledBubbleContainer>
                    )
                  )}
                </StyledTreatmentLine>
              ))}
            </StyledColumn>
          </StyledRow>
        </StyledCard>
      )}
    </StyledContainer>
  );
};
