import { ChangeEvent, useState } from 'react';
import styled from '@emotion/styled';
import { Divider } from '@mui/material';
import isNull from 'lodash/isNull';
import toast from 'react-hot-toast';

import { TextField, Autocomplete, CircularProgress } from '@mui/material';
import {
  useAllUsersQuery,
  UserBasicInfoFragment,
  AddUsersToExperimentInput,
  useAddUsersToExperimentMutation,
} from '#graphql';
import { getUserFullName } from '#lib/utils';
import { AppColors, i18n } from '#lib/constants';
import { ButtonNeutralSolid, ButtonPositiveSolid } from '#components/widgets';
import { useUpdateExperiment } from '#lib/hooks';

const StyledContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
});

const StyledDivider = styled(Divider)({
  marginBottom: '10px',
  width: '100%',
});

const ButtonsContainer = styled.div({
  display: 'flex',
  justifyContent: 'center',
});

const CancelButton = styled(ButtonNeutralSolid)({
  background: AppColors.WHITE,
  border: `2px solid ${AppColors.BLACK}`,
  borderRadius: '28px',
  color: AppColors.BLACK,
  letterSpacing: '1.25px',
  textTransform: 'uppercase',
  fontSize: '10px',
  fontWeight: '500',
  fontStyle: 'normal',
  fontFamily: 'Roboto',
  padding: '10px',
  width: '100px',
  marginRight: '10px',
  '&:hover': {
    background: AppColors.WHITE,
  },
});

const StyledButton = styled(ButtonPositiveSolid)({
  background: AppColors.BRAND_GREEN,
  borderRadius: '28px',
  color: AppColors.WHITE,
  letterSpacing: '1.25px',
  textTransform: 'uppercase',
  fontSize: '10px',
  fontWeight: 'bold',
  fontStyle: 'normal',
  fontFamily: 'Roboto',
  padding: '10px',
  width: '100px',
  marginBottom: '25px',
  marginLeft: '10px',

  '&:hover': {
    background: AppColors.BRAND_GREEN,
  },

  '&.Mui-disabled': {
    background: 'none !important',
  },
});

export const AddUsersModal = ({
  onClose,
  experimentId,
  excludedUsers,
}: {
  onClose: () => void;
  experimentId: number;
  excludedUsers: number[];
}) => {
  const { updateExperimentCache } = useUpdateExperiment();
  const [user, setUser] = useState<UserBasicInfoFragment[] | undefined>([]);
  const [query, setQuery] = useState('');
  const { data: allUsers, loading } = useAllUsersQuery({
    variables: {
      limit: 10,
      offset: 0,
      query: query,
      excludedIds: excludedUsers,
    },
  });

  const [addUsersToExperiment] = useAddUsersToExperimentMutation();

  const onAddUser = async () => {
    const addInput: AddUsersToExperimentInput = {
      experimentId,
      userIds: user!.map((user) => user.id),
    };
    const tid = toast.loading(i18n.Common.Saving);
    const response = await addUsersToExperiment({
      variables: {
        input: addInput,
      },
    });
    if (response?.data) {
      updateExperimentCache({
        team: response.data.addUsersToExperiment.team,
        id: experimentId,
      });
      onClose();
      toast.dismiss(tid);
    } else {
      toast.error(i18n.Common.GenericError, { id: tid });
    }
  };

  return (
    <StyledContainer>
      <Autocomplete
        sx={{
          padding: '1rem',
        }}
        multiple
        isOptionEqualToValue={(option, value) => option.email === value.email}
        value={user}
        onChange={(_event, value) => setUser(value)}
        getOptionLabel={(option) => getUserFullName(option)}
        options={allUsers?.users?.items || []}
        loading={loading}
        renderInput={(params) => (
          <TextField
            placeholder="Type for searching users"
            {...params}
            onChange={(ev: ChangeEvent<HTMLInputElement>) =>
              setQuery(ev.target.value)
            }
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress color="primary" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />

      <StyledDivider />
      <ButtonsContainer>
        <CancelButton onClick={onClose}>CANCEL</CancelButton>
        <StyledButton disabled={isNull(user)} onClick={onAddUser}>
          APPLY
        </StyledButton>
      </ButtonsContainer>
    </StyledContainer>
  );
};
