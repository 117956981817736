"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.addDefaultValues = exports.worksheetDataForExport = void 0;
const lodash_1 = require("lodash");
const Calc_1 = require("./Calc");
const worksheetDataForExport = ({ worksheet, entries, }) => {
    const headersMap = {
        entryId: 'Entry ID',
        experimentId: 'Experiment ID',
        worksheetId: 'Worksheet ID',
        worksheetStatus: 'Status',
        worksheetType: 'Worksheet Type',
        sectionId: 'Section ID',
        rowId: 'Row ID',
        columnId: 'Column ID',
    };
    const everyEntry = {
        worksheetId: worksheet.id,
        experimentId: worksheet.experimentId,
        worksheetStatus: worksheet.status,
        worksheetType: worksheet.type,
    };
    const entriesWithCalcValues = (0, Calc_1.updateAllCalcs)(worksheet.definition, entries).filter((entry) => entry.id >= 0);
    const entriesWithCalcAndDefaults = addDefaultValues(worksheet.definition, entriesWithCalcValues);
    const transformedEntries = (0, lodash_1.sortBy)(entriesWithCalcAndDefaults.map((entry) => {
        const { id: entryId, sectionId, rowId, columnId, data } = entry;
        Object.keys(data).forEach((key) => (headersMap[key] = getFieldName(key, entry, worksheet.definition)));
        return { ...everyEntry, entryId, sectionId, rowId, columnId, ...data };
    }), ['sectionId', 'rowId', 'columnId']);
    const headers = Object.values(headersMap);
    const entryKeys = Object.keys(headersMap);
    const data = transformedEntries.map((entry) => entryKeys.map((entryKey) => (entry[entryKey] ?? '').toString()));
    return { headers, entryKeys, data };
};
exports.worksheetDataForExport = worksheetDataForExport;
function addDefaultValues(definition, entries) {
    return entries.map((entry) => {
        const fields = definition.sectionsBySectionId?.[entry.sectionId]?.fields?.filter((f) => f.fieldType !== 'spacer');
        if (fields?.length) {
            for (const field of fields) {
                if (field.default) {
                    entry.data[field.fieldName] = field.default;
                }
            }
        }
        return entry;
    });
}
exports.addDefaultValues = addDefaultValues;
function getFieldName(key, entry, definition) {
    const section = definition.sectionsBySectionId?.[entry.sectionId];
    return section?.fieldsByFieldId?.[key]?.fieldName || key;
}
