import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormHelperText,
  FormLabel,
  Grid,
  InputLabel,
  Stack,
  Typography,
} from '@mui/material';
import { Fragment } from 'react';
import { useWatch } from 'react-hook-form';

import { OmniCheckboxGroup, OmniTextField, ReactHookCheckbox } from '#components/widgets';
import { type TestArticle } from '#graphql';
import { type TreatmentGroupWithTreatment } from '#lib/types';

function testArticleSummary(testArticle: TestArticle) {
  return `${testArticle.name} @ ${testArticle.dosage} ${testArticle.doseUnit}`;
}
interface TreatmentGroupsFormSectionProps {
  treatmentGroups: TreatmentGroupWithTreatment[];
}

export function TreatmentGroupsFormSection({ treatmentGroups }: TreatmentGroupsFormSectionProps) {
  const treatmentGroupsValue = useWatch({ name: 'treatmentGroups' });

  return (
    <OmniCheckboxGroup
      fieldNames={treatmentGroups.map((_tg, index) => `treatmentGroups[${index}].checked`)}
      hideErrorMessage
      render={({ error, errorMessage }) => (
        <Grid item paddingBottom={4} xs={12}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="treatmentGroups-content"
              id="treatmentGroups-header"
            >
              <Stack spacing={0}>
                <FormLabel error={error} component="legend" required>
                  Groups
                </FormLabel>
                <FormHelperText error>{errorMessage ?? ' '}</FormHelperText>
              </Stack>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container>
                <Grid item xs={1}>
                  <InputLabel error={error} shrink={true}>
                    Group
                  </InputLabel>
                </Grid>
                <Grid item xs={5}>
                  <InputLabel shrink={true}>Treatments</InputLabel>
                </Grid>
                <Grid item xs={6}>
                  <InputLabel shrink={true}>Notes</InputLabel>
                </Grid>
                <Grid item xs={12}>
                  <Grid container rowSpacing={1} alignItems="center">
                    {treatmentGroups.map((treatmentGroup, index) => (
                      <Fragment key={treatmentGroup.id}>
                        <Grid item xs={1}>
                          <ReactHookCheckbox
                            controller={{
                              name: `treatmentGroups[${index}].checked`,
                            }}
                            error={error}
                            label={treatmentGroup.treatmentGroupIndex.toString()}
                          />
                        </Grid>
                        <Grid item xs={5}>
                          <Typography variant="body2" component="ul" paddingLeft={2}>
                            {treatmentGroup.treatment.testArticles.map((testArticle) => (
                              <li key={testArticle.id}>{testArticleSummary(testArticle)}</li>
                            ))}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <OmniTextField
                            // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
                            disabled={!treatmentGroupsValue[index].checked}
                            hideErrorMessage
                            label=""
                            minRows={1}
                            multiline
                            name={`treatmentGroups[${index}].notes`}
                          />
                        </Grid>
                      </Fragment>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      )}
      validation={{
        fieldName: 'treatmentGroupsValidity',
        validator(_value, values) {
          return (
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            values.treatmentGroups.some((tg: any) => tg.checked) === true || 'please select at least one group'
          );
        },
      }}
    />
  );
}
