import { DOTMATIC_BASE_URL } from '#lib/constants';
import React from 'react';

export function DotmaticLink({
  id,
  children,
}: {
  id?: string;
  children: React.ReactNode;
}) {
  if (!id) return <>{children}</>;
  return (
    <a href={DOTMATIC_BASE_URL + id} target="_blank">
      {children}
    </a>
  );
}
