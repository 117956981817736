import React from "react";
import styled from "@emotion/styled";
import { lightGrey } from "./SectionStyles";
import { WorksheetSectionDefinition } from "@omnivivo/worksheets-core";

const SectionHeaderDiv = styled.div({
  fontFamily: "Roboto",
  fontSize: "14px",
  fontWeight: "500",
  lineHeight: "24px",
  letterSpacing: "0.15px",
  textAlign: "left",
  paddingTop: "30px",
  marginTop: "10px",
  borderTop: `1px solid ${lightGrey}`,
});

export const SectionHeader = ({
  section,
  sectionName,
}: {
  section?: WorksheetSectionDefinition;
  sectionName?: string;
}) => (
  <SectionHeaderDiv title={section ? `section id: ${section.id}` : sectionName}>
    {section ? section.sectionName : sectionName}
  </SectionHeaderDiv>
);
