"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.bulkAddEntriesLocal = exports.bulkCreateEntriesLocal = exports.addEntryLocal = exports.createEntryLocal = exports.deleteEntryLocal = exports.updateValueLocal = exports.entryMatches = exports.getUniqueNegativeWorksheetEntryId = exports.getUniqueWorksheetEntryId = void 0;
const getUniqueWorksheetEntryId = (entries) => entries.reduce((maxSoFar, { id }) => Math.max(maxSoFar, id), -1) + 1;
exports.getUniqueWorksheetEntryId = getUniqueWorksheetEntryId;
const getUniqueNegativeWorksheetEntryId = (entries) => entries.reduce((minSoFar, { id }) => Math.min(minSoFar, id), 0) - 1;
exports.getUniqueNegativeWorksheetEntryId = getUniqueNegativeWorksheetEntryId;
const entryMatches = (entry, entryId, sectionId) => entryId === entry.id && (sectionId ? sectionId === entry.sectionId : true);
exports.entryMatches = entryMatches;
const updateValueLocal = (entries, { entryId, sectionId, fieldId, value }) => {
    const foundEntry = entries.find((entry) => (0, exports.entryMatches)(entry, entryId, sectionId));
    if (foundEntry && foundEntry.data[fieldId] !== value) {
        return [
            ...entries.filter((entry) => !(0, exports.entryMatches)(entry, entryId, sectionId)),
            { ...foundEntry, data: { ...foundEntry.data, [fieldId]: value } },
        ];
    }
    else
        return entries;
};
exports.updateValueLocal = updateValueLocal;
const deleteEntryLocal = (entries, { entryId, sectionId }) => [...entries.filter((entry) => !(0, exports.entryMatches)(entry, entryId, sectionId))];
exports.deleteEntryLocal = deleteEntryLocal;
const createEntryLocal = (entries, newEntry) => ({
    ...newEntry,
    sectionId: newEntry.sectionId || '',
    worksheetId: newEntry.worksheetId || 0,
    data: newEntry.data || {},
    id: newEntry.id ?? (0, exports.getUniqueWorksheetEntryId)(entries),
});
exports.createEntryLocal = createEntryLocal;
const addEntryLocal = (entries, newEntry) => [...entries, (0, exports.createEntryLocal)(entries, newEntry)];
exports.addEntryLocal = addEntryLocal;
const bulkCreateEntriesLocal = (currentEntries, newEntries) => {
    let id = (0, exports.getUniqueWorksheetEntryId)(currentEntries);
    return newEntries.map((newEntry) => ({
        ...newEntry,
        sectionId: newEntry.sectionId || '',
        worksheetId: newEntry.worksheetId || 0,
        data: newEntry.data || {},
        id: id++,
    }));
};
exports.bulkCreateEntriesLocal = bulkCreateEntriesLocal;
const bulkAddEntriesLocal = (currentEntries, newEntriesWithIds) => [...currentEntries, ...newEntriesWithIds];
exports.bulkAddEntriesLocal = bulkAddEntriesLocal;
