import isPropValid from '@emotion/is-prop-valid';
import styled from '@emotion/styled';
import { TextField } from '@mui/material';

import { AppColors } from '#lib/constants';

export const StyledContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '5px',
});

export const StyledRow = styled.div({
  display: 'flex',
  alignItems: 'flex-start',
  gap: '40px',
});

export const StyledItem = styled.div(
  ({ flex = '0 32%' }: { flex?: string }) => ({
    display: 'flex',
    flexDirection: 'column',
    flex: flex,
  })
);

export const StyledTitle = styled.h4({
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 'bold',
  fontSize: '14px',
  lineHeight: '24px',
  letterSpacing: '0.15px',
  color: AppColors.BLACK,
});

export const StyledBubbleContainer = styled.div({
  width: '12vw',
});

export const StyledTextField = styled(TextField, {
  shouldForwardProp: (prop) =>
    prop === 'inputProps' ||
    (isPropValid(prop) && prop !== 'invalid') ||
    prop === 'multiline',
})(({ invalid }: { invalid?: boolean }) => ({
  fontStyle: 'normal',
  fontWeight: '400',
  background: AppColors.WHITE,
  borderRadius: '4px',
  fontSize: '18px',
  color: AppColors.DARK_GREY,
  borderColor: invalid ? AppColors.RED : AppColors.GREY3,
  outlineColor: invalid ? AppColors.RED : AppColors.GREY3,
  resize: 'none',
  '&::-webkit-scrollbar': {
    width: '0',
    borderRadius: '8px',
  },
}));

export const StyledApprovalNameField = styled(TextField)({
  div: {
    fontSize: '14px',
    lineHeight: '14px',
    padding: '5px',
    color: AppColors.BLACK,
    background: AppColors.LIGHT_LIGHT_GREY,
    borderRadius: '4px',
  },
  fieldset: {
    border: 'transparent',
    '.Mui-disabled': {
      color: AppColors.BLACK,
    },
  },
  input: {
    padding: 0,
  },
});
