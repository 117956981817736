"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getWorksheetDefinition = void 0;
const WorksheetTypes_1 = require("./WorksheetTypes");
const DataCollectionAnimalWeightWorksheet_1 = require("./DataCollectionAnimalWeightWorksheet");
const DataCollectionTumorSizeWorksheet_1 = require("./DataCollectionTumorSizeWorksheet");
const getWorksheetDefinition = (worksheetType, treatmentGroupsForWorksheet) => {
    switch (worksheetType) {
        case 'dataCollectionAnimalWeight':
            return (0, DataCollectionAnimalWeightWorksheet_1.createWeightChangeDataCollectionWorksheetDefinition)(treatmentGroupsForWorksheet);
        case 'dataCollectionTumorSize':
            return (0, DataCollectionTumorSizeWorksheet_1.createTumorSizeDataCollectionWorksheetDefinition)(treatmentGroupsForWorksheet);
        default:
            return WorksheetTypes_1.worksheetsByType[worksheetType];
    }
};
exports.getWorksheetDefinition = getWorksheetDefinition;
