import { FocusEvent } from 'react';

import { Can } from '#components/contexts';
import { StatusBox } from '..';
import { NameBubble, SyncedExperimentTextArea } from '#components/widgets';
import { getApprovalNameDefaultText } from '#lib/utils';
import {
  StyledBubbleContainer,
  StyledContainer,
  StyledItem,
  StyledRow,
  StyledTitle,
  StyledApprovalNameField,
} from './styles';
import { i18n } from '#lib/constants';
import { useUpdateExperiment } from '#lib/hooks';
import { ExperimentDetailsFragment } from '#graphql';

export const GeneralDetails = ({
  experiment,
}: {
  experiment: ExperimentDetailsFragment;
}) => {
  const { updateExperiment } = useUpdateExperiment();

  return (
    <StyledContainer>
      <StyledRow>
        <StyledItem data-test-id={`experimentAuthor`}>
          <StyledTitle>Author</StyledTitle>
          <StyledBubbleContainer>
            <NameBubble user={experiment.owner} />
          </StyledBubbleContainer>
        </StyledItem>

        <StyledItem data-test-id={`experimentNameInput`} flex="5 1 0">
          <StyledTitle>Experiment Name</StyledTitle>
          <Can
            I="update"
            this={experiment as never}
            passThrough
            field="approvalName"
          >
            {(allowed: boolean) => (
              <StyledApprovalNameField
                defaultValue={experiment.approvalName || ''}
                data-test-id={`approvedExperimentName`}
                placeholder={getApprovalNameDefaultText(experiment.status)}
                disabled={!allowed}
                {...(experiment.status === 'Review' && {
                  onBlur: (ev: FocusEvent<HTMLInputElement>) => {
                    updateExperiment({
                      approvalName: ev.target.value,
                      id: experiment.id,
                    });
                  },
                })}
              />
            )}
          </Can>
        </StyledItem>
      </StyledRow>

      <StyledRow>
        <StyledItem data-test-id={`experimentStatus`}>
          <StyledTitle>Status</StyledTitle>
          <StatusBox experiment={experiment} />
        </StyledItem>

        <StyledItem flex="5 1 0">
          <StyledTitle>{'Hypothesis & summary*'}</StyledTitle>
          <Can
            I="update"
            this={experiment as never}
            passThrough
            field="hypothesis"
          >
            {(allowed: boolean) => (
              <SyncedExperimentTextArea
                experimentId={experiment.id}
                disabled={!allowed}
                name="hypothesis"
                required={i18n.ValidateExperiment.NoHypothesis}
              />
            )}
          </Can>
        </StyledItem>
      </StyledRow>
    </StyledContainer>
  );
};
