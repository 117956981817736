export const i18n = {
  AppName: 'Omni-Vivo',
  Errors: {
    BAD_USER_INPUT: 'The data provided was invalid.',
    FORBIDDEN: 'You do not have permission to perform this action.',
    INTERNAL_SERVER_ERROR: 'Sorry, something unexpected happened, please try again.',
    INVALID_MTA_EXPERIMENT:
      'Please add at least one associated user who is an admin to this MTA experiment before submitting for approval.',
    INVALID_MTA_EXPERIMENT_TEAM:
      'This user cannot be removed from the team because an MTA experiment must have at least one associated admin user.',
    NOT_FOUND: 'Sorry, the requested data could not be found, please try again.',
    UNAUTHENTICATED: 'You have not authenticated or your session has expired.\n\nPlease log in before continuing.',
    SEAGEN_UNAUTHENTICATED: 'You do not have access to OmniVivo.',
  },
  Common: {
    Draft: 'Draft',
    Review: 'Review',
    Provision: 'Provision',
    Ready: 'Ready',
    Execution: 'Execution',
    Completed: 'Completed',
    Saving: 'Saving data, please wait...',
    Duplicating: 'Duplicating data, please wait...',
    Deleting: 'Deleting data, please wait...',
    Updating: 'Updating data, please wait...',
    CompleteFields: 'Please complete all the required fields before saving data.',
    GenericError: 'Oops, something happened...',
    GenericEmpty: 'Nothing here',
    Done: 'Done',
    NoFiles: 'No files selected',
    EmptySearch: 'No items match your search.',
    addAttachments: `Add Attachments`,
  },
  ExperimentTracker: {
    RelatedExperimentMessage: 'List of Related experiments from',
    EmptyRelatedExperimentMessage: 'No experiments were found from',
  },
  ExperimentView: {
    ExperimentDeleted: 'Experiment deleted successfully',
    TreatmentGroupNotesMessage: 'Notes were updated successfully',
    DeletingAssociatedUser: 'Deleting user, please wait...',
    DeleteExperimentQuestion: 'Are you sure you want to delete the experiment',
    CSVExportLoadingMessage: 'Experiment is being exported, please wait...',
    ExportedCSVMessage: 'Experiment exported successfully',
    DuplicateExperimentMessage: 'Experiment duplicated successfully',
    DuplicateExperimentTransitionMessage:
      'Experiment is being duplicated, please wait while we redirect to the new experiment...',
    AddTeamMemberMessage: 'User has been added successfully',
    DeleteTeamMemberMessage: 'User has been removed successfully',
    UploadingFiles: 'Uploading files, please wait...',
    DeletedFileMessage: 'File deleted successfully',
    DeletingFileMessage: 'Deleting file, please wait...',
    DownloadingFileMessage: 'Downloading File, please wait...',
    DownloadedFileMessage: 'File Downloaded successfully',
    NoModelsDefined: 'Please define at least one model before creating a group',
    NoTreatmentsDefined: 'Please defined at least one treatment before creating a group',
    ExperimentCreatedMessage: 'Experiment Created Successfully',
    PopulateTreatmentGroups: 'Creating groups, please wait...',
    ExperimentDuplicateName: 'Experiment name already exists',
    'Access denied': 'You do not have permission to view this experiment',
    ExperimentUpdatingExperiment: 'Updating experiment...',
    ExperimentUpdatedMessage: 'Experiment updated successfully',
    Workspace: {
      QueryError: 'Sorry, there was a problem fetching the worksheet data, configuration, please contact support.',
      NoTreatmentGroups: 'Worksheets for this experiment cannot be displayed until groups have been specified.',
      NoWorksheets: 'No worksheets have been created for this experiment.',
    },
    InvalidTreatmentGroupError: 'Please make sure all groups have at least one animal assigned to them.',
  },
  TreatmentCard: {
    DeleteConfirmation: 'Are you sure you want to delete this treatment?',
    CantDelete: 'Please delete the associated groups before deleting this treatment.',
  },
  ModelCard: {
    DeleteConfirmation: 'Are you sure you want to delete this animal model?',
    CantDelete: 'Please delete the associated groups before deleting this model.',
  },
  TreatmentGroupCard: {
    DeleteConfirmation: 'Are you sure you want to delete this group?',
    DeleteTreatmentGroup: {
      success: 'Treatment group deleted',
      error: 'Treatment groups deletion failed',
      loading: 'Deleting treatment group...',
    },
    UpdateTreatmentGroup: {
      success: 'Treatment group updated',
      error: 'Treatment groups update failed',
      loading: 'Updating treatment group...',
    },
  },
  Worksheet: {
    DeleteColumnConfirmation: 'Are you sure you want to delete this day from this group?',
  },
  AnimalInventoryWorksheet: {
    DeleteConfirmation: 'Are you sure you want to delete this PO?',
    DeleteReservationConfirmation: 'Are you sure you want to delete this reservation?',
    CantDelete: 'Please delete the associated reservations before deleting this PO.',
    DeleteInventoryAccessDenied: 'Only administrators can delete inventory.',
    DeleteReservationAccessDenied: 'You do not have permission to delete reservations.',
  },
  ValidateExperiment: {
    Main: 'Please fill the highlighted required field in order to submit the experiment:',
    NoConclusions: 'In the Documentation tab, please fill out the conclusions section',
    NoModels: 'Please add at least one model to be able to set this experiment to review',
    NoTreatmentGroups: 'Please add at least one group to be able to set this experiment to review',
    NoTreatments: 'Please add at least one treatment to be able to set this experiment to review',
    NoExperimentName: 'Please add an experiment name to be able to approve this experiment',
    NoPurposes: 'In the Documentation tab, please fill out the purposes section',
    NoDesignMethods: 'In the Documentation tab, please fill out the design methods section',
    NoEndpoints: 'In the Documentation tab, please fill out the endpoints section',
    NoHypothesis: 'Please fill out the hypothesis section',
    NoDoseNumber: 'Number of dose number required',
    NoDosage: 'Dosage required',
  },
  Profile: {
    UpdateUserInformationMessage: 'User information updated successfully',
  },
  Settings: {
    UpdateExperimentTagsMessage: 'Experiment settings updated successfully',
    NoName: "Can't save settings without a name",
    IsDuplicate: 'An item with the same name and category already exists',
    InvalidCategory: 'Invalid Category. Must be one of: ',
    RemoveItemConfirmation: 'Are you sure you want to permanently remove this item?',
  },
  AdcOrderDataHeadersWithoutOverage: {
    id: 'Id',
    experimentName: 'Experiment Name',
    purpose: 'Purpose',
    bioregId: 'Bioreg Id',
    name: 'Name',
    antibodyName: 'Antibody',
    mgPerMl: 'Min conc. mg/ml',
    quantity: 'Requested mg',
    approvalCount: '# of Approvals',
    lastUpdated: 'Last Updated',
  },
  AdcOrderDataHeaders: {
    experimentName: 'Experiment Name',
    purpose: 'Purpose',
    bioregId: 'Bioreg Id',
    name: 'Name',
    antibodyName: 'Antibody',
    mgPerMl: 'Min conc. mg/ml',
    quantity: 'Requested mg',
    overage: 'Avg overage',
  },
  AdcOrderFulfillmentHeaders: {
    batchNumber: 'Batch ID',
    deliveredAmount: 'Delivered Amount mg',
    deliveredConcentration: 'Delivered Concentration mg/ml',
    formulation: 'Diluent / Formulation',
    a280: 'A280',
    deliveredAt: 'Delivered *',
  },
  AdcOrderDataValidations: {
    batchNumberRequired: 'Batch number is required',
    formulationRequired: 'Formulation is required',
    minimumValue: 'Value must be greater than or equal to',
    maximumValue: 'Value must be less than or equal to',
    deliveredAtRequired: 'Delivered date is required',
  },
  NonAdcOrderDataHeaders: {
    name: 'Name',
    category: 'Category',
    bioregId: 'Bioreg Id',
    quantity: 'Requested mg',
    unit: 'Unit',
  },
  NonAdcOrderFulfillmentHeaders: {
    fulfilledAt: 'Fulfilled *',
  },
  DeliverAdcOrderItem: {
    success: 'ADC order item marked as delivered',
    error: 'ADC order item marked as delivered failed',
    loading: 'Marking ADC order item as delivered...',
  },
  UpdateAdcOrderItemDelivery: {
    success: 'Updated ADC order item delivery date',
    error: 'Failed to update ADC order item delivery date',
    loading: 'Updating ADC order item delivery date...',
  },
  DeliverAllOrderItems: {
    success: 'All ADC order items marked as delivered',
    error: 'Failed to mark all ADC order items as delivered',
    loading: 'Marking all ADC order items as delivered...',
  },
  ReturnAdcOrderItem: {
    success: 'ADC order item marked as returned',
    error: 'ADC order item marked as returned failed',
    loading: 'Marking ADC order item as returned...',
  },
  SaveAdcOrderItem: {
    success: 'ADC order item saved',
    error: 'ADC order item saving failed',
    loading: 'Saving ADC order item...',
  },
  SaveNonAdcOrderItem: {
    success: 'Test article order item saved',
    error: 'Test article order item saving failed',
    loading: 'Saving test article order item...',
  },
  SaveAdcOrderNotes: {
    success: 'ADC order notes saved',
    error: 'Failed to save ADC order notes',
    loading: 'Saving ADC order notes...',
  },
  UploadAdcOrderItemCsv: {
    success: 'Successfully uploaded Adc Orders',
    error: 'There was a problem uploading or validating the Adc Orders, see errors below',
    loading: 'Uploading and validating Adc Orders, please wait...',
  },
  Orders: {
    EmptyOrders: 'This experiment does not have any ADC Orders to preview.',
    EmptyUnfulfilledAdcOrders: 'This experiment does not have any unfulfilled ADC orders',
    EmptyFulfilledAdcOrders: 'This experiment does not have any fulfilled ADC orders',
  },
  Experiments: {
    Form: {
      Title: {
        Add: 'Create Experiment',
        Update: 'Update Experiment',
      },
      Error: {
        Add: 'There was a problem creating the experiment',
        Update: 'There was a problem updating the experiment',
      },
      Submit: {
        Add: 'Create experiment',
        Update: 'Update experiment',
      },
      Success: {
        Add: 'Successfully created the experiment',
        Update: 'Successfully updated the experiment',
      },
    },
  },
  BloodBioanalysis: {
    Delete: {
      Confirmation: 'Are you sure you want to delete this Blood Bioanalysis?',
      loading: 'Deleting Blood Bioanalysis...',
      success: 'Successfully deleted Blood Bioanalysis',
      error: 'There was a problem deleting the blood bioanalysis.',
    },
    Form: {
      Error: {
        Add: 'There was a problem adding the blood bioanalysis.',
        Update: 'There was a problem updating the blood bioanalysis.',
      },
      Submit: {
        Add: 'Add Blood Bioanalysis',
        Update: 'Update Blood Bioanalysis',
      },
      Success: {
        Add: 'Successfully added the blood bioanalysis.',
        Update: 'Successfully updated the blood bioanalysis.',
      },
    },
  },
  TissueBioanalysis: {
    Delete: {
      Confirmation: 'Are you sure you want to delete this Tissue Bioanalysis?',
      loading: 'Deleting Tissue Bioanalysis...',
      success: 'Successfully deleted Tissue Bioanalysis',
      error: 'There was a problem deleting the tissue bioanalysis.',
    },
    Form: {
      Error: {
        Add: 'There was a problem adding the tissue bioanalysis.',
        Update: 'There was a problem updating the tissue bioanalysis.',
      },
      Submit: {
        Add: 'Add Tissue Bioanalysis',
        Update: 'Update Tissue Bioanalysis',
      },
      Success: {
        Add: 'Successfully added the tissue bioanalysis.',
        Update: 'Successfully updated the tissue bioanalysis.',
      },
    },
  },
  Imaging: {
    Delete: {
      Confirmation: 'Are you sure you want to delete Imaging?',
      loading: 'Deleting Imaging...',
      success: 'Successfully deleted Imaging',
      error: 'There was a problem deleting imaging.',
    },
    Form: {
      Error: {
        Add: 'There was a problem adding Imaging.',
        Update: 'There was a problem updating Imaging.',
      },
      Submit: {
        Add: 'Add Imaging',
        Update: 'Update Imaging',
      },
      Success: {
        Add: 'Successfully added Imaging.',
        Update: 'Successfully updated Imaging.',
      },
    },
  },
  PurchaseOrders: {
    DeleteModal: {
      Content: 'All associated reservations will also be deleted, are you sure you want to delete this purchase order?',
      Error: 'There was a problem deleting this purchase order.',
      Title: 'Delete Purchase Order',
      Success: 'Successfully deleted the purchase order.',
    },
    Form: {
      Title: {
        Add: 'Add Purchase Order',
        Update: 'Update Purchase Order',
      },
      Error: {
        Add: 'There was a problem adding the purchase order.',
        Update: 'There was a problem updating the purchase order.',
      },
      Submit: {
        Add: 'Add Purchase Order',
        Update: 'Update Purchase Order',
      },
      Success: {
        Add: 'Successfully added the purchase order.',
        Update: 'Successfully updated the purchase order.',
      },
    },
  },
  Reservations: {
    DeleteModal: {
      Content: 'Are you sure you want to delete this reservation?',
      Error: 'There was a problem deleting this reservation.',
      Title: 'Delete Reservation',
      Success: 'Successfully deleted the reservation.',
    },
    Form: {
      Title: {
        Add: 'Add Reservation',
        Update: 'Update Reservation',
      },
      Description: 'Reservations can only be made for approved experiments that have not been completed.',
      Error: {
        Add: 'There was a problem adding the reservation.',
        Update: 'There was a problem updating the reservation.',
      },
      Submit: {
        Add: 'Add Reservation',
        Update: 'Update Reservation',
      },
      Success: {
        Add: 'Successfully added the reservation.',
        Update: 'Successfully updated the reservation.',
      },
    },
  },
  Users: {
    Form: {
      Title: {
        Add: 'Add User',
        Update: 'Update User',
      },
      Error: {
        Add: 'There was a problem adding the user.',
        Update: 'There was a problem updating the user.',
      },
      Submit: {
        Add: 'Add User',
        Update: 'Update User',
      },
      Success: {
        Add: 'Successfully added the user.',
        Update: 'Successfully updated the user.',
      },
    },
  },
  Worksheets: {
    WorksheetEntrySaveFailed: 'Entry save failed',
  },
  CroCellLines: {
    DeleteModal: {
      Content: 'Are you sure you want to delete this cro cell line?',
      Error: 'There was a problem deleting this cro cell line.',
      Title: 'Delete cro cell line',
      Success: 'Successfully deleted the cro cell line.',
    },
    Form: {
      Title: {
        Add: 'Add cro cell line',
        Update: 'Update cro cell line',
      },
      Error: {
        Add: 'There was a problem adding the cro cell line.',
        Update: 'There was a problem updating the cro cell line.',
      },
      Submit: {
        Add: 'Add cro cell line',
        Update: 'Update cro cell line',
      },
      Success: {
        Add: 'Successfully added the cro cell line..',
        Update: 'Successfully updated the cro cell line..',
      },
    },
    CroCellLineHeaders: {
      name: 'Name',
      modelType: 'Model Type',
      disease: 'Disease',
      recommendedGrowthMedia: 'Recommended Growth Media',
      cultureProperties: 'Cultural Properties',
      source: 'Source',
      location: 'Location',
      species: 'Species',
    },
  },
  PKBloodWorksheet: {
    emptyMessage:
      'Sorry, there was a problem fetching the PK Blood Worksheet data, configuration, please contact support.',
    UpdateStatus: {
      loading: 'Updating the status...',
      success: 'Successfully updated the status',
      error: 'There was a problem updating the status',
    },
    UpdateBasisDate: {
      loading: 'Updating the basis date...',
      success: 'Successfully updated the basis date',
      error: 'There was a problem updating the basis date',
    },
    UpdateNotes: {
      loading: 'Updating the notes...',
      success: 'Successfully updated the notes',
      error: 'There was a problem updating the notes',
    },
    DeletePKBloodCollection: {
      Confirmation: [
        'All data will be lost and unrecoverable, please export the data before proceeding.',
        'Are you sure you want to delete the existing collection data?',
      ],
      loading: 'Deleting PK Blood Collection data...',
      success: 'Successfully deleted PK Blood Collection data',
      error: 'There was a problem deleting the PK Blood Collection data.',
    },
    InvalidPKBloodCollectionDeletionError:
      'This experiment cannot be returned to Draft because data collection has started on the PK Blood Worksheet.\n\nIf it is necessary to return the experiment to Draft, please visit the associated PK Blood Worksheet, export the data, and clear the existing data.',
    PopulateActualTimesWithExpectedTimes: {
      Confirmation: [
        'Actual collection times exist for this timepoint and will be overwritten by the current expected times.',
        'Are you sure you want to populate the actual times with the expected times?',
      ],
    },
  },
  PKTissueWorksheet: {
    emptyMessage:
      'Sorry, there was a problem fetching the PK Tissue Worksheet data, configuration, please contact support.',
    UpdateStatus: {
      loading: 'Updating the status...',
      success: 'Successfully updated the status',
      error: 'There was a problem updating the status',
    },
    UpdateBasisDate: {
      loading: 'Updating the basis date...',
      success: 'Successfully updated the basis date',
      error: 'There was a problem updating the basis date',
    },
    UpdateNotes: {
      loading: 'Updating the notes...',
      success: 'Successfully updated the notes',
      error: 'There was a problem updating the notes',
    },
    DeletePKTissueCollection: {
      Confirmation: [
        'All data will be lost and unrecoverable, please export the data before proceeding.',
        'Are you sure you want to delete the existing collection data?',
      ],
      loading: 'Deleting PK Tissue Collection data...',
      success: 'Successfully deleted PK Tissue Collection data',
      error: 'There was a problem deleting the PK Tissue Collection data.',
    },
    InvalidPKTissueCollectionDeletionError:
      'This experiment cannot be returned to Draft because data collection has started on the PK Tissue Worksheet.\n\nIf it is necessary to return the experiment to Draft, please visit the associated PK Tissue Worksheet, export the data, and clear the existing data.',
    PopulateActualTimesWithExpectedTimes: {
      Confirmation: [
        'Actual collection times exist for this timepoint and will be overwritten by the current expected times.',
        'Are you sure you want to populate the actual times with the expected times?',
      ],
    },
  },
} as const;
