import { TextField } from '@mui/material';
import { debounce } from 'lodash';
import { type ChangeEvent, useCallback } from 'react';
import toast from 'react-hot-toast';

import { SyncIndicator } from '#components/widgets';
import { type PkTissueWorksheetFragment, useUpdatePkTissueWorksheetNotesMutation } from '#graphql';
import { DEFAULT_DEBOUNCE, i18n } from '#lib/constants';

interface NotesProps {
  canUpdateNotes: boolean;
  worksheet: Pick<PkTissueWorksheetFragment, 'id' | 'notes' | 'status'>;
}

export function Notes(props: NotesProps) {
  const { canUpdateNotes, worksheet } = props;
  const [updateNotes, { loading, error }] = useUpdatePkTissueWorksheetNotesMutation();

  const onChangeNotes = useCallback(
    debounce((event: ChangeEvent<HTMLInputElement>) => {
      void toast.promise(
        updateNotes({
          variables: {
            input: {
              id: worksheet.id,
              notes: event.target.value,
            },
          },
        }),
        i18n.PKTissueWorksheet.UpdateNotes
      );
    }, DEFAULT_DEBOUNCE),
    [updateNotes]
  );

  return (
    <TextField
      name="notes"
      inputProps={{ maxLength: 1000 }}
      disabled={!canUpdateNotes}
      label="Notes"
      multiline
      InputProps={{
        sx: {
          alignItems: 'start',
        },
        endAdornment: <SyncIndicator dirty={false} error={error} loading={loading} />,
      }}
      fullWidth
      minRows={1}
      onChange={onChangeNotes}
      defaultValue={worksheet.notes}
    />
  );
}
