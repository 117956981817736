import { Navigate, Route, Routes } from 'react-router';
import { NotFound } from '#components/pages';
import { AdcOrders, AdcOrderHeader } from '#components/partials';
import { StyledSeparator } from '#components/styles';
import {
  FULFILLED_ADC_ORDERS_PATH,
  UNFULFILLED_ADC_ORDERS_PATH,
} from '#lib/constants';

export const AdcOrdersPage = () => (
  <>
    <AdcOrderHeader />
    <StyledSeparator />
    <Routes>
      <Route
        path={UNFULFILLED_ADC_ORDERS_PATH}
        element={<AdcOrders status="inProgress" />}
      />
      <Route
        path={FULFILLED_ADC_ORDERS_PATH}
        element={<AdcOrders status="completed" />}
      />
      <Route
        index
        element={<Navigate to={UNFULFILLED_ADC_ORDERS_PATH} replace={true} />}
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  </>
);
