import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { Info, type DateTime } from 'luxon';

// To workaround bug in Luxon Adapter
// https://github.com/mui/material-ui/issues/30591
export class DateAdapter extends AdapterLuxon {
  // /** Controls the day buttons of the calendar month view. */
  getWeekArray = (date: DateTime) => {
    const weekStartsOn = Info.getStartOfWeek();

    const startOfMonth = date.startOf('month');
    const endOfMonth = date.endOf('month');
    const firstDayOfMonth = startOfMonth.weekday;

    const startOfWeek = startOfMonth.minus({
      days: (firstDayOfMonth - weekStartsOn + 7) % 7,
    });

    const endOfWeek = endOfMonth.plus({ days: 6 - endOfMonth.weekday + weekStartsOn }).endOf('day');

    const { days } = endOfWeek.diff(startOfWeek, 'days').toObject();

    const weeks: DateTime[][] = [];
    new Array<number>(Math.round(days ?? 0))
      .fill(0)
      .map((_, i) => i)
      .map((day) => startOfWeek.plus({ days: day }))
      .forEach((v, i) => {
        if (i === 0 || (i % 7 === 0 && i > 6)) {
          weeks.push([v]);
          return;
        }

        weeks[weeks.length - 1].push(v);
      });

    return weeks;
  };

  startOfWeek = (value: DateTime) => {
    return value.startOf('week', { useLocaleWeeks: true });
  };
}
