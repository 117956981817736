import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box } from '@mui/material';
import {
  DataGridPremium,
  gridClasses,
  useGridApiRef,
  type GridColDef,
  type GridRowParams,
  type GridValidRowModel,
} from '@mui/x-data-grid-premium';
import { compact, isNil, isNull, isUndefined, max, min } from 'lodash';
import { useCallback, useMemo } from 'react';

import {
  type ListContentDetailsFragment,
  type BloodBioanalysisTimepointFragment,
  type ExperimentDetailsFragment,
  type PkBloodWorksheetCollectionFragment,
} from '#graphql';
import { formatDate, formatTime } from '#lib/utils';
import { DetailPanel, type DetailRow } from './DetailPanel';

interface GridRow extends GridValidRowModel {
  id: number;
  date: string;
  dose: number;
  timepoint: string;
  expectedCollectionTime: string | null;
  completedTime: string | null;
}

interface CollectionsProps {
  bioanalysisTimepoints: ListContentDetailsFragment[];
  canUpdateActualTime: boolean;
  collections: PkBloodWorksheetCollectionFragment[] | undefined;
  experiment: ExperimentDetailsFragment;
  timepoints: BloodBioanalysisTimepointFragment[];
  worksheetId: number;
}

export function Collections(props: CollectionsProps) {
  const { bioanalysisTimepoints, canUpdateActualTime, collections, experiment, timepoints, worksheetId } = props;

  if (isUndefined(collections)) {
    return null;
  }

  const apiRef = useGridApiRef();

  const { rows, detailRows } = useMemo(() => {
    const rows: GridRow[] = [];
    const detailRows = new Map<number, DetailRow[]>();
    const index = new Map<string, number>();

    for (const collection of collections) {
      const timepoint = timepoints.find((t) => t.id === collection.bloodBioanalysisTimepointId);
      if (isUndefined(timepoint)) {
        // This should not be possible
        continue;
      }

      const key = `${collection.expectedDate}-${timepoint.dose}-${timepoint.minutes}`;

      const detailRow = buildDetailRow(collection, experiment);

      let rowId = index.get(key);
      if (isUndefined(rowId)) {
        rowId = rows.length + 1;
        index.set(key, rowId);
        rows.push({
          id: rowId,
          date: !isNil(collection.expectedDate) ? formatDate(collection.expectedDate) : 'TBD',
          dose: timepoint.dose,
          timepoint:
            bioanalysisTimepoints.find(
              (bioanalysisTimepoint) => bioanalysisTimepoint.name === timepoint.minutes.toString()
            )?.description ?? '',
          // assigned below
          expectedCollectionTime: null,
          completedTime: null,
        });
        detailRows.set(rowId, [detailRow]);
      } else {
        detailRows.set(rowId, [...(detailRows.get(rowId) ?? []), detailRow]);
      }
    }

    const updatedRows = rows.map((row) => {
      const detailRowGroup = detailRows.get(row.id);

      const completedTime = (detailRowGroup ?? []).some((detailRow) => isNil(detailRow.actualTime))
        ? null
        : max(detailRowGroup?.map((detailRow) => detailRow.actualTime)) ?? null;

      const expectedCollectionTime =
        isNull(completedTime) && (detailRowGroup ?? []).some((detailRow) => !isNil(detailRow.expectedTime))
          ? min(compact(detailRowGroup?.map((detailRow) => detailRow.expectedTime))) ?? null
          : null;

      return {
        ...row,
        expectedCollectionTime,
        completedTime,
      };
    });
    return { rows: updatedRows, detailRows };
  }, [collections, timepoints, experiment]);

  const columns: Array<GridColDef<GridRow>> = useMemo(
    () => [
      { field: 'date', headerName: 'Date', width: 150, sortable: false },
      { field: 'dose', headerName: 'Dose', sortable: false },
      { field: 'timepoint', headerName: 'Timepoint', width: 150, sortable: false },
      {
        field: 'expectedCollectionTime',
        headerName: 'Exp. Collection Time',
        width: 150,
        sortable: false,
        valueGetter(params) {
          return formatTime(params.row.expectedCollectionTime) ?? '-';
        },
      },
      {
        field: 'completedTime',
        headerName: 'Completed Time',
        width: 150,
        sortable: false,
        valueGetter(params) {
          return formatTime(params.row.completedTime) ?? '-';
        },
      },
    ],
    [detailRows]
  );

  const getDetailPanelContent = useCallback(
    (rowParams: GridRowParams<GridRow>) => {
      const rows = detailRows.get(rowParams.row.id) ?? [];

      return (
        <DetailPanel
          canUpdateActualTime={canUpdateActualTime}
          closePanel={() => {
            apiRef.current.toggleDetailPanel(rowParams.row.id);
          }}
          data={rows}
          worksheetId={worksheetId}
        />
      );
    },
    [detailRows, canUpdateActualTime]
  );

  return (
    <Box paddingY={2}>
      <DataGridPremium
        apiRef={apiRef}
        columns={columns}
        rows={rows}
        autoHeight
        density="compact"
        disableColumnMenu
        disableColumnReorder
        disableColumnResize
        hideFooter
        rowThreshold={0}
        getDetailPanelHeight={() => 'auto'}
        getDetailPanelContent={getDetailPanelContent}
        getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
        components={{
          DetailPanelExpandIcon: ExpandMoreIcon,
          DetailPanelCollapseIcon: ExpandLessIcon,
        }}
        sx={(theme) => ({
          '&.MuiDataGrid-root': {
            borderLeft: '0',
            borderRight: '0',
            borderTop: '0',
          },
          [`& .${gridClasses.columnSeparator}`]: {
            display: 'none',
          },
          [`& .${gridClasses.columnHeaderTitle}`]: {
            fontWeight: 600,
          },
          [`& .${gridClasses.row}.odd`]: {
            backgroundColor: '#ECECEC',
          },
          [`& .${gridClasses['row--detailPanelExpanded']}`]: {
            backgroundColor: theme.palette.primary.light,
          },
        })}
      />
    </Box>
  );
}

function buildDetailRow(
  collection: PkBloodWorksheetCollectionFragment,
  experiment: ExperimentDetailsFragment
): DetailRow {
  const treatmentGroup = experiment.treatmentGroups.find((tg) => tg.id === collection.treatmentGroupId);
  const treatment = experiment.treatments
    .find((treatment) => treatment.id === treatmentGroup?.treatmentId)
    ?.testArticles.map((testArticle) => testArticle.name)
    .join(', ');
  return {
    id: collection.id,
    treatment: treatment ?? '',
    treatmentGroupIndex: treatmentGroup?.treatmentGroupIndex.toString() ?? '',
    animalNumber: collection.animalNumber,
    expectedTime: collection.expectedTime,
    actualTime: collection.actualTime,
  };
}
