import { ChangeEvent, useCallback } from 'react';
import styled from '@emotion/styled';
import { TextField, IconButton } from '@mui/material';
import { Search as SearchIcon } from '@mui/icons-material';
import { debounce } from 'lodash';

const StyledSearchContainer = styled.div({
  display: 'flex',
  justifyContent: 'center',
  marginTop: '10px',
});

const StyledTextField = styled(TextField)({
  div: {
    borderRadius: '4px',
    fontFamily: 'Roboto',
    width: '14em',
    height: '28px',
    padding: '0',
  },
});

interface SearchProps {
  query: string;
  onChange: (value: string) => void;
}

export const Search = ({ query, onChange }: SearchProps) => {
  const changeHandler = useCallback(
    debounce((ev: ChangeEvent<HTMLInputElement>) => {
      onChange(ev.target.value);
    }, 500),
    [onChange]
  );

  return (
    <StyledSearchContainer>
      <StyledTextField
        placeholder="Search"
        type="search"
        defaultValue={query}
        onChange={changeHandler}
        InputProps={{
          endAdornment: (
            <IconButton name="Search" aria-label="Search">
              <SearchIcon />
            </IconButton>
          ),
        }}
      />
    </StyledSearchContainer>
  );
};
