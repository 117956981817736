import React, { ChangeEvent } from 'react';
import styled from '@emotion/styled';
import {
  Table,
  Checkbox,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  CircularProgress,
  Box,
} from '@mui/material';
import { AppColors, i18n } from '#lib/constants';
import { DotmaticLink, Loader } from '#components/widgets';
import { Waypoint } from 'react-waypoint';
import { HeadersColumns, TableData } from '#lib/types';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { NetworkStatus } from '@apollo/client';

const StyledTableCell = styled(TableCell)(({ bold }: { bold?: number }) => ({
  fontSize: '10px',
  padding: '5px',
  fontWeight: bold ? 'bold' : 'normal',
  color: AppColors.DARK_GREY,
}));

const StyledCheckboxCell = styled(TableCell)({
  width: 0,
  padding: 0,
});

const StyledTableContainer = styled.div({
  height: '40vh',
  overflow: 'auto',
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',

  '&::-webkit-scrollbar': {
    width: '0.9px',
    borderRadius: '8px',
  },

  '&::-webkit-scrollbar-track': {
    boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)',
  },

  '&::-webkit-scrollbar-thumb': {
    backgroundColor: AppColors.GREY2,
    outline: '1px solid slategrey',
  },
});

const StyledTable = styled(Table)({
  marginTop: '20px',
  marginBottom: '20px',
  height: '50%',
});

const StyledTableRow = styled(TableRow)({
  height: '26px',
  '&:nth-of-type(odd)': {
    backgroundColor: AppColors.VERY_LIGHT_GREY,
  },
});

const StyledRadioGroup = styled(RadioGroup)({
  paddingLeft: '20px',
});

const StyledCircularProgress = styled(CircularProgress)({
  width: '40px',
  margin: '20px auto',
});

interface TableViewProps {
  onChangeCheckbox?: (name: string) => boolean;
  loadItems: () => void;
  networkStatus: number;
  columns: HeadersColumns[];
  tableData?: TableData[];
  dataCount?: number;
  selectedItemField: string;
  onChangeSelectedItem?: (
    id: string,
    isNew: boolean,
    testArticleId?: number,
    tab?: string,
    bioregId?: string,
    name?: string
  ) => void;
  tabName: string;
  includeNone?: boolean;
  isRadio?: boolean;
  onChangeRadio?: (id: number | null) => void;
  radioValue?: string;
  loading: boolean;
  radioName?: string;
}
export const TableView = ({
  onChangeCheckbox,
  loadItems,
  networkStatus,
  columns,
  tableData,
  dataCount,
  onChangeSelectedItem,
  selectedItemField,
  tabName,
  includeNone,
  isRadio,
  onChangeRadio = () => {},
  radioValue,
  loading,
  radioName,
}: TableViewProps) => {
  const name = radioName ?? 'controlled-radio-buttons-group';

  const content =
    loading && networkStatus !== NetworkStatus.fetchMore ? (
      <Loader />
    ) : dataCount === 0 && !includeNone ? (
      <Box padding={3}>{i18n.Common.EmptySearch}</Box>
    ) : (
      <>
        <StyledTable data-test-id={tabName} stickyHeader>
          <TableHead>
            <TableRow>
              <StyledTableCell />
              {columns.map((column, index) => (
                <StyledTableCell key={index} bold={+true}>
                  {column.columnName}
                </StyledTableCell>
              ))}
              <StyledTableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {isRadio && includeNone && (
              <StyledTableRow>
                <StyledCheckboxCell>
                  <StyledRadioGroup
                    name={name}
                    value={radioValue}
                    onChange={() => onChangeRadio(null)}
                  >
                    <FormControlLabel value={''} control={<Radio />} label="" />
                  </StyledRadioGroup>
                </StyledCheckboxCell>
                <StyledTableCell>None</StyledTableCell>
                <StyledTableCell></StyledTableCell>
                <StyledTableCell></StyledTableCell>
              </StyledTableRow>
            )}
            {!!tableData &&
              tableData.map((data, index) => (
                <React.Fragment key={index}>
                  <StyledTableRow>
                    <StyledCheckboxCell>
                      {isRadio ? (
                        <StyledRadioGroup
                          name={name}
                          value={radioValue}
                          onChange={() => onChangeRadio(data.id)}
                        >
                          <FormControlLabel
                            value={data[selectedItemField]}
                            control={<Radio />}
                            label=""
                          />
                        </StyledRadioGroup>
                      ) : (
                        <Checkbox
                          data-test-id={`checkbox`}
                          checked={onChangeCheckbox!(`${tabName}-${data.id}`)}
                          onChange={(ev: ChangeEvent<HTMLInputElement>) =>
                            onChangeSelectedItem!(
                              `${tabName}-${data.id}`,
                              ev.target.checked,
                              data.id,
                              data.bioregId as string,
                              tabName,
                              `${data[selectedItemField]}`
                            )
                          }
                        />
                      )}
                    </StyledCheckboxCell>
                    {columns.map(({ columnName, field }) => (
                      <StyledTableCell
                        key={columnName}
                        data-test-id={`col-${columnName}`}
                      >
                        {columnName === 'Name' && data.dotmaticId ? (
                          <DotmaticLink id={data.dotmaticId.toString()}>
                            {data[field]}
                          </DotmaticLink>
                        ) : (
                          data[field]
                        )}
                      </StyledTableCell>
                    ))}
                    <StyledTableCell>
                      {!!tableData?.length &&
                        index === tableData.length - 10 &&
                        dataCount &&
                        tableData.length < dataCount && (
                          <Waypoint key={index} onEnter={loadItems} />
                        )}
                    </StyledTableCell>
                  </StyledTableRow>
                </React.Fragment>
              ))}
          </TableBody>
        </StyledTable>
        {tableData && networkStatus === NetworkStatus.fetchMore && (
          <StyledCircularProgress />
        )}
      </>
    );

  return <StyledTableContainer>{content}</StyledTableContainer>;
};
