import { GridRow, type GridRowProps } from '@mui/x-data-grid-premium';
import { useEffect, useMemo, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import { useDetailPanelContext } from './detailPanelContext';
import { RowContext } from './rowContext';

export function DetailPanelRow(props: GridRowProps) {
  const { rowId } = props;

  const {
    error: panelError,
    loading: panelLoading,
    updates,
    mutationStatuses,
    updateActualTime,
    updateHandlingAndStorageCollected,
    updateHandlingAndStorageWeight,
  } = useDetailPanelContext();

  const nodeRef = useRef(null);

  const [updated, setUpdated] = useState(false);

  useEffect(() => {
    setUpdated(updates.includes(Number(rowId)));
    setTimeout(() => {
      setUpdated(false);
    }, 3000);
  }, [updates]);

  const { updateActualTimeStatus, updateHandlingAndStorageStatus } = mutationStatuses[rowId] ?? {};

  const error = panelError ?? updateActualTimeStatus?.error ?? updateHandlingAndStorageStatus?.error;
  const loading = panelLoading || updateActualTimeStatus?.loading || updateHandlingAndStorageStatus?.loading;

  const rowContext = useMemo(
    () => ({
      error,
      loading,
      updateActualTime,
      updateHandlingAndStorageCollected,
      updateHandlingAndStorageWeight,
    }),
    [error, loading]
  );

  return (
    <RowContext.Provider value={rowContext}>
      <CSSTransition nodeRef={nodeRef} in={updated} timeout={500} classNames="updated">
        <GridRow {...props} ref={nodeRef} />
      </CSSTransition>
    </RowContext.Provider>
  );
}
