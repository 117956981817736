import { useCallback } from 'react';

import { ExperimentByIdDocument, useUpdateBloodBioanalysisMutation, type BloodBioanalysisFragment } from '#graphql';
import { type TreatmentGroupWithTreatment } from '#lib/types';
import { BloodBioanalysisForm } from './BloodBioanalysisForm';
import { type BloodBioanalysisFormData, formDataToUpdateInput } from './bloodBioanalysis';

interface EditBloodBioanalysisProps {
  bloodBioanalysis: BloodBioanalysisFragment;
  onCancel?: () => void;
  onSuccess?: () => void;
  treatmentGroups: TreatmentGroupWithTreatment[];
}

export function EditBloodBioanalysis(props: EditBloodBioanalysisProps) {
  const { bloodBioanalysis, onCancel = () => undefined, onSuccess = () => undefined, treatmentGroups } = props;

  const [updateBloodBioanalysis] = useUpdateBloodBioanalysisMutation({
    awaitRefetchQueries: true,
    refetchQueries: [ExperimentByIdDocument, 'experimentById'],
  });

  const handleSubmit = useCallback(
    async function handleSubmit(data: BloodBioanalysisFormData) {
      await updateBloodBioanalysis({
        variables: {
          input: formDataToUpdateInput(bloodBioanalysis.id, data),
        },
      });
    },
    [updateBloodBioanalysis]
  );

  return (
    <BloodBioanalysisForm
      bloodBioanalysis={bloodBioanalysis}
      handleSubmit={handleSubmit}
      onCancel={onCancel}
      onSuccess={onSuccess}
      treatmentGroups={treatmentGroups}
    />
  );
}
