import { FormControl, FormHelperText, type SelectChangeEvent } from '@mui/material';
import { capitalize, map, sortBy } from 'lodash';
import { useMemo, useState } from 'react';

import { Can } from '#components/contexts';
import { UserDropdown } from '#components/partials/ConfigureOverview/partials';
import { useAllProgramsQuery, type ExperimentDetailsFragment, type UserBasicInfoFragment } from '#graphql';
import { useListContent, useUpdateExperiment } from '#lib/hooks';
import {
  StyledButton,
  StyledColumn,
  StyledContainer,
  StyledLabel,
  StyledMenuItem,
  StyledRow,
  StyledSelect,
  StyledTitle,
} from './styles';

export const ExperimentDetails = ({ experiment }: { experiment: ExperimentDetailsFragment }) => {
  const { updateExperiment } = useUpdateExperiment();
  const { experimentTypeList, IACUCList, loading: listContentLoading } = useListContent();
  const { data: programsData, loading: programsLoading } = useAllProgramsQuery();

  const programs = useMemo(() => {
    return map(programsData?.programs.items, 'name');
  }, [programsData]);

  const protocols = useMemo(() => {
    return sortBy(map(IACUCList, 'name'), (protocol) => Number(protocol.split('-')[0].trim()));
  }, [IACUCList]);

  const types = useMemo(() => {
    return sortBy(map(experimentTypeList, 'name'), (type) => capitalize(type));
  }, [experimentTypeList]);

  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  if (listContentLoading || programsLoading) {
    return null;
  }

  return (
    <StyledContainer data-test-id="experimentDetails">
      <StyledTitle>Experiment Details</StyledTitle>
      <StyledRow>
        <StyledColumn data-test-id="experimentResearcher">
          <StyledLabel>Research Associate</StyledLabel>
          <Can I="update" this={experiment} passThrough field="researcherId">
            {(allowed: boolean) => (
              <UserDropdown
                fieldName="researcher"
                role="researcher"
                disabled={!allowed}
                experimentId={experiment.id}
                // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                selectedUser={experiment.researcher ?? ({} as UserBasicInfoFragment)}
              />
            )}
          </Can>
        </StyledColumn>

        <StyledColumn data-test-id="experimentType">
          <StyledLabel>Experiment Type*</StyledLabel>
          <FormControl>
            <Can I="update" this={experiment} passThrough field="type">
              {(allowed: boolean) => (
                <>
                  <StyledSelect
                    value={experiment.type}
                    disabled={!allowed}
                    data-test-id="experimentTypeInput"
                    onChange={(ev: SelectChangeEvent<string>) =>
                      updateExperiment({
                        type: ev.target.value,
                        id: experiment.id,
                      })
                    }
                  >
                    {types.map((type) => (
                      <StyledMenuItem key={type} value={type}>
                        {type}
                      </StyledMenuItem>
                    ))}
                  </StyledSelect>
                  {allowed && <FormHelperText>Required</FormHelperText>}
                </>
              )}
            </Can>
          </FormControl>
        </StyledColumn>

        <StyledColumn data-test-id="experimentCRO">
          <StyledLabel>CRO</StyledLabel>
          <Can I="update" this={experiment} passThrough field="CRO">
            {(allowed: boolean) => (
              <StyledSelect
                value={String(Number(experiment.CRO))}
                disabled={!allowed}
                data-test-id="croInput"
                onChange={(ev: SelectChangeEvent<string>) =>
                  updateExperiment({
                    CRO: ev.target.value === '1',
                    id: experiment.id,
                  })
                }
              >
                <StyledMenuItem value="1">Yes</StyledMenuItem>
                <StyledMenuItem value="0">No</StyledMenuItem>
              </StyledSelect>
            )}
          </Can>
        </StyledColumn>
      </StyledRow>
      <StyledRow>
        <StyledColumn data-test-id="experimentProject">
          <StyledLabel>Program*</StyledLabel>
          <FormControl>
            <Can I="update" this={experiment} passThrough field="project">
              {(allowed: boolean) => (
                <>
                  <StyledSelect
                    open={open}
                    onClose={handleClose}
                    onOpen={handleOpen}
                    disabled={!allowed}
                    data-test-id="experimentProjectInput"
                    value={experiment.project}
                    onChange={(ev: SelectChangeEvent<string>) => {
                      updateExperiment({
                        project: ev.target.value,
                        id: experiment.id,
                      });
                    }}
                  >
                    <StyledButton onClick={handleClose}>Close Menu</StyledButton>
                    {/* Include programs that may no longer be active */}
                    {!programs.includes(experiment.project) && (
                      <StyledMenuItem value={experiment.project}>{experiment.project}</StyledMenuItem>
                    )}
                    {programs.map((program) => (
                      <StyledMenuItem key={program} value={program}>
                        {program}
                      </StyledMenuItem>
                    ))}
                  </StyledSelect>
                  {allowed && <FormHelperText>Required</FormHelperText>}
                </>
              )}
            </Can>
          </FormControl>
        </StyledColumn>

        <StyledColumn data-test-id="experimentIACUCProtocol">
          <StyledLabel>IACUC protocol*</StyledLabel>
          <FormControl>
            <Can I="update" this={experiment} passThrough field="IACUC">
              {(allowed: boolean) => (
                <>
                  <StyledSelect
                    value={experiment.IACUC}
                    disabled={!allowed}
                    data-test-id="experimentIACUCInput"
                    onChange={(ev: SelectChangeEvent<string>) =>
                      updateExperiment({
                        IACUC: ev.target.value,
                        id: experiment.id,
                      })
                    }
                  >
                    {protocols.map((protocol) => (
                      <StyledMenuItem key={protocol} value={protocol}>
                        {protocol}
                      </StyledMenuItem>
                    ))}
                  </StyledSelect>
                  {allowed && <FormHelperText>Required</FormHelperText>}
                </>
              )}
            </Can>
          </FormControl>
        </StyledColumn>

        <StyledColumn data-test-id="experimentMTA">
          <StyledLabel>MTA</StyledLabel>
          <Can I="update" this={experiment} passThrough field="MTA">
            {(allowed: boolean) => (
              <StyledSelect
                value={String(Number(experiment.MTA))}
                disabled={!allowed}
                data-test-id="experimentMTAInput"
                onChange={(ev: SelectChangeEvent<string>) =>
                  updateExperiment({
                    MTA: ev.target.value === '1',
                    id: experiment.id,
                  })
                }
              >
                <StyledMenuItem value="1">Yes</StyledMenuItem>
                <StyledMenuItem value="0">No</StyledMenuItem>
              </StyledSelect>
            )}
          </Can>
        </StyledColumn>
      </StyledRow>
    </StyledContainer>
  );
};
