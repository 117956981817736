import { Box, Container } from '@mui/material';
import { useSearchParams } from 'react-router-dom';

import { useAdcOrdersQuery, type OrderStatus } from '#graphql';
import { i18n } from '#lib/constants';
import { HandleNetworkLifecycle } from '../../widgets/HandleNetworkLifecycle';
import { AdcOrder } from './AdcOrder';

const DEFAULT_PAGE_SIZE = 100;

export const dataTestId = 'adcOrders';

interface AdcOrdersProps {
  status: OrderStatus;
}

export const AdcOrders = ({ status }: AdcOrdersProps) => {
  const [searchParams] = useSearchParams();
  const query = searchParams.get('q') ?? '';

  const { data, loading, error, refetch } = useAdcOrdersQuery({
    variables: {
      limit: DEFAULT_PAGE_SIZE,
      status,
      query,
    },
  });

  let emptyMessage =
    status === 'inProgress' ? i18n.Orders.EmptyUnfulfilledAdcOrders : i18n.Orders.EmptyFulfilledAdcOrders;

  if (query !== '') {
    emptyMessage += ` that match the provided search criteria of "${query}"`;
  }
  return (
    <Container data-test-id={dataTestId} maxWidth="lg" sx={{ paddingY: '2rem' }}>
      <HandleNetworkLifecycle
        emptyMessage={emptyMessage}
        error={error}
        loading={loading}
        data={data?.adcOrders?.items}
        render={({ data: orders }) => (
          <Box display="flex" flexDirection="column" gap={4}>
            {orders
              .filter((order) => order.adcOrderItems.length > 0)
              .map((order) => {
                return <AdcOrder key={order.id} adcOrder={order} refetchAdcOrders={refetch} />;
              })}
          </Box>
        )}
      />
    </Container>
  );
};
