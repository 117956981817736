"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.treatmentCalculation = void 0;
const worksheets_core_1 = require("@omnivivo/worksheets-core");
const ExperimentInjectedSections_1 = require("./ExperimentInjectedSections");
const style_1 = require("@omnivivo/style");
exports.treatmentCalculation = (0, worksheets_core_1.getNormalizeWorksheetDefinition)({
    worksheetName: 'Treatment calculation',
    color: style_1.AppColors.SPECTRA_YELLOW,
    sections: [
        ...ExperimentInjectedSections_1.experimentInjectedSectionDefinitions,
        {
            id: 'heading',
            sectionType: 'form',
            fields: [
                {
                    id: 'instructions',
                    fieldType: 'readOnly',
                    fieldName: 'Instructions',
                    gridWidth: 6,
                    default: 'In this worksheet, treatments are prepared before dosing the animals. The “delivered treatments” section is a record of the test articles that have been ordered/fulfilled for the experiment. Below, the “Concentration workspace” and “ADC verification workspace” sections allow researchers to prepare the various treatments before dosing the animals.',
                },
                {
                    id: 'notes',
                    fieldType: 'longText',
                    fieldName: 'notes',
                    gridWidth: 6,
                },
            ],
        },
        {
            id: 'concentrationWorkspace',
            sectionType: 'table',
            sectionName: 'Concentration Workspace',
            allowAddRows: false,
            sortBy: 'treatmentGroupIndex',
            fields: [
                {
                    id: 'treatmentGroupName',
                    fieldType: 'readOnly',
                    fieldName: 'Group',
                    gridWidth: 2,
                },
                {
                    id: 'treatmentGroupIndex',
                    fieldType: 'readOnly',
                    gridWidth: 1,
                    hidden: true,
                },
                {
                    id: 'testArticleName',
                    fieldName: 'Test article',
                    fieldType: 'readOnly',
                    gridWidth: 2,
                },
                {
                    id: 'totalAnimals',
                    fieldName: 'Num animals',
                    fieldType: 'number',
                    gridWidth: 0.5,
                },
                {
                    id: 'numInjections',
                    fieldName: 'Num injections',
                    fieldType: 'number',
                    gridWidth: 0.5,
                },
                {
                    id: 'animalWeight',
                    fieldName: 'Animals weight (kg)',
                    fieldType: 'number',
                    unit: 'kg',
                    gridWidth: 0.5,
                },
                {
                    id: 'injVolumeMls',
                    fieldName: 'Injection volume (mls)',
                    fieldType: 'number',
                    unit: 'ml',
                    gridWidth: 0.5,
                },
                {
                    id: 'injVolumeKgs',
                    fieldName: 'Injection volume (kg)',
                    fieldType: 'number',
                    unit: 'kg',
                    gridWidth: 0.5,
                },
                {
                    id: 'initialStock',
                    fieldName: 'Initial stock conc. (mg/ml)',
                    fieldType: 'number',
                    gridWidth: 1,
                },
                {
                    id: 'neededDosage',
                    fieldName: 'Needed dosage',
                    fieldType: 'number',
                    numberFormat: style_1.AUTO_SCIENTIFIC_NOTATION_FORMAT,
                    gridWidth: 1,
                },
                {
                    id: 'doseUnit',
                    fieldName: 'Units',
                    fieldType: 'readOnly',
                    gridWidth: 0.5,
                },
                {
                    id: 'finalConcentration',
                    fieldName: 'Final conc. (mg/ml)',
                    fieldType: 'calc',
                    calc: 'if(equal(doseUnit, "mg/kg"), neededDosage * injVolumeKgs / injVolumeMls, "")',
                    gridWidth: 0.75,
                    unit: 'mg/ml',
                    numberFormat: '0.000',
                },
                {
                    id: 'totalVolume',
                    fieldName: 'Total volume (ml)',
                    fieldType: 'calc',
                    calc: 'if(equal(doseUnit, "mg/kg"), totalAnimals * numInjections * animalWeight * injVolumeMls / injVolumeKgs, "")',
                    gridWidth: 0.75,
                    unit: 'ml',
                    numberFormat: '0.000',
                },
                {
                    id: 'totalNeeded',
                    fieldName: 'Total needed (mg)',
                    fieldType: 'calc',
                    calc: 'if(equal(doseUnit, "mg/kg"), finalConcentration * totalVolume, "")',
                    gridWidth: 0.75,
                    unit: 'mg',
                    numberFormat: '0.000',
                },
                {
                    id: 'amountStock',
                    fieldName: 'Amount stock (ml)',
                    fieldType: 'calc',
                    calc: 'if(equal(doseUnit, "mg/kg"), totalVolume / (initialStock / finalConcentration), "")',
                    gridWidth: 0.75,
                    unit: 'ml',
                    numberFormat: '0.000',
                },
                {
                    id: 'amountDiluent',
                    fieldName: 'Amount diluent (ml)',
                    fieldType: 'calc',
                    calc: 'if(equal(doseUnit, "mg/kg"), totalVolume - amountStock, "")',
                    gridWidth: 0.5,
                    numberFormat: '0.000',
                },
            ],
        },
        {
            id: 'adcVerificationWorkspace',
            sectionType: 'table',
            sectionName: 'ADC verification workspace',
            sortBy: 'treatmentGroupIndex',
            allowAddRows: false,
            fields: [
                {
                    id: 'treatmentId',
                    fieldName: 'Treatment ID',
                    fieldType: 'string',
                    gridWidth: 2,
                },
                {
                    id: 'batchNumber',
                    fieldName: 'Batch Number',
                    fieldType: 'readOnly',
                    gridWidth: 2,
                },
                {
                    id: 'deliveredConcentration',
                    fieldName: 'mg/ml',
                    fieldType: 'readOnly',
                    gridWidth: 1,
                },
                {
                    id: 'formulation',
                    fieldName: 'Diluent / Formulation',
                    fieldType: 'readOnly',
                    gridWidth: 1,
                },
                {
                    id: 'conjugationAbsorbanceAtA280',
                    fieldName: 'Conjugation A280',
                    fieldType: 'readOnly',
                    gridWidth: 1,
                },
                {
                    id: 'inVivoAbsorbanceAtA280',
                    fieldName: 'InVivo A280',
                    fieldType: 'number',
                    gridWidth: 1,
                },
                {
                    id: 'percentageDifferenceForAbsorbance',
                    fieldName: '% Difference for A280',
                    fieldType: 'calc',
                    calc: '((inVivoAbsorbanceAtA280 - conjugationAbsorbanceAtA280) / conjugationAbsorbanceAtA280) * 100',
                    gridWidth: 1,
                },
            ],
        },
        {
            id: 'proteinVerificationWorkspace',
            sectionType: 'table',
            sectionName: 'Protein verification workspace',
            sortBy: 'treatmentGroupIndex',
            allowAddRows: false,
            fields: [
                {
                    id: 'treatmentId',
                    fieldName: 'Treatment ID',
                    fieldType: 'string',
                    gridWidth: 2,
                },
                {
                    id: 'bioregId',
                    fieldName: 'Bioreg ID',
                    fieldType: 'readOnly',
                    gridWidth: 2,
                },
                {
                    id: 'absorbanceAtA280',
                    fieldName: 'Absorbance at A280',
                    fieldType: 'number',
                    gridWidth: 1,
                },
                {
                    id: 'extinctionCoefficient',
                    fieldName: 'Extinction Coefficient',
                    fieldType: 'number',
                    gridWidth: 1,
                },
                {
                    id: 'mgMl',
                    fieldName: 'mg/ml',
                    fieldType: 'calc',
                    calc: 'absorbanceAtA280 / extinctionCoefficient',
                    unit: 'mg/ml',
                    gridWidth: 1,
                    numberFormat: '0.00',
                },
            ],
        },
    ],
});
