import { Container } from '@mui/material';
import { useCreateCroCellLineMutation } from '#graphql';
import { CroCellLineForm, type CroCellLineFormData } from './CroCellLineForm';

export function CreateCroCellLine() {
  const [createCroCellLine] = useCreateCroCellLineMutation();

  async function handleSubmit(data: CroCellLineFormData) {
    await createCroCellLine({
      variables: {
        input: {
          ...data,
        },
      },
    });
  }

  return (
    <Container maxWidth="sm">
      <CroCellLineForm handleSubmit={handleSubmit} />
    </Container>
  );
}
