import styled from '@emotion/styled';
import { BeatLoader } from 'react-spinners';

import { ExperimentBasicInfoFragment } from '#graphql';
import ExperimentCard from '#components/partials/ExperimentCard';
import { AppColors } from '#lib/constants';

const StyledContainer = styled.div({
  flex: '1 1 23.75%',
});

const StyledHeader = styled.div(({ color }: { color: string }) => ({
  display: 'flex',
  gap: '4px',
  borderBottom: `2px solid ${color}`,
  fontWeight: 'bold',
}));

const StyledTitle = styled.p({
  fontFamily: 'Roboto',
  fontSize: '12px',
  lineHeight: '24px',
  letterSpacing: '0.1px',
  color: AppColors.BLACK,
});

const StyledCardsContainer = styled.div({
  borderLeft: `1px solid ${AppColors.LIGHT_GREY}`,
  padding: '0px 6px',
  display: 'flex',
  flexDirection: 'column',
  gap: '5px',
  paddingBottom: '100px',
});

const StyledLoader = styled.div({
  width: '42px',
  margin: '0px auto',
});

interface ExperimentProps {
  experiments: ExperimentBasicInfoFragment[];
  columnColor: string;
  title: string;
  loading: boolean;
}

export const SwimLane = ({
  experiments,
  columnColor,
  title,
  loading,
}: ExperimentProps) => {
  return (
    <StyledContainer>
      <StyledHeader color={columnColor}>
        <StyledTitle>{title}</StyledTitle>
      </StyledHeader>
      <StyledCardsContainer data-test-id={`swimLane${title}`}>
        {experiments.map((experiment: ExperimentBasicInfoFragment) => (
          <ExperimentCard
            key={experiment.id}
            color={columnColor}
            experiment={experiment}
          />
        ))}
      </StyledCardsContainer>
      {loading && (
        <StyledLoader data-test-id={`swimLaneLoader`}>
          <BeatLoader color={AppColors.BRAND_GREEN} loading={true} size={10} />
        </StyledLoader>
      )}
    </StyledContainer>
  );
};
