"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getWorksheetWithExperimentSections = void 0;
const worksheets_core_1 = require("@omnivivo/worksheets-core");
const ExperimentInjectedSections_1 = require("./ExperimentInjectedSections");
const getWorksheetWithExperimentSections = (numTreatmentGroups, worksheet) => {
    const definedSections = worksheet.definition.sectionsBySectionId ||
        (0, worksheets_core_1.getSectionsBySectionId)(worksheet.definition.sections);
    const sectionsToAdd = [];
    ExperimentInjectedSections_1.experimentInjectedSectionDefinitions.forEach((section) => {
        if (!definedSections[section.id])
            sectionsToAdd.push((0, worksheets_core_1.getNormalizedSectionDefinition)(section));
    });
    const sections = [...worksheet.definition.sections, ...sectionsToAdd];
    return {
        ...worksheet,
        definition: {
            ...worksheet.definition,
            sections,
            sectionsBySectionId: (0, worksheets_core_1.getSectionsBySectionId)(sections),
        },
    };
};
exports.getWorksheetWithExperimentSections = getWorksheetWithExperimentSections;
