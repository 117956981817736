import { omit } from 'lodash';

import { ExperimentByIdDocument, useCreateImagingMutation, type CreateImagingInput } from '#graphql';
import { type TreatmentGroupWithTreatment } from '#lib/types';
import { ImagingForm, type ImagingFormData } from './ImagingForm';

interface CreateImagingProps {
  experimentId: number;
  onCancel?: () => void;
  onSuccess?: () => void;
  treatmentGroups: TreatmentGroupWithTreatment[];
}

export function formDataToCreateInput(experimentId: number, data: ImagingFormData): CreateImagingInput {
  const { treatmentGroups, ...rest } = data;

  return {
    ...rest,
    experimentId,
    treatmentGroups: treatmentGroups.filter((tg) => tg.checked).map((tg) => omit(tg, 'checked')),
    numViews: Number(data.numViews),
  };
}

export function CreateImaging(props: CreateImagingProps) {
  const { experimentId, onCancel = () => undefined, onSuccess = () => undefined, treatmentGroups } = props;

  const [createImaging] = useCreateImagingMutation({
    awaitRefetchQueries: true,
    refetchQueries: [ExperimentByIdDocument, 'experimentById'],
  });

  async function handleSubmit(data: ImagingFormData) {
    await createImaging({
      variables: {
        input: formDataToCreateInput(experimentId, data),
      },
    });
  }

  return (
    <ImagingForm
      handleSubmit={handleSubmit}
      onCancel={onCancel}
      onSuccess={onSuccess}
      treatmentGroups={treatmentGroups}
    />
  );
}
