import { useState } from 'react';
import toast from 'react-hot-toast';
import styled from '@emotion/styled';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
  OrdersDetailsFragment,
  useSyncExperimentOrdersMutation,
} from '#graphql';
import { AppColors } from '#lib/constants';

const StyledRefresh = styled.div({
  color: AppColors.BRAND_GREEN,
  cursor: 'pointer',
  marginLeft: '20px',
  fontSize: '9px',
  fontWeight: 'bold',
  textTransform: 'uppercase',
  letterSpacing: '1px',
});

const StyledRefreshIcon = styled(RefreshIcon)(
  ({ loading }: { loading: string }) => ({
    marginBottom: '-5px',
    marginRight: '2px',
    fontSize: '17px',
    animation: loading === 'true' ? 'rotating 1s linear infinite' : 'none',
  })
);

export const Refresh = ({
  experimentId,
  onUpdate,
}: {
  experimentId: number;
  onUpdate: (orders: OrdersDetailsFragment) => void;
}) => {
  const [loading, setLoading] = useState(false);
  const [refreshOrders] = useSyncExperimentOrdersMutation({
    variables: {
      experimentId,
    },
    onError: (error) => {
      setLoading(false);
      toast.error(error.message);
    },
    onCompleted: (data) => {
      setLoading(false);
      onUpdate(data.syncExperimentOrders);
    },
  });
  return (
    <StyledRefresh
      onClick={() => {
        setLoading(true);
        refreshOrders();
      }}
    >
      <StyledRefreshIcon loading={`${loading}`} />
      refresh
    </StyledRefresh>
  );
};
