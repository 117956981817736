import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { SingleField } from './SingleField';
import { SectionRenderProps } from './WorksheetComponentInterfaces';
import { StyledFieldName } from './SectionStyledComponents';
import { formatId } from '#lib/utils';
import { mapFieldsForPresentation } from './SectionLib';

const FormSectionContainer = styled.div({
  label: 'FormSectionContainer',
  display: 'flex',
  flexWrap: 'wrap',
  marginTop: '20px',
  margin: '0 -10px',
  maxWidth: '1000px',
});

const FieldWrapper = styled.div({
  width: '33%',
  '@media (max-width: 650px)': {
    width: '100% !important',
  },
});

const FieldContainer = styled.div({
  display: 'flex',
  marginTop: '20px',
  flexDirection: 'column',
  padding: '0 10px',
});

export const FormSection = ({
  worksheetDefinition,
  section,
  entries,
  worksheetMutations: { updateValue, addEntry },
  columnId,
  getWorksheetState,
}: SectionRenderProps) => {
  const [entry] = entries;

  // vivify missing entry
  useEffect(() => {
    if (!section.disableEntryVivification && !entry)
      addEntry({ sectionId: section.id, columnId });
  }, []);

  const data = entry?.data ?? {};
  const entryId = entry?.id;
  const sectionId = entry?.sectionId;

  return (
    <FormSectionContainer>
      {mapFieldsForPresentation(section, (field) => {
        const { fieldName, gridWidth } = field;
        const value = data[field.id];
        return (
          <FieldWrapper
            key={field.id}
            style={{
              width: `${(100 * ((gridWidth || 4) / 12) - 0.01).toFixed(2)}%`,
            }}
          >
            <FieldContainer
              data-test-id={formatId(
                `${section.sectionName || ''} ${fieldName || ''}`
              )}
              key={`field-${field.id}`}
            >
              {fieldName != null ? (
                <StyledFieldName
                  key={`field-name-${field.id}`}
                  title={`${field.id}(${field.fieldType})${
                    field.calc ? `: ${field.calc}` : ''
                  }`}
                >
                  {fieldName || <>&nbsp;</>}
                </StyledFieldName>
              ) : undefined}

              <SingleField
                {...{
                  key: field.id,
                  id: `${field.fieldName}`,
                  entryId,
                  field,
                  sectionId,
                  updateValue,
                  value,
                  worksheetDefinition,
                  getWorksheetState,
                }}
              />
            </FieldContainer>
          </FieldWrapper>
        );
      })}
    </FormSectionContainer>
  );
};
