import { Box, Container } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { Header } from '#components/widgets';
import { CRO_CELL_LINE_PATH, TEST_ARTICLES_ROUTE } from '#lib/constants';

const navLinks = [
  {
    name: 'CRO Cell Lines',
    path: `${TEST_ARTICLES_ROUTE}${CRO_CELL_LINE_PATH}`,
  },
];

export function TestArticlesPage() {
  return (
    <>
      <Header title="Test Articles" navLinks={navLinks} />
      <Container maxWidth="xl" component="main">
        <Box paddingX={1} paddingY={3}>
          <Outlet />
        </Box>
      </Container>
    </>
  );
}
