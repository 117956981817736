import styled from '@emotion/styled';

import { AppColors } from '#lib/constants';
import { ButtonPositive } from '#components/widgets';

export const StyledContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '0.4em',
});

export const StyledTeamContainer = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  width: '100%',
  gap: '1em',
  flexGrow: '0',
});

export const StyledItem = styled.div({
  flex: '12%',
  flexGrow: 0,
});

export const StyledButtonContainer = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
});

export const StyledButton = styled(ButtonPositive)({
  height: '29px',
  borderRadius: '38px',
  boxSizing: 'border-box',
  color: AppColors.BRAND_GREEN,
  textTransform: 'uppercase',
  fontSize: '10px',
  lineHeight: '16px',
  fontWeight: 'bold',
  display: 'flex',
  justifyContent: 'space-between',
});
