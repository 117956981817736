import React from 'react';
import { Navigate } from 'react-router';
import { User, useMeQuery } from '#graphql';
import { Loader } from '#components/widgets';

export const AuthStateContext = React.createContext<User | undefined>(
  undefined
);

export function useAuthState() {
  return React.useContext(AuthStateContext);
}

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const { data, loading } = useMeQuery();

  if (loading) return <Loader message="Authenticating..." />;
  if (!data) return <Navigate to="/login" />;

  return (
    <AuthStateContext.Provider value={data.me}>
      {children}
    </AuthStateContext.Provider>
  );
};
