"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.dataCollectionAnimalWeight = exports.createWeightChangeDataCollectionWorksheetDefinition = void 0;
const style_1 = require("@omnivivo/style");
const worksheets_core_1 = require("@omnivivo/worksheets-core");
const DataCollectionSectionGenerators_1 = require("./DataCollectionSectionGenerators");
const WorksheetInitializationTypes_1 = require("./WorksheetInitializationTypes");
const createWeightChangeDataCollectionWorksheetDefinition = (treatmentGroupsForWorksheet) => (0, worksheets_core_1.getNormalizeWorksheetDefinition)({
    worksheetName: 'Animal Weight Worksheet',
    color: style_1.AppColors.SPECTRA_GREEN,
    sections: (0, DataCollectionSectionGenerators_1.createAllDataCollectionSections)(treatmentGroupsForWorksheet, 'mass', 'Mass', 'mass', '0.00', [
        {
            id: 'mass',
            fieldName: 'Mass (g)',
            fieldType: 'number',
            gridWidth: 0.75,
        },
        {
            id: DataCollectionSectionGenerators_1.WEIGHT_CHANGE_PERCENT_ID,
            fieldName: 'Weight change %',
            fieldType: 'calc',
            numberFormat: '0.00',
            unit: '%',
            calc: 'or(100 * mass / valueFromFirstEntry("mass") - 100, 0)',
            gridWidth: 0.5,
        },
        {
            id: 'observations',
            fieldName: 'Preclinical Observations',
            fieldType: 'multiSelect',
            gridWidth: 3,
            fieldValuesCalc: 'select("preclinicalObservationList", "name")',
            fieldValueLabelsCalc: 'select("preclinicalObservationList", "name")',
        },
        {
            id: DataCollectionSectionGenerators_1.DOSE_REQUIRED,
            fieldName: 'Dose Required',
            fieldType: 'boolean',
            gridWidth: 0.5,
        },
        {
            id: DataCollectionSectionGenerators_1.DOSE_AMOUNT,
            fieldName: 'Dose Amount (μL)',
            fieldType: 'calc',
            numberFormat: '0.00',
            unit: 'ml',
            calc: 'if(equal(doseRequired, true), round(10 * mass, 10), "-")',
            gridWidth: 0.5,
        },
        {
            id: DataCollectionSectionGenerators_1.DOSE_TIME,
            fieldName: 'Dose Time',
            fieldType: 'time',
            gridWidth: 1.75,
        },
    ]),
});
exports.createWeightChangeDataCollectionWorksheetDefinition = createWeightChangeDataCollectionWorksheetDefinition;
// This is only used for Demo worksheets; maybe it belongs elsewhere.
exports.dataCollectionAnimalWeight = (0, exports.createWeightChangeDataCollectionWorksheetDefinition)(WorksheetInitializationTypes_1.demoExperimentForWorksheet.treatmentGroupsForWorksheet);
