import { LoadingButton as MuiButton, type LoadingButtonProps } from '@mui/lab';

const styleOverrides = {
  borderRadius: '1.25rem',
  textTransform: 'none',
} as const;

export default function LoadingButton(props: LoadingButtonProps) {
  return <MuiButton {...props} sx={styleOverrides} />;
}
