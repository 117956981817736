import { type AdcOrderItem } from '#graphql';
import { i18n } from '#lib/constants';
import { formatFloatString, ensureFloat } from '#lib/adcOrder';
import { ReactHookTextField, type RenderCellParams } from '#components/widgets';

interface FloatFieldProps extends RenderCellParams<AdcOrderItem> {
  maxValue?: number;
  minValue?: number;
}

export const FloatField = ({ column, extras, maxValue = 99.99, minValue = 0, row, rowIndex }: FloatFieldProps) => {
  const floatOptions = {
    maxIntegerDigits: Math.floor(maxValue).toString().length,
  };
  const canEditField = extras.canEditField?.(column.field) ?? true;
  const disabled = !canEditField;
  return (
    <ReactHookTextField
      textField={{
        disabled,
        id: `${row.bioregId}-adcOrderItems.${rowIndex}.${column.field}`,
        inputProps: {
          'aria-labelledby': column.field,
          inputMode: 'numeric',
        },
      }}
      controller={{
        name: `adcOrderItems.${rowIndex}.${column.field}`,
        rules: {
          min: {
            value: minValue,
            message: `${i18n.AdcOrderDataValidations.minimumValue} ${minValue}`,
          },
          max: {
            value: maxValue,
            message: `${i18n.AdcOrderDataValidations.maximumValue} ${maxValue}`,
          },
        },
      }}
      formatValue={formatFloatString}
      setValueAs={(value) => ensureFloat(value, floatOptions)}
    />
  );
};
