import { compact, isNil } from 'lodash';

interface MessageObj {
  row?: string | number;
  column?: string | number;
  message: string;
}

type Message = string | MessageObj;

export class UploadError extends Error {
  readonly messages: Message[];
  constructor(messages: Message[], options?: ErrorOptions) {
    super('There was an error uploading the file', options);
    this.messages = messages;
  }

  formattedMessages() {
    return this.messages.map((message) => {
      if (typeof message === 'string') {
        return `message: "${message}"`;
      }

      return compact([
        !isNil(message.row) ? `row: "${message.row}"` : undefined,
        !isNil(message.column) ? `column: "${message.column}"` : undefined,
        `message: "${message.message}"`,
      ]).join(', ');
    });
  }
}
