import styled from '@emotion/styled';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';

import { ButtonNeutral } from '#components/widgets';
import { optionsMenu } from '#lib/utils/settings';
import SettingsMenuCard from '#components/partials/SettingsMenuCard';
import { HOME_PATH } from '#lib/constants';

const StyledContainer = styled.div({
  display: 'flex',
  height: '100%',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});

const StyledRow = styled.div({
  display: 'flex',
  padding: '20px',
  width: '75%',
  justifyContent: 'space-between',
});

const StyledBox = styled.div({
  display: 'flex',
  alignItems: 'center',
});

const StyledText = styled.h3({
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '18px',
  lineHeight: '24px',
});

const StyledButton = styled(ButtonNeutral)({
  fontSize: '10px',
});

const StyledArrowIcon = styled(ArrowBackIcon)({
  fontSize: 'medium',
  margin: '0 5px 0 5px',
});

const StyledCardsContainer = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'flex-start',
  gap: '100px',
  alignItems: 'stretch',
});

export const SettingsPage = () => {
  const navigate = useNavigate();

  return (
    <StyledContainer data-test-id={`settingsPage`}>
      <StyledRow>
        <StyledBox>
          <StyledText>Settings</StyledText>
        </StyledBox>
        <StyledBox>
          <StyledButton onClick={() => navigate(HOME_PATH)}>
            <StyledArrowIcon />
            Home
          </StyledButton>
        </StyledBox>
      </StyledRow>

      <StyledCardsContainer data-test-id={`settingsPageMenu`}>
        {optionsMenu.map(({ id, title, image, description, path }) => (
          <SettingsMenuCard
            key={id}
            title={title}
            image={image}
            description={description}
            path={path}
          />
        ))}
      </StyledCardsContainer>
    </StyledContainer>
  );
};
