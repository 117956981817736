import { SCIENTIFIC_NOTATION_FORMAT } from '@omnivivo/style';
const numeral = require('numeral');

export const antibodiesTableColumns = [
  {
    columnName: 'Name',
    field: 'name',
  },
  {
    columnName: 'BioReg Id',
    field: 'bioregId',
  },
  {
    columnName: 'SgName',
    field: 'sgName',
  },
  {
    columnName: 'Iso Type',
    field: 'isoType',
  },
  {
    columnName: 'Humanized Variant',
    field: 'humanizedVariant',
  },
  {
    columnName: 'Target',
    field: 'target',
  },
  {
    columnName: 'Status',
    field: 'status',
  },
];

export const conjugatesTableColumns = [
  {
    columnName: 'Name',
    field: 'sgName',
  },
  {
    columnName: 'BioReg ID',
    field: 'bioregId',
  },
  {
    columnName: 'Parent Antibody',
    field: 'antibodyName',
  },
  {
    columnName: 'Parent AB BioReg ID',
    field: 'parentabbioregId',
  },
  {
    columnName: 'Drug Linker',
    field: 'drugLinker',
  },
  {
    columnName: 'Dars',
    field: 'dars',
  },
];

export const smallMoleculesTableColumns = [
  {
    columnName: 'Name',
    field: 'name',
  },
  {
    columnName: 'Type',
    field: 'type',
  },
  {
    columnName: 'BioReg ID',
    field: 'bioregId',
  },
  {
    columnName: 'Drug Class',
    field: 'drugClass',
  },
  {
    columnName: 'Stereo',
    field: 'stereo',
  },
];

export const otherMoleculesTableColumns = [
  {
    columnName: 'Name',
    field: 'name',
  },
  {
    columnName: 'Category',
    field: 'category',
  },
  {
    columnName: 'BioReg Id',
    field: 'bioregId',
  },
  {
    columnName: 'Common Name',
    field: 'commonName',
  },
  {
    columnName: 'Other Name',
    field: 'otherNames',
  },
];
export const cellLineTableColumns = [
  {
    columnName: 'Name',
    field: 'name',
  },
  {
    columnName: 'Location',
    field: 'location',
  },
  {
    columnName: 'Disease',
    field: 'disease',
  },
];

export function maybeConvertToScientificNotation(
  moleculeType: string = '',
  value?: number
) {
  if (value && moleculeType === 'Cell Lines')
    return numeral(value).format(SCIENTIFIC_NOTATION_FORMAT);

  return value;
}
