import styled from '@emotion/styled';

const StyledContainer = styled.div({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
});

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export const TabPage = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <StyledContainer
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </StyledContainer>
  );
};
