import AddIcon from '@mui/icons-material/Add';
import { Box, Stack } from '@mui/material';
import { times } from 'lodash';
import { useFieldArray } from 'react-hook-form';

import { type ListContentDetailsFragment } from '#graphql';
import { type TreatmentGroupWithTreatment } from '#lib/types';
import { Button } from '#rds';
import { initialCollectionSchedule, type CollectionSchedulesFormData } from './bioanalysis';
import { CollectionScheduleFormSection } from './CollectionScheduleFormSection';

interface CollectionSchedulesFormSectionProps {
  bioanalysisTimepoints: ListContentDetailsFragment[];
  treatmentGroups: TreatmentGroupWithTreatment[];
}

export function CollectionSchedulesFormSection(props: CollectionSchedulesFormSectionProps) {
  const { bioanalysisTimepoints, treatmentGroups } = props;

  const {
    fields: collectionSchedules,
    append,
    remove,
  } = useFieldArray<CollectionSchedulesFormData, 'collectionSchedules'>({
    name: 'collectionSchedules',
  });

  const addSet = () => {
    append(initialCollectionSchedule);
  };

  const doseValues = computeDoseValues(treatmentGroups);

  return (
    <Stack spacing={3}>
      {collectionSchedules.map((collectionSchedule, index) => {
        return (
          <CollectionScheduleFormSection
            key={collectionSchedule.id}
            bioanalysisTimepoints={bioanalysisTimepoints}
            collectionScheduleIndex={index}
            doseValues={doseValues}
            isDefault={collectionSchedule.isDefault}
            onDelete={() => {
              remove(index);
            }}
            treatmentGroups={treatmentGroups}
          />
        );
      })}

      <Box>
        <Button variant="outlined" size="small" onClick={addSet} startIcon={<AddIcon />}>
          Add a New Collection Schedule
        </Button>
      </Box>
    </Stack>
  );
}

export function computeDoseValues(treatmentGroups: TreatmentGroupWithTreatment[]) {
  const doseNumbers = treatmentGroups.flatMap((treatmentGroup) =>
    treatmentGroup.treatment.testArticles.map((testArticle) => testArticle.doseNumber ?? 0)
  );

  const maxDoseNumber = Math.max(...doseNumbers);

  return times(maxDoseNumber, (num) => ({
    value: (num + 1).toString(),
    label: `Dose ${num + 1}`,
  }));
}
