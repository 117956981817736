import styled from '@emotion/styled';
import { MenuItem, Select, Button } from '@mui/material';

import { AppColors } from '#lib/constants';

export const StyledContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '1em',
});

export const StyledRow = styled.div({
  display: 'flex',
  gap: '8px',
});

export const StyledColumn = styled.div({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: '1',
  gap: '0.5em',
  marginBottom: '20px',
});

export const StyledTitle = styled.div({
  marginTop: '10px',
  fontStyle: 'normal',
  fontWeight: 'bold',
  fontSize: '14px',
  lineHeight: '24px',
  letterSpacing: '0.15px',
  color: AppColors.BLACK,
});

export const StyledLabel = styled.label({
  fontWeight: '700',
  fontSize: '10px',
  lineHeight: '12px',
  color: AppColors.GREY,
  fontStyle: 'normal',
});

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const StyledSelect = styled(Select)({
  height: '28px',
  fontSize: '12px',
  lineHeight: '14px',
  color: 'rgba(0,0,0,0.6)',
}) as unknown as typeof Select;

export const StyledMenuItem = styled(MenuItem)({
  fontSize: '12px',
  lineHeight: '14px',
  color: AppColors.BLACK,
});

export const StyledButton = styled(Button)({
  fontSize: '14px',
  color: AppColors.BLUE,
  position: 'fixed',
  top: '0',
  opacity: '1',
  height: '30px',
  padding: '30px 0',
  textAlign: 'center',
  width: '20.2vw',
  zIndex: '1',
  background: AppColors.WHITE,
  '&:hover': {
    background: AppColors.WHITE,
  },
});
