import { Stack, Typography } from '@mui/material';
import { isNil } from 'lodash';

interface TitleProps {
  subtitle?: string;
  title: string;
}

export function Title(props: TitleProps) {
  const { subtitle, title } = props;

  return (
    <Stack paddingX={4} paddingTop={2} spacing={1}>
      <Typography variant="h1">{title}</Typography>
      {!isNil(subtitle) ? <Typography variant="h2">{subtitle}</Typography> : null}
    </Stack>
  );
}
