export const fontFamily =
  'roboto, -apple-system, BlinkMacSystemFont, sans-serif';
export const fontSize = '12px';
export const largeFontSize = '14px';
export const fontColor = 'rgba(0, 0, 0, 0.6);';
export const mediumTextStyle = {
  fontSize,
  fontFamily,
  color: fontColor,
};
