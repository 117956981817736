import { mediumTextStyle } from '#lib/constants/typography';
import { DatePicker, type DateValidationError } from '@mui/x-date-pickers';
import { isNil } from 'lodash';
import { DateTime } from 'luxon';
import { useState } from 'react';

import { type CommonProps } from './WorksheetComponentInterfaces';

export function DateField(props: CommonProps) {
  const {
    inputElementProps: { onChange, value },
  } = props;

  const dateValue = isNil(value) || value === '' ? undefined : DateTime.fromJSDate(new Date(value));

  const [error, setError] = useState<DateValidationError | null>(null);

  return (
    <DatePicker
      label=""
      value={dateValue}
      onChange={(value, context) => {
        if (!isNil(context.validationError)) {
          return;
        }
        const valueStr = DateTime.isDateTime(value) ? value.toJSDate() : value;
        onChange?.(valueStr);
      }}
      onError={(newError) => {
        setError(newError);
      }}
      disableFuture={true}
      slotProps={{
        textField: {
          helperText: error,
          size: 'small',
          InputProps: {
            sx: {
              ...mediumTextStyle,
              '.MuiOutlinedInput-input': {
                paddingRight: '5px',
                paddingTop: '3px',
                paddingLeft: '5px',
                paddingBottom: '3px',
              },
            },
          },
        },
      }}
    />
  );
}
