import {
  getSectionDetailsCSV,
  getCSVSimpleFormat,
  blankLine,
  getWorksheetSectionListSingleRow,
  getWorksheetSectionListMultipleRows,
} from './exportCore';
import { WorksheetDefinition } from '@omnivivo/worksheets-core';

export const getCellCalculationWorksheetCsv = (
  definition: WorksheetDefinition,
  cellCalcType: string,
  data: string[][],
  headers: string[],
  keys: string[]
) => {
  let worksheetCsv = '';

  const setAmountsList = getWorksheetSectionListSingleRow(
    definition,
    'setAmount',
    headers,
    data,
    keys,
    [
      'numberOfGroups',
      'totalAnimalsFromGroups',
      'Animal factor',
      'totalAnimals',
      'cellsPerAnimal',
      'implantVolume',
    ]
  );

  const thawCellsList = getWorksheetSectionListSingleRow(
    definition,
    'thawCells',
    headers,
    data,
    keys,
    [
      'date',
      'cellLineLotNumber',
      'cellCultureMedia',
      'numberOfVials',
      'centrifugeSpeed',
      'centrifugeTime',
      'flaskSize',
      'totalMediaVolume',
      'numberOfFlasks',
      'notes',
    ]
  );

  const cultureCellColumns =
    cellCalcType === 'adherent'
      ? [
          'date',
          'day',
          'confluency',
          'trypsinConcentration',
          'trypsin',
          'PBS',
          'viableCells',
          'suspensionVolume',
          'totalCells',
          'cellCheck',
          'ratioSplit',
          'totalFreshMediaVolume',
          'flaskSize',
          'numberOfFlasks',
          'notes',
        ]
      : [
          'date',
          'day',
          'viCellCountStart',
          'cellCheck',
          'initialTotalVolume',
          'initialTotalNumViableCells',
          'finalCellConcentration',
          'finalTotalVolumePerFlask',
          'flaskSize',
          'numberOfFlasks',
          'notes',
        ];

  const cultureCellsList = getWorksheetSectionListMultipleRows(
    definition,
    'cultureCells',
    headers,
    data,
    keys,
    cultureCellColumns
  );

  const harvestCellColumns =
    cellCalcType === 'adherent'
      ? [
          'date',
          'day',
          'confluencyPercentage',
          'totalNumberOfFlasks',
          'flaskSize',
          'trypsin',
          'trypsinConcentration',
          'centrifugeSpeed',
          'centrifugeLength',
          'viCellCount',
          'cellVolume',
          'totalCells',
          'volumeForTransfer',
          'cellViabilityPercentageAtImplant',
          'averageCellsPerConfluentFlasks',
          'notes',
        ]
      : [
          'date',
          'day',
          'cellVolume',
          'viCellCount',
          'totalCells',
          'volumeForTransfer',
          'centrifugeSpeed',
          'centrifugeLength',
          'cellViabilityPercentageAtImplant',
          'totalCultureMediaUsed',
          'notes',
        ];

  const harvestCellsList = getWorksheetSectionListSingleRow(
    definition,
    'harvestCells',
    headers,
    data,
    keys,
    harvestCellColumns
  );

  const implantCellsList = getWorksheetSectionListSingleRow(
    definition,
    'implantCells',
    headers,
    data,
    keys,
    [
      'date',
      'day',
      'cellConcentrationForImplant',
      'totalVolumeNeeded',
      'totalNumCellsNeeded',
      'Overage factor',
      'cellImplantMedia',
      'implantVolumePresentation',
      'notes',
    ]
  );

  worksheetCsv = 'Set amount'.concat(
    '\n',
    getCSVSimpleFormat(setAmountsList.csvHeaders, setAmountsList.csvInfo)
  );
  worksheetCsv = worksheetCsv.concat(
    blankLine,
    'Thaw cells'.concat(
      '\n',
      getCSVSimpleFormat(thawCellsList.csvHeaders, thawCellsList.csvInfo)
    )
  );
  worksheetCsv = worksheetCsv.concat(
    blankLine,
    getSectionDetailsCSV(
      `Culture Cells (${cellCalcType})`,
      cultureCellsList.csvHeaders,
      cultureCellsList.csvInfo
    )
  );
  worksheetCsv = worksheetCsv.concat(
    blankLine,
    `Harvest cells (${cellCalcType})`.concat(
      '\n',
      getCSVSimpleFormat(harvestCellsList.csvHeaders, harvestCellsList.csvInfo)
    )
  );
  worksheetCsv = worksheetCsv.concat(
    blankLine,
    'Implant requirements'.concat(
      '\n',
      getCSVSimpleFormat(implantCellsList.csvHeaders, implantCellsList.csvInfo)
    )
  );

  return worksheetCsv;
};
