import { useEffect, useState } from 'react';
import { Paper, Stack, Typography } from '@mui/material';
import { AdcOrderDetailsFragment, ExperimentDetailsFragment } from '#graphql';
import Adc from '#assets/adc.svg';
import { ATTACHMENT_CATEGORY_ADC, AppColors } from '#lib/constants';
import { Attachments } from '#components/partials';
import {
  getAdcOrderWithExperiment,
  getConjugateAdcOrderItemsForDisplay,
  groupConjugatesAndAdcOrderItemsByBioregId,
} from '#lib/adcOrder';
import { download } from '#lib/adcOrderDownload';
import { ConjugateAdcOrder } from '#components/partials';
import { AdcNotes } from './AdcNotes';
import {
  TitleContainer,
  StyledTableLogo,
  StyledAttachments,
  HeaderTitle,
  TestArticleCardHeader,
  TestArticleCard,
  IconsContainer,
  StyledCheckIcon,
  StyledDownloadIcon,
} from './style';

export const AdcOrder = ({
  adcOrder,
  experiment,
}: {
  adcOrder: AdcOrderDetailsFragment;
  experiment: ExperimentDetailsFragment;
}) => {
  const [isFulfilled, setIsFulfilled] = useState(false);
  const adcOrderWithExperiment = getAdcOrderWithExperiment(
    adcOrder,
    experiment
  );
  useEffect(() => {
    const items = adcOrder.adcOrderItems;
    if (items?.length) {
      const isReady = items.every((i) => !!i.deliveredAt);
      setIsFulfilled(isReady);
    }
  }, [adcOrder]);
  if (!adcOrder.adcOrderItems.length) return null;

  const [conjugatesByBioregId, adcOrderItemGroupsByBioregId] =
    groupConjugatesAndAdcOrderItemsByBioregId(adcOrder.adcOrderItems);

  return (
    <TestArticleCard data-test-id={`adc-order-${adcOrder.id}`}>
      <TestArticleCardHeader>
        <TitleContainer>
          <StyledTableLogo src={Adc} />
          <HeaderTitle data-test-id="adc-order-title">ADC</HeaderTitle>
        </TitleContainer>
        <IconsContainer>
          {isFulfilled && <StyledCheckIcon />}
          <StyledDownloadIcon
            onClick={() => download(adcOrderWithExperiment, experiment.name)}
          />
        </IconsContainer>
      </TestArticleCardHeader>

      <Stack
        spacing={4}
        padding={4}
        sx={{
          '& > *': {
            padding: (theme: any) => theme.spacing(2),
          },
        }}
      >
        {Array.from(conjugatesByBioregId.keys())
          .sort()
          .flatMap((bioregId) => {
            const conjugate = conjugatesByBioregId.get(bioregId)!;
            const adcOrderItemGroup =
              adcOrderItemGroupsByBioregId.get(bioregId)!;

            return getConjugateAdcOrderItemsForDisplay(
              bioregId,
              adcOrderItemGroup,
              false //hideDelivered
            ).map(({ key, adcOrderItems }) => (
              <ConjugateAdcOrder
                key={key}
                bioregId={bioregId}
                conjugate={conjugate}
                adcOrderId={adcOrder.id}
                adcOrderItems={adcOrderItems}
              />
            ));
          })}
        <Paper
          elevation={1}
          sx={{
            backgroundColor: AppColors.VERY_LIGHT_GREY,
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
          }}
        >
          <Typography variant="h3" id="notes">
            Notes
          </Typography>
          <AdcNotes adcOrder={adcOrder} disabled={true} hiddenLabel />
        </Paper>
      </Stack>

      <StyledAttachments>
        <Attachments
          experimentId={adcOrder.experimentId}
          canEdit={false}
          category={ATTACHMENT_CATEGORY_ADC}
          render={() => <></>}
        />
      </StyledAttachments>
    </TestArticleCard>
  );
};
