import FormHelperText from '@mui/material/FormHelperText';
import styled from '@emotion/styled';
import DialogContent from '@mui/material/DialogContent';
import { Divider, TextField } from '@mui/material';
import { MenuItem, Select } from '@mui/material';

import { ButtonPositiveSolid, ButtonNeutralSolid } from '#components/widgets';
import { AppColors } from '#lib/constants';

export const StyledDialogContent = styled(DialogContent)({
  display: 'flex',
  padding: '0',
  justifyContent: 'center',
  alignItems: 'center',
});

export const StyledButton = styled(ButtonPositiveSolid)({
  background: AppColors.BRAND_GREEN,
  borderRadius: '28px',
  color: AppColors.WHITE,
  letterSpacing: '1.25px',
  textTransform: 'uppercase',
  fontSize: '10px',
  fontWeight: 'bold',
  fontStyle: 'normal',
  fontFamily: 'Roboto',
  padding: '10px',
  width: '100px',
  marginBottom: '25px',
  marginLeft: '10px',
  '&:hover': {
    background: AppColors.BRAND_GREEN,
  },
});

export const CancelButton = styled(ButtonNeutralSolid)({
  background: AppColors.WHITE,
  border: `2px solid ${AppColors.BLACK}`,
  borderRadius: '28px',
  color: AppColors.BLACK,
  letterSpacing: '1.25px',
  textTransform: 'uppercase',
  fontSize: '10px',
  fontWeight: '500',
  fontStyle: 'normal',
  fontFamily: 'Roboto',
  padding: '10px',
  width: '100px',
  marginRight: '10px',
  '&:hover': {
    background: AppColors.WHITE,
  },
});

export const ButtonsContainer = styled.div({
  marginTop: '20px',
  display: 'flex',
  justifyContent: 'center',
});

export const StyledDivider = styled(Divider)({
  marginBottom: '10px',
  width: '100%',
});

export const ModelOptionsContainer = styled.div({
  display: 'flex',
  justifyContent: 'space-around',
  width: '100%',
});

export const SelectContainer = styled.div({
  padding: '25px 0px',
  display: 'flex',
  flexDirection: 'column',
});

export const StyledLabel = styled.label({
  fontWeight: '700',
  fontSize: '10px',
  lineHeight: '12px',
  color: AppColors.GREY,
  fontStyle: 'normal',
  marginBottom: '3px',
});

export const StyledSelect = styled(Select)({
  width: '130px',
  height: '28px',
  fontSize: '12px',
  lineHeight: '14px',
  color: 'rgba(0,0,0,0.6)',
});

export const StyledTextField = styled(TextField)({
  width: '130px',
  div: {
    borderRadius: '4px',
    color: 'rgba(0,0,0,0.6)',
    fontSize: '12px',
    lineHeight: '14px',
    height: '28px',
    padding: '0',
  },
});

export const StyledMenuItem = styled(MenuItem)({
  fontSize: '12px',
  lineHeight: '14px',
  color: AppColors.BLACK,
});

export const StyledFormHelperText = styled(FormHelperText)({
  color: 'red',
  marginLeft: '10px',
  fontSize: '10px',
});

export const StyledTableView = styled.div({
  width: '100%',
});

export const TableTitle = styled.h4({
  color: '#000000',
  fontSize: '14px',
  margin: '10px 0px 0px 35px',
  paddingLeft: '0px',
});
