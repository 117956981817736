import { Checkbox } from '@mui/material';
import { type GridRowId } from '@mui/x-data-grid-premium';
import { useEffect, useState, type ChangeEvent } from 'react';

import { useRowContext } from './rowContext';

interface DetailPanelRowCheckboxProps {
  checked: boolean;
  disabled?: boolean;
  field: string;
  handlingAndStorageId: number;
  rowId: GridRowId;
}

export function DetailPanelRowCheckbox(props: DetailPanelRowCheckboxProps) {
  const { checked: defaultChecked, disabled = false, field, handlingAndStorageId, rowId } = props;

  const { updateHandlingAndStorageCollected } = useRowContext();
  const [checked, setChecked] = useState(defaultChecked);
  const [dirty, setDirty] = useState(false);

  function onChange(_event: ChangeEvent<HTMLInputElement>, checked: boolean) {
    setChecked(checked);
    setDirty(true);
  }

  useEffect(() => {
    if (dirty) {
      void updateHandlingAndStorageCollected(rowId, handlingAndStorageId, checked);
    }
  }, [checked]);

  useEffect(() => {
    setChecked(defaultChecked);
    setDirty(false);
  }, [defaultChecked]);

  return <Checkbox color="primary" checked={checked} disabled={disabled} name={field} onChange={onChange} />;
}
