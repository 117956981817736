import { type GridRowId } from '@mui/x-data-grid-premium';
import { isNil } from 'lodash';
import { type DateTime } from 'luxon';

import TimeField from '#rds/TimeField';
import { useRowContext } from './rowContext';

interface DetailPanelRowTimeFieldProps {
  id: GridRowId;
  actualTime: string | undefined;
  disabled?: boolean;
}

export function DetailPanelRowTimeField(props: DetailPanelRowTimeFieldProps) {
  const { id, actualTime, disabled = false } = props;

  const { error, updateActualTime } = useRowContext();

  function onValidChange(value: DateTime | null) {
    void updateActualTime(id, value);
  }

  return (
    <TimeField
      disabled={disabled}
      error={!isNil(error)}
      onValidChange={onValidChange}
      size="small"
      value={actualTime}
    />
  );
}
