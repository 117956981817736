import { ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import { DateAdapter } from './DateAdapter';
import { OmniApolloProvider } from './OmniApolloProvider';
import './index.css';
import { seagenTheme } from './lib/theme';

ReactDOM.render(
  <BrowserRouter>
    <ThemeProvider theme={seagenTheme}>
      <OmniApolloProvider>
        <LocalizationProvider dateAdapter={DateAdapter}>
          <App />
        </LocalizationProvider>
      </OmniApolloProvider>
    </ThemeProvider>
  </BrowserRouter>,
  document.getElementById('root')
);
