import styled from '@emotion/styled';
import {
  WorksheetEntriesBySectionId,
  WorksheetEntry,
  WorksheetSectionDefinition,
} from '@omnivivo/worksheets-core';
import {
  CategoryScale,
  Chart,
  Legend,
  LegendItem,
  LineController,
  LineElement,
  LinearScale,
  PointElement,
  Tooltip,
} from 'chart.js';
import fill from 'lodash/fill';
import { Line } from 'react-chartjs-2';
import { AppColors } from '#lib/constants';
import { SectionRenderProps } from './WorksheetComponentInterfaces';

Chart.register(
  CategoryScale,
  LineController,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend
);

type ChartData = {
  label: string;
  backgroundColor: string;
  borderColor: string;
  data: { x: number; y: number }[];
};

const ChartContainer = styled.div({
  display: 'inline-block',
  width: '80vw',
  verticalAlign: 'middle',
});

const LegendContainer = styled.div({
  display: 'inline-block',
  width: 'calc(20vw - 62px)',
  verticalAlign: 'middle',
});

export const ChartSection = ({
  section,
  entriesBySectionId,
}: SectionRenderProps) => {
  const { chartXLabel, chartYLabel } = section;
  const { datasets, maxX } = getChartData(section, entriesBySectionId);
  const legendId = `${section.id}Legend`;
  return (
    <>
      <ChartContainer data-test-id={section.id}>
        <Line
          data={{
            labels: getLabels(maxX),
            datasets: datasets.map((dataset) => ({
              ...dataset,
              fill: false,
              spanGaps: true,
              parsing: false,
            })),
          }}
          options={{
            responsive: true,
            interaction: { intersect: false },
            plugins: {
              // Need the legend plugin for the htmlLegend. Don't display the legend generated in the canvas.
              legend: { display: false },
            },
            scales: {
              x: {
                title: {
                  display: true,
                  text: chartXLabel,
                },
              },
              y: {
                title: {
                  display: true,
                  text: chartYLabel,
                },
              },
            },
          }}
          plugins={[
            // Generate this plugin "inline" so that the legendId can be dynamic for multiple charts on a page.
            // See https://www.chartjs.org/docs/latest/samples/legend/html.html for htmlLegend info.
            {
              id: 'htmlLegend',
              afterUpdate: (chart: Chart) => {
                const ul = getOrCreateLegendList(legendId);

                // Remove old legend items
                while (ul.firstChild) {
                  ul.firstChild.remove();
                }

                // The `generateLabels` function may not exist. Create a default function that returns and empty array.
                const generateLabels =
                  chart.options.plugins?.legend?.labels?.generateLabels ||
                  ((chart: Chart) => [] as LegendItem[]);
                const items = generateLabels(chart);
                items.forEach((item) => {
                  createLegendItem(chart, item, ul);
                });
              },
            },
          ]}
        />
      </ChartContainer>
      <LegendContainer id={legendId} data-test-id={legendId}></LegendContainer>
    </>
  );
};

function createLegendItem(
  chart: Chart,
  item: LegendItem,
  ul: HTMLUListElement
) {
  const li = document.createElement('li');
  li.style.alignItems = 'center';
  li.style.cursor = 'pointer';
  li.style.display = 'flex';
  li.style.flexDirection = 'row';
  li.style.margin = '0 0 8px 10px';

  // When a list item is clicked on, the associated line in the chart will toggle visibility.
  li.onclick = () => {
    chart.setDatasetVisibility(
      item.datasetIndex as number,
      !chart.isDatasetVisible(item.datasetIndex as number)
    );
    chart.update();
  };

  // Color box
  const boxSpan = document.createElement('span');
  boxSpan.style.background = item.fillStyle as string;
  boxSpan.style.borderColor = item.strokeStyle as string;
  boxSpan.style.borderWidth = item.lineWidth + 'px';
  boxSpan.style.borderRadius = '50%';
  boxSpan.style.display = 'inline-block';
  boxSpan.style.height = '20px';
  boxSpan.style.marginRight = '10px';
  boxSpan.style.width = '20px';
  boxSpan.style.flexBasis = '20px';
  boxSpan.style.flexGrow = '0';
  boxSpan.style.flexShrink = '0';

  // Text
  const textContainer = document.createElement('p');
  textContainer.style.color = item.fontColor as string;
  textContainer.style.margin = '0';
  textContainer.style.padding = '0';
  textContainer.style.textDecoration = item.hidden ? 'line-through' : '';

  const text = document.createTextNode(item.text);
  textContainer.appendChild(text);

  li.appendChild(boxSpan);
  li.appendChild(textContainer);
  ul.appendChild(li);
}

function getChartData(
  section: WorksheetSectionDefinition,
  entriesBySectionId: WorksheetEntriesBySectionId
): { datasets: ChartData[]; maxX: number; maxY: number } {
  const data: Record<string, ChartData> = {};
  let maxX = 0;
  let maxY = 0;

  section.chartData?.forEach(
    ({ sourceSectionId, yFieldId, xFieldId, color, label }) => {
      const lineLabel = label || `${sourceSectionId}.${yFieldId}`;
      const colorValue = color || AppColors.BRAND_GREEN;
      (entriesBySectionId[sourceSectionId] || ([] as WorksheetEntry[])).forEach(
        ({ data: entryData }) => {
          const xValue = entryData[xFieldId];
          const yValue = entryData[yFieldId];
          if (xValue != null && yValue != null) {
            if (!data[lineLabel]) {
              data[lineLabel] = {
                label: lineLabel,
                data: [{ x: 0, y: 0 }],
                borderColor: colorValue,
                backgroundColor: colorValue,
              };
            }
            const x = parseInt(xValue as string);
            const y = parseInt(yValue as string);
            maxX = Math.max(maxX, x);
            maxY = Math.max(maxY, y);
            data[lineLabel].data.push({ x, y });
          }
        }
      );
    }
  );
  return { datasets: Object.values(data), maxX, maxY };
}

function getLabels(maxX: number): number[] {
  return fill(Array(maxX + 1), 0).map((_v, i) => i);
}

function getOrCreateLegendList(id: string) {
  const legendContainer = document.getElementById(id);
  let listContainer = legendContainer?.querySelector('ul');

  if (!listContainer) {
    listContainer = document.createElement('ul');
    listContainer.style.display = 'flex';
    listContainer.style.flexDirection = 'column';
    listContainer.style.margin = '0';
    listContainer.style.padding = '0';

    legendContainer?.appendChild(listContainer);
  }

  return listContainer;
}
