import { Box, Checkbox, Chip, FormHelperText, MenuItem } from '@mui/material';
import { isArray, isNil, isString, sortBy, startCase } from 'lodash';
import { useMemo } from 'react';
import { useWatch, type FieldValues, type UseControllerProps } from 'react-hook-form';

import { ReactHookTextField } from '#components/widgets/ReactHookTextField';

export interface OmniFieldSelectOptionObject {
  value: string;
  label: string | React.ReactNode;
}

export type OmniFieldSelectOption = string | OmniFieldSelectOptionObject;

export interface OmniSelectFieldProps<TFormData extends FieldValues>
  extends Pick<UseControllerProps<TFormData>, 'name' | 'rules'> {
  disabled?: boolean;
  helperText?: string;
  hideErrorMessage?: boolean;
  label?: string;
  multiple?: boolean;
  required?: boolean;
  selectOptions: OmniFieldSelectOption[];
  sort?: boolean;
  onClose?: () => void;
}

function mapSelectOptions(selectOptions: OmniFieldSelectOption[], sort: boolean) {
  const mappedOptions = selectOptions.map((selectOption) => {
    return isString(selectOption)
      ? {
          value: selectOption,
          label: startCase(selectOption),
        }
      : selectOption;
  });

  if (sort) {
    return sortBy(mappedOptions, 'label');
  }

  return mappedOptions;
}

function getLabelForValue(value: string, options: OmniFieldSelectOption[]) {
  const selectedOption = options.find((option) => {
    return typeof option === 'string' ? option === value : option.value === value;
  });
  return typeof selectedOption === 'string' ? startCase(selectedOption) : selectedOption?.label;
}

export function OmniSelectField<TFormData extends FieldValues>(props: OmniSelectFieldProps<TFormData>) {
  const {
    disabled,
    helperText,
    hideErrorMessage = false,
    label,
    multiple = false,
    name,
    required = false,
    rules,
    selectOptions,
    sort = true,
    onClose = () => undefined,
  } = props;

  const currentValue = useWatch<TFormData>({ name });

  const options = useMemo(() => {
    return mapSelectOptions(selectOptions, sort);
  }, [selectOptions]);

  const SelectProps = {
    onClose,
    multiple,
    ...(multiple
      ? {
          renderValue: (selectedValues: unknown) => {
            if (!isArray(selectedValues)) {
              return null;
            }

            return (
              <Box display="flex" flexWrap="wrap" gap={0.5}>
                {selectedValues.map((selectedValue: string) => (
                  <Chip key={selectedValue} size="small" label={getLabelForValue(selectedValue, selectOptions)} />
                ))}
              </Box>
            );
          },
        }
      : {}),
  };

  const labelText = `${label ?? startCase(name)} ${required ? '*' : ''}`;

  return (
    <>
      <ReactHookTextField
        collapseHelperText={hideErrorMessage}
        controller={{ name, rules }}
        textField={{
          disabled,
          fullWidth: true,
          id: name,
          label: labelText,
          select: true,
          SelectProps,
          type: 'text',
        }}
      >
        {options.map(({ value, label }) => (
          <MenuItem key={value} value={value}>
            {multiple && <Checkbox checked={currentValue.indexOf(value) > -1} />}
            {label}
          </MenuItem>
        ))}
      </ReactHookTextField>
      {!isNil(helperText) && <FormHelperText>{helperText}</FormHelperText>}
    </>
  );
}
