import { type WorksheetDefinition } from '@omnivivo/worksheets-core';
import {
  getSectionDetailsCSV,
  getCSVSimpleFormat,
  blankLine,
  getWorksheetSectionListSingleRow,
  getWorksheetSectionListMultipleRows,
} from './exportCore';

export const getImagingWorksheetCsv = (
  definition: WorksheetDefinition,
  data: string[][],
  headers: string[],
  keys: string[]
) => {
  let worksheetCsv = '';

  const requirements = getWorksheetSectionListSingleRow(definition, 'requirements', headers, data, keys, [
    'timePoints',
    'instructions',
    'numViews',
  ]);
  const animalsToInclude = getWorksheetSectionListMultipleRows(definition, 'animalsToInclude', headers, data, keys, [
    'treatmentGroupIndex',
    'notes',
  ]);

  worksheetCsv = worksheetCsv.concat(
    'Requirements'.concat('\n', getCSVSimpleFormat(requirements.csvHeaders, requirements.csvInfo))
  );
  worksheetCsv = worksheetCsv.concat(
    blankLine,
    getSectionDetailsCSV('Animals to Include', animalsToInclude.csvHeaders, animalsToInclude.csvInfo)
  );

  return worksheetCsv;
};
