import styled from '@emotion/styled';
import { InputLabel } from '@mui/material';
import { AppColors } from '#lib/constants';

export const StyledContainer = styled.div({
  padding: '10px',
  margin: 'auto',
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  overflowX: 'auto',
});

export const StyledCard = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  minHeight: '39px',
  background: AppColors.LIGHT_LIGHT_GREY,
  borderRadius: '4px',
  boxShadow: `2px 2px 6px ${AppColors.DARK_SHADE_RGBA}`,
});

export const StyledRow = styled.div({
  display: 'flex',
  gap: '4px',
  justifyContent: 'center',
  alignItems: 'center',
});

export const StyledIndex = styled.div(
  ({ cardcolor }: { cardcolor: string }) => ({
    borderRadius: '3px',
    width: '20px',
    background: cardcolor,
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '10px',
    color: AppColors.GREY,
    textTransform: 'uppercase',
  })
);

export const StyledLine = styled.div({
  display: 'flex',
  gap: '10px',
});

export const StyledBubble = styled.div(
  ({
    customwidth = '250px',
    isicon,
    bubblecolor,
  }: {
    customwidth?: string;
    isicon?: boolean;
    bubblecolor?: string;
  }) => ({
    background: bubblecolor || AppColors.LOW_GREY,
    width: customwidth,
    height: '23px',
    fontSize: '12px',
    padding: '5px',
    lineHeight: '16px',
    color: AppColors.BLACK,
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  })
);

export const StyledBubbleContainer = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '10px',
});

export const StyledColumn = styled.div({
  display: 'flex',
  flexDirection: 'column',
});

export const StyledTreatmentLine = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '27px',
  padding: '0.3em',
});

export const StyledLabel = styled(InputLabel)({
  fontStyle: 'normal',
  fontWeight: 'bold',
  fontSize: '10px',
  lineHeight: '12px',
  color: AppColors.DARK_GREY,
});
