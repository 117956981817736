import { omit } from 'lodash';

import { ExperimentByIdDocument, useUpdateImagingMutation, type Imaging, type UpdateImagingInput } from '#graphql';
import { type TreatmentGroupWithTreatment } from '#lib/types';
import { ImagingForm, type ImagingFormData } from './ImagingForm';

interface EditImagingProps {
  imaging: Imaging;
  onCancel?: () => void;
  onSuccess?: () => void;
  treatmentGroups: TreatmentGroupWithTreatment[];
}

function formDataToUpdateInput(id: number, data: ImagingFormData): UpdateImagingInput {
  const { treatmentGroups, ...rest } = data;

  return {
    ...rest,
    id,
    treatmentGroups: treatmentGroups.filter((tg) => tg.checked).map((tg) => omit(tg, 'checked')),
    numViews: Number(data.numViews),
  };
}

export function EditImaging(props: EditImagingProps) {
  const { imaging, onCancel = () => undefined, onSuccess = () => undefined, treatmentGroups } = props;

  const [updateImaging] = useUpdateImagingMutation({
    awaitRefetchQueries: true,
    refetchQueries: [ExperimentByIdDocument, 'experimentById'],
  });

  async function handleSubmit(data: ImagingFormData) {
    await updateImaging({
      variables: {
        input: formDataToUpdateInput(imaging.id, data),
      },
    });
  }

  return (
    <ImagingForm
      imaging={imaging}
      handleSubmit={handleSubmit}
      onCancel={onCancel}
      onSuccess={onSuccess}
      treatmentGroups={treatmentGroups}
    />
  );
}
