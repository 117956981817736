import { useState } from 'react';
import get from 'lodash/get';

import { Search } from '#components/widgets';
import { TREATMENT_MODAL_QUERIES } from '#lib/constants';
import { TableView } from './TableView';
import { ModalDataSetTab } from '#lib/types';

interface ModalTabProps {
  onChangeCheckbox: (name: string) => boolean;
  onChangeSelectedItem: (
    id: string,
    isNew: boolean,
    testArticleId?: number,
    tab?: string,
    bioregId?: string,
    name?: string
  ) => void;
  tabName: string;
  selectedItemField: string;
  objectPath: string;
  category?: string;
}

export const ModalTab = ({
  onChangeCheckbox,
  onChangeSelectedItem,
  tabName,
  selectedItemField,
  objectPath,
  category,
}: ModalTabProps) => {
  const [query, setQuery] = useState('');
  const [dataSet, setDataSet] = useState<ModalDataSetTab>();

  const tabOption = TREATMENT_MODAL_QUERIES.find(
    (option) => option.tabName === tabName
  )!;

  let variables = {
    limit: 50,
    offset: 0,
    query,
    category,
  };

  if (!category) delete variables.category;

  const { fetchMore, networkStatus, loading } = tabOption.query({
    variables,
    notifyOnNetworkStatusChange: true,
    onCompleted: (data: ModalDataSetTab) => setDataSet(get(data, objectPath)),
  });

  const onChangeQuery = (searchText: string) => setQuery(searchText);

  const loadMoreData = () =>
    fetchMore({
      variables: {
        limit: 50,
        offset: dataSet?.items.length,
      },
    });

  return (
    <>
      <Search onChange={onChangeQuery} query={query} />
      <TableView
        tabName={category || tabName}
        tableData={dataSet?.items}
        loading={loading}
        dataCount={dataSet?.totalCount}
        onChangeCheckbox={onChangeCheckbox}
        loadItems={loadMoreData}
        networkStatus={networkStatus}
        columns={tabOption.columns}
        onChangeSelectedItem={onChangeSelectedItem}
        selectedItemField={selectedItemField}
      />
    </>
  );
};
