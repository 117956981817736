import { type ListContent, type ExperimentDetailsFragment } from '#graphql';
import { find, isNil } from 'lodash';
import {
  type WorksheetEntry,
  getUniqueNegativeWorksheetEntryId,
  type Worksheet,
  toWorksheetDate,
} from '@omnivivo/worksheets-core';

export const getExperimentWorksheetEntries = (
  experiment: ExperimentDetailsFragment | undefined,
  worksheet: Worksheet,
  entries: WorksheetEntry[],
  listContent: ListContent[]
): WorksheetEntry[] => {
  if (isNil(experiment)) return [];
  let uniqueId = getUniqueNegativeWorksheetEntryId(entries);
  return [
    ...experiment.treatmentGroups.map((treatmentGroup) => {
      const treatment = find(experiment.treatments, {
        id: treatmentGroup.treatmentId,
      });
      let untreatedControl = false;
      const data = {
        ...treatmentGroup,
        testArticles: treatment?.testArticles
          .map((ta) => {
            if (ta.name === 'untreated') {
              untreatedControl = true;
            }
            return `${ta.name} ${ta.dosage}${ta.doseUnit}`;
          })
          .join(', '),
        name: `Group ${treatmentGroup.treatmentGroupIndex}`,
      };

      return {
        id: uniqueId--,
        worksheetId: worksheet.id,
        sectionId: 'experimentTreatmentGroups',
        data: { ...data, untreatedControl },
      };
    }),

    ...listContent.map((res) => {
      const data = {
        name: res.name,
      };
      return {
        id: uniqueId--,
        worksheetId: worksheet.id,
        sectionId: 'preclinicalObservationList',
        data: {
          ...data,
        },
      };
    }),

    ...experiment.models.map((model) => ({
      id: uniqueId--,
      worksheetId: worksheet.id,
      sectionId: 'experimentModels',
      data: {
        ...model,
        cellLine: undefined,
        cellLineName: model.cellLine?.name,
        implantationDate: model.implantationDate != null ? toWorksheetDate(model.implantationDate) : undefined,
      },
    })),
  ];
};
