import styled from '@emotion/styled';
import { TextField } from '@mui/material';

import { AppColors } from '#lib/constants';
import { AppButton } from '#components/widgets';

export const StyledShadowBox = styled.div({
  borderRadius: '4px',
  boxShadow: '2px 2px 6px rgba(0,0,0,0.2)',
});

export const StyledContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '4px',
  boxShadow: '2px 2px 6px rgba(0,0,0,0.2)',
  background: AppColors.WHITE,
  maxWidth: '280px',
  height: 'fit-content',
  gap: '1em',
});

export const StyledTitle = styled.div({
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '10px',
  lineHeight: '12px',
  color: AppColors.GREY,
});

export const StyledPhaseSection = styled.div({
  display: 'flex',
  padding: '1em',
  borderTopLeftRadius: '4px',
  borderTopRightRadius: '4px',
  justifyContent: 'space-around',
  background: AppColors.GREY7,
});

export const StyledColumn = styled.div(
  ({ roundedBox = false }: { roundedBox?: boolean }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '0.7rem',
    padding: '1em',
    background: roundedBox ? AppColors.WHITE : '',
    borderRadius: roundedBox ? '4px' : '',
  })
);

export const StyledActionsSection = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '0.3em',
});

export const StyledActionsContainer = styled.div({
  padding: '1em',
});

export const StyledTextField = styled(TextField)({
  borderRadius: '4px',
  background: AppColors.WHITE,
  color: AppColors.MID_GREY,
  div: {
    maxHeight: '1.7em',
  },
});

export const StyledButtonsContainer = styled.div({
  display: 'flex',
  gap: '1em',
  alignSelf: 'left',
  width: 'fit-content',
});

export const StyledButton = styled(AppButton)(
  ({ hover }: { hover?: string }) => ({
    maxHeight: '18px',
    width: '170px',
    whiteSpace: 'pre',
    textTransform: 'none',
    padding: '12px 0',
    '&:hover': {
      backgroundColor: hover ? AppColors.DARK_RED : AppColors.BRAND_GREEN,
    },
    '&.Mui-disabled': {
      background: 'none !important',
    },
  })
);
