import { ThumbDownAltOutlined, ThumbUpAltOutlined } from '@mui/icons-material';
import { DateTime } from 'luxon';
import toast from 'react-hot-toast';

import { experimentStatuses, i18n } from '#lib/constants';
import { AbilityContext, Can } from '#components/contexts';
import { getApprovalTextButton } from '#lib/utils';
import { StatusBubble, SubStatusBubble } from '#components/widgets';
import { ExperimentDetailsFragment } from '#graphql';
import { useUpdateExperiment } from '#lib/hooks';
import {
  StyledPhaseSection,
  StyledColumn,
  StyledTitle,
  StyledActionsSection,
  StyledTextField,
  StyledButtonsContainer,
  StyledShadowBox,
  StyledButton,
  StyledActionsContainer,
} from './styles';
import styled from '@emotion/styled';
import { ChangeEvent } from 'react';
import { useFormContext } from 'react-hook-form';
import { useAbility } from '@casl/react';

const StyledApprovalCount = styled.div({
  opacity: 0.5,
  fontSize: '11px',
  fontStyle: 'italic',
});

export const StatusBox = ({
  experiment,
}: {
  experiment: ExperimentDetailsFragment;
}) => {
  const ability = useAbility(AbilityContext);
  const { register, setValue } = useFormContext();
  const { updateExperiment, loading } = useUpdateExperiment();

  const onHandleNext = () => {
    const nextStatus =
      experimentStatuses[experimentStatuses.indexOf(experiment.status) + 1];
    if (nextStatus === 'Provision' && !experiment.approvalName) {
      toast.error(i18n.ValidateExperiment.NoExperimentName, {
        id: 'noApprovalName-Toast',
      });
    } else {
      setValue('status', nextStatus);
    }
  };

  let meetingDate = '';
  const todayDate = DateTime.now().toFormat('yyyy-MM-dd');
  if (experiment.meetingDate)
    meetingDate = DateTime.fromISO(experiment.meetingDate || '', {
      zone: 'utc',
    }).toFormat('yyyy-MM-dd');

  return (
    <StyledShadowBox>
      <StyledPhaseSection>
        <StyledColumn>
          <StyledTitle>Phase</StyledTitle>
          <StatusBubble status={experiment.status} />
        </StyledColumn>

        <StyledColumn roundedBox>
          <StyledTitle>Sub Phase</StyledTitle>
          <SubStatusBubble status={experiment.subStatus} />
        </StyledColumn>
      </StyledPhaseSection>

      <StyledActionsSection>
        {experiment.status !== 'Draft' && (
          <StyledActionsContainer>
            <StyledTitle>Meeting Date</StyledTitle>
            <Can
              I="update"
              this={experiment as never}
              passThrough
              field="meetingDate"
            >
              {(allowed: boolean) => (
                <StyledTextField
                  data-test-id="meetingDateInput"
                  disabled={!allowed}
                  type="date"
                  inputProps={{
                    max: todayDate,
                  }}
                  value={meetingDate}
                  onChange={(ev: ChangeEvent<HTMLInputElement>) =>
                    updateExperiment({
                      meetingDate: ev.target.value,
                      id: experiment.id,
                    })
                  }
                />
              )}
            </Can>
          </StyledActionsContainer>
        )}
        {(ability.can('returnToDraft', experiment as never) ||
          ability.can('update', experiment as never, 'status')) && (
          <StyledActionsContainer>
            <StyledTitle>Actions</StyledTitle>
            <StyledButtonsContainer data-test-id="statusUpdateButton">
              <input type="hidden" {...register('status')} />
              <Can I="returnToDraft" this={experiment as never}>
                <StyledButton
                  onClick={() => setValue('status', 'Draft')}
                  hover="true"
                  polarity="negative"
                  customsize="small"
                  solid="true"
                  type="submit"
                  data-test-id={`rejectExperimentButton`}
                >
                  <ThumbDownAltOutlined fontSize="inherit" />
                  Return to Draft
                </StyledButton>
              </Can>

              {['Draft', 'Review'].includes(experiment.status) && (
                <Can I="update" this={experiment as never} field="status">
                  <StyledButton
                    disabled={experiment.status === i18n.Common.Completed}
                    onClick={() => onHandleNext()}
                    solid="true"
                    polarity="positive"
                    customsize="small"
                    type="submit"
                    data-test-id={`submitExperimentButton`}
                  >
                    <ThumbUpAltOutlined fontSize="inherit" />{' '}
                    {getApprovalTextButton(experiment.status)}
                  </StyledButton>
                </Can>
              )}
            </StyledButtonsContainer>
          </StyledActionsContainer>
        )}
        {experiment.approvalCount > 1 && (
          <StyledApprovalCount data-test-id={`experimentApprovalCount`}>
            Approved {experiment.approvalCount} times
          </StyledApprovalCount>
        )}
      </StyledActionsSection>
    </StyledShadowBox>
  );
};
