import {
  WorksheetDefinition,
  WorksheetEntry,
  WorksheetFieldDefinition,
  WorksheetSectionDefinition,
  isFieldTypeEditable,
} from '@omnivivo/worksheets-core';
import { memo } from 'react';

import DeleteRowIcon from '#assets/delete-row-icon.svg';
import {
  ColumnLabelCell,
  DataCell,
  LayoutCell,
  StyledDeleteRowIcon,
  StyledFieldName,
} from './SectionStyledComponents';
import { getCellPositionStyle, gridSize } from './SectionStyles';
import { SingleField } from './SingleField';
import { decodeSingleFieldId, encodeSingleFieldId } from './SingleFieldLib';
import {
  GetWorksheetState,
  WorksheetMutations,
} from './WorksheetComponentInterfaces';

const otherGridWidth = 1000 / 12;

export const mapFieldsForPresentation = <T,>(
  section: WorksheetSectionDefinition,
  f: (field: WorksheetFieldDefinition, visibleFieldIndex: number) => T
): T[] => (section.fields || []).filter(({ hidden }) => !hidden).map(f);

export const getFieldPixelWidth = (field: WorksheetFieldDefinition) =>
  ((field.gridWidth || 4) * otherGridWidth) | 0;

export const getEntryWidth = (fields: WorksheetFieldDefinition[] = []) =>
  fields.map((field) => getFieldPixelWidth(field)).reduce((a, b) => a + b, 0) +
  2 * gridSize * (fields.length + 1);

export const getFieldWidthCssValue = (
  field: WorksheetFieldDefinition,
  plus?: number
) => `${getFieldPixelWidth(field) + (plus || 0)}px`;
export const getFieldWidthStyle = (
  field: WorksheetFieldDefinition,
  plus?: number
) => ({
  width: getFieldWidthCssValue(field, plus),
});

export const getEntryPixelWidth = (section: WorksheetSectionDefinition) =>
  (section.fields || []).map(getFieldPixelWidth).reduce((a, b) => a + b, 0);

const TableEntryBase = ({
  worksheetDefinition,
  section,
  entry,
  forDataCollection,
  onGoUpInput,
  onGoDownInput,
  onGoLeftInput,
  onGoRightInput,
  worksheetMutations,
  getWorksheetState,
  rowNumber = 0,
  columnNumber = 0,
  editableColumnCount = 0,
}: {
  worksheetDefinition: WorksheetDefinition;
  section: WorksheetSectionDefinition;
  entry: WorksheetEntry;
  forDataCollection?: boolean;
  onGoUpInput?: (elementId: string) => void;
  onGoDownInput?: (elementId: string) => void;
  onGoLeftInput?: (elementId: string) => void;
  onGoRightInput?: (elementId: string) => void;
  rowNumber?: number;
  columnNumber?: number;
  editableColumnCount?: number;
  worksheetMutations: WorksheetMutations;
  getWorksheetState: GetWorksheetState;
}) => {
  let editableFieldCount = 0;
  return (
    <>
      {mapFieldsForPresentation(section, (field, fieldNumber) => {
        if (isFieldTypeEditable(field.fieldType)) editableFieldCount++;
        return (
          <DataCell
            data-test-id={`${field.id}`}
            key={field.id}
            style={getCellPositionStyle(
              section.fields,
              fieldNumber,
              forDataCollection
            )}
          >
            {(entry && (
              <SingleField
                {...{
                  key: entry.id,
                  id: encodeSingleFieldId({
                    sectionId: section.id,
                    rowNumber,
                    columnNumber:
                      columnNumber * editableColumnCount + editableFieldCount,
                  }),
                  worksheetDefinition,
                  entryId: entry.id,
                  sectionId: entry.sectionId,
                  value: entry.data[field.id],
                  field,
                  updateValue: worksheetMutations.updateValue,
                  onGoUpInput,
                  onGoDownInput,
                  onGoLeftInput,
                  onGoRightInput,
                  getWorksheetState,
                }}
              />
            )) ||
              undefined}
          </DataCell>
        );
      })}
    </>
  );
};

export const TableEntry = memo(TableEntryBase);

const getTableCellStyle = (
  fields: WorksheetFieldDefinition[] = [],
  field: WorksheetFieldDefinition,
  visibleFieldIndex: number,
  forDataCollection?: boolean
) => ({
  ...getFieldWidthStyle(field),
  ...getCellPositionStyle(fields, visibleFieldIndex, forDataCollection),
});

export const TableColumnLabels = ({
  section,
  forDataCollection,
}: {
  section: WorksheetSectionDefinition;
  forDataCollection?: boolean;
}) => (
  <>
    {mapFieldsForPresentation(section, (field, visibleFieldIndex) => (
      <ColumnLabelCell
        key={field.id}
        style={getTableCellStyle(
          section.fields,
          field,
          visibleFieldIndex,
          forDataCollection
        )}
      >
        <StyledFieldName
          key={field.id}
          title={`${field.id}(${field.fieldType})${
            field.calc ? `: ${field.calc}` : ''
          }`}
        >
          {field.fieldName}
        </StyledFieldName>
      </ColumnLabelCell>
    ))}
  </>
);

export const TableFieldLayoutRow = ({
  section,
  forDataCollection,
}: {
  section: WorksheetSectionDefinition;
  forDataCollection?: boolean;
}) => (
  <>
    {mapFieldsForPresentation(section, (field, visibleFieldIndex) => (
      <LayoutCell
        key={field.id}
        style={getTableCellStyle(
          section.fields,
          field,
          visibleFieldIndex,
          forDataCollection
        )}
      >
        &nbsp;
      </LayoutCell>
    ))}
  </>
);

export const DeleteEntryButton = ({
  deleteAction,
}: {
  deleteAction: () => void;
}) => <StyledDeleteRowIcon src={DeleteRowIcon} onClick={deleteAction} />;

const getFocusAdjacentIdFunction =
  (field: 'rowNumber' | 'columnNumber', amount: number) => (id: string) => {
    const idParts = decodeSingleFieldId(id);
    document
      .getElementById(
        encodeSingleFieldId({
          ...idParts,
          ...{ [field]: idParts[field] + amount },
        })
      )
      ?.focus();
  };

function focusPreviousColumn(id: string, numEditableFields: number) {
  const { sectionId, rowNumber, columnNumber } = decodeSingleFieldId(id);

  const remainder = columnNumber % numEditableFields;

  let nextId;
  if (remainder === 1) {
    nextId = encodeSingleFieldId({
      sectionId,
      rowNumber: rowNumber - 1,
      columnNumber: columnNumber + numEditableFields - 1,
    });
  } else {
    nextId = encodeSingleFieldId({
      sectionId,
      rowNumber,
      columnNumber: columnNumber - 1,
    });
  }
  document.getElementById(nextId)?.focus();
}

function focusNextColumn(id: string, numEditableFields: number) {
  const currentEl = document.getElementById(id) as HTMLInputElement;

  if (currentEl && currentEl.value.length !== currentEl.selectionStart) {
    currentEl.selectionStart = currentEl.value.length;
    return;
  }

  const { sectionId, rowNumber, columnNumber } = decodeSingleFieldId(id);

  const remainder = columnNumber % numEditableFields;

  let nextId;
  if (remainder === 0) {
    nextId = encodeSingleFieldId({
      sectionId,
      rowNumber: rowNumber + 1,
      columnNumber: columnNumber - numEditableFields + 1,
    });
  } else {
    nextId = encodeSingleFieldId({
      sectionId,
      rowNumber,
      columnNumber: columnNumber + 1,
    });
  }
  document.getElementById(nextId)?.focus();
}

export function getTableNavigationByIdsHandlers(
  fields: WorksheetFieldDefinition[] | undefined
) {
  const numEditableFields =
    fields?.filter((field) => field.fieldType !== 'calc').length ?? 0;

  return {
    onGoUpInput: getFocusAdjacentIdFunction('rowNumber', -1),
    onGoDownInput: getFocusAdjacentIdFunction('rowNumber', 1),
    onGoLeftInput: (id: string) => focusPreviousColumn(id, numEditableFields),
    onGoRightInput: (id: string) => focusNextColumn(id, numEditableFields),
  };
}
