import { Box, Typography } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { type FieldValues, FormProvider, useForm } from 'react-hook-form';
import { groupBy, isNil, map, sortBy, sumBy } from 'lodash';
import {
  type ExperimentForInventoryRequirementsFragment,
  type InventoryRequestFragment,
  type InventoryRequestStatus,
  type InventorySummaryFragment,
  useInventoryRequirementsQuery,
  useListInventoryQuery,
} from '#graphql';
import { OmniForm, type OmniFormFieldProps } from '#components/widgets';
import { ANIMAL_INVENTORY_ROUTE, i18n } from '#lib/constants';
import { formatDate } from '#lib/utils';

function getExperimentOptions(experiments: ExperimentForInventoryRequirementsFragment[]) {
  return sortBy(
    experiments.map((experiment) => ({
      value: experiment.id.toString(),
      label: experiment.approvalName ?? experiment.name,
    })),
    'label'
  );
}

function getStrainLabel(
  strain: string,
  quantity: number,
  inventoryRequests?: ExperimentForInventoryRequirementsFragment['inventoryRequests']
) {
  const animals =
    Math.ceil(1.34 * quantity) -
    sumBy(inventoryRequests?.filter((inventoryRequest) => inventoryRequest.inventory?.strain === strain), 'quantity');

  const animalsContent =
    animals < 0 ? (
      <Typography color="red">{Math.abs(animals)} animal(s) over reserved</Typography>
    ) : (
      `${animals} animal(s) pending reservation`
    );

  return (
    <Box display="flex" justifyContent="space-between" width="100%">
      <div>{strain}</div>
      <div>{animalsContent}</div>
    </Box>
  );
}

function getStrainOptions(experiments: ExperimentForInventoryRequirementsFragment[], experimentId: number) {
  return sortBy(
    experiments
      .filter((experiment) => experiment.id === experimentId)
      .flatMap((experiment) => {
        const animalOrderItemsByStrain = groupBy(experiment.orders?.animalOrder?.animalOrderItems, 'animal.strain');
        return Object.keys(animalOrderItemsByStrain).map((strain) => ({
          value: strain,
          label: getStrainLabel(
            strain,
            sumBy(animalOrderItemsByStrain[strain], 'quantity'),
            experiment.inventoryRequests
          ),
        }));
      }),
    'label'
  );
}

function getInventoryOptions(purchaseOrders: InventorySummaryFragment[], strain: string) {
  return sortBy(
    purchaseOrders
      .filter((purchaseOrder) => purchaseOrder.strain === strain)
      .map((purchaseOrder) => ({
        value: purchaseOrder.id.toString(),
        label: (
          <Box display="flex" justifyContent="space-between" width="100%">
            <div>
              ({formatDate(purchaseOrder.dob)}) {purchaseOrder.poNumber}
            </div>
            <div>{purchaseOrder.quantity - (purchaseOrder.used ?? 0) - (purchaseOrder.reserved ?? 0)} available</div>
          </Box>
        ),
      })),
    'label'
  );
}

export interface ReservationFormData extends FieldValues {
  status: InventoryRequestStatus;
  inventoryId: string;
  experimentId: string;
  quantity: string;
  notes: string;
  strain: string;
}

export interface ReservationFormProps {
  inventoryRequest?: InventoryRequestFragment;
  handleSubmit?: (data: ReservationFormData) => Promise<void> | void;
}

export function ReservationForm(props: ReservationFormProps) {
  const { inventoryRequest, handleSubmit = () => undefined } = props;

  const listInventoryQueryResult = useListInventoryQuery({
    variables: {
      limit: 1000,
      filters: {
        status: 'open',
      },
    },
  });

  const experimentsQueryResult = useInventoryRequirementsQuery({
    variables: {
      limit: 1000,
      statuses: ['Execution', 'Provision', 'Ready', 'Review'],
    },
  });

  const purchaseOrders = useMemo(() => {
    return listInventoryQueryResult.data?.listInventory.items ?? [];
  }, [listInventoryQueryResult]);

  const experiments = useMemo(() => {
    return experimentsQueryResult.data?.experiments.items ?? [];
  }, [experimentsQueryResult]);

  const methods = useForm<ReservationFormData>({
    criteriaMode: 'all',
    defaultValues: {
      status: inventoryRequest?.status ?? 'reserved',
      inventoryId: inventoryRequest?.inventoryId?.toString() ?? '',
      experimentId: inventoryRequest?.experimentId?.toString() ?? '',
      quantity: inventoryRequest?.quantity?.toString() ?? '',
      notes: inventoryRequest?.notes ?? '',
      strain: inventoryRequest?.inventory?.strain,
    },
    mode: 'onBlur',
  });

  const [watchExperimentId, watchStrain] = methods.watch(['experimentId', 'strain']);

  const experimentOptions = useMemo(() => {
    return getExperimentOptions(experiments);
  }, [experiments]);

  const strainOptions = useMemo(() => {
    return getStrainOptions(experiments, parseInt(watchExperimentId));
  }, [experiments, watchExperimentId]);

  const inventoryOptions = useMemo(() => {
    return getInventoryOptions(purchaseOrders, watchStrain);
  }, [purchaseOrders, watchStrain]);

  const validateInventoryId = useCallback(
    (inventoryId: ReservationFormData['inventoryId']) => {
      return (
        inventoryOptions.some((inventoryOption) => inventoryOption.value === inventoryId) ||
        'please select valid purchase order'
      );
    },
    [inventoryOptions]
  );

  const validateStrain = useCallback(
    (strain: ReservationFormData['string']) => {
      return map(strainOptions ?? [], 'value').includes(strain) || 'please select a valid strain';
    },
    [strainOptions]
  );

  const formFields: Array<OmniFormFieldProps<ReservationFormData>> = useMemo(
    () => [
      {
        name: 'experimentId',
        label: 'Experiment',
        rules: { required: 'please select a valid experiment' },
        select: true,
        selectOptions: experimentOptions,
        colSpan: 2,
      },
      {
        name: 'strain',
        rules: {
          required: 'please select a valid strain',
          validate: validateStrain,
        },
        select: true,
        selectOptions: strainOptions,
        colSpan: 2,
      },
      {
        name: 'inventoryId',
        label: 'Purchase Order',
        rules: {
          required: 'please select a valid purchase order',
          validate: validateInventoryId,
        },
        select: true,
        selectOptions: inventoryOptions,
        colSpan: 2,
      },
      {
        name: 'quantity',
        rules: { required: 'quantity is required' },
      },
      {
        name: 'status',
        rules: { required: 'status must be `reserved` or `used`' },
        select: true,
        selectOptions: ['reserved', 'used'],
      },
      {
        name: 'notes',
        multiline: true,
        colSpan: 2,
      },
    ],
    [experimentOptions, strainOptions, inventoryOptions]
  );

  return (
    <FormProvider {...methods}>
      <OmniForm
        fields={formFields}
        formText={i18n.Reservations.Form}
        handleSubmit={handleSubmit}
        isNew={isNil(inventoryRequest?.id)}
        successNavigation={`/${ANIMAL_INVENTORY_ROUTE}/reservations`}
      />
    </FormProvider>
  );
}
