import { type EmotionJSX } from '@emotion/react/types/jsx-namespace';
import { ArrowBack, ContentCopy, Delete, Launch, LockOpenOutlined, LockOutlined } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { saveAs } from 'file-saver';
import { isNil } from 'lodash';
import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router-dom';

import { Can } from '#components/contexts';
import { ConfirmationModal, LatestActivityList } from '#components/partials';
import { NavButton } from '#components/widgets';
import {
  ExperimentsByStatusDocument,
  useDeleteExperimentMutation,
  useDuplicateExperimentMutation,
  useInventoryRequestsByExperimentLazyQuery,
  useListContentsQuery,
  usePkBloodWorksheetWithCollectionsLazyQuery,
  usePkTissueWorksheetWithCollectionsLazyQuery,
  useWorksheetEntriesByWorksheetLazyQuery,
  type ExperimentDetailsFragment,
  type PermissionAction,
} from '#graphql';
import {
  CARDS_DRAFTS_PATH,
  DEFAULT_LOCATION_STATE,
  HOME_PATH,
  getConfiguratorDocumentationPath,
  getConfiguratorOverviewPath,
  getConfiguratorTestArticlesPath,
  getConfiguratorWorkspacePath,
  i18n,
} from '#lib/constants';
import { getExperimentZipFile } from '#lib/export';
import { useModal } from '#lib/hooks';
import { type LocationState } from '#lib/types';
import { isDraft } from '#lib/utils';
import {
  BottomHalfWrapper,
  HeaderWrapper,
  ItemWrapper,
  StyledButton,
  StyledCircleLink,
  TabButtonsWrapper,
  TopHalfWrapper,
} from './styles';

type ButtonActions = [
  name: string,
  icon: EmotionJSX.Element,
  testId: string,
  action: () => void,
  permission: PermissionAction,
  isVisible: boolean,
];

export const ExperimentConfiguratorHeader = ({ experiment }: { experiment: ExperimentDetailsFragment }) => {
  const navigate = useNavigate();
  const { origin = HOME_PATH, isDuplicate, ...locationState } = useLocation()?.state ?? DEFAULT_LOCATION_STATE;

  const { Modal, openModal, closeModal } = useModal(
    `${i18n.ExperimentView.DeleteExperimentQuestion} ${experiment.name}?`,
    'sm'
  );

  const [duplicateExperiment] = useDuplicateExperimentMutation({
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: ExperimentsByStatusDocument,
        variables: {
          limit: 10,
          offset: 0,
          query: '',
          statuses: [i18n.Common.Draft, i18n.Common.Provision, i18n.Common.Ready, i18n.Common.Review],
        },
      },
    ],
    onCompleted: (data) => {
      navigate(getConfiguratorOverviewPath(data.duplicateExperiment.id), {
        state: { ...locationState, isDuplicate: true },
      });
    },
  });

  const [deleteExperiment] = useDeleteExperimentMutation({
    onCompleted: () => {
      closeModal();
      navigate(CARDS_DRAFTS_PATH, {
        state: { ...locationState, deleteExperiment: true },
      });
    },
    onError: (error) => {
      closeModal();
      toast.error(error.message);
    },
  });

  // Removing isDuplicate flag to prevent showing snackbar if user reload the page
  useEffect(() => {
    if (isDuplicate === true) {
      toast.success(i18n.ExperimentView.DuplicateExperimentMessage);
      navigate(location.pathname, {
        state: { ...locationState, isDuplicate: false },
        replace: true,
      });
    }
  }, []);

  if (isNil(experiment)) return null;

  const [getWorksheetEntries] = useWorksheetEntriesByWorksheetLazyQuery();
  const getWorksheetEntriesForWorksheet = async (worksheetId: number) => {
    const { data } = await getWorksheetEntries({
      variables: {
        worksheetId,
      },
    });
    return data?.worksheetEntriesByWorksheet.items ?? [];
  };

  const [getInventoryRequests] = useInventoryRequestsByExperimentLazyQuery();
  const getInventoryRequestsByExperiment = async (experimentId: number) => {
    const { data } = await getInventoryRequests({
      variables: {
        experimentId,
      },
    });

    return data?.inventoryRequestsByExperiment ?? [];
  };
  const [getPKBloodWorksheet] = usePkBloodWorksheetWithCollectionsLazyQuery();
  const getPKBloodWorksheetByExperiment = async (experimentId: number) => {
    const { data } = await getPKBloodWorksheet({
      variables: {
        experimentId,
      },
    });

    return data?.pkBloodWorksheet;
  };
  const [getPKTissueWorksheet] = usePkTissueWorksheetWithCollectionsLazyQuery();
  const getPKTissueWorksheetByExperiment = async (experimentId: number) => {
    const { data } = await getPKTissueWorksheet({
      variables: {
        experimentId,
      },
    });

    return data?.pkTissueWorksheet;
  };
  const handleDuplicateExperiment = (experimentId: number) => {
    // Creating timeout showing message to the user before duplicate experiment
    toast(i18n.ExperimentView.DuplicateExperimentTransitionMessage);
    setTimeout(() => {
      void duplicateExperiment({
        variables: {
          id: experimentId,
        },
      });
    }, 3000);
  };

  const handleNavigate = (path: string, options?: LocationState) => {
    if (!isNil(options)) {
      navigate(path, { state: options });
    } else {
      navigate(path);
    }
  };
  const listContentQuery = useListContentsQuery();

  const handleExportCSVExperiment = async () => {
    const inventoryRequests = await getInventoryRequestsByExperiment(experiment.id);
    const pkBloodWorksheet = await getPKBloodWorksheetByExperiment(experiment.id);
    const pkTissueWorksheet = await getPKTissueWorksheetByExperiment(experiment.id);
    try {
      await toast.promise(
        getExperimentZipFile(
          experiment,
          getWorksheetEntriesForWorksheet,
          inventoryRequests,
          listContentQuery.data?.listContents?.items ?? [],
          pkBloodWorksheet,
          pkTissueWorksheet
        ),
        {
          loading: i18n.ExperimentView.CSVExportLoadingMessage,
          success: (zipFile) => {
            saveAs(zipFile, 'example.zip');
            return i18n.ExperimentView.ExportedCSVMessage;
          },
          error: i18n.Common.GenericError,
        }
      );
    } catch (error: unknown) {
      console.error(error);
    }
  };

  const configureActions: ButtonActions[] = [
    ['Export', <Launch fontSize="inherit" />, 'exportExperiment', handleExportCSVExperiment, 'read', true],
    // Temporarily removed - see AB#45385
    // [
    //   'See Related',
    //   <FolderOpenOutlined fontSize="inherit" />,
    //   'seeRelatedExperiments',
    //   () =>
    //     handleNavigate(CARDS_ARCHIVE_PATH, {
    //       ...locationState,
    //       origin,
    //       experimentRelated: experiment.id,
    //       experimentName: experiment.name,
    //     }),
    //   'read',
    //   true,
    // ],
    [
      'Duplicate',
      <ContentCopy fontSize="inherit" />,
      'duplicateExperiment',
      () => {
        handleDuplicateExperiment(experiment.id);
      },
      'create',
      true,
    ],
    ['Delete', <Delete fontSize="inherit" />, 'deleteExperiment', openModal, 'delete', isDraft(experiment)],
    [
      'Exit',
      <ArrowBack fontSize="inherit" />,
      'exitConfigurator',
      () => {
        handleNavigate(origin);
      },
      'read',
      true,
    ],
  ];

  const overviewMenu = [
    ['Configure', getConfiguratorOverviewPath(experiment.id), origin],
    ['Documentation', getConfiguratorDocumentationPath(experiment.id), origin],
    ['Workspace', getConfiguratorWorkspacePath(experiment.id), origin],
    ['Orders', getConfiguratorTestArticlesPath(experiment.id), origin],
  ];

  return (
    <HeaderWrapper>
      <Modal>
        <ConfirmationModal
          closeModal={closeModal}
          confirmationFunction={async () =>
            await deleteExperiment({
              variables: {
                id: experiment.id,
              },
            })
          }
        />
      </Modal>
      <TopHalfWrapper>
        <ItemWrapper>
          <StyledCircleLink to={origin}>
            <ArrowBack fontSize="inherit" />
          </StyledCircleLink>
          <Can I={'update'} this={experiment} passThrough>
            {(allowed) => (allowed ? <LockOpenOutlined /> : <LockOutlined />)}
          </Can>
          <Typography variant="h1" data-test-experiment-name={experiment.name} data-test-id="experimentDisplayName">
            {experiment.approvalName ?? 'Pending'}
          </Typography>
        </ItemWrapper>
        <ItemWrapper>
          {configureActions.map(([name, icon, testId, action, permission]) => (
            <Can key={`button-${name}`} I={permission} this={experiment}>
              <StyledButton solid="true" data-test-id={testId} customsize="tiny" polarity="positive" onClick={action}>
                {icon} {name}
              </StyledButton>
            </Can>
          ))}
        </ItemWrapper>
      </TopHalfWrapper>
      <BottomHalfWrapper>
        <TabButtonsWrapper>
          {overviewMenu.map(([text, path, originPath]) => (
            <NavButton
              data-test-id={`${text.toLowerCase()}Tab`}
              currentPath={originPath}
              key={`button-${text}`}
              tab
              path={path}
            >
              {text}
            </NavButton>
          ))}
        </TabButtonsWrapper>
        <ItemWrapper>
          <LatestActivityList activities={experiment.activity} />
        </ItemWrapper>
      </BottomHalfWrapper>
    </HeaderWrapper>
  );
};
