import styled from '@emotion/styled';
import { Language } from '@mui/icons-material';

import { type ExperimentDetailsFragment } from '#graphql';
import { AppColors } from '#lib/constants';
import { type WorksheetThumbnailWorksheet } from './WorksheetThumbnail';
import { Worksheets } from './Worksheets';

const StyledContainer = styled.div({
  display: 'flex',
  background: AppColors.WHITE,
  flexDirection: 'column',
  width: 'inherit',
  boxShadow: ' 2px 2px 6px rgba(0, 0, 0, 0.2)',
  borderRadius: '4px',
});

const StyledHeader = styled.div({
  display: 'flex',
  background: AppColors.GREY7,
  justifyContent: 'space-between',
  gap: '0.5em',
  padding: '0.5em',
});

const StyledSection = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '1em',
});

const StyledTitle = styled.div({
  color: AppColors.BLACK,
  letterSpacing: '0.15px',
  lineHeight: '24px',
  fontSize: '14px',
  fontWeight: 'bold',
  fontStyle: 'normal',
});

const StyledLanguageIcon = styled(Language)({
  color: AppColors.WHITE,
  background: AppColors.MID_GREY,
  padding: '2px',
});

interface PreparationCardProps {
  worksheets: WorksheetThumbnailWorksheet[];
  models: ExperimentDetailsFragment['models'];
}

// TODO: Update worksheets type when data is in database.
export const PreparationCard = ({ worksheets, models }: PreparationCardProps) => {
  return (
    <StyledContainer>
      <StyledHeader>
        <StyledSection>
          <StyledLanguageIcon fontSize="small" />
          <StyledTitle>Worksheets</StyledTitle>
        </StyledSection>
      </StyledHeader>
      <Worksheets {...{ worksheets }} models={models} />
    </StyledContainer>
  );
};
