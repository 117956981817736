let fileDownload = require('js-file-download');
import { ExperimentDetailsFragment } from '#graphql';
import { getWorksheetPageCSV } from '#lib/export';
import {
  Worksheet,
  WorksheetEntry,
  worksheetDataForExport,
} from '@omnivivo/worksheets-core';

export const downloadAsCsv = ({
  worksheet,
  experiment,
  entries,
}: {
  worksheet: Worksheet;
  experiment: ExperimentDetailsFragment;
  entries: WorksheetEntry[];
}) => {
  const worksheetData = {
    name: worksheet.name!,
    data: worksheetDataForExport({ worksheet, entries }),
  };

  const [name, worksheetCSV] = getWorksheetPageCSV(
    worksheet.definition,
    experiment,
    worksheet,
    worksheetData
  );

  fileDownload(worksheetCSV, `${name}.csv`);
};
