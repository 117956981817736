import { useAbility } from '@casl/react';
import AttachmentIcon from '@mui/icons-material/Attachment';
import DownloadIcon from '@mui/icons-material/Download';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import UploadIcon from '@mui/icons-material/Upload';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  FormControlLabel,
  Paper,
  Stack,
  Switch,
  Typography,
} from '@mui/material';
import { isNil } from 'lodash';
import { DateTime } from 'luxon';
import { useState } from 'react';
import toast from 'react-hot-toast';

import Adc from '#assets/adc.svg';
import { AbilityContext, Can } from '#components/contexts';
import { Attachments } from '#components/partials';
import { AdcNotes } from '#components/partials/TestArticles/partials';
import { StatusBubble } from '#components/widgets';
import { useDeliverAdcOrderItemsMutation, type AdcOrderItemInput, type AdcOrderWithExperimentFragment } from '#graphql';
import {
  allDeliverableAdcOrderItemIds,
  canDeliverAllAdcOrderItemGroups,
  getConjugateAdcOrderItemsForDisplay,
  groupConjugatesAndAdcOrderItemsByBioregId,
  hasDeliveredItems,
} from '#lib/adcOrder';
import { download } from '#lib/adcOrderDownload';
import { ATTACHMENT_CATEGORY_ADC, AppColors, i18n } from '#lib/constants';
import { useModal } from '#lib/hooks';
import { UploadAdcOrders } from './UploadAdcOrders';
import { ConjugateAdcOrder } from './ConjugateAdcOrder';

interface AdcOrderProps {
  adcOrder: AdcOrderWithExperimentFragment;
  refetchAdcOrders?: () => void;
}

export type EditableAdcOrderItemFields = keyof Omit<AdcOrderItemInput, 'id'> | 'deliveredAt' | 'returnedAt';

export const dataTestId = 'adcOrder';

export const AdcOrder = ({
  adcOrder,
  adcOrder: { adcOrderItems },
  refetchAdcOrders = () => undefined,
}: AdcOrderProps) => {
  const [conjugatesByBioregId, adcOrderItemGroupsByBioregId] = groupConjugatesAndAdcOrderItemsByBioregId(adcOrderItems);

  const experiment = adcOrder.experiment;
  const experimentName = experiment.approvalName ?? experiment.name;
  const experimentId = experiment.id;

  const canDeliverAll = canDeliverAllAdcOrderItemGroups(adcOrderItemGroupsByBioregId);

  const accordionId = `unfulfilledAdcOrder-${adcOrder.id}`;

  const [hideDelivered, setHideDelivered] = useState<boolean>(false);
  const [deliverAdcOrderItems] = useDeliverAdcOrderItemsMutation();
  const deliverAll = async () => {
    await toast.promise(
      deliverAdcOrderItems({
        variables: {
          input: {
            adcOrderId: adcOrder.id,
            adcOrderItemIds: allDeliverableAdcOrderItemIds(adcOrderItemGroupsByBioregId),
          },
        },
      }),
      i18n.DeliverAllOrderItems
    );
    refetchAdcOrders();
  };

  // Once the delivery mutation is created we can remove this in favor of updating the cache
  const handleConjugateDeliver = () => {
    refetchAdcOrders();
  };

  const ability = useAbility(AbilityContext);

  const canEditField = (field: EditableAdcOrderItemFields) => ability.can('update', adcOrder, field);

  const { openModal: openUploadModal, Modal: UploadModal, closeModal } = useModal('Upload Adc Orders', 'lg');

  return (
    <>
      <UploadModal>
        <UploadAdcOrders adcOrderItems={adcOrderItems} experimentName={experimentName} onUpload={closeModal} />
      </UploadModal>
      <Accordion data-test-id={dataTestId} TransitionProps={{ unmountOnExit: true }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`${accordionId}-details`}
          id={`${accordionId}-summary`}
          sx={{ backgroundColor: AppColors.LIGHT_GREY }}
        >
          <Box display="flex" justifyContent="space-between" alignItems="center" width="100%" marginRight={1}>
            <Box display="flex" alignItems="center" width="auto" gap={2}>
              <img src={Adc} width="30px" height="30px" />
              {'experiment' in adcOrder ? (
                <>
                  <Typography id={experimentName} variant="h2" data-test-id="adc-order-title">
                    {experimentName}
                  </Typography>
                  <StatusBubble status={experiment.status}></StatusBubble>
                </>
              ) : (
                <Typography id="adc" variant="h2">
                  ADC
                </Typography>
              )}
            </Box>
            {!isNil(adcOrder.fulfilledAt) && (
              <Typography variant="body2" paddingY={1}>
                Fulfilled: {DateTime.fromISO(adcOrder.fulfilledAt).toLocaleString()}
              </Typography>
            )}
          </Box>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: 0 }}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            paddingY={2}
            paddingX={4}
            sx={{ backgroundColor: AppColors.LIGHT_LIGHT_GREY }}
          >
            <Box display="flex" gap={4}>
              <Box display="flex" gap={2}>
                <Button
                  variant="contained"
                  disableElevation
                  startIcon={<DownloadIcon />}
                  onClick={() => {
                    download(adcOrder, experimentName);
                  }}
                >
                  Download
                </Button>
                <Button variant="outlined" disableElevation startIcon={<UploadIcon />} onClick={openUploadModal}>
                  Upload
                </Button>
              </Box>
              {hasDeliveredItems(adcOrderItems) && (
                <FormControlLabel
                  control={<Switch />}
                  label="Hide delivered orders"
                  checked={hideDelivered}
                  onChange={(_e, checked) => {
                    setHideDelivered(checked);
                  }}
                />
              )}
            </Box>
            {canEditField('deliveredAt') && (
              <Box display="flex" justifyContent="flex-end">
                <Button
                  startIcon={<LocalShippingIcon />}
                  variant="contained"
                  disabled={!canDeliverAll}
                  disableElevation
                  onClick={deliverAll}
                >
                  Deliver All
                </Button>
              </Box>
            )}
          </Box>
          <Stack
            spacing={4}
            padding={4}
            sx={{
              '& > *': {
                padding: (theme) => theme.spacing(2),
              },
            }}
          >
            {Array.from(conjugatesByBioregId.keys())
              .sort()
              .flatMap((bioregId) => {
                const conjugate = conjugatesByBioregId.get(bioregId);
                const adcOrderItemGroup = adcOrderItemGroupsByBioregId.get(bioregId);

                if (isNil(conjugate) || isNil(adcOrderItemGroup)) {
                  return [];
                }

                return getConjugateAdcOrderItemsForDisplay(bioregId, adcOrderItemGroup, hideDelivered).map(
                  ({ key, adcOrderItems }) => (
                    <ConjugateAdcOrder
                      key={key}
                      bioregId={bioregId}
                      conjugate={conjugate}
                      adcOrderId={adcOrder.id}
                      adcOrderItems={adcOrderItems}
                      canEditField={canEditField}
                      onConjugateDeliver={handleConjugateDeliver}
                    />
                  )
                );
              })}
            <Paper
              elevation={1}
              sx={{
                backgroundColor: AppColors.VERY_LIGHT_GREY,
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
              }}
            >
              <Typography variant="h3" id="notes">
                Notes
              </Typography>
              <AdcNotes adcOrder={adcOrder} hiddenLabel />
            </Paper>
            <Paper elevation={1} sx={{ backgroundColor: AppColors.VERY_LIGHT_GREY }}>
              <Can I="update" this={experiment} field="attachments" passThrough>
                {(canEditAttachment) => (
                  <Attachments
                    experimentId={experimentId}
                    canEdit={canEditAttachment}
                    category={ATTACHMENT_CATEGORY_ADC}
                    render={(openModal) => (
                      <Button
                        variant="contained"
                        disableElevation
                        disabled={!canEditAttachment}
                        startIcon={<AttachmentIcon />}
                        onClick={() => {
                          openModal();
                        }}
                      >
                        {i18n.Common.addAttachments}
                      </Button>
                    )}
                  />
                )}
              </Can>
            </Paper>
          </Stack>
        </AccordionDetails>
      </Accordion>
    </>
  );
};
