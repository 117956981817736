import { Box, FormControl, InputLabel, debounce } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { isNil } from 'lodash';
import { DateTime } from 'luxon';
import { useCallback } from 'react';
import toast from 'react-hot-toast';

import { useUpdatePkTissueWorksheetBasisDateMutation, type PkTissueWorksheet } from '#graphql';
import { DEFAULT_DEBOUNCE } from '#lib/constants';
import { i18n } from '#lib/constants/i18n';
import { formatDate } from '#lib/utils';

const MIN_DATE = DateTime.fromISO('2023-06-01');

interface BasisDateProps {
  canUpdateBasisDate: boolean;
  worksheet: {
    id: number;
    basisDate?: PkTissueWorksheet['basisDate'];
  };
}

/**
 *
 * TODO
 * - add validation to basis date field, do not allow date to be removed
 * - above may require using react-hook-form
 */
export function BasisDate(props: BasisDateProps) {
  const { canUpdateBasisDate, worksheet } = props;

  const [updateBasisDate] = useUpdatePkTissueWorksheetBasisDateMutation();

  const onChangeBasisDate = useCallback(
    debounce((value: DateTime | null) => {
      void toast.promise(
        updateBasisDate({
          variables: {
            input: {
              id: worksheet.id,
              basisDate: isNil(value) ? null : value.toISODate() ?? '',
            },
          },
        }),
        i18n.PKTissueWorksheet.UpdateBasisDate
      );
    }, DEFAULT_DEBOUNCE),
    [updateBasisDate]
  );

  return canUpdateBasisDate ? (
    <DatePicker
      label="Basis Date"
      defaultValue={DateTime.fromISO(worksheet.basisDate ?? '')}
      onChange={onChangeBasisDate}
      minDate={MIN_DATE}
      slotProps={{
        field: { clearable: true },
      }}
    />
  ) : (
    // width and padding to match text input
    <FormControl sx={{ width: '246px' }}>
      <InputLabel>Basis Date</InputLabel>
      <Box paddingY="10.5px" paddingX="16px">
        {isNil(worksheet.basisDate) ? '-' : formatDate(worksheet.basisDate)}
      </Box>
    </FormControl>
  );
}
