import { isNil, isNumber, uniqBy } from 'lodash';
import { type FieldFunctionOptions } from '@apollo/client';

import {
  type ExperimentCollection,
  type Collection,
  type CellLineCollection,
  type AntibodyCollection,
  type ConjugateCollection,
  type SmallMoleculeCollection,
  type UserCollection,
  type WorksheetCollection,
  type OtherMoleculeCollection,
  type AdcOrderCollection,
  type InventoryCollection,
} from '#graphql';

function mergingQueriesCache<T extends Collection>(keyArgs: string[], append = false) {
  return {
    keyArgs,
    read: (existing: T | undefined, options: FieldFunctionOptions) => {
      const { offset, limit } = options.args ?? {};
      if (!append && !isNil(existing) && isNumber(offset) && isNumber(limit)) {
        return {
          items: existing.items.slice(offset, offset + limit),
          totalCount: existing.totalCount,
        };
      }
      return existing;
    },
    merge: (existing: T | undefined, incoming: T, { args }: FieldFunctionOptions) => {
      const mergedItems = [...(existing?.items ?? [])];
      for (let i = 0; i < incoming.items.length; ++i) {
        mergedItems[(args?.offset ?? 0) + i] = incoming.items[i];
      }
      return {
        __typename: incoming.__typename,
        totalCount: incoming.totalCount,
        items: uniqBy(mergedItems, '__ref'),
      };
    },
  };
}

export const apolloCacheConfiguration = {
  typePolicies: {
    Query: {
      fields: {
        adcOrders: mergingQueriesCache<AdcOrderCollection>(['query', 'status']),
        antibodies: mergingQueriesCache<AntibodyCollection>(['query'], true),
        conjugates: mergingQueriesCache<ConjugateCollection>(['query'], true),
        smallMolecules: mergingQueriesCache<SmallMoleculeCollection>(['query'], true),
        otherMolecules: mergingQueriesCache<OtherMoleculeCollection>(['query', 'category'], true),
        cellLines: mergingQueriesCache<CellLineCollection>(['query', 'filter', 'sort'], true),
        croCellLines: mergingQueriesCache<CellLineCollection>(['query', 'filter', 'sort']),
        experimentsByStatus: mergingQueriesCache<ExperimentCollection>(['query', 'filter', 'sort', 'statuses']),
        experiments: mergingQueriesCache<ExperimentCollection>(['query', 'filter', 'sort', 'statuses']),
        listInventory: mergingQueriesCache<InventoryCollection>(['query', 'filter', 'sort']),
        allUsers: mergingQueriesCache<UserCollection>(['query', 'filter', 'sort', 'excludedIds', 'role']),
        worksheets: mergingQueriesCache<WorksheetCollection>(['']),
      },
    },
    WorksheetEntry: {
      fields: {
        data: {
          merge(existing: string | undefined, incoming: string) {
            return JSON.stringify({
              ...JSON.parse(existing ?? '{}'),
              ...JSON.parse(incoming),
            });
          },
        },
      },
    },
    HandlingAndStorage: {
      keyFields: false as const,
    },
  },
};
