import { GridColDef, GridRowId } from '@mui/x-data-grid-premium';
import { useNavigate } from 'react-router-dom';
import { useCallback, useMemo } from 'react';
import { useAbility } from '@casl/react';
import { map } from 'lodash';
import {
  InventoryRequestFragment,
  useDeleteInventoryRequestMutation,
  useInventoryRequirementsQuery,
  useListContentsQuery,
  useListInventoryQuery,
  useListInventoryRequestsLazyQuery,
} from '#graphql';
import { AbilityContext } from '#components/contexts';
import { OmniGrid } from '#components/widgets';
import { ANIMAL_INVENTORY_ROUTE, i18n } from '#lib/constants';
import { formatDate } from '#lib/utils';

export function Reservations() {
  const navigate = useNavigate();
  const ability = useAbility(AbilityContext);

  const [deleteInventoryRequest] = useDeleteInventoryRequestMutation();

  const listContentsQueryResult = useListContentsQuery({
    variables: {
      category: 'strain',
    },
    fetchPolicy: 'cache-first',
  });

  const listInventoryQueryResult = useListInventoryQuery({
    variables: {
      limit: 1000,
    },
  });

  const experimentsQueryResult = useInventoryRequirementsQuery({
    variables: {
      limit: 1000,
      statuses: ['Execution', 'Provision', 'Ready', 'Review'],
    },
  });

  const [getInventoryRequests, { loading, error, data, previousData }] =
    useListInventoryRequestsLazyQuery();

  const strains = useMemo(() => {
    return map(
      listContentsQueryResult.data?.listContents.items ?? [],
      'name'
    ).sort();
  }, [listContentsQueryResult]);

  const poNumbers = useMemo(() => {
    return map(
      listInventoryQueryResult.data?.listInventory.items ?? [],
      'poNumber'
    ).sort();
  }, [listInventoryQueryResult]);

  const experimentNames = useMemo(() => {
    return map(
      experimentsQueryResult.data?.experiments.items ?? [],
      (experiment) => experiment.approvalName ?? 'N/A'
    ).sort();
  }, [experimentsQueryResult]);

  const currentData =
    data?.listInventoryRequests ?? previousData?.listInventoryRequests;

  const rows = currentData?.items ?? [];
  const rowCount = currentData?.totalCount ?? 0;

  const handleEdit = useCallback(
    (id: GridRowId) =>
      navigate(`/${ANIMAL_INVENTORY_ROUTE}/reservations/${id}/edit`),
    [navigate]
  );

  const onDelete = useCallback(
    async (id: GridRowId) => {
      await deleteInventoryRequest({
        variables: {
          id: Number(id),
        },
        awaitRefetchQueries: true,
        refetchQueries: ['ListInventoryRequests'],
      });
    },
    [deleteInventoryRequest]
  );

  const columns: GridColDef<InventoryRequestFragment>[] = useMemo(() => {
    return [
      {
        field: 'status',
        flex: 1,
        headerName: 'Status',
        type: 'singleSelect',
        valueOptions: () => ['reserved', 'used'],
      },
      {
        field: 'experiment.approvalName',
        flex: 1,
        headerName: 'Experiment',
        valueGetter: (params) => params.row.experiment?.approvalName,
        type: 'singleSelect',
        valueOptions: () => experimentNames,
        disableColumnMenu: true,
        sortable: false,
        filterable: false,
      },
      {
        field: 'inventory.strain',
        flex: 1,
        headerName: 'Strain',
        valueGetter: (params) => params.row.inventory?.strain,
        type: 'singleSelect',
        valueOptions: () => strains,
        filterable: false,
      },
      {
        field: 'inventory.poNumber',
        flex: 1,
        headerName: 'PO #',
        valueGetter: (params) => params.row.inventory?.poNumber,
        type: 'singleSelect',
        valueOptions: () => poNumbers,
        disableColumnMenu: true,
        sortable: false,
        filterable: false,
      },
      {
        field: 'inventory.dob',
        flex: 1,
        headerName: 'PO Receipt Date',
        type: 'date',
        valueGetter: (params) => params.row.inventory?.dob,
        valueFormatter: (params) =>
          params.value ? formatDate(params.value) : '',
        minWidth: 120,
        filterable: false,
      },
      {
        field: 'quantity',
        flex: 1,
        headerName: 'Quantity',
        disableColumnMenu: true,
        sortable: false,
        filterable: false,
      },
      {
        field: 'notes',
        flex: 1,
        headerName: 'Notes',
        disableColumnMenu: true,
        sortable: false,
        filterable: false,
      },
    ];
  }, [experimentNames, poNumbers, strains]);

  return (
    <OmniGrid
      columns={columns}
      defaultSort={[{ field: 'createdAt', sort: 'asc' }]}
      error={error}
      loading={loading}
      refetch={getInventoryRequests}
      rows={rows}
      rowCount={rowCount}
      showDeleteButton={ability.can('delete', 'inventoryRequest')}
      deleteConfirmProps={{
        modalText: i18n.Reservations.DeleteModal,
        onConfirm: onDelete,
      }}
      showEditButton={ability.can('update', 'inventoryRequest')}
      editButtonProps={{ handleEdit }}
      headerProps={{
        title: 'Reservations',
        showAddButton: ability.can('create', 'inventoryRequest'),
        addButtonProps: {
          label: 'Add Reservation',
          to: `/${ANIMAL_INVENTORY_ROUTE}/reservations/create`,
        },
      }}
      showQuickFilter={true}
    />
  );
}
