"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.bioanalysis = void 0;
const style_1 = require("@omnivivo/style");
const worksheets_core_1 = require("@omnivivo/worksheets-core");
const ExperimentInjectedSections_1 = require("./ExperimentInjectedSections");
exports.bioanalysis = (0, worksheets_core_1.getNormalizeWorksheetDefinition)({
    worksheetName: 'Bioanalysis',
    color: style_1.AppColors.RED,
    sections: [
        ...ExperimentInjectedSections_1.experimentInjectedSectionDefinitions,
        {
            id: 'bloodRequirements',
            sectionType: 'form',
            sectionName: 'Blood requirements',
            fields: [
                {
                    id: 'bloodIncluded',
                    fieldName: 'Blood included',
                    fieldType: 'boolean',
                    gridWidth: 6,
                },
                {
                    id: 'spacer',
                    fieldType: 'spacer',
                    gridWidth: 6,
                },
                {
                    id: 'amountOfBloodToInclude',
                    fieldName: 'Amount of sample to deliver',
                    fieldType: 'string',
                    gridWidth: 6,
                },
                {
                    id: 'timePoints',
                    fieldName: 'Time points',
                    fieldType: 'string',
                    gridWidth: 6,
                },
                {
                    id: 'typeBlood',
                    fieldName: 'Type blood',
                    fieldType: 'boolean',
                    gridWidth: 2,
                },
                {
                    id: 'typePlasma',
                    fieldName: 'Type plasma',
                    fieldType: 'boolean',
                    gridWidth: 2,
                },
                {
                    id: 'typeSerum',
                    fieldName: 'Type Serum',
                    fieldType: 'boolean',
                    gridWidth: 2,
                },
            ],
        },
        {
            id: 'bloodAnimalsToInclude',
            sectionType: 'table',
            sectionName: 'Blood: Animals to include (uL)',
            allowAddRows: true,
            fields: [
                {
                    id: 'treatmentGroup',
                    fieldName: 'Group',
                    fieldType: 'singleSelect',
                    fieldValuesCalc: 'select("experimentTreatmentGroups", "id", ["hasBioanalysis", true])',
                    fieldValueLabelsCalc: 'select("experimentTreatmentGroups", "name", ["hasBioanalysis", true])',
                    gridWidth: 3,
                },
                {
                    id: 'treatmentGroupIndex',
                    fieldName: 'Group Index',
                    fieldType: 'readOnly',
                    hidden: true,
                },
                {
                    id: 'notes',
                    fieldName: 'Notes',
                    fieldType: 'string',
                    gridWidth: 6,
                },
            ],
        },
        {
            id: 'bloodMethodRequirements',
            sectionType: 'form',
            sectionName: 'Blood: Method requirements',
            fields: [
                {
                    id: 'gTAb',
                    fieldName: 'gTAb Gyros (min plasma = 15 uL)',
                    fieldType: 'boolean',
                    gridWidth: 3,
                },
                {
                    id: 'acDRUG',
                    fieldName: 'acDRUG (min plasma = 30uL)',
                    fieldType: 'boolean',
                    gridWidth: 3,
                },
                {
                    id: 'gADC',
                    fieldName: 'gADC Gyros (min Plasma = 15 UL)',
                    fieldType: 'boolean',
                    gridWidth: 3,
                },
                {
                    id: 'lbaCba',
                    fieldName: 'Custom LBA/CBA (add pre-dose)',
                    fieldType: 'boolean',
                    gridWidth: 3,
                },
                {
                    id: 'ivigs',
                    fieldName: 'Specific (ivigs) Hybrid Assay (Peptide sTAb + acDrug; min plasma = 30 uL)',
                    fieldType: 'boolean',
                    gridWidth: 3,
                },
                {
                    id: 'sTAb',
                    fieldName: 'sTAb LBA',
                    fieldType: 'boolean',
                    gridWidth: 3,
                },
                {
                    id: 'unconjugatedMmae',
                    fieldName: 'Unconjugated MMAE (min plasma = 30 uL)',
                    fieldType: 'boolean',
                    gridWidth: 3,
                },
                {
                    id: 'ada',
                    fieldName: 'ADA (add pre-dose)',
                    fieldType: 'boolean',
                    gridWidth: 3,
                },
                {
                    id: 'sAntigen',
                    fieldName: 'sAntigen/Biomarker (add pre-dose)',
                    fieldType: 'boolean',
                    gridWidth: 3,
                },
                {
                    id: 'generic',
                    fieldName: 'Multiplex Assay (peptide-based gTAb/sTAB, acDrug) ',
                    fieldType: 'boolean',
                    gridWidth: 3,
                },
                {
                    id: 'unconjugatedDrug',
                    fieldName: 'Unconjugated drug (other payload, min plasma = 60 uL)',
                    fieldType: 'boolean',
                    gridWidth: 3,
                },
                {
                    id: 'other',
                    fieldName: 'Other',
                    fieldType: 'string',
                    gridWidth: 12,
                },
            ],
        },
        {
            id: 'tissueRequirements',
            sectionType: 'form',
            sectionName: 'Tissue requirements',
            fields: [
                {
                    id: 'tissueIncluded',
                    fieldName: 'Tissue included',
                    fieldType: 'boolean',
                    gridWidth: 6,
                },
                {
                    id: 'space',
                    fieldType: 'spacer',
                    gridWidth: 6,
                },
                {
                    id: 'tissuesToInclude',
                    fieldName: 'Tissues to include',
                    fieldType: 'string',
                    gridWidth: 6,
                },
                {
                    id: 'timePoints',
                    fieldName: 'Time points',
                    fieldType: 'string',
                    gridWidth: 6,
                },
                {
                    id: 'handling',
                    fieldName: 'Handling',
                    fieldType: 'string',
                    gridWidth: 6,
                },
                {
                    id: 'storage',
                    fieldName: 'Storage',
                    fieldType: 'string',
                    gridWidth: 6,
                },
                {
                    id: 'strains',
                    fieldName: 'Stains',
                    fieldType: 'string',
                    gridWidth: 6,
                },
                {
                    id: 'destination',
                    fieldName: 'Destination',
                    fieldType: 'singleSelect',
                    fieldValues: ['AP Lab', 'Histology Core'],
                    gridWidth: 6,
                },
            ],
        },
        {
            id: 'tissueAnimalsToInclude',
            sectionType: 'table',
            sectionName: 'Tissue: Animals to include',
            allowAddRows: true,
            fields: [
                {
                    id: 'treatmentGroup',
                    fieldName: 'Group',
                    fieldType: 'singleSelect',
                    fieldValuesCalc: 'select("experimentTreatmentGroups", "id", ["hasBioanalysis", true])',
                    fieldValueLabelsCalc: 'select("experimentTreatmentGroups", "name", ["hasBioanalysis", true])',
                    gridWidth: 3,
                },
                {
                    id: 'treatmentGroupIndex',
                    fieldName: 'Group Index',
                    fieldType: 'readOnly',
                    hidden: true,
                },
                {
                    id: 'notes',
                    fieldName: 'Notes',
                    fieldType: 'string',
                    gridWidth: 6,
                },
            ],
        },
    ],
});
