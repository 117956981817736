import { Stack } from '@mui/material';

import { Button } from '#rds';

interface ConfirmationModalProps {
  closeModal: VoidFunction;
  confirmationFunction: VoidFunction;
}

export const ConfirmationModal = ({ closeModal, confirmationFunction }: ConfirmationModalProps) => {
  return (
    <Stack direction="row" gap={2} padding={2}>
      <Button variant="contained" onClick={confirmationFunction}>
        Yes
      </Button>
      <Button variant="outlined" onClick={closeModal}>
        No
      </Button>
    </Stack>
  );
};
