"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getTestArticleName = exports.formatNumber = exports.createAllDataCollectionSections = exports.createDataCollectionCharts = exports.createDataCollectionMainSection = exports.createDataCollectionColumnsSection = exports.dataCollectionCommonSections = exports.getTgColumnsSectionId = exports.getTgSectionId = exports.DOSE_REQUIRED = exports.DOSE_TIME = exports.DOSE_AMOUNT = exports.WEIGHT_CHANGE_PERCENT_ID = exports.MEDIAN_ID = exports.MEAN_ID = exports.AVERAGE_WEIGHT_CHANGE_PERCENT_ID = exports.DAY_ID = void 0;
const style_1 = require("@omnivivo/style");
const ExperimentInjectedSections_1 = require("./ExperimentInjectedSections");
const lodash_1 = require("lodash");
const numeral = require('numeral');
const TREATMENT_GROUP_SECTION_TYPE = 'treatmentGroup';
const TREATMENT_GROUP_COLUMNS_SECTION_TYPE = 'treatmentGroupColumns';
exports.DAY_ID = 'day';
const IMPLANTATION_DATE_ID = 'implantationDate';
const INPUTS_ID = 'inputs';
exports.AVERAGE_WEIGHT_CHANGE_PERCENT_ID = 'averageWeightChangePercentage';
exports.MEAN_ID = 'mean';
exports.MEDIAN_ID = 'median';
exports.WEIGHT_CHANGE_PERCENT_ID = 'weightChangePercentage';
exports.DOSE_AMOUNT = 'doseAmount';
exports.DOSE_TIME = 'doseTime';
exports.DOSE_REQUIRED = 'doseRequired';
const getSectionId = (sectionType, treatmentGroupForWorksheet) => `${sectionType}-${treatmentGroupForWorksheet.treatmentGroupId}`;
const getTgSectionId = (tg) => getSectionId(TREATMENT_GROUP_SECTION_TYPE, tg);
exports.getTgSectionId = getTgSectionId;
const getTgColumnsSectionId = (tg) => getSectionId(TREATMENT_GROUP_COLUMNS_SECTION_TYPE, tg);
exports.getTgColumnsSectionId = getTgColumnsSectionId;
const dataCollectionCommonSections = (tg) => [
    {
        id: 'heading',
        sectionType: 'form',
        fields: [
            {
                id: 'instructions',
                fieldType: 'readOnly',
                fieldName: 'Instructions',
                gridWidth: 6,
                default: "In this worksheet, data can be collected for all animals in all groups. The groups are vertically stacked by number in their own section of the worksheet. Within each group's section the data is separated by day, with each day being represented by a large column unit. The dates for each day are fully editable and there is no limit to the number of days that can be recorded. ",
            },
            {
                id: 'notes',
                fieldType: 'longText',
                fieldName: 'notes',
                gridWidth: 6,
            },
        ],
    },
    {
        id: INPUTS_ID,
        sectionType: 'form',
        fields: [
            {
                id: IMPLANTATION_DATE_ID,
                fieldType: 'calc',
                fieldName: 'Implantation date',
                calc: `lookup('${ExperimentInjectedSections_1.EXPERIMENT_MODELS_ID}', 'implantationDate', ['id', ${tg?.modelId}])`,
                gridWidth: 2,
            },
        ],
    },
];
exports.dataCollectionCommonSections = dataCollectionCommonSections;
const createDataCollectionColumnsSection = (tg, dataSourceFieldName, numberFormat) => ({
    id: (0, exports.getTgColumnsSectionId)(tg),
    dataCollectionSectionId: (0, exports.getTgSectionId)(tg),
    sectionType: 'hidden',
    fields: [
        {
            id: 'date',
            fieldType: 'date',
            gridWidth: 4,
            fieldName: 'Date',
        },
        {
            id: exports.DAY_ID,
            fieldType: 'calc',
            gridWidth: 2,
            calc: `daysDifference(${INPUTS_ID}.${IMPLANTATION_DATE_ID}, date)`,
            fieldName: 'Day',
        },
        {
            id: 'notes',
            fieldType: 'longText',
            gridWidth: 6,
            fieldName: 'notes',
        },
        {
            id: 'testArticles',
            fieldType: 'readOnly',
            gridWidth: 12,
            default: getTestArticleName(tg.testArticlesForWorksheet),
            fieldName: 'Test Articles',
        },
        {
            id: exports.MEAN_ID,
            fieldName: 'Mean',
            fieldType: 'calc',
            numberFormat,
            gridWidth: 2,
            calc: `mean(valuesFromColumn(dataCollectionSectionId(), columnId(), "${dataSourceFieldName}"))`,
        },
        {
            id: 'stdDev',
            fieldName: 'Standard deviation',
            fieldType: 'calc',
            numberFormat,
            gridWidth: 3,
            calc: `stdev(valuesFromColumn(dataCollectionSectionId(), columnId(), "${dataSourceFieldName}"))`,
        },
        {
            id: exports.MEDIAN_ID,
            fieldName: 'Median',
            fieldType: 'calc',
            numberFormat,
            gridWidth: 2,
            calc: `median(valuesFromColumn(dataCollectionSectionId(), columnId(), "${dataSourceFieldName}"))`,
        },
        {
            id: exports.AVERAGE_WEIGHT_CHANGE_PERCENT_ID,
            fieldType: 'calc',
            numberFormat: '0.00',
            unit: '%',
            hidden: true,
            // The original Excel sheets used the `AVERAGE` function for this which calculates the `arithmetic mean`
            calc: `mean(valuesFromColumn(dataCollectionSectionId(), columnId(), "${exports.WEIGHT_CHANGE_PERCENT_ID}"))`,
        },
    ],
});
exports.createDataCollectionColumnsSection = createDataCollectionColumnsSection;
const createDataCollectionMainSection = (treatmentGroupForWorksheet, fields, treatmentGroupsForWorksheet) => ({
    id: (0, exports.getTgSectionId)(treatmentGroupForWorksheet),
    columnSectionId: (0, exports.getTgColumnsSectionId)(treatmentGroupForWorksheet),
    sectionName: treatmentGroupForWorksheet.treatmentGroupName,
    sectionType: 'dataCollection',
    allowAddColumns: true,
    allowAddRows: true,
    allowRemoveRows: false,
    rowName: 'animal',
    columnName: 'day',
    addRowToSectionIds: treatmentGroupsForWorksheet.map((tg) => (0, exports.getTgSectionId)(tg)),
    fields: fields,
});
exports.createDataCollectionMainSection = createDataCollectionMainSection;
const createDataCollectionCharts = (treatmentGroupsForWorksheet, chartLabel, yAxisLabel) => [
    {
        id: 'meanGraph',
        sectionName: `Mean ${chartLabel}`,
        sectionType: 'chart',
        chartXLabel: 'days since implantation',
        chartYLabel: `mean ${yAxisLabel}`,
        chartData: treatmentGroupsForWorksheet.map((tg, i) => ({
            sourceSectionId: (0, exports.getTgColumnsSectionId)(tg),
            label: `${getTestArticleName(tg.testArticlesForWorksheet)} | ${tg.treatmentGroupName}`,
            xFieldId: exports.DAY_ID,
            yFieldId: exports.MEAN_ID,
            color: style_1.ChartColors[i % style_1.ChartColors.length],
        })),
    },
    {
        id: 'medianGraph',
        sectionName: `Median ${chartLabel}`,
        sectionType: 'chart',
        chartXLabel: 'days since implantation',
        chartYLabel: `median ${yAxisLabel}`,
        chartData: treatmentGroupsForWorksheet.map((tg, i) => ({
            sourceSectionId: (0, exports.getTgColumnsSectionId)(tg),
            label: `${getTestArticleName(tg.testArticlesForWorksheet)} | ${tg.treatmentGroupName}`,
            xFieldId: exports.DAY_ID,
            yFieldId: exports.MEDIAN_ID,
            color: style_1.ChartColors[i % style_1.ChartColors.length],
        })),
    },
    {
        id: 'bodyWeightGraph',
        sectionName: `% Body Weight Change`,
        sectionType: 'chart',
        chartXLabel: 'days',
        chartYLabel: `% weight change`,
        chartData: treatmentGroupsForWorksheet.map((tg, i) => ({
            sourceSectionId: (0, exports.getTgColumnsSectionId)(tg),
            label: `${getTestArticleName(tg.testArticlesForWorksheet)} | ${tg.treatmentGroupName}`,
            xFieldId: exports.DAY_ID,
            yFieldId: exports.AVERAGE_WEIGHT_CHANGE_PERCENT_ID,
            color: style_1.ChartColors[i % style_1.ChartColors.length],
        })),
    },
];
exports.createDataCollectionCharts = createDataCollectionCharts;
const createAllDataCollectionSections = (treatmentGroupsForWorksheet, dataSourceFieldName, chartLabel, yAxisLabel, numberFormat, fields) => [
    ...ExperimentInjectedSections_1.experimentInjectedSectionDefinitions,
    ...(0, exports.dataCollectionCommonSections)(treatmentGroupsForWorksheet[0]),
    ...treatmentGroupsForWorksheet.map((num) => (0, exports.createDataCollectionColumnsSection)(num, dataSourceFieldName, numberFormat)),
    ...treatmentGroupsForWorksheet.map((treatmentGroupForWorksheet) => (0, exports.createDataCollectionMainSection)(treatmentGroupForWorksheet, fields, treatmentGroupsForWorksheet)),
    ...(0, exports.createDataCollectionCharts)(treatmentGroupsForWorksheet, chartLabel, yAxisLabel),
];
exports.createAllDataCollectionSections = createAllDataCollectionSections;
function formatNumber(value) {
    if (value) {
        const format = value > 10000 ? style_1.SCIENTIFIC_NOTATION_FORMAT : style_1.WORKSHEET_DEFAULT_FORMAT;
        return numeral(value).format(format);
    }
    return '';
}
exports.formatNumber = formatNumber;
function getTestArticleName(testArticles) {
    return testArticles
        .map(({ doseNumber, doseUnit, interval, neededDosage, testArticleName }) => {
        return (0, lodash_1.compact)([
            testArticleName,
            formatNumber(neededDosage),
            testArticleName === 'Untreated' ? '' : doseUnit,
            (0, lodash_1.compact)([
                interval && `q${numeral(interval).format(style_1.WORKSHEET_DEFAULT_FORMAT)}`,
                doseNumber && `dx${doseNumber}`,
            ]).join(''),
        ]).join(' ');
    })
        .join(', ');
}
exports.getTestArticleName = getTestArticleName;
