import { useState } from 'react';
import styled from '@emotion/styled';
import {
  useListContentsQuery,
  ListContentCategory,
  useSetListContentCategoryMutation,
  ListContentInput,
  ListContentsDocument,
} from '#graphql';
import { useApolloClient } from '@apollo/client';
import { categoryTitleMap } from '#lib/constants';
import { ListContentEditor } from '#components/partials';
import { Loader } from '#components/widgets';
import { OtherMoleculesEditor } from './OtherMoleculesEditor';

const EditorContainer = styled.div({
  padding: '10px 50px',
  marginBottom: '50px',
});

const ListContentContainer = styled.div({
  display: 'flex',
  justifyContent: 'flex-start',
  flexWrap: 'wrap',
});

export const TemplateEditor = () => {
  const client = useApolloClient();
  const { data, loading } = useListContentsQuery();
  const [savingCategory, setSavingCategory] = useState<string>();
  const [setListContentCategory, { loading: saveLoading }] =
    useSetListContentCategoryMutation();
  if (loading) return <Loader />;

  const categories = Object.keys(
    categoryTitleMap
  ).sort() as ListContentCategory[];

  const getListContents = (category: string) =>
    (data?.listContents! || []).items.filter(
      (content) => content.category === category
    );

  const save = async (items: ListContentInput[], category: string) => {
    setSavingCategory(category);
    await setListContentCategory({
      variables: {
        input: { category, items },
      },
    });
    client.writeQuery({
      query: ListContentsDocument,
      data: {
        listContents: {
          items: [
            ...(data?.listContents.items || []).filter(
              (d) => d.category !== category
            ),
            ...items.map((i) => ({
              id: Math.floor(Math.random() * 100000000),
              __typename: 'ListContent',
              name: i.name,
              description: i.description,
              category,
            })),
          ],
        },
      },
    });
  };

  return (
    <EditorContainer>
      <h4>Experiment Content Categories:</h4>
      <ListContentContainer>
        <OtherMoleculesEditor />
        {categories.map((category) => (
          <ListContentEditor
            listContents={getListContents(category)}
            category={category}
            save={save}
            key={category}
            loading={saveLoading && savingCategory === category}
          />
        ))}
      </ListContentContainer>
    </EditorContainer>
  );
};
