import styled from '@emotion/styled';
import { AddOutlined, CloseOutlined } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { type GridRenderCellParams } from '@mui/x-data-grid-premium';
import { AppColors } from '@omnivivo/style';
import { compact, isNil } from 'lodash';
import toast from 'react-hot-toast';

import { ConfirmationModal } from '#components/partials/ConfirmationModal';
import { DefinitionList, OmniGrid, type DefinitionListProps } from '#components/widgets';
import { type ImagingTreatmentGroup, type TestArticle } from '#graphql';
import { useModal } from '#lib/hooks';
import { type TreatmentGroupWithTreatment } from '#lib/types';
import { StyledButton, StyledButtonContainer, StyledCloseButton, StyledGroupCard } from '../Cards/styles';

interface DeleteTexts {
  Delete: {
    Confirmation: string;
    loading: string;
    success: string;
    error: string;
  };
}

interface BioanalysisDataProps {
  bioanalysisTreatmentGroups: ImagingTreatmentGroup[];
  canEdit: boolean;
  definitions: DefinitionListProps['definitions'];
  deleteTexts: DeleteTexts;
  handleEdit: () => void;
  onDelete: () => Promise<void>;
  treatmentGroups: TreatmentGroupWithTreatment[];
}

export const StyledHeader = styled.div({
  display: 'flex',
  background: AppColors.LIGHT_LIGHT_GREY,
  padding: '10px',
  minWidth: '1150px',
  flexDirection: 'column',
});

const StyledContainer = styled.div({
  padding: '10px',
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  overflowX: 'auto',
});

const treatmentGroupColumns = [
  {
    field: 'group',
    flex: 1,
    headerName: 'Group',
    sortable: false,
  },
  {
    field: 'testArticles',
    flex: 2,
    headerName: 'Test Articles',
    sortable: false,
    renderCell(params: GridRenderCellParams<TestArticle[]>) {
      return (
        <Typography variant="body2" component="ul" paddingLeft={2}>
          {params.value?.map((testArticle) => <li key={testArticle.id}>{testArticleSummary(testArticle)}</li>)}
        </Typography>
      );
    },
  },
  {
    field: 'notes',
    flex: 2,
    headerName: 'Notes',
    sortable: false,
  },
];

function testArticleSummary(testArticle: TestArticle) {
  return `${testArticle.name} @ ${testArticle.dosage} ${testArticle.doseUnit}`;
}

export function BioanalysisData(props: BioanalysisDataProps) {
  const { bioanalysisTreatmentGroups, canEdit, definitions, deleteTexts, handleEdit, onDelete, treatmentGroups } =
    props;

  const {
    Modal: DeleteModal,
    openModal: openDeleteModal,
    closeModal: closeDeleteModal,
  } = useModal(deleteTexts.Delete.Confirmation, 'sm');

  async function handleDelete() {
    await toast.promise(onDelete(), deleteTexts.Delete);
    closeDeleteModal();
  }

  const treatmentGroupRows = compact(
    treatmentGroups.map((tg) => {
      const bbtg = bioanalysisTreatmentGroups.find((bbtg) => bbtg.treatmentGroupId === tg.id);

      if (isNil(bbtg)) {
        return undefined;
      }

      return {
        id: bbtg.id,
        group: tg.treatmentGroupIndex,
        testArticles: tg.treatment.testArticles,
        notes: bbtg.notes,
      };
    })
  );

  return (
    <>
      <DeleteModal>
        <ConfirmationModal
          closeModal={closeDeleteModal}
          confirmationFunction={() => {
            void handleDelete();
          }}
        />
      </DeleteModal>

      <StyledGroupCard>
        <Box display="flex" justifyContent="space-between">
          <Box padding={2} sx={{ background: AppColors.LIGHT_LIGHT_GREY, flex: 1 }}>
            <DefinitionList definitions={definitions} />
          </Box>
          {canEdit && (
            <Box display="flex" alignItems="center" paddingLeft={2} sx={{ background: AppColors.LIGHT_GREY }}>
              <StyledButtonContainer gap="5px">
                <StyledButton onClick={handleEdit}>
                  <AddOutlined /> Edit
                </StyledButton>
                <StyledCloseButton background={AppColors.WHITE} onClick={openDeleteModal}>
                  <CloseOutlined fontSize="small" />
                </StyledCloseButton>
              </StyledButtonContainer>
            </Box>
          )}
        </Box>
        <StyledContainer>
          <OmniGrid
            columns={treatmentGroupColumns}
            dataGridProps={{
              autoHeight: true,
              disableColumnFilter: true,
              disableColumnMenu: true,
              disableSelectionOnClick: true,
              getRowHeight: () => 'auto',
              hideFooter: true,
              sx: {
                '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
                  py: (theme) => theme.spacing(1),
                },
              },
            }}
            defaultPageSize={treatmentGroupRows.length}
            refetch={() => undefined}
            rows={treatmentGroupRows}
            rowCount={treatmentGroupRows.length}
            showQuickFilter={false}
          />
        </StyledContainer>
      </StyledGroupCard>
    </>
  );
}
