export const JWT_NAME = 'StaticWebAppsAuthCookie';

export const API_GET_ATTACHMENT = 'attachment-get';

export const API_ADD_ATTACHMENT = 'attachment-add';

export const API_DELETE_ATTACHMENT = 'attachment-delete';

export const HTTP = {
  OK: 200,
  CREATED: 201,
  ACCEPTED: 202,
  NO_CONTENT: 204,
  REDIRECT: 302,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  NOT_ACCEPTABLE: 406,
  CONFLICT: 409,
  UNPROCESSABLE_ENTITY: 422,
  INTERNAL_SERVER_ERROR: 500,
};
