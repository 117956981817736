import styled from '@emotion/styled';
import { getWorksheetWithExperimentSections } from '@omnivivo/worksheets';
import {
  getNormalizeWorksheetDefinition,
  type NewWorksheetEntry,
  type Worksheet,
  type WorksheetEntry,
  type WorksheetEntryBulkDeleter,
  type WorksheetEntryDeleter,
  type WorksheetFieldUpdater,
} from '@omnivivo/worksheets-core';
import { compact, isNil, map } from 'lodash';
import { useMemo } from 'react';
import { useParams } from 'react-router';

import { Loader, MessageBox } from '#components/widgets';
import {
  useBulkCreateWorksheetEntriesMutation,
  useBulkDeleteWorksheetEntriesMutation,
  useCreateWorksheetEntryMutation,
  useDeleteWorksheetEntryMutation,
  useExperimentByIdQuery,
  useUpdateWorksheetEntryDataValuesMutation,
  useWorksheetEntriesByWorksheetQuery,
  useWorksheetQuery,
  type ExperimentDetailsFragment,
  type ListContent,
  useListContentsQuery,
} from '#graphql';
import { getExperimentWorksheetEntries } from '#lib/ExperimentWorksheetsEntryInjectionLib';
import { i18n } from '#lib/constants';
import { WorksheetBody, WorksheetHeader } from './WorksheetComponents';

const PageWrapper = styled.div({
  label: 'PageWrapper',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
});
interface WorksheetPageFullyLoadedProps {
  worksheet: Worksheet;
  worksheetEntries: WorksheetEntry[];
  experiment?: ExperimentDetailsFragment;
  listContent: ListContent[];
}

function preprocessAddEntry(newEntry: NewWorksheetEntry, worksheetId: number) {
  if (isNil(newEntry.sectionId)) {
    throw new Error('newEntry must have a sectionId');
  }

  return {
    ...newEntry,
    worksheetId: newEntry.worksheetId ?? worksheetId,
    sectionId: newEntry.sectionId,
    data: JSON.stringify(newEntry.data ?? {}),
  };
}

const WorksheetPageFullyLoaded = ({
  worksheet,
  experiment,
  worksheetEntries,
  listContent,
}: WorksheetPageFullyLoadedProps) => {
  const [updateWorksheetEntryDataValues] = useUpdateWorksheetEntryDataValuesMutation();
  const [createWorksheetEntry] = useCreateWorksheetEntryMutation();
  const [bulkCreateWorksheetEntries] = useBulkCreateWorksheetEntriesMutation();
  const [bulkDeleteWorksheetEntries] = useBulkDeleteWorksheetEntriesMutation();
  const [deleteWorksheetEntry] = useDeleteWorksheetEntryMutation();

  const [deleteEntry, bulkAddEntries, bulkDeleteEntries, updateValue, addEntry, entries, memoizedWorksheet] =
    useMemo(() => {
      async function addEntry(newEntry: NewWorksheetEntry): Promise<WorksheetEntry> {
        const result = await createWorksheetEntry({
          variables: {
            input: preprocessAddEntry(newEntry, worksheet.id),
          },
        });

        const worksheetEntry = result.data?.createWorksheetEntry;
        if (isNil(worksheetEntry)) {
          throw new Error(`server didn't return expected data.createWorksheetEntry`);
        }

        return {
          ...worksheetEntry,
          data: newEntry.data ?? {},
        };
      }

      async function bulkAddEntries(newEntries: NewWorksheetEntry[]): Promise<WorksheetEntry[]> {
        const result = await bulkCreateWorksheetEntries({
          variables: {
            input: {
              entries: newEntries.map((entry) => preprocessAddEntry(entry, worksheet.id)),
            },
          },
        });

        const worksheetEntries = result.data?.bulkCreateWorksheetEntries;

        if (isNil(worksheetEntries)) {
          return [];
        }

        return worksheetEntries.items.map((entry, i) => ({
          ...entry,
          data: newEntries[i].data ?? {},
        }));
      }

      const updateValue: WorksheetFieldUpdater = async ({ entryId, fieldId, value }) =>
        await updateWorksheetEntryDataValues({
          variables: {
            input: {
              id: entryId,
              dataUpdates: JSON.stringify({ [fieldId]: value ?? '' }),
            },
          },
        });

      const bulkDeleteEntries: WorksheetEntryBulkDeleter = async (ids: number[]) =>
        await bulkDeleteWorksheetEntries({ variables: { input: { ids } } });

      const deleteEntry: WorksheetEntryDeleter = async ({ entryId }) =>
        await deleteWorksheetEntry({ variables: { input: { id: entryId } } });

      const entries = [
        ...worksheetEntries,
        ...getExperimentWorksheetEntries(experiment, worksheet, worksheetEntries, listContent),
      ];

      const memoizedWorksheet = getWorksheetWithExperimentSections(experiment?.treatmentGroups.length ?? 0, worksheet);

      return [deleteEntry, bulkAddEntries, bulkDeleteEntries, updateValue, addEntry, entries, memoizedWorksheet];
    }, []);
  return (
    <>
      <WorksheetHeader worksheet={worksheet} entries={entries} experiment={experiment} />

      <WorksheetBody
        {...{
          worksheet: memoizedWorksheet,
          entries,
          experiment,
          updateValue,
          addEntry,
          bulkAddEntries,
          bulkDeleteEntries,
          deleteEntry,
        }}
      />
    </>
  );
};

export const WorksheetPage = () => {
  const { experimentId, worksheetId } = useParams();

  if (isNil(experimentId) || isNil(worksheetId)) {
    throw new Error('`experimentId` and `worksheetId` are required path parameters');
  }

  const listContentQuery = useListContentsQuery({
    variables: { category: 'preclinicalObservation' },
  });
  const experimentQuery = useExperimentByIdQuery({
    variables: { id: +experimentId },
  });

  const worksheetQuery = useWorksheetQuery({
    variables: { id: +worksheetId },
  });

  const entriesQuery = useWorksheetEntriesByWorksheetQuery({
    fetchPolicy: 'no-cache',
    variables: { worksheetId: +worksheetId },
  });

  const loading = experimentQuery.loading || worksheetQuery.loading || entriesQuery.loading || listContentQuery.loading;
  if (loading) {
    return <Loader />;
  }

  const errors = compact([experimentQuery.error, worksheetQuery.error, entriesQuery.error, listContentQuery.error]);
  if (errors.length > 0) {
    return <MessageBox>{map(errors, 'message').join('\n')}</MessageBox>;
  }

  if (
    experimentQuery.data?.experiment === undefined ||
    worksheetQuery.data?.worksheet === undefined ||
    entriesQuery.data?.worksheetEntriesByWorksheet === undefined ||
    listContentQuery.data?.listContents?.items === undefined
  ) {
    return <MessageBox>{i18n.Common.GenericError}</MessageBox>;
  }

  const parsedWorksheet = {
    ...worksheetQuery.data.worksheet,
    definition: getNormalizeWorksheetDefinition(
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      JSON.parse(worksheetQuery.data.worksheet.definition ?? '{}')
    ),
  };

  const parsedEntries = entriesQuery.data.worksheetEntriesByWorksheet.items.map((entry) => ({
    ...entry,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    data: JSON.parse(entry.data ?? '{}'),
  }));

  return (
    <PageWrapper>
      <WorksheetPageFullyLoaded
        experiment={experimentQuery.data?.experiment}
        worksheet={parsedWorksheet}
        worksheetEntries={parsedEntries}
        listContent={listContentQuery.data?.listContents?.items ?? []}
      />
    </PageWrapper>
  );
};
