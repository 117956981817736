import type { ApolloLink, InMemoryCacheConfig } from '@apollo/client';
import type { GraphQLErrors } from '@apollo/client/errors';
import type { NavigateFunction } from 'react-router-dom';
import { ApolloClient, ApolloProvider, InMemoryCache, from } from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { onError } from '@apollo/client/link/error';
import React, { useMemo } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

import { apolloCacheConfiguration } from './apolloCacheConfiguration';
import { i18n } from '#lib/constants';
import { isNil } from 'lodash';

function buildBatchLink() {
  return new BatchHttpLink({
    uri: `${process.env.REACT_APP_API_URI}/graphql`,
  });
}

function hasSeagenAuthenticationError(graphQLErrors: GraphQLErrors | undefined) {
  return !isNil(graphQLErrors?.find((graphQLError) => graphQLError.extensions.code === 'SEAGEN_UNAUTHENTICATED'));
}

function hasAuthenticationError(graphQLErrors: GraphQLErrors | undefined) {
  return !isNil(graphQLErrors?.find((graphQLError) => graphQLError.extensions.code === 'UNAUTHENTICATED'));
}

function buildOnErrorLink(navigate: NavigateFunction) {
  return onError(({ graphQLErrors, networkError }) => {
    if (hasSeagenAuthenticationError(graphQLErrors)) {
      navigate('./no-access');
      toast.error(i18n.Errors.SEAGEN_UNAUTHENTICATED);
      return;
    }

    if (hasAuthenticationError(graphQLErrors)) {
      navigate('./login');
      toast.error(i18n.Errors.UNAUTHENTICATED);
      return;
    }

    if (!isNil(networkError)) {
      toast.error(i18n.Errors.INTERNAL_SERVER_ERROR);
    }
  });
}

function buildClient(cacheConfiguration: InMemoryCacheConfig, links: ApolloLink[]) {
  return new ApolloClient({
    link: from(links),
    cache: new InMemoryCache(cacheConfiguration),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'network-only', // Used for first execution
        nextFetchPolicy: 'cache-first', // Used for subsequent executions
      },
    },
  });
}

interface OmniApolloProviderProps {
  children: React.ReactNode;
}

export function OmniApolloProvider({ children }: OmniApolloProviderProps) {
  const navigate = useNavigate();

  const client = useMemo(() => {
    return buildClient(apolloCacheConfiguration, [buildOnErrorLink(navigate), buildBatchLink()]);
  }, []);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}
