import { ExperimentState, ExperimentSubState } from '#graphql';

export function getExperimentSubState(subState?: ExperimentSubState) {
  switch (subState) {
    case 'CellThaw':
      return 'Cell Thaw';

    case 'StudyEnd':
      return 'Study End';

    case 'StudyStart':
      return 'Study Start';

    default:
      return '-';
  }
}

export function isDraft(experiment: { status: ExperimentState }) {
  return experiment.status === 'Draft';
}

export function isApproved(experiment: { status: ExperimentState }) {
  return experiment.status !== 'Draft' && experiment.status !== 'Review';
}

export function isInProvision(experiment: { status: ExperimentState }) {
  return experiment.status === 'Provision';
}
