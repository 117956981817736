import CheckIcon from '@mui/icons-material/Check';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import {
  DataGridPremium,
  GridColumnHeaderTitle,
  gridClasses,
  useGridApiRef,
  type GridColumns,
  type GridRowClassNameParams,
  type GridValueFormatterParams,
} from '@mui/x-data-grid-premium';
import { chain, compact, isNil, sum, sumBy } from 'lodash';
import { useCallback, useMemo } from 'react';

import { ConfirmationModal } from '#components/partials';
import { type ListContentDetailsFragment, type TissueBioanalysisTissueFragment } from '#graphql';
import { AppColors, i18n } from '#lib/constants';
import { useModal } from '#lib/hooks';
import { formatDate, formatTime } from '#lib/utils';
import { Button } from '#rds';
import { DetailPanelProvider } from './DetailPanelProvider';
import { DetailPanelRow } from './DetailPanelRow';
import { DetailPanelRowCheckbox } from './DetailPanelRowCheckbox';
import { DetailPanelRowIntegerField } from './DetailPanelRowIntegerField';
import { DetailPanelRowSetCurrentTimeButton } from './DetailPanelRowSetCurrentTimeButton';
import { DetailPanelRowSyncIndicator } from './DetailPanelRowSyncIndicator';
import { DetailPanelRowTimeField } from './DetailPanelRowTimeField';
import { useDetailPanel } from './useDetailPanel';

export interface HandlingAndStorage {
  id: number;
  weight: number | undefined;
  collected: boolean;
}

export type HandlingAndStorageKey = `handlingAndStorage|${number}|${string}`;

export interface DetailRow {
  id: number;
  treatment: string;
  treatmentGroupIndex: string;
  animalNumber: number;
  expectedTime: string | undefined;
  actualTime: string | undefined;
  [key: HandlingAndStorageKey]: HandlingAndStorage;
}

interface GridDetailRow extends DetailRow {
  lastOfGroup?: boolean;
}

interface DetailPanelProps {
  canUpdateActualTime: boolean;
  closePanel?: () => void;
  collectionCriteria: string;
  data: DetailRow[];
  handlingAndStorage?: ListContentDetailsFragment[];
  tissues: TissueBioanalysisTissueFragment[];
  worksheetId: number;
}

const rowHeight = 56;

const sx = {
  // borders
  [`&.${gridClasses.root}`]: {
    border: 'none',
  },
  [`.${gridClasses.columnHeaders}`]: {
    borderBottom: 'none',
  },
  [`.${gridClasses.row}:first-of-type .${gridClasses.cell}:not(.sync)`]: {
    borderTop: '1px solid lightgrey',
  },
  [`.${gridClasses.row} .${gridClasses.cell}:first-of-type`]: {
    borderLeft: '1px solid lightgrey',
  },
  [`.${gridClasses.row} .${gridClasses.cell}.sync`]: {
    borderBottom: 'none',
    borderLeft: '1px solid lightgrey',
    borderRight: 'none',
    borderTop: 'none',
  },
  [`.${gridClasses.columnHeader}.${gridClasses.withBorder}`]: {
    borderRight: 'none',
  },

  // column header separator
  [`.${gridClasses.columnSeparator}`]: {
    display: 'none',
  },

  // striped
  [`.${gridClasses['pinnedColumns--right']} .${gridClasses.row}.Mui-selected .${gridClasses.cell}, .${gridClasses['pinnedColumns--right']} .${gridClasses.row}.Mui-hovered .${gridClasses.cell}`]:
    {
      background: 'white',
    },
  [`.${gridClasses.row}:nth-of-type(odd):not(.Mui-selected) .${gridClasses.cell}:not(.sync)`]: {
    background: AppColors.LIGHT_LIGHT_GREY,
  },

  // grouped
  [`.${gridClasses.row}.last-of-group`]: {
    marginBottom: 4,
  },
  [`.${gridClasses.row}.last-of-group + .${gridClasses.row} .${gridClasses.cell}:not(.sync)`]: {
    borderTop: '1px solid lightgrey',
  },
  [`.${gridClasses.row}.first-of-group .${gridClasses.cell}:not(.sync)`]: {
    borderTop: '1px solid lightgrey',
  },
  [`.${gridClasses['row--lastVisible']} .${gridClasses.cell}:not(.sync)`]: {
    borderBottom: '1px solid lightgrey',
  },

  // fade on data update
  [`.${gridClasses.row}.updated-enter .${gridClasses.cell}:not(.sync)`]: {
    borderColor: '#DA7929',
    backgroundColor: '#F4CFA6',
  },
  [`.${gridClasses.row}.updated-enter-active .${gridClasses.cell}:not(.sync)`]: {
    borderColor: '#DA7929',
    backgroundColor: '#F4CFA6',
    transition: `all 200ms ease-in-out`,
  },
  [`.${gridClasses.row}.updated-enter-done .${gridClasses.cell}:not(.sync)`]: {
    transition: `all 2000ms ease-in-out`,
  },
};

const experimentFeatures = {
  newEditingApi: true,
};

export function DetailPanel(props: DetailPanelProps) {
  const {
    canUpdateActualTime,
    closePanel = () => undefined,
    collectionCriteria,
    data: dataProp,
    handlingAndStorage: handlingAndStorageListContent,
    tissues,
    worksheetId,
  } = props;

  const apiRef = useGridApiRef();

  const { data, updateActualTimes, context } = useDetailPanel(dataProp);

  const {
    Modal: PopulateActualTimesWithExpectedTimesModal,
    openModal: openPopulateActualTimesWithExpectedTimesModal,
    closeModal: closePopulateActualTimesWithExpectedTimesModal,
  } = useModal(null, 'sm');

  const [rows, height] = useMemo(() => {
    const rows = buildRows(data);
    const height = rowHeight + sum(rows.map((row) => (row.lastOfGroup === true ? 84 : 52))) + 20;
    return [rows, height];
  }, [data]);

  const populateActualTimesWithExpectedTimes = useCallback(() => {
    const updatedRows = rows.map((row) => ({ ...row, actualTime: row.expectedTime }));

    apiRef.current.updateRows(updatedRows);
    void updateActualTimes({
      variables: {
        input: {
          pkTissueWorksheetId: worksheetId,
          actualTimes: updatedRows.map(({ id, actualTime }) => ({ id, actualTime: actualTime ?? null })),
        },
      },
    });
  }, [apiRef, rows, updateActualTimes, worksheetId]);

  const maybePopulateActualTimesWithExpectedTimes = useCallback(() => {
    const hasData = data.some((collection) => !isNil(collection.actualTime) && collection.actualTime !== '');
    if (hasData) {
      openPopulateActualTimesWithExpectedTimesModal();
    } else {
      populateActualTimesWithExpectedTimes();
    }
  }, [data, openPopulateActualTimesWithExpectedTimesModal, populateActualTimesWithExpectedTimes]);

  const [columns, gridWidth] = useMemo(() => {
    const tissueColumns: GridColumns<GridDetailRow> = tissues.flatMap((tissue) =>
      tissue.handlingAndStorage.map(({ includeWeight, method }, handlingAndStorageIndex) => {
        const width = includeWeight ? 125 : 100;
        const field: HandlingAndStorageKey = `handlingAndStorage|${tissue.id}|${method}`;
        const headerTitle = method;
        const headerName = handlingAndStorageListContent?.find((hs) => hs.name === method)?.description ?? method;
        return {
          field,
          headerName,
          description: headerTitle,
          sortable: false,
          width,
          align: includeWeight ? 'left' : 'center',
          ...(handlingAndStorageIndex === 0
            ? {
                renderHeader() {
                  return (
                    <TissueAndMethodHeader
                      columnWidth={width}
                      method={headerName}
                      rowHeight={rowHeight}
                      tissue={tissue.tissue}
                      title={headerTitle}
                    />
                  );
                },
              }
            : {}),
          renderCell(params) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const handlingAndStorage = params.row[field]!;
            return includeWeight ? (
              <DetailPanelRowIntegerField
                rowId={params.id}
                disabled={!canUpdateActualTime}
                field={field}
                handlingAndStorageId={handlingAndStorage.id}
                value={handlingAndStorage.weight}
              />
            ) : (
              <DetailPanelRowCheckbox
                rowId={params.id}
                checked={handlingAndStorage.collected}
                disabled={!canUpdateActualTime}
                field={field}
                handlingAndStorageId={handlingAndStorage.id}
              />
            );
          },
        };
      })
    );

    const columns: GridColumns<GridDetailRow> = compact([
      {
        field: 'treatment',
        headerName: 'Treatment',
        sortable: false,
        width: 200,
      },
      {
        field: 'treatmentGroupIndex',
        headerName: 'Group',
        sortable: false,
        width: 70,
      },
      {
        field: 'animalNumber',
        headerName: 'Animal',
        sortable: false,
        width: 70,
      },
      collectionCriteria === 'dose'
        ? {
            field: 'expectedTime',
            headerName: 'Exp. Time',
            sortable: false,
            valueFormatter(params: GridValueFormatterParams<GridDetailRow['expectedTime']>) {
              return formatTime(params.value) ?? '-';
            },
            width: 80,
          }
        : null,
      collectionCriteria === 'dose'
        ? {
            field: 'populateButton',
            renderHeader() {
              return (
                <IconButton size="small" onClick={maybePopulateActualTimesWithExpectedTimes}>
                  <DoubleArrowIcon color="primary" titleAccess="populate actual times with expected times" />
                </IconButton>
              );
            },
            sortable: false,
            width: 50,
          }
        : null,
      {
        field: 'actualDate',
        headerName: 'Collection Date',
        sortable: false,
        valueGetter(params) {
          return isNil(params.row.actualTime) ? '-' : formatDate(params.row.actualTime);
        },
        width: 120,
      },
      {
        field: 'actualTime',
        headerName: 'Collection Time',
        renderCell(params) {
          return (
            <DetailPanelRowTimeField
              id={params.id}
              actualTime={params.row.actualTime}
              disabled={!canUpdateActualTime}
            />
          );
        },
        sortable: false,
        width: 150,
      },
      {
        field: 'actions',
        // @ts-expect-error -- ok for now
        getActions(params) {
          return [<DetailPanelRowSetCurrentTimeButton id={params.id} disabled={!canUpdateActualTime} />];
        },
        type: 'actions',
        width: 60,
      },
      ...tissueColumns,
      {
        field: 'sync',
        headerName: '',
        cellClassName: 'sync',
        renderCell() {
          return <DetailPanelRowSyncIndicator />;
        },
        sortable: false,
        width: 50,
      },
    ]);

    const gridWidth = sumBy(columns, 'width') + 22;

    return [columns, gridWidth];
  }, [maybePopulateActualTimesWithExpectedTimes, tissues, handlingAndStorageListContent, collectionCriteria]);

  const canClosePanel = !rows.some(
    (row) =>
      isNil(row.actualTime) ||
      Object.keys(row)
        .filter((key) => key.startsWith('handlingAndStorage'))
        .some((key) => {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/consistent-type-assertions
          const { collected } = row[key as HandlingAndStorageKey]!;
          return !collected;
        })
  );

  const pinnedColumns = {
    left: ['treatment', 'treatmentGroupIndex', 'animalNumber'],
    right: ['sync'],
  };

  return (
    <>
      <PopulateActualTimesWithExpectedTimesModal
        title="Populate actual times with expected times"
        content={i18n.PKTissueWorksheet.PopulateActualTimesWithExpectedTimes.Confirmation}
      >
        <ConfirmationModal
          closeModal={closePopulateActualTimesWithExpectedTimesModal}
          confirmationFunction={() => {
            closePopulateActualTimesWithExpectedTimesModal();
            populateActualTimesWithExpectedTimes();
          }}
        />
      </PopulateActualTimesWithExpectedTimesModal>
      <Box padding={4} borderBottom="1px solid #E0E0E0">
        <Stack spacing={4} alignItems="flex-start">
          <Box height={height} maxHeight={700} width={gridWidth} maxWidth="100%">
            <DetailPanelProvider context={context}>
              <DataGridPremium
                apiRef={apiRef}
                rows={rows}
                columns={columns}
                hideFooter
                disableColumnMenu
                disableColumnReorder
                disableExtendRowFullWidth
                experimentalFeatures={experimentFeatures}
                initialState={{ pinnedColumns }}
                getRowClassName={getRowClassName}
                components={{
                  Row: DetailPanelRow,
                }}
                sx={sx}
              />
            </DetailPanelProvider>
          </Box>
          <Button variant="contained" startIcon={<CheckIcon />} disabled={!canClosePanel} onClick={closePanel}>
            Confirm Final Collection Times
          </Button>
        </Stack>
      </Box>
    </>
  );
}

function buildRows(rows: GridDetailRow[]) {
  return chain(rows)
    .sortBy([(row) => Number(row.treatmentGroupIndex), 'animalNumber'])
    .groupBy('treatmentGroupIndex')
    .flatMap((rowGroup) => {
      const last = rowGroup.pop();
      return isNil(last) //
        ? rowGroup
        : rowGroup.concat([{ ...last, lastOfGroup: true }]);
    })
    .value();
}

function getRowClassName(params: GridRowClassNameParams<GridDetailRow>) {
  return params.row.lastOfGroup === true ? 'last-of-group' : '';
}

interface TissueAndMethodHeaderProps {
  columnWidth: number;
  method: string;
  rowHeight: number;
  tissue: string;
  title: string;
}

function TissueAndMethodHeader({ columnWidth, method, rowHeight, tissue, title }: TissueAndMethodHeaderProps) {
  return (
    <Box
      height={rowHeight}
      sx={{
        overflow: 'hidden',
        lineHeight: '2rem',
      }}
    >
      <Typography
        variant="body2"
        sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
        title={tissue}
      >
        {tissue}
      </Typography>
      <GridColumnHeaderTitle label={method} columnWidth={columnWidth} title={title} />
    </Box>
  );
}
