import { GridRow, useGridApiContext, type GridRowId, type GridRowProps } from '@mui/x-data-grid-premium';
import { type DateTime } from 'luxon';
import { useCallback, useEffect, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import { useUpdatePkBloodWorksheetCollectionActualTimeMutation } from '#graphql';
import { useDetailPanelContext } from './detailPanelContext';
import { RowContext } from './rowContext';
import { isNil } from 'lodash';

export function DetailPanelRow(props: GridRowProps) {
  const apiContext = useGridApiContext();
  const { error: panelError, loading: panelLoading, updates } = useDetailPanelContext();

  const [update, { error, loading }] = useUpdatePkBloodWorksheetCollectionActualTimeMutation();

  const updateActualTime = useCallback(
    async (rowId: GridRowId, value: DateTime | null) => {
      const actualTime = isNil(value) ? value : value.toISOTime() ?? '';
      apiContext.current.updateRows([{ ...props.row, actualTime }]);
      try {
        await update({
          variables: {
            input: {
              id: Number(rowId),
              actualTime,
            },
          },
        });
      } catch (_error: unknown) {
        // ignore, this is handled wth the error variable above
      }
    },
    [apiContext, update]
  );

  const nodeRef = useRef(null);

  const [updated, setUpdated] = useState(false);

  useEffect(() => {
    setUpdated(updates.includes(Number(props.rowId)));
    setTimeout(() => {
      setUpdated(false);
    }, 3000);
  }, [updates]);

  const rowContext = {
    error: panelError ?? error,
    loading: panelLoading || loading,
    updateActualTime,
  };

  return (
    <RowContext.Provider value={rowContext}>
      <CSSTransition nodeRef={nodeRef} in={updated} timeout={500} classNames="updated">
        <GridRow {...props} ref={nodeRef} />
      </CSSTransition>
    </RowContext.Provider>
  );
}
